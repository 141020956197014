import React, { useState, useRef } from 'react'
import {
    Card, Typography, TextField,
    InputAdornment, IconButton, Tooltip,
    Grid, Button, CircularProgress, Link,
    Box
} from '@mui/material';
import { Loop } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { Auth } from 'aws-amplify';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDialog';
import * as Validators from '../../common/form-validators';
import { useHistory } from 'react-router-dom';
import { RootDiv, JustifyBox, IMG } from '../AuthStyle';
import { useTranslation } from 'react-i18next';
import { AuthAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import AuctionReCaptcha from '../../common/AuctionReCaptcha';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import { AppHostDomain } from '../../common/StaticVariableDeclaration';

const ResetPassword = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [restPwd, setResetPwd] = useState({
        otpVal: '',
        errorMessage: '',
        showErrorMessage: false,
        showConfirmation: false,
        loading: false,
        errors: {},
        showSuccessMessage: false,
        successMessage: ''
    })
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const sendOtpRef = useRef(null);

    const setOtpValue = (e) => {
        setResetPwd(prevState => ({
            ...prevState,
            otpVal: e.target.value
        }))
        setTouched(prevState => ({
            ...prevState,
            otpVal: true,
        }))
    }

    const setStateData = (id, value) => {
        setResetPwd(prevState => ({
            ...prevState,
            [id]: value
        }))
        setTouched(prevState => ({
            ...prevState,
            [id]: true,
        }))
    }

    const validateOtp = async event => {
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                passwordReset(event);
            }
        })
    }

    const login = () => {
        history.push("/sign-in")
    }

    const passwordReset = async event => {
        let code = restPwd.otpVal.replaceAll(' ', '')
        event.preventDefault();
        setStateData('loading', true);
        setStateData('showErrorMessage', false);
        let payload = {
            loginId: props.location.state.username,
            password: props.location.state.password,
            otp: code
        }
        AuthAxiosAuctionInterceptor.patch(`user/reset-password?recaptcha=${captcha}`, payload).then(response => {
            setStateData('errorMessage', '');
            setStateData('showConfirmation', true)
            setStateData('loading', false);
        }).catch((error) => {
            setStateData('loading', false);
            if (error.message) {
                if(error.message.includes('ReCaptcha Expired')) {
                    setIsRefresh(true);
                    setTimeout(() => {
                        setIsRefresh(false);
                        sendOtpRef.current.click();
                    }, 2000);
                } else {
                    setStateData('errorMessage', error.message);
                    setStateData('showErrorMessage', true);
                }
            }
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        })
        // try {
        //     await Auth.forgotPasswordSubmit(props.location.state.username, code, restPwd.password)
        //         .then(res => {
        //             setStateData('errorMessage', '');
        //             setStateData('showConfirmation', true)

        //         }, error => {
        //             setStateData('showErrorMessage', true);
        //             setStateData('errorMessage', error.message);
        //         })
        // } catch (error) {
        //     setStateData('showErrorMessage', true);
        //     setStateData('errorMessage', error.message);
        // }
        // setStateData('loading', false);
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(restPwd).map(([key]) => {
            if (key === 'otpVal') {
                const newError = validate[key](restPwd[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    const validate = {
        otpVal: otpVal => (Validators.requiredValidation('OTP', otpVal)),
    };

    const resendOtp = async event => {
        event.preventDefault();
        setStateData('errorMessage', '');
        setStateData('showErrorMessage', false);
        setStateData('successMessage', '');
        setStateData('showSuccessMessage', false);
        AuthAxiosAuctionInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
            loginId: props.location.state.username, password: props.location.state.password
        }).then(response => {
            setStateData('errorMessage', '');
            setStateData('successMessage', t('OTP_Sent_Successfully'));
            setStateData('showSuccessMessage', true);
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        }).catch((error) => {
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            if (error.message) {
                setStateData('errorMessage', error.message);
                setStateData('showErrorMessage', true);
            }
        })
        // try {
        //     await Auth.forgotPassword(props.location.state.username)
        // } catch (error) { }
    }

    return (
        <RootDiv>
            <Card className='card'>
                <Grid container sx={{padding: '30px'}}>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <Grid item xs={12} sx={{ textAlign : 'center' }}>
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + '/images/auction.svg'} height={50} alt='E-AuctionLogo'></img> }
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + '/images/nbid.png'} height={45} alt='NbidLogo'></img> }
                        </Grid>
                        <Box sx={{marginTop: '20px'}}>
                            <Typography component="h1" variant="h5" gutterBottom>
                                {t('Otp_Confirmation_To_Reset_Password')}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {t('Please_enter_the_OTP')}
                                {/^[0-9+]+$/.test(props.location.state.username) ? t('Sent_To_Your_Registered_Mobile_Number') : t('Sent_To_Your_Registered_Email_ID') }
                            </Typography>
                            <br /><br />
                            <Typography>
                                <InputMask
                                    mask="9 9 9 9 9 9"
                                    value={restPwd.otpVal}
                                    onChange={setOtpValue}>
                                    {() => <TextField
                                        id='otp'
                                        margin="normal"
                                        placeholder="OTP"
                                        autoComplete="change-password-otp"
                                        style={{ margin: '8px 8px 8px 0px' }}
                                        inputProps={{ style: { textAlign: 'center', fontSize: '16px', fontWeight: 'bold' } }}
                                        helperText={touched['otpVal'] && errors.otpVal}
                                        error={touched['otpVal'] && errors.otpVal}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip title="Resend">
                                                    <InputAdornment position="end" className="pointer" style={{ minHeight: '1.8rem' }}>
                                                        <IconButton aria-label="resend-otp"
                                                            onClick={resendOtp}>
                                                            <Loop style={{ color: 'blue' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                </Tooltip>
                                            )
                                        }}
                                    />}
                                </InputMask>
                            </Typography>
                            <Grid item xs={12} className="text-center" style={{ marginTop: '15px' }}>
                                <Link onClick={login} style={{ float: 'left', fontSize: '12px', cursor: 'pointer' }}>{t('Back_to_Sign_In')}</Link>
                                <Button size="small" variant="contained" color="primary" style={{ float: 'right' }} ref={sendOtpRef}
                                    onClick={validateOtp} disabled={restPwd.loading}>
                                    {t('Reset')}
                                    {/* {restPwd.loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />} */}
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <JustifyBox p={4} height="100%">
                            <IMG
                                src={process.env.PUBLIC_URL + '/resetpassword.svg'}
                                alt=""
                            />
                        </JustifyBox>
                    </Grid>
                </Grid>
            </Card>
            {restPwd.showErrorMessage &&
                <AuctionSnackBar show={restPwd.showErrorMessage} message={restPwd.errorMessage} severity="error" reset={() => setStateData('errorMessage', null)}></AuctionSnackBar>}
            {restPwd.showSuccessMessage &&
                <AuctionSnackBar show={restPwd.showSuccessMessage} message={restPwd.successMessage} severity="success" reset={() => setStateData('successMessage', null)}></AuctionSnackBar>}
            {restPwd.showConfirmation &&
                <ConfirmationDialog title="Password Reset successful" show={restPwd.showConfirmation}
                    description={[t('Your_'), <strong>{t('App_Name')}</strong>, t('_account_password_reset_is_successfully_Please_SignIn_with_NewPassword')]} redirect={true} redirectUrl="/sign-in" />}
            {!isRefresh && <AuctionReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
            {restPwd.loading && <AuctionLoader show={true} invisible={false} />}
        </RootDiv>
    )
}

export default ResetPassword;
