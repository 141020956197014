
import { useLazyQuery } from '@apollo/client';
import { History } from '@mui/icons-material';
import { BottomNavigation, Card, Chip, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRupeeSign } from 'react-icons/fa';
import { PaperHeader } from '../auction/auction-detail/auction-style/AuctionStyle';
import AuctionLoader from "../common/auction-loader/AuctionLoader";
import { UserContext } from '../common/context/UserContext';
import GraphQLErrors from '../common/GraphQLErrors';
import AuctionPagination from "../common/pagination/AuctionPagination";
import { AuctionStatus, BidStatus, Roles } from '../common/StaticVariableDeclaration';
import { BID_HISTORY, LIST_BIDDER_BID_HISTORY } from '../GraphQL/Queries';
import { CurrencyFormatter } from '../utils/Utils';
import { AuctionDetailContext } from '../auction/auction-detail/AuctionDetail';

const moment = require("moment-timezone");

const useStyles = makeStyles(() => ({
    headCell: {
        fontWeight: "bold !important",
        fontSize: '0.875rem !important',
        padding: '10px 15px !important',
    },
    bidHeadCell: {
        fontWeight: "bold !important",
        fontSize: '12px !important',
        padding: '5px 10px !important',
    },
    tableCell: {
        padding: '10px 15px !important',
        color: "rgb(62, 80, 96) !important"
    },
    bidTableCell: {
        padding: '5px 10px !important',
        color: "rgb(62, 80, 96) !important"
    },
    pagination: {
        padding: '12px',
    },
    paper: {
        color: "rgb(26, 32, 39)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '10px',
        border: "1px solid rgb(238, 238, 238)",
        backgroundImage: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        // margin: "40px auto",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,
            "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    bidPaper: {
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 22px 37px 6px !important',
        borderRadius: '0px !important',
    },
    warningChip: {
        color: '#d7660a !important',
        backgroundColor: '#fff0e4 !important',
        fontWeight: 'bold !important'
    },
    errorChip: {
        color: '#d32f2f !important',
        backgroundColor: '#fae7e7 !important',
        fontWeight: 'bold !important'
    },
    successChip: {
        color: '#2d6e10 !important',
        backgroundColor: '#d3f6d5 !important',
        fontWeight: 'bold !important'
    },
    bidderInfoCard: {
        width: 'fit-content !important',
        padding: '5px 8px !important',
        borderRadius: '5px 5px 0px 0px !important',
        border:'2px solid #eeeeee'
    }
}));

function BidHistory(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { bidderId, bidOnId, auctionId, key, limit, isBid, pagination, isBidder, biddersBidHistory, auctionStatus, showLess, setBidHisDialogOpen } =
        { ...props, isBid: props.isBid || false, showLess: props.showLess || false };
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(limit ? limit : 10);
    const [bidhistory, setBidHistory] = useState(biddersBidHistory ? biddersBidHistory : [])
    const [count, setCount] = useState(isBid ? biddersBidHistory?.length : 0);
    const auctionDetailContext = useContext(AuctionDetailContext);
    const userContext = useContext(UserContext).rootContext;
    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

    const [ListBidHistory, { loading: loadingHistory, error: errorHistory }] = useLazyQuery(BID_HISTORY, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setBidHistory([]);
            setCount(data.listBidHistory.count)
            setBidHistory(data.listBidHistory.bids)
        }
    })

    const [ListBidderBidHistory, { loading: loadingBidderBidHistory, error: errorBidderBidHistory }] = useLazyQuery(LIST_BIDDER_BID_HISTORY, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setBidHistory([]);
            setCount(data.listBidderBidHistory.count)
            setBidHistory(data.listBidderBidHistory.bids)
        }
    })

    useEffect(() => {
        if (!isBid) {
            setBidHistory([]);
            if (isBidder) {
                ListBidderBidHistory({
                    variables: {
                        auctionId: auctionId,
                        bidOnId: bidOnId,
                        pagination: {
                            limit: rowsPerPage,
                            page: page,
                            asc: false
                        }
                    }
                })
            } else {
                ListBidHistory({
                    variables: {
                        auctionId: auctionId,
                        bidOnId: bidOnId,
                        bidderId: bidderId,
                        pagination: {
                            limit: rowsPerPage,
                            page: page,
                            asc: false
                        },
                        isLot: null
                    }
                })
            }
        }
    }, [page, rowsPerPage, isBid])

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };

    const getRemarksValue = (remarks) => {
        let remarksUICode = null;
        enumListOptions.BidStatus.map((status, index) => {
            if (status.dateBaseCode === remarks) {
                return remarksUICode = status.uiCode;
            }
        })
        if (remarksUICode !== null) return remarksUICode;
        else return remarks;
    }

    const columns = [
        { id: 'biddernameid', label: AuctionStatus.open === auctionStatus.toLowerCase() ? t('Bidder_ID') : t('Bidder_Name_ID'), align: 'left', className: classes.headCell, minWidth: 140 },
        { id: 'bididipaddress', label: <Fragment>{t('Bid_ID')}<br></br>{t('IP_Address')}</Fragment>, align: 'left', className: classes.headCell, minWidth: 170 },
        { id: 'bidstatus', label: t('Bid_Status'), align: 'left', className: classes.headCell, minWidth: 170 },
        { id: 'bidremarks', label: t('Bid_Remarks'), align: 'left', className: classes.headCell, minWidth: 200, maxWidth: 350 },
        { id: 'performedby', label: t('Performed_By'), align: 'left', className: classes.headCell, minWidth: 100, maxWidth: 200 },
        { id: 'bidtime', label: t('Bid_Date_And_Time') + ' (' + dateTimeZoneValue + ')', align: 'center', className: classes.headCell, minWidth: 180 },
        { id: 'bidamount', label: t('Bid_Amount'), align: 'right', className: classes.headCell, minWidth: 200 },
    ]

    const bidColumns = [
        { id: 'performedby', label: t('Performed_By'), align: 'left', className: classes.bidHeadCell, minWidth: 100 },
        { id: 'bidtime', label: t('Bid_Date_And_Time') + ' (' + dateTimeZoneValue + ')', align: 'center', className: classes.bidHeadCell, minWidth: 100 },
        { id: 'bidamount', label: t('Bid_Amount'), align: 'right', className: classes.bidHeadCell, minWidth: 190 },
    ]

    return (
        <div key={key}>
                {(isBidder && bidhistory.length > 0) && <Card className={classes.bidderInfoCard}>
                    <Stack spacing={1} direction='row'>
                        {bidhistory[0].name &&
                            <Fragment>
                                <Typography variant='subtitle2' color='textSecondary' sx={{fontWeight: 'bold'}}>{t('Bidder_Name')}:&nbsp;</Typography>
                                <Typography variant='subtitle2' color='primary' id='bidder-name' sx={{ fontWeight: 'bold' }}>{bidhistory[0].name}</Typography>
                                <Divider flexItem orientation='vertical'/>
                            </Fragment>
                        }
                        <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }} color='textSecondary'>{t('Bidder_ID')}:&nbsp;</Typography>
                        <Typography variant='subtitle2' id='bidder-id'>{bidhistory[0].bidderId}</Typography>
                    </Stack>
                </Card>}
                <TableContainer component={Paper} className={`${classes.paper} ${isBid ? classes.bidPaper : ''}`}>
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>
                                {!isBid && columns?.map(({ id, align, minWidth, maxWidth, label, className }) => (
                                    <Fragment key={id}>
                                        {(isBidder && id !== 'biddernameid') && <TableCell
                                            key={id}
                                            id={id}
                                            align={align}
                                            style={{ minWidth: minWidth, maxWidth: maxWidth ? maxWidth : '' }}
                                            className={`${className != undefined ? className : ''}`}
                                        >
                                            {label}
                                        </TableCell>}
                                        {(!isBidder) && <TableCell
                                            key={id}
                                            id={id}
                                            align={align}
                                            style={{ minWidth: minWidth, maxWidth: maxWidth ? maxWidth : '' }}
                                            className={`${className != undefined ? className : ''}`}
                                        >
                                            {label}
                                        </TableCell>}
                                    </Fragment>
                                ))}
                                {isBid && bidColumns.map(({ id, align, minWidth, label, className }) => (
                                    <TableCell
                                        key={id}
                                        id={id}
                                        align={align}
                                        style={{ minWidth: minWidth }}
                                        className={`${className != undefined ? className : ''}`}
                                    >
                                        {label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {((bidhistory === null || bidhistory === undefined || count === 0) && !loadingHistory) &&
                                <TableRow>
                                    <TableCell colSpan={6} align='center' sx={{ border: 'none !important' }}>
                                        <Stack justifyContent='center' alignItems='center' p={2}>
                                            <History color='action' style={{ fontSize: '2rem' }} />
                                            <Typography id='norecord' variant='subtitle2' color='textSecondary'>{t('No_History_found_at_the_moment')}</Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRow>}
                            {(isBid && (loadingHistory || loadingBidderBidHistory)) &&
                                < TableRow >
                                    <TableCell colSpan={6} align='center'>
                                        <Typography id='bidhistoryloading' color='primary' variant='h6' className='dot-loading'>{t('Loading')}</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                            {bidhistory.length > 0 && bidhistory?.map((value, index) => {
                                return (
                                    ((showLess && index < 3) || !showLess) && <TableRow key={index}>
                                        {(!isBid && !isBidder) && <TableCell className={classes.tableCell}>
                                            <Stack spacing={1}>
                                                {value.name && <Typography variant='subtitle2' color='primary' style={{fontWeight: 'bold'}}>{value.name}</Typography>}
                                                <Typography variant='subtitle2' id={`bidderid-${index}`}>
                                                    { (auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && !isAdmin &&
                                                        !(isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))) ? '--' : value.bidderId
                                                    }
                                                </Typography>
                                            </Stack>
                                        </TableCell>}
                                        {!isBid && <TableCell className={classes.tableCell}>
                                            <Typography variant='subtitle2' id={`bid-id-${index}`}>{value.id}</Typography>
                                            <Typography variant='subtitle2' id={`ip-address-${index}`}>
                                                { (auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && !isAdmin && !isBidder &&
                                                    !(isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))) ? '--' : `(${value.ipAddress})`
                                                }
                                            </Typography>
                                        </TableCell>}
                                        {!isBid && <TableCell align='left' className={classes.tableCell}>
                                            {enumListOptions.BidStatus.map((status, index) => {
                                                if (status.dateBaseCode === value.bidStatus)
                                                    return <Chip label={status.uiCode} size='small' id={`bid-status-${index}`} className={
                                                        status.dateBaseCode === BidStatus.bidNotConfirmed ? classes.warningChip :
                                                            (status.dateBaseCode === BidStatus.bidConfirmed ? classes.successChip : classes.errorChip)} />
                                            })}
                                        </TableCell>}
                                        {!isBid && <TableCell align='left' className={classes.tableCell}>
                                            <Typography variant='subtitle2' id={`bid-remarks-${index}`}>{getRemarksValue(value.remarks)}</Typography>
                                        </TableCell>}
                                        <TableCell align='left' className={isBid ? classes.bidTableCell : classes.tableCell}>
                                            { (auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && !isAdmin && !isBidder &&
                                                !(isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))) ? '--' :
                                                (<>
                                                    { value.performedByInString !== null && <Typography variant='subtitle2' id={`performedbyname-${index}`} color='primary' style={{ fontWeight: 'bold' }}>{value.performedByInString}</Typography>}
                                                    <Typography variant='subtitle2' id={`performedby-${index}`}>{value.performedBy}</Typography>
                                                </>)
                                            }
                                        </TableCell>
                                        {!isBid && <TableCell align='center' className={classes.tableCell}>
                                            <Typography variant='subtitle2' id={`bid-date-${index}`}>{format(new Date(value.startTime), 'dd MMM yyyy')}</Typography>
                                            <Typography variant='subtitle2' id={`bid-time${index}`}>{format(new Date(value.startTime), 'hh:mm:ss:SSS a')}</Typography>
                                        </TableCell>}
                                        {isBid && <TableCell align='center' className={classes.bidTableCell}>
                                            <Typography variant='subtitle2' id={`bid-date-${index}`}>{format(new Date(value.startTime), 'dd MMM yyyy hh:mm:ss:SSS a')}</Typography>
                                        </TableCell>}
                                        <TableCell align='right' className={isBid ? classes.bidTableCell : classes.tableCell}>
                                            <Typography variant='subtitle2' id={`bid-amt-${index}`}>{CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, value.amount)}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                            {/* {showLess && count > 3 && <TableRow key={3}>
                                <TableCell colSpan={3} sx={{ textAlign: 'center', padding: '1px'}}>
                                    <Button sx={{ padding: '0px', textTransform: 'capitalize', width: '100%' }} id='viewMoreBidHistory' onClick={() => setBidHisDialogOpen(true)} color='primary'>{t('View_More')}</Button>
                                </TableCell>
                            </TableRow>} */}
                        </TableBody>
                    </Table>
                    {count > 0 && pagination && !isBid &&
                        <BottomNavigation className={classes.pagination}>
                            <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={count}
                                onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage} />
                        </BottomNavigation>
                    }
                    {(!isBid && (loadingHistory || loadingBidderBidHistory)) && <AuctionLoader show={(loadingHistory || loadingBidderBidHistory)} invisible={false} />}
                </TableContainer>
            {errorHistory && <GraphQLErrors error={errorHistory} show={false} />}
            {errorBidderBidHistory && <GraphQLErrors error={errorBidderBidHistory} show={false} />}
        </div >
    );
}
export default BidHistory