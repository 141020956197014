import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    Divider,
    Typography,
    Link,
	Stack
}                               from '@mui/material';
import { makeStyles }           from '@mui/styles';
import { useTranslation }       from 'react-i18next';
import TouchAppTwoToneIcon from '@mui/icons-material/TouchAppTwoTone';

const useStyles = makeStyles((theme) => ({
    nextBtn: {
        // float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

export const NbidRedirectDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    

    return (
        <Container maxWidth='md'>
            <Dialog open={open} fullWidth maxWidth='md'>
                {/* <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                    <Typography variant="h6" gutterBottom style={{fontWeight: 'bold'}}>
                        {t('Old_Auctions_NBID')}
                    </Typography>
                </DialogTitle> */}
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} justifyContent='center'
                                sx={{ background: 'whitesmoke', padding: '20px', borderRadius: '10px'}}>
                                <Typography id='nbid-redirect' variant='subtitle1'>{t('Nbid_Old_Auction_Redirect_Message')}</Typography>
                                <Stack direction='row' spacing={0} justifyContent='center'>
                                    <Link target="_parent" href={process.env.REACT_APP_NBID_URL} style={{ cursor: 'pointer' }} >
                                        <span style={{verticalAlign: 'super'}}>{t('Please_Click_Here')}</span>
                                        <TouchAppTwoToneIcon  color='primary' />
                                    </Link>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Divider />
                    <Grid container>
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <Button id='nBid-redirect-close' size="small" variant="outlined" className={classes.nextBtn} onClick={onClose}>
                                {t('Close')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Container>
    )
}