import { useLazyQuery, useMutation } from '@apollo/client';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/EditOutlined';
import { BottomNavigation, Button, Grid, IconButton, MenuItem, MenuList, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcDownload } from "react-icons/fc";
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { UserContext } from '../../common/context/UserContext';
import { fileRestrictionTypes } from '../../common/FileRestrictionTypes';
import { FileIcon } from '../../common/files-upload/FileIcon';
import UploadDialog from '../../common/files-upload/UploadDialog';
import { fileSizeConversion } from '../../common/FileSizeConversion';
import GraphQLErrors from '../../common/GraphQLErrors';
import AuctionPagination from '../../common/pagination/AuctionPagination';
import { FilePath } from '../../common/StaticVariableDeclaration';
import { CREATE_BIDDER_UPLOAD_DOCUMENTS, UPDATE_BIDDER_UPLOAD_DOCUMENTS } from '../../GraphQL/Mutation';
import { GET_PRE_SIGNED_URL, GET_PRE_SIGNED_URL_SUBMIT_DOC, LIST_FILE_VERSIONS, LIST_REQUIRED_DOCUMENTS } from '../../GraphQL/Queries';
import ReuploadPopUp from '../ReuploadPopUp';

const ReferenceNumberMaxLength = 50;

const useStyles = makeStyles((theme) => ({
    paper: {
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '0px',
        border: "1px solid rgb(238, 238, 238)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        margin: "auto auto ",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 10px !important"
    },
    tableData: {
        color: theme.tableDataColor
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    tableHeadRow: {
        backgroundColor: theme.tableHearderBackgroundColor
    },
    fileNameDivBlock: {
        width: '115px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto',
        float: 'left'
    },
    fileSizeClass: {
        fontSize: '12px',
        color: theme.fileSizeColor,
        marginTop: '2px',
        float: 'right'
    },
    fileTypeColorClass: {
        color: theme.fileTypeDisplayColor,
        margin: 'auto'
    }

}))

const BidderViewDocumentList = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [bidderUploadDocuments, setBidderUploadDocuments] = useState([]);
    const [totalNumberOfDocuments, setTotalNumberOfDocuments] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const userContext = useContext(UserContext).rootContext;
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [fileUploadDialog, setFileUploadDialog] = useState(false);
    const [documentDetail, setDocumentDetail] = useState({});
    const [referenceNumberValue, setReferenceNumberValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const referenceNumberId = 'reference-number-popover';
    const [isReferenceUpdate, setIsReferenceUpdate] = useState(false);
    const [isReupload, setIsReupload] = useState(false);
    const [popOverEvent, setPopOverEvent] = useState(null);
    const [versionList, setVersionList] = useState([]);
    const [templateUri, setTemplateUri] = useState('');
    const [anchorElTemplateDownalod, setAnchorElTemplateDownalod] = useState(null);
    const openTemplateDownalod = Boolean(anchorElTemplateDownalod);
    const templateDownalodId = 'template-download-popover'
    const [anchorElFileDownalod, setAnchorElFileDownalod] = useState(null);
    const openFileDownalod = Boolean(anchorElFileDownalod);
    const fileDownalodId = 'file-download-popover';
    const [restrictedFileTypes, setRestrictedFileTypes] = useState(null);
    const [bidderId, setBidderId] = useState(props.bidderId);
    const showAction = props.showActionColumn ? props.showActionColumn : false;
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const handleClick = (event, document) => {
        setDocumentDetail({});
        setDocumentDetail(document)
        setReferenceNumberValue(document.referenceNumber)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseFileDownload = () => {
        setAnchorElFileDownalod(null);
    }

    const handleCloseTemplateDownload = () => {
        setAnchorElTemplateDownalod(null);
    }

    const [listRequiredDocuments, { loading: fetchingRequiredDocuments, error: errorOnFetchingRequiredDocuments, refetch }] =
        useLazyQuery(LIST_REQUIRED_DOCUMENTS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setBidderUploadDocuments([]);
                if (data.listRequiredDocuments != null) {
                    data.listRequiredDocuments.documents.map(requiredDocument => {
                        setBidderUploadDocuments(prevState => ([
                            ...prevState,
                            {
                                id: requiredDocument.id,
                                name: requiredDocument.name,
                                submitFormat: requiredDocument.submitFormat,
                                requiresDigitalSignature: requiredDocument.requiresDigitalSignature,
                                isMandatory: requiredDocument.isMandatory,
                                s3Uri: requiredDocument.s3Uri,
                                templateS3Uri: requiredDocument.templateS3Uri,
                                startTime: requiredDocument.startTime,
                                endTime: requiredDocument.endTime,
                                addedBy: requiredDocument.addedBy,
                                removedBy: requiredDocument.removedBy,
                                updatedBy: requiredDocument.updatedBy,
                                referenceNumber: requiredDocument.referenceNumber,
                                requiredDocumentBrief: requiredDocument.requiredDocumentBrief,
                                submittedDocumentBrief: requiredDocument.submittedDocumentBrief,
                                toSubmit: requiredDocument.toSubmit,
                                templateS3UriSize: fileSizeConversion(requiredDocument.templateS3UriSize),
                                s3UriSize: fileSizeConversion(requiredDocument.s3UriSize),
                                templateS3UriFileName: requiredDocument.templateS3UriFileName,
                                s3UriFileName: requiredDocument.s3UriFileName,
                                templateS3UriFileLastModifiedTime: requiredDocument.templateS3UriFileLastModifiedTime,
                                s3UriFileLastModifiedTime: requiredDocument.s3UriFileLastModifiedTime
                            }
                        ]))
                    })
                    setBidderId(data.listRequiredDocuments.bidderId);
                    setTotalNumberOfDocuments(data.listRequiredDocuments.count);
                }
            }
        })

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    useEffect(() => {
        listRequiredDocuments({
            variables: {
                auctionId: props.auctionId,
                bidderId: props.bidderId,
                pagination: {
                    page: page,
                    limit: rowsPerPage,
                    asc: false
                },
                showDeleted: false,
                toSubmit: true
            }
        })
    }, [rowsPerPage, page])


    const [getFileDownloaded, { loading: gettingPresignedUrl, error: errorOnGettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: preSignUrlData => {
            if (preSignUrlData.getPresignedUrl) {
                let subURL = preSignUrlData.getPresignedUrl.split("?")[0];
                let fileName = subURL.substring(subURL.lastIndexOf('/') + 1);
                axios({
                    url: preSignUrlData.getPresignedUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    setTimeout(() => {
                        // setDownload(false)
                    }, 1000)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURI(fileName));
                    document.body.appendChild(link);
                    link.click();
                    handleCloseFileDownload();
                    handleCloseTemplateDownload();
                }).catch((error) => {
                    setMessage({ show: true, message: error, severity: 'error' })
                    // setDownload(false)
                })
            }
        }
    })

    const [createBidderDocuments, { loading: creatingRequiredDocument, error: errorOnCreatingRequiredDocument }] =
        useMutation(CREATE_BIDDER_UPLOAD_DOCUMENTS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.createSubmittedDocumentDetails != null) {
                    // props.setOpenPopup(false);
                    showSuccessMsg({ show: true, message: t('Document_Uploaded_Successfully'), severity: 'success' });

                }
            },
            refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const [updateBidderDocuments, { loading: updatingBidderDocuments, error: errorOnUpdatingBidderDocument }] =
        useMutation(UPDATE_BIDDER_UPLOAD_DOCUMENTS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateSubmittedDocument != null) {
                    // props.setOpenPopup(false);
                    handleClose();
                    if (isReferenceUpdate) {
                        setIsReferenceUpdate(false);
                        showSuccessMsg({ show: true, message: t('Reference_Number_Updated_Successfully'), severity: 'success' });
                    } else {
                        showSuccessMsg({ show: true, message: t('Document_Uploaded_Successfully'), severity: 'success' });
                    }
                }
            },
            refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const handleUpload = (documentDetail) => {
        setRestrictedFileTypes(fileRestrictionTypes(documentDetail.submitFormat));
        setDocumentDetail(documentDetail);
        setReferenceNumberValue(documentDetail.referenceNumber)
        if (documentDetail.s3Uri == null) {
            setFileUploadDialog(true);
        } else {
            setSeekConfitmation({
                show: true,
                title: t('Reupload'),
                message: t('Do_You_Want_To_Reupload_With_Same_File_Name'),
                onAgree: () => handleUserInput(true),
                onDisAgree: () => handleUserInput(false),
                isCancel: true
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const handleUserInput = (isReupload) => {
        setIsReupload(isReupload)
        resetSeekConfirmation();
        setFileUploadDialog(true);
    }

    const recordFileUpload = (presignedUrl, referenceNumber) => {
        if (props.auctionId) {
            if (!isReupload && documentDetail.s3UriFileName === null) {
                createBidderDocuments({
                    variables: {
                        auctionId: props.auctionId,
                        documentId: documentDetail.id,
                        presignedUrl: presignedUrl,
                        referenceNumber: referenceNumber,
                        brief: '',
                        bidderId: bidderId,
                        pagination: {
                            limit: rowsPerPage,
                            page: 1,
                            asc: false
                        }
                    }
                })
            } else {
                updateBidderDocuments({
                    variables: {
                        auctionId: props.auctionId,
                        documentId: documentDetail.id,
                        presignedUrl: presignedUrl,
                        referenceNumber: referenceNumber,
                        brief: null,
                        bidderId: bidderId
                    }
                })
            }
        }
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setTimeout(() => {
                setMessage(msg);
            }, 100)
        }
    }

    const update = () => {
        setAnchorEl(null);
        updateBidderDocuments({
            variables: {
                auctionId: props.auctionId,
                documentId: documentDetail.id,
                presignedUrl: null,
                referenceNumber: referenceNumberValue,
                brief: null,
                bidderId: bidderId
            }
        })
    }

    const bidderDocumentReset = () => {
        setIsReupload(false);
    }

    const addReferenceNumber = (event) => {
        let refValue = event.target.value.trimStart();
        if (refValue.length <= ReferenceNumberMaxLength) {
            setReferenceNumberValue('');
            setReferenceNumberValue(refValue.trim() != '' ? refValue : '');
        }
    }

    const handleDownloadTemplate = (event, document, isTemplate) => {
        setPopOverEvent(event.currentTarget);
        setDocumentDetail(document);
        setTemplateUri(isTemplate);
        let uri;
        if (isTemplate == 'Template_Download') {
            uri = document.templateS3Uri;
        } else {
            uri = document.s3Uri;
        }
        getListVersions({
            variables: {
                fileName: uri
            }
        });
    }

    const handleVersionDownload = (event, version) => {
        getFileDownloaded({
            variables: {
                presignedUrl: {
                    fileName: version.key,
                    bucket: process.env.REACT_APP_S3_BUCKET,
                    httpMethod: 'GET',
                    versionId: version.versionId,
                    isVersion: false,
                }
            }
        })
    }

    const [getListVersions, { loading: loadingFileVersions, error: errorOnLoadingFileVersions }] = useLazyQuery(LIST_FILE_VERSIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            let uri;
            if (data.listFileVersions.length > 1) {
                if (templateUri == 'Template_Download') {
                    setAnchorElTemplateDownalod(popOverEvent);
                } else {
                    setAnchorElFileDownalod(popOverEvent);
                }
                setVersionList(data.listFileVersions)
            } else {
                if (templateUri == 'Template_Download') {
                    uri = documentDetail.templateS3Uri;
                } else {
                    uri = documentDetail.s3Uri;
                }
                setPopOverEvent(null);
                getFileDownloaded({
                    variables: {
                        presignedUrl: {
                            fileName: uri,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'GET',
                            versionId: '',
                            isVersion: false,
                        }
                    }
                })
            }
        }
    })

    useEffect( () =>  {
        if(props.setTourGuide) {
            props.setTourGuide({
                triggerEvent: false,
                triggerButton: false
            });
        }
    }, [])

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.paper}>
                <Table aria-label="bidder documents table" stickyHeader>
                    <TableHead className={`${classes.tableHeadRow}`}>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '20%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Document_Type_Accepted_File_Types")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                            style={{ width: '8%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Mandatory")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                            style={{ width: '9%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Digital_Sign")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                            style={{ width: '20%', borderRight: '1px solid #c8d6eb', textAlign: 'left' }}>
                            {t("Template")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                            style={{ width: '20%', borderRight: '1px solid #c8d6eb', textAlign: 'left' }}>
                            {t("Uploaded_File")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '15%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Reference_Number")}
                        </TableCell>
                        {showAction && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{ width: '8%' }}>{t("Actions")}</TableCell>}
                    </TableHead>
                    <TableBody id="bidder-uploaded-document-list">
                        {bidderUploadDocuments.length === 0 &&
                            <TableRow hover>
                                <TableCell id={`bidder-upload-doc-no-records-row`} colSpan={7} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                            </TableRow>
                        }
                        {bidderUploadDocuments.length > 0 && bidderUploadDocuments.map((document, index) => {
                            return (<TableRow key={document.id + index} id={`bidder-upload-doc${index}`}>
                                <TableCell id={`bidder-upload-doc-name${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.name}
                                    <p className={classes.fileTypeColorClass}>{document.submitFormat.length > 0 ? '[' + document.submitFormat.join(", ") + ']' : '-'}</p></TableCell>
                                <TableCell id={`bidder-upload-doc-mandatory${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.isMandatory ? <CheckIcon style={{ fontSize: '28px' }} /> : <React.Fragment>{'-'}</React.Fragment>}</TableCell>
                                <TableCell id={`bidder-upload-doc-digital-sign${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.requiresDigitalSignature ? <CheckIcon style={{ fontSize: '28px' }} /> : <React.Fragment>{'-'}</React.Fragment>}</TableCell>
                                <TableCell id={`bidder-upload-doc-template${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                    <React.Fragment>
                                        {document.templateS3Uri == "" && <div>{'-'}</div>}
                                        {document.templateS3Uri != "" &&
                                            <div style={{ width: 'fit-content', float: 'left' }}>
                                                <div>
                                                    <div className={classes.fileNameDivBlock}>
                                                        <span style={{ position: 'relative', top: '4px' }}><FileIcon style={{ position: 'relative', top: '4px' }} fileName={document.templateS3UriFileName} fontSize={'1rem'}></FileIcon></span>
                                                        <Tooltip title={document.templateS3UriFileName}><i>{' ' + document.templateS3UriFileName}</i></Tooltip>
                                                    </div>
                                                    <span className={classes.fileSizeClass}>{'(' + document.templateS3UriSize + ')'}</span>
                                                </div>
                                                <p style={{ margin: 'auto', clear: 'both' }}><span style={{ height: '0.7rem !important', width: '0.8rem !important', position: 'relative', top: '3px' }}>
                                                    <CalendarMonthIcon style={{ fontSize: '17px' }} /></span>
                                                    {format(new Date(document.templateS3UriFileLastModifiedTime), ' dd MMM yyyy hh:mm:ss')}
                                                </p>
                                            </div>}
                                        {document.templateS3Uri != "" &&
                                            <React.Fragment>
                                                <Tooltip title={t('Download_Template')} arrow>
                                                    <span id={`bidder-upload-doc-template-downlaod${index}`} style={{ float: 'right' }}><FcDownload aria-describedby={templateDownalodId} id='Template-download' style={{ fontSize: '20px', marginTop: '19px', cursor: 'pointer' }} onClick={(event) => handleDownloadTemplate(event, document, 'Template_Download')} /></span>
                                                </Tooltip>
                                                <Popover
                                                    id={templateDownalodId}
                                                    open={openTemplateDownalod}
                                                    anchorEl={anchorElTemplateDownalod}
                                                    onClose={handleCloseTemplateDownload}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuList id="split-button-menu">
                                                        {versionList.map((option, index) => (
                                                            <MenuItem key={index} id={"bidder-upload-template-version" + index}
                                                                onClick={(event) => handleVersionDownload(event, option)}>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontWeight: index === 0 ? 600 : 500 }}>{`Version ${versionList.length - index}`}</Typography>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontSize: '14px', marginleft: '6px', marginBottom: '2px', fontWeight: index === 0 ? 600 : 500 }}>
                                                                    &nbsp;({format(new Date(option.lastModified), ' dd MMM yyyy hh:mm:ss a ')})</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Popover>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                </TableCell>
                                <TableCell id={`bidder-upload-doc-file${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{
                                    <React.Fragment>
                                        {document.s3Uri == null && <div>{'-'}</div>}
                                        {document.s3Uri != null &&
                                            <div style={{ width: 'fit-content', float: 'left' }}>
                                                <div>
                                                    <div className={classes.fileNameDivBlock}>
                                                        <span style={{ position: 'relative', top: '4px' }}><FileIcon style={{ position: 'relative', top: '4px' }} fileName={document.s3UriFileName} fontSize={'1rem'}></FileIcon></span>
                                                        <Tooltip title={document.s3UriFileName}><i>{' ' + document.s3UriFileName}</i></Tooltip>
                                                    </div>
                                                    <span className={classes.fileSizeClass}>{'(' + document.s3UriSize + ')'}</span>
                                                </div>
                                                <p style={{ margin: 'auto', clear: 'both' }}><span style={{ height: '0.7rem !important', width: '0.8rem !important', position: 'relative', top: '3px' }}><CalendarMonthIcon style={{ fontSize: '17px' }} /></span>
                                                    {format(new Date(document.s3UriFileLastModifiedTime), ' dd MMM yyyy hh:mm a')}
                                                </p>
                                            </div>}
                                        {document.s3Uri != null &&
                                            <React.Fragment>
                                                <Tooltip title={t('Download_Uploaded_File')} arrow>
                                                    <span id={`bidder-uload-doc-file-downlaod${index}`} style={{ float: 'right' }}><FcDownload aria-describedby={fileDownalodId} id='file-download' style={{ fontSize: '20px', marginTop: '19px', cursor: 'pointer' }} onClick={(event) => handleDownloadTemplate(event, document, 'File_Download')} /></span>
                                                </Tooltip>
                                                <Popover
                                                    id={fileDownalodId}
                                                    open={openFileDownalod}
                                                    anchorEl={anchorElFileDownalod}
                                                    onClose={handleCloseFileDownload}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuList id="split-button-menu">
                                                        {versionList.map((option, index) => (
                                                            <MenuItem key={index} id={"bidder-upload-download-version" + index}
                                                                onClick={(event) => handleVersionDownload(event, option)}>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontWeight: index === 0 ? 600 : 500 }}>{`Version ${versionList.length - index}`}</Typography>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontSize: '14px', marginleft: '6px', marginBottom: '2px', fontWeight: index === 0 ? 600 : 500 }}>
                                                                    &nbsp;({format(new Date(option.lastModified), ' dd MMM yyyy hh:mm:ss a ')})</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Popover>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }</TableCell>
                                <TableCell id={`biddre-uplaod-doc-refereceNumber${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">
                                    <div>{document.referenceNumber ? document.referenceNumber : '-'}</div>
                                </TableCell>
                                {showAction && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{ width: '20%' }}>
                                    <Tooltip title={document.s3Uri == null ? t('Upload_File_document') : t('Reupload_File_document')} arrow>
                                        <span id={`handle-upload-doc${index}`} style={{ cursor: 'pointer', position: 'relative', top: '10px', padding: '5px' }}><img src={process.env.PUBLIC_URL + './images/fileUpload.png'} height={22} width={22}
                                            onClick={() => handleUpload(document)}></img></span>
                                    </Tooltip>
                                    {document.s3Uri != null && <IconButton id="edit-document" style={{ padding: '5px' }}>
                                        <Tooltip id={`handle-click-ref-number${index}`} aria-describedby={referenceNumberId} title={t('Edit')} onClick={(event) => handleClick(event, document)}>
                                            <EditIcon color='primary' style={{ fontSize: '1.25rem' }} />
                                        </Tooltip>
                                    </IconButton>}
                                    <Popover
                                        id={referenceNumberId}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                    >
                                        <Typography sx={{ margin: 2 }} component="div">
                                            <Grid item xs={12}>
                                                <Tooltip title={t('Reference_Number_For_Documents_Uploaded')} arrow placement='left'>
                                                    <TextField id={`reference-number${index}`} label={t('Reference_Number')} value={referenceNumberValue} onChange={addReferenceNumber} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} style={{ paddingTop: '5px', paddingBottom: '20px' }}>
                                                {!isReferenceUpdate && <Button id={`add-ref-number-button${index}`} style={{ float: 'right', marginBottom: '10px' }}
                                                    disabled={documentDetail.referenceNumber === referenceNumberValue}
                                                    color="primary" variant="contained" className="float-right" onClick={() => {
                                                        setIsReferenceUpdate(true); update();}} >{t('Update')}
                                                </Button>}
                                            </Grid>
                                        </Typography>
                                    </Popover>
                                </TableCell>}

                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
                {totalNumberOfDocuments > 0 &&
                    <BottomNavigation style={{ padding: '12px' }}>
                        <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={totalNumberOfDocuments}
                            onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
                    </BottomNavigation>
                }
            </TableContainer>
            {<UploadDialog
                dialogOpen={fileUploadDialog}
                setDialogOpen={setFileUploadDialog}
                isMultiple={false}
                fileName={props.auctionId + "/" + bidderId + FilePath.uploadingDocumentsFromBidder + '/' + documentDetail.name}
                title={t('Upload_Files')}
                reUploadFile={isReupload}
                onSuccess={recordFileUpload}
                isReferenceNo={true}
                bidderDocumentReset={bidderDocumentReset}
                acceptableFiles={restrictedFileTypes}
                isBidderSubmitDoc={true}
                auctionId={props.auctionId}
                oldFilePath={!isReupload ? documentDetail.s3Uri : null}
            />
            }
            {(fetchingRequiredDocuments || creatingRequiredDocument || updatingBidderDocuments || gettingPresignedUrl || loadingFileVersions) && <AuctionLoader show={(fetchingRequiredDocuments || creatingRequiredDocument || updatingBidderDocuments || gettingPresignedUrl || loadingFileVersions)} invisible={false} />}
            {(errorOnFetchingRequiredDocuments || errorOnCreatingRequiredDocument || errorOnUpdatingBidderDocument || errorOnGettingPresignedUrl || errorOnLoadingFileVersions) && <GraphQLErrors error={errorOnFetchingRequiredDocuments || errorOnCreatingRequiredDocument || errorOnUpdatingBidderDocument
                || errorOnGettingPresignedUrl || errorOnLoadingFileVersions} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity}></AuctionSnackBar>}
            {/* {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />} */}
            {seekConfirmation.show && <ReuploadPopUp open={seekConfirmation.show} title={seekConfirmation.title} handleClose={resetSeekConfirmation}
                message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree}
                agreeLabel={t('Yes_Same_File_Name')} disagreeLabel={t('No_Different_File_Name')} />}
        </React.Fragment>
    );
}

export default BidderViewDocumentList;