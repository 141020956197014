import React, {useState} from 'react';
import { FormHelperText, FormControl, TextField }   from '@mui/material';
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export const NumberFormatController = (props) => {
    const { errors, control, name, rules, label, mandatory, defaultValue, onComplete, disabled} = props;
    // const [currentValue, setCurrentValue] = useState(value)
    return (
        <FormControl variant="standard" fullWidth required={mandatory}> 
            <Controller
                errors={errors}
                control={control}
                name={name}
                rules={rules}
                // defaultValue={defaultValue}
                render={( {field} ) => (
                    <NumericFormat id={name} {...field}
                        inputRef={field.ref}
                        required={mandatory}
                        customInput={TextField}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        decimalScale={2}
                        // prefix={prefix + " "}
                        variant="standard"
                        label={label} 
                        disabled={disabled}
                        onChange={(data) => {
                            // console.log('onChange', data)
                            // setCurrentValue(data.target.value)
                        }}
                        onValueChange={({ value }) => {
                            // console.log('onValueChange', value)
                            field.onChange(Number(value))
                        }}
                        // value={currentValue}
                        onBlur={onComplete}
                    />
                )}
            />
            <FormHelperText>{errors[name] && errors[name].message}</FormHelperText>
        </FormControl>
    );
}