import { Link } from '@mui/material'
import React from 'react';

const PoweredBy = () => {
    return (
        <div style={{
            backgroundColor: '#f5f8fd', borderColor: '#f5f8fd', borderRadius: '25px',
            borderWidth: '1px', color: '#a8b9d5', display: 'inline-flex', fontSize: '0.8rem',
            fontWeight: 'bold', letterSpacing: '0px', lineHeight: 'normal', padding: '5px 18px',
            textAlign: 'center', borderStyle: 'solid'
        }}>
            <Link href='http://www.enkindletech.com' target="_blank" underline='none' rel='noopener noreferrer'
                style={{background: '-webkit-linear-gradient(#FF1E40, #D9B551)', 
                    backgroundClip: 'border-box',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'}}>
                ♥ Powered by ENKINDLE TECHNOLOGIES PVT. LTD.
            </Link>
        </div>
    )
}

export default PoweredBy
