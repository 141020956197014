import { alpha, List, ListItem, ListItemIcon, ListItemText, Stack, SvgIcon } from "@mui/material";
import { styled } from "@mui/styles";

const imgLightUrl = process.env.PUBLIC_URL + '/images/sidebarlight.svg'
const imgDarkUrl = process.env.PUBLIC_URL + '/images/sidebardark.svg'
export const sideBarWidth = '190px';
export const sideBarCollapseWidth = '70px';
export const topBarHeight = '48px';
export const subHeaderHeight = '62px';

export const SideNavBar = styled(List)(({ theme, ismenucollapse }) => ({
    position: 'absolute !important',
    width: ismenucollapse ? sideBarCollapseWidth : sideBarWidth,
    height: `calc(100vh - ${topBarHeight})`,
    padding: '0px !important',
    transition: 'width 0.3s ease',
    zIndex: 100,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundImage: `url(${theme.palette.type === 'light' ? imgLightUrl : imgDarkUrl})`,
    backgroundColor: `${theme.palette.type === 'light' ? '#fff' : 'rgb(25 24 24)'}`,
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
}))

export const SideNavHeader = styled('header')(({ height }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: height ? height : subHeaderHeight,
}))

export const MenuSvgIcon = styled(SvgIcon)(({ theme, menucollapse }) => ({
    position: 'absolute',
    top: `calc(${subHeaderHeight} - 2px)`,
    right: '-8px',
    transform: `${menucollapse ? 'translateY(-50%) rotate(0deg)' : 'translateY(-50%) rotate(180deg)'}`,
    height: '20px !important',
    width: '20px !important',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px',
    cursor: 'pointer',
    zIndex: 1000,
    transition: 'width 0.5s ease'
}))

export const MenuBar = styled('div')(({ gap }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 4px 50px 4px',
    gap: gap,
    overflow: 'auto',
    height: 'calc(100% - 75px)'
    // '&::-webkit-scrollbar': {
    //     display: 'none'
    // }
}))

export const MenuListItem = styled(ListItem)(({ theme, isselected }) => ({
    height: '100%',
    borderRadius: '0px !important',
    transition: 'all 0.6s ease',
    height: 'fit-content',
    padding: '8px !important',
    borderLeft: isselected ? '3px solid #298ce2 !important' : 'inherit',
    backgroundColor: isselected ? theme.palette.type === "light" ? alpha(theme.palette.primary.main, 0.04) + ' !important' : 'rgb(142 162 171 / 20%) !important' : 'inherit',
    '&:hover': {
        backgroundColor: `${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.04) !important' : 'rgb(255 255 255 / 0.16) !important'}`,
        '& .icon': {
            animation: 'times 900ms linear infinite'
        },
    },
    // borderLeft: `${isselected ? '3px solid #298ce2' + ' !important' : 'inherit'}`,
    // backgroundColor: `${isselected ? theme.palette.type === 'light' ? alpha(theme.palette.primary.main, 0.04) + ' !important' : 'rgb(142 162 171 / 20%) !important' : 'transparent'}`,
    '& .icon': {
        transform: 'translateX(0)',
        color: `${isselected ? theme.palette.primary.main : theme.palette.type === "light" ? 'rgb(63, 63, 63) !important' : 'inherit'}`
    },
    '& .text': {
        color: `${isselected ? theme.palette.primary.main : theme.palette.type === "light" ? 'rgb(63, 63, 63) !important' : 'inherit'}`
    },
    '& .typo': {
        fontWeight: `${isselected ? 'bold !important' : theme.palette.type === "light" ? 'rgb(63, 63, 63) !important' : 'inherit'}`,
    },
}))

export const MenuListIcon = styled(ListItemIcon)(() => ({
    // color: '#707070 !important',
    minWidth: '15px !important',
    margin: '4px 0px !important',
}))

export const MenuListText = styled(ListItemText)(() => ({
    // color: '#707070 !important',
    margin: '4px 0px !important',
    transition: 'all 0.3s ease'
}))

export const AuctionContainer = styled('div')(({ iscollapse }) => ({
    position: 'absolute',
    left: iscollapse ? sideBarCollapseWidth : sideBarWidth,
    width: iscollapse ? `calc(100% - ${sideBarCollapseWidth})` : `calc(100% - ${sideBarWidth})`,
    transition: 'all 0.3s ease',
    padding: '0px 10px 10px 10px',
    height: `calc((100vh - ${topBarHeight}) - ${subHeaderHeight})`,
    overflow: 'auto'
}))

export const SubHeaderContainer = styled('div')(({ theme, iscollapse }) => ({
    position: 'relative',
    height: subHeaderHeight,
    background: theme.palette.background.paper,
    minHeight: '48px',
    transition: 'all 0.3s ease',
    padding: '0px 10px',
    boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
    left: iscollapse ? sideBarCollapseWidth : sideBarWidth,
    width: iscollapse ? `calc(100% - ${sideBarCollapseWidth})` : `calc(100% - ${sideBarWidth})`,
}))

export const AuctionSection = styled('section')(({ show }) => ({
    minHeight: '100%',
}))