import React, { useEffect, useState }                           from 'react';
import { Autocomplete, TextField, Grid, List,
        ListItemText,ListItem, Tooltip }                        from '@mui/material';
import { useTranslation }                                       from 'react-i18next';
import SearchIcon                                               from '@mui/icons-material/Search';
import { makeStyles }                                           from '@mui/styles';
import ArrowDropDownIcon                                        from '@mui/icons-material/ArrowDropDown';
import Button                                                   from '@mui/material/Button';
import Popover                                                  from '@mui/material/Popover';
import { SEARCH_PARTY }                                         from '../../GraphQL/Queries';
import { useLazyQuery }                                         from '@apollo/client';
import ListItemButton                                           from '@mui/material/ListItemButton';
import KeyboardArrowDown                                        from '@mui/icons-material/KeyboardArrowDown';
import Box                                                      from '@mui/material/Box';

const useStyles = makeStyles( (theme) => ({
    root: { 
        '& .MuiAutocomplete-endAdornment' : {
            display: 'none'
        },
        '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: '0px !important',
            position: 'relative',
            right: '34px',
            bottom: '2px'
        },
        '& .css-eozd4h-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall' : {
            padding:'6px 6px 5px 6px !important',
            alignContent: 'center !important'
        },
    },
    searchFiled : {
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            height: 36,
            paddingRight: "0px !important",
            borderColor: '#0a4ea2',
            backgroundColor: theme.palette.background.paper
        }
    },
    dropdownBtn: {
        backgroundColor: theme.search.bg,
        position: 'relative !important',
        borderTopRightRadius: '0px !important',
        borderBottomRightRadius: '0px !important',
        color: theme.palette.text.primary,
        textTransform: 'unset !important',
        borderTopLeftRadius: '10px !important',
        borderBottomLeftRadius: '10px !important'
    },
    popOverIcon: {
        minWidth: '100px !important',
        padding: '0px 0px 0px 10px !important'
    },
    gridItem: {
        display: 'flex',
        padding: '4px',
        borderRadius: '10px',
        backgroundColor: theme.search.bg
    },
    titleStyle: {
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        fontWeight: 'bold', 
        whiteSpace: 'nowrap'
    }
}))

const PartySearch  = (props) => {

    const {individual, defaultValue, setDropDownValue, onSelect, cognitoUsers, identificationTypes } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [openIdentification, setIdentificationOpen] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuId = 'primary-search';
    const isMenuOpen = Boolean(anchorEl);
    const [options, setOptions] = useState([]);
    const [searchType, setSearchType] = useState(defaultValue ? defaultValue : '');
    const identificationType = identificationTypes;

    const [getSearchedParty, { loading }] = useLazyQuery(SEARCH_PARTY, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setOptions([]);
            setOptions(data.searchUser.map((user) =>  {
                return  { 
                    id: user.id,
                    name: user.name,
                    identification: user.identification,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    restOfName: user.restOfName,
                    individual: user.individual
                }
            }));
        }
    });

    useEffect(() => {
        if(searchFilter.trim() == ''){
            setOptions([]);
            // onSelect(searchFilter);
        }
        if (searchFilter.trim() != '' && open) {
            getSearchedParty({
                variables: {
                    searchInput : {
                        searchBy: searchType.searchTypeValue,
                        individual: individual,
                        searchText: searchFilter.trim(),
                        cognitoUsers: cognitoUsers,
                        identificationType: searchType.type
                    }
                }
            })
        }
    }, [searchFilter]);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const selectedSearchType = (event) => {
        setSearchFilter('');
        setSearchType(event);
        handleMenuClose();
        setIdentificationOpen(false);
    }

    const setUser = (user) => {
        if (user != '' && (user != null && user.name.trim() != '')) {
            if(searchType.searchTypeValue == 'email') {
                setSearchFilter(user.email)
            } else if(searchType.searchTypeValue == 'name') {
                setSearchFilter(user.name)
            } else if(searchType.searchTypeValue == 'phone_number') {
                setSearchFilter(user.phoneNumber)
            } else if(searchType.searchTypeValue == 'identification') {
                setSearchFilter(user.identification);
            }
            onSelect({
                id: user.id,
                name: user.name,
                identification: user.identification,
                email: user.email,
                phoneNumber: user.phoneNumber
            });
        }
    }

    const handleOnChange = (value) => {
        if(searchType.searchTypeValue == 'phone_number') {
            var regEx = /^[+-]?[\d]*?$/;
            if (regEx.test(value)) {
                setSearchFilter(value);
            }
        } else {
            setSearchFilter(value);
        }
    }

    return (
        <React.Fragment>
                <div className={`${classes.gridItem}`} key={searchType.title} id="search-party-details">
                    <Button id="search-by" style={{minWidth: '33%'}} className={classes.dropdownBtn} onClick={handleClick}>
                        <div className={classes.titleStyle} >{searchType.title}</div>
                         {setDropDownValue != null && <ArrowDropDownIcon/>}  
                    </Button>
                    <Autocomplete
                        id="search"
                        className={classes.searchFiled}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        disableClearable={true}
                        onChange={(event, data) => {
                            if(data != null && data.name.trim() != '') {
                                setUser(data);
                            }
                        }}
                        componentsProps={{
                            paper: {
                              sx: {
                                minWidth: '100%',
                                width: 'fit-content'
                              }
                            }
                        }}
                        loading={loading}
                        getOptionLabel={(option) => option.name + ' (' + (searchType.searchTypeValue == 'email' || searchType.searchTypeValue == 'name' ?  option.email : (searchType.searchTypeValue == 'phone_number') ?  option.phoneNumber : option.identification) +  ') '}
                        renderOption={(props, option, state) => {
                            props.key = option.id;
                            return <li id={option.id} {...props}>
                                { option.name + ' (' + (searchType.searchTypeValue == 'email' || searchType.searchTypeValue == 'name' ?  option.email : (searchType.searchTypeValue == 'phone_number') ?  option.phoneNumber : option.identification) +  ') '}
                            </li>
                        }}
                        options={options}
                        inputValue={searchFilter}
                        fullWidth
                        renderInput={(params) => (
                        <Tooltip placement='top'
                            title= {searchType.searchTypeValue != 'phone_number' ? "search is case-sensitive" : ''}>
                            <TextField className={`${classes.root}`} {...params}
                                onChange={(e) => {
                                    if(e.target.value == '') {
                                        handleOnChange(e.target.value);
                                        // setSearchFilter(e.target.value);
                                    } else {
                                        handleOnChange(e.target.value.trimStart());
                                        // setSearchFilter(e.target.value.trimStart())
                                    }
                                }}
                                variant='outlined'
                                size='small'
                                style={{position: 'relative', bottom: '2px !important'}}
                                placeholder={searchType.placeholder}
                            /> 
                            </Tooltip>
                            
                        )}
                    /> 
                    <SearchIcon style={{margin: 'auto 6px', color: '#2076d2'}}/>
                </div>
                {setDropDownValue != null && <Popover style={{width: '200px'}}
                    id={menuId}
                    open={isMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: { width: '100%' },
                    }}   
                >
                <Grid container>
                    <Grid item xs={12}>
                        <List component="nav" aria-label="Search">
                            {setDropDownValue.map((searchType, index) => {
                                return (<ListItem id={`search-by${searchType.title}`} button className={classes.popOverIcon}
                                    onClick={() => selectedSearchType(searchType)} key={index}>
                                    <ListItemText primary={t(searchType.title)} ></ListItemText>                                
                                </ListItem>)
                            })}
                            {identificationType != null && 
                            <Box> 
                                <ListItemButton
                                    alignItems="flex-start"
                                    onClick={() => setIdentificationOpen(!openIdentification)}
                                    sx={{
                                        float: 'left',
                                        padding: '0px 0px 5px 10px !important',
                                        width: '100%',
                                        px: 3,
                                        pb: openIdentification ? 0 : 2.5,
                                        '&:hover, &:focus': { '& svg': { opacity: openIdentification ? 1 : 0 } },
                                    }}>
                                    <ListItemText
                                        primary={'Identification'}
                                        style={{fontSize: '17px !important'}} />
                                        <KeyboardArrowDown
                                            sx={{
                                                mr: 2,
                                                mt: 0.5,
                                                opacity: 0,
                                                transform: openIdentification ? 'rotate(-180deg)' : 'rotate(0)',
                                                transition: '0.2s',
                                            }}
                                            />
                                </ListItemButton>
                                {openIdentification && 
                                    identificationType.map((identification, index) => (
                                        <ListItem id="search_by_Identification" button className={classes.popOverIcon}
                                            onClick={() => selectedSearchType(identification)} key={index}>
                                        <ListItemText
                                        primary={identification.title}
                                        primaryTypographyProps={{ fontSize: 15, fontWeight: 'medium', padding: '0px 0px 0px 30px' }}
                                        />
                                        </ListItem>
                                    ))}       
                            </Box>
                            }
                        </List>
                    </Grid>
                </Grid>
            </Popover>} 
    </React.Fragment>
    )
}

export default PartySearch;