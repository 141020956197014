import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Card, CircularProgress, Dialog, Grid, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionReCaptcha from '../../common/AuctionReCaptcha';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDialog';
import { UserContext } from '../../common/context/UserContext';
import PasswordPolicy, { LowerLetter, MinPwdLength, NumberCheck, SpecialChar, UpperLetter, MaxPwdLength } from '../../common/PasswordPolicy';
import { ContentBox, IMG, JustifyBox, RootDiv } from '../AuthStyle';

const CompleteNewPassword = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const context = useContext(UserContext);
    const [open, setOpen] = useState(false)
    const [pwdLength, setPwdLength] = useState(false);
    const [maxPwdLength, setMaxPwdLength] = useState(false);
    const [isUpper, setIsUpper] = useState(false);
    const [isLower, setIsLower] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [restPwd, setResetPwd] = useState({
        errorMessage: '',
        showErrorMessage: false,
        showConfirmation: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false,
        password: '',
        confirmpassword: '',
        errors: {},
        isValidForm: false
    })
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [loading, setLoading] = useState(false)

    const toggleShowPassword = () => setStateData('showPassword', !restPwd.showPassword);
    const toggleShowConfirmPassword = () => setStateData('showConfirmPassword', !restPwd.showConfirmPassword);

    const handlePopoverClose = () => setOpen(false);

    const onInputChange = (event) => {
        setResetPwd(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
        if (restPwd.password !== event.target.value) {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: t('Password_does_not_match')
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: ""
            }))
        }
    }

    const onPasswordChange = (event) => {
        const pwdValue = event.target.value
        setResetPwd(prevState => ({
            ...prevState,
            [event.target.id]: pwdValue
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
        setPwdLength(pwdValue.length > MinPwdLength ? true : false);
        setMaxPwdLength(pwdValue.length < MaxPwdLength ? true : false);
        setIsUpper(UpperLetter.test(pwdValue) ? true : false);
        setIsLower(LowerLetter.test(pwdValue) ? true : false);
        setIsNumber(NumberCheck.test(pwdValue) ? true : false);
        setIsSpecial(SpecialChar.test(pwdValue) ? true : false);
        setOpen(event.target);
        if (restPwd.confirmpassword !== '' && restPwd.confirmpassword !== event.target.value) {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: t('Password_does_not_match')
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: ""
            }))
        }
    }

    const setStateData = (id, value) => {
        setResetPwd(prevState => ({
            ...prevState,
            [id]: value
        }))
        setTouched(prevState => ({
            ...prevState,
            [id]: true,
        }))
    }

    const login = () => {
        history.push("/sign-in")
    }

    const completeNewPasswordSubmit = async () => {
        setLoading(true);
        if (captcha != '') {
            try {
                Auth.completeNewPassword(props.user, restPwd.password)
                    .then((res) => {
                        setLoading(false);
                        if (res) {
                            // context.setUserSession({
                            //     isAuthenticated: true, session: {
                            //         accessToken: res.signInUserSession.idToken.jwtToken,
                            //         name: res.challengeParam.userAttributes.name,
                            //         email: res.challengeParam.userAttributes.email,
                            //         phone_number: res.challengeParam.userAttributes.phone_number,
                            //         userId: res.username
                            //     },
                            //     roles: {
                            //         agent: true,
                            //         auctioneer: true
                            //     }
                            // });
                            context.setRootContext(prevState => ({
                                ...prevState,
                                userSession: {
                                    isAuthenticated: true, session: {
                                        accessToken: res.signInUserSession.idToken.jwtToken,
                                        name: res.challengeParam.userAttributes.name,
                                        email: res.challengeParam.userAttributes.email,
                                        phone_number: res.challengeParam.userAttributes.phone_number,
                                        userId: res.username
                                    },
                                    roles: {
                                        agent: true,
                                        auctioneer: true
                                    }
                                }
                              })
                            )
                            sessionStorage.setItem('token', res.signInUserSession.idToken.jwtToken);
                            props.getUser();
                            setLoading(true)
                            history.push({
                                pathname: "/auction-list",
                                state: {
                                    userRole: [],
                                    successMessage: {
                                        show: true,
                                        message: t('Yours') + ' ' + t('_account_new_password_is_set_successfully')
                                    },
                                    pagination: {
                                        page: 1,
                                        pageSize: 10
                                    },
                                    isMyBid: false
                                }
                            })
                        }
                    })
            }
            catch (error) {
                setLoading(true);
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        } else {
            setLoading(true);
            setMessage({ show: false, message: null, severity: null });
            setTimeout(() => {
                setLoading(false);
                setMessage({ show: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
            }, 1000);
        }
    }

    return (
        <Dialog open={props.open} >
            <Card className='card'>
                <Stack direction='row'>
                    <Stack spacing={1} p={4} width='100%'>
                        <Typography id='newpasswordhead' component="h1" variant="h5" gutterBottom>
                            {t('Set_your_New_Password')}
                        </Typography>
                        <br></br>
                        <TextField id="password" label={t('New_Password')} type={restPwd.showPassword ? "text" : "password"}
                            style={{ margin: '8px 8px 8px 0px' }} value={restPwd.password}
                            onChange={onPasswordChange}
                            // onClick={handlePopoverOpen}
                            onBlur={handlePopoverClose}
                            autoComplete="new-password"
                            helperText={touched['password'] && errors.password}
                            error={touched['password'] && errors.password}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                        >
                                            {restPwd.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <PasswordPolicy open={open} pwdLength={pwdLength} isUpper={isUpper} isLower={isLower} isNumber={isNumber} isSpecial={isSpecial} maxPwdLength={maxPwdLength} />
                        <TextField id="confirmpassword" label={t('Confirm_Password')} type={restPwd.showConfirmPassword ? "text" : "password"}
                            style={{ margin: '8px 8px 8px 0px' }} value={restPwd.confirmpassword}
                            onChange={onInputChange}
                            autoComplete="new-password"
                            helperText={touched['confirmpassword'] && errors.confirmpassword}
                            error={touched['confirmpassword'] && errors.confirmpassword}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowConfirmPassword}
                                        >
                                            {restPwd.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button variant="contained" color="primary" style={{ marginTop: '15px' }}
                            onClick={completeNewPasswordSubmit} disabled={loading}>
                            {t('Submit')}
                            {loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                        </Button>
                        <Grid item xs={12} className="text-center" style={{ marginTop: '15px' }}>
                            <Link onClick={props.handleClose} style={{ fontSize: '12px', cursor: 'pointer' }}>{t('Back_to_Sign_In')}</Link>
                        </Grid>
                    </Stack>
                    <Stack width={'55%'} justifyContent='center' alignItems='center'>
                        <JustifyBox p={4} height="100%">
                            <img
                                width='100%'
                                src={process.env.PUBLIC_URL + '/images/newpassword.svg'}
                                alt=""></img>
                        </JustifyBox>
                    </Stack>
                </Stack>
            </Card>
            {restPwd.showErrorMessage &&
                <AuctionSnackBar show={restPwd.showErrorMessage} message={restPwd.errorMessage} severity="error" reset={() => setStateData('errorMessage', null)}></AuctionSnackBar>}
            {loading && <AuctionLoader show={true} />}
            {message &&
                <AuctionSnackBar show={message.show} message={message.message} severity={message.severity}></AuctionSnackBar>}
            {/* {restPwd.showConfirmation &&
                <ConfirmationDialog title="New password set successfully" show={restPwd.showConfirmation}
                    description={[t('Your_'), <strong>{t('App_Name')}</strong>, t('_account_new_password_is_set_successfully_Please_SignIn_with_NewPassword')]} redirect={true} redirectUrl="/sign-in" />} */}
            {!isRefresh && <AuctionReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
        </Dialog>
    )
}

export default CompleteNewPassword
