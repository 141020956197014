
import React from 'react';

const MillisecondsToDuration = (date1, date2) => {

  const differenceInMilliseconds = date2 - date1;
  const days = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000));
  const remainingMilliseconds = differenceInMilliseconds % (24 * 60 * 60 * 1000);
  const hours = Math.floor(remainingMilliseconds / (60 * 60 * 1000));
  const minutes = Math.floor((remainingMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
  return {
    days: days > 0 ? days : 0,
    hours: hours > 0 ? hours : 0,
    minutes: minutes > 0 ? minutes : 0
  }
};

export default MillisecondsToDuration;