import React                        from 'react';
import {
    Grid
}                                   from '@mui/material';
import ReportCard                   from './ReportCard';
import { AuctionReportTypes }       from '../../common/StaticVariableDeclaration';

const ReportList = ({auctionId}) => {

    let typeOfReports = [
        {type: AuctionReportTypes.auctionHall, reportCd: 2, name: 'Auction_Hall_Report', description: 'Auction_Hall_Report_Description', reportName: 'Auction Hall Report'},
        {type: AuctionReportTypes.biddingHistory, reportCd: 4, name: 'Bidding_History_Report', description: 'Bidding_History_Report_Description', reportName: 'Bid History Report'},
        {type: AuctionReportTypes.h1Report, reportCd: 3, name: 'H1_Report', description: 'H1_Report_Description', reportName: 'H1 Report'},
        {type: AuctionReportTypes.auctionSummary, reportCd: 5, name: 'Auction_Summary_Report', description: 'Auction_Summary_Report_Description', reportName: 'Auction Summary Report'}
    ]

    return (
        <div>
            <Grid container>
                {
                    typeOfReports.length > 0 && typeOfReports.map((report, index) => {
                        return <Grid key={index} item lg={3} md={4} sm={6} xs={12} style={{padding: '12px'}}>
                            <ReportCard name={report.name} type={report.type} auctionId={auctionId}
                                    description={report.description} reportName={report.reportName}
                                    reportCd={report.reportCd}/>
                        </Grid>
                    })
                }
            </Grid>
        </div>
    )
}

export default ReportList;