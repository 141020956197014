import React, {useState} from "react";
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Grid, Typography,
        Divider, DialogActions, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import {CREATE_SELLER} from '../../GraphQL/Mutation';
import { LIST_SELLER_DETAILS } from '../../GraphQL/Queries';
import { useMutation } from '@apollo/client';
import CreatePartyDetails from '../party-details/CreatePartyDetails';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import { IdentificationTypes, PartyType, AddressType } from '../../common/StaticVariableDeclaration';
import TourIcon from '@mui/icons-material/Tour';

const AuctionSellerCreate = (props) => {

    const { t } = useTranslation();
    const [tourGuide, setTourGuide] = useState({
        triggerEvent: false,
        triggerButton: false
    });

    const { register: register, getValues, trigger, resetField, setValue, watch, reset ,control, unregister, clearErrors,
        formState: { errors, isValid }} = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        defaultValues: {
            individual: false,
            identificationType: IdentificationTypes.cin,
            name: '',
            identification: '',
            otherIdentificationType: '',
            emailId: '',
            phoneNumber: '',
            phone: '',
            personId: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',
            attention: '',
            dobDoi: null,
            addressType: AddressType.primaryContact,
            userId: '',
            cityTown: '',
            postalCode: '',
            province: '',
            country: 'India',
            addressId: '',
        }
    })

    const [createSellerDetails, {loading: creatingSellerDetails, error: errorOnCreatingSellerDetails}] =
            useMutation(CREATE_SELLER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addSeller != null) {
                    props.onSuccess({show: true, message: t('Seller_Added_Successfully'), severity: 'success'});
                    props.setCancel(true);
                    props.handleGetAuctionDetail();
            }
        },
        refetchQueries: [LIST_SELLER_DETAILS]
    })

    const handleSave = () => {
        createSellerDetails({
            variables: {
                auctionId: props.auctionId,
                sellerInput : {
                    personId: getValues('personId') !== '' ? getValues('personId') : null,
                    individual: getValues('individual'),
                    name: getValues('name'),
                    restOfName: null,
                    dobDoi: getValues('dobDoi'),
                    identification: getValues('identification') ? {
                        id: null,
                        identificationType: getValues('identificationType'),
                        otherIdentificationType: getValues('otherIdentificationType') !== '' ? getValues('otherIdentificationType'): null,
                        identification: getValues('identification')
                    } : [],
                    address: {
                        addressId: getValues('addressId'),
                        attention: getValues('attention'),
                        phone: getValues('phone'),
                        email: getValues('email'),
                        addressLine1: getValues('addressLine1'),
                        addressLine2: getValues('addressLine2'),
                        landmark: getValues('landmark'),
                        cityTown: getValues('cityTown'),
                        postalCode: getValues('postalCode'),
                        state: getValues('state'),
                        country: getValues('country'),
                        addressType: getValues('addressType')
                    },
                    email: {
                        id: null,
                        email: getValues('emailId'),
                        preferred: true
                    },
                    phoneNumber: {
                        id: null,
                        phoneNumber: getValues('phoneNumber'),
                        preferred: true
                    }
                }
            }
        })

    }

    const handleCancel = () => {
        props.setCancel(true);
    }

    const onSelectedPartyType = (type) => {
        setValue('individual', type);
    }

    const updateTourGuide = () => {
        setTourGuide({
            triggerEvent: false,
            triggerButton: true
        });
        setTimeout(() => {
            setTourGuide({
                triggerEvent: true,
                triggerButton: true
            });
        }, 0);
    }

    return (
        <Dialog open={props.openPopup} maxWidth='lg' fullWidth scroll='paper'>
            <DialogTitle style={{ borderBottom: '1px solid #e0e0e0', textAlign: 'center'}}>
                <Grid container>
                    <Typography variant="h6" gutterBottom color='primary' style={{ fontWeight: 'bold', width: '100%' }}>
                        {t('Seller_Details')}
                    </Typography>
                    {tourGuide.triggerButton && <Button
                        id='guide-me-link'
                        variant="contained"
                        startIcon={<TourIcon />}
                        sx={{ textTransform: 'none' }}
                        onClick={updateTourGuide}
                        className='guide-me'
                    >{t('Guide_Me')}
                    </Button>}
                </Grid>
            </DialogTitle>
            <DialogContent sx={{padding: '0px !important'}}>
                <Box mt={4} mb={4} mx={3}>
                    <CreatePartyDetails register={register} getValues={getValues} control={control} watch={watch} errors={errors}
                        setValue={setValue} isValid={isValid} onSelectedPartyType={onSelectedPartyType} trigger={trigger} resetField={resetField}
                        reset={reset} unregister={unregister} clearErrors={clearErrors} tourGuide={tourGuide} setTourGuide={setTourGuide}
                        tourButtonVisibility={true}>
                    </CreatePartyDetails>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'right' }}>
                <Button onClick={handleCancel} id="cancel" variant="outlined">Cancel</Button>
                <Button onClick={handleSave} disabled={!isValid} id="save" variant='contained' color='primary' >{t('Save')}</Button>
            </DialogActions>
            {(creatingSellerDetails) && <AuctionLoader show={(creatingSellerDetails)}
                    invisible={false} />}
            {(errorOnCreatingSellerDetails) && <GraphQLErrors error={(errorOnCreatingSellerDetails)} show={false} />}
        </Dialog>
    );
}

export default AuctionSellerCreate;