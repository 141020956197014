import React,{
    useState
}                               from 'react';
import {
    Container,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Grid,
    Button,
    RadioGroup,
    Card,
    FormControlLabel,
    Radio
}                               from '@mui/material';
import { makeStyles }           from '@mui/styles';
import { useTranslation }       from 'react-i18next';
import AuctionLoader            from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar          from '../../common/AuctionSnackBar';
import { AuthAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '4px !important'
    },
    btnRight: {
        float: 'right'
    },
    selectedMFA: {
        border: '1px solid #2a83ad',
        background: '#2a83ad0a !important'
    }
}))

const EnableMFA = ({show, userId, onClose, onSuccess}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const [loading, setLoading] = useState(false);
    const [mfaCd, setMfaCd] = useState("1");
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const handleMFACdChange = (event) => {
        setMfaCd(event.target.value);
    }

    const enableMFA = () => {
        let payload = {
            mfaCd: Number(mfaCd)
        }
        setLoading(true);
        AuthAxiosAuctionInterceptor.post(`mfa/${userId}`, payload).then(response => {
            setLoading(false);
            onSuccess('Two_Step_Verification_Enabled_Successfully', true);
            handleCancel();
        }).catch(error => {
            setMessage({show: false, message: '', severity: ''});
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const handleCancel = () => {
        onClose();
        setOpen(false)
    }

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='md'>
                <DialogTitle style={{ paddingTop: '10px', fontWeight: 'bold' }}>
                    {t('Enable_Two_Step_Verification')}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography id="enable-two-factor-auth-sub-title" variant="subtitle1" gutterBottom>
                                {t('Enable_Two_Step_Verification_SubTitle')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <RadioGroup row aria-label="mfa-cd" name="role" value={mfaCd} onChange={handleMFACdChange}>
                                <Grid item xs={6} style={{textAlign: 'center'}}>
                                    <Card style={{margin: '4px', padding: '4px', display: 'flex', flexDirection: 'row', paddingTop: '36px', paddingBottom: '36px'}}
                                            className={mfaCd === "1" ? classes.selectedMFA : ''}>
                                        <div style={{width: '100%'}}>
                                            <FormControlLabel value={1} control={<Radio color="primary"/>} label={<span style={{display: 'flex'}}><EmailIcon style={{marginRight: '6px'}}/>{t("Email")}</span>} style={{fontWeight: 'bold'}}/>
                                            <br/>
                                            <Typography id="verification-send-to-email-msg" variant="body2" gutterBottom style={{color: 'gray'}}>
                                                {t('Verification_Send_To_Email_Msg')}
                                            </Typography>
                                        </div>
                                        
                                    </Card>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'center'}}>
                                    <Card style={{margin: '4px', padding: '4px', display: 'flex', flexDirection: 'row', paddingTop: '36px', paddingBottom: '36px'}}
                                            className={mfaCd === "2" ? classes.selectedMFA : ''}>
                                        <div style={{width: '100%'}}>
                                            <FormControlLabel value={2} control={<Radio color="primary"/>} label={<span style={{display: 'flex'}}><SmartphoneIcon style={{marginRight: '6px'}}/>{t("SMS")}</span>} style={{fontWeight: 'bold'}}/>
                                            <br/>
                                            <Typography id="verification-send-to-sms-msg" variant="body2" gutterBottom style={{color: 'gray'}}>
                                                {t('Verification_Send_To_SMS_Msg')}
                                            </Typography>
                                        </div>
                                    </Card>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='enable-mfa' size="small" variant="contained" onClick={enableMFA}
                                    className={`${classes.button} ${classes.btnRight}`}>
                                {t('Enable')}
                            </Button>
                            <Button id='cancel' size="small" variant="outlined" onClick={handleCancel} 
                                    className={`${classes.button} ${classes.btnRight}`}>
                                {t('Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                {loading && <AuctionLoader show={loading} invisible={false} />}
            </Dialog>
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </Container>
    )
}

export default EnableMFA;