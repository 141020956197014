import React, { 
    useState, 
    useContext,
    useEffect
}                                   from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    Checkbox,
    ListItemText,
    Tooltip,
    Fab
}                                   from '@mui/material';
import { useWatch }                 from 'react-hook-form';
import { makeStyles }               from '@mui/styles';
import { useTranslation }           from 'react-i18next';
import { Validation }               from '../../common/Validation';
import { UserContext }              from "../../common/context/UserContext";  
import { SelectControl }            from "../../custom-form-control/SelectControl";  
import { 
    AuctionFieldSet, 
    AuctionLengend 
}                                   from '../../authentication/AuthStyle';
import { Check, Clear, Edit }       from '@mui/icons-material';
import { UPDATE_ITEM }              from '../../GraphQL/Mutation';
import { LIST_ITEMS }               from '../../GraphQL/Queries';
import { useMutation }              from '@apollo/client';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../common/GraphQLErrors';
import AuctionSnackBar              from '../../common/AuctionSnackBar';

const useStyles = makeStyles((theme) => ({
    // itemSelect: {
    //     "& #itemType": {
    //         textOverflow: 'ellipsis',
    //         overflow: 'hidden',
    //         width: '75vw'
    //     }
    // },
    shadow: {
        boxShadow: theme.boxShadow
    },
    topFab: {
        width: '34px !important',
        height: '34px !important',
        background: `${theme.palette.background.paper} !important`,
        zIndex: 'unset !important'
    },
    checkFab: {
        border: `1px solid ${theme.palette.success.main} !important`,
        '&[disabled]': {
            border: `1px solid ${theme.palette.text.secondary} !important`,
            pointerEvents: 'none',
            boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important'
        }
    },
    closeFab: {
        border: `1px solid ${theme.palette.error.main} !important`
    },
    itemType: {
        margin: theme.spacing(1),
        width: '31vw'
    },

}))

const ItemDetail = ({register, control, errors, getValues, setValue, trigger, isNew, auction_id,
        getItemBidDetailValues, isValidForm, canEdit}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const [selectedItemTypes, setSelectedItemTypes] = useState(getValues('itemType'));
    const itemType = useWatch({ control, name: 'itemType' });
    const [isEdit, setIsEdit] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [tempValue, setTempValue] = useState('');

    const [updateItem, {loading: updatingItem, error: errorOnUpdatingItem}] = useMutation(UPDATE_ITEM, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateItem != null) {
                setMessage({
                    show: true,
                    message: t('Item_Updated_Successfully'),
                    severity: 'success'
                })
                setIsEdit(false);
            }
        },
        refetchQueries: [LIST_ITEMS]
    })

    const onThumbnailImageUpload = (event) => {
        setThumbnailImage({
            file: event.target.files[0],
            url: URL.createObjectURL(event.target.files[0])
        })
    }

    const removeThumbnail = () => {
        setThumbnailImage(null)
    }

    const handleItemTypeChange = (event) => {   
        setSelectedItemTypes(event.target.value);
        setValue('itemType', event.target.value);
    };

    useEffect(() => {
        trigger('itemType');
    }, [itemType])

    const enableEdit = () => {
        setIsEdit(true);
        setTempValue(getValues());
    }

    const cancelEdit = () => {
        setValue('itemName', tempValue.itemName);
        setValue('itemRefNo', tempValue.itemRefNo, {shouldValidate: true});
        setValue('description', tempValue.description, {shouldValidate: true});
        setValue('itemType', tempValue.itemType, {shouldValidate: true});
        setValue('quantity', tempValue.quantity, {shouldValidate: true});
        setValue('unit', tempValue.unit, {shouldValidate: true});
        setSelectedItemTypes(tempValue.itemType);
        trigger();
        setTempValue('');
        setIsEdit(false);
    }
    
    const updateItemValues = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
        updateItem({
            variables: {
                auctionId: auction_id,
                itemInput: {
                    id: getValues('id'),
                    itemName: getValues('itemName') ? getValues('itemName') : null,
                    itemRefNo: getValues('itemRefNo') ? getValues('itemRefNo') : null,
                    description: getValues('description'),
                    itemType: getValues('itemType'),
                    quantity: Number(getValues('quantity')),
                    unit: getValues('unit') ? getValues('unit') : null
                }
            }
        })
    }

    return (
        <div>
            <AuctionFieldSet>
                <AuctionLengend align="left"><strong>{t('Item_Details')}</strong></AuctionLengend>
                <Grid container spacing={2}>
                    {(!isNew && canEdit) &&<Grid item xs={12} sx={{paddingTop: '0px !important'}}>
                        {!isEdit && <span style={{float: 'right', position: 'relative'}}>
                            <Tooltip title={t('Edit')}>
                                <Fab id="edit-item" className={`${classes.topFab} ${classes.editFab}`} color='primary' 
                                    onClick={enableEdit}>
                                <Edit color='primary' />
                                </Fab>
                            </Tooltip>
                        </span>}
                        {isEdit && <span style={{float: 'right', position: 'relative'}}>
                            <Tooltip title={t('Save')}>
                                <Fab id="save-item" className={`${classes.topFab} ${classes.checkFab}`} style={{position: 'relative', right: '10px'}} color='success'
                                        onClick={updateItemValues} disabled={!isValidForm || updatingItem}>
                                    <Check color='success' />
                                </Fab>
                            </Tooltip>
                            <Tooltip title={t('Cancel')}>
                                <Fab id="cancel-edit-item" className={`${classes.topFab} ${classes.closeFab}`} color='error' onClick={cancelEdit}>
                                    <Clear color='error' />
                                </Fab>
                            </Tooltip>
                        </span>}
                    </Grid>}
                    <Grid item xs={1}>
                        <TextField id="item-id" label={t('Item_Id')} 
                            {...register("itemRefNo", Validation.required)}
                            helperText={errors["itemRefNo"] && errors["itemRefNo"].message}
                            variant="standard" fullWidth required={isNew || isEdit}
                            disabled={!isNew && !isEdit}
                            />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="itemName" label={t('Item_Name')} 
                            {...register("itemName", Validation.required)}
                            helperText={errors["itemName"] && errors["itemName"].message}
                            variant="standard" fullWidth required={isNew || isEdit}
                            disabled={!isNew && !isEdit}/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            className={classes.itemType}
                            id="itemType"
                            select
                            label={t('Item_Type')}
                            SelectProps={{
                                multiple: true,
                                value: selectedItemTypes,
                                onChange: handleItemTypeChange,

                                renderValue: (selected) => {
                                    let displayItemTypes = [];
                                    for (let i = 0; i < selected.length; i++) {
                                        let item = enumListOptions.ItemType.find((iType) => iType.dateBaseCode === selected[i]);
                                        if (item) {
                                            displayItemTypes.push(item.uiCode)
                                        }
                                    }
                                    return displayItemTypes.join(', ');
                                }

                            }}
                            variant="standard"
                            required={isNew || isEdit}
                            {...register('itemType', isNew ? Validation.required : {})}
                            disabled={!isNew && !isEdit}
                            >
                            {enumListOptions.ItemType.map((item, index) => (
                                <MenuItem key={index} value={item.dateBaseCode}>
                                    <Checkbox checked={selectedItemTypes.includes(item.dateBaseCode)} />
                                    <ListItemText primary={item.uiCode} />
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField id="item-quantity" label={t('Quantity')} 
                            {...register("quantity", {...Validation.quantity, ...Validation.required})}
                            helperText={errors["quantity"] && errors["quantity"].message}
                            variant="standard" fullWidth type="number" required={isNew || isEdit}
                            disabled={!isNew && !isEdit}/>
                    </Grid>
                    <Grid item xs={2}>
                        <SelectControl
                            name='unit'
                            control={control}
                            errors={errors}
                            label={t('Unit')}
                            getValues={getValues}
                            disabled={!isNew && !isEdit}
                        >
                            {enumListOptions.Unit.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id}>{item.uiCode}</MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="item-description" label={t('Item_Description')} 
                            {...register("description")}
                            helperText={errors["description"] && errors["description"].message}
                            variant="standard" fullWidth multiline rows={2}
                            disabled={!isNew && !isEdit}/>
                    </Grid>
                </Grid>
            </AuctionFieldSet>
            {/* <Grid container xs={3} direction="row" justifyContent="center" alignItems="center"
                    style={{display: 'grid', textAlign: 'center'}}>
                <Grid item xs={12}>
                    {thumbnailImage && 
                        <div style={{display: 'grid'}}>
                            <Fragment>
                                <img src={thumbnailImage.url} alt="thumbnail" width='180' height='180'
                                        style={{borderRadius: '4px'}}/>
                                <Button color="primary" startIcon={<EditIcon />} variant="outlined" size="small"
                                        component="label">
                                    {t('Change')}
                                    <input hidden accept="image/*" type="file" onChange={onThumbnailImageUpload}/>
                                </Button>
                                <Button color="secondary" startIcon={<DeleteIcon />} variant="outlined" size="small"
                                        onClick={removeThumbnail}>
                                    {t('Remove')}
                                </Button>
                            </Fragment>
                        </div>
                        
                    }
                    {!thumbnailImage &&
                        <Fragment>
                            <IconButton color="primary" aria-label="upload picture" component="label" size="large">
                                <input hidden accept="image/*" type="file" onChange={onThumbnailImageUpload}/>
                                <AddPhotoAlternateIcon fontSize="large"/>
                            </IconButton>
                            <Typography variant="overline" display="block" gutterBottom
                                    style={{color: 'grey', fontSize: '0.8rem'}}>
                                {t('Upload_Thumbnail')}
                            </Typography>
                        </Fragment>
                    }
                </Grid>
            </Grid> */}
            {updatingItem && <AuctionLoader show={updatingItem} invisible={false} />}
            {(errorOnUpdatingItem) && <GraphQLErrors error={errorOnUpdatingItem} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </div>
    )
}

export default ItemDetail;