import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

export const Validation = {
    required:
    {
        required: { value: true, message: "Field is required" }
    },
    name:
    {
        required: { value: true, message: "Field is required" },
        minLength: { value: 1, message: "Enter minimum of 1 characters" },
        maxLength: { value: 150, message: "Exceeds maximum limit of 150 characters" },
        pattern: { value: /^\S$|^\S.*\S$/, message: 'Spaces are not allowed at start and end of the Input' }
        // validate: value => (value.trim() != '') || 'Value should not be empty'
    },
    email:
    {
        required: { value: true, message: "Field is required" },
        pattern: { value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Invalid pattern" }
    },
    amount:
    {
        // valueAsNumber: true,
        validate: value => ((value > 0 && value <= 50000000000000) || value == null || value == '') || (value > 50000000000000 ? 'Value should not be more than 5,00,00,00,00,00,000' : 'Value should be more than Zero')
    },
    number:
    {
        required: { value: true, message: "Field is required" },
        min: { value: 1, message: "Value should be more than or equal to 1" },
        // valueAsNumber: true,
        pattern: { value: /^[0-9]+$/, message: 'Invalid Value' }
        // validate: value => (value > 0) || 'Value should be more than Zero'
    },
    minutes:
    {
        required: { value: true, message: "Field is required" },
        min: { value: 5, message: "Minimum value is 5" },
        max: { value: 60, message: "Value should be less than 60" }
    },
    dateTime:
    {
        required: { value: true, message: "Field is required" },
        // value: "19/05/2022",
        // valueAsDate: true
    },
    mobileNumberValidation: {
        validate: value => isValidPhoneNumber(value) || 'Invalid Phone Number'
    },
    addressField:
    {
        required: { value: true, message: "Field is required" },
        // minLength: { value: 2, message: "Enter minimum of 2 letters" },
        validate: value => (value != null && value.trim() != '') || 'Value should not be empty',
    },
    addressLine: {
        minLength: { value: 2, message: "Enter minimum of 2 characters" },
        maxLength: { value: 250, message: "Exceeds maximum limit of 250 characters" }
    },
    indiaPincode:
    {
        pattern: { value: /^[0-9]{6}$/, message: 'Invalid Pin Code' }
    },
    accountNumber:
    {
        pattern: { value: /^[0-9]{6,18}$/, message: 'Value should be between 6 to 18 digits'}
    },
    ifsc:
    {
        pattern: { value: /^[A-Z]{4}0[A-Z0-9]{6}$/, message: 'Invalid IFSC' }
    },
    micr:
    {
        pattern: { value: /^[0-9]{9}$/, message: 'Value should be 9 digits' }
    },
    bankName:
    {
        minLength: { value: 2, message: "Enter minimum of 2 characters" },
        maxLength: { value: 100, message: "Exceeds maximum limit of 100 characters" },
        validate: value => (value.trim() != '') || 'Value should not be empty'
    },
    otherAccountType:
    {
        required: { value: true, message: "Field is required" },
        minLength: { value: 2, message: "Enter minimum of 2 characters" },
        maxLength: { value: 50, message: "Exceeds maximum limit of 50 characters" },
        validate: value => (value.trim() != '') || 'Value should not be empty'
    },
    branchName:
    {
        minLength: { value: 2, message: "Enter minimum of 2 characters" },
        maxLength: { value: 100, message: "Exceeds maximum limit of 100 characters" }
    },
    pan: {
        pattern: { value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, message: 'Invalid PAN' },
    },
    minAmount:
    {
        required: { value: true, message: "Field is required" },
        // maxLength: { value: 16, message: "Exceeds maximum limit of 100 characters" },
        // min: { value: 1, message: "Value should be more than 0" }
        // valueAsNumber: true
        // validate: value => (value > 0 && value.toString().length < 18) || ('ftft:.. ' + value.toString().length)

        validate: value => (value > 0 && value <= 50000000000000) || (value > 50000000000000 ? 'Value should not be more than 5,00,00,00,00,00,000' : 'Value should be more than Zero')
    },
    cin: {
        // pattern: { value: /^(U||L|)[0-9]{5}[A-Z]{2}[0-9]{4}(P||S||N||G||F||U||O|)(L||T||G||P||O||A|)(C||L||I||P||T|)[0-9]{6}$/, message: 'Invalid CIN' },
        pattern: { value: /^([U||L]{1})[0-9]{5}[A-Z]{2}[0-9]{4}([P||S||N||G||F||U||O]{1})([L||T||G||P||O||A]{1})([C||L||I||P||T]{1})[0-9]{6}$/, message: 'Invalid CIN' },
    },
    // pan: {
    //     pattern: {value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/, message: 'Invalid PAN Number'}
    // },
    shortNameRequired:
    {
        required: { value: true, message: "Field is required" },
        minLength: { value: 1, message: "Enter minimum of 1 characters" },
        maxLength: { value: 50, message: "Exceeds maximum limit of 50 characters" },
        // validate: value => (value.trim() != '') || 'Value should not be empty',
        pattern: { value: /^\S$|^\S.*$/, message: 'Spaces are not allowed at start' }
    },
    shortName:
    {
        // validate: value => !(value.length > 0 && value.trim() == '') || 'Value should not be empty',
        maxLength: { value: 50, message: "Exceeds maximum limit of 50 characters" },
        pattern: { value: /^\S$|^\S.*$/, message: 'Spaces are not allowed at start' }
    },
    // ^[a-zA-Z0-9_.-]*$
    selectRequired:
    {
        required: { value: true, message: "Field is required" }
    },
    addressNameNotMandatory:
    {
        minLength: { value: 2, message: "Enter minimum of 2 characters" },
        maxLength: { value: 50, message: "Exceeds maximum limit of 50 characters" }
    },
    addressEmailNotMandatory:
    {
        //TODO
    },
    addressLineNotMandatory:
    {
        minLength: { value: 2, message: "Enter minimum of 2 characters" },
        maxLength: { value: 250, message: "Exceeds maximum limit of 250 characters" }
    },
    phoneNumberNotMandatory:
    {
        validate: value => (value.trim() === '' || isValidPhoneNumber(value)) || 'Invalid Phone Number'
    },
    quantity:
    {
        min: { value: 0.01, message: "Value should be more than or equal to 0.01" },
        valueAsNumber: true
    },
    gstin:
    {
        pattern: {value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}$/, message: 'Invalid GSTIN'}
    },
    longName:
    {
        required: { value: true, message: "Field is required" },
        minLength: { value: 1, message: "Enter minimum of 1 characters" },
        maxLength: { value: 250, message: "Exceeds maximum limit of 250 characters" },
        // validate: value => (value.trim() != '') || 'Value should not be empty',
        pattern: { value: /^\S$|^\S.*$/, message: 'Spaces are not allowed at start' }
    },
    middleLastName: {
        minLength: { value: 1, message: "Enter minimum of 1 characters" },
        maxLength: { value: 100, message: "Exceeds maximum limit of 100 characters" },
        pattern: { value: /^\S$|^\S.*\S$/, message: 'Spaces are not allowed at start and end of the Input' }
    },
    longNameNotMandatory: {
        minLength: { value: 1, message: "Enter minimum of 1 characters" },
        maxLength: { value: 250, message: "Exceeds maximum limit of 250 characters" },
        // validate: value => (value.trim() != '') || 'Value should not be empty',
        pattern: { value: /^\S$|^\S.*$/, message: 'Spaces are not allowed at start' }
    }
}

// /(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/</>