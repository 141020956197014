import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography, FormControlLabel, Switch } from '@mui/material'
import MuiPhoneNumber from "material-ui-phone-number";
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Validation } from '../../common/Validation';
import AddressDetail from '../../profile/address/AddressDetail';
import { AuctionFieldSet } from '../../authentication/AuthStyle';
import { PartyType, SearchCriteria, AddressType, IdentificationTypes, IndividualIdentificationType, CompanyIdentification, PartyCodeCd }
    from '../../common/StaticVariableDeclaration';
import PartySearch from '../../common/search/PartySearch';
import { GET_PERSON_DETAILS } from '../../GraphQL/Queries';
import { NumericFormat } from 'react-number-format';
import { AddressFieldsForProfile } from '../../profile/address/address-common/RequiredFieldsAddress';
import { UserContext } from '../../common/context/UserContext';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import CustomizedTourComponent from '../../common/customized-tour-component/CustomizedTourComponent';

const authrepTourConfig = [
    {
        header: 'Existing Authorized Representative Details',
        body: 'Search here for required Authorized Representative details if the party already exist in the system.',
        target: '#search-party-details',
        disableBeacon: true
    },
    {
        header: 'Same as Bidder',
        body: 'Just enable the toggle button If Authorized Representative is same as Bidder.',
        target: '#same-as-authrep',
        disableBeacon: false
    }
]

const bidderTourConfig = [
    {
        header: 'Bidder Type',
        body: 'Select proper type of bidder here.',
        target: '#select-party-type',
        disableBeacon: true
    },
    {
        header: 'Existing Bidder Details',
        body: 'Search here for required bidder details if the party already exist in the system.',
        target: '#search-party-details',
        disableBeacon: false
    }
]


const BidderInput = ({ register, errors, control, setValue, getValues, showPartyTypeSelection, showSearch, trigger, reset,
    showEMDAmountField, children, isEdit, showClear, clearErrors, tourGuide, setTourGuide, handleAuthrepAddressSameAsBidder, sameAsAddress }) => {
    const { t } = useTranslation();
    const [refreshSearch, setRefreshSearch] = useState(true)
    const individual = useWatch({ control, name: 'individual' });
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const [tour, setTour] = useState({
        run: false,
        steps: []
    });

    const addTourSteps = (items) => {
        setTour({
            run: false,
            steps: []
        });
        setTimeout(() => {
            setTour({
                run: true,
                steps: items
            })
        }, 0);
    }

    const [getPersonDetails, { loading: fetchingPersonDetails, error: errorOnFetchingPersonDetails }] = useLazyQuery(GET_PERSON_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            clearAll();
            if (data.getUserDetails != null) {
                let person = data.getUserDetails;
                setValue('personId', person.personId, { shouldValidate: true, shouldDirty: true });
                setValue('name', person.name, { shouldValidate: true, shouldDirty: true });
                for (let i = 0; i < person.email.length; i++) {
                    if (person.email[i].preferred) {
                        setValue('emailId', person.email[i].email, { shouldValidate: true, shouldDirty: true });
                    }
                }
                for (let i = 0; i < person.phoneNumber.length; i++) {
                    if (person.phoneNumber[i].preferred) {
                        setValue('phoneNumber', person.phoneNumber[i].phoneNumber, { shouldValidate: true, shouldDirty: true });
                    }
                }
                for (let i = 0; i < person.identification.length; i++) {
                    if (person.identification[i].identificationType === IdentificationTypes.pan) {
                        setValue('pan', person.identification[i].identification)
                    } else if (person.identification[i].identificationType === IdentificationTypes.cin) {
                        setValue('cin', person.identification[i].identification)
                    }
                }
                if (person.address.length > 0) {
                    let primaryContact = person.address.find(address => address.addressType === AddressType.primaryContact);
                    if (primaryContact) {
                        setValue('addressId', primaryContact.addressId);
                        setValue('attention', primaryContact.attention);
                        setValue('phone', primaryContact.phone);
                        setValue('email', primaryContact.email);
                        setValue('addressLine1', primaryContact.addressLine1);
                        setValue('addressLine2', primaryContact.addressLine2);
                        setValue('landmark', primaryContact.landmark);
                        setValue('cityTown', primaryContact.cityTown);
                        setValue('postalCode', primaryContact.postalCode);
                        setValue('state', primaryContact.state);
                        setValue('country', primaryContact.country)
                        setValue('addressType', primaryContact.addressType);
                    } else {
                        setValue('addressId', person.address[0].addressId);
                        setValue('attention', person.address[0].attention);
                        setValue('phone', person.address[0].phone);
                        setValue('email', person.address[0].email);
                        setValue('addressLine1', person.address[0].addressLine1);
                        setValue('addressLine2', person.address[0].addressLine2);
                        setValue('landmark', person.address[0].landmark);
                        setValue('cityTown', person.address[0].cityTown);
                        setValue('postalCode', person.address[0].postalCode);
                        setValue('state', person.address[0].state);
                        setValue('country', person.address[0].country);
                        setValue('addressType', person.address[0].addressType);
                    }
                } else {
                    setValue('addressId', '');
                    setValue('attention', '');
                    setValue('phone', '');
                    setValue('email', '');
                    setValue('addressLine1', '');
                    setValue('addressLine2', '');
                    setValue('landmark', '');
                    setValue('cityTown', '');
                    setValue('postalCode', '');
                    setValue('state', '');
                    setValue('addressType', AddressType.primaryContact);
                }
            }
            trigger();
        }
    })

    const setPersonType = (event) => {
        setRefreshSearch(false);
        reset({
            individual: event.target.value,
            name: '',
            cin: '',
            pan: '',
            emailId: '',
            phoneNumber: '',
            emdAmount: '',
            attention: '',
            phone: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',
            cityTown: '',
            postalCode: '',
            state: '',
            addressType: AddressType.primaryContact,
            personId: '',
            addressId: '',
            country: 'India'
        });
        setTimeout(() => {
            setRefreshSearch(true);
        }, 100);
    }

    const onSelectParty = (party) => {
        getPersonDetails({
            variables: {
                userId: party.id
            }
        })
    }

    const clearAll = () => {
        setRefreshSearch(false);
        reset({
            individual: individual,
            name: '',
            cin: '',
            pan: '',
            emailId: '',
            phoneNumber: '',
            emdAmount: '',
            attention: '',
            phone: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',
            cityTown: '',
            postalCode: '',
            state: '',
            country: 'India',
            addressType: AddressType.primaryContact,
            personId: '',
            addressId: ''
        });
        setTimeout(() => {
            setRefreshSearch(true);
        }, 100);
    }

    useEffect(() => {
        if(tourGuide?.triggerEvent && isEdit) {
            showPartyTypeSelection? addTourSteps(bidderTourConfig) : (children ? addTourSteps(authrepTourConfig) : addTourSteps([authrepTourConfig[0]]));
        }
    }, [tourGuide?.triggerEvent])

    useEffect(() => {
        setTourGuide( (prevState) => ({
            ...prevState,
            triggerButton: isEdit
        }));
    }, [isEdit])

    return (
        <div>
            {/* <Grid style={{textAlign: 'center'}}>
                <Button variant="outlined"
                        onClick={() => setPersonType(PartyType.company)}
                        style={{borderRadius: '0px', touchAction: 'manipulation', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px',
                        backgroundColor: personType === PartyType.company ? '#0a4ea2': 'rgb(196 213 233 / 13%)',
                        color: personType === PartyType.company ? 'white' : '#0a4ea2'
                        }}
                        size='small'>
                    {t('Company')}
                </Button>
                <Button variant="outlined"
                        onClick={() => setPersonType(PartyType.individual)}
                        style={{touchAction: 'manipulation',
                        borderTopRightRadius:'25px', borderBottomRightRadius:'25px',
                            backgroundColor: personType === PartyType.individual ? '#0a4ea2': 'rgb(196 213 233 / 13%)',
                            color: personType === PartyType.individual ? 'white' : '#0a4ea2',
                            position: 'relative', right: '1px'}}
                        size='small'>
                    {t('Individual')}
                </Button>
            </Grid> */}
            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="flex-start" style={{ paddingTop: '6px' }}>
                {(showPartyTypeSelection && isEdit) && <Grid item xs={2}>
                    <FormControl fullWidth size='small' required  id="select-party-type">
                        <InputLabel id="bidder-type-label">{t('Bidder_Type')}</InputLabel>
                        <Select
                            labelId="bidder-type-label"
                            id="bidder-type-selection"
                            value={individual}
                            label="Bidder_Type"
                            onChange={setPersonType}
                            required
                        >
                            {PartyCodeCd.map((item, index) => {
                                return (<MenuItem value={item.dateBaseCode} key={index}>{item.uiCode}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                }

                {refreshSearch && (showSearch && isEdit) && <Grid item xs={5}>
                    <PartySearch style={{ width: '100vh' }}
                        individual={individual}
                        defaultValue={individual ? SearchCriteria.email : SearchCriteria.name}
                        setDropDownValue={[SearchCriteria.email, SearchCriteria.name, SearchCriteria.phoneNumber]}
                        onSelect={onSelectParty}
                        cognitoUsers={false}
                        identificationTypes={individual === true ? [IndividualIdentificationType.pan] : [CompanyIdentification.cin, CompanyIdentification.pan]}
                    />
                </Grid>}
                {/* Add switch as a chidren to this component  */}
                {(isEdit && showClear) && <Grid item xs={showPartyTypeSelection ? 5 : 7} className='float-right'>
                    {children}
                    <Typography color="primary" style={{ float: 'right', position: 'relative', top: '10px', right: '24px', cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={clearAll}>{t('Clear')}
                    </Typography>
                </Grid>}
            </Grid>
            <br />
            <AuctionFieldSet>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                            name="name"
                            control={control}
                            rules={Validation.required}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="name"
                                    label={individual ? t('Name') : t('Institution_Name')}
                                    type="text"
                                    variant="standard"
                                    fullWidth
                                    required={(isEdit && getValues('personId') == '') || getValues('personId') == ''}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors?.name?.message}
                                    InputLabelProps={{ shrink: field.value }}
                                />
                            )}
                        />
                        {/* <TextField id="name" label={t('Name')}
                            {...register("name", Validation.required)}
                            helperText={errors["name"] && errors["name"].message}
                            variant="standard" fullWidth required
                            InputLabelProps={{ shrink: getValues('name') }}
                        /> */}
                    </Grid>
                    {/* { personType === PartyType.company && <Grid item xs={6}>
                        <TextField id="restOfName" label={t('Name')}
                            {...register("restOfName", Validation.required)}
                            helperText={errors["restOfName"] && errors["restOfName"].message}
                            variant="standard" fullWidth required
                            />
                    </Grid> } */}
                    {individual === false && <Grid item xs={3}>
                        <Controller
                            name="cin"
                            control={control}
                            rules={Validation.cin}
                            render={({ field: { name, onChange, value }, field }) => (
                                <TextField
                                    {...field}
                                    id="cin"
                                    label={t('CIN')}
                                    variant="standard"
                                    fullWidth
                                    onChange={(event) => onChange((event.target.value).toUpperCase())}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors?.cin?.message}
                                    InputLabelProps={{ shrink: field.value }}
                                />
                            )}
                        />
                        {/* <TextField id="cin" label={t('CIN')}
                            {...register("cin", Validation.cin)}
                            helperText={errors["cin"] && errors["cin"].message}
                            variant="standard" fullWidth
                            /> */}
                    </Grid>}
                    <Grid item xs={3}>
                        <Controller
                            name="pan"
                            control={control}
                            rules={{ ...Validation.pan }}
                            render={({ field: { name, onChange, value }, field }) => (
                                <TextField
                                    {...field}
                                    id="pan"
                                    label={t('PAN')}
                                    variant="standard"
                                    fullWidth
                                    onChange={(event) => onChange((event.target.value).toUpperCase())}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors?.pan?.message}
                                    InputLabelProps={{ shrink: value }}
                                />
                            )}
                        />
                        {/* <TextField id="pan" label={t('PAN')}
                            {...register("pan", Validation.pan)}
                            helperText={errors["pan"] && errors["pan"].message}
                            variant="standard" fullWidth
                            /> */}
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="emailId"
                            control={control}
                            rules={Validation.email}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="emailId"
                                    label={t('Email')}
                                    type="text"
                                    variant="standard"
                                    fullWidth
                                    required={(isEdit && getValues('personId') == '') || getValues('personId') == ''}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors?.emailId?.message}
                                    InputLabelProps={{ shrink: field.value }}
                                />
                            )}
                        />
                        {/* <TextField id="emailId" label={t('Email')}
                            {...register("emailId", Validation.email)}
                            helperText={errors["emailId"] && errors["emailId"].message}
                            variant="standard" fullWidth required
                            /> */}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={Validation.mobileNumberValidation}
                            render={({ field: { name, onChange, value } }) => (
                                <MuiPhoneNumber
                                    id="phoneNumber"
                                    name={name}
                                    label={t('Phone_Number')}
                                    defaultCountry={"in"}
                                    value={value}
                                    disabled={!isEdit || getValues('personId')}
                                    onChange={onChange}
                                    helperText={errors?.phoneNumber?.message}
                                    autoFormat={false} fullWidth
                                    required={(isEdit && getValues('personId') == '') || getValues('personId') == ''}
                                    variant="standard"
                                    countryCodeEditable={false}
                                />
                            )}
                        />
                    </Grid>
                    {/* {showEMDAmountField && <Grid item xs={3}>
                        <NumericFormat id="emd-amount" label={t("EMD_Amount")}
                            customInput={TextField}
                            variant="standard"
                            thousandSeparator={true}
                            onValueChange={({ floatValue }) => {
                                setValue('emdAmount', floatValue);
                            }}
                            thousandsGroupStyle="lakh"
                            allowNegative={false}
                            required
                            fullWidth
                            disabled={!isEdit}
                            helperText={errors["emdAmount"] && errors["emdAmount"].message}
                            {...register("emdAmount", Validation.required)}
                        />
                    </Grid>} */}
                </Grid>
            </AuctionFieldSet>
            <br />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    { isEdit && !getValues('personId') && handleAuthrepAddressSameAsBidder &&
                        <FormControlLabel
                            sx={{ float: 'right', paddingRight: '10%' }}
                            id="same-as-bidder-address"
                            control={ <Switch name="same-as-bidder-address" checked={sameAsAddress}/> }
                            label={t('Same_As_Bidder_Address')}
                            onChange={handleAuthrepAddressSameAsBidder}
                        />
                    }
                    <AddressDetail register={register} control={control}
                        setValue={setValue} getValues={getValues}
                        errors={errors} isEdit={isEdit && (getValues('addressId') === null || getValues('addressId') === '')}
                        requiredShowFields={AddressFieldsForProfile} createTitle={true} clearErrors={clearErrors}/>
                </Grid>
            </Grid>
            {fetchingPersonDetails && <AuctionLoader show={fetchingPersonDetails} invisible={false} />}
            {errorOnFetchingPersonDetails && <GraphQLErrors error={errorOnFetchingPersonDetails} show={false} />}
            <CustomizedTourComponent tour={tour} setTour={setTour} />
        </div>
    )
}

export default BidderInput;