import React, {
    useContext, useEffect, useState
} from 'react';
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Typography,
    Grid,
    Button,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    useTheme
} from '@mui/styles';
import SwipeableViews from 'react-swipeable-views';
import BidderAuthRepList from '../bidder-auth-rep/BidderAuthRepList';
import BidHistory from '../../auction-bid-history/BidHistory';
import BidderViewDocumentList from '../../auction-document/bidder-documents/BidderViewDocumentList';
import EMDList from '../emd-amount/emd-list/EMDList';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import { AuctionStatus, Roles, BidderStatus } from '../../common/StaticVariableDeclaration';
import UpdateBidder from '../update-bidder/UpdateBidder';
import { useMutation } from '@apollo/client'; 
import { LIST_BIDDER_AUTH_REP } from '../../GraphQL/Queries';
import { SEND_CREDETIALS_TO_BIDDER_AUTH_REPS } from '../../GraphQL/Mutation';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import SendIcon from '@mui/icons-material/Send';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import { AxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import { UserContext } from '../../common/context/UserContext'

const useStyles = makeStyles((theme) => ({
    bidderTab: {
        textTransform: 'none !important',
        fontWeight: 'bold !important'
    },
    button: {
        margin: '4px !important'
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ background: 'rgba(255, 255, 255, 0.65)', minHeight: '65vh', position: 'relative' }}
        >
            {value === index && (
                <Box sx={{ p: 1 }} style={{ paddingTop: '30px' }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const BidderDetails = ({ auction_id, bidderDetails, resetShowBidder, seller_id, canEdit, lot_id }) => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);
    const classes = useStyles();
    const theme = useTheme();
    const auctionContext = useContext(AuctionDetailContext);
    const [bidderName, setBidderName] = useState(bidderDetails ? bidderDetails.name : '');
    const [bidder, setBidder] = useState(bidderDetails ? bidderDetails : {id: ''});
    const userContext = useContext(UserContext).rootContext;
    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (useContext(UserContext).rootContext.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    });
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    });

    const [sendCredetials, {loading: sendingCredentials, error: errorOnSendingCredentials}] = useMutation(SEND_CREDETIALS_TO_BIDDER_AUTH_REPS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setMessage({ show: false, message: '', severity: '' })
            if (data.createBidderAccount !== null && data.createBidderAccount.createdAccount > 0) {
                setMessage({
                    show: true,
                    message: `${t('Total')} ${data.createBidderAccount.createdAccount} ${t('accounts_created')} ${t('and')} ${t('Sent_Credentials_Successfully')}`,
                    severity: 'success'
                });
            } else if(data.createBidderAccount !== null && data.createBidderAccount.createdAccount == 0) {
                setMessage({
                    show: true,
                    message: t('Currently_All_Bidder_Authrep_Are_Account_Holders'),
                    severity: 'success'
                });
            }
        },
        refetchQueries: [LIST_BIDDER_AUTH_REP]
    })

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleTabChangeIndex = (index) => {
        setTab(index);
    };

    const sendCredentialsToBidderAuthRep = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Send_Credentials_To_Bidder_Auth_Reps'),
            onAgree: () => {
                sendCredetials({
                    variables: {
                        auctionId: auction_id,
                        bidderId: bidder.id
                    }
                })
                resetSeekConfirmation()
            },
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const getBidderWithoutId = () => {
        console.log('getBidderWithoutId')
        AxiosAuctionInterceptor.get(`bidder/${auction_id}`).
          then((response) => {
            setBidder(response);
            setBidderName(response.name);
          }).catch((error) => {
            console.log("error", error)
          })
    }
      
    useEffect(() => {
        if(!bidderDetails){
            getBidderWithoutId(); 
        }
    }, [])


    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    {resetShowBidder && <Button id="back-to-bidders" size="small" aria-label="back-to-bidders" onClick={resetShowBidder} style={{ float: 'right'}}
                            variant='outlined' className={classes.button}>
                        {t('Back')}
                    </Button>}
                    {((auctionContext.auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin ||
                        ( (auctionContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.scheduled ||
                            auctionContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.open) &&
                            (auctionContext.auctionGetDetail.roles.includes(Roles.auctioneer) || auctionContext.auctionGetDetail.roles.includes(Roles.support))
                        )) &&
                        <Tooltip title={t('Send_Credentials_To_Bidder')}>
                            <Button variant="contained" size="small" startIcon={<SendIcon />} style={{float: 'right'}}
                                    onClick={sendCredentialsToBidderAuthRep} className={classes.button} id="send-credentials-btn">
                                {t('Send_Credentials')}
                            </Button>
                        </Tooltip>
                    }
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{t('Bidder_Name')} : </span>
                        <span id="bidder-name-label">{bidderName}</span>
                    </div>
                </Grid>
            </Grid>
            <AppBar position="static" color="default">
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="item details tab"
                >
                    <Tab className={classes.bidderTab} label={t('Bidder_Details')} id='bidder-tab' aria-controls='bidder-tab' />
                    <Tab className={classes.bidderTab} label={t('Authorized_Representative')} id='auth-rep-tab' aria-controls='auth-rep-tab' />
                    {/* <Tab className={classes.bidderTab} label={t("Joint_Bidder")} id='joint-bidder-tab' aria-controls='joint-bidder-tab' /> */}
                    <Tab className={classes.bidderTab} label={t("EMD_Details")} id='emd-details-tab' aria-controls='emd-details-tab' />
                    <Tab className={classes.bidderTab} label={t("Documents")} id='documents-tab' aria-controls='documents-tab' />
                    {(  (auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.open && !auctionContext.auctionGetDetail.roles.includes(Roles.auctioneer)
                            && ( !auctionContext.auctionGetDetail.roles.includes(Roles.support) || (auctionContext.auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) )
                        ) || auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.cancelled
                        || auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed
                    ) && <Tab className={classes.bidderTab} label={t("Bid_History")} id='bid-history-tab' aria-controls='bid-history-tab' />
                    }
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleTabChangeIndex}
            >
                <TabPanel value={tab} index={0} dir={theme.direction}>
                    <UpdateBidder bidder_id={bidder.id} auction_id={auction_id} canEdit={canEdit}
                        setBidderName={setBidderName} bidder={bidder} bidder_login_id={bidder.loginId}/>
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction}>
                    <BidderAuthRepList auction_id={auction_id} bidder_id={bidder.id}
                        bidder_login_id={bidder.loginId}
                        canAdd={canEdit && ((auctionContext.auctionGetDetail.status == AuctionStatus.setup || auctionContext.auctionGetDetail.status == AuctionStatus.publish_request) ? true : bidder.bidderStatus != BidderStatus.reject)}
                        canEdit={canEdit}
                        canDelete={canEdit} />
                </TabPanel>
                {/* <TabPanel value={tab} index={2} dir={theme.direction}>

                </TabPanel> */}
                <TabPanel value={tab} index={2} dir={theme.direction}>
                    <EMDList auction_id={auction_id} user_id={bidder.personId}
                        seller_id={seller_id} bidder_id={bidder.id}
                        allowAdd={canEdit}
                        allowEdit={canEdit}
                        allowDelete={canEdit}
                        auctionCreationDate={auctionContext.auctionGetDetail.createdOn}
                        mannerOfSale={auctionContext.auctionGetDetail.mannerOfSale}
                        allowUpdateReceivedEMDField={(auctionContext.auctionGetDetail.roles.includes(Roles.support) || auctionContext.auctionGetDetail.roles.includes(Roles.auctioneer))} 
                        bidder_login_id={bidder.loginId}/>
                </TabPanel>
                <TabPanel value={tab} index={3} dir={theme.direction}>
                    <BidderViewDocumentList auctionId={auction_id} bidderId={bidder.personId}
                        showActionColumn={(auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled &&
                            (auctionContext.auctionGetDetail.roles.includes(Roles.requestedBidder) || auctionContext.auctionGetDetail.roles.includes(Roles.bidder)) &&
                            !auctionContext.auctionGetDetail.roles.includes(Roles.rejectedBidder))}
                    />
                </TabPanel>
                {(auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.open || auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.cancelled
                    || auctionContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed) &&
                    <TabPanel value={tab} index={4} dir={theme.direction}>
                        <BidHistory auctionId={auction_id} bidderId={bidder.personId} bidOnId={lot_id} pagination={true}
                            isBidder={(auctionContext.auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) || auctionContext.auctionGetDetail.roles.includes(Roles.bidder))} auctionStatus={auctionContext.auctionGetDetail.status.toLowerCase()} />
                    </TabPanel>}
            </SwipeableViews>
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {sendingCredentials && <AuctionLoader show={sendingCredentials} invisible={false} />}
            {errorOnSendingCredentials && <GraphQLErrors error={errorOnSendingCredentials} show={false} />}
        </div>
    )
}

export default BidderDetails;