import React, { useContext, useEffect, useState }               from "react";
import { makeStyles }                                           from "@mui/styles";
import { Button,BottomNavigation, IconButton, Tooltip, Card, Chip}          from '@mui/material';
import { Box }                                                  from "@mui/system";
import { 
    LIST_AUCTION_BANK_DETAIL,
    LIST_BANK_DETAIL_FOR_LOGINUSER
}                                                               from '../../../GraphQL/Queries';
import { 
    DELETE_BANK_DETAILS,
    DELETE_PROFILE_BANK_DETAILS
}                                                               from '../../../GraphQL/Mutation';
import Table                                                    from '@mui/material/Table';
import TableBody                                                from '@mui/material/TableBody';
import TableCell                                                from '@mui/material/TableCell';
import TableContainer                                           from '@mui/material/TableContainer';
import TableHead                                                from '@mui/material/TableHead';
import TableRow                                                 from '@mui/material/TableRow';
import Paper                                                    from '@mui/material/Paper';
import AuctionSnackBar                                          from '../../../common/AuctionSnackBar';
import AuctionLoader                                            from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors                                            from '../../../common/GraphQLErrors';
import { useLazyQuery }                                         from '@apollo/client';
import AuctionPagination                                        from '../../../common/pagination/AuctionPagination';
import { styled }                                               from '@mui/material/styles';
import { useTranslation }                                       from 'react-i18next';
import { UserContext }                                          from "../../../common/context/UserContext";
import AddIcon                                                  from '@mui/icons-material/Add';
import BankDetails                                              from "../bank-details/BankDetail";
import DeleteIcon                                               from '@mui/icons-material/DeleteOutlined';
import EditIcon                                                 from '@mui/icons-material/EditOutlined';
import { useMutation }                                          from '@apollo/client';
import BankDetailModal                                          from '../bank-details/BankDetailModal';
import AuctionConfirmDialog                                     from "../../../common/AuctionConfirmDialog";
import VisibilityIcon                                           from '@mui/icons-material/Visibility';
import { PublicAxiosAuctionInterceptor } from "../../../config/AxiosAuctionInterceptor";
import { useServerCurrentDateTime } from '../../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    tableRow: {
        backgroundColor: theme.tableHearderBg
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important",
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    infoMsg: {
        color: theme.bankAccountInfoColor,
        fontWeight: 'bold',
        fontSize: '14px'
    },
    statusText: {
        fontWeight: 'bold',
        fontSize: '0.9rem !important',
        padding: '14px !important',
        margin: '4px 0px'
    }  
   
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#add8e612 !important',
    }
  }));

const BankDetailList = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const userContext = useContext(UserContext).rootContext;
    const [page, setPage] = useState(1);
    const [bankDetailsList, setBankDetailsList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalNumberOfBankAccounts, setTotalNumberOfBankAccounts] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [bankDetailId, setBankDetail] = useState('');
    const [isNew, setIsNew] = useState(false);
    // const [canModify, setCanMofidy] = useState(props.canModify != undefined ? props.canModify : true);
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const showInfo = props.showInfo ? props.showInfo : false;
    const isProfile = props.isProfile ? props.isProfile : false;
    const [isView, setIsView] = useState(false);
    const [loading, setLoading] = useState(false);
    const serverCurrentTime = useServerCurrentDateTime();
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [listBankDetailForLoginUser, {loading: listingLoginUserBankDetails, error: errorOnListingLoginUserBankDetails, refetch}] = 
            useLazyQuery(LIST_BANK_DETAIL_FOR_LOGINUSER, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setBankDetailsList([]);
            if (data.listBankDetailForLoginUser != null) {
                data.listBankDetailForLoginUser.bankDetail.map(bankAccount => {
                    let accountTypeDescription = ''
                    enumListOptions.AccountType.find((type) => {
                        if(type.dateBaseCode == bankAccount.accountType) {
                           accountTypeDescription = type.uiCode;
                        }
                    })
                    setBankDetailsList(prevState => ([
                        ...prevState,
                        {
                            id: bankAccount.id,
                            bankAccountNumber: bankAccount.bankAccountNumber,
                            accountType: accountTypeDescription,
                            ifsc: bankAccount.ifsc,
                            micr: bankAccount.micr,
                            bankName: bankAccount.bankName,
                            branchName: bankAccount.branchName,
                            bankHolderName: bankAccount.bankHolderName,
                            userId: bankAccount.userId,
                            edgeId: bankAccount.edgeId,
                            startTime: bankAccount.startTime,
                            endTime: bankAccount.endTime
                        }
                    ]))
                })
                setTotalNumberOfBankAccounts(data.listBankDetailForLoginUser.count);
            }
        }
    })

    const [listAuctionBankDetail, {loading: listingAuctionBankDetails, error: errorOnListingAuctionBankDetails,
        refetch: refetchAuctionBankAccounts}] = 
            useLazyQuery(LIST_AUCTION_BANK_DETAIL, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setBankDetailsList([]);
            if (data.bankDetailListInsideAuction != null) {
                data.bankDetailListInsideAuction.bankDetail.map(bankAccount => {
                    let accountTypeDescription = ''
                    enumListOptions.AccountType.find((type) => {
                        if(type.dateBaseCode === bankAccount.accountType) {
                           accountTypeDescription = type.uiCode;
                        }
                    })
                    setBankDetailsList(prevState => ([
                        ...prevState,
                        {
                            id: bankAccount.id,
                            bankAccountNumber: bankAccount.bankAccountNumber,
                            accountType: accountTypeDescription,
                            ifsc: bankAccount.ifsc,
                            micr: bankAccount.micr,
                            bankName: bankAccount.bankName,
                            branchName: bankAccount.branchName,
                            bankHolderName: bankAccount.bankHolderName,
                            userId: bankAccount.userId,
                            edgeId: bankAccount.edgeId,
                            startTime: bankAccount.startTime,
                            endTime: bankAccount.endTime
                        }
                    ]))
                })
                setTotalNumberOfBankAccounts(data.bankDetailListInsideAuction.count);
            }
        }
    })

    const handlelistAuctionBankDetailApi = () => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/bank-detail/list/${props.auction_id}/${props.userId}/${page}/${rowsPerPage}`).then((response) => {
            setBankDetailsList([]);
            response.bankDetail.map(bankAccount => {
                let accountTypeDescription = ''
                    enumListOptions.AccountType.find((type) => {
                        if(type.dateBaseCode === bankAccount.accountType) {
                           accountTypeDescription = type.uiCode;
                        }
                    })
                    setBankDetailsList(prevState => ([
                        ...prevState,
                        {
                            id: bankAccount.id,
                            bankAccountNumber: bankAccount.bankAccountNumber,
                            accountType: accountTypeDescription,
                            ifsc: bankAccount.ifsc,
                            micr: bankAccount.micr,
                            bankName: bankAccount.bankName,
                            branchName: bankAccount.branchName,
                            bankHolderName: bankAccount.bankHolderName,
                            userId: bankAccount.userId,
                            edgeId: bankAccount.edgeId,
                            startTime: bankAccount.startTime,
                            endTime: bankAccount.endTime
                        }
                    ]))
                })
                setTotalNumberOfBankAccounts(response.count);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({show: false, message: '', severity: ''})
            setMessage({show: true, message: error.message, severity: 'error'})
        });
    }

    const [deleteBankDetails, {loading: deletingBankDetails, error: errorOnDeletingBankDetails}] = 
            useMutation(DELETE_BANK_DETAILS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteBankDetail) {
                onSuccess({show: true, message: t('Deleted_Successfully'), severity: 'success'});
            }
            if (page !== 1) {
                let lastPageRows = totalNumberOfBankAccounts % rowsPerPage;
                let noOfPage = ~~(totalNumberOfBankAccounts/rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                if(lastPageRows == 1 && page == noOfPage){
                    setPage(1);
                } else {
                    refetchAuctionBankAccounts();
                }
            } else {
                refetchAuctionBankAccounts();
            }
        },
        // refetchQueries: [LIST_AUCTION_BANK_DETAIL]
    })

    const [deleteProfileBankDetails, {loading: deletingProfileBankDetails, error: errorOnProfileDeletingBankDetails}] = 
            useMutation(DELETE_PROFILE_BANK_DETAILS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteBankDetailForLoginUser) {
                onSuccess({show: true, message: t('Deleted_Successfully'), severity: 'success'});
            }
            if (page !== 1) {
                let lastPageRows = totalNumberOfBankAccounts % rowsPerPage;
                let noOfPage = ~~(totalNumberOfBankAccounts/rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                if(lastPageRows == 1 && page == noOfPage){
                    setPage(1);
                } else {
                    refetch();
                }
            } else {
                refetch();
            }
        },
        
        // refetchQueries: [LIST_BANK_DETAIL_FOR_LOGINUSER]
    })

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    useEffect(() => {
        if (props.auction_id) {
            if (userContext.userSession.isAuthenticated) {
                listAuctionBankDetail({
                    variables: {
                        userId: props.userId,
                        auctionId: props.auction_id,
                        pagination: { 
                            page: page,
                            limit: rowsPerPage,
                            asc: false
                        },
                    }
                })
            } else {
                handlelistAuctionBankDetailApi();
            }
        } else {
            listBankDetailForLoginUser({
                variables: {
                    pagination: { 
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    },
                }
            })
        }
    }, [rowsPerPage, page])  

    const deleteBankAccount = (bankDetail) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Bank_Account'),
            onAgree: () => deleteAccount(bankDetail),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const deleteAccount = (bankDetail) => {
        resetSeekConfirmation();
        if (bankDetail) {
            if (props.auction_id) {
                deleteBankDetails({
                    variables: {
                        auctionId: props.auction_id,
                        edgeId: bankDetail.edgeId
                    }
                })
            } else {
                deleteProfileBankDetails({
                    variables: {
                        edgeId: bankDetail.edgeId
                    }
                })
            }
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }


    const onSuccess = (successMessage) => {
        setMessage({
            show: successMessage.show,
            message: successMessage.message,
            severity: successMessage.severity
        })
        if (successMessage.severity != 'error') {
            setTimeout(() => {
                resetMessage();
            }, 1500);
        }
        onSuccessAuctionAccount();
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    const addBankAccount = () => {
        setIsView(false);
        setOpenPopup(true);
        setIsNew(true);
    }

    const editBankAccount = (bankDetailIdValue, isView) => {
        setIsView(isView);
        setBankDetail(bankDetailIdValue);
        setOpenPopup(true);
        setIsNew(false);
    }

    const setCancel = (isCancel) => {
        setOpenPopup(false);
        setBankDetail('');
    }

    const onSuccessAuctionAccount = () => {
        if (props.auction_id) {
            refetchAuctionBankAccounts();
        }
    }

    return(
        <React.Fragment>
            {(bankDetailsList.length === 0 && props.isProfile) &&
            // <Box mt={2} mb={2}>  
                <Card elevation={4} sx={{ p: 3, width: '95%', marginLeft: '2.5%' }}>
                <BankDetails isCreate={true} isNew={true} isModal={false} userId={props.userId} auctionId={props.auction_id} 
                    onSuccess={onSuccessAuctionAccount} isView={false} 
                    showExistingBankDetails={props.showExistingBankDetails}/>
                </Card>
            // </Box>
            }
            {((bankDetailsList.length > 0) || (bankDetailsList.length === 0 && !props.isProfile)) && <Box mt={2} mb={2}>
                {showInfo && <span className={`${classes.infoMsg}`}>{t('Bank_Accounts_To_Which_EMD_To_Be_Transferred')}</span>}
                {!props.isView && <Tooltip title={t('Add_Bank_Account')}>
                    <Button id="add-bank-account" variant="outlined" startIcon={<AddIcon />} style={{float: 'right', position: 'relative', bottom: '7px'}}
                    onClick={addBankAccount}>{t('Add')}</Button>
                </Tooltip>}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow className={`${classes.tableRow}`}>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left">{t('Account_Holder_Name')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left">{t('Bank_Account_Number')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left">{t('Account_Type')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left">{t('Bank_Name')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center">{t('Actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody id="bank-account-body">
                            {bankDetailsList.length === 0 && 
                                <TableRow>
                                    <TableCell colSpan={5} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                </TableRow>
                            }
                            {bankDetailsList.length > 0 && bankDetailsList.map((bankAccount, index) => {
                                return (<StyledTableRow key={bankAccount.id} id={`bank-account${index}`}>
                                        <TableCell style={{cursor: 'pointer'}} onClick={() => {editBankAccount(bankAccount.id, true)}} id={`bank-holder-name${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{bankAccount.bankHolderName}</TableCell>
                                        <TableCell style={{cursor: 'pointer'}} onClick={() => {editBankAccount(bankAccount.id, true)}} id={`bank-aaccount-number${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{bankAccount.bankAccountNumber}</TableCell>
                                        <TableCell style={{cursor: 'pointer'}} onClick={() => {editBankAccount(bankAccount.id, true)}} id={`bank-account-type${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{bankAccount.accountType}</TableCell>
                                        <TableCell style={{cursor: 'pointer'}} onClick={() => {editBankAccount(bankAccount.id, true)}} id={`bank-name${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{bankAccount.bankName}</TableCell>
                                        {<TableCell className={`${classes.tableCell} ${classes.tableData}`}  align="center">
                                            {new Date(bankAccount.endTime) < serverCurrentTime ?  <Chip className={classes.statusText} label={t('Deleted')} color="error" size="small" /> : 
                                            <React.Fragment>
                                            {(!props.isView && props.sellerLoginUserId == null) && <IconButton id={`bank-account-edit${index}`}  onClick={() => { editBankAccount(bankAccount.id, false) }}>
                                                <Tooltip title={t('Edit')}>
                                                    <EditIcon color='primary'/>
                                                </Tooltip>
                                            </IconButton>}
                                            {!props.isView && <IconButton id={`bank-account-delete${index}`}  onClick={() => {deleteBankAccount(bankAccount)}}>
                                                <Tooltip title={t('Delete')}>
                                                    <DeleteIcon color='error' />
                                                </Tooltip>
                                            </IconButton>}
                                            <IconButton id={`bank-account-view${index}`}  onClick={() => {editBankAccount(bankAccount.id, true)}}>
                                                <Tooltip title={t('View')}>
                                                    <VisibilityIcon color='primary' style={{fontSize: '1.25rem'}} />
                                                </Tooltip>
                                        </IconButton>
                                        </React.Fragment>
                                        }
                                        </TableCell>}
                                </StyledTableRow>)
                            })}
                        </TableBody>
                    </Table>
                    { totalNumberOfBankAccounts > 0 &&
                        <BottomNavigation style={{padding: '12px'}}>
                            <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={totalNumberOfBankAccounts}
                                onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange}/>
                        </BottomNavigation>
                    }
                </TableContainer>
            </Box>}
            {(listingLoginUserBankDetails || deletingBankDetails || listingAuctionBankDetails || loading || deletingProfileBankDetails) && <AuctionLoader 
                show={deletingBankDetails || listingLoginUserBankDetails || listingAuctionBankDetails || loading || deletingProfileBankDetails} 
                    invisible={false} />}
            {(errorOnListingLoginUserBankDetails || errorOnDeletingBankDetails || errorOnListingAuctionBankDetails || errorOnProfileDeletingBankDetails) && 
                <GraphQLErrors error={(errorOnListingLoginUserBankDetails ||errorOnDeletingBankDetails || errorOnListingAuctionBankDetails || errorOnProfileDeletingBankDetails)} show={false} />}
            {openPopup && <BankDetailModal openPopup={openPopup} setCancel={setCancel} isView={isView}
                bankDetailId={bankDetailId} isNew={isNew}  onSuccess={onSuccess} userId={props.userId} auctionId={props.auction_id}
                showExistingBankDetails={props.showExistingBankDetails} sellerLoginUserId={props.sellerLoginUserId} existingBankIds={bankDetailsList.map((data) => data.id)}/>}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </React.Fragment>
    )
}

export default BankDetailList;


