import { useLazyQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import {
  Box, Button, Divider, Fab, Grid, List, ListItem, ListItemButton, Popover, Stack, Tab, Tabs, TextField,
  Typography
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenChip, JustifyBox } from '../../authentication/AuthStyle';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import { AddressType } from '../../common/StaticVariableDeclaration';
import TabPanel from '../../common/tabPanel/TabPanel';
import { LIST_ADDRESS, LIST_LOGIN_USER_ADDRESS } from '../../GraphQL/Queries';
import { AddressFieldsForCommon } from './address-common/RequiredFieldsAddress';
import AddressTab from './AddressTab';

export const COUNTRY_DEFAULT = 'India'

const Address = (props) => {
  const { sourceId, requiredShowFields, isLoginUser, hasPermission, address, allowMultipleAddress, atlestOneMandatory, onlyPrimary, 
    newAddress, addressType, showCancel,setOpenAddress, newAddressDefaultData } = {
    ...props, requiredShowFields: props.requiredShowFields || AddressFieldsForCommon, sourceId: props.sourceId || '',
    isLoginUser: props.isLoginUser || false, atlestOneMandatory: props.atlestOneMandatory || false, onlyPrimary: props.onlyPrimary || false
  }
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [newAddressType, setNewAddressType] = useState('');
  const [userAddresses, setUserAddresses] = useState([]);
  const [isPrimaryContactPresent, setIsPrimaryContactPresent] = useState(false);
  const [error, setError] = useState({ show: false, message: '' });
  const [other, setOther] = useState(false);
  let opens = Boolean(anchorEl);
  const popperId = opens ? 'add-address-popper' : undefined;
  const [disableOnEdit, setDisableOnEdit] = useState(false);
  const [allowMultiple] = useState(allowMultipleAddress != undefined ? allowMultipleAddress : true);

  const [listAddress, { loading: loadingAddress, error: fetchError }] = useLazyQuery(LIST_ADDRESS, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.listAddress !== null) {
        setUserAddressDetails(data.listAddress.address)
      }
    },
  })

  const [listLoginUserAddress, { loading: loadingLoginUserAddress, error: fetchLoginUserError }] = useLazyQuery(LIST_LOGIN_USER_ADDRESS, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.listLoginUserAddress !== null) {
        setUserAddressDetails(data.listLoginUserAddress.address)
      }
    },
  })

  const handleClick = (event) => {
    if (!onlyPrimary) {
      setAnchorEl(event.currentTarget)
    } else {
      addAddress(AddressType.primaryContact);
    }
  }

  const listAddressForLoginOrSource = (isRefetch) => {
    if (isLoginUser) {
      listLoginUserAddress({
        variables: {
          pagination: {
            page: 1,
            limit: 500,
            asc: false
          }
        }
      });
    } else {
      if (address != undefined && address != null && !isRefetch) {
        setUserAddressDetails(address)
      } else {
        listAddress({
          variables: {
            sourceId: sourceId,
            pagination: {
              page: 1,
              limit: 500,
              asc: false
            }
          }
        });
      }
    }
  }

  useEffect(() => {
    listAddressForLoginOrSource(false);
  }, [])

  const setUserAddressDetails = (data) => {
    setUserAddresses([]);
    let dbAddressTypes = []
    if (data.length > 0) {
      data.map((address) => {
        if (address?.addressId) {
          dbAddressTypes.push(address.addressType)
          setUserAddresses(prevState => ([
            ...prevState,
            {
              addressId: address.addressId,
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2,
              country: address.country,
              addressType: address.addressType,
              postalCode: address.postalCode,
              state: address.state,
              cityTown: address.cityTown,
              landmark: address.landmark,
              attention: address.attention,
              phone: address.phone,
              email: address.email,
            }
          ]))
        }
      })
      //check primary contact is already present for the person
      if (dbAddressTypes.includes(AddressType.primaryContact)) {
        setIsPrimaryContactPresent(true);
      } else {
        setIsPrimaryContactPresent(false);
      }
    } else if(newAddress) {
        addOtherAddress(addressType);
    }
  }

  const handleClose = (event) => {
    setAnchorEl(null);
    setOther(false);
    setError({ show: false, message: '' });
    setNewAddressType('');
  }

  const addNewAddress = (e) => {
    let value = e.target.value
    if (value !== '' && value.trim() === '') {
      setError({ show: true, message: t('Value_can_not_be_empty') });
      setNewAddressType(value);
      return;
    }
    if (isPrimaryContactPresent && AddressType.primaryContact.toLocaleLowerCase() === value.toLocaleLowerCase()) {
      setError({ show: true, message: t('PrimaryContact_is_already_present') });
      setNewAddressType(value);
      return;
    }
    if (value !== '' && value.trim().length > 50) {
      setError({ show: true, message: t('Exceed_maximum_limit_of_50_characters') });
      setNewAddressType(value);
      return;
    }
    setError({ show: false, message: '' });
    setNewAddressType(value);
  }

  const addOtherAddress = (newAddressType) => {
    if (newAddressType.trim() === '') {
      setError({ show: true, message: t('Please_enter_Address_Type') })
    } else {
      addAddress(newAddressType)
    }
  }

  const addAddress = (newAddressType) => {
    handleClose();
    setUserAddresses(prevState => ([
      ...prevState,
      {
        addressId: '',
        addressLine1: newAddressDefaultData?.addressLine1? newAddressDefaultData.addressLine1 : '',
        addressLine2: newAddressDefaultData?.addressLine2? newAddressDefaultData.addressLine2 : '',
        addressType: newAddressType.trim(),
        country: COUNTRY_DEFAULT,
        postalCode: newAddressDefaultData?.postalCode? newAddressDefaultData.postalCode : '',
        state: newAddressDefaultData?.state? newAddressDefaultData.state : '',
        cityTown: newAddressDefaultData?.cityTown? newAddressDefaultData.cityTown : '',
        landmark: newAddressDefaultData?.landmark? newAddressDefaultData.landmark : '',
        attention: newAddressDefaultData?.attention? newAddressDefaultData.attention : '',
        phone: '',
        email: '',
      }
    ]))
    setDisableOnEdit(true)
    setValue(userAddresses.length)
    setNewAddressType('')
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }

  const setAddressTab = (event) => {
    let addressData = { ...userAddresses[value] }
    addressData.addressLine1 = event.addressLine1
    addressData.addressLine2 = event.addressLine2
    addressData.country = event.country
    addressData.addressType = event.addresss_Type
    addressData.postalCode = event.postalCode
    addressData.state = event.state
    addressData.cityTown = event.cityTown
    addressData.landmark = event.landmark
    addressData.attention = event.attention
    addressData.phone = event.phone
    addressData.email = event.email
    userAddresses[value] = addressData
    setUserAddresses(userAddresses)
  }

  const deleteNewAddress = async (e) => {
    let addressListses = userAddresses
    if (addressListses.at(-1).addressId == '') {
      addressListses.splice(-1, 1)
      setValue(0)
      setUserAddresses(addressListses);
    }
  }

  const refreshlistAddress = (index) => {
    listAddressForLoginOrSource(true);
    if (index != null) {
      setValue(index);
    }
  }

  return (
    <div>
      {(!loadingAddress || !loadingLoginUserAddress) &&
        <>
          {(hasPermission === true && (allowMultiple || (!allowMultiple && userAddresses.length === 0))) && <div>
            <Fab style={{ float: 'right' }} size='medium' color="primary" aria-label="add" id="addressplus"
              onClick={handleClick} disabled={disableOnEdit}>
              <Add />
            </Fab>
          </div>}
          {!showCancel && <Grid item xs={12} style={{ textAlign: 'center' }} className="loading-container">
            <Box sx={{ bgcolor: 'background.paper' }} justifyContent="center">
              <Tabs value={value}
                variant="scrollable"
                scrollButtons='auto'
                allowScrollButtonsMobile
                aria-label="address tabs"
                id="addresstabs"
                onChange={handleTabChange}>
                {userAddresses.map((e, index) => {
                  return <Tab disabled={disableOnEdit} label={e.addressType} index={index} key={index} style={{ maxWidth: '265px', wordBreak: 'break-all' }} />
                })}
              </Tabs>
            </Box>
          </Grid>}
          {userAddresses.map((e, index) => {
            return <TabPanel key={index} value={value} index={index}>
              <AddressTab data={e} setData={setAddressTab} closeModels={deleteNewAddress} setOpenAddress={setOpenAddress} requiredShowFields={requiredShowFields} showContact={props.showContact}
                isLoginUser={isLoginUser} sourceId={sourceId} refreshlistAddress={refreshlistAddress} isEdit={disableOnEdit} setIsEdit={setDisableOnEdit}
                hasPermission={hasPermission} deletePermission={(atlestOneMandatory ? (atlestOneMandatory && userAddresses.length > 1) : true)}
                listLoading={loadingAddress} showCancel={showCancel} /></TabPanel>
          })}
          {userAddresses.length === 0 &&
            <JustifyBox>
              <Typography variant='h7' color='GrayText' marginTop='2rem' textAlign='center'>
                {t('No_Address_at_the_moment')}
              </Typography>
            </JustifyBox>}
        </>
      }
      <Popover
        id={popperId}
        open={opens}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List style={{ paddingTop: '0px' }}>
          <Typography color='GrayText' sx={{ p: '8px 16px', background: '#F0F8FF' }}>{t('Select_the_Address_Type')}</Typography>
          <Divider flexItem />
          {!isPrimaryContactPresent && <ListItemButton disabled={!opens} onClick={(e) => { opens = false; addAddress(AddressType.primaryContact) }}>
            {t('Primary_Contact')}</ListItemButton>}
          <ListItemButton disabled={!opens} onClick={(e) => {
            opens = false; addAddress('Registered Office Address'); }}>{t('Registered_Office_Address')}</ListItemButton>
          <ListItemButton disabled={!opens} onClick={(e) => {
            opens = false; addAddress('Billing');}}>{t('Billing')}</ListItemButton>
          <ListItemButton disabled={!opens} onClick={(e) => {
            opens = false; addAddress('Shipping');}}>{t('Shipping')}</ListItemButton>
          <ListItemButton disabled={!opens} onClick={(e) => {
            opens = false; setOther(true);}}>{t('Other')}</ListItemButton>
          {other &&
            <Fragment>
              <Divider flexItem />
              <ListItem>
                <Stack spacing={1.2}>
                  <Typography>{t('Please_enter_for_Other')}</Typography>
                  <TextField label={t('Address_Type')} autoFocus placeholder={t('Shipping_Billing')} value={newAddressType}
                    error={error.show} helperText={error.message} onChange={addNewAddress} />
                  <Button color="primary" id="typeaddress" variant="contained" className="float-right" onClick={() => addOtherAddress(newAddressType)}>{t('Add_Other')}</Button>
                </Stack>
              </ListItem>
            </Fragment>
          }
        </List>
      </Popover>
      {(loadingAddress || loadingLoginUserAddress) && <AuctionLoader show={(loadingAddress || loadingLoginUserAddress)} />}
      {fetchError && <GraphQLErrors error={fetchError} show={false} />}
      {fetchLoginUserError && <GraphQLErrors error={fetchLoginUserError} show={false} />}
    </div>
  )
}

export default Address