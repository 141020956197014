import axios from "axios";
import { decrypt, encrypt } from './encrypt-decrypt';

export const AxiosAuctionInterceptor = axios.create({
    baseURL: process.env.REACT_APP_AWS_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const APIAxiosAuctionInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    // headers: { Accept: 'application/json' }
})

export const PublicAxiosAuctionInterceptor = axios.create({
    baseURL: process.env.REACT_APP_AWS_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const PaymentAxiosAuctionInterceptor = axios.create({
    baseURL: 'http://localhost:9037/',
    headers: { Accept: 'application/json' }
})

export const AuthAxiosAuctionInterceptor = axios.create({
    baseURL: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const LookUpAxiosAuctionInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_LOOK_UP_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const AuctionAxiosDownloadInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    responseType: 'arraybuffer'
})

AxiosAuctionInterceptor.interceptors.request.use((config) => {
    if (!config.url.includes('s3.ap-south-2') && !config.url.includes('eauction.s3')) {
        config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
        config.headers['Content-Type'] = 'application/json';
        if (config.data) {
            config.data = encrypt(JSON.stringify(config.data));
        }
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

PublicAxiosAuctionInterceptor.interceptors.request.use((config) => {
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    if (error.response.data && error.response.data.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data && error.response.data.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

AxiosAuctionInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosAuctionInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosAuctionInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                return Promise.reject({message: 'refresh-token-expired'})
            }
        })
    } else if(typeof  window !== undefined && !window.navigator.onLine) {
        return Promise.reject({ message: 'Please Check internet connection' });
    } else if(error.response.status === 503) {
        return Promise.reject({ message: 'Service unavailable' });
    } else if (error.response.data && error.response.data.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data && error.response.data.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

PublicAxiosAuctionInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    if (error.config.url === 'token' && (error.response.status === 401 || error.response.status === 500)) {
        return Promise.reject({message: 'refresh-token-expired'})
    }else if(typeof  window !== undefined && !window.navigator.onLine) {
        return Promise.reject({ message: 'Please Check internet connection' });
    } else if(error.response.status === 503) {
        return Promise.reject({ message: 'Service unavailable' });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

AuthAxiosAuctionInterceptor.interceptors.request.use((config) => {
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    if (error.response.data && error.response.data.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data && error.response.data.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

AuthAxiosAuctionInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    if (error.response.data && error.response.data.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

APIAxiosAuctionInterceptor.interceptors.request.use((config) => {
    if (!config.url.includes('s3.ap-south-2') && !config.url.includes('eauction.s3')) {
        config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

AuctionAxiosDownloadInterceptor.interceptors.request.use((config) => {
    if (!config.url.includes('s3.ap-south-2') && !config.url.includes('eauction.s3')) {
        config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

APIAxiosAuctionInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosAuctionInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosAuctionInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                return Promise.reject({message: 'refresh-token-expired'})
            }
        })
    } else if(typeof  window !== undefined && !window.navigator.onLine) {
        return Promise.reject({ message: 'Please Check internet connection' });
    } else if(error.response.status === 503) {
        return Promise.reject({ message: 'Service unavailable' });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

AuctionAxiosDownloadInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    console.log('error.response.data', error.response.data);
    error.response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)));
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosAuctionInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosAuctionInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                return Promise.reject({message: 'refresh-token-expired'})
            }
        })
    } else if(typeof  window !== undefined && !window.navigator.onLine) {
        return Promise.reject({ message: 'Please Check internet connection' });
    } else if(error.response.status === 503) {
        return Promise.reject({ message: 'Service unavailable' });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

LookUpAxiosAuctionInterceptor.interceptors.request.use((config) => {
    return config
}, (error) => {
    if (error.response.data && error.response.data.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data && error.response.data.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})

LookUpAxiosAuctionInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    if (error.config.url === 'token' && (error.response.status === 401 || error.response.status === 500)) {
        return Promise.reject({message: 'refresh-token-expired'})
    } else if(typeof  window !== undefined && !window.navigator.onLine) {
        return Promise.reject({ message: 'Please Check internet connection' });
    } else if(error.response.status === 503) {
        return Promise.reject({ message: 'Service unavailable' });
    } else if (error.response.data && error.response.data.message) {
        return Promise.reject({ message: error.response.data.message });
    } else if (error.response.data && error.response.data.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else {
        return Promise.reject(error);
    }
})