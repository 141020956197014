import { Box, Button, Card, Chip, FormControlLabel, styled } from '@mui/material';

//Style Start
export const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

export const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))

export const JustifyBetweenBox = styled(FlexBox)(() => ({
    justifyContent: 'space-between',
}))

export const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.01)',
}))

export const AlertInfoBox = styled(FlexBox)(() => ({
    padding: '10px 10px 5px 0px',
    color: 'orangered',
    justifyContent: 'flex-start',
    fontSize: '13px'
}))

export const IMG = styled('img')(() => ({
    width: '100%',
}))

export const RootDiv = styled(JustifyBox)(() => ({
    background: '#dee7f1',
    minHeight: '100vh !important',
    '& .card': {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
        boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
    },
}))

export const RadioCard = styled(JustifyBox)(({ selected }) => ({
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: 12,
    padding: '0.5rem',
    border: selected ? '2px solid #1976d2' : '2px solid #e1e2e7',
    background: selected ? '#def0ff' : 'inherit',
    margin: '0.5rem',
    '&: hover': {
        backgroundColor: '#def0ff',
        border: '2px solid #1976d2'
        // color: '#9e9e9e'
    },
    '& .icon:hover': {
        color: '#1976d2'
    },
    '& .text:hover': {
        color: '#1976d2'
    },
    '& .icon': {
        color: selected ? '#1976d2' : 'currentColor'
    },
    '& .text': {
        color: selected ? '#1976d2' : 'currentColor'
    },
    '&[disabled]': {
        pointerEvents: 'none',
        opacity: 0.7
    }

    // boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
}))

export const FormControlRadioLabel = styled(FormControlLabel)(({ selected }) => ({
    borderRadius: 25,
    border: selected ? '2px solid #1976d2' : '2px solid #e1e2e7',
    backgroundColor: selected ? '#def0ff' : 'inherit',
    marginLeft: '0px !important',
    // '&: hover': {
    //     backgroundColor: '#def0ff',
    //     border: '2px solid #1976d2',
    //     '& .icon': {
    //         color: '#1976d2'
    //     },
    //     '& .text': {
    //         color: '#1976d2'
    //     },
    // },
    '& .icon': {
        color: selected ? '#1976d2' : 'currentColor'
    },
    '& .text': {
        color: selected ? '#1976d2' : 'currentColor'
    },
    '&[disabled]': {
        pointerEvents: 'none !important',
        opacity: 0.7
    }

    // boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
}))

export const AuctionFieldSet = styled('fieldset')(({ theme }) => ({
    background: theme.palette.type === "light" ? '#fff' : '#1e1d1d',
    borderRadius: '4px',
    padding: '1rem',
    border: '0px',
    boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px',
    lineHeight: '0.3 !important',
    border: theme.palette.type === "light" ? '1px solid #eae7e7' : '1px solid #575757'
}))

export const AuctionLengend = styled('legend')(() => ({
    background: '#1976d2',
    color: '#fff',
    borderRadius: '10px',
    padding: '5px 10px',
    fontSize: '12px',
    lineHeight: '1 !important'
}))

export const AuctionCard = styled(Card)(() => ({
    boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 46px 50px -22px rgb(50 50 93 / 25%), 0 10px 40px -15px rgb(0 0 0 / 30%)',
    borderRadius: '0.5rem'
}))

export const GreenChip = styled(Chip)(() => ({
    background: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
    color: 'rgb(255, 255, 255)',
    padding: '9px',
    borderRadius: '50px',
    fontSize: '15px',
    boxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem !important',

}))

export const ReUploadButton = styled(Button)(() => ({
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    boxShadow: '0px 1px 5px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
    '&: hover': {
        boxShadow: '0px 1.7px 2.2px rgba(0, 0, 0, 0.02), 0px 4px 5.3px rgba(0, 0, 0, 0.028), 0px 7.5px 10px rgba(0, 0, 0, 0.035), 0px 13.4px 17.9px rgba(0, 0, 0, 0.042), 0px 25.1px 33.4px rgba(0, 0, 0, 0.05), 0px 60px 80px rgba(0, 0, 0, 0.07) !important '
    },
}))