import { BadgeRounded, CurrencyRupeeRounded, DifferenceRounded, FontDownloadRounded, RemoveCircleRounded, WarningRounded } from '@mui/icons-material';
import { Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grow, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { FaPercentage } from 'react-icons/fa';
import { FcMoneyTransfer } from 'react-icons/fc';
import { HiCurrencyRupee, HiIdentification, HiMinusCircle } from 'react-icons/hi';
import { MdNotes } from 'react-icons/md';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import { CurrencyFormatter } from '../utils/Utils';
import { AuctionDetailContext } from '../auction/auction-detail/AuctionDetail';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
    dialogAction: {
        margin: '0 0.9rem 0.2rem 0.9rem !important', justifyContent: 'center !important',
    },
    actionBtn: {
        width: '75% !important', margin: '0.2rem 1.5rem 1rem 1.5rem !important'
    },
    palceBidAlertBox: {
        margin: '10px !important',
        background: '#ffdb99',
        borderRadius: '20px',
        padding: '10px',
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
        textAlign: 'center'
    },
    palceBidAlertText: {
        fontSize: '16px !important',
        fontWeight: '600 !important',
        color: 'black',
        padding: '10px'
    }
}));

const BidFieldsWithIcon = (props) => {
    const { label, value, icon, idLabel, idValue } = props;
    return (
        <Stack direction='row' spacing={2} alignItems='center' px={2}>
            <Stack width='5%'>{icon}</Stack>
            <Typography id={idLabel} sx={{ width: '35%' }}>{label}</Typography>
            <Typography sx={{ width: '3%' }}>:</Typography>
            {value && <Typography id={idValue} sx={{ width: '57%', textTransform: 'capitalize' }}>{value}</Typography>}
        </Stack>
    )
}

function BidConfirmation(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { open, seekConfirmation, loading, bidTransactionId, bidConfirmTime, currentHighestBid, diffInIncreamentVsExtension, currentExtension, baseCurrency } = props;
    // const auctionDetailContext = useContext(AuctionDetailContext);
    const [prevExtension, setPrevExtension] = useState(0);

    const handleDisAgree = () => {
        seekConfirmation.onDisAgree();
    };

    const handleAgree = () => {
        if (bidTransactionId != null) {
            seekConfirmation.onAgree(bidTransactionId);
        } else {
            seekConfirmation.onAgree();
        }
    };

    useEffect(() => {
        setPrevExtension(currentExtension);
    }, [])

    return (
        <Dialog open={open} maxWidth='md'>
            <DialogTitle sx={{ minWidth: '50vw !important' }}>
                <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
                    <BsFillPatchCheckFill style={{ color: 'green', fontSize: '3rem' }} />
                    <Typography id={seekConfirmation.title} variant='h5'>{seekConfirmation.title}</Typography>
                </Stack>
            </DialogTitle>
            {/* <Divider /> */}
            <DialogContent>
                <Card elevation={0} sx={{ boxShadow: 'rgb(0 0 0 / 5%) 0px 2px 24px !important', border: '1px solid #eeeeee' }}>
                    <Stack spacing={2} py={2}>
                        <BidFieldsWithIcon label={t('Auction_ID')} value={seekConfirmation.auctionId}
                            idLabel='auctionidlabel' idValue='auctionidvalue'
                            icon={<BadgeRounded style={{ fontSize: "1.5rem", color: 'rgb(112, 112, 112)' }} />} />
                        <Divider flexItem />
                        <BidFieldsWithIcon label={<Fragment>{t('Bid_Amount')}</Fragment>} value={<Fragment>{CurrencyFormatter(baseCurrency, seekConfirmation.bidPrice)}</Fragment>}
                            idLabel='bidpricelabel' idValue='bidpricevalue'
                            icon={<HiCurrencyRupee style={{ fontSize: "1.4rem", color: 'rgb(112, 112, 112)' }} />} />
                        <Divider flexItem />
                        <BidFieldsWithIcon label={t('Bid_Amount_In_Words')} value={seekConfirmation.priceInWords}
                            idLabel='bidinwordslabel' idValue='bidinwordsvalue'
                            icon={<FontDownloadRounded style={{ fontSize: "1.3rem", color: 'rgb(112, 112, 112)' }} />} />
                        { seekConfirmation.agreeBtnLabel === t('Re_Confirm') ?
                            <Fragment>
                                {currentHighestBid >= seekConfirmation.bidPrice || (currentHighestBid < seekConfirmation.bidPrice &&
                                    diffInIncreamentVsExtension && prevExtension == 0 && currentExtension > prevExtension) ?
                                    <Fragment>
                                        {currentHighestBid >= seekConfirmation.bidPrice ?
                                            <Fragment>
                                                <Divider flexItem />
                                                <Stack direction='column' alignItems='center' className={classes.palceBidAlertBox}>
                                                    <WarningRounded style={{ fontSize: "2rem", color: 'black' }} />
                                                    <Typography variant='body2' className={classes.palceBidAlertText}>{t('CurrentHighestBidIsGreaterThanYour')}</Typography>
                                                </Stack>
                                            </Fragment>
                                        :
                                            <Fragment>
                                                <Divider flexItem />
                                                <Stack direction='column' alignItems='center' className={classes.palceBidAlertBox}>
                                                    <WarningRounded style={{ fontSize: "2rem", color: 'black' }} />
                                                    <Typography variant='body2' sx={{paddingBottom: '0px'}} className={classes.palceBidAlertText}>{t('Auction_Has_Been_Extended')}</Typography>
                                                    <Typography variant='body2' className={classes.palceBidAlertText}>{t('Required_To_Place_Fresh_Bid')}</Typography>
                                                </Stack>
                                            </Fragment>
                                        }
                                    </Fragment>
                                :
                                    <Fragment>
                                        {seekConfirmation.bidPercentage !== 0 &&
                                            <Fragment>
                                                <Divider flexItem />
                                                <BidFieldsWithIcon label={t('Difference_From_Current_Highest_Bid_Amount')} value={<Fragment>{CurrencyFormatter(baseCurrency, seekConfirmation.bidDifferenceInPrice)}</Fragment>}
                                                    idLabel='diffpricelabel' idValue='diffpricevalue'
                                                    icon={<DifferenceRounded style={{ fontSize: "1.35rem", color: 'rgb(112, 112, 112)' }} />} />
                                                {/* <Divider flexItem />
                                                <BidFieldsWithIcon label={t('Bid_Difference_percent')} value={<Fragment>{seekConfirmation.bidPercentage}&#37;</Fragment>}
                                                    icon={<FaPercentage color='orange' style={{ fontSize: "1.3rem" }} />} /> */}
                                            </Fragment>
                                        }
                                        <Divider flexItem />
                                        <BidFieldsWithIcon label={t('Bid_Confirmed_Time')} value={ (bidTransactionId != null && bidConfirmTime != null) ? (format(new Date(bidConfirmTime), ' dd MMM yyyy hh:mm:ss a')) : null}
                                            idLabel='bidConfirmedTime' idValue='bidConfirmedTime'
                                            icon={<AccessTimeFilledOutlinedIcon style={{ fontSize: "1.3rem", color: 'rgb(112, 112, 112)' }} />}
                                        />
                                    </Fragment>
                                }
                            </Fragment> 
                        :
                            <Fragment>
                                { currentHighestBid >= seekConfirmation.bidPrice || (currentHighestBid < seekConfirmation.bidPrice &&
                                    diffInIncreamentVsExtension && prevExtension == 0 && currentExtension > prevExtension) ?
                                    <Fragment>
                                        {currentHighestBid >= seekConfirmation.bidPrice ?
                                            <Fragment>
                                                <Divider flexItem />
                                                <Stack direction='column' alignItems='center' className={classes.palceBidAlertBox}>
                                                    <WarningRounded style={{ fontSize: "2rem", color: 'black' }} />
                                                    <Typography variant='body2' className={classes.palceBidAlertText}>{t('CurrentHighestBidIsGreaterThanYour')}</Typography>
                                                </Stack>
                                            </Fragment>
                                        :
                                            <Fragment>
                                                <Divider flexItem />
                                                <Stack direction='column' alignItems='center' className={classes.palceBidAlertBox}>
                                                    <WarningRounded style={{ fontSize: "2rem", color: 'black' }} />
                                                    <Typography variant='body2' sx={{paddingBottom: '0px'}} className={classes.palceBidAlertText}>{t('Auction_Has_Been_Extended')}</Typography>
                                                    <Typography variant='body2' className={classes.palceBidAlertText}>{t('Required_To_Place_Fresh_Bid')}</Typography>
                                                </Stack>
                                            </Fragment>
                                        }
                                    </Fragment>
                                :
                                    <Fragment>
                                        {seekConfirmation.bidPercentage !== 0 &&
                                            <Fragment>
                                                <Divider flexItem />
                                                <BidFieldsWithIcon label={t('Difference_From_Current_Highest_Bid_Amount')} value={<Fragment>{CurrencyFormatter(baseCurrency, seekConfirmation.bidDifferenceInPrice)}</Fragment>}
                                                    idLabel='diffpricelabel' idValue='diffpricevalue'
                                                    icon={<DifferenceRounded style={{ fontSize: "1.35rem", color: 'rgb(112, 112, 112)' }} />} />
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        {/* <BidFieldsWithIcon label={t('Current_Time')} value={format(new Date(auctionDetailContext.auctionGetDetail.currentTime), ' dd MMM yyyy hh:mm a')}
                            idLabel='currentTime' idValue='currentTime'
                            icon={<AccessTimeFilledOutlinedIcon style={{ fontSize: "1.3rem", color: 'rgb(112, 112, 112)' }} />}
                        /> */}
                    </Stack>
                </Card>
            </DialogContent>
            {open && <DialogActions className={classes.dialogAction}>
                { currentHighestBid >= seekConfirmation.bidPrice || (currentHighestBid < seekConfirmation.bidPrice &&
                    diffInIncreamentVsExtension && prevExtension == 0 && currentExtension > prevExtension) ?
                    <Button onClick={handleDisAgree} id="popup-no" variant='outlined'>{t('Close')}</Button>
                :
                    <Fragment>
                        <Button onClick={handleDisAgree} id="popup-no" variant='outlined' className={classes.actionBtn}> {seekConfirmation.disAgreeBtnLabel}</Button>
                        <Button onClick={handleAgree} id="popup-yes" variant='contained' autoFocus className={classes.actionBtn}
                            disabled={seekConfirmation.agreeBtnLabel === t('Re_Confirm') ? (loading || bidTransactionId === null) : false}> {seekConfirmation.agreeBtnLabel}
                            {(loading) && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                        </Button>
                    </Fragment>
                }
            </DialogActions>
            }
        </Dialog>
    )
}

export default BidConfirmation;
