import React from 'react';
import { Select, InputLabel, FormHelperText, FormControl } from '@mui/material';
import { Controller } from "react-hook-form";

export const SelectControl = (props) => {
    const { errors, control, name, rules, label, children, mandatory, setValue, getValues, disabled } = props;
    return (<FormControl variant="standard" fullWidth required={mandatory}>
        <InputLabel>{label}</InputLabel>
        <Controller
            errors={errors}
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => {
                return <Select id={name} {...field} value={getValues(name)} 
                        disabled={disabled}>
                    {children}
                </Select>
            }}
        />
        <FormHelperText>{errors[name] && errors[name].message}</FormHelperText>
    </FormControl>
    );
}