import React, {
    useState,
    useEffect,
    Fragment, useContext
} from 'react';
import {
    Grid,
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    BottomNavigation,
    Link,
    Tooltip,
    ButtonGroup,
    InputBase
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/AddOutlined';
import AddBidder from '../add-bidder/AddBidder';
import FieldHelperText from '../../common/FieldHelperText';
import {
    useLazyQuery,
    useMutation
} from '@apollo/client';
import {
    LIST_BIDDER,
    GET_PRE_SIGNED_URL,
    SEARCH_BIDDER
} from '../../GraphQL/Queries';
import AuctionPagination from '../../common/pagination/AuctionPagination';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import UpdateBidder from '../update-bidder/UpdateBidder';
import BidderRow from './BidderRow';
import BidderDetails from '../bidder-details/BidderDetails';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import {
    AxiosAuctionInterceptor,
    PublicAxiosAuctionInterceptor,
    APIAxiosAuctionInterceptor
} from '../../config/AxiosAuctionInterceptor';
import UploadDialog from '../../common/files-upload/UploadDialog';
import { excelAcceptableMimeType } from '../../common/files-upload/FileUploadStaticVar';
import axios from 'axios';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import {
    SEND_CREDETIALS_TO_BIDDER
} from '../../GraphQL/Mutation';
import SendIcon from '@mui/icons-material/Send';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import { BidderStatus, AuctionStatus } from '../../common/StaticVariableDeclaration';
import SearchIcon from '@mui/icons-material/Search';
import {
    Search,
    SearchIconWrapper,
    StyledInputBase
} from '../../common/search/ListSearch';
import TourIcon from '@mui/icons-material/Tour';
import CustomizedTourComponent from '../../common/customized-tour-component/CustomizedTourComponent';


const useStyles = makeStyles((theme) => ({
    paper: {
        color: "rgb(26, 32, 39)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '10px',
        border: "1px solid rgb(238, 238, 238)",
        backgroundImage: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,
            "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    button: {
        margin: '4px !important'
    },
    textIconContainer: {
        display: 'flex'
    },
    textIcon: {
        fontSize: '1.2rem !important',
        paddingRight: '3px'
    },
    statusText: {
        fontWeight: 'bold',
        fontSize: '0.7rem'
    }
}))

const ListBidder = ({ auction_id, seller_id, canAdd, canEdit, canChangeStatus, lot_id, showFilters, sendCredentials }) => {
    const { t } = useTranslation(); 
    const classes = useStyles();
    const [showAddBidder, setShowAddBidder] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [showBidderDetails, setShowBidderDetails] = useState({
        show: false,
        bidder_id: '',
        isEdit: false
    });
    const [bidders, setBidders] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [noOfBidders, setNoOfBidders] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortByStatus, setSortByStatus] = useState(BidderStatus.all)
    const [refreshSearch, setRefreshSearch] = useState(true);
    const [searchText, setSearchText] = useState(null);
    const auctionDetailContext = useContext(AuctionDetailContext);

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const columns = [
        { name: 'Name', width: '18%', helperText: '' },
        { name: 'Bidder_Type', width: '12%', helperText: '' },
        { name: 'Contact', width: '18%', helperText: '' },
        { name: 'Authorized_Representative', width: '18%', helperText: '' },
        { name: 'Status', width: '14%', helperText: '' }
    ]

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [tourGuide, setTourGuide] = useState({
        triggerEvent: false
    });

    const [tour, setTour] = useState({
        run: false,
        steps: []
    });
    
    const addTourSteps = (items) => {
        console.log('addTourSteps', tour)
        setTour({
            run: false,
            steps: []
        });
        setTimeout(() => {
            setTour({
                run: true,
                steps: items
            })
        }, 0);
    }

    const [listBidders, { loading: fetchingBidders, error: errorOnFetchingBidders, refetch }] = useLazyQuery(LIST_BIDDER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setBidders([]);
            if (data.listBidder != null) {
                setBidders(data.listBidder.bidder);
                setNoOfBidders(data.listBidder.count);
            }
        }
    })

    const [sendCredetials, { loading: sendingCredentials, error: errorOnSendingCredentials }] = useMutation(SEND_CREDETIALS_TO_BIDDER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setMessage({ show: false, message: '', severity: '' })
            if (data.createAccountsForAllBidder !== null && data.createAccountsForAllBidder.createdAccount > 0) {
                setMessage({
                    show: true,
                    message: `${t('Total')} ${data.createAccountsForAllBidder.createdAccount} ${t('accounts_created')} ${t('and')} ${t('Sent_Credentials_Successfully')}`,
                    severity: 'success'
                });
            } else if(data.createAccountsForAllBidder !== null && data.createAccountsForAllBidder.createdAccount == 0) {
                setMessage({
                    show: true,
                    message: t('Currently_All_Bidder_Authrep_Are_Account_Holders'),
                    severity: 'success'
                });
            }
        },
        refetchQueries: [LIST_BIDDER]
    })


    const [searchBidders, { loading: searchingBidders, error: errorOnSearchingBidders }] = useLazyQuery(SEARCH_BIDDER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setBidders([]);
            if (data.searchBidder != null) {
                setBidders(data.searchBidder.bidder);
                setNoOfBidders(data.searchBidder.count);
            }
        }
    })

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const onRowsPerPageChange = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    const addBidder = () => {
        setShowAddBidder(true);
    }

    const showBidderInformation = (showBidder) => {
        setShowBidderDetails({
            show: true,
            bidder: showBidder.bidder,
            isEdit: showBidder.isEdit
        })
    }

    const resetShowBidder = () => {
        setShowBidderDetails({
            show: false,
            bidder: '',
            isEdit: false
        })
        refetch();
    }

    useEffect(() => {
        listBidders({
            variables: {
                auctionId: auction_id,
                bidderStatus: sortByStatus,
                pagination: {
                    page: page,
                    limit: rowsPerPage,
                    asc: false
                }
            }
        })
    }, [page, rowsPerPage, sortByStatus])


    useEffect(() => {
        setRefreshSearch(false);
        setTimeout(() => {
            setRefreshSearch(true);
        }, 100);
    }, [sortByStatus])


    if (page === 0) {
        setPage(1);
    }

    const handleSortByStatusChange = (sortBy) => {
        setSortByStatus(sortBy);
        setPage(1);
    }

    const bulkUpload = (excelfile) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        const formData = new FormData();
        formData.append("file", excelfile[0]);
        APIAxiosAuctionInterceptor.post(`bulk-upload-bidder/${auction_id}`, formData).then(res => {
            setShowBulkUpload(false);
            refetch();
            setMessage({
                show: true,
                message: t('Excel_Uploaded_Successfully_Refresh_After_Sometime'),
                severity: 'success'
            });
        }).catch(error => {
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Please_Check_Excel_And_Try_Again'),
                    severity: 'error'
                });
            }
            setShowBulkUpload(false);
        });
    }

    const [getPresignedUrl, { loading: gettingPresignedUrl, error: errorOnGettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            download(data.getPresignedUrl);
        }
    });

    const download = (preSignedUrl) => {
        setLoading(true);
        axios({
            url: preSignedUrl,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'bidders.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch((error) => {
            // setMessage({ showMsg: true, message: error, severity: 'error' })
            setLoading(false);
        })
    }

    const downloadExcelFile = (fileName) => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/get-presigned-url/${encodeURI(process.env.REACT_APP_S3_EXCEL_BUCKET)}?fileName=${encodeURI(process.env.REACT_APP_S3_EXCEL_FOLDER + '/' + fileName)}`).then((response) => {
            if (response) {
                download(response)
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' })
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        });

        // getPresignedUrl({ variables: { 
        //     presignedUrl : {
        //         fileName: process.env.REACT_APP_S3_EXCEL_FOLDER + '/' + fileName, 
        //         bucket: process.env.REACT_APP_S3_EXCEL_BUCKET,
        //         httpMethod:'GET', 
        //         versionId:'' 
        //     }
        // }})
    }

    const sendCredentialsToBidder = () => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Send_Credentials_To_Bidders'),
            onAgree: () => {
                sendCredetials({
                    variables: {
                        auctionId: auction_id
                    }
                })
                resetSeekConfirmation()
            },
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const searchBidder = (event) => {
        searchBidders({
            variables: {
                auctionId: auction_id,
                bidderStatus: sortByStatus,
                searchText: event.target.value,
                pagination: {
                    page: 1,
                    limit: rowsPerPage,
                    asc: false
                }
            }
        })
    }

    const updateTourGuide = () => {
        setTourGuide({
            triggerEvent: false,
        });
        setTimeout(() => {
            setTourGuide({
                triggerEvent: true
            });
        }, 0);
    }

    useEffect(() => {
        if (searchText != null) {
            searchBidders({
                variables: {
                    auctionId: auction_id,
                    bidderStatus: sortByStatus,
                    searchText: searchText.trim(),
                    pagination: {
                        page: 1,
                        limit: rowsPerPage,
                        asc: false
                    }
                }
            })
        }
    }, [searchText])

    useEffect(() => {
        if(tourGuide.triggerEvent) {
            let bidderListTourConfig = [];
            if(showFilters) {
                bidderListTourConfig.push(
                    {
                        header: 'All Bidders List',
                        body: 'List of all Bidders irrespective of bidder type',
                        target: '#all-bidders'
                    },
                    {
                        header: 'Approved Bidders List',
                        body: 'List of all bidders who were requested to participate as a bidder got Approved',
                        target: '#approved-bidders'
                    },
                    {
                        header: 'Pending Bidders List',
                        body: 'List of all Bidders who are requested to participate as a bidder',
                        target: '#potential-bidders'
                    },
                    {
                        header: 'Rejected Bidders List',
                        body: 'List all the bidders who were requested to participate as a bidder got Rejected',
                        target: '#rejected-bidders'
                    },
                );
            }
            if(canAdd) {
                bidderListTourConfig.push(
                    {
                    header: 'Add Bidder',
                    body: 'Add one bidder details at a time manually',
                    target: '#add-bidder-btn'
                    },
                    {
                        header: 'Template for Bidder Bulk Upload',
                        body: 'Please download this template, Incase you have multiple bidder details and you want system to handle bidder creation in bulk',
                        target: '#download-bidder-excel-format'
                    },
                    {
                        header: 'Bidder Bulk Upload',
                        body: 'Please upload the updated bidder bulk upload template here, so that system will handle bidder creation in bulk',
                        target: '#upload-bidder-excel-btn'
                    }
                );
                if(bidders.length > 0 && sendCredentials) {
                    bidderListTourConfig.push(
                        {
                            header: 'Send Credentials to Bidders',
                            body: 'Once the bidders are added by manually or bulk upload, You can send login credentials to all the bidders or authorized representative who do not have account ',
                            target: '#send-credentials-btn'
                        }
                    );
                }
            }
            if(showFilters) {
                bidderListTourConfig.push(
                    {
                        header: 'Search Bidder',
                        body: 'Search bidder of specific type with name',
                        target: '#search-bidder-box'
                    }
                )
            }
            addTourSteps(bidderListTourConfig);
        }
    }, [tourGuide.triggerEvent])

    return (
        <div>
            {!showBidderDetails.show && <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                   <Button
                        id='publish-auction-link'
                        variant="contained"
                        startIcon={<TourIcon  />}
                        sx={{ textTransform: 'none'}}
                        onClick={updateTourGuide}
                        className="guide-me-on-left"
                    >{t('Guide_Me')}
                    </Button>
                    {showFilters && <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                        <Button id="all-bidders" color="primary" variant={sortByStatus === BidderStatus.all ? "contained" : "outlined"}
                            onClick={() => handleSortByStatusChange(BidderStatus.all)}>{t('ALL')}</Button>
                        <Button id="approved-bidders" color="success" variant={sortByStatus === BidderStatus.approve ? "contained" : "outlined"}
                            onClick={() => handleSortByStatusChange(BidderStatus.approve)}>{t('APPROVED')}</Button>
                        <Button id="potential-bidders" color="warning" variant={sortByStatus === BidderStatus.potential ? "contained" : "outlined"}
                            onClick={() => handleSortByStatusChange(BidderStatus.potential)}>{t('PENDING')}</Button>
                        <Button id="rejected-bidders" color="error" variant={sortByStatus === BidderStatus.reject ? "contained" : "outlined"}
                            onClick={() => handleSortByStatusChange(BidderStatus.reject)}>{t('REJECTED')}</Button>
                    </ButtonGroup>}
                    {canAdd &&
                        <Fragment>
                            <Button variant="contained" size="small" startIcon={<AddIcon />} style={{ float: 'right' }}
                                onClick={addBidder} className={classes.button} id="add-bidder-btn">
                                {t('Add')}
                            </Button>
                            <Button variant="contained" size="small" startIcon={<FileUploadIcon />} style={{ float: 'right' }}
                                onClick={() => setShowBulkUpload(true)} className={classes.button} id="upload-bidder-excel-btn">
                                {t('Bulk_Upload')}
                            </Button>
                            {bidders.length > 0 && sendCredentials &&
                                <Tooltip title={t('Send_Credentials_To_Bidder')}>
                                    <Button variant="contained" size="small" startIcon={<SendIcon />} style={{ float: 'right' }}
                                        onClick={sendCredentialsToBidder} className={classes.button} id="send-credentials-btn">
                                        {t('Send_Credentials')}
                                    </Button>
                                </Tooltip>
                            }
                            <Link id="download-bidder-excel-format" component="button" variant="body2" onClick={() => downloadExcelFile('Bidder.xlsx')}
                                style={{
                                    float: 'right',
                                    padding: '10px',
                                    fontWeight: 'bold'
                                }}>
                                {t('Bulk_Upload_Template')}
                            </Link>
                        </Fragment>
                    }
                    {(showFilters && refreshSearch) && <Search id='search-bidder-box' style={{ float: 'right', border: '1px solid #8080804a', margin: '4px', borderRadius: '20px', display: 'flex' }}>
                        <SearchIconWrapper style={{ borderRadius: '16px 0px 0px 16px', padding: '4px 0px 0px 8px' }}>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            initialWidth='14ch'
                            expandableWidth='20ch'
                            placeholder={t('Search_By_Name')}
                            inputProps={{
                                'aria-label': 'search',
                                onChange: (event) => setSearchText(event.target.value.replace(/\s+/g, '') === '' ? '' : event.target.value),
                                variant: 'outlined',
                                'id': 'search-bidder',
                                value: searchText
                            }}
                        />
                    </Search>}
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} className={classes.paper}>
                            <Table aria-label="bidder table" stickyHeader id="list-of-bidder">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell /> */}
                                        {columns.map(column => {
                                            return (
                                                <TableCell key={column.name} className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: column.width }}
                                                    id={column.name}>
                                                    {t(column.name)}
                                                    {column.helperText && <FieldHelperText helperText={t(column.helperText)} />}
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell id="action-column" className={`${classes.tableCell} ${classes.tableHead}`} align="center">{t("Actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bidders.length === 0 &&
                                        <TableRow hover>
                                            <TableCell id="no-bidders-row" colSpan={8} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                        </TableRow>
                                    }
                                    {bidders.length > 0 && bidders.map((bidder, index) => {
                                        return (<BidderRow key={bidder.id} index={index} bidder={bidder} auction_id={auction_id}
                                            showBidderDetails={showBidderInformation} canEdit={canEdit} canChangeStatus={canChangeStatus} 
                                            page={page} refetch={refetch}
                                            setPage={setPage} rowsPerPage={rowsPerPage} noOfBidders={noOfBidders}/>)
                                    })
                                    }
                                </TableBody>
                            </Table>
                            {noOfBidders > 0 &&
                                <BottomNavigation style={{ padding: '12px' }}>
                                    <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={noOfBidders}
                                        onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange}
                                        rowsPerPageText={t('Bidders_Per_Page')} rowsText={t('Bidders')} />
                                </BottomNavigation>
                            }
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {showAddBidder && <AddBidder show={showAddBidder} auction_id={auction_id} onClose={setShowAddBidder} />}
            {showBidderDetails.show && <BidderDetails resetShowBidder={resetShowBidder} auction_id={auction_id} bidderDetails={showBidderDetails.bidder}
                seller_id={seller_id} canEdit={canEdit && showBidderDetails.isEdit} lot_id={lot_id} />}
            {(fetchingBidders || gettingPresignedUrl || loading || searchingBidders || sendingCredentials)
                && <AuctionLoader show={fetchingBidders || gettingPresignedUrl || loading || searchingBidders || sendingCredentials} invisible={false} />}
            {errorOnFetchingBidders && <GraphQLErrors error={errorOnFetchingBidders} show={false} />}
            {errorOnSearchingBidders && <GraphQLErrors error={errorOnSearchingBidders} show={false} />}
            {errorOnSendingCredentials && <GraphQLErrors error={errorOnSendingCredentials} show={false} />}
            {errorOnGettingPresignedUrl && <GraphQLErrors error={errorOnGettingPresignedUrl} show={false} />}
            {showBulkUpload && <UploadDialog
                dialogOpen={showBulkUpload}
                setDialogOpen={setShowBulkUpload}
                isMultiple={false}
                title={t('Upload_Bidder_Excel')}
                customFileUpload={bulkUpload}
                acceptableFiles={excelAcceptableMimeType}
            />
            }
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />}
            <CustomizedTourComponent tour={tour} setTour={setTour} />
        </div>
    )
}

export default ListBidder;