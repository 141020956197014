import React, { Fragment, useEffect, useState } from 'react';
import { useCountDown } from '../../common/auction-hook/useCountDown';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

const TimerCard = styled(Card)(({ theme }) => ({
    borderLeft: '6px solid rgb(13, 186, 183)',
    boxShadow: 'rgb(173 221 208 / 9%) 0px 13px 27px -5px, rgb(177 210 201) 0px 8px 16px -8px !important',
    border: '1px solid rgb(173, 221, 212)',
    borderRadius: '10px !important',
    marginLeft: '5px',
    width: '100%',
    height: '50px',
    padding: '0.3rem'
}))

const BidTimerCard = styled(Card)(({ theme }) => ({
    boxShadow: 'rgb(173 221 208 / 9%) 0px 13px 27px -5px, rgb(177 210 201) 0px 8px 16px -8px !important',
    border: '1px solid rgb(173, 221, 212)',
}))

const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        borderColor: '#38d !important',
        boxShadow: '0 0 10px rgb(51 136 221 / 50%)'
    },
    price: {
        fontWeight: '600'
    },
    idChip: {
        background: theme.palette.type === 'light' ? '#f4f4f8' : '#262626',
        padding: '0.1rem 0.4rem',
        borderRadius: '4px',
        width: 'fit-content'
    },
    "@keyframes flicker": {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0.2
        }
    },
    fastFlicker: {
        animationName: '$flicker',
        animationDuration: '1s',
        animationDirection: "alternate",
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
        opacity: '0.4',
        background: `#ffff99 !important`,
        color: `${theme.flashButton.color} !important`
    },
}))

const DisplayTime = ({ time, timeUnit }) => {
    const { t } = useTranslation();
    return (
        <Stack>
            <div id={`value${timeUnit}`} style={{ fontSize: '1rem', fontWeight: "bold", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {time > 9 ? time : '0' + time}
            </div>
            <div id={timeUnit} style={{ fontSize: '12px' }}>{t(timeUnit)}</div>
        </Stack>
    )
}

const DisplayTimeForBid = ({ time, timeUnit }) => {
    const { t } = useTranslation();
    return (
        <Stack direction='column' justifyContent='center' alignItems='center'>
            <div id={`value${timeUnit}`} style={{ fontSize: '16px', fontWeight: "bold", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {time > 9 ? time : '0' + time}
            </div>
            <div id={timeUnit} style={{ fontSize: '12px' }}>{t(timeUnit)}</div>
        </Stack>
    )
}

const DisplayMultiAuctionTime = ({ time, timeUnit }) => {
    const { t } = useTranslation();
    return (
        <Stack direction='column' justifyContent='center' alignItems='center'>
            <div id={`value${timeUnit}`} style={{ fontSize: '16px', fontWeight: "bold", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {time > 9 ? time : '0' + time}
            </div>
        </Stack>
    )
}

const AuctionTimer = (props) => {
    const { date, title, terminated, isBid, setOnlyMinutes, disable, multiAuction } = {
        ...props, disable: props.disable || false
    };
    const [days, hours, minutes, seconds, onlyMinutes] = useCountDown(date);
    const { t } = useTranslation();
    const classes = useStyles();
    const [initialLoad, setInitialLoad] = useState(true);

    // console.log('current', currentDateTime, format(new Date(currentDateTime), 'dd MMM yyyy hh:mm:ss'))

    useEffect(() => {
        if (!initialLoad && seconds === 0 && days === 0 && hours === 0 && minutes === 0 && terminated) {
            terminated();
        }
        // if (setOnlyMinutes) setOnlyMinutes(onlyMinutes);
    }, [days, hours, minutes, seconds])

    useEffect(() => {
        setInitialLoad(false);
    }, [])

    return (
        <Fragment>
            {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) &&
                <Fragment>
                    {isBid ?
                        <BidTimerCard className={(onlyMinutes < 2) ? classes.fastFlicker : ''}>
                            <Stack direction='row' spacing={2} sx={{ padding: '5px 10px' }}>
                                {days > 0 && <Fragment><DisplayTimeForBid time={days} timeUnit={t('Days')} /><Fragment>&nbsp; :</Fragment></Fragment>}
                                {(days > 0 || hours > 0) && <Fragment><DisplayTimeForBid time={hours} timeUnit={t('Hours')} /><Fragment>&nbsp; :</Fragment></Fragment>}
                                {(hours > 0 || minutes > 0) && <Fragment><DisplayTimeForBid time={minutes} timeUnit={t('Mins')} /><Fragment>&nbsp; :</Fragment></Fragment>}
                                {(minutes > 0 || seconds > 0) && <Fragment><DisplayTimeForBid time={seconds} timeUnit={t('Secs')} /></Fragment>}
                            </Stack>
                        </BidTimerCard> :
                        <Fragment>
                            {!disable && !multiAuction && <TimerCard>
                                <Stack direction='row' justifyContent='center' alignItems='center' spacing={0.5} sx={{ height: '100%' }}>
                                    <Typography variant='subtitle2' style={{ textAlign: 'center' }}>{title}</Typography>
                                    <Divider flexItem sx={{ borderRightWidth: 2 }} variant='middle' />
                                    {days > 0 && <span style={{ display: 'flex' }}><DisplayTime time={days} timeUnit={t('Days')} /> : </span>}
                                    {(days > 0 || hours > 0) && <span style={{ display: 'flex' }}><DisplayTime time={hours} timeUnit={t('Hours')} /> : </span>}
                                    {(hours > 0 || minutes > 0) && <span style={{ display: 'flex' }}><DisplayTime time={minutes} timeUnit={t('Mins')} /> : </span>}
                                    {(minutes > 0 || seconds > 0) && <span><DisplayTime time={seconds} timeUnit={t('Secs')} /></span>}
                                </Stack>
                            </TimerCard>}
                        </Fragment>
                    }
                    {/* { multiAuction && 
                        <BidTimerCard className={(onlyMinutes < 2) ? classes.fastFlicker : ''}>
                            <Stack direction='row' spacing={0.1} sx={{ padding: '5px 8px' }}>
                                {days > 0 && <Fragment><DisplayMultiAuctionTime time={days} timeUnit='days' /><Fragment>&nbsp;:&nbsp;</Fragment></Fragment>}
                                {(days > 0 || hours > 0) && <Fragment><DisplayMultiAuctionTime time={hours} timeUnit='hour' /><Fragment>&nbsp;:&nbsp;</Fragment></Fragment>}
                                {(hours > 0 || minutes > 0) && <Fragment><DisplayMultiAuctionTime time={minutes} timeUnit='mins' /><Fragment>&nbsp;:&nbsp;</Fragment></Fragment>}
                                {(minutes > 0 || seconds > 0) && <Fragment><DisplayMultiAuctionTime time={seconds} timeUnit='secs' /></Fragment>}
                            </Stack>
                        </BidTimerCard>
                    } */}
                    { multiAuction && 
                        <BidTimerCard className={(onlyMinutes < 2) ? classes.fastFlicker : ''}>
                            <Stack direction='row' sx={{ padding: '2px 8px', marginTop: '1px' }}>
                                {days > 0 && <Fragment><DisplayMultiAuctionTime time={days} timeUnit='days' /><Fragment>&nbsp;:&nbsp;</Fragment></Fragment>}
                                {(days > 0 || hours > 0) && <Fragment><DisplayMultiAuctionTime time={hours} timeUnit='hour' /><Fragment>&nbsp;:&nbsp;</Fragment></Fragment>}
                                {(hours > 0 || minutes > 0) && <Fragment><DisplayMultiAuctionTime time={minutes} timeUnit='mins' /><Fragment>&nbsp;:&nbsp;</Fragment></Fragment>}
                                {(minutes > 0 || seconds > 0) && <Fragment><DisplayMultiAuctionTime time={seconds} timeUnit='secs' /></Fragment>}
                            </Stack>
                        </BidTimerCard>
                    }
                </Fragment>
            }
        </Fragment >
    )
}

export default AuctionTimer;