import { useMutation } from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/EditOutlined';
import {
    IconButton, TableCell, TableRow, TextField, Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ADD_IDENTIFICATION, DELETE_IDENTIFICATION, UPDATE_IDENTIFICATION } from '../../GraphQL/Mutation';
import AuctionLoader from '../auction-loader/AuctionLoader';
import AuctionConfirmDialog from '../AuctionConfirmDialog';
import { UserContext } from '../context/UserContext';
import GraphQLErrors from '../GraphQLErrors';
import { IdentificationTypes, IdentificationTypeUiCodes, PartyType } from '../StaticVariableDeclaration';
import { Validation } from '../Validation';


const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    inputFiledPadding: {
        "& #identification-type": {
            padding: '0px !important'
        },
        "& #identification": {
            padding: '0px !important'
        }
    },
    tableRow: {
        verticalAlign: 'initial !important'
    }
}))

const ListIdentificationRow = (props) => {
    const {
        identificationDetail, individual, personId, refetch, onSuccess, setCancel, isView, index
    } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const [isNew, setIsNew] = useState(identificationDetail.isNew ? identificationDetail.isNew : false);
    const [isEdit, setIsEdit] = useState(false);
    const { register, getValues, setValue, trigger, control, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        defaultValues: {

        }
    })

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [addIdentificationDetails, { loading: addingIdentificationDetails, error: errorOnAddingIdentificationDetails }] =
        useMutation(ADD_IDENTIFICATION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.addIdentification != null) {
                    setIsNew(false);
                    refetch();
                    onSuccess({ show: true, message: t('Identification_Added_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const [updateIdentification, { loading: updatingIdentificationDetails, error: errorOnUpdatingIdentificationDetails }] =
        useMutation(UPDATE_IDENTIFICATION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateIdentification != null) {
                    setIsNew(false);
                    refetch();
                    onSuccess({ show: true, message: t('Identification_Updated_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const [deleteIdentification, { loading: deletingIdentification, error: errorOnDeletingIdentification }] =
        useMutation(DELETE_IDENTIFICATION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.deleteIdentification) {
                    onSuccess({ show: true, message: t('Identification_Deleted_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const cancel = () => {
        setCancel(true);
    }

    const handleSave = () => {
        if (isNew) {
            addIdentificationDetails({
                variables: {
                    personId: personId,
                    identificationInput: {
                        id: null,
                        identificationType: identificationDetail.identificationType,
                        identification: getValues('identification') == 'string' ? getValues('identification').trim() : getValues('identification'),
                        otherIdentificationType: getValues('otherIdentificationType') == 'string' ? getValues('otherIdentificationType').trim() : getValues('otherIdentificationType')
                    }
                }
            })
        } else {
            updateIdentification({
                variables: {
                    personId: personId,
                    identificationInput: {
                        id: getValues('id'),
                        identificationType: getValues('identificationType'),
                        identification: getValues('identification') == 'string' ? getValues('identification').trim() : getValues('identification'),
                        otherIdentificationType: getValues('otherIdentificationType') == 'string' ? getValues('otherIdentificationType').trim() : getValues('otherIdentificationType')
                    }
                }
            })
        }
    }

    const deleteConfirmation = (selectedId) => {
        setSeekConfirmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Idenification_Number'),
            onAgree: () => handleDelete(selectedId),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const handleDelete = (selectedId) => {
        resetSeekConfirmation();
        if (selectedId) {
            deleteIdentification({
                variables: {
                    personId: personId,
                    id: selectedId
                }
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const handleEdit = (selectedIdentficationDetail) => {
        if (selectedIdentficationDetail) {
            setIsEdit(true);
            setValue('id', selectedIdentficationDetail.id)
            setValue('identificationType', selectedIdentficationDetail.identificationType)
            setValue('otherIdentificationType', selectedIdentficationDetail.otherIdentificationType)
            setValue('identification', selectedIdentficationDetail.identification)
            trigger();
        }
    }

    const handleCase = (event) => {
        if(event.target.placeholder != 'OTHER') {
            setValue('identification', event.target.value.toUpperCase());
        } else {
            setValue('identification', event.target.value);
        }
        trigger('identification');
    }

    return (
        <React.Fragment>
            <TableRow id={`indentification${index}`} className={classes.tableRow}>
                <TableCell id={`identification-type${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                    {((isNew || isEdit) && identificationDetail.identificationType === IdentificationTypeUiCodes.other) &&
                        <TextField id="identification-type" fullWidth className={classes.inputFiledPadding}
                            type="text" variant='outlined' placeholder='Other Type'
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 33,
                                    padding: '5px'
                                }
                            }}
                            {...register("otherIdentificationType", { ...Validation.shortName, ...Validation.required })}
                            helperText={errors["otherIdentificationType"] && errors["otherIdentificationType"].message}
                        />}
                    {(identificationDetail.identificationType === IdentificationTypeUiCodes.pan || identificationDetail.identificationType === IdentificationTypeUiCodes.cin || identificationDetail.identificationType === IdentificationTypeUiCodes.gstin) && <div>{identificationDetail.identificationType}</div>}
                    {(!(isNew || isEdit) && identificationDetail.identificationType === IdentificationTypeUiCodes.other) && <div>{identificationDetail.otherIdentificationType}</div>}

                </TableCell>
                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                    {(isNew || isEdit) && <Controller
                        name="identification"
                        control={control}
                        rules={identificationDetail.identificationType === IdentificationTypeUiCodes.pan ? {...Validation.pan, ...Validation.required} : 
                            identificationDetail.identificationType === IdentificationTypeUiCodes.cin ? { ...Validation.cin, ...Validation.required } : 
                            identificationDetail.identificationType === IdentificationTypeUiCodes.gstin ? { ...Validation.gstin, ...Validation.required } : 
                            identificationDetail.identificationType === IdentificationTypeUiCodes.other && { ...Validation.shortName, ...Validation.required }
                        }
                        render={({ field }) => (
                            <TextField id="identification" fullWidth className={classes.inputFiledPadding}
                                {...field}
                                value={getValues('identification')}
                                type="text" variant='outlined' helperText={errors["identification"] && errors["identification"].message}
                                placeholder={identificationDetail.identificationType === IdentificationTypeUiCodes.pan && 'PAN Number' ||
                                    identificationDetail.identificationType === IdentificationTypeUiCodes.cin && 'CIN Number' ||
                                    identificationDetail.identificationType === IdentificationTypeUiCodes.gstin && 'GSTIN' ||
                                    identificationDetail.identificationType === IdentificationTypeUiCodes.other && 'OTHER'}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 33,
                                        padding: '5px'
                                    }
                                }}
                                onChange={(event) => handleCase(event)}
                                style={{ padding: '0px !important' }}
                            />
                        )}
                    />}
                    {(!isNew && !isEdit) && <div>{identificationDetail.identification}</div>}
                </TableCell>
                {!isView && <TableCell className={`${classes.tableCell} ${classes.tableData}`} align="center">
                    {(isNew || isEdit) && <Tooltip title={t('Save')} arrow>
                        <IconButton id={`identification-done${index}`} disabled={!isValid} aria-label="save" color="success" onClick={handleSave}>
                            <DoneIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(!isNew && !isEdit) && <Tooltip title={t("Edit")}>
                        <IconButton id={`identification-edit${index}`} aria-label="edit" color="primary" onClick={() => handleEdit(identificationDetail)}>
                            <EditIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(!isNew && !isEdit) && <Tooltip title={t("Delete")} >
                        <IconButton id={`identification-delete${index}`} aria-label="delete" color="secondary" onClick={() => deleteConfirmation(identificationDetail.id)}>
                            <DeleteIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(isNew || isEdit) && <Tooltip title={t('Cancel')} arrow>
                        <IconButton id={`identification-cancel${index}`} aria-label="cancel" color="secondary" onClick={cancel} >
                            <ClearIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                </TableCell>}
            </TableRow>
            {(addingIdentificationDetails || updatingIdentificationDetails || deletingIdentification) && <AuctionLoader show={(addingIdentificationDetails
                || updatingIdentificationDetails || deletingIdentification)} invisible={false} />}
            {(errorOnAddingIdentificationDetails || errorOnUpdatingIdentificationDetails || errorOnDeletingIdentification) && <GraphQLErrors error={(errorOnAddingIdentificationDetails ||
                errorOnUpdatingIdentificationDetails || errorOnDeletingIdentification)} show={false} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </React.Fragment>
    )
}

export default ListIdentificationRow;