import React, { 
    useState, 
    useEffect,
    useContext,
    Fragment 
}                                           from 'react';
import { LIST_LOTS }                        from '../../GraphQL/Queries';
import { useLazyQuery }                     from '@apollo/client';
import { makeStyles }                       from '@mui/styles';
import {
    Grid,
    Button
}                                           from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import AddIcon                              from '@mui/icons-material/AddOutlined';
import LotRow                               from './LotRow';
import AuctionLoader                        from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                        from '../../common/GraphQLErrors';
import AddLot                               from '../lot-detail/AddLot';
import { UserContext }                      from '../../common/context/UserContext';
import { PublicAxiosAuctionInterceptor }    from "../../config/AxiosAuctionInterceptor";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '4px !important'
    }
}))

const LotList = ({auction_id, multiLot, canAdd, mannerOfSale, canDelete, canEdit, canUpload}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [noOfLots, setNoOfLots] = useState(0);
    const [lots, setLots] = useState([]);
    const [addLot, setAddLot] = useState(false);
    const userContext = useContext(UserContext).rootContext;
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [listLots, {loading: fetchingLots, error: errorOnFetchingLots}] = useLazyQuery(LIST_LOTS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setLots([]);
            data.listAuctionLot.auctionLot.map(lot => {
                setLots(prevState => ([
                    ...prevState,
                    lot
                ]))
            })
            setNoOfLots(data.listAuctionLot.count);
        }
    })

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
    } 

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            listLots({
                variables: {
                    auctionId: auction_id,
                    pagination: {
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    }
                }
            })
        } else {
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/lot/${auction_id}?page=${page}&limit=${rowsPerPage}&asc=false&order=shuffle`).then((response) => {
                response.auctionLot.map(lot => {
                    setLots(prevState => ([
                        ...prevState,
                        lot
                    ]))
                })
                setNoOfLots(response.count);
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({show: false, message: '', severity: ''})
                setMessage({show: true, message: error, severity: 'error'})
            });
        }
    }, [auction_id, rowsPerPage, page])

    if (page === 0) {
        setPage(1);
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    return (
        <div>
            <Grid container>
                {multiLot && canAdd && <Grid item xs={12}>
                    <Fragment>
                        <Button variant="contained" size="small" startIcon={<AddIcon />} style={{float: 'right'}}
                                onClick={() => setAddLot(true)} className={classes.button}>
                            {t('Add_Lot')}
                        </Button>
                    </Fragment>
                </Grid>}
                <Grid item xs={12}>
                    {lots.length > 0 && lots.map((lot, index) => {
                        return ( <LotRow key={index} lot={lot} noOfLots={lots.length} auction_id={auction_id}
                            mannerOfSale={mannerOfSale} canAdd={canAdd} canDelete={canDelete} canEdit={canEdit}  canUpload={canUpload} />)
                        })
                    }
                </Grid>
            </Grid>
            {addLot && <AddLot show={addLot} onClose={(event) => setAddLot(event)}
                auction_id={auction_id} mannerOfSale={mannerOfSale} onSuccess={showSuccessMsg}/>}
            {(fetchingLots || loading) && <AuctionLoader show={fetchingLots || loading} invisible={false} />}
            {errorOnFetchingLots && <GraphQLErrors error={errorOnFetchingLots} show={false} />}
        </div>
    )
}

export default LotList;