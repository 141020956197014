import React, { createContext, useReducer }  from 'react'
import {themeFullMoon , themeDarkMoon, themeHalfMoon}    from '../../themeMoon/Themes'

export const ThemeContext = createContext();

//HOC
const themeReducer = (state, action) => {
  switch (action.type) {
    case "dark":
      return {theme: themeDarkMoon};
    case "dim":
      return {theme: themeHalfMoon};
    default:
      return {theme: themeFullMoon};
  }
};

export function ThemeProvider({children}) {
  const [state, dispatch] = useReducer(themeReducer, {theme: themeFullMoon});
  return <ThemeContext.Provider value={{ state: state, dispatch: dispatch }}>{children}</ThemeContext.Provider>
}


