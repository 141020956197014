import React, { useEffect, useState }                  from 'react';
import { Typography, Box, Tabs, Tab, Button}           from '@mui/material';
import { useTranslation }                              from 'react-i18next';
import { makeStyles }                                  from "@mui/styles";
import AuctionSellerUpdateDetails                      from './AuctionSellerUpdateDetails';
import { Roles }                                       from '../../common/StaticVariableDeclaration';
import BankDetailList                                  from '../../profile/bank-accounts/bank-detail-list/BankDetailList';

const useStyles = makeStyles((theme) => ({
  tab: {
    textTransform: 'none !important',
    fontWeight: '600 !important'
  }
}));


function TabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const AuctionSellerUpdateTabs = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const goBack = () => {
      props.handleBack();
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Button size="small" variant="outlined" aria-label="back-to-bidders" onClick={goBack} style={{float: 'right',
              position: 'relative', bottom: '15px'}}>
                {t('Back')}
            </Button>
            <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Seller Update Container"
            >
                <Tab id="seller-detail-tab" label={t('Seller_Detail')} className={classes.tab} />
                <Tab id="seller-bank-detail-tab" label={t('Bank_Account')} className={classes.tab} />
            </Tabs> 
            <TabPanel id="seller-detail" style={{backgroundColor: 'white', height: 'auto'}} value={value} index={0}>
                <AuctionSellerUpdateDetails selectedUserId={props.selectedUserId} auctionId={props.auctionId} isView={props.isView} sellerLoginUserId={props.sellerLoginUserId}/>
            </TabPanel>
            <TabPanel id="seller-bank-account" style={{backgroundColor: 'white', height: '75vh'}}  value={value} index={1}>
            <BankDetailList
                auction_id={props.auctionId}
                userId={props.selectedUserId} 
                sellerLoginUserId={props.sellerLoginUserId}
                // canModify={(props.roles.includes(Roles.auctioneer) || props.roles.includes(Roles.support))}
                showInfo={true}
                isView={props.isView}
                showExistingBankDetails={true}
                />
            </TabPanel>
      </Box>
    );

}

export default AuctionSellerUpdateTabs;