import { HttpLink, fromPromise, ApolloLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { PublicAxiosAuctionInterceptor } from './AxiosAuctionInterceptor';
import { decrypt, encrypt } from './encrypt-decrypt';

const httpLink = () => {
    return new HttpLink({
        uri: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
        fetch: requestEncryption
    })
};

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: 'Bearer ' + sessionStorage.getItem('token') || null,
        }
    }));
    return forward(operation);
})

const getNewToken = async () => {
    await PublicAxiosAuctionInterceptor.post('token', {
        'refresh_token': sessionStorage.getItem('refreshToken')
    }).then(response => {
        sessionStorage.setItem('token', response.access_token);
        return response.access_token;
    }).catch(error => {
        if (error.message && error.message === 'refresh-token-expired') {
            setTimeout(() => {
                sessionStorage.clear();
                window.location.replace('/sign-in');
            }, 2000);
            throw new Error('Session has been expired, Please relogin to the application');
        }
    })
}

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            if (err.errorType === 'UnauthorizedException') {
                return fromPromise(
                    getNewToken()
                    // getNewToken().catch(error => {
                        // console.log('*** graphQLErrors-error ****', error)
                    //     return error;
                    // })
                    ).flatMap(() => {
                        return forward(operation);
                    });
            }
        }
    }

    if (networkError) {
        if (networkError.statusCode === 401) {
            return fromPromise(
                getNewToken()
                // getNewToken().catch(error => {
                //     console.log('*** networkError-error ****', error)
                //     return error;
                // })
                ).flatMap(() => {
                    return forward(operation);
                });
        }
    }
});

const requestEncryption = (url, options) => {
    return new Promise(async (resolve, reject) => {
        let req = new XMLHttpRequest();
        req.open(options.method || "get", url);
        for (let i in options.headers) {
            req.setRequestHeader(i, options.headers[i]);
        }
        req.onload = () => {
            resolve(responseDecryption(req));
        };
        req.onerror = reject;
        req.send(process.env.REACT_APP_ENV === 'production' ? encrypt(options.body) : options.body);
    })
};

const responseDecryption = (req) => {
    return {
        ok: ((req.status / 200) | 0) == 1,
        status: req.status,
        statusText: req.statusText,
        url: req.responseURL,
        clone: responseDecryption,
        text: async () => (process.env.REACT_APP_ENV === 'production' ? JSON.stringify(decrypt(req.responseText)) : req.responseText)
    };
}

export { httpLink, errorLink, authMiddleware }