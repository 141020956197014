
import { useLazyQuery, useMutation } from "@apollo/client";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    BottomNavigation, Button, Checkbox, Grid, IconButton, Paper, Slide,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import AuctionLoader from "../common/auction-loader/AuctionLoader";
import AuctionConfirmDialog from "../common/AuctionConfirmDialog";
import AuctionSnackBar from "../common/AuctionSnackBar";
import { UserContext } from "../common/context/UserContext";
import GraphQLErrors from "../common/GraphQLErrors";
import AuctionPagination from "../common/pagination/AuctionPagination";
import { AuctionStatus, Roles } from "../common/StaticVariableDeclaration";
import { ASSIGN_ROLE, REVOKE_ROLE } from "../GraphQL/Mutation";
import { LIST_ROLE } from "../GraphQL/Queries";
import { AssignRole } from "./AssignRole";
import RoleDetails from './RoleDetails';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const useStyles = makeStyles((theme) => ({
    tableColor: {
        border: `0.1px solid ${theme.rolesColorTheme.borderColor}`,
        borderBottom: `0.1px solid ${theme.rolesColorTheme.borderColor} !important`,
        fontWeight: 'bold !important',
        color: `${theme.rolesHeaderColor} !important`,
    },
    avatar: {
        marginLeft: '10px',
        backgroundColor: `${theme.rolesHeaderBachground} !important`,
        width: '34px !important',
        height: '34px !important',
        fontSize: '1rem !important'
    },
    bidderBorder: {
        border: `0.1px solid ${theme.rolesColorTheme.borderColor}`,
        borderBottom: `0.1px solid ${theme.rolesColorTheme.borderColor} !important`,
        padding: '1px !important',
        fontWeight: 'bold !important'
    },
    tableHeader: {
        background: theme.rolesHeaderBachground,
    },
    avatarColor: {
        color: theme.flashButton.color
    },
    mailColor: {
        color: theme.rolesMailColor
    },
    icons: {
        fontSize: '1.25rem !important'
    }
}));

const ManageRolesList = (props) => {
    const { isSystemAdminSupport } = props;
    const classes = useStyles();
    const [users, setUsers] = useState([])
    const context = useContext(UserContext).rootContext;
    const [countUser, setCountUser] = useState(0)
    const { t } = useTranslation();
    const [message, setMessage] = useState({ showMsg: false, message: '', severity: '' })
    const [showRoleHistory, setShowRoleHistory] = useState({ show: false, user: '' })
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const [seekconfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [slide, setSlide] = useState(false)
    const [assignOpen, setAssignOpen] = useState(false)
    const [revokeAuctionRole, setRevokeAuctionRole] = useState(null)
    const [userLoginId, setUserLoginId] = useState(null)
    const [loading, setLoading] = useState(false)

    const [listRole, { error: listError, loading: listLoading }] = useLazyQuery(LIST_ROLE, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setUsers([])
            data.listRole.userRole.map((role) => {
                setUsers(prevState => [
                    ...prevState,
                    {
                        auctionId: role.auctionId,
                        userId: role.userId,
                        name: role.name,
                        loginId: role.loginId,
                        email: role.email,
                        phoneNumber: role.phoneNumber,
                        roleDetails: role.roleDetails.map((roleDetail) => {
                            return {
                                id: roleDetail.id,
                                role: roleDetail.role,
                                startTime: roleDetail.startTime,
                                endTime: new Date(roleDetail.endTime).getFullYear() === 10000 ? '' : roleDetail.endTime,
                                grantedBy: roleDetail.grantedBy,
                                revokedBy: roleDetail.revokedBy,
                                revoker: roleDetail.revoker,
                                granter: roleDetail.granter,
                                active: roleDetail.active
                            }
                        })
                    }
                ])
            })
            setCountUser(data.listRole.count)
        }
    })

    const [assignRoleForUserMutation, { loading: assignRoleLoading, error: assignRoleError }] = useMutation(ASSIGN_ROLE, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setMessage({ showMsg: false, message: '', severity: '' })
            if (data.assignRole != null) {
                setMessage({ showMsg: true, message: t("Role_assigned_successfully"), severity: 'success' })
            }
        },
        refetchQueries: [LIST_ROLE]
    })

    const [revokeRoleMutation, { error: revokeError, loading: revokeLoading }] = useMutation(REVOKE_ROLE, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setMessage({ showMsg: false, message: '', severity: '' })
            if (data.revokeRole != null) {
                if (userLoginId === context.userSession.session.userId && ((revokeAuctionRole.role === Roles.auctioneer) || (revokeAuctionRole.role === Roles.support))) {
                    users.map((user) => {
                        if (user.loginId === userLoginId) {
                            if (user.roleDetails.find(e => e.role == Roles.support && e.active) && user.roleDetails.find(e => e.role == Roles.auctioneer && e.active)) {
                                setMessage({ showMsg: true, message: t("Role_revoked_successfully"), severity: 'success' })
                                setLoading(false)
                            } else (
                                props.handleBackNavigation()
                            )
                        }
                    })
                } else {
                    setMessage({ showMsg: true, message: t("Role_revoked_successfully"), severity: 'success' })
                    setLoading(false)
                }
            }
        },
        refetchQueries: [LIST_ROLE]
    })

    useEffect(() => {
        listRole({
            variables: {
                auctionId: props.auctionId,
                pagination: { limit: itemsPerPage, page: page, asc: false }
            }
        })
    }, [page, itemsPerPage])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setItemPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const confirmRevoke = (existingRole, user_id, role, loginId, name) => {
        setUserLoginId(loginId)
        let roleTypeDescription = ''
        context.enumList.Role.find((roleType) => {
            if (roleType.dateBaseCode == role) {
                roleTypeDescription = roleType.uiCode === 'Viewer' ? "Observer" : roleType.uiCode;
            }
        })
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: existingRole === undefined ?
                <Fragment>{t('Are_You_Sure_That_You_Want_To_Assign')} <b>{roleTypeDescription}</b> {t('Role_For')} <b>{name}?</b></Fragment> :
                <Fragment>{t('Are_You_Sure_That_You_Want_To_Revoke')} <b>{roleTypeDescription}</b> {t('Role_For')} <b>{name}</b>?</Fragment>,
            onDisAgree: resetConfirmation,
            onAgree: existingRole === undefined ? () => { assignRole(user_id, role) } : (remarks) => { revokeRole(existingRole, remarks) },
            withConfirmText: existingRole === undefined ? false : true,
            isConfirmTextMandatory: existingRole === undefined ? false : true
        }))
    }

    const resetConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const assignRole = (id, selectedRole) => {
        resetConfirmation()
        assignRoleForUserMutation({
            variables: {
                userId: id,
                auctionId: props.auctionId,
                role: selectedRole,
                pagination: { limit: itemsPerPage, page: page, asc: false }
            }
        })
    }

    const revokeRole = (existingRole, remarks) => {
        setRevokeAuctionRole(existingRole)
        // setLoading(true)
        resetConfirmation()
        revokeRoleMutation({
            variables: {
                auctionId: props.auctionId,
                roleId: existingRole.id,
                remarks: remarks,
                pagination: { limit: itemsPerPage, page: page, asc: false }
            }
        })
    }

    const AssignRoleToUser = () => {
        setAssignOpen(true)
    }

    useEffect(() => {
        setSlide(true)
    }, [])

    return (
        <div id='division'>
            {(isSystemAdminSupport || (props.auctionListType !== AuctionStatus.closed && props.auctionListType !== AuctionStatus.cancelled)) &&
                <Tooltip title='Assign role for existing users'>
                    <Button id='assignRole' style={{ float: 'right' }} variant='contained' onClick={AssignRoleToUser}>
                        <AssignmentIndIcon style={{ marginLeft: '-5px' }} />&nbsp;&nbsp; {t("Assign_Role")}
                    </Button>
                </Tooltip>}
            <Grid direction='row' container spacing={2}>
                <Grid item xs={12}>
                    <Slide direction="left" id='slide' in={slide} mountOnEnter unmountOnExit>
                        <Paper>
                            <TableContainer sx={{ maxHeight: 480 }} id='container'>
                                <Table id='table' stickyHeader aria-label="sticky table">
                                    <TableHead id='tableHeader' aria-label="sticky table">
                                        <TableRow id='table-row' className={classes.tableHeader}>
                                            <TableCell id='name' size='small' align="center" colSpan={2} >{t("Name")}</TableCell>
                                            <TableCell id='auctioneer' size='small' align="center" >{t("Auctioneer")}</TableCell>
                                            <TableCell id='support' size='small' align="center" >{t("Support")}</TableCell>
                                            <TableCell id='viewer' size='small' align="center"  >{t("Observer")}</TableCell>
                                            <TableCell id='actions' size='small' align="center" >{t("Actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.length === 0 && <TableRow id='no-record'>
                                            <TableCell id='empty-record' colSpan={9}>{t("No_record_found...")}</TableCell>
                                        </TableRow>
                                        }
                                        {users.length > 0 && users.map((user, index) => {
                                            return (
                                                <TableRow key={user.id} id={`table-data${index}`} style={index % 2 ? { background: "#fffec8" } : { background: "white" }}>
                                                    <TableCell align="center" style={{ padding: '0.3rem 0rem 0.3rem 0rem', width: '68px' }} id={`avatar-cell${index}`}>
                                                        <Avatar className={classes.avatar} id={`avatar${index}`}>
                                                            <span id={`avatar-name${index}`} className={classes.avatarColor}>{user.name[0].toUpperCase()}</span>
                                                        </Avatar>
                                                    </TableCell>
                                                    <TableCell id={`name-cell${index}`} style={{ padding: '0.3rem 0rem 0.3rem 0rem' }}>
                                                        <div id={`name${index}`}>{user.name} </div>
                                                        <div id={`email${index}`} className={classes.mailColor}>{user.email} </div>
                                                    </TableCell>
                                                    <TableCell align="center" id={`auction-role${index}`} style={{ padding: '0.3rem 0rem 0.3rem 0rem' }}>
                                                        <Tooltip id={`auction-tooltip${index}`} title={user.roleDetails.some(e => e.role == Roles.auctioneer && e.active) ? t("Click_here_to_revoke_role") : t("Click_here_to_assign_role")}>
                                                            <Checkbox size="small"
                                                                disabled={(props.auctionListType === AuctionStatus.closed || props.auctionListType === AuctionStatus.cancelled) && !isSystemAdminSupport}
                                                                id={`auction-check${index}`}
                                                                checked={user.roleDetails.some(e => e.role == Roles.auctioneer && e.active)}
                                                                onClick={() => confirmRevoke(user.roleDetails.find(e => e.role == Roles.auctioneer && e.active), user.userId, Roles.auctioneer, user.loginId, user.name)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ padding: '0.3rem 0rem 0.3rem 0rem' }} id={`support-role${index}`}>
                                                        <Tooltip id={`support-tooltip${index}`} title={user.roleDetails.some(e => e.role == Roles.support && e.active) ? t("Click_here_to_revoke_role") : t("Click_here_to_assign_role")}>
                                                            <Checkbox size="small" id={`support-check${index}`}
                                                                disabled={(props.auctionListType === AuctionStatus.closed || props.auctionListType === AuctionStatus.cancelled)  && !isSystemAdminSupport}
                                                                checked={user.roleDetails.some(e => e.role == Roles.support && e.active)}
                                                                onClick={() => confirmRevoke(user.roleDetails.find(e => e.role == Roles.support && e.active), user.userId, Roles.support, user.loginId, user.name)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ padding: '0.3rem 0rem 0.3rem 0rem' }} id={`viewer-role${index}`}>
                                                        <Tooltip id={`viewer-tooltip${index}`} title={user.roleDetails.some(e => e.role == Roles.viewer && e.active) ? t("Click_here_to_revoke_role") : t("Click_here_to_assign_role")}>
                                                            <Checkbox id={`viewer-check${index}`} size="small"
                                                                disabled={(props.auctionListType === AuctionStatus.closed || props.auctionListType === AuctionStatus.cancelled)  && !isSystemAdminSupport}
                                                                checked={user.roleDetails.some(e => e.role == Roles.viewer && e.active)}
                                                                onClick={() => confirmRevoke(user.roleDetails.find(e => e.role == Roles.viewer && e.active), user.userId, Roles.viewer, user.loginId, user.name)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell id={`button-cell${index}`} style={{ textAlign: 'center' }}>
                                                        <Tooltip title={t('View_History')}>
                                                            <IconButton id={`button-view${index}`} aria-label="action"
                                                                onClick={() => setShowRoleHistory({ show: true, user: user })}>
                                                                <VisibilityIcon className={classes.icons} id='visible' color='primary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {countUser > 0 &&
                                <BottomNavigation style={{ padding: '12px' }}>
                                    <AuctionPagination rowsPerPage={itemsPerPage} page={page} totalItems={countUser}
                                        onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage} />
                                </BottomNavigation>
                            }
                        </Paper>
                    </Slide>
                </Grid>
            </Grid>
            {assignOpen && <AssignRole page={page} itemsPerPage={itemsPerPage} open={assignOpen} close={setAssignOpen} slide={slide} auctionId={props.auctionId} setMessage={setMessage} />}
            {seekconfirmation.show && <AuctionConfirmDialog show={seekconfirmation.show}
                title={seekconfirmation.title} message={seekconfirmation.message}
                onAgree={seekconfirmation.onAgree} onDisAgree={seekconfirmation.onDisAgree}
                withConfirmText={seekconfirmation.withConfirmText}
                isConfirmTextMandatory={seekconfirmation.isConfirmTextMandatory}
            />}
            {showRoleHistory.show && <RoleDetails show={showRoleHistory.show}
                user={showRoleHistory.user} setShowRoleDetails={setShowRoleHistory} auctionId={props.auctionId}></RoleDetails>
            }
            {message.showMsg && <AuctionSnackBar show={message.showMsg} severity={message.severity} message={message.message} />}
            {(assignRoleLoading || listLoading || loading) && <AuctionLoader show={true} invisible={false} />}
            {(assignRoleError || revokeLoading || listError || revokeError) && <GraphQLErrors GraphQLErrors error={assignRoleError || revokeLoading || listError || revokeError} show={false} />}
        </div>
    )
}

export default ManageRolesList