import { useLazyQuery } from '@apollo/client';
import { BottomNavigation, IconButton, Slide, Stack, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { AUDIT_TABLE, LIST_FILTER } from '../GraphQL/Queries'
import { format } from 'date-fns';
import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import AuctionPagination from '../common/pagination/AuctionPagination';
import { useTranslation } from 'react-i18next';
import AuditFilter from './AuditFilter';
import AuctionLoader from "../common/auction-loader/AuctionLoader";
import GraphQLErrors from '../common/GraphQLErrors';
import { Close, TaskAltOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import AuctionSnackBar from '../common/AuctionSnackBar';
// import { TbReport } from 'react-icons/tb'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { AuctionDetailContext } from '../auction/auction-detail/AuctionDetail';
import { UserContext } from '../common/context/UserContext';
import { AuctionStatus, Roles } from '../common/StaticVariableDeclaration';

const useStyles = makeStyles((theme) => ({
  filtermsg: {
    color: theme.palette.success.main,
    marginLeft: '1.5rem',
    borderRadius: '2rem',
    width: 'fit-content',
    float: 'right',
    border: `1px solid ${theme.palette.success.main} !important`,
    padding: "0px 5px"
  },
  clear: {
    padding: '5px !important',
    marginLeft: '0px !important'
  },
  searchText: {
    borderRight: `1px solid ${theme.palette.success.main} !important`,
    paddingRight: '5px !important',
    fontSize: '14px !important'
  },
  tableHead: {
    fontWeight: "bold !important",
    fontSize: "14px !important",
    padding: '10px 15px !important',
  },
  tableCell:
    { padding: '12px 15px !important', }
}));

function AuditTable(props) {
  const { date } = props
  const [page, setPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [auditList, setAuditList] = useState([])
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    eventCd: [],
    activityCd: [],
    rowCreatedBy: [],
    description: '',
    startRange: null,
    endRange: null,
    sortOrder: 'desc',
    isReset: false
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const classes = useStyles();
  const [generateRpt, setGenerateRpt] = useState(false)
  const [message, setMessage] = useState({
    show: false,
    message: '',
    severity: ''
  })
  const auctionDetailContext = useContext(AuctionDetailContext);
  const userContext = useContext(UserContext).rootContext;
  const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
  const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;

  const onSuccess = (message) => {
    setMessage({ show: false, message: '', severity: 'error' });
    if (message) {
      setTimeout(() => {
        setMessage(message);
      }, 100);
    }
  }

  const handleChangefilter = (search) => {
    setFilterValues({
      eventCd: search.eventCd,
      activityCd: search.activityCd,
      rowCreatedBy: search.rowCreatedBy,
      description: search.description,
      startRange: search.startRange,
      endRange: search.endRange,
      sortOrder: search.sortOrder,
      isReset: false
    })
    setIsFilterApplied(true)
    userListFilter({
      variables: {
        auditOf: props.auctionId,
        auditResourceInput: {
          eventCd: search.eventCd,
          activityCd: search.activityCd,
          rowCreatedBy: search.rowCreatedBy,
          description: search.description,
          startRange: search.startRange,
          endRange: search.endRange,
        },
        pagination: { limit: 10, page: 1, asc: search.sortOrder === 'asc' ? true : false },
      }
    })
    setPage(1);
    setItemPerPage(10);
    setOpen(false);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  }

  const onRowsPerPageChange = (event) => {
    setPage(1)
    setItemPerPage(event.target.value);
  }

  const [userAuditTable, { loading: auditLoading, error: errorOnAuditTableLoad }] = useLazyQuery(AUDIT_TABLE, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAuditList([])
      setAuditList(
        data.listByAuditOf.audit
      )
      setIsFilterApplied(false)
      setCount(
        data.listByAuditOf.count
      )
      clearFilterValues()
    }
  })

  const [userListFilter, { loading: filterLoading, error: errorOnFilterLoad }] = useLazyQuery(LIST_FILTER, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAuditList([])
      if (data.listForFilter) {
        setAuditList(data.listForFilter.audit)
        setCount(data.listForFilter.count)
      }
    }
  })

  const clearFilterValues = () => {
    setFilterValues({
      eventCd: [],
      activityCd: [],
      rowCreatedBy: [],
      description: '',
      startRange: null,
      endRange: null,
      sortOrder: 'desc',
      isReset: false
    })
  }

  const handleClearFilter = () => {
    clearFilterValues();
    setIsFilterApplied(false)
    userAuditTable({
      variables: {
        auditOf: props.auctionId,
        pagination: { limit: 10, page: 1, asc: false },
      }
    })
    setPage(1);
    setItemPerPage(10);
  }

  useEffect(() => {
    if (isFilterApplied === false) {
      userAuditTable({
        variables: {
          auditOf: props.auctionId,
          pagination: { limit: itemPerPage, page: page, asc: false },

        }
      })
    } else if (isFilterApplied === true) {
      userListFilter({
        variables: {
          auditOf: props.auctionId,
          auditResourceInput: {
            eventCd: filterValues.eventCd,
            activityCd: filterValues.activityCd,
            rowCreatedBy: filterValues.rowCreatedBy,
            description: filterValues.description,
            startRange: filterValues.startRange,
            endRange: filterValues.endRange,
          },
          pagination: { limit: itemPerPage, page: page, asc: filterValues.sortOrder === 'asc' ? true : false },
        }
      })
    }
  }, [itemPerPage, page])


  const handleClickOpen = (value) => {
    setGenerateRpt(value)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // if (filterValues.isReset) {
    //   userAuditTable({
    //     variables: {
    //       auditOf: props.auctionId,
    //       pagination: { limit: 10, page: 1, asc: false },
    //     }
    //   })
    //   setPage(1);
    //   setItemPerPage(10);
    // }
  };

  return (
    <div style={{ maxHeight: 'calc(100vh - 200px' }}>
      <Stack direction='row' mb={1} justifyContent='end' alignItems='center' spacing={2}>
        {isFilterApplied && <Slide in={isFilterApplied} unmountOnExit direction='down' container={containerRef.current}>
          <div className={classes.filtermsg}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <TaskAltOutlined fontSize='small' />
              <Typography id='search-applied' className={classes.searchText} variant='body1' fontWeight='bold'>{t('Search_is_applied')}</Typography>
              <Tooltip title="Clear">
                <IconButton className={classes.clear} id='clear-filter'
                  color="secondary"
                  onClick={handleClearFilter}>
                  <Close id='close-type' color='error' />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>
        </Slide>}
        <Button size='small' id='search-button' variant="contained" color='primary' onClick={() => handleClickOpen(false)}>
          <SearchIcon />&nbsp;
          {t('Search')}
        </Button>
        <Button size='small' id='report-button' variant="contained" color='primary' onClick={() => handleClickOpen(true)}>
          <ReceiptLongIcon style={{ fontSize: "21px" }} />&nbsp;
          {t('Generate_Report')}
        </Button>
      </Stack>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 500 }} ref={containerRef}>
          <Table stickyHeader aria-label="sticky table" id='table'>
            <TableHead id='tablehead'>
              <TableRow id='tablerow' style={{ backgroundColor: "#dae2ef" }}>
                <TableCell id='tablecell' align="left" className={classes.tableHead}>
                  <Stack direction='row' justifyContent='left' spacing={2} alignItems='left'>
                    <Fragment>{t("Activity_Event")}</Fragment>
                  </Stack>
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  <Fragment>{t("Description")}</Fragment>
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  <Fragment>{t("IP_Address")}</Fragment>
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  <Stack direction='row' justifyContent='left' spacing={2} alignItems='left'>
                    <Fragment>{t("Performed_By")}</Fragment>
                  </Stack>
                </TableCell>
                {<TableCell align="left" className={classes.tableHead}>
                  <Stack direction='row' justifyContent='left' spacing={2} alignItems='left'>
                    <Fragment>{t("Event_Time")}</Fragment>
                  </Stack>
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {auditList.length === 0 &&
                <TableRow >
                  <TableCell colSpan={5}>
                    {t("No_Records_Found")}
                  </TableCell>
                </TableRow>
              }
              {auditList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align='left' className={classes.tableCell} component="th" scope="row">{row.activityLocale}  /  {`${row.eventLocale}`} </TableCell>
                  <TableCell align="left" className={classes.tableCell}>{row.description}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    { (row.eventLocale == 'Bid' && auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && !isAdmin &&
                        !(isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))) ? '--' : row.ipAddress 
                    }
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    { (row.eventLocale == 'Bid' && auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && !isAdmin &&
                        !(isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))) ? '--' : row.rowCreatedByInString 
                    }
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>{format(new Date(row.rowCreatedOn), 'dd-MM-yyyy hh:mm a')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BottomNavigation style={{ padding: '12px' }}>
          <AuctionPagination rowsPerPage={itemPerPage} page={page} totalItems={count}
            onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
        </BottomNavigation>
      </Paper>
      {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
      {open && <AuditFilter open={open} setOpen={setOpen} isFilterApplied={isFilterApplied} generateRpt={generateRpt} setIsFilterApplied={setIsFilterApplied} onSuccess={onSuccess}
        onClose={handleClose} onSearch={handleChangefilter} auctionId={props.auctionId} date={props.date} filterValues={filterValues} setFilterValues={setFilterValues} page={page} itemPerPage={itemPerPage} />}
      {(auditLoading || filterLoading) && <AuctionLoader show={(auditLoading || filterLoading)} invisible={false} />}
      {errorOnAuditTableLoad && <GraphQLErrors error={errorOnAuditTableLoad} show={false} />}
      {errorOnFilterLoad && <GraphQLErrors error={errorOnFilterLoad} show={false} />}
    </div>
  );
}
export default AuditTable