import React, { useContext, useState, useEffect } from 'react';
import { Box, Drawer,List, ListItem, FormControlLabel, Switch, ListSubheader, Grid } from '@mui/material';
import TourIcon from '@mui/icons-material/Tour';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import AuctionConfirmDialog from '../AuctionConfirmDialog';
import { UserContext } from '../context/UserContext';
import { AuthAxiosAuctionInterceptor, AxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import EnableMFA from '../../authentication/mfa-verification/EnableMFA';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';



const useStyles = makeStyles((theme) => ({
    quickSettingsListItem: {
        padding: '25px 10px',
        borderBottom: '10px solid #f7f9fb',
        background: '#e7edf5',
        '&:hover': {
            backgroundColor: '#dfe7f1'
        }
    },
    quickSettingsIcon: {
        color: 'slategrey',
        marginLeft: '1.5em'
    },
    quickSettingsDesc: {
        fontSize: '0.8em',
        color: 'grey'
    },
    quickSettingsHeader: {
        marginLeft: '10px'
    }
}))

const QuickSettingsDrawer = ({toggleDrawer, open}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [tourGuideStatus, setTourGuideStatus] = useState(false);
    const userContext = useContext(UserContext);
    const userContextValue = useContext(UserContext).rootContext;
    const [showEnableTwoAuthDialog, setShowEnableTwoAuthDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false
        }))
    }

    const handleEnbaleDisableTour = () => {
        setSeekConfitmation({
            show: true,
            title: t("Confirmation"),
            message:( `${ t('Are_You_Sure_That_You_Want_To') + (tourGuideStatus ? t('Disable_Guided_Tour') : t('Enable_Guided_Tour')) } `),
            onDisAgree: resetConfirmation,
            onAgree: () => {
                resetConfirmation();
                tourServiceCall();
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No'),
            isCancel: true
        })
    };

    const confirmDisableMFA = () => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Disable_Two_Step_Verification'),
            onAgree: () => {
                resetConfirmation();
                disableMFA();
            },
            onDisAgree: resetConfirmation,
            isCancel: true
        })
    }

    const onEnableDisableMFA = (msg, status) => {
        sessionStorage.setItem('isMfaEnabled', status);
        setIsMfaEnabled(status);
        setSeekConfitmation({
            show: true,
            title: t('Information'),
            message: t(msg),
            onAgree: resetConfirmation,
            agreeBtnLabel: 'Okay',
            isCancel: false
        })
    }

    const disableMFA = () => {
        setLoading(true);
        AuthAxiosAuctionInterceptor.delete(`mfa/${userContextValue.userSession.session.userId}`).then(response => {
            setLoading(false);
            onEnableDisableMFA('Two_Step_Verification_Disabled_Successfully', false);
        }).catch(error => {
            setMessage({ show: false, message: '', severity: '' });
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
            setLoading(false);
        })
    }

    const tourServiceCall = () => {
        setLoading(true);
        setMessage({ show: false, message: '', severity: '' });
        AxiosAuctionInterceptor.patch(`user/tour-guide/${!tourGuideStatus}`, {}).then(function (response) {
            setMessage({ show: true, message: (tourGuideStatus ? t('Guided_Tour_Disabled_Successfully') : t('Guided_Tour_Enabled_Successfully')), severity: 'info' });
            setTourGuideStatus(!tourGuideStatus);
            sessionStorage.setItem('tourGuide', !tourGuideStatus);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        });
    }

    useEffect(() => {
        if(sessionStorage.getItem('tourGuide') === 'true') {
            setTourGuideStatus(true);
        }
        if(sessionStorage.getItem('isMfaEnabled') === 'true') {
            setIsMfaEnabled(true);
        }
    }, [])

    return (
        <React.Fragment>
            <Drawer anchor='right' open={open} onClose={toggleDrawer('settings', false)}
                PaperProps={{
                    sx: { backgroundColor: '#f7f9fb' }
                }}
            >
                <Box sx={{ width: '30em' }} id="settngs-content-box" >
                    <List subheader={
                        <ListSubheader component="div" id="quick-settings-header" sx={{color: 'grey', background: '#f7f9fb'}}>
                            <h4>{t('Quick_Settings')}</h4>
                        </ListSubheader>
                    }>
                        <ListItem key='tour-guide-settings' disablePadding className={classes.quickSettingsListItem}>
                            <FormControlLabel id="tour-guide"
                                control={ <Switch name="tour-guide" checked={tourGuideStatus}/>}
                                label={<Grid sx={{ display: 'flex'}} >
                                    <TourIcon className={classes.quickSettingsIcon} />
                                    <Grid container className={classes.quickSettingsHeader} >
                                        <Grid xs={12} item>{tourGuideStatus ? t('Disable_Guided_Tour') : t('Enable_Guided_Tour')}</Grid>
                                        <Grid xs={12} item className={classes.quickSettingsDesc} >{t('Guided_Tour_Desc')}</Grid>
                                    </Grid>
                                </Grid>}
                                onChange={handleEnbaleDisableTour}
                            />
                        </ListItem>
                        <ListItem key='mfa-settings' disablePadding className={classes.quickSettingsListItem}>            
                            <FormControlLabel id="mfa-enable-disable"
                                control={ <Switch name="mfa-enable-disable" checked={isMfaEnabled}/> }
                                label={ <Grid sx={{ display: 'flex'}} >
                                    <AdminPanelSettingsIcon className={classes.quickSettingsIcon} />
                                     <Grid container className={classes.quickSettingsHeader} >
                                        <Grid xs={12} item>{isMfaEnabled ? t('Disable_Two_Step_Verification') : t('Enable_Two_Step_Verification')}</Grid>
                                        <Grid xs={12} item className={classes.quickSettingsDesc} >{t('Two_Step_Verification_Desc')}</Grid>
                                    </Grid>
                                </Grid>}
                                onChange={isMfaEnabled ? confirmDisableMFA : (() => setShowEnableTwoAuthDialog(true)) }
                            />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {showEnableTwoAuthDialog && <EnableMFA show={showEnableTwoAuthDialog} 
                userId={userContextValue.userSession.session.userId} onClose={() => setShowEnableTwoAuthDialog(false)}
                onSuccess={onEnableDisableMFA}/>
            }
            {loading && <AuctionLoader show={loading} invisible={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </React.Fragment>
    )
}

export default QuickSettingsDrawer