import React from "react";
import { UserContext } from "../context/UserContext";
import { Redirect } from 'react-router-dom';

export function requireAuthentication(Component, skipAuthentication) {
    return class AuthenticatedComponent extends React.Component {
        static contextType = UserContext;

        handleRender = isAuthenticated => {
            if (!isAuthenticated) {
                return <Redirect to="/sign-in" />
            } else {
                return <Component {...this.props} />
            }
        }

        render() {
            const context = this.context.rootContext;
            return (
                skipAuthentication ? <Component {...this.props} /> : <>{this.handleRender(context.userSession.isAuthenticated)}</>
                // <>{this.handleRender(context.userSession.isAuthenticated)}</>
            );
        }
    };
}

export default requireAuthentication;