import { useLazyQuery, useMutation } from '@apollo/client';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { Button, Grid, IconButton, Paper, Tooltip, List, ListItemText, Popover, ListItemButton, ListItemIcon, Box,
    TextField, MenuItem, Link, Badge, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles, styled } from '@mui/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuctionDetailMenuItems } from '../../auction/auction-detail/AuctionDetailStaticVar';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { UserContext } from "../../common/context/UserContext";
import GraphQLErrors from '../../common/GraphQLErrors';
import AuctionPagination from '../../common/pagination/AuctionPagination';
import { AuctionStatus, BiddingAccess, Roles } from '../../common/StaticVariableDeclaration';
import { AxiosAuctionInterceptor, PublicAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import EnhancedTableToolbar from '../../EnancedTable/EnhancedTableToolbar';
import { ADD_FAVOURITE_AUCTION, REMOVE_FAVOURITE_AUCTION, UPDATE_AUCTION_STATUS } from "../../GraphQL/Mutation";
import { GET_AUCTION_LIST } from "../../GraphQL/Queries";
import { AuctionCreateSchema } from "../../schema/AuctionCreateSchema";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { AuctionFilterContext } from '../../common/context/AuctionFilterContext';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import {
    Search,
    SearchIconWrapper,
    StyledInputBase
 } from '../../common/search/ListSearch';
 import SearchIcon from '@mui/icons-material/Search';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import { GetWindowSize } from '../../common/GetWindowSize';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import { CurrencyFormatter } from '../../utils/Utils';
import SendIcon from '@mui/icons-material/Send';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import TakeoutDiningTwoToneIcon from '@mui/icons-material/TakeoutDiningTwoTone';
import { AuctionBriefToPublish } from '../../auction/auction-detail/AuctionBriefToPublish';
import { useServerCurrentDateTime } from '../../utils/Utils';

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({

    listContainer: {
        background: theme.table.bg,
        width: '100%',
        height: 'calc(100vh - 70px)'
    },
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0.2 },
    },
    flashIcon: {
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        opacity: '0.4',
        background: `${theme.flashButton.background} !important`,
        color: `${theme.flashButton.color} !important`,
        margin: '4px 0px !important'
    },
    favouriteIcon: {
        color: `${theme.flashButton.background} !important`,
        fontSize: '20px !important'
    },
    secondDataView: {
        color: 'lightslategray'
    },
    auctionMenuIcon: {
        minWidth: '32px'
    },
    startDate: {
        fontSize: '16px !important',
        color: 'forestgreen',
        verticalAlign: 'text-bottom'
    },
    endDate: {
        fontSize: '16px !important',
        color: 'indianred',
        verticalAlign: 'text-bottom'
    }
}));

const StyledGridNoRowOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));

// const currencyFormatter = new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR'});

const CustomNoRowsOverlay = (props) => {
    const {data, type, handleUpcomingNavigation} = props;
    const { t } = useTranslation();

    const getAuctionTypeDescription = (auctionListType) => {
        switch (auctionListType) {
            case AuctionStatus.open: return 'Open_Auctions'
                break;
            case AuctionStatus.scheduled: return 'Scheduled_Auctions'
                break;
            case AuctionStatus.setup: return 'Auctions_Under_Setup'
                break;
            case AuctionStatus.closed: return 'Archived_Closed'
                break;
            case AuctionStatus.cancelled: return 'Cancelled_Auctions'
                break;
            case AuctionStatus.favourite: return 'Bookmarked_Auctions'
                break;
            default: return 'Open_Auctions'
                break;
        }
    }

    return (
        <StyledGridNoRowOverlay id="no-auction-record-row">
            {/* {!data ? <Box sx={{ mt: 1 }}>{t('No') + ' ' + t(getAuctionTypeDescription(type)) }</Box> : <Box sx={{ mt: 1 }}></Box>} */}
            {   !data ?
                <Box sx={{ mt: 1 }}>
                    <Grid container sx={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={1} justifyContent='center'
                                sx={{
                                    marginBottom: '20px',
                                    background: 'whitesmoke',
                                    padding: '1rem 2rem ',
                                    borderRadius: '10px',
                                    alignItems: 'center'
                                }}
                            >
                                <TakeoutDiningTwoToneIcon color='primary' fontSize='medium' style={{ verticalAlign: 'bottom' }} />
                                <Typography variant='body1'>{ t('No') + ' ' + t(getAuctionTypeDescription(type)) }</Typography>
                            </Stack>
                        </Grid>
                        {type == AuctionStatus.open && <Grid item xs={12} sx={{ width: 'min-content' }} >
                            <Link component="button" variant="body1" onClick={handleUpcomingNavigation} color='primary'
                                style={{pointerEvents: "all", zIndex: 1}}
                            >
                                <Stack direction='row' spacing={1} justifyContent='center' >
                                    <Typography variant='h6'>{t('View_Upcoming_Auctions')}</Typography>
                                    <ShortcutOutlinedIcon fontSize='large' style={{ verticalAlign: 'middle' }} />
                                </Stack>
                            </Link>
                        </Grid>}
                    </Grid>
                </Box> 
                :
                <Box sx={{ mt: 1 }}></Box>
            }
        </StyledGridNoRowOverlay>
    );
}

export const EAuctionListTable = (props) => {
    const { auctionListType, roles, menuCollapse, pagination, isMyBid, handleChange } = props;
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const history = useHistory();
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(pagination.pageSize);
    const [page, setPage] = useState(pagination.page);
    const [auctionList, setAuctionList] = useState([]);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const classes = useStyles();
    const [moveAmongList, setMoveAmongList] = useState(null);
    const [bidTermsCondition, setBidTermsCondition] = useState(false)
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })
    const [requestForBid, setRequestForBid] = useState({
        show: false,
        auction_id: '',
        seller_id: '',
        auctionCreationDate: ''
    });
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const userSession = useContext(UserContext).rootContext.userSession;
    const userContext = useContext(UserContext).rootContext;
    const [initialSetup, setInitialSetup] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [showTodoTaskSummary, setShowTodoTaskSummary] = useState({
        open: false,
        todoList: []
    })
    const [auctionMenuAnchorEl, setAuctionMenuAnchorEl] = useState(null);
    const isAuctionMenuOpen = Boolean(auctionMenuAnchorEl);
    const [selectedAuctionForMenu, setSelectedAuctionForMenu] = useState(null); 
    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const [filtersValue, setFiltersValue] = useState({
        CountryId: null,
        Country: null,
        StateId: null,
        State: null,
        CityId: null,
        City: null,
        AuctionStartDate: null,
        AuctionEndDate: null,
        startLastDateForEmd: null,
        endLastDateForEmd: null,
        EmdMinPrice: null,
        EmdMaxPrice: null,
        ReserveMinPrice: null,
        ReserveMaxPrice: null,
        Category: [],
        isFilterApplied: false
    });
    const [filterValueObject, setFilterValueObject] = useState(props.filtersValue ? props.filtersValue : {
        CountryId: null,
        Country: null,
        StateId: null,
        State: null,
        CityId: null,
        City: null,
        AuctionStartDate: null,
        AuctionEndDate: null,
        startLastDateForEmd: null,
        endLastDateForEmd: null,
        EmdMinPrice: null,
        EmdMaxPrice: null,
        ReserveMinPrice: null,
        ReserveMaxPrice: null,
        Category: [],
        isFilterApplied: false
    })

    const [searchText, setSearchText] = useState(null);
    const [searchBy, setSearchBy] = useState('id');
    const serverCurrentTime = useServerCurrentDateTime();

    const [createAuctionModal, setCreateAuctionModal] = useState({
        show: false
    });

    const searchByOptions = [
        {label: 'Auction_ID', value: 'id'},
        {label: 'Seller', value: 'name'},
        {label: 'Auction_Brief', value: 'brief'},
        {label: 'Auction_Title', value: 'title'}
        // {label: 'Auctioneer_Name', value: 'auctioneerName'},
    ]
    const [aPIListloading, setAPIListloading] = useState(false);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [width, height] = GetWindowSize(createAuctionModal.show || showTodoTaskSummary.open || requestForBid.show || bidTermsCondition);
    const [refresh, setRefresh] = useState(false);

    const getPixelWidthWRTScreen = (percentWidth) => {
        return ((width - ( !userContext.userSession.isAuthenticated ? 0 : (menuCollapse ? 70 : 190))) * percentWidth) / 100;
    }

    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

    function lazyWithPreload(url) {
        const Component = React.lazy(url);
        Component.preload = url;
        return Component;
    }
    
    const listInterval = useRef();

    const AuctionCreateStepper = lazyWithPreload(() => import('../../auction-create/AuctionCreateStepper'));
    const BidTermsAndCondition = lazyWithPreload(() => import('../../bidder/bid-process/terms-and-condition/BidTermsAndCondition'));
    const RequestForBid = lazyWithPreload(() => import('../../bidder/request-for-bid/RequestForBid'));
    const TodoPendingTaskSummary = lazyWithPreload(() => import('../../auction/todo-pending-task-summary/TodoPendingTaskSummary'));

    // useEffect( () => {
    //     console.log('eachAuction.....')
    //     if(auctionList.length > 0) {
    //         auctionList.map( (eachAuction) => {
    //             if(eachAuction.status != AuctionStatus.cancelled && eachAuction.status != AuctionStatus.closed) {
    //                 let diffInAuctionStart = (new Date(eachAuction[AuctionCreateSchema.auctionStartDate]).getTime() - new Date(userContext.currentDateTime).getTime());
    //                 let diffInAuctionEnd = (new Date(eachAuction[AuctionCreateSchema.auctionEndDate]).getTime() - serverCurrentTime.getTime(userContext.currentDateTime));
    //                 console.log('eachAuction - auctionStartDate', diffInAuctionStart)
    //                 console.log('eachAuction - auctionEndDate', diffInAuctionEnd)
    //                 console.log('eachAuction - currentDateTime', new Date(userContext.currentDateTime).getTime())
    //                 if(diffInAuctionStart > 0 && diffInAuctionStart < 120000) {
    //                     setTimeout(() => {
    //                         console.log('diffInAuctionStart - refresh', diffInAuctionStart)
    //                         setRefresh(true);
    //                     }, diffInAuctionStart);
    //                 }
    //                 if(diffInAuctionEnd > 0 && diffInAuctionEnd < 120000) {
    //                     setTimeout(() => {
    //                         console.log('diffInAuctionEnd - refresh', diffInAuctionStart)
    //                         setRefresh(true);
    //                     }, diffInAuctionEnd);
    //                 }
    //             }
    //         })
    //     }
    // }, [auctionList])

    const [getAuctionList, { loading: listLoading, error: listError, refetch }] = useLazyQuery(GET_AUCTION_LIST, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: auctions => {
            if (auctions.listAuction != null) {
                setTotalNumberOfRows(auctions.listAuction.count);
                setAuctionList(auctions.listAuction.auction);
            }
            if (listError) {
                console.log('getAuctionList -> API error :', listError);
            }
        },
        onError: (error) => {
            console.log('getAuctionList -> NETWORK error :', error);
        }
    });

    const triggerGetAuctionList = () => {
        setAuctionList([]);
        if (userSession.isAuthenticated) {
            getAuctionList({
                variables: {
                    status: auctionListType,
                    pagination: { page: page, limit: pageSize, asc: false },
                    roles: (userContext.selectedMenu == 'auction' && auctionListType == AuctionStatus.open && !isAdmin)?
                        [Roles.auctioneer, Roles.support, Roles.bidder, Roles.bidderAuthorizedRepresentative, Roles.viewer] : roles
                }
            });
        } else {
            setAPIListloading(true);
            PublicAxiosAuctionInterceptor.get(`rest/auction/${auctionListType}/${page}/${pageSize}`).then(function (response) {
                setTotalNumberOfRows(response.count);
                setAuctionList(response.auction);
                setAPIListloading(false);
            }).catch(function (error) {
                setAPIListloading(false);
                setMessage({ show: false, message: '', severity: '' });
                setMessage({ show: true, message: (error.message ? error.message : error), severity: 'error' });
            });
        }
    }

    const handleAddDeleteFavouriteAuction = (data) => {
        if (data.favouriteAuction) {
            setSeekConfitmation(prevState => ({
                ...prevState,
                show: true,
                title: t("Confirmation"),
                message: `${t('Are_You_Sure_That_You_Want_To') + t('remove_from_Bookmark')} `,
                onDisAgree: resetUpdateStatusConfirm,
                onAgree: () => {
                    resetUpdateStatusConfirm();
                    removeFavouriteAuction({
                        variables: { auctionId: data.id }
                    });
                },
                agreeBtnLabel: t('Yes'),
                disAgreeBtnLabel: t('No')
            }))
        } else {
            setSeekConfitmation(prevState => ({
                ...prevState,
                show: true,
                title: t("Confirmation"),
                message: `${t('Are_You_Sure_That_You_Want_To') + t('bookmark')} `,
                onDisAgree: resetUpdateStatusConfirm,
                onAgree: () => {
                    resetUpdateStatusConfirm();
                    addFavouriteAuction({
                        variables: { auctionId: data.id }
                    });
                },
                agreeBtnLabel: t('Yes'),
                disAgreeBtnLabel: t('No')
            }))
        }
    }

    useEffect(() => {
        if (!initialSetup && !filtersValue?.isFilterApplied) {
            clearInterval(listInterval.current);
            if(searchText == null || searchText.trim() == '') {
                triggerGetAuctionList();
                listInterval.current = setInterval(() => {
                    triggerGetAuctionList() 
                }, 120000);
            } else {
                if (userSession.isAuthenticated) {
                    searchAuction();
                    listInterval.current = setInterval(() => {
                        searchAuction() 
                    }, 120000);
                } else {
                    searchAuctionPublic();
                    listInterval.current = setInterval(() => {
                        searchAuctionPublic()
                    }, 120000);
                }
            }
        }
    }, [page, pageSize])

    useEffect(() => {
        if (!initialSetup && !filtersValue?.isFilterApplied && refresh) {
            setRefresh(false);
            clearInterval(listInterval.current);
            if(searchText == null || searchText.trim() == '') {
                triggerGetAuctionList();
                listInterval.current = setInterval(() => {
                    triggerGetAuctionList() 
                }, 120000);
            } else {
                if (userSession.isAuthenticated) {
                    searchAuction();
                    listInterval.current = setInterval(() => {
                        searchAuction() 
                    }, 120000);
                } else {
                    searchAuctionPublic();
                    listInterval.current = setInterval(() => {
                        searchAuctionPublic()
                    }, 120000);
                }
            }
        }
    }, [refresh])

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value.replace(/\s+/g, '') === '' ? '' : event.target.value);
    }

    const CustomToolbar = useCallback(() => {
        return (
            <EnhancedTableToolbar
                auctionListType={auctionListType}
                createAuction={handleCreateAuctionModal}
                roles={roles}
                setTotalNumberOfRows={setTotalNumberOfRows}
                setAuctionList={setAuctionList}
                triggerGetAuctionList={triggerGetAuctionList}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                listInterval={listInterval}
                >
                    <Search style={{float: 'right', border: '1px solid #8080804a', margin: '4px', borderRadius: '20px', display: 'flex'}}>
                        <SearchIconWrapper style={{  borderRadius: '16px 0px 0px 16px', backgroundColor: '#F0F8FF', padding: '0px 0px 0px 4px'}} >
                            <SearchIcon />
                            <TextField id="filterBy" select variant="standard" style={{paddingLeft: '4px'}}
                                    InputProps={{ disableUnderline: true }}
                                    defaultValue={searchBy}
                                    onChange={setSearchByText}>
                                {
                                    searchByOptions.map((option, index) => {
                                        return <MenuItem key={index} value={option.value}>{t(option.label)}</MenuItem>
                                    })
                                }
                            </TextField>
                        </SearchIconWrapper>
                        <StyledInputBase
                            initialWidth='20ch'
                            expandableWidth='28ch'
                            placeholder={searchBy === 'id' ? `${t('Search')} (${t('Enter_Auction_ID')})` : `${t('Search')} (${t('Case_Sensitive')})`}
                            style={{paddingLeft: '0px important!'}}
                            inputProps={{
                                'aria-label': 'search',
                                onChange: (event) => handleSearchTextChange(event),
                                variant: 'outlined',
                                'id': 'search-auction-by-text',
                                defaultValue: searchText,
                                pattern: '[0-9]'
                            }}
                        />
                    </Search>
            </EnhancedTableToolbar>
        );
    }, [searchBy, triggerFilter]);

    const setTablePage = (event, data) => {
        setPage(data);
    }

    const onPageSizeChange = (event) => {
        setPage(1)
        setPageSize(event.target.value);
    }

    const CustomTablePagination = () => {
        return (
            <Grid container style={{ padding: '5px 12px 0px 12px' }}>
                <Grid item xs={12}>
                    <AuctionPagination
                        rowsPerPage={pageSize}
                        page={page}
                        totalItems={totalNumberOfRows}
                        onRowsPerPageChange={onPageSizeChange}
                        onPageChange={setTablePage}
                        rowsPerPageText={t('Auctions_Per_Page')}
                        rowsText={t('Auction(s)')}
                    />
                </Grid>
            </Grid>
        );
    }

    const [updateAuctionStatus, { loading: updateStatusLoading, error: updateStatusError }] = useMutation(UPDATE_AUCTION_STATUS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (resp) => {
            if (resp.updateAuctionStatus) {
                        if(moveAmongList == 'request_publish') {
                            showSuccessMsg({ show: true, message: t('Successfully_Sent_Request_To_SYSTEM_ADMIN/SUPPORT'), severity: 'success' });
                        } else if (moveAmongList == 'upcoming') {
                            showSuccessMsg({ show: true, message: t('Your_Auction_Is_Now_Visible_In') + " " + moveAmongList + " " + t('Auctions'), severity: 'success' });
                        } else {
                            showSuccessMsg({ show: true, message: t('Auction_Has_Been_Moved_To') + " " + moveAmongList + " " + t('auction') + " " + t('list'), severity: 'success' });
                        }

                    if(page == 1) {
                        triggerGetAuctionList();
                    } else {
                        let lastPageRows = totalNumberOfRows % pageSize;
                        let noOfPage = ~~(totalNumberOfRows/pageSize) + (lastPageRows > 0 ? 1 : 0);
                        if(lastPageRows == 1 && page == noOfPage){
                            setPage(1);
                        } else {
                            triggerGetAuctionList();
                        }
                    }
            }
            if (updateStatusError) {
                console.log('createAuction -> API error :', updateStatusError);
            }
        },
        onError: (error) => {
            console.log('createAuction -> NETWORK error :', error);
        }
    })

    const [addFavouriteAuction, { loading: addFavouriteStatusLoading, error: addFavouriteStatusError }] = useMutation(ADD_FAVOURITE_AUCTION, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (resp) => {
            if (resp.addFavouriteAuction) {
                handleServiceCall();
                showSuccessMsg({ show: true, message: t('Auction_Has_Been_Bookmarked'), severity: 'success' })
            }
            if (addFavouriteStatusError) {
                console.log('createAuction -> API error :', addFavouriteStatusError);
            }
        },
        onError: (error) => {
            console.log('createAuction -> NETWORK error :', error);
        }
    })

    const [removeFavouriteAuction, { loading: removeFavouriteStatusLoading, error: removeFavouriteStatusError }] = useMutation(REMOVE_FAVOURITE_AUCTION, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (resp) => {
            if (resp.removeFavouriteAuction) {
                handleServiceCall();
                showSuccessMsg({ show: true, message: t('Auction_Has_Been_Removed_From_Bookmark'), severity: 'success' })
            }
        },
        onError: (error) => {
            console.log('createAuction -> NETWORK error :', error);
        }
    })

    const handleImageAuctionDetail = (data, navigation) => {
        if(!data.roles.includes(Roles.viewer) && !data.roles.includes(Roles.bidderAuthorizedRepresentative) &&
            !data.roles.includes(Roles.bidder) && !data.roles.includes(Roles.requestedBidder) &&
            !data.roles.includes(Roles.auctioneer) && !data.roles.includes(Roles.support) &&
            !data.roles.includes(Roles.coWorker) && !data.roles.includes(Roles.valuer) &&
            !data.roles.includes(Roles.systemAdmin) && !data.roles.includes(Roles.rejectedBidder) &&
            !isCustomerSupport && !isAdmin &&
            (data.conductingFor.length == 0 || !data.conductingFor[0].loginId || !userSession?.session?.userId
                || userSession.session.userId != data.conductingFor[0].loginId) 
        ) {
            handleAuctionDetailImage(data, navigation);
        } else {
            handleAuctionDetail(data, navigation);
        }
    }

    const handleAuctionDetail = (data, navigation) => {
        history.push({
            pathname: "/auction-detail",
            state: {
                auctionDetail: data,
                navigationId: roles,
                backNavigation: history.location.pathname,
                sideNavigation: navigation,
                auctionListType: auctionListType,
                pagination: {
                    page: page,
                    pageSize: pageSize
                },
                filtersValue: filtersValue,
                isMyBid: isMyBid
            }
        })
    }

    const handleAuctionDetailImage = (data, navigation) => {
        history.push({ 
            pathname: "/auction-detail-image",
            state: {
                auctionDetail: data,
                navigationId: roles,
                backNavigation: history.location.pathname,
                sideNavigation: navigation,
                auctionListType: auctionListType,
                pagination: {
                    page: page,
                    pageSize: pageSize
                },
                filtersValue: filtersValue,
                isMyBid: isMyBid
            }
        })
    }

    const handleRequestBid = (data) => {
        if (userSession.isAuthenticated) {
            clearInterval(listInterval.current);
            setRequestForBid({
                show: true,
                auction_id: data.id,
                seller_id: data.conductingFor[0] ? data.conductingFor[0].personId : '',
                auctionCreationDate: data.createdOn
            })
        } else {
            history.push({ pathname: "/sign-in" });
        }
    };

    const resetRequestForBid = () => {
        setRequestForBid({
            show: false,
            auction_id: '',
            seller_id: '',
            auctionCreationDate: ''
        })
        handleServiceCall();
    }

    const handleViewDetail = (data) => {
        handleImageAuctionDetail(data, AuctionDetailMenuItems.AUCTION_DETAILS.value);
    };

    const handleViewDetailImage =(data) => {
        handleAuctionDetailImage(data, AuctionDetailMenuItems.AUCTION_DETAIL_IMAGE.value);
    }

    const handleDocumentView = (data) => {
        handleAuctionDetail(data, AuctionDetailMenuItems.DOCUMENTS.value);
    };

    const handleAuctionStatusUpdate = (data, status, msg) => {
        setMoveAmongList(null);
        if (msg == 'publish') {
            if(isAdmin || (isCustomerSupport && selectedAuctionForMenu.roles.includes(Roles.support))){
                if(new Date(selectedAuctionForMenu[AuctionCreateSchema.auctionStartDate]) <= serverCurrentTime) {
                    setMoveAmongList(t('live'));
                } else {
                    setMoveAmongList(t('upcoming'));
                }
            } else {
                setMoveAmongList(t('request_publish'));
            }
            
        } else if (msg == 'resetup') {
            setMoveAmongList(t('undersetup'));
        } else {
            setMoveAmongList(msg);
        }
        
        resetUpdateStatusConfirm()
        updateAuctionStatus({
            variables: {
                auctionId: data.id,
                status: status
            }
        })
    };

    const onAcceptTC = () => {
        setBidTermsCondition(false);
        handleAuctionDetail(rowData, AuctionDetailMenuItems.BIDDING_HALL.value);
    }

    const onDeclineTC = () => {
        setBidTermsCondition(false);
    }

    const handleBidNow = (data) => {
        setRowData(null);
        setRowData(data);
        setBidTermsCondition(true)
    };

    const handleTodoTaskSummaryClose = () => {
        setShowTodoTaskSummary({
            open: false,
            todoList: [],
        })
    }

    const checkAuctionTodoEvents = (data, status, msg) => {
        handleAuctionMenuClose();
        setLoading(true);
        AxiosAuctionInterceptor.get(`rest/auction/todo/${data.id}`).then(function (response) {
            if (response.length > 0) {
                setShowTodoTaskSummary({
                    open: true,
                    todoList: response,
                    data: data,
                    status: status,
                    msg: msg
                })
            } else {
                confirmUpdateStatus(data, status, msg);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' });
            setMessage({ show: true, message: error, severity: 'error' });
        });
    }

    const confirmUpdateStatus = (data, status, msg) => {
        handleAuctionMenuClose();
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message:(msg == 'publish' && !isAdmin && !isCustomerSupport) ?
                <Stack spacing={2} direction='column'>
                    <Stack>{t("Are_You_Sure_That_You_Want_To_Send_The_Publish_Request_To_System_Admin/Support?")}</Stack>
                    <AuctionBriefToPublish auctionId={data.id} />
                    <Stack spacing={1} direction='column' justifyContent='center' alignItems='center'
                        sx={{ background: '#fff6e5', borderRadius: '10px', padding: '10px' }}>
                        <ContactSupportRoundedIcon color='warning' />
                        <Typography fontSize='14px'>
                            {t('Pubish_Contact_Support')} {t('Space')} {process.env.REACT_APP_PLATFORM} {t('Space')} {t('Support_At')} {t('Space')} {process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}
                        </Typography>
                    </Stack>
                </Stack>
                :
                <Stack spacing={2} direction='column'>
                    <Stack>{(t('Are_You_Sure_That_You_Want_To') + ' ' + t(msg) + '?')}</Stack>
                    {msg == 'publish' && <AuctionBriefToPublish auctionId={data.id} />}
                </Stack>,
            onDisAgree: resetUpdateStatusConfirm,
            onAgree: () => { handleAuctionStatusUpdate(data, status, msg) },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const resetUpdateStatusConfirm = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const auctionTitleTypeRender = (value) => {
        let description = value;
        enumListOptions.AuctionType.find((item) => {
            if (item.dateBaseCode === value) {
                description = item.description;
                return
            }
        })
        return description;
    }

    const AuctionListMenuItem = useCallback(() => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <List aria-label="AuctionMenu">
                        <ListItemButton
                            id={'view-auction-detail-' + selectedAuctionForMenu.auctionId} key={1} className={classes.auctionMenuIcon}
                            onClick={() => handleViewDetail(selectedAuctionForMenu)}
                        >
                            <ListItemIcon>
                                <ViewSidebarIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={t('View_Detail')}></ListItemText>
                        </ListItemButton>
                        {userSession.isAuthenticated && (isAdmin || selectedAuctionForMenu.roles.includes(Roles.support) || selectedAuctionForMenu.roles.includes(Roles.auctioneer)) &&
                            (selectedAuctionForMenu.status.toLowerCase() == AuctionStatus.setup || selectedAuctionForMenu.status.toLowerCase() == AuctionStatus.scheduled
                              || selectedAuctionForMenu.status.toLowerCase() === AuctionStatus.publish_request  || selectedAuctionForMenu.status.toLowerCase() == AuctionStatus.open) &&
                            <ListItemButton id={'cancel-auction-' + selectedAuctionForMenu.auctionId} key={2} className={classes.auctionMenuIcon}
                                onClick={() => confirmUpdateStatus(selectedAuctionForMenu, AuctionStatus.cancelled, 'cancel')}
                            >
                                <ListItemIcon>
                                    <HighlightOffIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={t('Cancel') + ' ' + t('Auction')}></ListItemText>
                            </ListItemButton>}
                       <ListItemButton
                            id={'view-auction-detail-' + selectedAuctionForMenu.auctionId} key={1} className={classes.auctionMenuIcon}
                            onClick={() => handleViewDetailImage(selectedAuctionForMenu)}>     
                               <ListItemIcon>
                                  <ViewSidebarIcon color="primary" />
                               </ListItemIcon>
                            <ListItemText primary={t('Image View')}></ListItemText>
                        </ListItemButton>
                        {userSession.isAuthenticated && new Date(selectedAuctionForMenu[AuctionCreateSchema.auctionEndDate]) > serverCurrentTime &&
                            (selectedAuctionForMenu.roles.includes(Roles.auctioneer) || selectedAuctionForMenu.roles.includes(Roles.support)
                            || isAdmin || (isCustomerSupport && selectedAuctionForMenu.roles.includes(Roles.support))) &&
                            <React.Fragment>
                                { selectedAuctionForMenu.status.toLowerCase() == AuctionStatus.setup &&
                                    <ListItemButton id={'publish-auction-' + selectedAuctionForMenu.auctionId} key={3} className={classes.auctionMenuIcon}
                                        onClick={() => checkAuctionTodoEvents(selectedAuctionForMenu, AuctionStatus.ready, 'publish')}
                                    >
                                        <ListItemIcon>
                                            <UpcomingIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={t((isAdmin || (isCustomerSupport)) ? 'Publish_Auction' : 'Send_Request_To_Publish_Auction')}></ListItemText>
                                    </ListItemButton> 
                                }
                                { selectedAuctionForMenu.status.toLowerCase() == AuctionStatus.publish_request &&
                                    <React.Fragment>
                                        { isAdmin || (isCustomerSupport && selectedAuctionForMenu.roles.includes(Roles.support)) ?
                                            <ListItemButton id={'publish-auction-' + selectedAuctionForMenu.auctionId} key={3} className={classes.auctionMenuIcon}
                                                onClick={() => checkAuctionTodoEvents(selectedAuctionForMenu, AuctionStatus.ready, 'publish')}
                                            >
                                                <ListItemIcon>
                                                    <Badge color="secondary" badgeContent="1">
                                                        <UpcomingIcon color="primary" />
                                                    </Badge>
                                                </ListItemIcon>
                                            
                                                <ListItemText primary={t('Publish_Auction')}></ListItemText>
                                            </ListItemButton>
                                            : <ListItemButton id={'publish-auction-' + selectedAuctionForMenu.auctionId} key={3} className={classes.auctionMenuIcon}
                                                onClick={() => checkAuctionTodoEvents(selectedAuctionForMenu, AuctionStatus.ready, 'publish')}
                                            >
                                                <ListItemIcon>
                                                    <SendIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={t('Resend_Request_To_Publish_Auction')}></ListItemText>
                                            </ListItemButton>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </List>
                </Grid>
            </Grid>
        )
    }, [selectedAuctionForMenu])

    const handleAuctionMenuClose = () => {
        setAuctionMenuAnchorEl(null)
    }

    const handleAuctionMenuOpen = (event, params) => {
        setAuctionMenuAnchorEl(event);
        setSelectedAuctionForMenu(params);
    };

    const getActionBidHeaderItem = useCallback((params) => {
        return (
            <Grid container sx={{textAlign: 'end'}}>
                {userSession.isAuthenticated && params.row.status.toLowerCase() == AuctionStatus.open &&
                    params.row.roles.includes(Roles.bidder) &&
                    <Grid item xs={12}>
                        <Tooltip title={t('Bid_Now')} arrow placement='top'>
                            <Button variant='contained' size='small' color="primary" id={'cancel-auction-' + params.row.auctionId}
                                sx={{ padding: '0px 4px !important', textTransform: 'capitalize' }}
                                className={classes.flashIcon}
                                onClick={() => handleBidNow(params.row)}>
                                {t('Bid_Now')}
                            </Button>
                        </Tooltip>
                    </Grid>}
                <Grid item xs={12}>
                    <Tooltip title={t('Navigation_To_Documents')} arrow>
                        <IconButton id={'download-documents-' + params.row.auctionId} color="primary" sx={{ padding: '4px !important' }}
                            onClick={() => handleDocumentView(params.row)}>
                            <SimCardDownloadIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('View_Detail')} arrow>
                        <IconButton id={'view-auction-detail-' + params.row.auctionId} color="primary" sx={{ padding: '4px !important' }}
                            onClick={() => handleViewDetail(params.row)}>
                            <ViewSidebarIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }, [auctionList, filtersValue])

    const getActionHeaderItem = useCallback((params) => {
        return (
            <Grid container sx={{textAlign: 'end'}}>
                {userSession.isAuthenticated && params.row.roles.includes(Roles.bidder) &&
                    params.row.status.toLowerCase() == AuctionStatus.open &&
                    <Grid item xs={12}>
                        <Tooltip title={t('Bid_Now')} arrow placement='top'>
                            <Button variant='contained' size='small' color="primary" id={'cancel-auction-' + params.row.auctionId}
                                sx={{ padding: '0px 4px !important', textTransform: 'capitalize' }}
                                className={classes.flashIcon}
                                onClick={() => handleBidNow(params.row)}>
                                {t('Bid_Now')}
                            </Button>
                        </Tooltip>
                    </Grid>}
                { params.row.status.toLowerCase() == AuctionStatus.scheduled && new Date(params.row.auctionLots[0].lastDateForKyc) > serverCurrentTime &&
                    params.row[AuctionCreateSchema.biddingAccess] == enumListObject.BidderService[BiddingAccess.limited].dateBaseCode &&
                    !params.row.roles.includes(Roles.viewer) &&
                    !params.row.roles.includes(Roles.bidderAuthorizedRepresentative) &&
                    !params.row.roles.includes(Roles.bidder) &&
                    !params.row.roles.includes(Roles.requestedBidder) &&
                    !params.row.roles.includes(Roles.auctioneer) &&
                    !params.row.roles.includes(Roles.support) &&
                    !params.row.roles.includes(Roles.coWorker) &&
                    !params.row.roles.includes(Roles.valuer) &&
                    !params.row.roles.includes(Roles.systemAdmin) &&
                    !params.row.roles.includes(Roles.rejectedBidder) &&
                    !isCustomerSupport && !isAdmin &&
                    (params.row.conductingFor.length == 0 || !params.row.conductingFor[0].loginId || !userSession?.session?.userId
                        || userSession.session.userId != params.row.conductingFor[0].loginId) &&
                    <Grid item xs={12}>
                        <Tooltip title={t('Interested_To_Bid')} arrow placement='top'>
                            <Button variant='contained' size='small' color="primary" id={'request-for-bid-' + params.row.auctionId}
                                sx={{ padding: '0px 4px !important', margin: '4px 0px !important', textTransform: 'capitalize' }}
                                onClick={() => handleRequestBid(params.row)}>
                                {t('Interested?')}
                            </Button>
                        </Tooltip>
                    </Grid>}
                <Grid item xs={12}>
                    {userSession.isAuthenticated &&
                        (params.row['favouriteAuction'] ||
                        (!params.row.roles.includes(Roles.bidderAuthorizedRepresentative) &&
                            !params.row.roles.includes(Roles.bidder) &&
                            !params.row.roles.includes(Roles.requestedBidder) &&
                            !params.row.roles.includes(Roles.auctioneer) &&
                            !params.row.roles.includes(Roles.support) &&
                            !params.row.roles.includes(Roles.coWorker) &&
                            !params.row.roles.includes(Roles.valuer) &&
                            !params.row.roles.includes(Roles.rejectedBidder) &&
                            !params.row.roles.includes(Roles.systemAdmin))) &&
                        <Tooltip title={params.row['favouriteAuction'] ? t('Remove_from_Bookmark') : t('Bookmark')} arrow>
                            <IconButton id={'add-to-favourite-' + params.row.auctionId} color="primary" sx={{ padding: '4px !important' }}
                                onClick={() => handleAddDeleteFavouriteAuction(params.row)}>
                                {params.row['favouriteAuction'] && <StarIcon className={classes.favouriteIcon} />}
                                {!params.row['favouriteAuction'] && <StarBorderIcon sx={{ fontSize: '20px' }} />}
                            </IconButton>
                        </Tooltip>}
                    <Tooltip title={t('Navigation_To_Documents')} arrow>
                        <IconButton id={'download-documents-' + params.row.auctionId} color="primary" sx={{ padding: '4px !important' }}
                            onClick={() => handleDocumentView(params.row)}>
                            <SimCardDownloadIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('More_Actions')} arrow>
                        <IconButton id={'open-auction-menu-item-' + params.row.auctionId} color="primary" sx={{ padding: '4px !important' }}
                            onClick={(event) => handleAuctionMenuOpen(event.target, params.row)}>
                            <MoreVertIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }, [auctionList, filtersValue])

    const handleServiceCall = () => {
        if(filtersValue?.isFilterApplied) {
            setTriggerFilter(true);
        } else {
            clearInterval(listInterval.current);
            if (searchText !== null && searchText.trim() !== '') {
                if (userSession.isAuthenticated) {
                    searchAuction();
                    listInterval.current = setInterval(() => {
                        searchAuction();
                    }, 120000);
                } else {
                    searchAuctionPublic();
                    listInterval.current = setInterval(() => {
                        searchAuctionPublic();
                    }, 120000);
                }
            } else {
                triggerGetAuctionList();
                listInterval.current = setInterval(() => {
                    triggerGetAuctionList();
                }, 120000);
            }
        }
    }

    const headCells = useMemo(() => [
        {
            field: 'auctionId',
            width: getPixelWidthWRTScreen(8),
            headerName: t('Auction_Id'),
            type: 'string',
            headerClassName: 'firstCustomTableHeader',
            renderCell: (params) => (
                <Grid container>
                    <Grid item xs={12}>
                        <Stack direction='row' justifyContent='space-between'>
                            <span>{params.row.auctionId}</span>
                            <Stack direction='column' justifyContent='end' spacing={1} >
                                {params.row.roles.includes(Roles.bidder) &&
                                    <Tooltip title={t('Request_Participate_Bidder_Approved')} arrow >
                                        <VerifiedUserIcon sx={{ color: 'forestgreen', float: 'right' }} />
                                    </Tooltip>
                                }
                                {params.row.roles.includes(Roles.requestedBidder) &&
                                    <Tooltip title={t('Request_Participate_Bidder_Pending')} arrow>
                                        <PauseCircleOutlineIcon sx={{ color: 'orange', float: 'right' }} />
                                    </Tooltip>
                                }
                                {params.row.roles.includes(Roles.rejectedBidder) &&
                                    <Tooltip title={t('Request_Participate_Bidder_Rejected')} arrow>
                                        <GppBadIcon sx={{ color: 'red', float: 'right' }} />
                                    </Tooltip>
                                }{ params.row.status.toLowerCase() == AuctionStatus.publish_request && (params.row.roles.includes(Roles.auctioneer) || (!isCustomerSupport && params.row.roles.includes(Roles.support))) &&
                                    <Tooltip title={t('Request_sent_To_The_System_Admin_Support')} arrow>
                                        {/* <PauseCircleOutlineIcon sx={{ color: 'orange', float: 'right' }} /> */}
                                        <img src={process.env.PUBLIC_URL + './request-sent.svg'} style={{ color: 'blue', float: 'right' }} />
                                    </Tooltip>
                                }
                                { params.row.status.toLowerCase() == AuctionStatus.publish_request && (isAdmin || (isCustomerSupport && params.row.roles.includes(Roles.support))) &&
                                    <Tooltip title={t('You_Have_Been_Requested_to_Publish_Auction')} arrow>
                                        {/* <PauseCircleOutlineIcon sx={{ color: 'orange', float: 'right' }} /> */}
                                        <img src={process.env.PUBLIC_URL + './request-sent.svg'} style={{ color: 'blue', float: 'right' }} />
                                    </Tooltip>
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'title',
            width: getPixelWidthWRTScreen(20),
            renderHeader: () => (
                <strong style={{ lineHeight: '29px' }}>
                    <span>{t('Auction_Title')}</span><br />
                    <span className={classes.secondDataView}>{t('Auction_Type')}</span>
                </strong>
            ),
            type: 'string',
            headerClassName: 'customTableHeader',
            renderCell: (params) => (
                <Grid container>
                    <Grid item xs={12}>
                        <span>{params.row.title}</span>
                    </Grid><br />
                    <Grid item xs={12} className={classes.secondDataView}>
                        <span>{auctionTitleTypeRender(params.row.auctionType)}</span>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'companyName',
            width: getPixelWidthWRTScreen(18),
            renderHeader: () => (
                <strong style={{ lineHeight: '29px' }}>
                    <span>{t('Seller')}</span><br />
                    <span className={classes.secondDataView}>{t('Auctioneer')}</span>
                </strong>
            ),
            type: 'string',
            headerClassName: 'customTableHeader',
            renderCell: (params) => (
                <Grid container>
                    <Grid item xs={12}>
                        <span>{params.row.conductingFor.length > 0 ? params.row.conductingFor[0].name : "-"}</span>
                    </Grid><br />
                    <Grid item xs={12} className={classes.secondDataView}>
                        {params.row.auctioneers.length > 0 && <span>{params.row.auctioneers[0].name}</span>}
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'reservePrice',
            renderHeader: () => (
                <strong style={{ lineHeight: '29px' }}>
                    <span>{t('Reserve_Price')}</span><br />
                    <span className={classes.secondDataView}>{t('EMD_Amount')}</span>
                </strong>
            ),
            type: 'number',
            width: getPixelWidthWRTScreen(13.5),
            headerClassName: 'customTableHeader',
            cellClassName: 'font-tabular-nums',
            renderCell: (params) => (
                <Grid container>
                    <Grid item xs={12}>
                        <span>{params.row.auctionLots[0]?.reservePrice != null ? CurrencyFormatter(params.row.baseCurrency, params.row.auctionLots[0].reservePrice) : '-'}</span>
                    </Grid>
                    <Grid item xs={12} className={classes.secondDataView}>
                        <span>{params.row.auctionLots[0]?.emdAmount != null ? CurrencyFormatter(params.row.baseCurrency, params.row.auctionLots[0].emdAmount) : '-'}</span>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'lastDateForKyc',
            renderHeader: () => (
                <strong style={{ lineHeight: '29px' }}>
                    <span>{t('Last_Date_For_KYC')} ({dateTimeZoneValue})</span><br />
                    <span className={classes.secondDataView}>{t('Last_Date_For_EMD')} ({dateTimeZoneValue})</span>
                </strong>
            ),
            width: getPixelWidthWRTScreen(15),
            type: 'dateTime',
            headerClassName: 'customTableHeader',
            renderCell: (params) => (
                <Grid container>
                    <Grid item xs={12}>
                        {params.row.auctionLots[0].lastDateForKyc && <CalendarMonthRoundedIcon className={classes.endDate} />}
                        <Tooltip title={t('Last_Date_For_KYC_Submission')} arrow >
                            <span style={{ verticalAlign: 'top' }}>
                                {params.row.auctionLots[0].lastDateForKyc ? format(new Date(params.row.auctionLots[0].lastDateForKyc), ' dd-MM-yyyy hh:mm a') : '-'}
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} className={classes.secondDataView}>
                        {params.row.auctionLots[0].lastDateForEmd && <CalendarMonthRoundedIcon className={classes.endDate} />}
                        <Tooltip title={t('Last_Date_For_Emd_Amount')} arrow >
                            <span style={{ verticalAlign: 'top' }}>
                                {params.row.auctionLots[0].lastDateForEmd ? format(new Date(params.row.auctionLots[0].lastDateForEmd), ' dd-MM-yyyy hh:mm a') : '-'}
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'startTime',
            // headerName: t('Auction_Date'),
            renderHeader: () => <strong> {t('Auction_Date')} ({dateTimeZoneValue})</strong>,
            width: getPixelWidthWRTScreen(15),
            type: 'dateTime',
            headerClassName: 'customTableHeader',
            renderCell: (params) => (
                <Grid container>
                    <Grid item xs={12}>
                        <CalendarMonthRoundedIcon className={classes.startDate} />
                        <Tooltip title={t('Auction_Start_Date_Time')} arrow >
                            <span style={{ verticalAlign: 'top' }}>{format(new Date(params.row.startTime), ' dd-MM-yyyy hh:mm a')}</span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <CalendarMonthRoundedIcon className={classes.endDate} />
                        <Tooltip title={t('Auction_End_Date_Time')} arrow >
                            <span style={{ verticalAlign: 'top' }}>{format(new Date(params.row.endTime), ' dd-MM-yyyy hh:mm a')}</span>
                        </Tooltip>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'actions',
            headerName: t('Actions'),
            width: getPixelWidthWRTScreen(9),
            headerClassName: 'customTableHeader',
            renderCell: (params) => isMyBid ? getActionBidHeaderItem(params) : getActionHeaderItem(params)
        }
        // {
        //     field: 'auctionType',
        //     width: 200,
        //     headerName: t('Auction_Type'),
        //     type: 'string',
        //     headerClassName: 'customTableHeader',
        //     valueFormatter: ({ value }) => {
        //         let description = value;
        //         enumListOptions.AuctionType.find((item) => {
        //             if(item.dateBaseCode === value) {
        //                 description = item.description;
        //                 return
        //             }
        //         })
        //         return description;
        //     }
        // }
    ], [menuCollapse, auctionList, filtersValue, width])

    const searchAuction = () => {
        setMessage({show: false, message: '', severity: ''});
        const pattern = /^([0-9]*)$/;
        let callSearch = false;
        if (searchBy === 'name' || searchBy === 'brief' || searchBy === 'title') {
            callSearch = true;
        } else if (searchBy === 'id' && pattern.test(searchText)) {
            callSearch = true;
        } else {
            setTimeout(() => {
                setMessage({show: true, message: t('Only_Numbers_Are_Allowed_With_Auction_ID_Search'), severity: 'warning'});
            }, 500);
        }

        if (callSearch) {
            let payload = {
                searchBy: searchBy,
                searchText: (searchText != null ? searchText.trim() : searchText),
                pagination: {
                    page: page,
                    limit: pageSize,
                    asc: false,
                },
                roles: (userContext.selectedMenu == 'auction' && auctionListType == AuctionStatus.open && !isAdmin)?
                    [Roles.auctioneer.toUpperCase(), Roles.support.toUpperCase(), Roles.bidder.toUpperCase(), Roles.bidderAuthorizedRepresentative.toUpperCase()] :
                    roles.map(role => role.toUpperCase()),
                status: auctionListType
            }
            setLoading(true);
            AxiosAuctionInterceptor.post(`rest/auction/search`, payload).then(res => {
                setLoading(false);
                setTotalNumberOfRows(res.count);
                setAuctionList(res.auction);
            }).catch(error => {
                setLoading(false);
                if (error.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
            });
        }
    }

    const searchAuctionPublic = () => {
        setMessage({show: false, message: '', severity: ''});
        const pattern = /^([0-9]*)$/;
        let callSearch = false;
        if (searchBy === 'name' || searchBy === 'brief' || searchBy === 'title') {
            callSearch = true;
        } else if (searchBy === 'id' && pattern.test(searchText)) {
            callSearch = true;
        } else {
            setTimeout(() => {
                setMessage({show: true, message: t('Only_Numbers_Are_Allowed_With_Auction_ID_Search'), severity: 'warning'});
            }, 500);
        }

        if (callSearch) {
            let payload = {
                searchBy: searchBy,
                searchText: (searchText != null ? searchText.trim() : searchText),
                asc: false,
                roles: [],
                status: auctionListType
            }
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/auction/search-public/${page}/${pageSize}`, {params: payload}).then(res => {
                setLoading(false);
                setTotalNumberOfRows(res.count);
                setAuctionList(res.auction);
            }).catch(error => {
                setLoading(false);
                if (error.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
            });
        }
    }

    const setSearchByText = (event) => {
        setSearchBy(event.target.value);
        setSearchText(null);
    }

    const handleCreateAuctionModal = () => {
        if (userSession.isAuthenticated) {
            clearInterval(listInterval.current);
            setCreateAuctionModal({
                show: true
            })
        } else {
            history.push({ pathname: "/sign-in" });
        }
    };

    const resetCreateAuctionModal = () => {
        setCreateAuctionModal({
            show: false
        })
        handleServiceCall();
    }

    // Add useEffect functions here
    useEffect(() => {
        if(!initialSetup) {
            if (searchText !== null && searchText.trim() !== '') {
                if(filtersValue?.isFilterApplied) {
                    setFiltersValue({
                        CountryId: null,
                        Country: null,
                        StateId: null,
                        State: null,
                        CityId: null,
                        City: null,
                        AuctionStartDate: null,
                        AuctionEndDate: null,
                        startLastDateForEmd: null,
                        endLastDateForEmd: null,
                        EmdMinPrice: null,
                        EmdMaxPrice: null,
                        ReserveMinPrice: null,
                        ReserveMaxPrice: null,
                        Category: [],
                        isFilterApplied: false
                    });
                }
                if(page == 1) {
                    clearInterval(listInterval.current);
                    if (userSession.isAuthenticated) {
                        searchAuction();
                        listInterval.current = setInterval(() => {
                            searchAuction() 
                        }, 120000);
                    } else {
                        searchAuctionPublic();
                        listInterval.current = setInterval(() => {
                            searchAuctionPublic() 
                        }, 120000);
                    }
                } else {
                    setPage(1);
                }
            } else {
                if(!filtersValue?.isFilterApplied) {
                    clearInterval(listInterval.current);
                    triggerGetAuctionList();
                    listInterval.current = setInterval(() => {
                        triggerGetAuctionList() 
                    }, 120000);
                }
            }
        }
    }, [searchText])

    useEffect(() => {
        setInitialSetup(false);
        if(!filterValueObject?.isFilterApplied) {
            triggerGetAuctionList();
            listInterval.current = setInterval(() => {
                triggerGetAuctionList() 
            }, 120000);
            return () => clearInterval(listInterval.current);
        }
        setTimeout(() => {
            AuctionCreateStepper.preload();
            BidTermsAndCondition.preload();
            RequestForBid.preload();
            TodoPendingTaskSummary.preload();
        }, 3000);
    }, [])

    return (
        <Grid container className="grid-container">
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                <Paper id="auction-list-datagrid"
                    className={classes.listContainer}
                    sx={{
                        '& .font-tabular-nums': {
                            fontVariantNumeric: 'tabular-nums',
                            textAlign: 'end'
                        },
                        '& .alignItemRight': {
                            textAlign: 'end'
                        },
                        '& .alignItemCenter': {
                            textAlign: 'center'
                        }
                    }}
                >
                    <AuctionFilterContext.Provider
                        value={{ setTotalNumberOfRows,
                            setAuctionList,
                            triggerGetAuctionList,
                            setPageSize,
                            setPage,
                            setFiltersValue,
                            setFilterValueObject,
                            setSearchText,
                            setRefresh,
                            page,
                            pageSize,
                            filtersValue,
                            filterValueObject,
                            refresh
                            
                        }}
                    >
                        { headCells && <DataGrid rows={auctionList}
                            getRowId={(row) =>  row.auctionId}
                            columns={ headCells }
                            components={{
                                Toolbar: CustomToolbar,
                                Pagination: CustomTablePagination,
                                // NoRowsOverlay: () => CustomNoRowsOverlay(userSession.isAuthenticated? listLoading : aPIListloading, auctionListType)
                                NoRowsOverlay: () => (<CustomNoRowsOverlay
                                    data={userSession.isAuthenticated? listLoading : aPIListloading}
                                    type={auctionListType}
                                    handleUpcomingNavigation={() => handleChange(AuctionStatus.scheduled)}
                                />)
                            }}
                            componentsProps={{
                                CustomToolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                }
                                // cell: {
                                    // (params, event) => {
                                    //     if(params.field != "actions") {
                                    //         handleRowPopoverOpen(params, event);
                                    //     }
                                    // }
                                // }
                            }}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '5px' },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '15px' },
                                [`& .${gridClasses.row}`]: {
                                    '&:hover, &.Mui-hovered': {
                                        bgcolor: (theme) => theme.palette.type === 'dark' ? grey[700] : grey[50],
                                        cursor: 'pointer'
                                    }
                                },
                                '& .customTableHeader': {
                                    bgcolor: (theme) => theme.palette.type === 'dark' ? grey[800] : theme.table.toolbarBg,
                                    fontWeight: '600 !important',
                                    borderLeft: (theme) => `1px solid ${theme.palette.divider}`
                                },
                                '& .firstCustomTableHeader': {
                                    bgcolor: (theme) => theme.palette.type === 'dark' ? grey[800] : theme.table.toolbarBg,
                                    fontWeight: '600 !important'
                                },
                                '& .MuiDataGrid-columnHeaderTitle': { fontWeight: '600 !important' },
                                border: '0px',
                                '& .MuiDataGrid-columnHeaderTitleContainer': { flexDirection: 'row !important' },
                                '& .MuiDataGrid-columnSeparator--sideRight': { visibility: 'collapse' }
                                // '& .MuiDataGrid-row:nth-of-type(even)': {
                                //     backgroundColor: (theme) => theme.table.trAltColor
                                // },
                                // '& .MuiDataGrid-row:nth-of-type(odd)': {
                                //     backgroundColor: (theme) => theme.table.trAltColor
                                // }
                                // boxShadow: 2,
                            }}
                            hideFooterSelectedRowCount={true}
                            disableColumnMenu={true}
                            onCellClick={((params) => {
                                if (params.field != "actions") {
                                    handleImageAuctionDetail(params.row, AuctionDetailMenuItems.AUCTION_DETAILS.value)
                                }
                            })}
                            disableVirtualization={true}
                        // loading
                        // hideFooter
                        // hideFooterPagination
                        />}
                    </AuctionFilterContext.Provider>
                </Paper>
            </Grid>
            <Popover
                id={'more-actions-popup'}
                open={isAuctionMenuOpen}
                anchorEl={auctionMenuAnchorEl}
                onClose={handleAuctionMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <AuctionListMenuItem />
            </Popover>
            {(listLoading || updateStatusLoading || addFavouriteStatusLoading || removeFavouriteStatusLoading || loading || aPIListloading) &&
                <AuctionLoader show={listLoading || updateStatusLoading || addFavouriteStatusLoading || removeFavouriteStatusLoading || loading || aPIListloading} invisible={false} />
            }
            {(listError || updateStatusError || addFavouriteStatusError || removeFavouriteStatusError) &&
                <GraphQLErrors error={listError || updateStatusError || addFavouriteStatusError || removeFavouriteStatusError} show={false} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {requestForBid.show && <RequestForBid
                onClose={resetRequestForBid}
                show={requestForBid.show}
                auction_id={requestForBid.auction_id}
                onSuccess={showSuccessMsg}
                seller_id={requestForBid.seller_id}
                auctionCreationDate={requestForBid.auctionCreationDate}
                updateAuctionContext={handleServiceCall}
            />}
            {bidTermsCondition &&
                <BidTermsAndCondition open={bidTermsCondition} onAccept={onAcceptTC} onDecline={onDeclineTC} auctionId={rowData.id} />}
            {createAuctionModal.show && <AuctionCreateStepper
                onClose={resetCreateAuctionModal}
                show={createAuctionModal.show}
                status={auctionListType}
                triggerGetAuctionList={triggerGetAuctionList}
                page={page}
                setPage={setPage}
                setSearchText={setSearchText}
                searchText={searchText}
                handleSubNavChange= {(data) => handleChange(data)}
                listInterval={listInterval}
            />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {showTodoTaskSummary.open && <TodoPendingTaskSummary
                handleAuctionDetail={handleAuctionDetail}
                onClose={handleTodoTaskSummaryClose}
                showTodoTaskSummary={showTodoTaskSummary}
            />}
        </Grid>
    )
}
export default React.memo(EAuctionListTable)