import React, { 
        useState, 
        Fragment, 
        useEffect,
        useContext 
}                                       from 'react';
import {
    Grid,
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    BottomNavigation,
    IconButton,
    Tooltip,
    Link,
    Typography,
    Stack
}                                       from '@mui/material';
import { makeStyles }                   from '@mui/styles';
import { 
    useLazyQuery, 
    useMutation 
}                                       from '@apollo/client';
import { useTranslation }               from 'react-i18next';   
import AddIcon                          from '@mui/icons-material/AddOutlined';
import FieldHelperText                  from '../../common/FieldHelperText';
import AuctionPagination                from '../../common/pagination/AuctionPagination';
import AddItem                          from '../add-item/AddItem';
import { 
    LIST_ITEMS,
    GET_PRE_SIGNED_URL 
}                                       from '../../GraphQL/Queries';
import { DELETE_ITEM }                  from '../../GraphQL/Mutation';
import AuctionLoader                    from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                    from '../../common/GraphQLErrors';
import EditIcon                         from '@mui/icons-material/EditOutlined';
import DeleteIcon                       from '@mui/icons-material/DeleteOutline';
import ImageIcon                        from '@mui/icons-material/ImageOutlined';
import VisibilityIcon                   from '@mui/icons-material/Visibility';
import { NumericFormat }                from 'react-number-format';
import FileUploadIcon                   from '@mui/icons-material/FileUpload';
import PersonIcon                       from '@mui/icons-material/Person';
import PhoneIcon                        from '@mui/icons-material/Phone';
import ItemImagesModal                  from '../item-images/ItemImagesModal';
import UploadDialog                     from '../../common/files-upload/UploadDialog';
import { AxiosAuctionInterceptor }      from '../../config/AxiosAuctionInterceptor';
import { UserContext }                  from '../../common/context/UserContext';
import { UpdateItem }                   from '../update-item/updateItem';
import AuctionSnackBar                  from '../../common/AuctionSnackBar';
import AuctionConfirmDialog             from '../../common/AuctionConfirmDialog';
import { excelAcceptableMimeType }      from '../../common/files-upload/FileUploadStaticVar';
import { PublicAxiosAuctionInterceptor, APIAxiosAuctionInterceptor } from "../../config/AxiosAuctionInterceptor";
import FileList                         from '../../file-upload-download/FileList';
import DescriptionIcon                  from '@mui/icons-material/Description';
import axios                            from 'axios';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: "rgb(26, 32, 39)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '10px',
        border: "1px solid rgb(238, 238, 238)",
        backgroundImage: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        margin: "0px auto",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,
            "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    button: {
        margin: '4px !important'
    },
    textIconContainer: {
        display: 'flex'
    },
    textIcon: {
        fontSize: '1.2rem !important',
        paddingRight: '3px'
    },
    tableRow: {
        verticalAlign: 'initial !important'
    },
    typography:{
        fontWeight:'bold !important',
        fontSize:'16px !important',
        // color:'blue',
        // margin:'20px 0px 0px 0px !important'
    }
}))

const ItemType = ({itemTypes, genericItemTypes}) => {
    let types = [];
    if (genericItemTypes != undefined) {
        for (let i = 0; i < itemTypes.length; i ++) {
            types.push(genericItemTypes[itemTypes[i]].uiCode);
        }
    } else {
        types = itemTypes;
    }
    
    return (
        <Fragment>
            {types.map(type => (
                <div>{type}</div>
            ))}
        </Fragment>
    )
}

const ItemList = ({isIndividualBid, auctionId, lotId, canAdd, canEdit, canDelete, canUpload, multiItem}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [addItemDetails, setAddItemDetails] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [updateItemDetails, setUpdateItemDetails] = useState({
        show: false,
        item: '',
        canEdit: false
    });
    const [showImageGalley, setShowImageGalley] = useState({
        item: '',
        show: false
    });
    const [noOfItems, setNoOfItems] = useState(0);
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const userContext = useContext(UserContext).rootContext;
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })
    const [showDocuments, setShowDocuments] = useState({
        itemId: '',
        show: false
    });

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false)

    const columns = [
        {name: 'Item_Id', width: '8%', helperText: ''},
        {name: 'Item_Name', width: '16%', helperText: ''},
        {name: 'Item_Type', width: '18%', helperText: ''},
        {name: 'Location', width: '12%', helperText: ''},
        {name: 'Contact', width: '17%', helperText: ''}
    ]

    const [listItems, {loading: fetchingItems, error: errorOnFetchingItems, refetch}] = useLazyQuery(LIST_ITEMS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setItems([]);
            if (data.listItem != null) {
                data.listItem.item.map(item => {
                    setItems(prevState => ([
                        ...prevState,
                        item
                    ]))
                })
                setNoOfItems(data.listItem.count);
            }
        }
    })

    const [deleteItem, {loading: deletingItems, error: errorOnDeletingItems}] = useMutation(DELETE_ITEM, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteItem) {
                setMessage({
                    show: true,
                    message: t('Item_Deleted_Successfully'),
                    severity: 'success'
                })
            }
            if (page !== 1) {
                let lastPageRows = noOfItems % rowsPerPage;
                let noOfPage = ~~(noOfItems/rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                if(lastPageRows == 1 && page == noOfPage){
                    setPage(1);
                } else {
                    refetch();
                }
            } else {
                refetch();
            }
        }
        // refetchQueries: [LIST_ITEMS]
    })

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    } 

    const addItem = () => {
        setAddItemDetails(true);
    }

    const editItem = (item) => {
        setUpdateItemDetails({
            show: true,
            item: item,
            canEdit: true
        });
    }

    const viewItem = (item) => {
        setUpdateItemDetails({
            show: true,
            item: item,
            canEdit: false
        });
    }

    const resetUpdateItemDetails = (msg, isEdit) => {
        setUpdateItemDetails({
            show: false,
            item: '',
            canEdit: false
        });
        if(msg) {
            showSuccessMsg(msg)
        }
        if (isEdit) {
            refetch();
        }
    }

    const showImages = (item) => {
        setShowImageGalley({
            item: item,
            show: true
        })
    }

    const resetShowImages = (item) => {
        setShowImageGalley({
            item: '',
            show: false
        })
    }
    
    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            listItems({
                variables: {
                    auctionId: auctionId,
                    lotId: lotId,
                    pagination: {
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    },
                    isActive: true
                }
            })
        } else {
            setLoading(true);
            setItems([]);
            PublicAxiosAuctionInterceptor.get(`rest/item-list/${auctionId}/${lotId}/${page}/${rowsPerPage}/true`).then((response) => {
                response.item.map(item => {
                    setItems(prevState => ([
                        ...prevState,
                        item
                    ]))
                })
                setNoOfItems(response.count);
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({show: false, message: '', severity: ''})
                if (error.message) {
                    setMessage({
                        show: true,
                        message: error.message,
                        severity: 'error'
                    });
                } else {
                    setMessage({
                        show: true,
                        message: t('Try_Again'),
                        severity: 'error'
                    });
                }
            });
        }
    }, [auctionId, lotId, rowsPerPage, page])

    if (page === 0) {
        setPage(1);
    }

    const deleteSelectedItem = (item) => {
        deleteItem({
            variables: {
                itemId: item.id,
                auctionId: auctionId
            }
        })
        resetSeekConfirmation();
    }

    const bulkUpload = (excelfile) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        const formData = new FormData();
        formData.append("file", excelfile[0]);
        APIAxiosAuctionInterceptor.post(`bulk-upload-item/${auctionId}/${lotId}`, formData).then(res => {
            setShowBulkUpload(false);
            refetch();
            setMessage({
                show: true,
                message: t('Excel_Uploaded_Successfully_Refresh_After_Sometime'),
                severity: 'success'
            });
        }).catch(error => {
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Please_Check_Excel_And_Try_Again'),
                    severity: 'error'
                });
            }
            setShowBulkUpload(false);
        });
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const deleteConfiration = (item) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete') + " " + t('Item') + " - " + item.itemRefNo + "?",
            onAgree: () => deleteSelectedItem(item),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const getDocuments = (itemId) => {
        setShowDocuments({
            itemId: itemId,
            show: true
        })
    }

    const resetShowDocuments = (event) => {
        setShowDocuments({
            itemId: '',
            show: false
        })
    }

    const [getPresignedUrl, { loading: gettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            download(data.getPresignedUrl);
        }
    });

    const download = (preSignedUrl) => {
        setLoading(true);
        axios({
            url: preSignedUrl,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'item.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }

    const downloadExcelFile = (fileName) => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/get-presigned-url/${encodeURI(process.env.REACT_APP_S3_EXCEL_BUCKET)}?fileName=${encodeURI(process.env.REACT_APP_S3_EXCEL_FOLDER + '/' + fileName)}`).then((response) => {
            setLoading(false);
            if (response) {
                download(response)
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({show: false, message: '', severity: ''})
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        });
        // getPresignedUrl({ variables: { 
        //     presignedUrl : {
        //         fileName: process.env.REACT_APP_S3_EXCEL_FOLDER + '/' + fileName, 
        //         bucket: process.env.REACT_APP_S3_EXCEL_BUCKET,
        //         httpMethod:'GET', 
        //         versionId:'' 
        //     }
        // }})
    }
    
    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={0}>
                    <Typography className={classes.typography}>Item Details</Typography>
                    {canAdd &&  <div>
                        {(multiItem || (!multiItem && items.length === 0 )) && <Button id="add-item" variant="contained" size="small" startIcon={<AddIcon />} style={{float: 'right'}}
                                onClick={addItem} className={classes.button}>
                            {t('Add')}
                        </Button>}
                        {multiItem && <Button id="upload-item-excel" variant="contained" size="small" startIcon={<FileUploadIcon />} style={{float: 'right'}}
                                onClick={() => setShowBulkUpload(true)} className={classes.button}>
                            {t('Bulk_Upload')}
                        </Button>}
                        {multiItem && <Link id="download-item-excel" component="button" variant="body2" onClick={() => downloadExcelFile('Lot-Item-Sale.xlsx')}
                                style={{float: 'right',
                                    padding: '10px',
                                    fontWeight: 'bold'}}>
                            {t('Bulk_Upload_Template')}
                        </Link>}
                    </div>
                    }
                    </Stack>
                    <TableContainer component={Paper} className={classes.paper}>
                        <Table aria-label="items table" stickyHeader>
                            <TableHead>
                                {columns.map(column => {
                                    return (
                                        <TableCell key={column.name} className={`${classes.tableCell} ${classes.tableHead}`} style={{width: column.width}}>
                                            {t(column.name)} 
                                            {column.helperText && <FieldHelperText helperText={t(column.helperText)}/>}
                                        </TableCell>
                                    )
                                })}
                                {isIndividualBid && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} >{t("Reserve_Price")}</TableCell>}
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center">{t("Actions")}</TableCell>
                            </TableHead>
                            <TableBody id='item-table-body'>
                                {items.length === 0 && 
                                    <TableRow hover>
                                        <TableCell id={'item-no-record'} colSpan={9} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                    </TableRow>
                                }
                                {
                                    items.length > 0 && items.map((item, index) => {
                                        return (
                                            <TableRow id={`item-${item.itemRefNo}`} hover key={index} className={classes.tableRow}>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {item.itemRefNo ? item.itemRefNo : <span style={{textAlign: 'center'}}> - </span>}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {item.itemName ? item.itemName : <span style={{textAlign: 'center'}}> - </span>}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    <ItemType itemTypes={item.itemType} genericItemTypes={enumListObject.ItemType}/>
                                                    {/* {item.itemType ? (enumListObject.ItemType ? enumListObject.ItemType[item.itemType[0]].uiCode : item.itemType ) : <span style={{textAlign: 'center'}}> - </span>} */}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {item.address ? item.address.cityTown : <span style={{textAlign: 'center'}}> - </span>}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {
                                                        (item.address && (item.address.attention || item.address.phone)) ?
                                                            <Fragment>
                                                                <div className={classes.textIconContainer}>
                                                                    <PersonIcon className={classes.textIcon}/>{item.address.attention ? item.address.attention :  '-' }
                                                                </div>
                                                                <div className={classes.textIconContainer}>
                                                                    <PhoneIcon className={classes.textIcon}/>{item.address.phone ? item.address.phone :  '-'}
                                                                </div>
                                                                {/* <div className={classes.textIconContainer}>
                                                                    <EmailIcon className={classes.textIcon}/>{item.address.email ? item.address.email :  '-'}
                                                                </div>                            */}
                                                            </Fragment>
                                                        :
                                                            '-'
                                                    }
                                                    
                                                </TableCell>
                                                {isIndividualBid && <TableCell className={`${classes.tableCell} ${classes.tableData}`} style={{textAlign: 'right'}}>
                                                    {item.reservePrice ? 
                                                        <NumericFormat value={item.reservePrice} displayType={'text'} 
                                                            thousandSeparator={true} thousandsGroupStyle="lakh"/>
                                                            : 
                                                        <span style={{textAlign: 'center'}}> - </span>}
                                                </TableCell>}
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`} style={{textAlign: 'center'}}>
                                                    { canEdit && <Tooltip title={t('Edit')} arrow>
                                                        <IconButton id={`edit-item=${index}`} size="small" onClick={() => editItem(item)} color="primary">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    <Tooltip title={t('View')} arrow>
                                                        <IconButton id={`view-item=${index}`} size="small" onClick={() => viewItem(item)} color="primary">
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t('Files')} arrow>
                                                        <IconButton id={`document-item=${index}`} size="small" onClick={() => getDocuments(item.id)}
                                                                color="primary">
                                                            <DescriptionIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t('Images_Videos')} arrow>
                                                        <IconButton id={`image-item=${index}`} size="small" onClick={() => showImages(item)}
                                                                color="secondary">
                                                            <ImageIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    { canDelete && <Tooltip title={t('Delete')} arrow>
                                                        <IconButton id={`delete-item=${index}`} size="small" onClick={() => deleteConfiration(item)}
                                                                color="secondary">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        { noOfItems > 0 &&
                            <BottomNavigation style={{padding: '12px'}}>
                                <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={noOfItems}
                                    onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange}
                                    rowsPerPageText={t('Items_Per_Page')} rowsText={t('Items')}/>
                            </BottomNavigation>
                        }
                    </TableContainer>
                </Grid>
            </Grid>
            { addItemDetails && <AddItem show={addItemDetails} onClose={(event) => setAddItemDetails(event)}
                isIndividualItemBid={isIndividualBid} auctionId={auctionId} lotId={lotId} /> }
            {showImageGalley.show && <ItemImagesModal 
                auction_id={auctionId} lot_id={lotId} item_id={showImageGalley.item.id}
                show={showImageGalley.show} onClose={resetShowImages}
                canUpload={canUpload}/>}
            {(fetchingItems || deletingItems || loading) && <AuctionLoader show={true} invisible={false} />}
            {(errorOnFetchingItems) && <GraphQLErrors error={errorOnFetchingItems} show={false} />}
            {(errorOnDeletingItems) && <GraphQLErrors error={errorOnDeletingItems} show={false} />}
            {updateItemDetails.show &&
                <UpdateItem auction_id={auctionId} item={updateItemDetails.item} onClose={resetUpdateItemDetails}
                    canEdit={updateItemDetails.canEdit}/>}
            { showBulkUpload && <UploadDialog
                dialogOpen={showBulkUpload}
                setDialogOpen={setShowBulkUpload}
                isMultiple={false}
                title={t('Upload_Item_Excel')}
                customFileUpload={bulkUpload}
                acceptableFiles={excelAcceptableMimeType}
                />
            }
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
            {showDocuments.show && <FileList show={showDocuments.show} isMultiple={true} 
                    fileName={`${lotId}/${showDocuments.itemId}/other documents`} 
                    item_id={showDocuments.itemId} lot_id={lotId}
                    onClose={resetShowDocuments} auction_id={auctionId} canUpload={canUpload}
                    title={t('Item_Files')}/>}
        </Fragment>
    )
}

export default ItemList;