import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/AddOutlined';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Grid, IconButton, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, Tooltip, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { LIST_IDENTIFICATION } from '../../GraphQL/Queries';
import AuctionLoader from "../auction-loader/AuctionLoader";
import AuctionSnackBar from "../AuctionSnackBar";
import { UserContext } from '../context/UserContext';
import GraphQLErrors from "../GraphQLErrors";
import { IdentificationTypes, PartyType } from '../StaticVariableDeclaration';
import ListIdentificationRow from './ListIdentificationRow';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    }
}))

const IndentificationModalList = ({personId, openPopup, setClose, individual, isView, identifications}) => {
    
    const { t } = useTranslation();
    const classes = useStyles();
    const [identificationList, setIdentificationList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [identificationTypes, setIdentificationTypes] = useState([]);
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const [selectedType, setSelectedType] = useState('');
    const open = Boolean(anchorEl);
    const id = 'identification-type-popover';    
    const [existingIDTypes, setExistingIDTypes] = useState([]);
    const [showPlusButton, setShowPlusButtom] = useState(true);
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const individualIdentificationTypes = [
        IdentificationTypes.pan
    ]

    const comapnyIdentificationTypes = [
        IdentificationTypes.pan,
        IdentificationTypes.cin,
        IdentificationTypes.gstin
    ]

    const [listIdentification, {loading: listingIdentification, error: errorOnListingIdentification, refetch}] = 
            useLazyQuery(LIST_IDENTIFICATION, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if(data.listIdentification != null) {
                setIdentificationList([]);
                setExistingIDTypes([]);
                data.listIdentification.map(id => {
                    setExistingIDTypes(prevState => ([
                        ...prevState,
                        id.identificationType
                    ]))
                    let identificationTypeValue;
                    enumListOptions.IdentificationType.map(type => {
                        if(type.dateBaseCode === id.identificationType) {
                            identificationTypeValue = type.uiCode;
                        }
                    })
                    setIdentificationList(prevState => ([
                        ...prevState,
                        {
                            id: id.id,
                            identificationType: identificationTypeValue,
                            identification: id.identification,
                            otherIdentificationType: id.otherIdentificationType,
                            startTime: id.startTime,
                            endTime: id.endTime,
                            isNew: false
                        }
                    ]))
                })
            }
        }
    })

    useEffect(() => { 
        if(individual) {
            setIdentificationTypes([]);
            individualIdentificationTypes.map(individualTypes => {
                enumListOptions.IdentificationType.map(type => {
                    if(type.dateBaseCode == individualTypes) {
                        setIdentificationTypes(
                            prevState => ([
                                    type,
                                ...prevState
                        ]))
                    }
                })
            })
        } else if(!individual) {
            setIdentificationTypes([]);
            comapnyIdentificationTypes.map(companyTypes => {
                enumListOptions.IdentificationType.map(type => {
                    if(type.dateBaseCode == companyTypes) {
                        setIdentificationTypes(
                            prevState => ([
                                    type,
                                ...prevState
                        ]))
                    }
                })
            })
        }
    },[individual])

    const setCancel = (isCancel) => {
        setShowPlusButtom(true);
        // refetch();
        if(personId != null) {
            listIdentification({
                variables: {
                    personId: personId,
                }
            })
        }
    }

    // useEffect(() => {
    //     if(personId != null) {
    //         listIdentification({
    //             variables: {
    //                 personId: personId,
    //             }
    //         })
    //     }
    // },[personId])

    useEffect(() => {
        if(identifications) {
            setIdentificationList([]);
            setExistingIDTypes([]);
            identifications.map(id => {
                setExistingIDTypes(prevState => ([
                    ...prevState,
                    enumListObject.IdentificationType[(id.identificationType).toLowerCase()].dateBaseCode
                ]))
                setIdentificationList(prevState => ([
                    ...prevState,
                    {
                        id: id.id,
                        identificationType: id.identificationType,
                        identification: id.identification,
                        otherIdentificationType: id.otherIdentificationType,
                        startTime: id.startTime,
                        endTime: id.endTime,
                        isNew: false
                    }
                ]))
            })
        }
    }, [])

    const handleClick = (event) => {
        setSelectedType('')
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleIdentificationTypeChange = (event) => {
        setShowPlusButtom(false);
        setSelectedType(event.target.value);
        if(event.target.value.trim() != '') {
            setIdentificationList(
                prevState => ([
                    {
                        id: '',
                        identification: '',
                        identificationType: event.target.value,
                        otherIdentificationType: '',
                        startTime: '',
                        endTime: '',
                        isNew: true
                    },
                    ...prevState,
                ])
            )
            handleClose();
        }
    }

    const onSuccess = (msg) => {
        if(personId != null) {
            listIdentification({
                variables: {
                    personId: personId,
                }
            })
        }
        setShowPlusButtom(true);
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }
    
    const handleCloseModal = () => {
        setClose(false);
    }

    return (
        <Dialog open={openPopup} maxWidth='false'>
            <div style={{width: 430}}>
                <DialogTitle style={{padding: '10px 15px'}}>{t('Identification')}</DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: '0px 15px 10px 15px !important', maxHeight: '400px !important',  overflow: 'auto'}}>
                    {!isView && <Tooltip title={t("Add")}> 
                        <IconButton id="add-identification" aria-label="add" size="small" color="primary" style={{float: 'right'}} disabled={!showPlusButton}>
                            <AddIcon aria-describedby={id} onClick={handleClick} />
                        </IconButton>
                    </Tooltip>}
                    {!isView && <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                    >
                    <Typography sx={{ margin: 2 }} component="div">
                        <Grid item xs={12} lg={12}>
                        <TextField
                            label={t('Select_Type')}
                            variant='outlined'
                            id="identification-type"
                            select
                            value={selectedType}
                            sx={{width: 130}}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleIdentificationTypeChange}
                        >
                            <option value=''>{t('Select')}</option>
                            <option value='OTHER'>{t('Other')}</option>
                            {identificationTypes.map((type) => {
                                return !existingIDTypes.includes(type.dateBaseCode) && <option key={type.id} value={type.uiCode}>
                                        {type.uiCode}
                                    </option>
                            })} 
                        </TextField>
                    </Grid>
                    </Typography> 
                </Popover>}
                <TableContainer component={Paper} style={{marginTop: isView ? "15px" : "0px"}}>
                    <Table aria-label="email table" size="small">
                        <TableHead style={{backgroundColor: '#dae2ef'}}>
                        <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '32% !important'}} align="left">{t('Type')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '42% !important'}} align="left">{t('ID')}</TableCell>
                            {!isView && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '25% !important'}} align="center">{t('Actions')}</TableCell>}
                        </TableRow>
                        </TableHead>
                        <TableBody id="identification-list-modal">
                            {identificationList.length === 0 && 
                                    <TableRow hover>
                                        <TableCell className={`${classes.tableCell}`} colSpan={3}>{t('No_Record_Found')}</TableCell>
                                    </TableRow>
                            }
                            {identificationList.length > 0 && identificationList.map((identification, index) => {
                                return (
                                        <ListIdentificationRow key={identification.id + index} index={index} id={`identifications${index}`} identificationDetail={identification} individual={individual} setCancel={setCancel}
                                        personId={personId} refetch={refetch} onSuccess={onSuccess} isView={isView} isModal="true"/>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={handleCloseModal} id="cancel" variant="outlined" 
                    style={{float: 'right'}} >{t('Close')}</Button>
                </DialogActions>
            </div>
            {listingIdentification && <AuctionLoader show={listingIdentification} invisible={false} />}
            {errorOnListingIdentification && <GraphQLErrors error={errorOnListingIdentification} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </Dialog>
    )
}

export default IndentificationModalList;