import { ArrowDropDown, Clear } from '@mui/icons-material';
import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material';
import React from 'react'
import { Controller } from 'react-hook-form';

const filter = createFilterOptions();

const AddressAutocompleteControl = (props) => {
  const { control, name, errors, rules, label, id, freesolo, defaultValue, handleOnChangeCall,
    getValues, isEdit, options, loading, addressId, required, setCountryReason, setValue, clearErrors } = props;

  return (
    <Controller
      errors={errors}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue ? defaultValue : ''}
      render={({
        field: { ref, ...field },
        fieldState: { error }
      }) => (
        <Autocomplete
          {...field}
          id={id}
          clearOnBlur={getValues(name) != undefined && getValues(name) != null
            && getValues(name) != '' ? false : true}
          value={getValues(name) || null}
          freeSolo={freesolo}
          disabled={addressId != '' && !isEdit}
          defaultValue={defaultValue ? defaultValue : ''}
          filterOptions={(option, params) => {
            const filtered = filter(option, params);
            const { inputValue } = params;
            const isExisting = option.some((op) => inputValue.trim() === op);
            if (freesolo && inputValue.trim() !== '' && !isExisting) {
              filtered.push(inputValue);
            }
            return filtered;
          }}
          options={options}
          getOptionLabel={(option) => {
            return option || "";
          }}
          popupIcon={isEdit ? <ArrowDropDown /> : <ArrowDropDown style={{ display: 'none' }} />}
          fullWidth
          loading={loading}
          renderInput={(params) =>
            <TextField {...params} id={id + "Textfield"} label={label}
              inputRef={ref}
              onClick={() => {
                if (handleOnChangeCall != undefined && isEdit) handleOnChangeCall()
              }}
              helperText={error?.message}
              required={required}
              disabled={addressId != '' && !isEdit} variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />}
          onChange={(event, data, reason) => {
            if (setCountryReason) {
              setCountryReason(reason)
            }
            if (setValue && clearErrors && reason === 'selectOption') {
              setValue('postalCode', '')
              clearErrors('postalCode')
            }
            field.onChange(data)
          }}
          onInputChange={(_, data, reason) => {
            if (freesolo || (reason === 'reset' && (data !== '' || data !== null))) {
              field.onChange(data)
            }
          }}
        />
      )}
    />
  )
}

export default AddressAutocompleteControl
