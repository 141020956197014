import { alpha, Button, Dialog, DialogActions, Divider, Fab, Grid, Stack, Typography } from '@mui/material'
import { TbFileUpload } from 'react-icons/tb'
import React from 'react'
import { BorderBottom, Close, CloudUpload } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ReUploadButton } from '../authentication/AuthStyle';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '12px !important', background: '#f7f9f9 !important',
        // animation: 'fadeup .5s .5s ease both',
        // transform: 'translateY(20px)',
    },
    closeFab: {
        position: 'absolute !important',
        top: '2% !important',
        left: '90% !important',
        float: 'right !important',
        boxShadow: 'none !important',
        color: `${theme.palette.error.main} !important`,
    },
    title: {
        background: theme.palette.background.paper,
        borderBottom: '1px solid rgb(240 241 241) !important'
    },
    titleIcon: {
        fontSize: '3rem !important', color: alpha(theme.palette.primary.main, 0.7)
    },
    dialogActions: {
        padding: '10px 35px 32px 35px !important',
        columnGap: '1rem !important',
        justifyContent: 'center !important'
    },
    noBtn: {
        color: `${theme.palette.primary.main}!important`,
        border: '1px solid #eeeeee !important',
        background: `${theme.palette.background.paper}!important`,
        '&: hover': {
            color: `${theme.palette.primary.main}!important`,
            background: `${theme.palette.background.paper}!important`,
        }
    }
}))

const ReuploadPopUp = (props) => {
    const { title, message, open, handleClose, agreeLabel, disagreeLabel, onAgree, onDisAgree } = props;
    const classes = useStyles();

    const onClose = () => {
        handleClose();
    }
    return (
        <Dialog open={open} PaperProps={{ className: classes.paper }}>
            <Grid item xs={12}>
                <Fab id="doc-pop-cancel" className={classes.closeFab} size='small' sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
                    onClick={() => onClose()}>
                    <Close />
                </Fab>
            </Grid>
            <Stack>
                <Stack justifyContent='center' alignItems='center' px={3} py={3} spacing={1.5} className={classes.title}>
                    <CloudUpload className={classes.titleIcon} />
                    <Typography id="doc-pop-title" variant='h5'>{title}</Typography>
                </Stack>
                {/* <Divider flexItem /> */}
                <Stack justifyContent='center' alignItems='center' px={5} py={3}>
                    <Typography id="doc-pop-msg">{message}</Typography>
                </Stack>
            </Stack>
            <DialogActions className={classes.dialogActions}>
                <ReUploadButton id="doc-pop-no" variant='contained' className={classes.noBtn} onClick={onDisAgree}>
                    {disagreeLabel}
                </ReUploadButton>
                <ReUploadButton id="doc-pop-yes" variant='contained' color='primary' onClick={onAgree}>
                    {agreeLabel}
                </ReUploadButton>
            </DialogActions>
        </Dialog >
    )
}

export default ReuploadPopUp
