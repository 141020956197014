import { CalendarMonth } from '@mui/icons-material'
import { Grid, Stack, Tooltip, Typography, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Popover, Button, Badge } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { format } from 'date-fns'
import { default as React, Fragment, useContext, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RiAuctionFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import AuctionTimer from '../../../bidder/bid-process/AuctionTimer'
import BidTermsAndCondition from '../../../bidder/bid-process/terms-and-condition/BidTermsAndCondition'
import TimerDelayAlert from '../../../bidder/bid-process/timer-delay-alert/TimerDelayAlert'
import RequestForBid from '../../../bidder/request-for-bid/RequestForBid'
import AuctionLoader from '../../../common/auction-loader/AuctionLoader'
import AuctionConfirmDialog from '../../../common/AuctionConfirmDialog'
import AuctionSnackBar from '../../../common/AuctionSnackBar'
import { UserContext } from '../../../common/context/UserContext'
import GraphQLErrors from '../../../common/GraphQLErrors'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { AuctionCreateSchema } from "../../../schema/AuctionCreateSchema";
import { UPDATE_AUCTION_STATUS } from "../../../GraphQL/Mutation";
import { AxiosAuctionInterceptor, PublicAxiosAuctionInterceptor } from '../../../config/AxiosAuctionInterceptor';
import { useLazyQuery, useMutation } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { AuctionStatus, Roles } from '../../../common/StaticVariableDeclaration'
import { AuctionDetailContext } from '../../auction-detail/AuctionDetail'
import { AuctionStatusIndicator, ButtonGlow } from '../auction-style/AuctionStyle'
import { AuctionDetailMenuItems } from '../AuctionDetailStaticVar'
import { SubHeaderContainer } from '../side-nav/side-nav-style/SideNavStyle'
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import { AuctionBriefToPublish } from '../AuctionBriefToPublish';
import { useServerCurrentDateTime } from '../../../utils/Utils';

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    '@keyframes iconScale': {
        from: { transform: 'scale(0.8)' },
        to: { transform: 'scale(1.1)' }
    },
    icon: {
        fontSize: '4.5rem !important',
        color: `${theme.palette.success.main} !important`,
        animationName: '$iconScale',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    title: {
        color: `${theme.palette.success.main} !important`,
    },
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0.2 },
    },
    '@keyframes blinkerSelected': {
        from: { opacity: 1 },
        to: { opacity: 1 },
    },
    flashIcon: {
        animationName: '$blinker',
        animationDuration: '1.5s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        opacity: '0.4',
        background: `aliceblue !important`,
        color: `blue !important`,
        fontWeight: '600 !important',
        borderRadius: '20px !important',
        boxShadow: 'none !important',
        '&:hover': {
            animationName: '$blinkerSelected',
            borderLeft: '1px solid #c3d2e6 !important',
            borderBottom: '6px solid #c3d2e6 !important'
        },
    }
}))

const SubHeader = (props) => {
    const { auction_Id, ismenucollapse, title, startDate, registerStartDate, registerEndDate, isBidding,
        isRequestBid, setShowBidPage, handleSetShowBidPage, handleBackNavigation, status, lotId, selectedList,
        auctionCreationDate, handleGetAuctionDetail } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [showBidButton, setShowBidButton] = useState(AuctionDetailMenuItems.BIDDING_HALL.value === selectedList ? false : true);
    const [bidTermsCondition, setBidTermsCondition] = useState(false)
    const [timerDetail, setTimerDetail] = useState()
    const auctionDetailContext = useContext(AuctionDetailContext);
    const userSession = useContext(UserContext).rootContext.userSession;
    const userContext = useContext(UserContext).rootContext;
    const [showRequestForBid, setShowRequestForBid] = useState(false);
    const [moveAmongList, setMoveAmongList] = useState(null);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        agreeBtnLabel: '',
        disAgreeBtnLabel: '',
        isCancel: true
    })
    const [auctionMenuAnchorEl, setAuctionMenuAnchorEl] = useState(null);
    const isAuctionMenuOpen = Boolean(auctionMenuAnchorEl);
    const [showTodoTaskSummary, setShowTodoTaskSummary] = useState({
        open: false,
        todoList: []
    })
    const [loading, setLoading] = useState(false);

    function lazyWithPreload(url) {
        const Component = React.lazy(url);
        Component.preload = url;
        return Component;
    }

    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const TodoPendingTaskSummary = lazyWithPreload(() => import('../../../auction/todo-pending-task-summary/TodoPendingTaskSummary'));
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
    const serverCurrentTime = useServerCurrentDateTime();

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const onClickBidNow = () => {
        setBidTermsCondition(true);
    }

    const onAcceptTC = () => {
        setBidTermsCondition(false);
        setShowBidButton(false);
        handleSetShowBidPage();
    }

    const onDeclineTC = () => {
        setBidTermsCondition(false);
    }

    // const redirectMsg = () => {
    //     setSeekConfirmation({
    //         show: true,
    //         icon: <HiOutlineCheckCircle className={classes.icon} />,
    //         title: <span className={classes.title}>{t('Your_Request_Successfully_Submitted')}</span>,
    //         message: t('You_Will_Be_Redirect_To_Upcoming_Auction_List'),
    //         onAgree: () => onRedirect(),
    //         agreeBtnLabel: t('OK'),
    //         isCancel: false
    //     })
    // }

    // const resetSeekConfirmation = () => {
    //     setSeekConfirmation({
    //         show: false,
    //         title: '',
    //         message: '',
    //         onAgree: '',
    //         agreeBtnLabel: '',
    //         isCancel: true
    //     })
    // }

    // const onRedirect = () => {
    //     history.push({
    //         pathname: "/auction-list",
    //         state: {
    //             userRole: [],
    //             auctionListType: AuctionStatus.scheduled,
    //             pagination: {
    //                 page: 1,
    //                 pageSize: 10
    //             }
    //         }
    //     })
    //     resetSeekConfirmation();
    // }

    // const Timer = useCallback(() => {
    //     return (
    //         <AuctionTimer
    //             date={timerDetail.date}
    //             title={timerDetail.title}
    //             terminated={reloadAuctionContext}
    //             disable={(AuctionDetailMenuItems.BIDDING_HALL.value === selectedList ||
    //                 AuctionDetailMenuItems.AUCTION_HALL.value === selectedList) ? true : false}
    //         />
    //     )
    // }, [timerDetail])

    const updateAuctionContext = () => {
        handleGetAuctionDetail();
    }

    const reloadAuctionContext = () => {
        if(AuctionDetailMenuItems.BIDDING_HALL.value !== selectedList && AuctionDetailMenuItems.AUCTION_HALL.value !== selectedList) {
            setTimeout(() => {
                handleGetAuctionDetail();
            }, 1000);
        }
    }

    useEffect(() => {
        if (!isRequestBid && userSession.isAuthenticated && auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled) {
            setTimerDetail({ date: auctionDetailContext.auctionGetDetail.auctionLots[0].startTime, title: 'Live Start in' })
        }
        if (auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.open && new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].endTime) > serverCurrentTime) {
            setTimerDetail({ date: auctionDetailContext.auctionGetDetail.auctionLots[0].endTime, title: 'Live Ends in' })
        }
    }, [auctionDetailContext])

    const handleRequestToBid = () => {
        if (userSession.isAuthenticated) {
            setShowRequestForBid(true);
        } else {
            history.push({ pathname: "/sign-in" });
        }
    }

    const handleAuctionMenuClose = () => {
        setAuctionMenuAnchorEl(null)
    }

    const handleAuctionMenuOpen = (event) => {
        setAuctionMenuAnchorEl(event);
    };

    const [updateAuctionStatus, { loading: updateStatusLoading, error: updateStatusError }] = useMutation(UPDATE_AUCTION_STATUS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (resp) => {
            if (resp.updateAuctionStatus) {
                    if(moveAmongList == 'request_publish') {
                        showSuccessMsg({ show: true, message: t('Successfully_Sent_Request_To_SYSTEM_ADMIN/SUPPORT'), severity: 'success' });
                    } else if (moveAmongList == 'upcoming') {
                        showSuccessMsg({ show: true, message: t('Your_Auction_Is_Now_Visible_In') + " " + moveAmongList + " " + t('Auctions'), severity: 'success' });
                    } else {
                        showSuccessMsg({ show: true, message: t('Auction_Has_Been_Moved_To') + " " + moveAmongList + " " + t('auction') + " " + t('list'), severity: 'success' });
                    }
                handleGetAuctionDetail();
            }
            if (updateStatusError) {
                console.log('createAuction -> API error :', updateStatusError);
            }
        },
        onError: (error) => {
            console.log('createAuction -> NETWORK error :', error);
        }
    })

    const handleAuctionStatusUpdate = (status, msg) => {
        setMoveAmongList(null);
            if (msg == 'publish') {
                if(isAdmin || (isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))){
                    if(new Date(auctionDetailContext.auctionGetDetail[AuctionCreateSchema.auctionStartDate]) <= serverCurrentTime) {
                        setMoveAmongList(t('live'));
                    } else {
                        setMoveAmongList(t('upcoming'));
                    }
                } else {
                    setMoveAmongList(t('request_publish'));
                }
            } else if (msg == 'resetup') {
                setMoveAmongList(t('undersetup'));
            } else {
                setMoveAmongList(msg);
            }
        resetUpdateStatusConfirm()
        updateAuctionStatus({
            variables: {
                auctionId: auctionDetailContext.auctionGetDetail.id,
                status: status
            }
        })
    };

    const resetUpdateStatusConfirm = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const confirmUpdateStatus = (status, msg) => {
        handleAuctionMenuClose();
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: (msg == 'publish' && !isAdmin && !isCustomerSupport) ?
                <Stack spacing={2} direction='column'>
                    <Stack>{t("Are_You_Sure_That_You_Want_To_Send_The_Publish_Request_To_System_Admin/Support?")}</Stack>
                    <AuctionBriefToPublish auctionId={auctionDetailContext.auctionGetDetail.id} />
                    <Stack spacing={1} direction='column' justifyContent='center' alignItems='center'
                        sx={{ background: '#fff6e5', borderRadius: '10px', padding: '10px' }}>
                        <ContactSupportRoundedIcon color='warning' />
                        <Typography fontSize='14px'>
                            {t('Pubish_Contact_Support')} {t('Space')} {process.env.REACT_APP_PLATFORM} {t('Space')} {t('Support_At')} {t('Space')} {process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}
                        </Typography>
                    </Stack>
                </Stack>
                :
                <Stack spacing={2} direction='column'>
                    <Stack>{(t('Are_You_Sure_That_You_Want_To') + ' ' + t(msg) + '?')}</Stack>
                    {msg == 'publish' && <AuctionBriefToPublish auctionId={auctionDetailContext.auctionGetDetail.id} />}
                </Stack>,
            onDisAgree: resetUpdateStatusConfirm,
            onAgree: () => { handleAuctionStatusUpdate(status, msg) },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const checkAuctionTodoEvents = (status, msg) => {
        handleAuctionMenuClose();
        setLoading(true);
        AxiosAuctionInterceptor.get(`rest/auction/todo/${auctionDetailContext.auctionGetDetail.id}`).then(function (response) {
            if (response.length > 0) {
                setShowTodoTaskSummary({
                    open: true,
                    todoList: response,
                    data: auctionDetailContext.auctionGetDetail,
                    status: status,
                    msg: msg
                })
            } else {
                confirmUpdateStatus(status, msg);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' });
            setMessage({ show: true, message: error, severity: 'error' });
        });
    }

    const auctionMenuItem = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <List aria-label="auction-settings-menu">
                        {userSession.isAuthenticated && new Date(auctionDetailContext.auctionGetDetail[AuctionCreateSchema.auctionEndDate]) > serverCurrentTime &&
                            ((isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support)) || isAdmin || auctionDetailContext.auctionGetDetail.roles.includes(Roles.support) || auctionDetailContext.auctionGetDetail.roles.includes(Roles.auctioneer)) &&
                            <Fragment>
                                {auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.setup &&
                                    <ListItemButton id={'publish-auction'} key={3} className={classes.auctionMenuIcon}
                                        onClick={() => checkAuctionTodoEvents(AuctionStatus.ready, 'publish')}>
                                        <ListItemIcon>
                                            <UpcomingIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={t((isCustomerSupport || isAdmin) ? 'Publish_Auction' : 'Send_Request_To_Publish_Auction')}></ListItemText>
                                    </ListItemButton>
                                }
                                { auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.publish_request &&
                                     <Fragment>
                                        {((isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support)) || isAdmin) ?
                                            <ListItemButton id={'publish-auction'} key={3} className={classes.auctionMenuIcon}
                                                onClick={() => checkAuctionTodoEvents(AuctionStatus.ready, 'publish')}>
                                                <ListItemIcon>
                                                <Badge color="secondary" badgeContent="1">
                                                    <UpcomingIcon color="primary" />
                                                    </Badge>
                                                </ListItemIcon>
                                                <ListItemText primary={t('Publish_Auction')}></ListItemText>
                                            </ListItemButton>
                                            : <ListItemButton id={'publish-auction'} key={3} className={classes.auctionMenuIcon}
                                                onClick={() => checkAuctionTodoEvents(AuctionStatus.ready, 'publish')}>
                                                <ListItemIcon>
                                                    <SendIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={t('Resend_Request_To_Publish_Auction')}></ListItemText>
                                            </ListItemButton>
                                            }
                                     </Fragment>
                                }
                            </Fragment>
                        }
                        {userSession.isAuthenticated && ( isAdmin || auctionDetailContext.auctionGetDetail.roles.includes(Roles.support) || auctionDetailContext.auctionGetDetail.roles.includes(Roles.auctioneer)) &&
                            (auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.setup || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.publish_request || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.scheduled
                                || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.open) &&
                            <ListItemButton id={'cancel-auction'} key={2} className={classes.auctionMenuIcon}
                                onClick={() => confirmUpdateStatus(AuctionStatus.cancelled, 'cancel')}
                            >
                                <ListItemIcon>
                                    <HighlightOffIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={t('Cancel') + ' ' + t('Auction')}></ListItemText>
                            </ListItemButton>
                        }
                    </List>
                </Grid>
            </Grid>
        )
    }

    const ChangeSideNavigation = (data, navigation) => {
        auctionDetailContext.setSelectedList(navigation);
        handleTodoTaskSummaryClose();
    }

    const handleTodoTaskSummaryClose = () => {
        setShowTodoTaskSummary({
            open: false,
            todoList: [],
        })
    }

    useEffect(() => {
        setTimeout(() => {
            TodoPendingTaskSummary.preload();
        }, 3000);
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
              handleGetAuctionDetail();
            }
          };
          document.addEventListener('visibilitychange', handleVisibilityChange);
          return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [])

    return (
        <Fragment>
            <SubHeaderContainer iscollapse={ismenucollapse}>
                {/* <Stack width={{ xs: '15%' }}>
                    <Button id='auction-info-back' aria-label="back-to-auction" color="primary" size="large" sx={{ textTransform: 'none', width: 'fit-content' }}
                        onClick={handleBackNavigation}>
                        <IoMdArrowRoundBack style={{ fontSize: 'larger' }} />&nbsp; {'Back to Auction'}
                    </Button>
                </Stack> */}
                <Grid container justifyContent='center' alignItems='center' height='100%'>
                    <Grid item xs={4} sm={4} md={2} lg={3} alignItems='center'>
                        {auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.open && <AuctionStatusIndicator id='status' bg={'linear-gradient(195deg, rgb(194 255 226), rgb(194 255 226))'} color={'#115201'}>{'Live'}</AuctionStatusIndicator>}
                        {auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled && <AuctionStatusIndicator id='status' bg={'linear-gradient(195deg, rgb(254 230 174), rgb(255 208 135))'} color={'#794600'}>{'Upcoming'}</AuctionStatusIndicator>}
                        {auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.setup && <AuctionStatusIndicator id='status' bg={'linear-gradient(195deg, rgb(223 235 247), rgb(225 236 250))'} color={'rgb(6 76 91)'}>{'Under Setup'}</AuctionStatusIndicator>}
                        {auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request && <AuctionStatusIndicator id='status' bg={'linear-gradient(195deg, rgb(223 235 247), rgb(225 236 250))'} color={'rgb(6 76 91)'}>{'Requested to Publish'}</AuctionStatusIndicator>}
                        {auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed && <AuctionStatusIndicator id='status' bg={'linear-gradient(195deg, rgb(251 193 193), rgb(253 164 164))'} color={'#790000'}>{'Closed'}</AuctionStatusIndicator>}
                        {auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.cancelled && <AuctionStatusIndicator id='status' bg={'linear-gradient(195deg, rgb(251 193 193), rgb(253 164 164))'} color={'#790000'}>{'Cancelled'}</AuctionStatusIndicator>}
                    </Grid>
                    <Grid item xs={0} sm={0} md={6.2} lg={5.5} alignItems='center' justifyContent='center' sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Tooltip title={title}>
                                    <Typography id='auctiontitle' sx={{ fontWeight: '600', fontSize: '1.1rem', textAlign: 'center' }} variant='h6' noWrap>
                                        {title}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={1} justifyContent='center'>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        <CalendarMonth fontSize='1.5rem' style={{ marginTop: '-2px', color: '#c59606' }} />
                                        <Typography id='startlabel' variant='subtitle2' color='forestgreen' noWrap>Start:</Typography>
                                        <Typography id='startdate' variant='subtitle2' fontWeight='bold' color='GrayText' noWrap>
                                            {format(new Date(startDate), 'dd MMM yyyy - hh:mm:ss a')}
                                        </Typography>
                                    </Stack>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        {/* <CalendarMonth fontSize='1.5rem' style={{ marginTop: '-2px', color: '#c59606' }} /> */}
                                        <Typography id='endlabel' variant='subtitle2' color='indianred' noWrap>End:</Typography>
                                        <Typography id='enddate' variant='subtitle2' fontWeight='bold' color='GrayText' noWrap>
                                            {format(new Date(auctionDetailContext?.auctionGetDetail.auctionLots[0].endTime), 'dd MMM yyyy - hh:mm:ss a')}
                                        </Typography>
                                        <Typography variant='subtitle2' color='InactiveCaptionText' noWrap>({dateTimeZoneValue})</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} sm={8} md={3.8} lg={3.5} alignItems='center'>
                        <Stack direction='row' height={{ xs: '50px' }} justifyContent='end'>
                            {(status === AuctionStatus.open || status === AuctionStatus.scheduled) && timerDetail &&
                                <Stack display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex' }}>
                                    {/* <Timer /> */}
                                    <AuctionTimer
                                        date={timerDetail.date}
                                        title={timerDetail.title}
                                        terminated={reloadAuctionContext}
                                        disable={(AuctionDetailMenuItems.BIDDING_HALL.value === selectedList ||
                                            AuctionDetailMenuItems.AUCTION_HALL.value === selectedList) ? true : false}
                                    />
                                </Stack>}
                            <div style={{ float: 'right', margin: 'auto 0px auto 15px' }}>
                                {isBidding && showBidButton &&
                                // <ButtonGlow variant='contained' id='bidnow' size='small' color='primary' onClick={() => { onClickBidNow(); }}
                                //     startIcon={<RiAuctionFill className='icon' />}
                                // >
                                //     Bid Now
                                // </ButtonGlow>
                                <Button
                                    id='bidnow'
                                    variant="contained"
                                    startIcon={<RiAuctionFill />}
                                    sx={{ textTransform: 'none'}}
                                    onClick={() => onClickBidNow() }
                                    className={classes.flashIcon}
                                >{t('Bid_Now')}
                                </Button>}
                                {isRequestBid && <Tooltip title={t('Interested_To_Bid')} arrow placement='bottom'>
                                    {/* <ButtonGlow variant='contained' color='primary' id='requesttobid' size='small' sx={{textTransform: 'capitalize'}}
                                        onClick={() => { handleRequestToBid() }}
                                    >
                                        {t('Interested?')}
                                    </ButtonGlow> */}
                                    <Button
                                        id='requesttobid'
                                        variant="contained"
                                        sx={{ textTransform: 'none'}}
                                        onClick={() => handleRequestToBid() }
                                        className={classes.flashIcon}
                                    >{t('Interested?')}
                                    </Button>
                                </Tooltip>}
                                { userSession.isAuthenticated && new Date(auctionDetailContext.auctionGetDetail[AuctionCreateSchema.auctionEndDate]) > serverCurrentTime &&
                                    (isCustomerSupport || isAdmin || auctionDetailContext.auctionGetDetail.roles.includes(Roles.support) || auctionDetailContext.auctionGetDetail.roles.includes(Roles.auctioneer)) &&
                                    auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.setup &&
                                    // <ButtonGlow variant='contained' color='primary' id='publish-auction-link' size='small' sx={{textTransform: 'capitalize'}}
                                    //     onClick={() => checkAuctionTodoEvents(AuctionStatus.ready, 'publish')}
                                    // >
                                    //     {t('Publish_Auction')}
                                    // </ButtonGlow>
                                    <Button
                                        id='publish-auction-link'
                                        variant="contained"
                                        startIcon={<UpcomingIcon />}
                                        sx={{ textTransform: 'none'}}
                                        onClick={() => checkAuctionTodoEvents(AuctionStatus.ready, 'publish')}
                                        className={classes.flashIcon}
                                    >{t('Publish_Auction')}
                                    </Button>
                                }
                                { userSession.isAuthenticated && 
                                    ( isAdmin || auctionDetailContext.auctionGetDetail.roles.includes(Roles.support) || auctionDetailContext.auctionGetDetail.roles.includes(Roles.auctioneer))
                                    && (auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.setup || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.publish_request || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.scheduled
                                    || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.open) &&
                                    <Tooltip title={t('Actions')} arrow>
                                        <IconButton id={'open-auction-menu-buttons'} color="primary" sx={{ padding: '4px !important', marginLeft: '4px' }}
                                            onClick={(event) => handleAuctionMenuOpen(event.target)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {/* {!showBidButton && <Button variant='contained' color='primary' onClick={() => { setShowBidButton(true); setShowBidPage(false) }}>
                        Back
                    </Button>} */}
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
                {/* <Stack direction='row' alignItems='center' spacing={1}>
                <CalendarMonth fontSize='1.5rem' style={{ marginTop: '-2px' }} />
                <Typography variant='subtitle2'>Extended Date Time:</Typography>
                <Typography variant='subtitle2'>22/10/2022 10:00 PM</Typography>
            </Stack> */}
            </SubHeaderContainer >
            <Popover
                id={'detail-more-actions-popup'}
                open={isAuctionMenuOpen}
                anchorEl={auctionMenuAnchorEl}
                onClose={handleAuctionMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {auctionMenuItem()}
            </Popover>
            {
                showRequestForBid &&
                <RequestForBid onClose={setShowRequestForBid} show={showRequestForBid} auction_id={props.auction_Id}
                    onSuccess={showSuccessMsg} seller_id={props.seller_id} auctionCreationDate={auctionCreationDate}
                    updateAuctionContext={updateAuctionContext}
                    allowUpdateReceivedEMDField={auctionDetailContext.auctionGetDetail.roles.includes(Roles.support) || auctionDetailContext.auctionGetDetail.roles.includes(Roles.auctioneer)} />
            }
            {   showTodoTaskSummary.open && <TodoPendingTaskSummary
                handleAuctionDetail={ChangeSideNavigation}
                onClose={handleTodoTaskSummaryClose}
                showTodoTaskSummary={showTodoTaskSummary}
            />  }
            { (updateStatusLoading || loading) && <AuctionLoader show={ updateStatusLoading || loading } invisible={false} /> }
            { updateStatusError  && <GraphQLErrors error={ updateStatusError } show={false} /> }
            { seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            /> }
            { bidTermsCondition &&
                <BidTermsAndCondition open={bidTermsCondition} onAccept={onAcceptTC} onDecline={onDeclineTC} auctionId={auction_Id} lotId={lotId} /> }
            { message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} /> }
        </Fragment>
    )
}

export default SubHeader

