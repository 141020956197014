import React, { useContext, useEffect }     from 'react';
import {
    Grid,
    TextField, Box
}                                           from '@mui/material';
import { makeStyles }                       from "@mui/styles";
import { useTranslation }                   from 'react-i18next';
import { Validation }                       from '../../../common/Validation';
import { UserContext }                      from "../../../common/context/UserContext";
import { AccountType }                      from '../../../common/StaticVariableDeclaration';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        borderColor: theme.outlinedTextFieldBorderColor
    }
}))

const BankDetailInput = ({register, errors, getValues, watch, resetField, isView, control, setValue, trigger, isLoginUser}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const watchAccountType = watch("accountType");

    useEffect(() => {
        if(getValues('accountType') != 'NOT_SPECIFIED_OR_OTHER') {
            resetField('otherAccountType')
        }
    }, [watch('accountType')])

    const handleTrimValue = (event) => {
        if(event.target.name == 'bankHolderName') {
            setValue('bankHolderName', event.target.value.trimStart());
            trigger('bankHolderName');
        } else if(event.target.name == 'bankName') {
            setValue('bankName', event.target.value.trimStart());
            trigger('bankName');
        } else if(event.target.name == 'branchName') {
            setValue('branchName', event.target.value.trimStart());
            trigger('branchName');
        } else if(event.target.name == 'bankAccountNumber') {
            setValue('bankAccountNumber', event.target.value.trim());
            trigger('bankAccountNumber');
        } else if(event.target.name == 'micr') {
            setValue('micr', event.target.value.trim());
            trigger('micr');
        }
    }

    const handleUpperCase = (event) => {
        setValue('ifsc', event.target.value.toUpperCase());
        trigger('ifsc');
    }

    return (
        <Box sx={{p: 3}}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                    name="bankHolderName"
                    control={control}
                    rules={{...Validation.name, ...Validation.required}}
                    render={({ field }) => (
                        <TextField id="account-holder-name" label={t('Account_Holder_Name')}
                            fullWidth {...field}
                            type="text"
                            defaultValue=''
                            required={!isView}
                            onChange={handleTrimValue}
                            helperText={errors["bankHolderName"] && errors["bankHolderName"].message}
                            InputLabelProps={{ shrink: field.value }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            disabled={isView || isLoginUser}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                    name="bankAccountNumber"
                    control={control}
                    rules={{...Validation.accountNumber, ...Validation.required}}
                    render={({ field }) => (
                        <TextField id="account-number" label={t('Bank_Account_Number')}
                            fullWidth {...field}
                            value={getValues('bankAccountNumber')}
                            required={!isView}
                            onChange={handleTrimValue}
                            helperText={errors["bankAccountNumber"] && errors["bankAccountNumber"].message}
                            InputLabelProps={{ shrink: getValues('bankAccountNumber')}}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            disabled={isView || isLoginUser}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={watchAccountType === AccountType.other ? 3 : 4 }>
                <TextField
                    required={!isView}
                    fullWidth
                    id="account-type"
                    select
                    SelectProps={{
                        native: true,
                    }}
                    disabled={isView || isLoginUser}
                    label={t('Account_Type')}
                    {...register("accountType", Validation.required)}
                    // onBlur={handleChange}
                    // onChange={() => {
                    //     if(getValues('accountType') != 'NOT_SPECIFIED_OR_OTHER') {
                    //         resetField('otherAccountType');
                    //     }
                    // }}
                    helperText={errors["accountType"] && errors["accountType"].message}
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                        }
                    }}
                    >
                    {enumListOptions.AccountType.map((option) => (
                        <option key={option.id} value={option.dateBaseCode}>
                            {option.uiCode}
                        </option>
                    ))}
                </TextField>
            </Grid>
            {watchAccountType === AccountType.other && <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                    required={!isView}
                    fullWidth
                    defaultValue=''
                    id="other-account-type"
                    label={t('Other_Account_Type')}
                    {...register("otherAccountType", {required: watchAccountType === AccountType.other ? true :  false, ...Validation.otherAccountType})}
                    helperText={errors["otherAccountType"] && errors["otherAccountType"].message}
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    disabled={isView || isLoginUser}
                />   
            </Grid>}
            <Grid item xs={12} sm={12} md={6} lg={watchAccountType === AccountType.other ? 3 : 4}>
                <Controller
                    name="ifsc"
                    control={control}
                    rules={{...Validation.required, ...Validation.ifsc}}
                    render={({ field }) => (
                        <TextField id="ifsc-number" label={t('IFSC')}
                            fullWidth {...field}
                            onChange={handleUpperCase}
                            helperText={errors["ifsc"] && errors["ifsc"].message}
                            InputLabelProps={{ shrink: field.value }}
                            required={!isView}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            disabled={isView || isLoginUser}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={watchAccountType === AccountType.other ? 3 : 4}>
                <Controller
                    name="micr"
                    control={control}
                    rules={Validation.micr}
                    render={({ field }) => (
                        <TextField id="micr-number" label={t('MICR')}
                            fullWidth {...field}
                            onChange={handleTrimValue}
                            helperText={errors["micr"] && errors["micr"].message}
                            InputLabelProps={{ shrink: field.value }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            disabled={isView || isLoginUser}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                    name="bankName"
                    control={control}
                    rules={{...Validation.bankName, ...Validation.required}}
                    render={({ field }) => (
                        <TextField id="bank-name" label={t('Bank_Name')}
                            fullWidth {...field}
                            defaultValue=''
                            required={!isView}
                            onChange={handleTrimValue}
                            helperText={errors["bankName"] && errors["bankName"].message}
                            InputLabelProps={{ shrink: field.value }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            disabled={isView || isLoginUser}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
                    name="branchName"
                    control={control}
                    rules={{...Validation.branchName}}
                    render={({ field }) => (
                        <TextField id="branch-name" label={t('Branch_Name')}
                            fullWidth {...field}
                            defaultValue=''
                            onChange={handleTrimValue}
                            helperText={errors["branchName"] && errors["branchName"].message}
                            InputLabelProps={{ shrink: field.value }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            disabled={isView || isLoginUser}
                        />
                    )}
                />
            </Grid>
        </Grid>
        </Box>
    )
}

export default BankDetailInput