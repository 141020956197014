import React, { useState, useEffect, useContext } from 'react';
import {
    Grid, TableContainer, Paper,
    Table, TableHead, TableCell, TableBody, TableRow,
    Button, BottomNavigation, Tooltip,
    IconButton,
    Popover, Typography, MenuList, MenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import {
    useLazyQuery,
    useMutation
} from '@apollo/client';
import {
    GET_PRE_SIGNED_URL, LIST_FILE_VERSIONS,
    LIST_REQUIRED_DOCUMENTS
} from '../../../GraphQL/Queries';
import AuctionSnackBar from '../../../common/AuctionSnackBar';
import AuctionLoader from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../../common/GraphQLErrors';
import AuctionPagination from '../../../common/pagination/AuctionPagination';
import { FileIcon } from '../../../common/files-upload/FileIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import { DELETE_REQUIRED_DOCUMENT, UPDATE_REQUIRED_DOCUMENT } from '../../../GraphQL/Mutation';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import UploadDialog from '../../../common/files-upload/UploadDialog';
import axios from 'axios';
import InformationDocumentDetail from '../information-document-detail/InformationDocumentDetail';
import { Roles, FilePath } from '../../../common/StaticVariableDeclaration';
import { fileSizeConversion } from '../../../common/FileSizeConversion';
import AuctionConfirmDialog from '../../../common/AuctionConfirmDialog';
import { UserContext } from '../../../common/context/UserContext';
import { PublicAxiosAuctionInterceptor } from '../../../config/AxiosAuctionInterceptor';
import ReuploadPopUp from '../../ReuploadPopUp';

const useStyles = makeStyles((theme) => ({
    paper: {
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '0px',
        border: "1px solid rgb(238, 238, 238)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        margin: "auto auto ",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 10px !important"
    },
    tableHead: {
        fontWeight: "bold !important",
    },
    tableData: {
        color: theme.tableDataColor
    },
    tableHeadRow: {
        backgroundColor: theme.tableHearderBackgroundColor
    },
    fileNameDivBlock: {
        width: '115px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto',
        float: 'left'
    },
    fileSizeClass: {
        fontSize: '12px',
        color: theme.fileSizeColor,
        marginTop: '2px',
        float: 'right'
    },
    infoMsg: {
        color: theme.bankAccountInfoColor,
        fontWeight: 'bold',
        fontSize: '14px'
    },
}))

const InformationDocumentList = ({ auction_Id, showAdd, showEdit, showDelete, showActionColumn, isCustomerSupport, showUpload, roles, isAdmin }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [auctionId] = useState(auction_Id);
    const [documents, setDocuments] = useState([]);
    const [canAdd] = useState(showAdd ? showAdd : false);
    const [canEdit] = useState(showEdit ? showEdit : false);
    const [canDelete] = useState(showDelete ? showDelete : false);
    const [canUpload] = useState(showUpload ? showUpload : false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalNumberOfDocuments, setTotalNumberOfDocuments] = useState(0);
    const userContext = useContext(UserContext).rootContext;
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [isReupload, setIsReupload] = useState(false);
    const [fileUploadDialog, setFileUploadDialog] = useState(false)
    const [documentDetail, setDocumentDetail] = useState({});
    const [versionList, setVersionList] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popOverEvent, setPopOverEvent] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = "version-download";
    const [isDelete, setIsDelete] = useState(false);
    const [existingTypes, setExistingTypes] = useState([]);
    const [loading, setLoading] = useState(false)
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [listRequiredDocuments, { loading: fetchingRequiredDocuments, error: errorOnFetchingRequiredDocuments, refetch }] =
        useLazyQuery(LIST_REQUIRED_DOCUMENTS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setDocuments([]);
                setExistingTypes([]);
                if (data.listRequiredDocuments != null) {
                    data.listRequiredDocuments.documents.map(requiredDocument => {
                        setExistingTypes(prevState => ([
                            ...prevState,
                            requiredDocument.name
                        ]))
                        setDocuments(prevState => ([
                            ...prevState,
                            {
                                id: requiredDocument.id,
                                name: requiredDocument.name,
                                submitFormat: requiredDocument.submitFormat,
                                requiresDigitalSignature: requiredDocument.requiresDigitalSignature,
                                isMandatory: requiredDocument.isMandatory,
                                s3Uri: requiredDocument.s3Uri,
                                templateS3Uri: requiredDocument.templateS3Uri,
                                startTime: requiredDocument.startTime,
                                endTime: requiredDocument.endTime,
                                addedBy: requiredDocument.addedBy,
                                removedBy: requiredDocument.removedBy,
                                updatedBy: requiredDocument.updatedBy,
                                referenceNumber: requiredDocument.referenceNumber,
                                requiredDocumentBrief: requiredDocument.requiredDocumentBrief,
                                submittedDocumentBrief: requiredDocument.submittedDocumentBrief,
                                toSubmit: requiredDocument.toSubmit,
                                templateS3UriSize: fileSizeConversion(requiredDocument.templateS3UriSize),
                                templateS3UriFileName: requiredDocument.templateS3UriFileName,
                                templateS3UriFileLastModifiedTime: requiredDocument.templateS3UriFileLastModifiedTime,
                                isNew: false
                            }
                        ]))
                    })
                    setTotalNumberOfDocuments(data.listRequiredDocuments.count);
                }
            }
        })

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            listRequiredDocuments({
                variables: {
                    auctionId: auctionId,
                    bidderId: null,
                    pagination: {
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    },
                    showDeleted: false,
                    toSubmit: false
                }
            })
        } else {
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/document/${auctionId}/${page}/${rowsPerPage}/false/false`).then((response) => {
                setDocuments([]);
                response.documents.map(requiredDocument => {
                    setDocuments(prevState => ([
                        ...prevState,
                        {
                            id: requiredDocument.id,
                            name: requiredDocument.name,
                            submitFormat: requiredDocument.submitFormat,
                            requiresDigitalSignature: requiredDocument.requiresDigitalSignature,
                            isMandatory: requiredDocument.isMandatory,
                            s3Uri: requiredDocument.s3Uri,
                            templateS3Uri: requiredDocument.templateS3Uri,
                            startTime: requiredDocument.startTime,
                            endTime: requiredDocument.endTime,
                            addedBy: requiredDocument.addedBy,
                            removedBy: requiredDocument.removedBy,
                            updatedBy: requiredDocument.updatedBy,
                            referenceNumber: requiredDocument.referenceNumber,
                            requiredDocumentBrief: requiredDocument.requiredDocumentBrief,
                            submittedDocumentBrief: requiredDocument.submittedDocumentBrief,
                            toSubmit: requiredDocument.toSubmit,
                            templateS3UriSize: fileSizeConversion(requiredDocument.templateS3UriSize),
                            templateS3UriFileName: requiredDocument.templateS3UriFileName,
                            templateS3UriFileLastModifiedTime: requiredDocument.templateS3UriFileLastModifiedTime,
                            isNew: false
                        }
                    ]))
                })
                setTotalNumberOfDocuments(response.count);
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({ show: false, message: '', severity: '' })
                setMessage({ show: true, message: error, severity: 'error' })
            });
        }
    }, [rowsPerPage, page])

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setTimeout(() => {
                setMessage(msg);
            }, 100)
        }
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    }

    const deleteDocumentConfirmation = (documentId) => {
        setIsDelete(true);
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Document'),
            onAgree: () => deleteDocument(documentId),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const deleteDocument = (documentId) => {
        resetSeekConfirmation();
        if (documentId) {
            deleteRequiredDocument({
                variables: {
                    auctionId: auctionId,
                    documentId: documentId
                }
            })
        }
    }

    const handleUpload = (document) => {
        setIsDelete(false);
        setDocumentDetail({});
        setDocumentDetail(document);
        setSeekConfitmation({
            show: true,
            title: t('Reupload'),
            message: t('Do_You_Want_To_Reupload_With_Same_File_Name'),
            onAgree: () => handleUserInput(true),
            onDisAgree: () => handleUserInput(false),
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const handleUserInput = (isReupload) => {
        setIsReupload(isReupload)
        resetSeekConfirmation();
        setFileUploadDialog(true);
    }

    const handlePublicApi = (event, document) => {
        setAnchorEl(null);
        setAnchorEl(event.currentTarget);
        setVersionList([]);
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/list-versions?fileName=${document.templateS3Uri}`).then((response) => {
            if (response.length > 1) {
                setVersionList(response)
                setLoading(false);
            } else {
                setAnchorEl(null);
                setPopOverEvent(null);
                downloadPublicApiFile(document.templateS3Uri, '');
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' })
            setMessage({ show: true, message: error, severity: 'error' })
        });
    }

    const handleDownload = (event, document) => {
        setPopOverEvent(event.currentTarget);
        setDocumentDetail(document);
        if (userContext.userSession.isAuthenticated) {
            getListVersions({
                variables: {
                    fileName: document.templateS3Uri
                }
            });
        } else {
            handlePublicApi(event, document);
        }

    }

    const handleClose = () => {
        setAnchorEl(null);
        setVersionList([]);
    };

    const [getListVersions, { loading: fileLoading, error: errorOnFileLoading }] = useLazyQuery(LIST_FILE_VERSIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.listFileVersions.length > 1) {
                setAnchorEl(popOverEvent);
                setVersionList(data.listFileVersions)
            } else {
                setAnchorEl(null)
                setPopOverEvent(null);
                getFileDownloaded({
                    variables: {
                        presignedUrl: {
                            fileName: documentDetail.templateS3Uri,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'GET',
                            versionId: '',
                            isVersion: false,
                        }
                    }
                })
            }
        }
    })

    const [getFileDownloaded, { loading: gettingPresignedUrl, error: errorOnGettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: preSignUrlData => {
            if (preSignUrlData.getPresignedUrl) {
                let subURL = preSignUrlData.getPresignedUrl.split("?")[0];
                let fileName = subURL.substring(subURL.lastIndexOf('/') + 1);
                axios({
                    url: preSignUrlData.getPresignedUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute('download', decodeURI(fileName));
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ show: true, message: error, severity: 'error' })
                    // setDownload(false)
                })
            }
        }
    })

    const recordFileUpload = (presignedUrl) => {
        if (auctionId) {
            updateRequiredDocument({
                variables: {
                    auctionId: auctionId,
                    documentInput: {
                        id: documentDetail.id,
                        name: documentDetail.name,
                        isMandatory: documentDetail.isMandatory,
                        requiresDigitalSignature: documentDetail.requiresDigitalSignature,
                        submitFormat: documentDetail.submitFormat,
                        templateS3Uri: presignedUrl,
                        toSubmit: documentDetail.toSubmit,
                        requiredDocumentBrief: documentDetail.requiredDocumentBrief
                    }
                }
            })
        }
    }

    const [updateRequiredDocument, { loading: updatingRequiredDocument, error: errorOnUpdatingRequiredDocument }] =
        useMutation(UPDATE_REQUIRED_DOCUMENT, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateRequiredDocument) {
                    showSuccessMsg({ show: true, message: t('Document_Uploaded_Successfully'), severity: 'success' });
                }
            },
            refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const [deleteRequiredDocument, { loading: deletingRequiredDocument, error: errorOnDeletingRequiredDocument }] =
        useMutation(DELETE_REQUIRED_DOCUMENT, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.deleteRequiredDocument) {
                    showSuccessMsg({ show: true, message: t('Document_Deleted_Successfully'), severity: 'success' });
                    if (page !== 1) {
                        let lastPageRows = totalNumberOfDocuments % rowsPerPage;
                        let noOfPage = ~~(totalNumberOfDocuments / rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                        if (lastPageRows == 1 && page == noOfPage) {
                            setPage(1);
                        } else {
                            refetch();
                        }
                    } else {
                        refetch();
                    }
                }
            },
            // refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const addNewDocument = () => {
        setOpenPopup(true);
        setIsNew(true);
    }

    const setCancel = (isCancel) => {
        setOpenPopup(false);
    }

    const updateDocument = (document) => {
        setOpenPopup(true);
        setIsNew(false);
        setDocumentDetail(document);
    }

    const downloadPublicApiFile = (url, version_id) => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/get-presigned-url/${process.env.REACT_APP_S3_BUCKET}?fileName=${url}&versionId=${version_id}`).then((response) => {
            if (response) {
                let subURL = response.split("?")[0];
                let fileName = subURL.substring(subURL.lastIndexOf('/') + 1);
                axios({
                    url: response,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute('download', decodeURI(fileName));
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ show: true, message: error, severity: 'error' })
                    // setDownload(false)
                })
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' })
            setMessage({ show: true, message: error, severity: 'error' })
        });
    }

    const handleVersionDownload = (event, version) => {
        setAnchorEl(null);
        if (userContext.userSession.isAuthenticated) {
            getFileDownloaded({
                variables: {
                    presignedUrl: {
                        fileName: version.key,
                        bucket: process.env.REACT_APP_S3_BUCKET,
                        httpMethod: 'GET',
                        versionId: version.versionId,
                        isVersion: false,
                    }
                }
            })
        } else {
            downloadPublicApiFile(version.key, version.versionId);
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={8} sx={{ padding: '5px, 0px' }}>
                    <span className={classes.infoMsg}>
                        { t('Auction_Documents_Info') }
                    </span>
                </Grid>
                {canAdd && <Grid item xs={4} sx={{ padding: '5px, 0px' }}>
                    <Button id="add-information-document" variant="contained" size="small" startIcon={<AddIcon />} style={{ float: 'right', marginBottom: '10px' }}
                        onClick={addNewDocument}>
                        {t('Add')}
                    </Button>
                </Grid>}
                <TableContainer component={Paper} className={classes.paper}>
                    <Table aria-label="required documents table" stickyHeader>
                        <TableHead className={`${classes.tableHeadRow}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '25%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Document_Type")}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '38%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Document_Brief")}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '23%', borderRight: '1px solid #c8d6eb' }}>
                                {t('Document')}
                            </TableCell>
                            {showActionColumn &&
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{ width: '14%' }}>
                                    {(roles?.includes(Roles.auctioneer || Roles.support) || isCustomerSupport || isAdmin) ? t("Actions") : t("Download")}
                                </TableCell>
                            }
                        </TableHead>
                        <TableBody id='info-document-list'>
                            {documents.length === 0 &&
                                <TableRow hover>
                                    <TableCell id="no-documents-row" colSpan={5} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                </TableRow>
                            }
                            {documents.length > 0 && documents.map((document, index) => {
                                return (<TableRow key={document.id + index} id={`info-doc${index}`}>
                                    <TableCell id={`document-name${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.name}</TableCell>
                                    <TableCell id={`document-brief${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.requiredDocumentBrief ? document.requiredDocumentBrief : '-'}</TableCell>
                                    <TableCell id={`doc-file${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                        <div style={{ width: 'fit-content' }}>
                                            <div>
                                                <div className={classes.fileNameDivBlock}>
                                                    <span style={{ position: 'relative', top: '4px' }}><FileIcon style={{ position: 'relative', top: '4px' }} fileName={document.templateS3UriFileName} fontSize={'1rem'}></FileIcon></span>
                                                    <Tooltip title={document.templateS3UriFileName}><i>{' ' + document.templateS3UriFileName}</i></Tooltip>
                                                </div>
                                                <span className={classes.fileSizeClass}>{'(' + document.templateS3UriSize + ')'}</span>
                                            </div>
                                            <p style={{ margin: 'auto', clear: 'both' }}><span style={{ height: '0.7rem !important', width: '0.8rem !important', position: 'relative', top: '3px' }}>
                                                <CalendarMonthIcon style={{ fontSize: '17px' }} /></span>
                                                {format(new Date(document.templateS3UriFileLastModifiedTime), ' dd MMM yyyy hh:mm a')}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell id={`doc-action${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="center">
                                        {canUpload &&
                                            <Tooltip title={t('Reupload_File_document')} arrow>
                                                <span id={`doc-upload${index}`} style={{ position: 'relative', top: '10px', cursor: 'pointer', padding: '5px' }}>
                                                    <img src={process.env.PUBLIC_URL + './images/fileUpload.png'} height={22} width={22}
                                                        onClick={() => handleUpload(document)}></img>
                                                </span>
                                            </Tooltip>
                                        }
                                        <React.Fragment>
                                            <Tooltip title={t('Download_File_Document')} arrow>
                                                <span id={`doc-download${index}`} style={{ position: 'relative', top: '10px', cursor: 'pointer', padding: '5px' }}><img aria-describedby={id} src={process.env.PUBLIC_URL + './images/fileDownload.png'} height={22} width={22}
                                                    onClick={(event) => handleDownload(event, document)}></img></span>
                                            </Tooltip>
                                        </React.Fragment>
                                        {canEdit && <Tooltip title={t('Edit')} arrow>
                                            <IconButton id={`doc-edit${index}`} aria-label="edit" color="primary" style={{ padding: '5px' }} onClick={() => updateDocument(document)}>
                                                <EditIcon style={{ fontSize: '1.25rem' }} />
                                            </IconButton>
                                        </Tooltip>}
                                        {canDelete && <Tooltip title={t('Delete')} arrow>
                                            <IconButton id={`doc-delete${index}`} aria-label="delete" color="secondary" style={{ padding: '5px' }} onClick={() => deleteDocumentConfirmation(document.id)}>
                                                <DeleteIcon style={{ fontSize: '1.25rem' }} />
                                            </IconButton>
                                        </Tooltip>}
                                    </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                    {totalNumberOfDocuments > 0 &&
                        <BottomNavigation style={{ padding: '12px' }}>
                            <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={totalNumberOfDocuments}
                                onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
                        </BottomNavigation>
                    }
                </TableContainer>
            </Grid>
            <UploadDialog
                dialogOpen={fileUploadDialog}
                setDialogOpen={setFileUploadDialog}
                isMultiple={false}
                auctionId={auctionId}
                fileName={auctionId + FilePath.auctionInfoDocumentsPath + '/' + documentDetail.name}
                title={t("Upload_Template_Files")}
                reUploadFile={isReupload}
                onSuccess={recordFileUpload}
                isTemplatePresigned={true}
                documentType={documentDetail.name}
                oldFilePath={!isReupload ? documentDetail.templateS3Uri : null}
            />
            {versionList.length > 0 && <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuList id="split-button-menu">
                    {versionList.map((option, index) => (
                        <MenuItem key={index} id={"info-doc-version" + index}
                            onClick={(event) => handleVersionDownload(event, option)}>
                            <Typography color={index === 0 ? "primary" : "none"} style={{ fontWeight: index === 0 ? 600 : 500 }}>{`Version ${versionList.length - index}`}</Typography>
                            <Typography color={index === 0 ? "primary" : "none"} style={{ fontSize: '14px', marginleft: '6px', marginBottom: '2px', fontWeight: index === 0 ? 600 : 500 }}>
                                &nbsp;({format(new Date(option.lastModified), ' dd MMM yyyy hh:mm:ss a ')})</Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>}
            {(fetchingRequiredDocuments || fileLoading || gettingPresignedUrl || updatingRequiredDocument || deletingRequiredDocument || loading)
                && <AuctionLoader show={(fetchingRequiredDocuments || fileLoading || gettingPresignedUrl ||
                    updatingRequiredDocument || deletingRequiredDocument || loading)} invisible={false} />}
            {(errorOnFetchingRequiredDocuments || errorOnFileLoading || errorOnGettingPresignedUrl || errorOnUpdatingRequiredDocument || errorOnDeletingRequiredDocument) && <GraphQLErrors error={(errorOnFetchingRequiredDocuments || errorOnFileLoading ||
                errorOnGettingPresignedUrl || errorOnUpdatingRequiredDocument || errorOnDeletingRequiredDocument)} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity}></AuctionSnackBar>}
            {openPopup && <InformationDocumentDetail openPopup={openPopup} setCancel={setCancel} isNew={isNew} auctionId={auctionId} rowsPerPage={rowsPerPage} onSuccess={showSuccessMsg}
                setOpenPopup={setOpenPopup} documentDetail={documentDetail} existingTypes={existingTypes} />}
            {isDelete && seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />}
            {!isDelete && seekConfirmation.show && <ReuploadPopUp open={seekConfirmation.show} title={seekConfirmation.title} handleClose={resetSeekConfirmation}
                message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree}
                agreeLabel={t('Yes_Same_File_Name')} disagreeLabel={t('No_Different_File_Name')} />}
        </React.Fragment>
    );
}

export default InformationDocumentList;