import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ErrorMsg from '../common/ErrorMsg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AuctionSnackBar = (props) => {
  const { handleResetOnClose } = props;
  const [open, setOpen] = React.useState(props.show);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (reason === 'timeout' && props.severity === 'error') {
      return;
    }
    if (handleResetOnClose) {
      handleResetOnClose();
    }
    setOpen(false);
  };

  return (
    <ErrorMsg>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={props.severity} sx={{ fontSize: '1rem', fontWeight: (props.weight ? props.weight : 'inherit') }}>
          {props.message}
        </Alert>
      </Snackbar>
    </ErrorMsg>
  )
}

export default AuctionSnackBar;