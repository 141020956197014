// WARNING: DO NOT EDIT. Its a aws configuration.

const aws =  {
    "aws_cognito_userPoolId": "ap-south-1_vQcuusGAJ",
    "aws_cognito_userPoolWebClientId": "3t55ubhd2tkpmlotm34mi0ho0",
    "aws_appsync_graphqlEndpoint": "http://43.204.99.186:9034/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dze5zmnwvvht7ewkcj7ex5jt7y",
};

export default aws;