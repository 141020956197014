import { Assignment, Category, DocumentScannerSharp, EventNote, Group, History, KeyboardArrowRightRounded, ManageAccounts, Payment, AccountBalance, PieChart } from '@mui/icons-material';
import { alpha, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { RiAuctionFill, RiFileEditFill } from 'react-icons/ri';
import { AuctionDetailMenuItems } from '../AuctionDetailStaticVar';
import { MenuBar, MenuListIcon, MenuListItem, MenuListText, MenuSvgIcon, SideNavBar, SideNavHeader } from './side-nav-style/SideNavStyle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SmsIcon from '@mui/icons-material/Sms';

const useStyles = makeStyles((theme) => ({
    menuItem: {
        borderLeft: '3px solid #298ce2 !important',
        backgroundColor: theme.palette.type === "light" ? alpha(theme.palette.primary.main, 0.04) + ' !important' : 'rgb(142 162 171 / 20%) !important',
    }
}))

const SideNav = props => {
    const { selectedList, listChangeHandler, menuCollapse, setMenuCollapse, handleBackNavigation,
        itemTitle, showAuctionHall, showBidPage, showBidder, showManageRole, showPayment, showBidHistory, showCommunications, showAuditTrail, showTermsAndCondition,
        showReport } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const AuctionDetailMenuItemsRender = [
        {
            title: AuctionDetailMenuItems.AUCTION_HALL.displayName, id: AuctionDetailMenuItems.AUCTION_HALL.id,
            icon: <PieChart />, value: AuctionDetailMenuItems.AUCTION_HALL.value, show: showAuctionHall
        },
        {
            title: AuctionDetailMenuItems.BIDDING_HALL.displayName, id: AuctionDetailMenuItems.BIDDING_HALL.id,
            icon: <RiAuctionFill />, value: AuctionDetailMenuItems.BIDDING_HALL.value, show: showBidPage
        },
        {
            title: AuctionDetailMenuItems.AUCTION_DETAILS.displayName, id: AuctionDetailMenuItems.AUCTION_DETAILS.id,
            icon: <Assignment style={{ fontSize: 'x-large' }} />, value: AuctionDetailMenuItems.AUCTION_DETAILS.value, show: true
        },
        {
            title: AuctionDetailMenuItems.SELLER.displayName, id: AuctionDetailMenuItems.SELLER.id,
            icon: <Group />, value: AuctionDetailMenuItems.SELLER.value, show: true
        },
        {
            title: itemTitle, id: AuctionDetailMenuItems.ITEMS.id,
            icon: <Category />, value: AuctionDetailMenuItems.ITEMS.value, show: true
        },
        {
            title: AuctionDetailMenuItems.DOCUMENTS.displayName, id: AuctionDetailMenuItems.DOCUMENTS.id,
            icon: <DocumentScannerSharp />, value: AuctionDetailMenuItems.DOCUMENTS.value, show: true
        },
        {
            title: AuctionDetailMenuItems.BIDDER.displayName, id: AuctionDetailMenuItems.BIDDER.id,
            icon: <Group />, value: AuctionDetailMenuItems.BIDDER.value, show: showBidder
        },
        // {
        //     title: AuctionDetailMenuItems.BANK_ACCOUNT.displayName, id: AuctionDetailMenuItems.BANK_ACCOUNT.id,
        //     icon: <AccountBalance />, value: AuctionDetailMenuItems.BANK_ACCOUNT.value, show: true
        // },
        // { title: 'Payment', icon: <Payment />, value: AuctionDetailMenuItems.PAYMENT.value, show: showPayment },
        {
            title: AuctionDetailMenuItems.MANAGE_ROLE.displayName, id: AuctionDetailMenuItems.MANAGE_ROLE.id,
            icon: <ManageAccounts />, value: AuctionDetailMenuItems.MANAGE_ROLE.value, show: showManageRole
        },
        {
            title: AuctionDetailMenuItems.BIDDING_HISTORY.displayName, id: AuctionDetailMenuItems.BIDDING_HISTORY.id,
            icon: <History />, value: AuctionDetailMenuItems.BIDDING_HISTORY.value, show: showBidHistory
        },
        {
            title: AuctionDetailMenuItems.AUDIT_TRAIL.displayName, id: AuctionDetailMenuItems.AUDIT_TRAIL.id,
            icon: <EventNote />, value: AuctionDetailMenuItems.AUDIT_TRAIL.value, show: showAuditTrail,

        },

        {
            title: AuctionDetailMenuItems.COMMUNICATIONS.displayName, id: AuctionDetailMenuItems.COMMUNICATIONS.id,
            icon: <SmsIcon />, value: AuctionDetailMenuItems.COMMUNICATIONS.value, show: showCommunications
        },
        {
            title: t('T_and_C'), toolTiptitle: AuctionDetailMenuItems.TERMS_AND_CONDITION.displayName, id: AuctionDetailMenuItems.TERMS_AND_CONDITION.id,
            icon: <RiFileEditFill style={{fontSize: '1.45rem'}}/>, value: AuctionDetailMenuItems.TERMS_AND_CONDITION.value, show: showTermsAndCondition
        },
        {
            title: AuctionDetailMenuItems.HELP_DESK.displayName, id: AuctionDetailMenuItems.HELP_DESK.id,
            icon: <SupportAgentIcon />, value: AuctionDetailMenuItems.HELP_DESK.value, show: true
        },
        {
            title: AuctionDetailMenuItems.REPORTS.displayName, id: AuctionDetailMenuItems.REPORTS.id,
            icon: <AssessmentIcon />, value: AuctionDetailMenuItems.REPORTS.value, show: showReport
        },
        // { title: 'Forum', icon: <Forum />, value: AuctionDetailMenuItems.FORUM .value},
    ]

    const toggleMenuCollapse = () => {
        setMenuCollapse(!menuCollapse)
    }

    return (
        // <Slide direction="right" in={true} timeout={500} unmountOnExit>
        <SideNavBar ismenucollapse={+menuCollapse}>
            <SideNavHeader>
                <Tooltip title={t('Back_to_Auctions')} arrow>
                    <Button id='auction-info-back' aria-label="back-to-auction" color="primary" size="large" sx={{ textTransform: 'none', width: '100%' }}
                        onClick={handleBackNavigation}>
                        <IoMdArrowRoundBack style={{ fontSize: 'larger' }} />&nbsp;
                        {!menuCollapse && <Typography>{'Back'}</Typography>}
                    </Button>
                </Tooltip>
                <Tooltip title={menuCollapse ? t('Expand') : t('Collapse')} placement='right' arrow>
                    <MenuSvgIcon component={KeyboardArrowRightRounded} onClick={toggleMenuCollapse} menucollapse={+menuCollapse} id='menucollapse' />
                </Tooltip>
            </SideNavHeader>
            <Divider />
            <MenuBar gap='0.4rem'>
                {AuctionDetailMenuItemsRender.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {item.show &&
                                <Tooltip title={item.toolTiptitle ? item.toolTiptitle : item.title} disableHoverListener={!menuCollapse} placement='right' key={index} arrow>
                                    <MenuListItem button style={menuCollapse ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}
                                        key={index} onClick={(e) => { listChangeHandler(e, item.value) }} id={item.id}
                                        isselected={selectedList === item.value ? +true : +false} className={selectedList === item.value ? classes.menuItem : ''}>
                                        <Stack direction={menuCollapse ? 'column' : 'row'} justifyContent='center' alignItems='center'>
                                            <MenuListIcon className='icon'>
                                                {item.icon}
                                            </MenuListIcon>
                                            {!menuCollapse && <MenuListText className='text'>
                                                <Typography className='typo'
                                                    style={menuCollapse ? { fontSize: '10px', margin: '0px' } : { margin: '0px 0px 0px 15px' }}>
                                                    {item.title}</Typography>
                                            </MenuListText>}
                                        </Stack>
                                    </MenuListItem>
                                </Tooltip>}
                        </Fragment>

                    )
                })}
            </MenuBar>
        </SideNavBar >
        // </Slide>
    )
}

export default SideNav;