import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    Divider,
    Typography
}                               from '@mui/material';
import { makeStyles }           from '@mui/styles';
import { useTranslation }       from 'react-i18next'; 
import { useForm }              from 'react-hook-form';
import { useMutation }          from '@apollo/client';
import { UPDATE_AUCTION, UPDATE_LOT }       from '../../GraphQL/Mutation';
import { MannerOfSale }         from '../../common/StaticVariableDeclaration';
import AuctionLoader            from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors            from '../../common/GraphQLErrors';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import { AuctionCreateSchema } from "../../schema/AuctionCreateSchema";
import { AuctionCreate } from "../../auction-create/AuctionCreate";

const useStyles = makeStyles((theme) => ({
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

export const AuctionUpdate = ({onClose, show, onSuccess}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const auctionDetailContext = useContext(AuctionDetailContext);
    const [dateValidationError, setDateValidationError] = useState([]);
    const [fieldFocusOnError, setFieldFocusOnError] = useState(false);
    const [auctionUpdateSuccess, setAuctionUpdateSuccess] = useState(false);
    const [lotUpdateSuccess, setLotUpdateSuccess] = useState(false);
    const { 
        register: auctionRegister,
        control: auctionControl,
        getValues: auctionGetValues, 
        setValue: auctionSetValue,
        trigger: auctionTrigger,
        reset: auctionReset,
        watch: auctionWatch,
        formState: { errors: auctionErrors, isValid: auctionIsValid },
        setFocus: auctionSetFocus
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: auctionDetailContext.auctionGetDetail.title,
            description: auctionDetailContext.auctionGetDetail.description,
            referenceNumber: auctionDetailContext.auctionGetDetail.referenceNumber,
            auctionVariant: auctionDetailContext.auctionGetDetail.auctionVariant,
            auctionType: auctionDetailContext.auctionGetDetail.auctionType,
            auctionMethod: auctionDetailContext.auctionGetDetail.auctionMethod,
            mannerOfSale: auctionDetailContext.auctionGetDetail.mannerOfSale,
            biddingAccess: auctionDetailContext.auctionGetDetail.biddingAccess,
            baseCurrency: auctionDetailContext.auctionGetDetail.baseCurrency,
            startTime: auctionDetailContext.auctionGetDetail.startTime,
            endTime: auctionDetailContext.auctionGetDetail.endTime,
            startResultShareTime: auctionDetailContext.auctionGetDetail.startResultShareTime,
            endResultShareTime: auctionDetailContext.auctionGetDetail.endResultShareTime,
            lastDateToFinalizeBidder: auctionDetailContext.auctionGetDetail.lastDateToFinalizeBidder
        }
    });

    const {
        register: lotRegister,
        getValues: lotGetValues,
        control: lotControl,
        trigger: lotTrigger,
        formState: { errors: lotErrors, isValid: lotIsValid },
        watch: lotWatch,
        setValue: lotSetValue,
        unregister: lotUnRegister,
        setFocus: lotSetFocus
      } = useForm({
        mode: 'onChange',
        defaultValues: {
            itemType: auctionDetailContext.auctionGetDetail.auctionLots[0].itemType ? auctionDetailContext.auctionGetDetail.auctionLots[0].itemType : [],
            autoExtension: auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtension,
            extendTimeWhenBidReceivedInLastMinute: auctionDetailContext.auctionGetDetail.auctionLots[0].extendTimeWhenBidReceivedInLastMinute,
            extendTimeBy: auctionDetailContext.auctionGetDetail.auctionLots[0].extendTimeBy,
            lastDateForEmd: auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForEmd,
            bidStartPrice: auctionDetailContext.auctionGetDetail.auctionLots[0].bidStartPrice,
            incrementalAmountDuringExtension:auctionDetailContext.auctionGetDetail.auctionLots[0].incrementalAmountDuringExtension,
            noOfExtension: auctionDetailContext.auctionGetDetail.auctionLots[0].noOfExtension,
            reservePrice: auctionDetailContext.auctionGetDetail.auctionLots[0].reservePrice,
            emdAmount: auctionDetailContext.auctionGetDetail.auctionLots[0].emdAmount,
            incrementalAmount:auctionDetailContext.auctionGetDetail.auctionLots[0].incrementalAmount,
            autoExtensionMode:auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtensionMode,
            lotNumber: auctionDetailContext.auctionGetDetail.auctionLots[0].lotNumber,
            description: auctionDetailContext.auctionGetDetail.auctionLots[0].description,
            lastDateForKyc: auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForKyc,
        }
      });

    const [updateAuction, {loading: updateAuctionLoading, error: errorOnUpdateAuction}] = useMutation(UPDATE_AUCTION, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateAuction != null) {
                setAuctionUpdateSuccess(true);
            }
        }
    })

    const [updateLot, {loading: updateLotLoading, error: errorOnLotAuction}] = useMutation(UPDATE_LOT, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateAuctionLot != null) {
                setLotUpdateSuccess(true);
                updateAuction({
                    variables: {
                        auctionInput: auctionGetValues(),
                        auctionId: auctionDetailContext.auctionGetDetail.id 
                    }
                })
            }
        },
    })

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const handleUpdate = () => {
        auctionTrigger();
        lotTrigger();
        if(auctionIsValid && lotIsValid && dateValidationError.length == 0 &&
            !Object.entries(auctionErrors).length > 0 && !Object.entries(lotErrors).length > 0) {
            handleLotUpdate();
        } else {
            if(Object.entries(auctionErrors).length > 0 || Object.entries(lotErrors).length > 0) {
              setFieldFocusOnError(true);
            } else if(dateValidationError.length > 0) {
              handleDateValidationErrors();
            }
        }
    }

    const setFocusOnSubmit = (errorCategory, setFocus) => {
        setFieldFocusOnError(false);
        Object.entries(errorCategory).every(element => {
          setFocus(element[0]);
        })
      }
    
    useEffect( () => {
        if(fieldFocusOnError) {
          setFocusOnSubmit(auctionErrors, auctionSetFocus);
        }
      }, [auctionErrors[AuctionCreateSchema.auctionTitle], auctionErrors[AuctionCreateSchema.referenceNumber],
        auctionErrors[AuctionCreateSchema.auctionBrief], auctionErrors[AuctionCreateSchema.auctionStartDate],
        auctionErrors[AuctionCreateSchema.auctionEndDate], auctionErrors[AuctionCreateSchema.lastDateToFinalizeBidder]]
    )
    
    useEffect( () => {
        if(fieldFocusOnError && auctionIsValid) {
          setFocusOnSubmit(lotErrors, lotSetFocus);
        }
      }, [lotErrors[AuctionCreateSchema.lotNumber], lotErrors[AuctionCreateSchema.lotDescription],
      lotErrors[AuctionCreateSchema.reservePrice], lotErrors[AuctionCreateSchema.emdAmount],
      lotErrors[AuctionCreateSchema.bidStartPrice], lotErrors[AuctionCreateSchema.incrementalAmount],
      lotErrors[AuctionCreateSchema.incrementalAmountDuringExtension], lotErrors[AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute],
      lotErrors[AuctionCreateSchema.extendTimeBy], lotErrors[AuctionCreateSchema.noOfExtension],
      lotErrors[AuctionCreateSchema.lastDateForEmd], lotErrors[AuctionCreateSchema.itemType],
      lotErrors[AuctionCreateSchema.lastDateForKyc]]
    )

    const handleDateValidationErrors = () => {
        if(dateValidationError[0] == AuctionCreateSchema.lastDateForEmd || dateValidationError[0] == AuctionCreateSchema.lastDateForKyc) {
          lotSetFocus(dateValidationError[0]);
        } else {
          auctionSetFocus(dateValidationError[0]);
        }
    }
    
    useEffect( () => {
        if(auctionUpdateSuccess == true && lotUpdateSuccess == true) {
            setOpen(false);
            onSuccess({show: true, message:t('Auction_Details_Updated_Successfully'), severity: 'success'});
        }
    }, [auctionUpdateSuccess, lotUpdateSuccess])

    const handleLotUpdate = () => {
        updateLot({
            variables: {
                auctionLotInput: {
                    id: auctionDetailContext.auctionGetDetail.auctionLots[0].id,
                    lotNumber: auctionGetValues(AuctionCreateSchema.mannerOfSale) == MannerOfSale.singleLot ? lotGetValues(AuctionCreateSchema.lotNumber) : auctionDetailContext.auctionGetDetail.auctionLots[0].lotNumber,
                    description: auctionGetValues(AuctionCreateSchema.mannerOfSale) == MannerOfSale.singleLot ? lotGetValues(AuctionCreateSchema.lotDescription) : auctionDetailContext.auctionGetDetail.auctionLots[0].description,
                    autoExtension: lotGetValues('autoExtension'),
                    autoExtensionMode: lotGetValues('autoExtensionMode'),
                    extendTimeWhenBidReceivedInLastMinute: Number(lotGetValues('extendTimeWhenBidReceivedInLastMinute')),
                    extendTimeBy: Number(lotGetValues('extendTimeBy')),
                    reservePrice: lotGetValues('reservePrice'),
                    emdAmount: lotGetValues('emdAmount'),
                    lastDateForEmd: lotGetValues('lastDateForEmd'),
                    bidStartPrice: lotGetValues('bidStartPrice'),
                    incrementalAmount: lotGetValues('incrementalAmount'),
                    incrementalAmountDuringExtension: lotGetValues('incrementalAmountDuringExtension'),
                    noOfExtension: Number(lotGetValues('noOfExtension')),
                    itemType: lotGetValues('itemType'),
                    lastDateForKyc: lotGetValues('lastDateForKyc'),
                },
                auctionId: auctionDetailContext.auctionGetDetail.id,
                auctionLotId: auctionDetailContext.auctionGetDetail.auctionLots[0].id
            }
        })
    }

    // useEffect( () => {
    //    auctionTrigger();
    // }, [])

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='lg'>
                <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                    <Typography variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                        {t('Auction_Details')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <AuctionCreate
                            auctionRegister={auctionRegister}
                            auctionControl={auctionControl}
                            auctionErrors={auctionErrors}
                            auctionGetValues={auctionGetValues}
                            auctionWatch={auctionWatch}
                            lotRegister={lotRegister}
                            lotControl={lotControl}
                            lotErrors={lotErrors}
                            lotGetValues={lotGetValues}
                            lotWatch={lotWatch}
                            auctionSetValue={auctionSetValue}
                            lotSetValue={lotSetValue}
                            disable={false}
                            auctionTrigger={auctionTrigger}
                            lotTrigger={lotTrigger}
                            auctionIsValid={auctionIsValid}
                            lotIsValid={lotIsValid}
                            lotUnRegister={lotUnRegister}
                            setDateValidation={setDateValidationError}
                            create={false}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Divider />
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='onAuctionUpdateSubmit' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={handleUpdate}>
                                {t('Update')}
                            </Button>
                            <Button id='onAuctionUpdateCancel' size="small" variant="outlined" className={classes.nextBtn} onClick={handleClose}>
                                {t('Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                {(updateAuctionLoading || updateLotLoading)  && <AuctionLoader show={updateAuctionLoading || updateLotLoading} invisible={false} />}
                {(errorOnUpdateAuction || errorOnLotAuction) && <GraphQLErrors error={errorOnUpdateAuction || errorOnLotAuction} show={false} />}
            </Dialog>
        </Container>
    )
}