import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/AddOutlined';
import {
    IconButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import GraphQLErrors from '../../common/GraphQLErrors';
import { LIST_PHONENUMBER } from '../../GraphQL/Queries';
import ListPhoneNumberModal from './ListPhoneNumberModal';
import PhoneNumberRow from './PhoneNumberRow';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    showMoreClass: {
        position: 'relative',
        top: '7px',
        float: 'right',
        fontSize: '14px !important',
        cursor: 'pointer'
    },

}))

const ListPhoneNumber = ({ personId, isEdit, phoneNumbers }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [openShowMoreModal, setOpenShowMoreModal] = React.useState(false);
    const [showPlusButton, setShowPlusButtom] = useState(true);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [listPhoneNumber, { loading: listingPhoneNumber, error: errorOnListingPhoneNumber, refetch }] =
        useLazyQuery(LIST_PHONENUMBER, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (data.listPhoneNumber != null) {
                    setPhoneNumberList([]);
                    data.listPhoneNumber.map(phone => {
                        setPhoneNumberList(prevState => ([
                            ...prevState,
                            {
                                id: phone.id,
                                phoneNumber: phone.phoneNumber,
                                preferred: phone.preferred,
                                lastVerifiedOn: phone.lastVerifiedOn,
                                deletedOn: phone.deletedOn,
                                isNew: false
                            }
                        ]))
                    })
                }
            }
        })

    const addPhoneNumber = () => {
        setShowPlusButtom(false);
        setPhoneNumberList(
            prevState => ([
                {
                    id: null,
                    phoneNumber: '',
                    preferred: false,
                    lastVerifiedOn: null,
                    deletedOn: null,
                    isNew: true
                },
                ...prevState,
            ])
        )
    }

    const setCancel = (isCancel) => {
        setShowPlusButtom(true);
        listPhoneNumber({
            variables: {
                personId: personId,
            }
        })
    }

    const onSuccess = (msg) => {
        if (personId != null) {
            listPhoneNumber({
                variables: {
                    personId: personId,
                }
            })
        }
        setShowPlusButtom(true);
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    // useEffect(() => {
    //     if(personId != null) {
    //         listPhoneNumber({
    //             variables: {
    //                 personId: personId,
    //             }
    //         })
    //     }
    // },[personId])

    useEffect(() => {
        if (phoneNumbers) {
            phoneNumbers.map(phone => {
                setPhoneNumberList(prevState => ([
                    ...prevState,
                    {
                        id: phone.id,
                        phoneNumber: phone.phoneNumber,
                        preferred: phone.preferred,
                        lastVerifiedOn: phone.lastVerifiedOn,
                        deletedOn: phone.deletedOn,
                        isNew: false
                    }
                ]))
            })
        }
    }, [])

    const handleClickOpenShowMoreDialog = () => {
        setOpenShowMoreModal(true);
    };

    const handleCloseShowMoreModal = () => {
        setOpenShowMoreModal(false);
    };

    return (
        <div>
            {isEdit && <Tooltip title={t("Add")}>
                <IconButton id="add-phone-number" aria-label="add" size="small" color="primary" style={{ float: 'right' }} disabled={!showPlusButton}>
                    <AddIcon onClick={addPhoneNumber} />
                </IconButton>
            </Tooltip>}
            <TableContainer component={Paper} style={{ marginTop: !isEdit ? "35px" : "0px" }}>
                <Table aria-label="phone number table" size="small">
                    <TableHead style={{ backgroundColor: '#dae2ef' }}>
                        <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '60% !important' }}>{t('Phone_Number')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '10% !important' }}>{t('Prefered')}</TableCell>
                            {isEdit && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{ width: '30% !important' }}>{t('Actions')}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody id="phone-number-list">
                        {phoneNumberList.length === 0 &&
                            <TableRow hover>
                                <TableCell className={`${classes.tableCell}`} colSpan={3}>{t('No_Record_Found')}</TableCell>
                            </TableRow>
                        }
                        {(phoneNumberList.length > 0 && phoneNumberList[0].isNew === false) && phoneNumberList.map((phone, index) => {
                            return phone.preferred && <PhoneNumberRow index={index} key={phone.id + index} phone={phone} setCancel={setCancel}
                                personId={personId} refetch={refetch} onSuccess={onSuccess} isView={!isEdit} />
                        })}
                        {(phoneNumberList.length > 0 && phoneNumberList[0].isNew === true) && phoneNumberList.map((phone, index) => {
                            return (
                                <React.Fragment>
                                    {phone.isNew && <PhoneNumberRow index={index} phone={phone} setCancel={setCancel}
                                        personId={personId} refetch={refetch} onSuccess={onSuccess} isView={!isEdit} />}
                                    {phone.preferred && <PhoneNumberRow index={index} key={phone.id + index} phone={phone} setCancel={setCancel}
                                        personId={personId} refetch={refetch} onSuccess={onSuccess} isView={!isEdit} />}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {(phoneNumberList.length > 1 && phoneNumberList[0].isNew == false) && <Typography color='primary' id="show-more-phonenumber" className={classes.showMoreClass} onClick={handleClickOpenShowMoreDialog}>{t('Show_More')}</Typography>}
            {openShowMoreModal && <ListPhoneNumberModal openPopup={openShowMoreModal} setClose={handleCloseShowMoreModal}
                personId={personId} isView={!isEdit} phoneNumbers={phoneNumberList}></ListPhoneNumberModal>}
            {listingPhoneNumber && <AuctionLoader show={listingPhoneNumber} invisible={false} />}
            {errorOnListingPhoneNumber && <GraphQLErrors error={errorOnListingPhoneNumber} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar>}
        </div>
    )
}

export default ListPhoneNumber;