import React, { 
    useState,
    useEffect 
}                                   from 'react';
import {
    Typography,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    Dialog,
    DialogTitle
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next'; 
import { useForm }                  from 'react-hook-form';
import EMDInput                     from './EMDInput';
import { CREATE_EMD }               from '../../../GraphQL/Mutation';
import { 
    LIST_EMD,
    LIST_LOTS,
    LIST_ITEMS
}                                   from '../../../GraphQL/Queries';
import { 
    useMutation,
    useLazyQuery 
}                                   from '@apollo/client';
import AuctionLoader                from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../../common/GraphQLErrors';
import { makeStyles }               from '@mui/styles';
import { MannerOfSale } from '../../../common/StaticVariableDeclaration';

const useStyles = makeStyles((theme) => ({
    button: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const AddEMDDetail = ({onClose, show, auction_id, bidder_id, seller_id, user_id, onSuccess, auctionCreationDate,
        mannerOfSale, allowUpdateReceivedEMDField, bidder_login_id}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(show ? show : false);
    const [lotId, setLotId] = useState(null);
    const [itemId, setItemId] = useState(null);

    const { register, control, getValues, setValue, trigger, reset,
            formState: { errors, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            fromBankId: null,
            toBankId: null,
            amount: 0,
            transactionDate: null,
            auctionId: '',
            userId: '',
            transactionRefNo: '',
            modeOfPayment: '',
            received: 0
        }
    });

    const [createEMD, {loading: creatingEMD, error: errorOnCreatingEMD}] = useMutation(CREATE_EMD, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addEmdDetail != null) {
                if (onSuccess) {
                    onSuccess({show: true, message: t('EMD_Added_Successfully'), severity: 'success'})
                }
                handleClose();
            }
        },
        refetchQueries: [LIST_EMD]
    })

    const [listLots, {loading: fetchingLots, error: errorOnFetchingLots}] = useLazyQuery(LIST_LOTS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.listAuctionLot != null && data.listAuctionLot.auctionLot.length > 0) {
                setLotId(data.listAuctionLot.auctionLot[0].id);
                listItems({
                    variables: {
                        auctionId: auction_id,
                        lotId: data.listAuctionLot.auctionLot[0].id,
                        pagination: {
                            page: 1,
                            limit: 100,
                            asc: false
                        },
                        isActive: true
                    }
                })
            }
        }
    })

    const [listItems, {loading: fetchingItems, error: errorOnFetchingItems, refetch}] = useLazyQuery(LIST_ITEMS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.listItem != null && data.listItem.item.length > 0) {
                setItemId(data.listItem.item[0].id);
            }
        }
    })

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const create = () => {
        createEMD({
            variables: {
                emdDetailInput: {
                    fromBankId: getValues('fromBankId'),
                    toBankId: getValues('toBankId'),
                    amount: getValues('amount'),
                    transactionDate: getValues('transactionDate'),
                    userId: user_id,
                    transactionRefNo: getValues('transactionRefNo'),
                    modeOfPayment: getValues('modeOfPayment'),
                    lotItemId: (mannerOfSale === MannerOfSale.item && itemId != null) ? itemId : lotId,
                    received: getValues('received')
                },
                auctionId: auction_id
            }
        })
    }

    useEffect(() => {
        listLots({
            variables: {
                auctionId: auction_id,
                pagination: {
                    page: 1,
                    limit: 100,
                    asc: false
                }
            }
        })
    }, [])
    

    return (
        <Dialog open={open} fullWidth maxWidth='xs'>
            <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                <Typography id="add-emd-amount-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                    {t('EMD_Details')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <EMDInput register={register} control={control} getValues={getValues} trigger={trigger}
                            errors={errors} auction_id={auction_id} bidder_id={bidder_id} seller_id={seller_id}
                            user_id={user_id} isValid={isValid} auctionCreationDate={auctionCreationDate}
                            allowUpdateReceivedEMDField={allowUpdateReceivedEMDField}
                            bidder_login_id={bidder_login_id}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button id='save-emd-amount' type="submit" size="small" variant="contained"
                                onClick={create} 
                                disabled={!isValid} 
                                className={classes.button}>
                            {t('Save')}
                        </Button>
                        <Button id='close-emd-amount' size="small" variant="outlined" className={classes.button}
                                onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            {creatingEMD && <AuctionLoader show={creatingEMD} invisible={false} />}
            {errorOnCreatingEMD && <GraphQLErrors error={errorOnCreatingEMD} show={false} />}
        </Dialog>
    )
}

export default AddEMDDetail;