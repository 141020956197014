import React, {
    useState,
    Fragment,
    useEffect
}                                           from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Grid,
    ButtonGroup,
    MenuItem,
    Menu,
    IconButton,
    Tooltip
}                                           from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import AuctionSnackBar                      from '../../common/AuctionSnackBar';
import AuctionLoader                        from '../../common/auction-loader/AuctionLoader';
import GenerateReport                       from '../generate-report/GenerateReport';
import ArrowDropDownIcon                    from '@mui/icons-material/ArrowDropDown';
import { 
    AxiosAuctionInterceptor,
    AuctionAxiosDownloadInterceptor
}                                           from '../../config/AxiosAuctionInterceptor';
import DownloadIcon                         from '@mui/icons-material/Download';
import DeleteIcon                           from '@mui/icons-material/Delete';
import AuctionConfirmDialog                 from '../../common/AuctionConfirmDialog';
import axios                                from 'axios';
import './ReportCard.css';

const ReportCard = ({name, type, description, auctionId, reportName, reportCd}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [showGenerateReport, setShowGenerateReport] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [report, setReport] = useState([]);

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const getReportVersions = () => {
        setLoading(true);
        AxiosAuctionInterceptor.get(`report/list-versions/${auctionId}/${reportCd}`, {}).then(response => {
            let prevVersion = 0;
            setReport(response.map(version => {
                prevVersion++;
                return {
                    deletedOn: version.deletedOn,
                    fileVersionId: version.fileVersionId,
                    numberOfPages: version.numberOfPages,
                    password: version.password,
                    reportId: version.reportId,
                    reportVersionId: version.reportVersionId,
                    rowCreatedOn: version.rowCreatedOn,
                    location: version.location,
                    versionNumber: (response.length + 1) - prevVersion
                }
            }))
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            console.log('error', error);
        })
    }

    const download = (event, version) => {
        handleClose(event);
        setLoading(true);
        AxiosAuctionInterceptor.post(`download-report/${auctionId}/${version.fileVersionId}/true?key=${version.location}`, {}).then(res => {
            axios({
                url: res,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${reportName}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); 
                setLoading(false);
            }).catch((error) => {
                setMessage({ showMsg: true, message: error, severity: 'error' })
                setLoading(false);
            })
            // const url = window.URL.createObjectURL(new Blob([res]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', `${reportName}.pdf`);
            // document.body.appendChild(link);
            // link.click();
            // link.remove();
            // window.URL.revokeObjectURL(url);
            // setLoading(false); 
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
        })
    }

    const deleteConfiration = (event, version, index) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete') + " " + t('Version') + " " + version.versionNumber + "?",
            onAgree: () => deleteVersion(event, version),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const deleteVersion = (event, version) => {
        setLoading(true);
        handleClose(event);
        setMessage({show: false, message: '', severity: 'error'});
        AxiosAuctionInterceptor.delete(`delete-version/${auctionId}/${version.reportVersionId}`).then(response => {
            setMessage({show: true, message: t('Report_Version_Deleted_Successfully'), severity: 'success'});
            getReportVersions();
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
        })
        resetSeekConfirmation();
    }

    const onSuccessfullGeneraton = (message) => {
        setMessage({show: false, message: '', severity: 'error'});
        if (message) {
            setTimeout(() => {
                setMessage(message);
            }, 100);
        }
        getReportVersions();
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClick = (event, version) => {
        download(event, version);
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
    };

    useEffect(() => {
        getReportVersions();
    }, [])
    
    return (
        <Fragment>
            <Card className="report-container">
                <CardContent className="reportCardContent">
                    <Grid container direction="row" justifyContent="center" alignItems="center"
                            style={{height: '100%', overflow: 'visible'}}>
                        <Grid item xs={12} style={{height: '20%'}}>
                            <Typography className='reportTitle'>{t(name)}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{height: '50%'}}>
                            <Typography className='reportDescription'>
                                {t(description)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{height: '30%'}}>
                            <Button id={`generate-${type}`} variant='contained' onClick={() => setShowGenerateReport(true)}
                                    style={{margin: '6px'}} size='small'>{report.length > 0 ? t('Re_Generate') : t('Generate')}</Button> 
                            <br/>
                            {report.length > 0 && <ButtonGroup variant="outlined" ref={anchorRef} aria-label="versions button" size='small'
                                    style={{margin: '6px 6px 0px 6px'}}>
                                <Tooltip title={t('Download_Latest_Version')} arrow>
                                    <Button id="download-button" onClick={(event) => {handleClick(event, report[0])}}>{t('Download')}</Button>
                                </Tooltip>
                                <Tooltip title={t('Versions')} arrow>
                                    <Button
                                        size="small"
                                        aria-controls={open ? 'version-button-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-label="select version"
                                        aria-haspopup="menu"
                                        onClick={handleToggle}
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>}
                            <Menu
                                id="version-button-menu"
                                anchorEl={anchorRef.current}
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="version-button-menu"
                                placement="bottom-end"
                            >
                                {report.map((version, index) => (
                                    <MenuItem key={version.versionNumber}>
                                        {`${t('Version')} ${version.versionNumber}`} 
                                        <Tooltip title={t('Download')} arrow>
                                            <IconButton variant="plain" color='primary' onClick={(event) => download(event, version)}
                                                    style={{margin: '4px'}}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('Delete')} arrow>
                                            <IconButton variant="plain" color='secondary' onClick={(event) => deleteConfiration(event, version, index)}
                                                    style={{margin: '4px'}}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                    <div className="corner">
                        <div className="arrow">
                            {/* → */}
                        </div>
                    </div>
                </CardContent>
            </Card>
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {loading && <AuctionLoader show={loading} invisible={false} />}
            {showGenerateReport && <GenerateReport show={showGenerateReport} 
                    onClose={setShowGenerateReport} auction_id={auctionId} type={type} onSuccess={onSuccessfullGeneraton}
                    reportName={reportName} reportCd={reportCd}/>}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </Fragment>
    )
}

export default ReportCard;