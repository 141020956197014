import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import { makeStyles } from "@mui/styles";
import { valueToPercent } from '@mui/base';

const useStyles = makeStyles((theme) => ({
    editorClass: {
        "& .ql-editor ": {
            height: '52vh',
            overflow: 'auto'
        }
    }
}))

const QuillEditor = (props) => {

    const classes = useStyles();
    const { termsConditionValue, handleTCValueChange, isreadOnlyMode } =
        { ...props, termsConditionValue: props.termsConditionValue || '' }

    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
            ['font']
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: "ordered" }, { list: "bullet" }],
            // ['link'],
            // ['clean'],
            ['code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
        ],
        clipboard: {
            matchVisual: false
        }
    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'code-block',
        'indent',
        'color',
        'background',
        'align'
        // 'link',
        // 'script'
    ]

    const handleChange = (content, editor) => {
        if (editor.getLength() > 1) {
            handleTCValueChange(content);
        } else {
            handleTCValueChange('');
        }
    }

    // useEffect(() => {
    //     if (props.getMethodValue.termsAndCondition) {
    //         setTermsAndConditions(props.getMethodValue.termsAndCondition);
    //     }
    // }, [props.getMethodValue.termsAndCondition])


    return (
        <div style={{ padding: '15px' }}>
            <ReactQuill id="terms-condition-editor" readOnly={isreadOnlyMode} className={classes.editorClass} placeholder="Add Terms and Conditions here..."
                modules={modules} formats={formats} theme="snow" value={termsConditionValue}
                onChange={(content, delta, source, editor) => {
                    handleChange(content, editor)
                }}
            />
        </div>
    )
}

export default QuillEditor