import React, { useState, useEffect }               from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Pagination
}                           from '@mui/material'
import { useTranslation }                   from 'react-i18next';

const AuctionPagination = ({rowsText, rowsPerPageText, rowsPerPage, totalItems, page, onRowsPerPageChange, onPageChange }) => {
    const { t } = useTranslation();
    const [pageCount, setPageCount] = useState(0);
    const pages = [ 5, 10, 25, 100 ];

    const handleRowsPerPageChange = (event) => {
        onRowsPerPageChange(event);
    }

    const handlePageChange = (event, data) => {
        onPageChange(event, data);
    }

    useEffect(() => {
        setPageCount(Math.ceil(totalItems / rowsPerPage));
    }, [totalItems, rowsPerPage])

    return (
        <Grid container>
            <Grid item xs={3}>
                <span>
                    <Typography variant="overline" display="block" gutterBottom
                        style={{display: 'inherit'}}>
                            {t('Total')}  
                            <span id="total-item"> {totalItems} </span>
                            {rowsText ? rowsText : t('Rows')}
                        </Typography>
                </span>
            </Grid>
            <Grid item xs={9}>
                <span style={{float: "right", display: 'flex'}}>
                    <Typography id="rows-per-page-abel" variant="overline" display="block" gutterBottom>{rowsPerPageText ? rowsPerPageText : t('Rows_Per_Page')} &nbsp;:&nbsp;</Typography>
                    <TextField id="rows-per-page" select size="small" variant="standard" value={rowsPerPage ? rowsPerPage : 5}
                            onChange={handleRowsPerPageChange}>
                        {pages.map((page, index) => (
                            <MenuItem key={index} value={page} id={`rows-per-page-${page}`}>
                                {page}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Pagination count={pageCount} color="primary" variant='outlined' showFirstButton showLastButton
                            page={page} onChange={handlePageChange} style={{float: 'right', padding: '0px 0px 0px 24px'}}
                            siblingCount={1} boundaryCount={2}/>
                </span>
            </Grid>
        </Grid>
    )
}

export default AuctionPagination;