import React                                                    from 'react';
import { TextField, FormControl, MenuItem, Checkbox, ListItemText }      from '@mui/material';
import { Controller }                                           from "react-hook-form";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    customChip: {
        border: `2px solid ${theme.step.contentBorderColor}`,
        marginRight: '5px',
        padding: '4px 8px',
        borderRadius: '14px',
        background: theme.palette.background.paper,
        fontSize: '14px'
    }
}));

export const MultiSelectTextControl = (props) => {

    const classes = useStyles();
    const {errors, control, name, rules, label, mandatory, options, optionsObject } = props;

    const getRenderValue = (list) => {
        return list.map(data => <span className={classes.customChip}>{optionsObject[data].uiCode}</span>);
    }
    return ( <FormControl variant="standard" fullWidth required={mandatory}>
            <Controller
                errors={errors}
                control={control}
                name={name}
                rules={rules}
                render = {({ field }) => {
                    return <TextField
                    {...field}
                    required
                    fullWidth
                    id={name}
                    select
                    label={label}
                    inputRef={field.ref}
                    SelectProps={{ 
                        autoWidth: true,
                        multiple: true,
                        renderValue: (selected) => getRenderValue(selected)
                    }}
                    variant="standard"
                    helperText={errors[name] && errors[name].message}
                    >
                    {options.map((item, index) => (
                        <MenuItem key={index} value={item.dateBaseCode}>
                            <Checkbox checked={field.value.includes(item.dateBaseCode)} />
                            <ListItemText primary={item.uiCode} />
                        </MenuItem>
                    ))}
                </TextField>
                }}
            />
        </FormControl>
    );
}