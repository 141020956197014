import { format } from 'date-fns';
import { useEffect, useState } from 'react';

const useCountDown = (date) => {
    const [countDown, setCountDown] = useState(null);
    
    useEffect(() => {
        const countDownDate = sessionStorage.getItem('eAuctionServerTimeType') == 'true' ? (new Date(date).getTime() - Number(sessionStorage.getItem('eAuctionDiffInServerTime'))) :
            (new Date(date).getTime() + Number(sessionStorage.getItem('eAuctionDiffInServerTime')));
        setCountDown(countDownDate - new Date().getTime());
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);
        return () => clearInterval(interval);
    }, [ date, sessionStorage.getItem('eAuctionServerTimeType'), sessionStorage.getItem('eAuctionDiffInServerTime') ]);
    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    const onlyMinutes = Math.floor((countDown / 1000) / 60);

    return [days, hours, minutes, seconds, onlyMinutes];
};

export { useCountDown };