import { gql } from '@apollo/client';

const CREATE_ADDRESS = gql`
    mutation CreateAddress($address: AddressInput, $sourceId: Int) {
        addAddress(address: $address, sourceId: $sourceId) {
            addressLine1
        }
    }
`

const CREATE_LOGIN_USER_ADDRESS = gql`
mutation CreateAddressForLoginUser($address: AddressInput) {
    addAddressForLoginUser(address: $address){
        addressLine1
    }
}
`

const UPDATE_ADDRESS = gql`
    mutation UpdateAddress($address: AddressInput!, $sourceId: Int!) {
        updateAddress(address: $address, sourceId: $sourceId) {
            addressLine1
        }
    }
`

const UPDATE_LOGIN_USER_ADDRESS = gql`
    mutation UpdateAddressForLoginUser($address: AddressInput!) {
        updateAddressForLoginUser(address: $address) {
            addressLine1
        }
    }
`

const DELETE_ADDRESS = gql`
    mutation DeleteAddress($addressId: String!) {
        deleteAddress(addressId: $addressId)
    }
`

const THEME = gql`
mutation GetThemeAndColor($banner_color: String, $theme:String){
    themeAndColor(data: {banner_color: $banner_color, theme:$theme}) {
        theme
    }
}`

const UPDATE_COGNITO_USER = gql`
    mutation updateCognito($data: CognitoUserInput, $user_id: String) {
        updateCognitoUser(data: $data, user_id: $user_id)
    }
`

const UPDATE_USER_DETAILS = gql`
    mutation UpdateUserDetails($personInput: PersonInput) {
        updateUserDetails(personInput: $personInput) {
            name,
            email {
                id
                email
                preferred
            }
            phoneNumber {
                id
                phoneNumber
                preferred
            }
        }
    }
`

const CREATE_CLIENT = gql`
    mutation CreateClient($clientInput : ClientInput) {
        addClient(clientInput: $clientInput) {
            id
            name
        }
    }
`

const ADD_IDENTIFICATION = gql`
    mutation AddIdentification($personId: Int!, $identificationInput: IdentificationInput!) {
        addIdentification(personId: $personId, identificationInput: $identificationInput) {
            id
            identificationType
            identification
            otherIdentificationType
            startTime
            endTime
        }
    }
`

const UPDATE_IDENTIFICATION = gql`
    mutation UpdateIdentification($personId: Int!, $identificationInput: IdentificationInput!) {
        updateIdentification(personId: $personId, identificationInput: $identificationInput) {
            id
            identificationType
            identification
            otherIdentificationType
            startTime
            endTime
        }
    }
`

const UPDATE_CLIENT_DETAILS = gql`
    mutation UpdateClient($clientInput : ClientInput) {
        updateClient(clientInput: $clientInput) {
            name
            personId
        }
    }
`

const DELETE_CLIENT = gql`
    mutation DeleteClient($clientId : Int!) {
        deleteClient(clientId: $clientId)
    }
`

const UPDATE_GST_TREATMENT = gql`
    mutation MyMutation($gst_treatment: String, $user_id: ID) {
        updateGstTreatment(gst_treatment: $gst_treatment, user_id: $user_id)
    }
`

const CREATE_AUCTION = gql`
    mutation CreateAuction($auctionInput: AuctionInput) {
        createAuction(auctionInput: $auctionInput) {
            id
            auctionId
            referenceNumber
            title
            auctionType
            auctionMethod,
            auctionVariant
            biddingAccess
            baseCurrency
            startTime
            endTime
            description
            registrationStartTime
            registrationEndTime
            mannerOfSale
            startResultShareTime
            endResultShareTime
            auctionLots {
                id
                reservePrice
                emdAmount
                lastDateForEmd
                bidStartPrice
                incrementalAmount
                autoExtension
                autoExtensionMode
                extendTimeWhenBidReceivedInLastMinute
                extendTimeBy
                incrementalAmountDuringExtension
                winningPrice
                noOfExtension
                itemType
                lastDateForKyc
            }
            auctioneers {
                personId
            }
            lastDateToFinalizeBidder
        }
    }
`
const ASSIGN_ROLE = gql`
    mutation AssignRoleForUsers($userId: Int!, $auctionId: Int, $role: String!, $pagination: Pagination) {
        assignRole(userId: $userId, auctionId: $auctionId, role: $role, pagination: $pagination) {
            count
            userRole {
                auctionId
                userId
                roleDetails {
                    role
                    id
                    startTime
                    endTime
                    revokedBy
                    revoker
                    granter
                    grantedBy
                    remarks
                }
            }
        }
    }
`
const REVOKE_ROLE = gql`
    mutation revokeRoleforUser($auctionId: Int, $roleId: String!, $remarks: String!, $pagination: Pagination) {
        revokeRole(
            auctionId: $auctionId,
            roleId: $roleId,
            remarks: $remarks,
            pagination: $pagination
            ) {
            count
            userRole {
                auctionId
                userId
                roleDetails {
                    role
                    id
                    granter
                    revoker
                    revokedBy
                    startTime
                    endTime
                    grantedBy
                    remarks
                }
            }

        }
    }
`


const CREATE_REQUIRED_DOCUMENT = gql`
    mutation CreateRequiredDocument($auctionId: Int, $documentInput: DocumentInput, $pagination: Pagination) {
        createRequiredDocumentDetails(auctionId: $auctionId, documentInput: $documentInput, pagination: $pagination) {
            documents {
                id
                name
                submitFormat
                requiresDigitalSignature
                isMandatory
                s3Uri
                templateS3Uri
                startTime
                endTime
                addedBy
                removedBy
                updatedBy
                referenceNumber
                requiredDocumentBrief
                submittedDocumentBrief
                toSubmit
            }
            count
        }
    }
`

const UPDATE_REQUIRED_DOCUMENT = gql`
    mutation UpdateRequiredDocument($auctionId: Int, $documentInput: DocumentInput) {
        updateRequiredDocument(auctionId: $auctionId, documentInput: $documentInput)
    }
`

const DELETE_REQUIRED_DOCUMENT = gql`
    mutation DeleteRequiredDocument($auctionId: Int, $documentId: Int) {
        deleteRequiredDocument(auctionId: $auctionId, documentId: $documentId)
    }
`

const UPDATETHEME = gql`
    mutation UpdateThemeColor($color: ColorInput){
        updateThemeColor(color: $color)
    }
`

const CREATE_BANK_DETAILS = gql`
    mutation CreateBankDetailForAuction($auctionId: Int, $bankDetailInput: BankDetailInput) {
        createBankDetailForAuction(auctionId: $auctionId,, bankDetailInput: $bankDetailInput) {
            id
            bankAccountNumber
            accountType
            ifsc
            micr
            bankName
            branchName
            bankHolderName
            userId
            edgeId
            otherAccountType
        }
    }
`

const RECORD_DOCUMENT_UPLOAD_FOR_REQUIRED_DOCUMENT = gql`
    mutation CreateSubmittedDocumentDetails($auctionId: Int, $documentId: Int, $presignedUrl: String, $pagination: Pagination) {
        createSubmittedDocumentDetails(auctionId: $auctionId, documentId: $documentId, presignedUrl: $presignedUrl, pagination: $pagination) {
            documents {
                id
                name
                isMandatory
                submitFormat
                requiresDigitalSignature
                startTime
                endTime
            }
            count
        }
    }
`
const SUBMIT_BID = gql`
mutation createBid($auctionId: Int, $bidInput: BidInput) {
   createBid(auctionId: $auctionId, bidInput: $bidInput){
    amount
    id
   }
 }
`

const UPDATE_BANK_DETAILS = gql`
    mutation UpdateBankDetailForAuction($auctionId: Int, $bankDetailInput: BankDetailInput) {
        updateBankDetailForAuction(auctionId: $auctionId, bankDetailInput: $bankDetailInput) {
            id
            bankAccountNumber
            accountType
            ifsc
            micr
            bankName
            branchName
            bankHolderName
            userId
            edgeId
            otherAccountType
        }
    }
`

const CREATE_ITEM = gql`
    mutation CreateItem($auctionId: Int, $lotId: Int, $itemInput: [ItemInput], $isBulkUpload: Boolean) {
        addItem(auctionId: $auctionId, lotId: $lotId, itemInput: $itemInput, isBulkUpload: $isBulkUpload) {
            id
            itemName
            itemRefNo
            description
            itemType
            unit
            quantity
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                phone
                attention
                email
            }
        }
    }
`

const DELETE_BANK_DETAILS = gql`
    mutation DeleteBankDetailForAuction($auctionId: Int, $edgeId: String) {
        deleteBankDetailForAuction(auctionId: $auctionId, edgeId: $edgeId)
    }
`
const DELETE_PROFILE_BANK_DETAILS = gql`
    mutation DeleteProfileBankDetailForAuction($edgeId: String) {
        deleteBankDetailForLoginUser(edgeId: $edgeId)
    }
`

const UPDATE_ITEM = gql`
    mutation UpdateItem($auctionId: Int, $itemInput: ItemInput) {
        updateItem(auctionId: $auctionId, itemInput: $itemInput) {
            id
            itemName
            itemRefNo
            description
            itemType
            unit
            quantity
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                phone
                attention
                email
            }
        }
    }
`

const DELETE_ITEM = gql`
    mutation DeleteItem($itemId: Int, $auctionId: Int) {
        deleteItem(itemId: $itemId, auctionId: $auctionId)
    }
`
const UPDATE_AUCTION_STATUS = gql`
    mutation UpdateAuctionStatus($auctionId: Int, $status: String) {
        updateAuctionStatus(auctionId: $auctionId, status: $status)
    }
`

const CREATE_BIDDER = gql`
    mutation AddBidder($auctionId: Int, $bidderInput: BidderInput) {
        addBidder(auctionId: $auctionId, bidderInput: $bidderInput) {
            id
            personId
            loginId
        }
    }
`

const DELETE_SELLER = gql`
    mutation DeleteSeller($auctionId: Int!, $userId: Int!) {
        deleteSeller(auctionId: $auctionId, userId: $userId)
    }
`

const CREATE_SELLER = gql`
    mutation AddSeller($auctionId: Int!, $sellerInput: SellerInput) {
        addSeller(auctionId: $auctionId, sellerInput: $sellerInput) {
            personId
            individual
            name
            restOfName
            dobDoi
            identification {
                id
	            identificationType
                otherIdentificationType
	            identification
            }
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                startTime
                endTime
                phone
                attention
                email
            }
            email {
                id
                email
                preferred
            }
            phoneNumber {
                id
                phoneNumber
                preferred
            }
        }
    }
`

const CREATE_BIDDER_AUTH_REP = gql`
    mutation AddAuthorizedRepresentative($auctionId: Int, $bidderId: String, $authorizedRepresentative: PersonInput) {
        addAuthorizedRepresentative(auctionId: $auctionId, bidderId: $bidderId, authorizedRepresentative: $authorizedRepresentative) {
            personId
            roleEdgeId
        }
    }
`

const UPDATE_SELLER_DETAILS = gql`
    mutation UpdateSeller($auctionId: Int!, $sellerInput: SellerInput) {
        updateSeller(auctionId: $auctionId, sellerInput: $sellerInput) {
            personId
            individual
            name
            restOfName
            dobDoi
        }
    }
`

const UPDATE_BIDDER_STATUS = gql`
    mutation UpdateBidderStatus($auctionId: Int, $bidderId: String, $status: String, $remarks: String) {
        modifyStatus(auctionId: $auctionId, bidderId: $bidderId, status: $status, remarks: $remarks)
    }
`

const UPDATE_PHONE_NUMBER = gql`
    mutation UpdatePhoneNumber($phoneNumberInput: PhoneNumberInput) {
        updatePhoneNumber(phoneNumberInput: $phoneNumberInput) {
            id
            phoneNumber
            preferred
            startTime
            endTime
        }
    }
`

const CREATE_BIDDER_UPLOAD_DOCUMENTS = gql`
    mutation CreateSubmittedDocumentDetails($auctionId: Int, $documentId: Int, $presignedUrl: String,
        $referenceNumber: String, $brief: String, $bidderId: Int, $pagination: Pagination) {
        createSubmittedDocumentDetails(auctionId: $auctionId, documentId: $documentId, presignedUrl: $presignedUrl,
            referenceNumber: $referenceNumber, brief: $brief, bidderId: $bidderId, pagination: $pagination) {
            documents {
                id
                name
                submitFormat
                requiresDigitalSignature
                isMandatory
                s3Uri
                templateS3Uri
                startTime
                endTime
                addedBy
                removedBy
                updatedBy
                referenceNumber
                requiredDocumentBrief
                submittedDocumentBrief
                toSubmit
            }
            count
        }
    }
`

const UPDATE_AUCTION = gql`
    mutation UpdateAuction($auctionId: Int, $auctionInput: AuctionInput) {
        updateAuction(auctionId: $auctionId, auctionInput: $auctionInput) {
            id
            auctionId
            referenceNumber
            title
            auctionType
            auctionMethod
            auctionVariant
            biddingAccess
            baseCurrency
            startTime
            endTime
            description
            registrationStartTime
            registrationEndTime
            mannerOfSale
            startResultShareTime
            endResultShareTime
        }
    }
`

const CREATE_EMD = gql`
    mutation CreateEMD($emdDetailInput: EmdDetailInput, $auctionId: Int) {
        addEmdDetail(emdDetailInput: $emdDetailInput, auctionId: $auctionId) {
            id
            fromBankId
            toBankId
            amount
            transactionDate
            userId
            transactionRefNo
            modeOfPayment
            lotItemId
            received
            transferredEdgeId
            toBankDetail {
                id
                bankAccountNumber
                accountType
                ifsc
                bankName
                branchName
                bankHolderName
                otherAccountType
            }
            fromBankDetail {
                id
                bankAccountNumber
                accountType
                ifsc
                bankName
                branchName
                bankHolderName
                otherAccountType
            }
        }
    }
`

const UPDATE_BIDDER_UPLOAD_DOCUMENTS = gql`
    mutation UpdateSubmittedDocument($auctionId: Int, $documentId: Int, $presignedUrl: String,
        $referenceNumber: String, $brief: String, $bidderId: Int) {
            updateSubmittedDocument(auctionId: $auctionId, documentId: $documentId,
            presignedUrl: $presignedUrl, referenceNumber: $referenceNumber, brief: $brief, bidderId: $bidderId)
    }
`

const ADD_FAVOURITE_AUCTION = gql`
    mutation AddFavouriteAuction($auctionId: Int) {
        addFavouriteAuction(auctionId: $auctionId)
    }
`

const REMOVE_FAVOURITE_AUCTION = gql`
    mutation RemoveFavouriteAuction($auctionId: Int) {
        removeFavouriteAuction(auctionId: $auctionId)
    }
`
const CREATE_TERMS_CONDITIONS = gql`
    mutation CreateTermsCondition($auctionId: Int, $termsConditionInput: TermsConditionInput) {
        createTermsCondition(auctionId: $auctionId, termsConditionInput: $termsConditionInput) {
            id
	        termsAndCondition
        }
    }
`

const UPDATE_TERMS_CONDITIONS = gql`
    mutation UpdateTermsCondition($auctionId: Int, $termsConditionInput: TermsConditionInput) {
        updateTermsCondition(auctionId: $auctionId, termsConditionInput: $termsConditionInput) {
            id
	        termsAndCondition
        }
    }
`

const DELETE_TERMS_CONDITION = gql`
    mutation DeleteTermsCondition($auctionId: Int, $termsConditionId: Int) {
        deleteTermsCondition(auctionId: $auctionId, termsConditionId: $termsConditionId)
    }
`

const DELETE_IDENTIFICATION = gql`
    mutation DeleteIdentification($personId: Int!, $id: Int!) {
        deleteIdentification(personId: $personId, id: $id)
    }
`

const ADD_PHONENUMBER = gql`
    mutation AddPhoneNumber($personId: Int!, $phoneNumberInput: PhoneNumberInput!) {
        addPhoneNumber(personId: $personId, phoneNumberInput: $phoneNumberInput) {
            id
            phoneNumber
            preferred
            lastVerifiedOn
            deletedOn
        }
    }
`

const CREATE_LOT = gql`
    mutation CreateLot($auctionId: Int, $auctionLotInput: AuctionLotInput) {
        createAuctionLot(auctionId: $auctionId, auctionLotInput: $auctionLotInput) {
            auctionLot {
                id
            }
            count
        }
    }
`

const UPDATE_LOT = gql`
    mutation UpdateLot($auctionId: Int, $auctionLotId: Int, $auctionLotInput: AuctionLotInput) {
        updateAuctionLot(auctionId: $auctionId, auctionLotId: $auctionLotId, auctionLotInput: $auctionLotInput) {
            id
            auctionId
            lotNumber
            description
            startTime
            endTime
            autoExtension
            autoExtensionMode
            extendTimeWhenBidReceivedInLastMinute
            extendTimeBy
            reservePrice
            emdAmount
            lastDateForEmd
            bidStartPrice
            incrementalAmount
            incrementalAmountDuringExtension
            winningPrice
            viewCount
            address {
                addressLine1
            }
            itemType
            lastDateForKyc
        }
    }
`

const UPDATE_PHONENUMBER = gql`
    mutation UpdatePhoneNumber($personId: Int!, $phoneNumberInput: PhoneNumberInput!) {
        updatePhoneNumber(personId: $personId, phoneNumberInput: $phoneNumberInput) {
            id
            phoneNumber
            preferred
            lastVerifiedOn
            deletedOn
        }
    }
`

const DELETE_LOT = gql`
    mutation DeleteLot($auctionId: Int, $auctionLotId: Int) {
        deleteAuctionLot(auctionId: $auctionId, auctionLotId: $auctionLotId) {
            auctionLot {
                id
            }
            count
        }
    }
`

const DELETE_PHONENUMBER = gql`
    mutation DeletePhoneNumber($personId: Int!, $id: Int!) {
        deletePhoneNumber(personId: $personId, id: $id)
    }
`
const ADD_EMAIL = gql`
    mutation AddEmail($personId: Int!, $emailInput: EmailInput!) {
        addEmail(personId: $personId, emailInput: $emailInput) {
            id
            email
            preferred
            startTime
            endTime
        }
    }
`
const UPDATE_EMAIL = gql`
    mutation UpdateEmail($personId: Int!, $emailInput: EmailInput!) {
        updateEmail(personId: $personId, emailInput: $emailInput) {
            id
            email
            preferred
            startTime
            endTime
        }
    }
`

const DELETE_EMD = gql`
    mutation DeleteEMD($auctionId: Int, $emdDetailId: String) {
        deleteEmdDetail(auctionId: $auctionId, emdDetailId: $emdDetailId)
    }
`

const UPDATE_EMD = gql`
    mutation UpdateEMD($emdDetailInput: EmdDetailInput, $auctionId: Int) {
        updateEmdDetail(emdDetailInput: $emdDetailInput, auctionId: $auctionId) {
            id
            fromBankId
            toBankId
            amount
            transactionDate
            userId
            transactionRefNo
            modeOfPayment
            lotItemId
            received
            transferredEdgeId
            toBankDetail {
                id
                bankAccountNumber
                accountType
                ifsc
                bankName
                branchName
                bankHolderName
                otherAccountType
            }
            fromBankDetail {
                id
                bankAccountNumber
                accountType
                ifsc
                bankName
                branchName
                bankHolderName
                otherAccountType
            }
        }
    }
`

const DELETE_EMAIL = gql`
    mutation DeleteEmail($personId: Int!, $id:  Int!) {
        deleteEmail(personId: $personId, id: $id)
    }
`

const ACCEPT_TERMS_AND_CONDITION = gql`
    mutation acceptTermsCondition($auctionId: Int, $termsConditionId: Int) {
        acceptTermsCondition(auctionId: $auctionId, termsConditionId: $termsConditionId)
    }
`

const UPDATE_BIDDER_DETAIL = gql`
    mutation UpdateBidder($auctionId: Int!, $bidderId: String!, $bidderInput: BidderInput) {
        updateBidder(auctionId: $auctionId, bidderId: $bidderId, bidderInput: $bidderInput) {
            id
            personId
            individual
            name
        }
    }
`

const UPDATE_BIDDER_AUTH_REP = gql`
    mutation UpdateBidderAuthRep($auctionId: Int!, $bidderId: String!, $authRepRoleId: String, $authorizedRepresentative: PersonInput) {
        updateAuthorizedRepresentative(auctionId: $auctionId, bidderId: $bidderId, authRepRoleId: $authRepRoleId, authorizedRepresentative: $authorizedRepresentative) {
            personId
            roleId
            individual
            name
        }
    }
`

const DELETE_BIDDER_AUTH_REP = gql`
    mutation DeleteBidderAuthRep($auctionId: Int, $bidderId: String, $roleId: String!) {
        deleteAuthorizedRepresentative(auctionId: $auctionId, bidderId: $bidderId, roleId: $roleId)
    }
`

const CREATE_LOGINUSER_BANK_DETAILS = gql`
    mutation CreateBankDetailForLoginUser($bankDetailInput: BankDetailInput) {
        createBankDetailForLoginUser(bankDetailInput: $bankDetailInput) {
            id
            bankAccountNumber
            accountType
            ifsc
            micr
            bankName
            branchName
            bankHolderName
            userId
            edgeId
            otherAccountType
        }
    }
`

const UPDATE_BANK_DETAILS_FOR_LOGINUSER = gql`
    mutation UpdateBankDetailForLoginUser($bankDetailInput: BankDetailInput) {
        updateBankDetailForLoginUser(bankDetailInput: $bankDetailInput) {
            id
            bankAccountNumber
            accountType
            ifsc
            micr
            bankName
            branchName
            bankHolderName
            userId
            edgeId
            otherAccountType
        }
    }
`

const SEND_CREDETIALS_TO_BIDDER = gql`
    mutation SendCredentialsToBidder($auctionId: Int!) {
        createAccountsForAllBidder(auctionId: $auctionId) {
            user
            createdAccount
        }
    }
`

const SEND_CREDETIALS_TO_BIDDER_AUTH_REPS = gql`
    mutation SendCredentialsToBidderAuthReps($auctionId: Int!, $bidderId: String!) {
        createBidderAccount(auctionId: $auctionId, bidderId: $bidderId) {
            user
            createdAccount
        }
    }
`

const DELETE_FILE = gql`
    mutation DeleteFile($fileName: String) {
        deleteFile(fileName: $fileName)
    }
`

export {
    CREATE_ADDRESS, CREATE_LOGIN_USER_ADDRESS, UPDATE_ADDRESS, UPDATE_LOGIN_USER_ADDRESS, DELETE_ADDRESS, THEME,
    UPDATE_COGNITO_USER, UPDATE_USER_DETAILS, CREATE_CLIENT, UPDATE_IDENTIFICATION, UPDATE_CLIENT_DETAILS, DELETE_CLIENT,
    UPDATE_GST_TREATMENT, CREATE_AUCTION,
    CREATE_REQUIRED_DOCUMENT, DELETE_REQUIRED_DOCUMENT, UPDATE_REQUIRED_DOCUMENT, UPDATETHEME, CREATE_BANK_DETAILS,
    DELETE_BANK_DETAILS, UPDATE_BANK_DETAILS, RECORD_DOCUMENT_UPLOAD_FOR_REQUIRED_DOCUMENT, CREATE_ITEM, UPDATE_ITEM,
    DELETE_ITEM, ASSIGN_ROLE, REVOKE_ROLE, SUBMIT_BID, DELETE_SELLER, CREATE_SELLER, UPDATE_SELLER_DETAILS, UPDATE_EMAIL,
    UPDATE_PHONE_NUMBER, UPDATE_AUCTION_STATUS, CREATE_BIDDER, CREATE_BIDDER_AUTH_REP, UPDATE_BIDDER_STATUS,
    UPDATE_AUCTION, CREATE_BIDDER_UPLOAD_DOCUMENTS, ADD_FAVOURITE_AUCTION, REMOVE_FAVOURITE_AUCTION,
    UPDATE_LOT, DELETE_LOT, CREATE_LOT, UPDATE_BIDDER_UPLOAD_DOCUMENTS, CREATE_EMD, DELETE_EMD, UPDATE_EMD,
    ADD_IDENTIFICATION, DELETE_IDENTIFICATION, ADD_PHONENUMBER, UPDATE_PHONENUMBER, DELETE_PHONENUMBER,
    ADD_EMAIL, DELETE_EMAIL, CREATE_TERMS_CONDITIONS, UPDATE_TERMS_CONDITIONS, DELETE_TERMS_CONDITION, ACCEPT_TERMS_AND_CONDITION, UPDATE_BIDDER_DETAIL,
    DELETE_BIDDER_AUTH_REP, CREATE_LOGINUSER_BANK_DETAILS, UPDATE_BANK_DETAILS_FOR_LOGINUSER,
    DELETE_PROFILE_BANK_DETAILS, UPDATE_BIDDER_AUTH_REP, SEND_CREDETIALS_TO_BIDDER, SEND_CREDETIALS_TO_BIDDER_AUTH_REPS, DELETE_FILE
}