import { useTranslation } from "react-i18next"

//width and height
export const collapseWidth = '5vw'
export const sideNavWidth = '13.5vw'
export const subHeaderHeight = '8.5vh'
export const topBarHeight = '48px'

// Side Bar menu list where each menu value should be unique
export const AuctionDetailMenuItems = {
    AUCTION_DETAILS: { id: 'auctiondetail', value: 'auctiondetail', displayName: 'Auction Details' },
    ITEMS: { id: 'items', value: 'items', displayName: 'Items/Lot' },
    BIDDER: { id: 'bidder', value: 'bidder', displayName: 'Bidder' },
    // PAYMENT: { id: 'payment', value: 'payment', displayName: 'Payment'},
    MANAGE_ROLE: { id: 'managerole', value: 'managerole', displayName: 'Manage Role' },
    BIDDING_HISTORY: { id: 'bidhistory', value: 'bidhistory', displayName: 'Bid History' },
    AUDIT_TRAIL: { id: 'audittrial', value: 'audittrial', displayName: 'Audit Trail' },
    // FORUM: { id: 'forum', value: 'forum', displayName: 'Forum'},
    DOCUMENTS: { id: 'documents', value: 'documents', displayName: 'Documents' },
    SELLER: { id: 'seller', value: 'seller', displayName: 'Seller' },
    BIDDING_HALL: { id: 'auctionBidHall', value: 'auctionBidHall', displayName: 'Auction Hall' },
    TERMS_AND_CONDITION: { id: 'termsconditions', value: 'termsconditions', displayName: 'Terms & Conditions' },
    COMMUNICATIONS: { id: 'communications', value: 'communications', displayName: 'Communications'},
    BANK_ACCOUNT: { id: 'bankaccount', value: 'bankaccount', displayName: 'Bank Account' },
    HELP_DESK: { id: 'helpdesk', value: 'helpdesk', displayName: 'Help Desk' },
    REPORTS: { id: 'report', value: 'report', displayName: 'Reports' },
    AUCTION_HALL: { id: 'auctionhall', value: 'auctionhall', displayName: 'Auction Hall' },
    AUCTION_DETAIL_IMAGE: {id: 'auctiondetailimage', value: 'auctiondetailimage', displayName: 'Auction Details' }
}
