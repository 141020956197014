import { GavelRounded } from '@mui/icons-material';
import { BottomNavigation, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionSnackBar from '../common/AuctionSnackBar';
import AuctionPagination from '../common/pagination/AuctionPagination';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import { CurrencyFormatter } from '../utils/Utils';
import { AuctionDetailContext } from '../auction/auction-detail/AuctionDetail';
import { AuctionStatus, Roles } from '../common/StaticVariableDeclaration';
import { UserContext } from '../common/context/UserContext';

const moment = require("moment-timezone");


const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: theme.tableDataBorderColor,
        color: theme.tableDataColor,
    },
    tableHead: {
        backgroundColor: theme.tableHearderBackgroundColor,
        fontWeight: "bold !important"
    }

}))

const ActiveBidderList = (props) => {
    const { auctionId, bidOnId, h1Bidder, setH1Bidder, activeBidderCount, setActiveBidderCount,
        setExtensionNumber, activeBidders, setActiveBidders, noOfRequests } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(1);
    // const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const auctionDetailContext = useContext(AuctionDetailContext);
    const userContext = useContext(UserContext).rootContext;
    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

    const ListActiveBidder = () => {
        // setLoading(true);
        setErrorMessage('');
        AxiosAuctionInterceptor.get(`${process.env.REACT_APP_AWS_ENDPOINT}bid/list/${auctionId}/${bidOnId}/${noOfRequests === 1 ? false : true}?page=${page}&limit=${rowsPerPage}&asc=false`).then(function (response) {
            if (response) {
                if (page === 1 && response.bids.length > 0) {
                    setH1Bidder({
                        amount: response.bids[0].amount,
                        timeOfBid: response.bids[0].startTime,
                    })
                }
                setActiveBidders({
                    bidders: response.bids,
                    count: response.count
                });
                setExtensionNumber(response.extensionNumber)
                setActiveBidderCount(response.count)
                // setLoading(false);
            }
        }).catch(error => {
            if (error.message) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage(t('Try_Again'));
            }
            // setLoading(false)
        });
    }

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const onRowsPerPageChange = (event) => {
        setPage(1);
        setRowsPerPage(event.target.value);
    }

    useEffect(() => {
        ListActiveBidder()
    }, [page, rowsPerPage])

    const columns = [
        {
            id: 'rank',
            label: t('Rank'),
            minWidth: 80,
            className: classes.tableHead,
            renderCell: (value) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center' ml={1.5}>
                        <Typography>{value}</Typography>
                    </Stack>
                </>)
            }
        },
        {
            id: 'bidderId',
            label: (auctionDetailContext.auctionGetDetail.status == AuctionStatus.closed) ? t('Bidder_Name') : t('Bidder_ID'),
            minWidth: 200,
            className: classes.tableHead,
            renderCell: (value, row) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center'>
                        { (auctionDetailContext.auctionGetDetail.status == AuctionStatus.closed && row.name) ? <Typography>{row.name}</Typography> :
                            ((auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && !isAdmin &&
                                !(isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support))) ? '--' : value
                            )
                        }
                    </Stack>
                </>)
            }
        },
        {
            id: 'ipAddress',
            label: t('Bidder_IP_Address'),
            minWidth: 100,
            className: classes.tableHead,
            renderCell: (value) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center'>
                        { value !== null && ( auctionDetailContext.auctionGetDetail.status == AuctionStatus.closed ||
                                ( auctionDetailContext.auctionGetDetail.status == AuctionStatus.open && (isAdmin ||
                                    (isCustomerSupport && auctionDetailContext.auctionGetDetail.roles.includes(Roles.support)))
                                ) 
                            ) ? <Typography>{value}</Typography> : '--'
                        }
                    </Stack>
                </>)
            }
        },
        {
            id: 'startTime',
            label: t('Last_Bid_Date_Time') + ' (' + dateTimeZoneValue + ')',
            minWidth: 100,
            className: classes.tableHead,
            renderCell: (value) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center'>
                        <Typography>{format(new Date(value), 'dd MMM yyyy hh:mm:ss:SSS a')} ({dateTimeZoneValue})</Typography>
                    </Stack>
                </>)
            }
        },
        {
            id: 'amount',
            label: t('Bid_Amount'),
            minWidth: 150,
            align: 'right',
            className: classes.tableHead,
            renderCell: (value) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center' justifyContent='end'>
                        <Typography>{CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, value)}</Typography>
                    </Stack>
                </>)
            }
        },
    ]

    return (
        <div>
            <TableContainer style={{}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            {columns.map(({ id, align, minWidth, label, className }) => (
                                <TableCell
                                    key={id}
                                    id={id}
                                    align={align}
                                    style={{ minWidth: minWidth }}
                                    className={`${className != undefined ? className : ''}`}
                                >
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {activeBidders.bidders &&
                        <Fragment>
                            <TableBody>
                                {
                                    activeBidders.bidders.map(row => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row.rank}>
                                                {columns.map((column) => {
                                                    let value;
                                                    if (column.renderCell != undefined) {
                                                        value = column.renderCell(row[column.id], row)
                                                    } else {
                                                        value = row[column.id]
                                                    }
                                                    return (
                                                        <TableCell size='medium' id={`${column.id}-${row.rank}`} className={classes.tableData} key={column.id} align={column.align}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Fragment>
                    }
                </Table>
                {(activeBidders.bidders.length === 0) && <Stack justifyContent='center' alignItems='center' margin='3rem'>
                    <GavelRounded color='textSecondary' fontSize='large' />
                    <Typography color='textSecondary'>{t('No_Records_Found')}</Typography>
                </Stack>}
            </TableContainer>
            {activeBidders.bidders.count > 5 &&
                <BottomNavigation style={{ padding: '12px' }}>
                    <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={activeBidderCount}
                        onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
                </BottomNavigation>
            }
            {errorMessage !== '' && <AuctionSnackBar error={errorMessage} show={errorMessage !== '' ? true : false} handleResetOnClose={() => setErrorMessage('')}/>}
            {/* {loading && <AuctionLoader show={loading} invisible={false} />} */}
        </div>
    )
}

export default ActiveBidderList
