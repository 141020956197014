import React, {
    useState,
    useEffect,
    useContext
}                               from 'react';
import {
    Stack,
    Tooltip,
    Typography,
    ButtonBase,
    Dialog,
    DialogContent,
    Container,
    Grid,
    Button,
    Paper,
    TablePagination,
    DialogActions,
    DialogTitle,
    Divider
}                               from '@mui/material';
import { FileIcon }             from './FileIcon';
import { format }               from 'date-fns';
import {
    ButtonStyled,
    FileAvatarDelete,
    FileAvatarDownload
}                               from './styles/FileStyle';
import UploadDialog             from '../common/files-upload/UploadDialog';
import FileTable                from './FileTable';
import AuctionSnackBar          from '../common/AuctionSnackBar';
import AuctionLoader            from '../common/auction-loader/AuctionLoader';
import {
    DeleteOutline,
    GetAppOutlined
}                               from '@mui/icons-material';
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { GET_PRE_SIGNED_URL }   from '../GraphQL/Queries';
import { 
    useLazyQuery,
    useMutation 
}                               from '@apollo/client';
import axios                    from 'axios';
import { useTranslation }       from 'react-i18next';
import { LIST_FILES }           from '../GraphQL/Queries';
import { DELETE_FILE }          from '../GraphQL/Mutation';
import AuctionPagination        from '../common/pagination/AuctionPagination';
import GraphQLErrors            from '../common/GraphQLErrors';
import AuctionConfirmDialog     from '../common/AuctionConfirmDialog';
import { PublicAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import { UserContext }          from '../common/context/UserContext';

const FileList = ({auction_id, title, isMultiple, fileName, files, show, onClose, canUpload, lot_id, item_id}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(show)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [fileDetail, setFileDetail] = useState(files ? files : []);
    // const [prevFiles, setPrevFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [scannedCount, setScannedCount] = useState(0);
    const [page, setPage] = useState(0);
    const userContext = useContext(UserContext).rootContext;
    const pageEventLable = {
        first: 'first',
        current: 'current',
        next: 'next',
        previous: 'previous'
    }
    const [pagePreviousLinks, setPagePreviousLinks] = useState([]);
    const [pageCurrentLinks, setPageCurrentLinks] = useState("''");
    const [paginationEvent, setPaginationEvent] = useState(pageEventLable.first);
    const [onLoadPage, setOnLoadPage] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const handleUpload = () => {
        setDialogOpen(true);
    }

    useEffect( () => {
        if(onLoadPage) {
            if(paginationEvent == pageEventLable.first) {
                setPageCurrentLinks("''");
                setPagePreviousLinks([]); 
            } else if(paginationEvent == pageEventLable.previous) {
                if(pagePreviousLinks.length > 0) {
                    setPageCurrentLinks(pagePreviousLinks[pagePreviousLinks.length-1]);
                    setPagePreviousLinks((prev) => [...prev.filter((data, index) => index != prev.length-1)]);
                } else {
                    setPageCurrentLinks("''");
                }
            } else if(paginationEvent == pageEventLable.next) {
                setPagePreviousLinks(prevState => [
                    ...prevState,
                    pageCurrentLinks
                ]);
                setPageCurrentLinks(fileDetail[fileDetail.length-1].file_name);
            }
            setPaginationEvent(pageEventLable.current);
            setOnLoadPage(false);
        }
    }, [paginationEvent, onLoadPage])

    const [listFiles, { loading: loadingFiles, error: errorOnLoadingFiles, refetch }] = useLazyQuery(LIST_FILES, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setOnLoadPage(true);
            setFileDetail([]);
            if (data.listFile != null) {
                data.listFile.files.map(file => {
                    setFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl
                        }
                    ]))
                    // setPrevFiles(prevState => ([
                    //     ...prevState,
                    //     {
                    //         file_name: file.s3ObjectFile.key.split('/').pop(),
                    //         lastModified: file.s3ObjectFile.lastModified,
                    //         size: file.s3ObjectFile.size,
                    //         key: file.s3ObjectFile.key,
                    //         preSignedUrl: file.presignedUrl
                    //     }
                    // ]))
                })
                setScannedCount(data.listFile.count);
            }
        }
    })

    const [deleteFile, {loading: deletingFile, error: errorOnDeletingFile}] = useMutation(DELETE_FILE, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteFile != null) {
                if (fileDetail.length > 1) {
                    setPaginationEvent(pageEventLable.current);
                    listFiles({
                        variables: {
                            auctionId: auction_id,
                            path: fileName,
                            isPresignedUrl: true,
                            limit: rowsPerPage,
                            startingToken: pageCurrentLinks
                        }
                    })   
                } else {
                    handleChangePage(null, page - 1);
                }
            }
        }
    })

    const getPublicItemImages = (rowsPerPage, startingToken) => {
        setLoading(true);
        setMessage({showMsg: false, message: '', severity: ''})
        PublicAxiosAuctionInterceptor.get(`rest/file/${auction_id}/${lot_id}/${rowsPerPage}/true/false?itemId=${item_id}&startingToken=${startingToken}`).then((response) => {
            setLoading(false);
            setOnLoadPage(true);
            setFileDetail([]);
            if (response.files != null) {
                response.files.map(file => {
                    setFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl
                        }
                    ]))
                    // setPrevFiles(prevState => ([
                    //     ...prevState,
                    //     {
                    //         file_name: file.s3ObjectFile.key.split('/').pop(),
                    //         lastModified: file.s3ObjectFile.lastModified,
                    //         size: file.s3ObjectFile.size,
                    //         key: file.s3ObjectFile.key,
                    //         preSignedUrl: file.presignedUrl
                    //     }
                    // ]))
                })
                setScannedCount(response.count);
            }
        }).catch(function (error) {
            setLoading(false);
            if (error.message) {
                setMessage({showMsg: true, message: error.message, severity: 'error' });
            } else {
                setMessage({showMsg: true, message: t('Try_Again'), severity: 'error' });
            }
        });
    }

    useEffect(() => {
        goToFirstPage();
    }, [rowsPerPage])


    const handleChangePage = (event, newPage) => {
        if (page === newPage) {
            // TODO
        } else {
            if(newPage < page){
                setPaginationEvent(pageEventLable.previous);
            } else {
                setPaginationEvent(pageEventLable.next)
            }
            let startingTokenValue = (newPage < page) ? pagePreviousLinks[pagePreviousLinks.length-1] :
                (fileDetail.length > 0 && newPage != 0 ? fileDetail[fileDetail.length - 1].file_name : "''")
            if (userContext.userSession.isAuthenticated) {
                listFiles({
                    variables: {
                        auctionId: auction_id,
                        path: fileName,
                        isPresignedUrl: true,
                        limit: rowsPerPage,
                        startingToken: startingTokenValue
                    }
                })   
            } else if (lot_id && item_id) {
                getPublicItemImages(rowsPerPage, startingTokenValue)
            }
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // setPrevFiles([]);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const onSuccess = (message) => {
        goToFirstPage()
        if (message) {
            setMessage(message);
        }
        setTimeout(() => {
            setMessage({ showMsg: false, message: '', severity: '' });
        }, 2000);
    }

    const goToFirstPage = () => {
        setPaginationEvent(pageEventLable.first);
        setPage(0);
        if (userContext.userSession.isAuthenticated) {
            listFiles({
                variables: {
                    auctionId: auction_id,
                    path: fileName,
                    isPresignedUrl: true,
                    limit: rowsPerPage,
                    startingToken: '',
                }
            })
        } else if (lot_id && item_id){
            getPublicItemImages(rowsPerPage, "''")
        }
    }

    const downloadFiles = (file) => {
        if (file.preSignedUrl) {
            download(file.preSignedUrl, file.file_name);
        } else {
            getPreSignedUrlToDownload({ variables: { fileName: file.key, bucket: process.env.REACT_APP_S3_BUCKET, httpMethod: 'GET', versionId: '' } })
        }
    }

    const [getPreSignedUrlToDownload, { loading: gettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: preSignUrlData => {
            if (preSignUrlData.getPreSignedUrl) {
                download(preSignUrlData.getPreSignedUrl);
            }
        }
    });

    const download = (preSignedUrl, fileName) => {
        setLoading(true);
        axios({
            url: preSignedUrl,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName ? fileName : 'file.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch((error) => {
            setMessage({ showMsg: true, message: error, severity: 'error' })
            setLoading(false);
        })
    }

    const deleteSelectedFile = (file) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete') + " " + file.file_name + "?",
            onAgree: () => {
                deleteFile({
                    variables: {
                        fileName: file.key
                    }
                })
                resetSeekConfirmation();
            },
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const columns = [
        {
            id: 'file_name',
            label: t('File_Name'),
            minWidth: 250,
            renderCell: (value) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center'>
                        <Stack width={{ xs: '1%' }}><FileIcon fontSize='1.4rem' fileName={value} /></Stack>
                        <Stack width={{ xs: '95%' }}><Tooltip title={value} arrow>
                            <Typography color='textPrimary' style={{ marginLeft: '5px', maxWidth: '300px' }} noWrap>{value}</Typography>
                        </Tooltip></Stack>
                    </Stack>
                </>)
            }
        },
        {
            id: 'lastModified', label: t('Date_Modified'), minWidth: 170,
            renderCell: (value) => {
                return (
                    <Stack direction='row' spacing={1}>
                        <Typography color='textSecondary'>{format(new Date(value), 'dd MMM yyyy')}</Typography>
                        <Typography color='textSecondary'>{format(new Date(value), 'h:mm a')}</Typography>
                    </Stack>
                )
            }
        },
        {
            id: 'size',
            label: t('Size_KB'),
            minWidth: 130,
            align: 'left',
            renderCell: (value) => {
                return (<Typography color='textSecondary'>{(value / 1024).toFixed(2)}</Typography>)
            }
        },
        {
            id: 'actions',
            label: t('Actions'),
            minWidth: 80,
            align: 'center',
            renderCell: (value, row) => {
                return (<Stack spacing={1} direction='row' justifyContent='center'>
                    <Tooltip title={t('Download_File')}>
                        <ButtonBase id={`download-document-${row.file_name}`} sx={{ borderRadius: '15px' }} onClick={() => downloadFiles(row)} className={`float-right`}>
                            <FileAvatarDownload variant="rounded">
                                <GetAppOutlined stroke={1.5} size="1.3rem" />
                            </FileAvatarDownload>
                        </ButtonBase>
                    </Tooltip>
                    {canUpload && <Tooltip title={t('Delete_File')}>
                        <ButtonBase id={`delete-document-${row.file_name}`} sx={{ borderRadius: '15px' }} onClick={() => deleteSelectedFile(row)} className={`float-right`}>
                            <FileAvatarDelete variant="rounded">
                                <DeleteOutline stroke={1.5} size="1.3rem" />
                            </FileAvatarDelete>
                        </ButtonBase>
                    </Tooltip>}
                </Stack>)
            }
        },
    ];

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose(false);
        }
    }

    return (
        <Dialog open={open} fullWidth maxWidth='lg' disableEscapeKeyDown>
            <DialogTitle>
                {title ? title : t('Files')}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Container maxWidth='lg'>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {canUpload && <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button id="upload-documents" color='primary' variant='contained' onClick={handleUpload} style={{ marginBottom: '10px' }}>
                                    <AiOutlineCloudUpload size='1.5rem' /> &nbsp;{t('Upload')}
                                </Button>
                            </div>}
                            <div>
                                {dialogOpen && <UploadDialog
                                    dialogOpen={dialogOpen}
                                    setDialogOpen={setDialogOpen}
                                    isMultiple={isMultiple ? isMultiple : false}
                                    fileName={`${auction_id}/${fileName}`}
                                    onSuccessMultiple={onSuccess}
                                    onSuccess={() => goToFirstPage()}
                                />}
                                <Paper>
                                    <FileTable columns={columns} rows={fileDetail}
                                        loading={loading} rowsPerPage={rowsPerPage} page={page} />
                                    {fileDetail.length > 0 &&
                                        <Stack padding={{ xs: 1 }}>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={scannedCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Stack>}
                                </Paper>
                                {(loading || loadingFiles || deletingFile) && <AuctionLoader show={loading || loadingFiles || deletingFile} invisible={false}/>}
                                {message.showMsg && <AuctionSnackBar show={message.showMsg} message={message.message}
                                    severity={message.severity} />}
                                {errorOnLoadingFiles && <GraphQLErrors error={errorOnLoadingFiles} show={false} />}
                                {errorOnDeletingFile && <GraphQLErrors error={errorOnDeletingFile} show={false} />}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid item xs={12}>
                    <Button id='document-close' size="small" variant="outlined" style={{float: 'right'}} onClick={handleClose}>
                        {t('Close')}
                    </Button>
                </Grid>
            </DialogActions>
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} 
                onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </Dialog>
    )
}

export default FileList;