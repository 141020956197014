import React, { Fragment, useContext, useEffect, useState }               from "react";
import { makeStyles }                                           from "@mui/styles";
import { Button,BottomNavigation, IconButton, Tooltip, Typography}          from '@mui/material';
import { Box }                                                  from "@mui/system";
import { LIST_SELLER_DETAILS }                                  from '../../GraphQL/Queries';
import { DELETE_SELLER }                                        from '../../GraphQL/Mutation';
import Table                                                    from '@mui/material/Table';
import TableBody                                                from '@mui/material/TableBody';
import TableCell                                                from '@mui/material/TableCell';
import TableContainer                                           from '@mui/material/TableContainer';
import TableHead                                                from '@mui/material/TableHead';
import TableRow                                                 from '@mui/material/TableRow';
import Paper                                                    from '@mui/material/Paper';
import AuctionSnackBar                                          from '../../common/AuctionSnackBar';
import AuctionLoader                                            from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                                            from '../../common/GraphQLErrors';
import { useLazyQuery }                                         from '@apollo/client';
import AuctionPagination                                        from '../../common/pagination/AuctionPagination';
import { useTranslation }                                       from 'react-i18next';
import { UserContext }                                          from "../../common/context/UserContext";
import AddIcon                                                  from '@mui/icons-material/Add';
import DeleteIcon                                               from '@mui/icons-material/DeleteOutlined';
import EditIcon                                                 from '@mui/icons-material/EditOutlined';
import { useMutation }                                          from '@apollo/client';
import AuctionSellerCreate                                      from '../auction-seller-create/AuctionSellerCreate';
import AuctionSellerUpdateTabs                                  from "../auction-seller-update/AuctionSellerUpdateTabs";
import AuctionConfirmDialog                                     from "../../common/AuctionConfirmDialog";
import { AuctionStatus, Roles, PartyCodeCd }                    from "../../common/StaticVariableDeclaration";
import VisibilityIcon                                           from '@mui/icons-material/Visibility';
import { PublicAxiosAuctionInterceptor }                        from "../../config/AxiosAuctionInterceptor";
import PersonIcon                                               from '@mui/icons-material/Person';
import AccountBalanceIcon                                       from '@mui/icons-material/AccountBalance';
import { BiCreditCard }                                         from 'react-icons/bi';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: theme.tableDataBorderColor,
        color: theme.tableDataColor,
    },
    tableHead: {
        backgroundColor: theme.tableHearderBackgroundColor,
        fontWeight: "bold !important"
    },
    textIconContainer: {
        display: 'flex'
    },
    textIcon: {
        fontSize: '1.2rem !important',
        paddingRight: '3px'
    },
}))

const AuctionSellerList = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const userContext = useContext(UserContext).rootContext;
    const [sellerList, setSellerList] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [userId, setUserId] = useState('');
    const [isEdit, setEdit] = useState(false);
    // const enumListOptions = userContext.enumList;
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalNumberOfSeller, setTotalNumberOfSeller] = useState(0);
    const enumListObject = userContext.enumListObject;
    const [isView, setIsView] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sellerLoginUserId, setSellerLoginUserId] = useState(null);
    const isCustomerSupport = (userContext.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [listSellerDetails, {loading: listingSellerDetails, error: errorOnListingSellerDetails, refetch}] =
            useLazyQuery(LIST_SELLER_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setSellerList([]);
            if(data.listSeller !== null) {
                data.listSeller.seller.map(seller => {
                    let personTypeDescription = '';
                    PartyCodeCd.find((type) => {
                        if(type.dateBaseCode === seller.individual) {
                            personTypeDescription = type.uiCode;
                        }
                    })
                    setSellerList(prevState => ([
                        ...prevState,
                        {
                            name: seller.name,
                            individual: personTypeDescription,
                            personId: seller.personId,
                            phoneNumber: seller.phoneNumber !== null && seller.phoneNumber.length > 0 ? seller.phoneNumber[0]: [],
                            identification: seller.identification !== null && seller.identification.length > 0 ? [seller.identification[0]] : '' ,
                            email: seller.email !== null && seller.email.length > 0 ? seller.email[0] : [],
                            dobDoi: seller.dobDoi,
                            bankDetail: seller.bankDetail,
                            loginId: seller.loginId
                        }
                    ]))
                })
                setTotalNumberOfSeller(data.listSeller.count);
            }
        }
    })

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handlesellerListApi = () => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/seller/${props.auctionId}/${page}/${rowsPerPage}/false`).then((response) => {
            setSellerList([]);
            response.seller.map(seller => {
                setSellerList(prevState => ([
                    ...prevState,
                    {
                        name: seller.name,
                        individual: seller.individual,
                        personId: seller.personId,
                        phoneNumber: seller.phoneNumber !== null && seller.phoneNumber.length > 0 ? seller.phoneNumber[0]: [],
                        identification: seller.identification !== null && seller.identification.length > 0 ? [seller.identification[0]] : '' ,
                        email: seller.email !== null && seller.email.length > 0 ? seller.email[0] : [],
                        dobDoi: seller.dobDoi,
                        bankDetail: seller.bankDetail,
                        loginId: seller.loginId
                    }
                ]))
            })
            setTotalNumberOfSeller(response.count);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({show: false, message: '', severity: ''})
            setMessage({show: true, message: error, severity: 'error'})
        });
    }

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            listSellerDetails({
                variables: {
                    auctionId: props.auctionId,
                    pagination: {
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    },
                }
            })
        } else {
            handlesellerListApi();
        }
    }, [rowsPerPage, page])

    const [deleteSellerDetails, {loading: deletingSellerDetails, error: errorOnDeletingSellerDetails}] =
            useMutation(DELETE_SELLER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteSeller) {
                onSuccess({show: true, message: t('Seller_Deleted_Successfully'), severity: 'success'});
                props.handleGetAuctionDetail();
            }
        },
        refetchQueries: [LIST_SELLER_DETAILS]
    })

    const editItemOwner = (itemOwner, isView) => {
        setIsView(isView)
        setEdit(true);
        setUserId(itemOwner.personId);
        setSellerLoginUserId(itemOwner.loginId)
    }

    const deleteSellerConfirmation = (seller) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Seller'),
            onAgree: () => deleteSeller(seller),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const deleteSeller = (seller) => {
        resetSeekConfirmation();
        if (seller) {
            deleteSellerDetails({
                variables: {
                   auctionId: props.auctionId,
                   userId: seller.personId
                }
            })
        }
    }

    const addSeller = () => {
        setOpenPopup(true);

    }

    const onSuccess = (successMessage) => {
        setMessage({
            show: successMessage.show,
            message: successMessage.message,
            severity: successMessage.severity
        })
        if (successMessage.severity != 'error') {
            setTimeout(() => {
                resetMessage();
            }, 1500);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    const setCancel = (isCancel) => {
        setOpenPopup(false);
    }

    const handleBack = () => {
        setEdit(false);
        if (userContext.userSession.isAuthenticated) {
            refetch();
        } else {
            handlesellerListApi();
        }
    }

    return(
        <React.Fragment>
            {!isEdit && (!listingSellerDetails && !loading) && <Box>
                {(((props.status === AuctionStatus.setup  || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))) ||
                    (isCustomerSupport && props.roles?.includes(Roles.support)) || isAdmin) && sellerList.length < 1 &&
                <Tooltip title={t('Add_Seller')}>
                    <Button id="seller-add" variant="contained" size="small" endIcon={<AddIcon />} style={{float: 'right', bottom: '7px'}}
                        onClick={addSeller}>{t('Add')}</Button>
                </Tooltip>}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                        <TableHead className={`${classes.tableHead}`}>
                            <TableRow className={`${classes.tableRow}`}>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left" style={{width: '25%'}}>{t('Name_And_Identification')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left" style={{width: '10%'}}>{t('Seller_Type')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left" style={{width: '20%'}}>{t('Bank_Details')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left" style={{width: '10%'}}>{t('Phone_Number')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="left" style={{width: '20%'}}>{t('Email')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{width: '15%'}}>{t('Actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody id="seller-body">
                            {sellerList.length === 0 &&
                                <TableRow>
                                    <TableCell id="no-sellers-row" colSpan={5} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                </TableRow>
                            }
                            {sellerList.length > 0 && sellerList.map((seller, index) => {
                                return (<TableRow key={seller.personId} id={`seller${index}`}>
                                        <TableCell id={`seller-person-name${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">
                                            {seller.name} <Typography>
                                            {seller.identification.length > 0 && <Tooltip title={(enumListObject.IdentificationType && userContext.userSession.isAuthenticated) ? enumListObject.IdentificationType[seller.identification[0]?.identificationType].uiCode :
                                                seller.identification[0].identificationType} disableHoverListener={seller.identification?.length === 0}>
                                                    <span>{seller.identification[0].identification}</span>
                                                    </Tooltip>}
                                            {seller.identification.length === 0 && '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell id={`seller-person-type${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{seller.individual}</TableCell>
                                        <TableCell id={`seller-person-type${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">
                                            {seller.bankDetail != null ?
                                                <Fragment>
                                                    <div className={classes.textIconContainer}>
                                                        <PersonIcon className={classes.textIcon}/>{seller.bankDetail.bankHolderName}
                                                    </div>
                                                    <div className={classes.textIconContainer}>
                                                        <BiCreditCard className={classes.textIcon}/>{seller.bankDetail.bankAccountNumber}
                                                    </div>
                                                    <div className={classes.textIconContainer}>
                                                        <AccountBalanceIcon className={classes.textIcon}/>{seller.bankDetail.bankName}
                                                    </div>
                                                </Fragment>
                                                :
                                                <div>{'-'}</div>
                                            }
                                        </TableCell>

                                        {/* <TableCell id={`seller-identification${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">
                                            {seller.identification.length > 0 && <Tooltip title={(enumListObject.IdentificationType && userContext.userSession.isAuthenticated) ? enumListObject.IdentificationType[seller.identification[0]?.identificationType].uiCode :
                                                seller.identification[0].identificationType} disableHoverListener={seller.identification?.length === 0}>
                                                    <span>{seller.identification[0].identification}</span>
                                                    </Tooltip>}
                                            {seller.identification.length === 0 && '-'}
                                        </TableCell> */}
                                        <TableCell id={`seller-phone${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{seller.phoneNumber.length === 0 ? '-' : seller.phoneNumber.phoneNumber}</TableCell>
                                        <TableCell id={`seller-email${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="left">{seller.email.length === 0 ? '-' : seller.email.email}</TableCell>
                                        <TableCell id={`seller-action${index}`} className={`${classes.tableCell} ${classes.tableData}`}  align="center">
                                        {(((props.status === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))) ||
                                            (isCustomerSupport && props.roles?.includes(Roles.support)) || isAdmin) &&
                                            <IconButton id={`seller-edit${index}`} onClick={() => { editItemOwner(seller, false) }}>
                                                <Tooltip title={t('Edit')}>
                                                    <EditIcon color='primary' style={{fontSize: '1.25rem'}}/>
                                                </Tooltip>
                                            </IconButton>}
                                        {(((props.status === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))) ||
                                            (isCustomerSupport && props.roles?.includes(Roles.support)) || isAdmin) &&
                                            <IconButton id={`seller-delete${index}`} onClick={() => {deleteSellerConfirmation(seller)}}>
                                                <Tooltip title={t('Delete')}>
                                                    <DeleteIcon color='error' style={{fontSize: '1.25rem'}} />
                                                </Tooltip>
                                            </IconButton>}
                                        <IconButton id={`seller-view${index}`} onClick={() => {editItemOwner(seller, true)}}>
                                            <Tooltip title={t('View')}>
                                                <VisibilityIcon color='primary' style={{fontSize: '1.25rem'}} />
                                            </Tooltip>
                                        </IconButton>
                                        </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                    { totalNumberOfSeller > 0 &&
                        <BottomNavigation style={{padding: '12px'}}>
                            <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={totalNumberOfSeller}
                                onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange}/>
                        </BottomNavigation>
                    }
                </TableContainer>
            </Box>}
            {isEdit &&
                <AuctionSellerUpdateTabs selectedUserId={userId} auctionId={props.auctionId} handleBack={handleBack} roles={props.roles} isView={isView} sellerLoginUserId={sellerLoginUserId}></AuctionSellerUpdateTabs>
            }
            {(listingSellerDetails || deletingSellerDetails || loading) && <AuctionLoader show={(listingSellerDetails || deletingSellerDetails || loading)}
                    invisible={false} />}
            {(errorOnListingSellerDetails || errorOnDeletingSellerDetails) && <GraphQLErrors error={(errorOnListingSellerDetails || errorOnDeletingSellerDetails)} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
            {openPopup && <AuctionSellerCreate openPopup={openPopup} setCancel={setCancel} onSuccess={onSuccess} auctionId={props.auctionId} userId={userId} handleGetAuctionDetail={props.handleGetAuctionDetail}/>}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </React.Fragment>
    );
}

export default AuctionSellerList;
