import { useMutation } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Checkbox, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import GraphQLErrors from '../../common/GraphQLErrors';
import { Validation } from '../../common/Validation';
import {
    ADD_PHONENUMBER, DELETE_PHONENUMBER,
    UPDATE_PHONENUMBER
} from '../../GraphQL/Mutation';


const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    inputFiledPadding: {
        "& #phone_number": {
            padding: '0px !important'
        }
    },
    tableRow: {
        verticalAlign: 'initial !important'
    }
}))

const PhoneNumberRow = (props) => {

    const {
        phone, personId, refetch, onSuccess, setCancel, isView, index,
    } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(phone.isNew ? phone.isNew : false);
    const [ispreferred, setIsPreferred] = useState(false);

    const { register, trigger, getValues, setValue, control, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        defaultValues: {}
    })

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [addPhoneNumber, { loading: addingPhoneNumber, error: errorOnAddingPhoneNumber }] =
        useMutation(ADD_PHONENUMBER, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.addPhoneNumber != null) {
                    setIsNew(false);
                    refetch();
                    onSuccess({ show: true, message: t('Phone_Number_Added_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const [updatePhoneNumber, { loading: updatingPhoneNumber, error: errorOnUpdatingPhoneNumber }] =
        useMutation(UPDATE_PHONENUMBER, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updatePhoneNumber != null) {
                    setIsNew(false);
                    refetch();
                    onSuccess({ show: true, message: t('Phone_Number_Updated_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            }
        })

    const [deletePhoneNumber, { loading: deletingPhoneNumber, error: errorOnDeletingPhoneNumber }] =
        useMutation(DELETE_PHONENUMBER, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.deletePhoneNumber) {
                    onSuccess({ show: true, message: t('Phone_Number_Deleted_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const handleEdit = (selectedPhoneNumberDetail) => {
        if (selectedPhoneNumberDetail) {
            setIsEdit(true);
            setValue('id', selectedPhoneNumberDetail.id)
            setValue('phoneNumber', selectedPhoneNumberDetail.phoneNumber)
            setIsPreferred(selectedPhoneNumberDetail.preferred)
            trigger();
        }
    }

    const handlesave = () => {
        if (isNew) {
            addPhoneNumber({
                variables: {
                    personId: personId,
                    phoneNumberInput: {
                        id: null,
                        phoneNumber: getValues('phoneNumber'),
                        preferred: ispreferred
                    }
                }
            })
        } else {
            updatePhoneNumber({
                variables: {
                    personId: personId,
                    phoneNumberInput: {
                        id: getValues('id'),
                        phoneNumber: getValues('phoneNumber'),
                        preferred: ispreferred
                    }
                }
            })
        }
    }

    const deleteConfirmation = (selectedId) => {
        setSeekConfirmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Phone_Number'),
            onAgree: () => handleDelete(selectedId),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const handleDelete = (selectedId) => {
        resetSeekConfirmation();
        if (selectedId) {
            deletePhoneNumber({
                variables: {
                    personId: personId,
                    id: selectedId
                }
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const cancel = () => {
        setCancel(true);
    }

    const handlePreferredChange = (event) => {
        setIsPreferred(event.target.checked)
    }

    return (
        <React.Fragment>
            <TableRow id={`phonenumber${index}`} className={classes.tableRow}>
                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                    {(isEdit || isNew) && <Controller name="phoneNumber" control={control}
                        rules={{ ...Validation.mobileNumberValidation, ...Validation.required }}
                        render={({ field: { name, onChange, value } }) => (
                            <MuiPhoneNumber
                                className={classes.inputFiledPadding}
                                id="phone_number"
                                name={name}
                                defaultCountry={"in"}
                                value={value}
                                onChange={onChange}
                                helperText={errors["phoneNumber"] && errors["phoneNumber"].message}
                                autoFormat={false}
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 33,
                                        paddingLeft: '5px'
                                    }
                                }}
                                variant="outlined"
                                countryCodeEditable={false}
                            />
                        )}
                    />
                    }
                    {(!isNew && !isEdit) && <div>{phone.phoneNumber}</div>}
                </TableCell>
                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                    {(isEdit || isNew) && <Checkbox size="small" id="phone-preferred" checked={ispreferred} onClick={handlePreferredChange}></Checkbox>}
                    {(!isNew && !isEdit) && (phone.preferred ? <CheckIcon style={{ fontSize: '1.25rem' }} /> : '-')}
                </TableCell>
                {!isView && <TableCell align="center">
                    {(!isEdit && !isNew) && <Tooltip title={t("Edit")}>
                        <IconButton id={`phone-edit${index}`} aria-label="edit" size="small" color="primary" onClick={() => handleEdit(phone)} >
                            <EditIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(!isEdit && !isNew) && <Tooltip title={t("Delete")}>
                        <IconButton id={`phone-delete${index}`} aria-label="delete" size="small" color="secondary" onClick={() => deleteConfirmation(phone.id)} >
                            <DeleteIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(isEdit || isNew) && <Tooltip title={t("Save")}>
                        <IconButton id={`phone-save${index}`} aria-label="save" size="small" style={{ color: 'green' }}
                            onClick={handlesave} disabled={!isValid}>
                            <DoneIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(isEdit || isNew) && <Tooltip title={t("Cancel")}>
                        <IconButton id={`phone-cancel${index}`} aria-label="cancel" size="small" color="secondary" onClick={cancel}>
                            <ClearIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                </TableCell>}
            </TableRow>
            {(addingPhoneNumber || updatingPhoneNumber || deletingPhoneNumber) && <AuctionLoader show={(addingPhoneNumber
                || updatingPhoneNumber || deletingPhoneNumber)} invisible={false} />}
            {(errorOnAddingPhoneNumber || errorOnUpdatingPhoneNumber || errorOnDeletingPhoneNumber) &&
                <GraphQLErrors error={(errorOnAddingPhoneNumber || errorOnUpdatingPhoneNumber || errorOnDeletingPhoneNumber)} show={false} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </React.Fragment>
    )
}

export default PhoneNumberRow