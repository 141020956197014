import React, {
    useState,
    useEffect
}                                   from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Grid,
    Button
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next'; 
import { makeStyles }               from '@mui/styles';
import Address from '../../profile/address/Address';

const useStyles = makeStyles((theme) => ({
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const LotAddressModal = ({show, onClose, lotId, canEdit, title, lotAddress}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const [createNew, setCreateNew] = useState(false);

    const handleClose = () => {
        setOpen(false);
        onClose(false)
    }

    useEffect(() => {
        if (!lotAddress) {
            setCreateNew(true)
        }
    }, [])
    

    return (
        <Dialog open={open} fullWidth maxWidth='lg'>
            <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                <Typography id="lot-address-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                    {title ? title : t('Location')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Address isLoginUser={false} sourceId={lotId} hasPermission={canEdit} 
                    address={lotAddress ? [lotAddress] : null} allowMultipleAddress={false} newAddress={createNew}
                    addressType={t('Lot_Location')} atlestOneMandatory={true}/>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button size="small" id="cancel-lot-address" variant="outlined"
                                className={classes.nextBtn} onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default LotAddressModal;