import { useLazyQuery } from '@apollo/client';
import { Close, CloudUpload, DoneAllRounded } from '@mui/icons-material';
import { Collapse, Dialog, DialogActions, DialogTitle, Divider, Fab, IconButton, LinearProgress, Slide, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ImCheckmark, ImCross } from "react-icons/im";
import { AxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import { GET_PRE_SIGNED_URL, GET_PRE_SIGNED_URL_SUBMIT_DOC, GET_PRE_SIGNED_URL_TEMPLATE } from '../../GraphQL/Queries';
import AuctionSnackBar from '../AuctionSnackBar';
import GraphQLErrors from '../GraphQLErrors';
import { FileIcon } from './FileIcon';
import { collectionFileExtensionSet, executableExtensionSet, executableMimeTypeSet, restrictTypeCode } from './FileUploadStaticVar';
import { ButtonStyled, DashedCard, FileUploadCard, UploadDialogContent } from './styles/FileStyle';

// const useStyles = makeStyles(() => ({
//     dots: {
//         fontWeight: 'bold',
//         color: '#157ce',
//         display: 'inline-block',
//         fontFamily: 'monospace',
//         fontSize: '30px',
//         clipPath: 'inset(0 3ch 0 0)',
//         animation: `$dot 1s steps(4) infinite`
//     },
//     '@keyframes dot': {
//         'to': {
//             clipPath: 'inset(0 - 1ch 0 0)'
//         }
//     }
// }))

const ReferenceNumberMaxLength = 50;

const UploadDialog = (props) => {
    const {
        dialogOpen, title, setDialogOpen, isMultiple, fileName, reUploadFile, onSuccess, isReferenceNo, oldFilePath, isTemplatePresigned, documentType,
        customFileUpload, customUploadedFiles, acceptableFiles, onSuccessMultiple, bidderDocumentReset, auctionId, isBidderSubmitDoc } =
    {
        ...props, customUploadedFiles: props.customUploadedFiles || [], isReferenceNo: props.isReferenceNo || false,
        acceptableFiles: props.acceptableFiles || {}, isBidderSubmitDoc: props.isBidderSubmitDoc || false
    };
    const { t } = useTranslation();
    const [files, setFiles] = useState([])
    const [progress, setProgress] = useState(props.progress ? props.progress : false)
    const containerRef = React.useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState(customUploadedFiles)
    const [countUrl, setCountUrl] = useState(0)
    const [referenceNumber, setReferenceNumber] = useState('')
    const [message, setMessage] = useState(props.message ? props.message : {
        showMsg: false,
        message: null,
        severity: null
    });

    const handleClose = () => {
        resetUpload();
    }

    const onDrop = useCallback(acceptedFiles => {
        if (isMultiple) {
            let allFiles = [...acceptedFiles, ...files];
            let uniqueFiles = files.length === 0 ? acceptedFiles : [...new Map(allFiles.map(item => [item['path'], item])).values()]
            setFiles(uniqueFiles)
        } else {
            setFiles(acceptedFiles)
        }
    }, [files])

    function restrictExecutableType(file) {
        if (executableMimeTypeSet.includes(file.type) || executableExtensionSet.includes(file.name.split('.').pop())) {
            return {
                code: restrictTypeCode.executable
            };
        }
        if (collectionFileExtensionSet.includes(file.name.split('.').pop())) {
            return {
                code: restrictTypeCode.collection
            }
        }
        if (file.name === 'mvnw') {
            return {
                code: restrictTypeCode.executable
            }
        }
    }

    const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone(
        {
            onDrop, noClick: true, multiple: isMultiple,
            accept: acceptableFiles,
            validator: restrictExecutableType
        }
    )

    const removeSelectedFiles = (filePath) => {
        setFiles(files.filter((file) => file.path != filePath));
    }

    const [getPresignedUrlToUpload, { loading: uploading }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getPresignedUrl !== null) {
                onFileUpload(data.getPresignedUrl, files[countUrl], countUrl);
                if (countUrl < (files.length - 1)) {
                    /* setting countUrl for next file Upload */
                    setCountUrl(countUrl + 1)
                }
            }
            // setUploading(false);
        },
        onError: (error) => {
            setProgress(false);
            setMessage({ showMsg: false, message: null, severity: null })
            setMessage({ showMsg: true, message: error, severity: 'error' })
        }

    });

    const [getSubmitDocPresignedUrl, { loading: gettingPresignedUrl, error: errorOnGettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL_SUBMIT_DOC, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getPresignedUrlSubmitDocument !== null) {
                onFileUpload(data.getPresignedUrlSubmitDocument, files[countUrl], countUrl);
                if (countUrl < (files.length - 1)) {
                    /* setting countUrl for next file Upload */
                    setCountUrl(countUrl + 1)
                }
            }
            // setUploading(false);
        },
        onError: (error) => {
            setProgress(false);
            setMessage({ showMsg: false, message: null, severity: null })
            setMessage({ showMsg: true, message: error, severity: 'error' })
        }
    })

    const [getTemplateDocPresignedUrl, { loading: gettingTemplatePresignedUrl, error: errorOnTemplatePresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL_TEMPLATE, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getPresignedUrlTemplate !== null) {
                onFileUpload(data.getPresignedUrlTemplate, files[countUrl], countUrl);
                if (countUrl < (files.length - 1)) {
                    /* setting countUrl for next file Upload */
                    setCountUrl(countUrl + 1)
                }
            }
            // setUploading(false);
        },
        onError: (error) => {
            setProgress(false);
            setMessage({ showMsg: false, message: null, severity: null })
            setMessage({ showMsg: true, message: error, severity: 'error' })
        }
    })

    const onFileUpload = (url, file, index) => {
        AxiosAuctionInterceptor.put(url, file, {
            headers: {
                'Content-Type': file.type
            },
        }).then(function (response) {
            if (!isMultiple && onSuccess) {
                onSuccess(url, referenceNumber);
            }
            setUploadedFiles(prevState => [
                ...prevState,
                file.path
            ])
            if (index == (files.length - 1)) {
                // setUploading(true)
                setReferenceNumber('')
                setTimeout(() => {
                    if (isMultiple && onSuccessMultiple) {
                        let multipleSuccessMsg = { showMsg: true, message: t('Files_uploaded_successfully'), severity: 'success' }
                        onSuccessMultiple(multipleSuccessMsg);
                        setMessage(multipleSuccessMsg)
                    }
                    resetUpload();
                }, 3000)
            }
        }).catch(function (error) {
            setMessage({ showMsg: true, message: error, severity: 'error' })
            // setUploading(false)
        });
    };

    const resetUpload = () => {
        setDialogOpen(false);
        setUploadedFiles([]);
        setProgress(false);
        setFiles([]);
        setReferenceNumber('');
        setMessage({ showMsg: false, message: null, severity: null })
        if (bidderDocumentReset) {
            bidderDocumentReset();
        }
    }

    const onClickUpload = () => {
        if (typeof customFileUpload !== 'undefined' && typeof customFileUpload === 'function') {
            customFileUpload(files)
        }
        else {
            uploadFile(files[0])
        }
    }

    const uploadFile = (fileToUpload) => {
        // setUploading(true);
        if (files.length > 0) {
            if (isBidderSubmitDoc) {
                getSubmitDocPresignedUrl({
                    variables: {
                        auctionId: auctionId ? auctionId : null,
                        bidderId: null,
                        presignedUrl: {
                            fileName: process.env.REACT_APP_S3_FOLDER + '/' + fileName + '/' + fileToUpload.name,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'PUT',
                            versionId: '',
                            isVersion: (reUploadFile != undefined && reUploadFile === true) ? true : false,
                            contentType: fileToUpload.type,
                            contentLength: fileToUpload.size
                        },
                        oldFilePath: oldFilePath
                    }
                })
            } else if (isTemplatePresigned) {
                getTemplateDocPresignedUrl({
                    variables: {
                        auctionId: auctionId,
                        presignedUrl: {
                            fileName: process.env.REACT_APP_S3_FOLDER + '/' + fileName + '/' + fileToUpload.name,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'PUT',
                            versionId: '',
                            isVersion: (reUploadFile != undefined && reUploadFile === true) ? true : false,
                            contentType: fileToUpload.type,
                            contentLength: fileToUpload.size
                        },
                        documentType: documentType,
                        toSubmit: false,
                        oldFilePath: oldFilePath
                    }
                })
            } else {
                getPresignedUrlToUpload({
                    variables: {
                        presignedUrl: {
                            fileName: process.env.REACT_APP_S3_FOLDER + '/' + fileName + '/' + fileToUpload.name,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'PUT',
                            versionId: '',
                            isVersion: (reUploadFile != undefined && reUploadFile === true) ? true : false,
                            contentType: fileToUpload.type,
                            contentLength: fileToUpload.size
                        }
                    }
                })
            }
        }
    }

    let i = 0;

    const OnInputRefNumber = (event) => {
        let refValue = event.target.value.trimStart();
        if (refValue.length <= ReferenceNumberMaxLength) {
            setReferenceNumber('');
            setReferenceNumber(refValue.trim() != '' ? refValue : '');
        }
    }

    useEffect(() => {
        if (countUrl != 0) {
            uploadFile(files[countUrl])
        }
    }, [countUrl]);

    return (
        <Dialog
            open={dialogOpen}
            maxWidth='md'
        >
            <DialogTitle id='upload-files'>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h6'>{title ? title : t('Upload_Files')}</Typography>
                    <Tooltip title={t('Close')} arrow>
                        <IconButton id='upload-close' onClick={handleClose} style={{ float: 'right' }}>
                            <Close color='error' />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </DialogTitle>
            <Divider />
            <UploadDialogContent>
                <Stack spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}>
                    <Stack>
                        <DashedCard elevation={0} {...getRootProps({ className: 'dropzone' })} id='auction-drop-file'
                            style={{
                                border: isDragActive ? '2px dashed #0a72d8' : '2px dashed #9dc3eb',
                            }}
                        >
                            {progress && uploadedFiles.length != files.length &&
                                <Stack direction='column'>
                                    <CloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '7rem' }} />
                                    <Typography color='primary' variant='h5' className='dot-loading'>{t('Uploading')}</Typography>
                                </Stack>}
                            {uploadedFiles.length === files.length && uploadedFiles.length != 0 &&
                                <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
                                    <CloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '7rem' }} />
                                    <DoneAllRounded color='success' style={{ fontSize: '2rem' }} />
                                    <Typography variant='h5' color='green'>{t('Done')}</Typography>
                                </Stack>
                            }
                            {!progress && <>
                                <input className="input-zone" {...getInputProps()} id='auction-input-file' />
                                <Stack spacing={1} width={{ xs: '100%' }} justifyContent='center' alignItems='center'>
                                    <CloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '7rem' }} />
                                    {isDragActive ?
                                        <><Typography color='primary'>{t('Release_to_drop_the_files_here')}</Typography></> :
                                        <><Typography color='primary'>{t('Drag_and_drop_your_file_here')}</Typography>
                                            <Typography color='primary'>{t('Or')}</Typography>
                                            <ButtonStyled id="browse-file" variant='contained' onClick={open}>{t('Browse_Files')}</ButtonStyled></>}
                                </Stack>
                            </>}
                        </DashedCard>
                    </Stack>
                    {files.length > 0 &&
                        <Stack spacing={1} ref={containerRef}>
                            {isReferenceNo &&
                                <Fragment>
                                    <TextField id="referencenumber" label={t('Reference_Number_For_File')} fullWidth
                                        type="text" variant='outlined' onChange={OnInputRefNumber} value={referenceNumber}
                                    />
                                    <Divider flexItem />
                                </Fragment>
                            }
                            {files.map((file, index) => {
                                i = i + 100;
                                return (
                                    <Slide direction='up' in={true} timeout={300 + i} container={containerRef.current} key={`file-card-${index}`}>
                                        <FileUploadCard>
                                            <Stack spacing={1} direction='column'>
                                                <Stack spacing={1} direction='row'>
                                                    <Stack width={{ xs: '10%' }}><FileIcon fileName={file.name} /></Stack>
                                                    <Tooltip describeChild title={file.name}>
                                                        <Stack width={{ xs: '75%' }}><Typography gutterBottom noWrap>{file.name}</Typography></Stack>
                                                    </Tooltip>
                                                    <Stack width={{ xs: '10%' }} alignItems='end'>
                                                        {!(uploadedFiles.includes(file.path)) && !progress &&
                                                            <Tooltip title={t('Remove_Files')}>
                                                                <Fab id={`file-remove-${index}`} size='small' onClick={() => { removeSelectedFiles(file.path) }}
                                                                    style={{
                                                                        width: '25px',
                                                                        background: '#ffdddd',
                                                                        height: '25px', minHeight: '25px', boxShadow: '0px 3px 5px -1px rgb(185 10 10 / 20%), 0px 3px 8px 0px rgb(196 26 26 / 14%), 0px 1px 16px 0px rgb(224 111 111 / 12%)'
                                                                    }}>
                                                                    <ImCross color='rgb(204 22 22 / 69%)' fontSize='0.8em' />
                                                                </Fab>
                                                            </Tooltip>}
                                                        {(uploadedFiles.includes(file.path)) && <ImCheckmark color='green' fontSize='1.5em' />}
                                                    </Stack>
                                                </Stack>
                                                {!(uploadedFiles.includes(file.path)) && progress && <LinearProgress color='success' />}
                                            </Stack>
                                        </FileUploadCard>
                                    </Slide>
                                )
                            })}
                        </Stack>
                    }
                </Stack>
            </UploadDialogContent>
            <Divider />
            {files.length > 0 && !progress && <Collapse in={true} timeout={1000}>
                <DialogActions style={{ padding: '0.8rem', gap: '10px' }}>
                    <Typography color='textPrimary' variant='h7' gutterBottom style={{ fontWeight: 'bold' }}>Total <span id='file-count'>{files.length}</span> {files.length === 1 ? 'file' : 'files'}</Typography>
                    <ButtonStyled id='files-upload-btn' variant='contained' onClick={() => { setProgress(true); onClickUpload(); }}>{t('Upload')}</ButtonStyled>
                </DialogActions></Collapse>}
            {message.showMsg && <GraphQLErrors error={message.message} show={false} />}
            {fileRejections.length > 0 && <AuctionSnackBar show={fileRejections.length > 0 ? true : false}
                message={fileRejections.length > 1 ? `${fileRejections.length} ${t('Executable_Files_Are_Detected_Executable_Files_Cannot_Be_Uploaded')}`:
                    `${fileRejections[0].file.name} - ${t('Executable_File_Is_Detected_Executable_File_Cannot_Be_Uploaded')}`} severity={'error'} />}
        </Dialog >
    )
}

export default UploadDialog
