import { useLazyQuery } from '@apollo/client';
import { Slide, Stack } from '@mui/material';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuditTable from '../../auction-audit-table/AuditTable';
import BidHistory from '../../auction-bid-history/BidHistory';
import AuctionDocumentTabs from '../../auction-document/AuctionDocumentTabs';
import HelpDesk from '../../auction-helpdesk/HelpDesk';
import ItemList from '../../auction-item/item-list/ItemList';
import LotList from '../../auction-lot/lot-list/LotList';
import AuctionSellerList from '../../auction-seller/auction-seller-list/AuctionSellerList';
import AuctionHall from '../../auctioneer-auction-hall/AuctionHall';
import Bid from '../../bidder/bid-process/Bid';
import ListBidder from '../../bidder/list-bidder/ListBidder';
import BidderDetails from '../../bidder/bidder-details/BidderDetails';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { UserContext } from '../../common/context/UserContext';
import GraphQLErrors from '../../common/GraphQLErrors';
import { AuctionStatus, BiddingAccess, MannerOfSale, Roles } from '../../common/StaticVariableDeclaration';
import { PublicAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import { GET_AUCTION } from '../../GraphQL/Queries';
import ManageRolesList from '../../manage-role/ManageRolesList';
import ReportList from '../../report/report-list/ReportList';
import TermsConditions from '../../terms-conditions/TermsConditions';
import { AuctionSpecificDetailView } from '../auction-specific-detail/AuctionSpecificDetailView';
import { SectionContent, StickyHeader } from './auction-style/AuctionStyle';
import { AuctionDetailMenuItems } from './AuctionDetailStaticVar';
import { AuctionContainer, AuctionSection } from './side-nav/side-nav-style/SideNavStyle';
import SideNav from './side-nav/SideNav';
import SubHeader from './sub-header/SubHeader';
import Communications from '../../auction-communications/Communications';
import CommunicationsEmail from '../../auction-communications/CommunicationsEmail';
import ItemImagesDetail from '../../auction-detail-image/ItemImagesDetail';
import { useServerCurrentDateTime } from '../../utils/Utils';


export const AuctionDetailContext = createContext(null);

const AuctionDetail = (props) => {
    const { t } = useTranslation();
    const [selectedList, setSelectedList] = useState(props.location.state.sideNavigation)
    const [menuCollapse, setMenuCollapse] = useState(false)
    const [itemTitle, setItemTitle] = useState(t('Lot'))
    const [bidHeading, setBidHeading] = useState({
        bidLabel: t('Auction'),
        bidDescription: t('Asset_Brief'),
        idLabel: t('Auction'),
        idDescription: ''
    })
    const [activeBidders, setActiveBidders] = useState({
        bidders: [],
        count: 0
    });
    const userContext = useContext(UserContext).rootContext;
    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const serverCurrentTime = useServerCurrentDateTime();

    const history = useHistory();
    // reference for all the menuItems
    const auctionDetailsRef = useRef(null);
    const itemRef = useRef(null);
    const itemOwnerRef = useRef(null);
    const documentRef = useRef(null);
    const bidderRef = useRef(null);
    const paymentRef = useRef(null);
    const manageRoleRef = useRef(null);
    const bidHistoryRef = useRef(null);
    const auditTrialRef = useRef(null);
    const communicationsRef = useRef(null);
    const forumRef = useRef(null);
    const termsConditionRef = useRef(null);
    const helpDeskRef = useRef(null);
    const auctionDetailImageRef = useRef(null);
    const reportRef = useRef(null);

    const [sideNavTrigger, setSideNavTrigger] = useState(1);
    const [showBidPage, setShowBidPage] = useState(false);
    const [showTCBidder, setShowTCBidder] = useState(false);
    const [auctionLotInfo, setAuctionLotInfo] = useState({
        id: '',
        autoExtension: false,
        autoExtensionMode: '',
        extendTimeBy: 0,
        extendTimeWhenBidReceivedInLastMinute: 0,
        startPrice: 0,
        reservedPrice: 0,
        incrementalPrice: 0,
        extensionTimeIncrementPrice: 0,
        currentHighestBid: 0,
        yourLastBid: 0,
        incremental: false,
        basePrice: 0,
    })
    const [itemInfo, setItemInfo] = useState('')
    const [auctionGetDetail, setAuctionGetDetail] = useState(null);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [loading, setLoading] = useState(false);
    //checking viewPort
    // let auctionDetailVP = useIsInViewport(auctionDetailsRef);
    // let itemVP = useIsInViewport(itemRef);
    // let bidderVP = useIsInViewport(bidderRef);
    // let paymentVP = useIsInViewport(paymentRef);
    // let manageRoleVP = useIsInViewport(manageRoleRef);
    // let bidHistoryVP = useIsInViewport(bidHistoryRef);
    // let auditTrialVP = useIsInViewport(auditTrialRef);
    // let forumVP = useIsInViewport(forumRef);

    const handleBackNavigation = () => {
        history.push({
            pathname: props.location.state.backNavigation,
            state: {
                userRole: props.location.state.navigationId,
                auctionListType: props.location.state.auctionListType,
                pagination: props.location.state.pagination,
                filtersValue:  props.location.state.filtersValue,
                isMyBid: props.location.state.isMyBid
            }
        })
        // **************  Uncomment for isolated landing component ******************
        // if(userContext.userSession.isAuthenticated) {
        //     history.push({
        //         pathname: props.location.state.backNavigation,
        //         state: {
        //             userRole: props.location.state.navigationId,
        //             auctionListType: props.location.state.auctionListType,
        //             pagination: props.location.state.pagination
        //         }
        //     })
        // } else {
        //     history.push({ pathname: "/landing" });
        // }
    };

    useEffect(() => {
        if (props.location.state.sideNavigation == AuctionDetailMenuItems.BIDDING_HALL.value) {
            setShowBidPage(true);
            setMenuCollapse(true);
        }
        handleGetAuctionDetail();
    }, [])

    const listChangeHandler = (event, value) => {
        event.stopPropagation();
        setSelectedList(value);
        setSideNavTrigger(sideNavTrigger + 1);
        // switch (value) {
        //     case AuctionDetailMenuItems.AUCTION_DETAILS: auctionDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        //     case AuctionDetailMenuItems.ITEMS: { console.log('v', itemRef); itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) }
        //         break;
        //     case AuctionDetailMenuItems.BIDDER: bidderRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break
        //     case AuctionDetailMenuItems.PAYMENT: paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        //     case AuctionDetailMenuItems.MANAGE_ROLE: manageRoleRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        //     case AuctionDetailMenuItems.BIDDING_HISTORY: bidHistoryRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        //     case AuctionDetailMenuItems.AUDIT_TRAIL: auditTrialRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        //     case AuctionDetailMenuItems.FORUM: forumRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        //     default: auctionDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        //         break;
        // }
    }

    // useEffect(() => {
    //     console.log('scroll', auctionDetailVP, itemVP, bidderVP, paymentVP, manageRoleVP, bidHistoryVP, auditTrialVP, forumVP)
    //     if (auctionDetailVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.AUCTION_DETAILS)
    //         console.log('scroll')
    //     } else if (itemVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.ITEMS)
    //         console.log('item')
    //     } else if (bidderVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.BIDDER)
    //     } else if (paymentVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.PAYMENT)
    //     } else if (manageRoleVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.MANAGE_ROLE)
    //     } else if (bidHistoryVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.BIDDING_HISTORY)
    //     } else if (auditTrialVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.AUDIT_TRAIL)
    //     } else if (forumVP != 0) {
    //         setSelectedList(AuctionDetailMenuItems.FORUM)
    //     }
    // }, [auctionDetailVP, itemVP, bidderVP, paymentVP, manageRoleVP, bidHistoryVP, auditTrialVP, forumVP])

    // function useIsInViewport(ref) {
    //     const [intersectionRatio, setIntersectionRatio] = useState();

    //     const observer = useMemo(
    //         () =>
    //             new IntersectionObserver(([entry]) =>
    //                 setIntersectionRatio(entry.intersectionRatio.toFixed(3)),
    //             ),
    //         [],
    //     );

    //     useEffect(() => {
    //         observer.observe(ref.current);

    //         return () => {
    //             observer.disconnect();
    //         };
    //     }, [ref, observer]);

    //     console.log('x', intersectionRatio)

    //     return intersectionRatio;
    // }

    const handleSetShowBidPage = () => {
        setShowBidPage(true);
        setMenuCollapse(true);
        setSelectedList(AuctionDetailMenuItems.BIDDING_HALL.value)
    }

    const handleGetAuctionDetail = () => {
        if (userContext.userSession.isAuthenticated) {
            getAuctionDetail({
                variables: { auctionId: props.location.state.auctionDetail.id }
            });
        } else {
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/auction/${props.location.state.auctionDetail.id}`).then(function (response) {
                setAuctionGetDetail(response);
                // setShowTCBidder(response?.hasTermsAndCondition)
                // setItemTitle(getTitleByMannerSale(response?.mannerOfSale));
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({ show: false, message: '', severity: '' })
                setMessage({ show: true, message: error, severity: 'error' })
            });
        }
    }

    const setLotInfoBasedOnMannerOfSale = (auctionLots, mannerOfSale, auctionInfo) => {
        switch (mannerOfSale) {
            case MannerOfSale.goingConcern:
                setBidHeading({
                    bidLabel: t('Auction_as_a_Going_Concern'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction_ID'),
                    idDescription: auctionInfo.auctionId
                })
                auctionLots.map((value) => {
                    return setAuctionLotInfo(prevState => ({
                        ...prevState,
                        id: value.id,
                        lastDateForKyc: value.lastDateForKyc,
                        autoExtension: value.autoExtension,
                        autoExtensionMode: value.autoExtensionMode,
                        extendTimeBy: value.extendTimeBy,
                        extendTimeWhenBidReceivedInLastMinute: value.extendTimeWhenBidReceivedInLastMinute,
                        startPrice: value.bidStartPrice,
                        reservedPrice: value.reservePrice,
                        incrementalPrice: value.incrementalAmount,
                        currentHighestBid: 0,
                        yourLastBid: 0,
                        noOfExtension: value.noOfExtension,
                        extensionTimeIncrementPrice: value.incrementalAmountDuringExtension > 0 ? value.incrementalAmountDuringExtension : value.incrementalAmount,
                        incremental: value.incrementalAmount > 0 ? true : false,
                        basePrice: value.bidStartPrice > 0 ? value.bidStartPrice : value.reservePrice
                    }))
                });
                return;
            case MannerOfSale.singleLot:
                if (auctionLots != null && auctionLots.length > 0) return setAuctionLotInfo(prevState => ({
                    ...prevState,
                    id: auctionLots[0].id,
                    autoExtension: auctionLots[0].autoExtension,
                    autoExtensionMode: auctionLots[0].autoExtensionMode,
                    extendTimeBy: auctionLots[0].extendTimeBy,
                    extendTimeWhenBidReceivedInLastMinute: auctionLots[0].extendTimeWhenBidReceivedInLastMinute,
                    startPrice: auctionLots[0].bidStartPrice,
                    reservedPrice: auctionLots[0].reservePrice,
                    incrementalPrice: auctionLots[0].incrementalAmount,
                    currentHighestBid: 0,
                    yourLastBid: 0,
                    extensionTimeIncrementPrice: auctionLots[0].incrementalAmountDuringExtension,
                    incremental: auctionLots[0].incrementalAmount > 0 ? true : false,
                    basePrice: auctionLots[0].bidStartPrice > 0 ? auctionLots[0].bidStartPrice : auctionLots[0].reservePrice
                }));
            case MannerOfSale.singleItem:
                if (auctionLots != null && auctionLots.length > 0) return setAuctionLotInfo(prevState => ({
                    ...prevState,
                    id: auctionLots[0].id,
                    autoExtension: auctionLots[0].autoExtension,
                    autoExtensionMode: auctionLots[0].autoExtensionMode,
                    extendTimeBy: auctionLots[0].extendTimeBy,
                    extendTimeWhenBidReceivedInLastMinute: auctionLots[0].extendTimeWhenBidReceivedInLastMinute,
                    startPrice: auctionLots[0].bidStartPrice,
                    reservedPrice: auctionLots[0].reservePrice,
                    incrementalPrice: auctionLots[0].incrementalAmount,
                    currentHighestBid: 0,
                    yourLastBid: 0,
                    extensionTimeIncrementPrice: auctionLots[0].incrementalAmountDuringExtension,
                    incremental: auctionLots[0].incrementalAmount > 0 ? true : false,
                    basePrice: auctionLots[0].bidStartPrice > 0 ? auctionLots[0].bidStartPrice : auctionLots[0].reservePrice
                }));
        }
    }

    const getTitleByMannerSale = (mannerOfSale) => {
        switch (mannerOfSale) {
            case MannerOfSale.goingConcern: return 'Items';
            case MannerOfSale.singleLot: return 'Lot';
            case MannerOfSale.singleItem: return 'Items';
            default: return 'Items/Lot';
        }
    }

    const [getAuctionDetail, { loading: getAuctionLoading, error: getAuctionError }] = useLazyQuery(GET_AUCTION, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: false,
        onCompleted: auctions => {
            if (auctions.getAuction != null) {
                setAuctionGetDetail(auctions.getAuction);
                setShowTCBidder(auctions.getAuction?.hasTermsAndCondition)
                // setItemTitle(getTitleByMannerSale(auctions.getAuction?.mannerOfSale))
                setLotInfoBasedOnMannerOfSale(auctions.getAuction.auctionLots, auctions.getAuction?.mannerOfSale, auctions.getAuction)
            }
        },
        onError: (error) => {
            console.log('getAuctionDetail -> NETWORK error :', error);
        }
    });

    return (
        <div>
            {auctionGetDetail && <AuctionDetailContext.Provider value={{ auctionGetDetail, setAuctionGetDetail, setSelectedList }}>
                <SideNav selectedList={selectedList} setSelectedList={setSelectedList} listChangeHandler={listChangeHandler} menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} itemTitle={itemTitle}
                    handleBackNavigation={handleBackNavigation} showBidPage={showBidPage}
                    showAuctionHall={(isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                        (AuctionStatus.open === auctionGetDetail.status.toLowerCase() || AuctionStatus.closed === auctionGetDetail.status.toLowerCase())}
                    showPayment={(isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support))}
                    showBidder={isAdmin || auctionGetDetail.roles?.includes(Roles.bidder) || auctionGetDetail.roles?.includes(Roles.requestedBidder) ||
                        auctionGetDetail.roles?.includes(Roles.rejectedBidder) || auctionGetDetail.roles.includes(Roles.auctioneer) ||
                        auctionGetDetail.roles.includes(Roles.support)}
                    showManageRole={isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)}
                    showBidHistory={(AuctionStatus.open === auctionGetDetail.status.toLowerCase() || AuctionStatus.closed === auctionGetDetail.status.toLowerCase() || AuctionStatus.cancelled === auctionGetDetail.status.toLowerCase()) &&
                        (auctionGetDetail.roles?.includes(Roles.bidder) || auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) ||
                        auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support) || isAdmin)}
                    showAuditTrail={isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)}
                    showReport={((isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) && auctionGetDetail.status.toLowerCase() === AuctionStatus.closed)}
                    showTermsAndCondition={isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)
                        || (showTCBidder === true ? true : false)}
                    showCommunications={isAdmin || auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)}
                />
                {/* SubHeader */}
                {auctionGetDetail && <SubHeader ismenucollapse={+menuCollapse}
                    title={auctionGetDetail.title} handleBackNavigation={handleBackNavigation} status={auctionGetDetail.status.toLowerCase()}
                    handleSetShowBidPage={handleSetShowBidPage} startDate={auctionGetDetail.startTime}
                    registerStartDate={auctionGetDetail.registrationStartTime} registerEndDate={auctionGetDetail.registrationEndTime}
                    selectedList={selectedList}
                    isBidding={
                        auctionGetDetail.biddingAccess === BiddingAccess.open ||
                        (auctionGetDetail.status.toLowerCase() === AuctionStatus.open &&
                        !auctionGetDetail.roles.includes(Roles.auctioneer) &&
                        !auctionGetDetail.roles.includes(Roles.support) &&
                        !auctionGetDetail.roles?.includes(Roles.rejectedBidder) &&
                        !auctionGetDetail.roles?.includes(Roles.requestedBidder) &&
                        // !auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative)) &&
                        (auctionGetDetail.roles?.includes(Roles.bidder) || auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative)))
                    }
                    isRequestBid={
                        auctionGetDetail.status.toLowerCase() == AuctionStatus.scheduled && new Date(auctionGetDetail.auctionLots[0].lastDateForKyc) > serverCurrentTime &&
                            auctionGetDetail.biddingAccess == BiddingAccess.limited &&
                            !auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) &&
                            !auctionGetDetail.roles?.includes(Roles.bidder) &&
                            !auctionGetDetail.roles?.includes(Roles.requestedBidder) &&
                            !auctionGetDetail.roles.includes(Roles.auctioneer) &&
                            !auctionGetDetail.roles.includes(Roles.support) &&
                            !auctionGetDetail.roles.includes(Roles.coWorker) &&
                            !auctionGetDetail.roles.includes(Roles.valuer) &&
                            !auctionGetDetail.roles.includes(Roles.viewer) &&
                            !auctionGetDetail.roles.includes(Roles.systemAdmin) &&
                            !auctionGetDetail.roles.includes(Roles.rejectedBidder) &&
                            !isCustomerSupport && !isAdmin &&
                        (auctionGetDetail.conductingFor.length == 0 || !auctionGetDetail.conductingFor[0].loginId || !userContext.userSession?.session?.userId ||
                            userContext.userSession.session.userId != auctionGetDetail.conductingFor[0].loginId)
                    } showBidPage={showBidPage}
                    auction_Id={auctionGetDetail.id} lotId={auctionLotInfo.id}
                    seller_id={auctionGetDetail.conductingFor[0] ? auctionGetDetail.conductingFor[0].personId : ''}
                    auctionCreationDate={auctionGetDetail.createdOn}
                    handleGetAuctionDetail={handleGetAuctionDetail}
                />}
                <AuctionContainer iscollapse={+menuCollapse}>
                    {auctionLotInfo.id != '' && showBidPage && selectedList === AuctionDetailMenuItems.BIDDING_HALL.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.BIDDING_HALL.id}-section`} ref={auctionDetailsRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.BIDDING_HALL.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    {/* This Bid Component is only for Manner of Sale - GoingConcern, SingleLot & SingleItem */}
                                    {(MannerOfSale.goingConcern === auctionGetDetail.mannerOfSale || MannerOfSale.singleLot === auctionGetDetail.mannerOfSale ||
                                        MannerOfSale.singleItem === auctionGetDetail.mannerOfSale) &&
                                        <Bid />}
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.AUCTION_HALL.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.AUCTION_HALL.id}-section`}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.AUCTION_HALL.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <AuctionHall auctionLotInfo={auctionLotInfo} auctionId={props.location.state.auctionDetail.id}
                                        lotId={auctionLotInfo.id} itemName={props.location.state.auctionDetail.description} endDate={props.location.state.auctionDetail.endTime}
                                        bidderId={userContext.userSession.session.userId} bidHeading={bidHeading}
                                        activeBidders={activeBidders} setActiveBidders={setActiveBidders} />
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.AUCTION_DETAILS.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.AUCTION_DETAILS.id}-section`} ref={auctionDetailsRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.AUCTION_DETAILS.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <AuctionSpecificDetailView handleGetAuctionDetail={handleGetAuctionDetail} />
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.ITEMS.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.ITEMS.id}-section`} ref={itemRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{itemTitle}</StickyHeader></Slide>
                                <SectionContent>
                                    {(auctionGetDetail.mannerOfSale === MannerOfSale.singleLot ||
                                        auctionGetDetail.mannerOfSale === MannerOfSale.goingConcern) &&
                                        <LotList auction_id={props.location.state.auctionDetail.id} lot_id={auctionLotInfo.id}
                                            // multiLot={auctionGetDetail.mannerOfSale === MannerOfSale.lot}
                                            canAdd={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            mannerOfSale={auctionGetDetail.mannerOfSale}
                                            canDelete={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            canEdit={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            canUpload={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)} />
                                    }
                                    {auctionGetDetail.mannerOfSale === MannerOfSale.singleItem &&
                                        <ItemList isIndividualBid={false} auctionId={props.location.state.auctionDetail.id} lotId={auctionLotInfo.id}
                                            canAdd={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            multiItem={false}
                                            // canAdd={false}
                                            canDelete={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            canEdit={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            canUpload={(((auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin)
                                                && (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)} />
                                    }
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.SELLER.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.SELLER.id}-section`} ref={itemOwnerRef} key={sideNavTrigger}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.SELLER.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <AuctionSellerList auctionId={props.location.state.auctionDetail.id} roles={auctionGetDetail.roles} status={auctionGetDetail.status.toLowerCase()} handleGetAuctionDetail={handleGetAuctionDetail}></AuctionSellerList>
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.DOCUMENTS.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.DOCUMENTS.id}-section`} ref={documentRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.DOCUMENTS.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <AuctionDocumentTabs auctionId={props.location.state.auctionDetail.id} roles={auctionGetDetail.roles} showBidderDropdown={true} status={auctionGetDetail.status.toLowerCase()}></AuctionDocumentTabs>
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.BIDDER.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.BIDDER.id}-section`} ref={bidderRef} key={sideNavTrigger}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.BIDDER.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    { !auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) && !auctionGetDetail.roles.includes(Roles.bidder)
                                            && !auctionGetDetail.roles.includes(Roles.requestedBidder) &&
                                        <ListBidder
                                            auction_id={props.location.state.auctionDetail.id}
                                            seller_id={auctionGetDetail.conductingFor[0] ? auctionGetDetail.conductingFor[0].personId : ''}
                                            canAdd={(((auctionGetDetail.roles.includes(Roles.support) || auctionGetDetail.roles.includes(Roles.auctioneer)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled
                                                    || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request))
                                                || (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin) &&
                                                (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            canEdit={((((auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) || auctionGetDetail.roles.includes(Roles.bidder)
                                                || auctionGetDetail.roles.includes(Roles.requestedBidder))
                                                || (auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support))) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled
                                                    || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request))
                                                || (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin) &&
                                                (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            canChangeStatus={(((auctionGetDetail.roles.includes(Roles.support) || auctionGetDetail.roles.includes(Roles.auctioneer)) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled ||
                                                auctionGetDetail.status.toLowerCase() === AuctionStatus.open || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) ||
                                                (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin) &&
                                                (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                            lot_id={auctionLotInfo.id}
                                            showFilters={(auctionGetDetail.roles.includes(Roles.support) || auctionGetDetail.roles.includes(Roles.auctioneer))}
                                            sendCredentials={(auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin ||
                                                ((auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled || auctionGetDetail.status.toLowerCase() === AuctionStatus.open) &&
                                                (auctionGetDetail.roles.includes(Roles.support) || auctionGetDetail.roles.includes(Roles.auctioneer)))
                                            }
                                        />}
                                    { (auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) || auctionGetDetail.roles.includes(Roles.bidder)
                                            || auctionGetDetail.roles.includes(Roles.requestedBidder)) &&
                                        <BidderDetails auction_id={props.location.state.auctionDetail.id}
                                            seller_id={auctionGetDetail.conductingFor[0] ? auctionGetDetail.conductingFor[0].personId : ''}
                                            lot_id={auctionLotInfo.id}
                                            canEdit={((((auctionGetDetail.roles.includes(Roles.bidderAuthorizedRepresentative) || auctionGetDetail.roles.includes(Roles.bidder)
                                                || auctionGetDetail.roles.includes(Roles.requestedBidder))
                                                || (auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support))) &&
                                                (auctionGetDetail.status.toLowerCase() === AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.scheduled
                                                || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request))
                                                || (auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin) &&
                                                (auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed)}
                                        />
                                    }
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {/* {selectedList === AuctionDetailMenuItems.BANK_ACCOUNT.value &&
                        <AuctionSection id={AuctionDetailMenuItems.BANK_ACCOUNT.id} ref={bidderRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.BANK_ACCOUNT.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <Typography style={{ marginLeft: '2.5%', fontWeight: 'bold' }}>{t('Bank_Accounts_To_Which_EMD_To_Be_Transferred')}</Typography>
                                    <BankDetailList
                                        auction_id={props.location.state.auctionDetail.id}
                                        userId={auctionGetDetail.conductingFor[0] ? auctionGetDetail.conductingFor[0].personId : ''}
                                        canModify={(auctionGetDetail.roles.includes(Roles.auctioneer) || auctionGetDetail.roles.includes(Roles.support))} />
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    } */}
                    {/* {selectedList === AuctionDetailMenuItems.PAYMENT.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.PAYMENT.id}-section`} ref={paymentRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.PAYMENT.displayName}</StickyHeader></Slide>
                                <SectionContent></SectionContent>
                            </Stack>
                        </AuctionSection>
                    } */}
                    {selectedList === AuctionDetailMenuItems.MANAGE_ROLE.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.MANAGE_ROLE.id}-section`} ref={manageRoleRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.MANAGE_ROLE.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <ManageRolesList auctionId={props.location.state.auctionDetail.id} handleBackNavigation={handleBackNavigation} auctionListType={props.location.state.auctionListType}
                                        isSystemAdminSupport={(auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin} />
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.BIDDING_HISTORY.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.BIDDING_HISTORY.id}-section`} ref={bidHistoryRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.BIDDING_HISTORY.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <BidHistory auctionId={props.location.state.auctionDetail.id} auctionStatus={auctionGetDetail.status.toLowerCase()}
                                        bidOnId={auctionLotInfo.id} key='BidHistory' pagination={true}
                                        isBidder={!isCustomerSupport && !isAdmin && !auctionGetDetail.roles.includes(Roles.auctioneer) && !auctionGetDetail.roles.includes(Roles.support)} />
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.AUDIT_TRAIL.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.AUDIT_TRAIL.id}-section`} ref={auditTrialRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.AUDIT_TRAIL.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <AuditTable
                                        auctionId={props.location.state.auctionDetail.id}
                                        endTime={props.location.state.auctionDetail.endTime}
                                        startTime={props.location.state.auctionDetail.startTime}
                                        description={props.location.state.auctionDetail.description}
                                        date={auctionGetDetail.createdOn} />
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.HELP_DESK.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.HELP_DESK.id}-section`} ref={helpDeskRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.HELP_DESK.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <HelpDesk auctionId={auctionGetDetail.id}></HelpDesk>
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.AUCTION_DETAIL_IMAGE.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.AUCTION_DETAIL_IMAGE.id}-section`} ref={auctionDetailImageRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.AUCTION_DETAIL_IMAGE.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <ItemImagesDetail auctionId={auctionGetDetail.id}></ItemImagesDetail>
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.COMMUNICATIONS.value &&
                        <AuctionSection id={AuctionDetailMenuItems.COMMUNICATIONS.id} ref={communicationsRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.COMMUNICATIONS.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <Communications auctionId={auctionGetDetail.id}
                                        date={auctionGetDetail.createdOn}></Communications>

                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {selectedList === AuctionDetailMenuItems.TERMS_AND_CONDITION.value &&
                        <AuctionSection id={`${AuctionDetailMenuItems.TERMS_AND_CONDITION.id}-section`} ref={termsConditionRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.TERMS_AND_CONDITION.displayName}</StickyHeader></Slide>
                                <SectionContent>
                                    <TermsConditions auctionId={props.location.state.auctionDetail.id}
                                        canModify={((auctionGetDetail.roles.includes(Roles.support) || auctionGetDetail.roles.includes(Roles.auctioneer)) &&
                                            (auctionGetDetail.status.toLowerCase() == AuctionStatus.setup || auctionGetDetail.status.toLowerCase() === AuctionStatus.publish_request)) && !isCustomerSupport && !isAdmin }
                                        canEdit={((auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin ) && auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed}>
                                    </TermsConditions>
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                    {(selectedList === AuctionDetailMenuItems.REPORTS.value && auctionGetDetail.status.toLowerCase() === AuctionStatus.closed) &&
                        <AuctionSection id={`${AuctionDetailMenuItems.REPORTS.id}-section`} ref={reportRef}>
                            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                                <Slide in={true} direction='down' unmountOnExit><StickyHeader id='subheadBid'>{AuctionDetailMenuItems.REPORTS.displayName}</StickyHeader></Slide>
                                <SectionContent style={{ padding: '15px' }}>
                                    <ReportList auctionId={props.location.state.auctionDetail.id}></ReportList>
                                </SectionContent>
                            </Stack>
                        </AuctionSection>
                    }
                </AuctionContainer>
            </AuctionDetailContext.Provider>}
            {(getAuctionLoading || loading) && <AuctionLoader show={getAuctionLoading || loading} invisible={false} />}
            {getAuctionError && <GraphQLErrors error={getAuctionError} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </div >
    )
}

export default React.memo(AuctionDetail)
