import React, { useContext, useState, useEffect } from 'react';
import { Grid, Box, Drawer,List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import TourIcon from '@mui/icons-material/Tour';


const SupportDrawer = ({toggleDrawer, open}) => {

    return (
        <React.Fragment>
            <Drawer anchor='right' open={open} onClose={toggleDrawer('support', false)} >
            <Box sx={{ width: '30em' }} id="support-content-box" >
                <List>
                    <ListItem key='tour-guide' disablePadding>
                        <ListItemButton>
                            <ListItemIcon><TourIcon /></ListItemIcon>
                            <ListItemText primary={'Support'} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
          </Drawer>
        </React.Fragment>
    )

}

export default SupportDrawer