import { Button, Dialog, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AnimatedSuccessSVG from './AnimatedSuccessSVG';

const ConfirmationDialog = (props) => {
    const { title, show, description, redirect, redirectUrl } = props
    let history = new useHistory();
    const [open, setOpen] = useState(show);
    const handleClose = () => {
        setOpen(false);
        if (redirect) {
            history.push(redirectUrl);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogContent>
                    <DialogContent sx={{ padding: '5px 0px 0px 0px' }}>
                        <Stack justifyContent='center' alignItems='center' spacing={1}>
                            <AnimatedSuccessSVG />
                            <Typography variant='h5' sx={{ color: (theme) => theme.palette.success.main }}>{title}</Typography>
                            <Typography className="text-center" color='textSecondary'>{description}</Typography>
                        </Stack>
                    </DialogContent>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Grid item className="text-center">
                        <Button onClick={handleClose} color="primary" autoFocus variant="contained" sx={{
                            padding: '6px 38px',
                            boxShadow: '0px 1.7px 2.2px rgba(0, 0, 0, 0.02), 0px 4px 5.3px rgba(0, 0, 0, 0.028), 0px 7.5px 10px rgba(0, 0, 0, 0.035),0px 13.4px 17.9px rgba(0, 0, 0, 0.042), 0px 25.1px 33.4px rgba(0, 0, 0, 0.05),0px 60px 80px rgba(0, 0, 0, 0.07)'
                        }}>
                            OK
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmationDialog