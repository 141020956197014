import { Box, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MuiPhoneNumber from "material-ui-phone-number";
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuctionFieldSet } from '../../authentication/AuthStyle';
import { Validation } from '../../common/Validation';
import { AxiosAuctionInterceptor, LookUpAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import AddressAutocompleteControl from '../../custom-form-control/AddressAutocompleteControl';
import { COUNTRY_DEFAULT } from './Address';
import { CountryPincodeRegex } from '../../common/AddressPincodeRegex';
import { AddressFieldsForCommon } from './address-common/RequiredFieldsAddress';

const useStyles = makeStyles(() => ({
    boxShadowNone: {
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0rem !important',
        margin: '0rem !important'
    }
}))

const AddressDetail = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { control, setValue, getValues, watch, errors, requiredShowFields, createTitle, onEditComponent, isEdit, clearErrors } = {
        ...props, requiredShowFields: props.requiredShowFields || AddressFieldsForCommon, onEditComponent: props.onEditComponent || false,
        isEdit: props.isEdit || false
    }
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [countryLoading, setCountryLoading] = useState();
    const [stateLoading, setStateLoading] = useState();
    const [cityLoading, setCityLoading] = useState();
    const [postalCodes, setPostalCodes] = useState([])
    const [countryId, setCountryId] = useState('')
    const [countryIso2, setCountryIso2] = useState('')
    const [stateId, setStateId] = useState('')
    const [cityTownId, setCityTownId] = useState('')
    const [postalCodeId, setPostalCodeId] = useState('')
    const [clearedStateOrCity, setClearedStateOrCity] = useState(false)
    const [onPinCode, setOnPinCode] = useState(false)
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    });
    const [countryReason, setCountryReason] = useState('');
    const watchCountry = useWatch({ control, name: 'country' });
    const watchPostalCode = useWatch({ control, name: 'postalCode' });
    const watchState = useWatch({ control, name: 'state' });
    const watchCityTown = useWatch({ control, name: 'cityTown' });
    const watchAddressID = useWatch({ control, name: 'addressId' });

    // const [ListCountry, { loading, countryerror }] = useLazyQuery(LIST_COUNTRIES, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         if (data.listCountry) {
    //             setCountries(data.listCountry)
    //         }
    //         //Set India as Default
    //         data.listCountry.map((value) => {
    //             if (value.name === 'India') {
    //                 setValue('country', value.name)
    //                 setCountryId(value.countryId)
    //             }
    //         })
    //     }
    // })

    const ListLookUpCountry = () => {
        LookUpAxiosAuctionInterceptor.get(`geo/countries`).then(function (response) {
            if (response) {
                setCountries(response)
                //Set India as Default
                if (getValues('addressId') === '') {
                    response.map((value) => {
                        if (value.name === 'India') {
                            setValue('country', value.name);
                            setCountryId(value.countryId);
                            setCountryIso2(value.iso2);
                        }
                    })
                }
            }
            setCountryLoading(false);
        }).catch(function (error) {
            setCountryLoading(false);
            console.log(error)
        });
    }

    // const [ListPostalCodeByCountry, { loading: pinCodeFetchLoading, error: pinCodefetchError }] = useLazyQuery(LIST_POSTALCODE_BY_COUNTRY, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         setPostalCodes(data.listPostalCode)
    //     }
    // })

    // const [ListStates, { loading: stateFetchLoading, error: stateFetchError }] = useLazyQuery(LIST_STATES, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         setStates(data.listState)
    //     }
    // })

    const ListLookUpStates = (countryId) => {
        LookUpAxiosAuctionInterceptor.get(`geo/country/${countryId}/states`).then(function (response) {
            if (response) {
                setStates(response);
            }
            setStateLoading(false);
        }).catch(function (error) {
            setStateLoading(false);
            // setMessage({ show: false, message: '', severity: '' });
            // setMessage({ show: true, message: error, severity: 'error' });
        });
    }

    // const [ListCities, { loading: cityFetchLoading, error: cityFetchError }] = useLazyQuery(LIST_CITIES, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         setCities(data.listCity)
    //     }
    // })

    const ListLookUpCities = (stateId) => {
        LookUpAxiosAuctionInterceptor.get(`geo/state/${stateId}/cities`).then(function (response) {
            if (response) {
                setCities(response)
            }
            setCityLoading(false);
        }).catch(function (error) {
            setCityLoading(false);
            // setMessage({ show: false, message: '', severity: '' });
            // setMessage({ show: true, message: error, severity: 'error' });
        });
    }

    // const [GetStateCityByPincode, { loading: autofillLoading, error: autofillfetchError }] = useLazyQuery(GET_STATE_CITY_BY_PINCODE, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         //Todo autofill
    //         if (data.getStateAndCity != null) {
    //             setValue('state', data.getStateAndCity.state)
    //             setValue('cityTown', data.getStateAndCity.city)
    //             setStateId(data.getStateAndCity.stateId)
    //             setCityTownId(data.getStateAndCity.cityId)
    //         }
    //     }
    // })

    const clearPincodeStateCity = () => {
        setValue('postalCode', '')
        setValue('state', '')
        setValue('cityTown', '')
        // setCountryId('')
        setStateId('')
        setCityTownId('')
        setPostalCodeId('')
        setPostalCodes([])
        setStates([])
        setCities([])
        setClearedStateOrCity(true)
    }

    const clearStateCity = () => {
        setValue('state', '')
        setValue('cityTown', '')
        setStateId('')
        setCityTownId('')
        setStates([])
        setCities([])
    }

    const clearCity = () => {
        setValue('cityTown', '')
        setCityTownId('')
        setCities([])
    }

    const clearCityPinCode = () => {
        setValue('cityTown', '')
        // setValue('postalCode', '')
        setCityTownId('')
        // setPostalCodeId('')
        setCities([])
    }

    const clearPinCode = () => {
        setValue('postalCode', '')
        setPostalCodeId('')
    }

    const handleClearUseState = () => {
        setClearedStateOrCity(false)
        setOnPinCode(true)
    }

    const handlePincode = () => {
        setOnPinCode(false)
        setClearedStateOrCity(true)
    }

    // useEffect(() => {
    //     if (getValues('postalCode') != undefined) {
    //         postalCodes?.filter((value) => value.postalCode != '' && getValues('postalCode').toLowerCase() === value.postalCode.toLowerCase()).map((value) => { setPostalCodeId(value.postalCodeId) });
    //     }
    //     if (getValues('addressId') != undefined && getValues('addressId') != '' && countryId !== '') {
    //         ListStates({
    //             variables: {
    //                 countryId: countryId
    //             }
    //         })
    //     }
    // }, [postalCodes, postalCodeId])

    useEffect(() => {
        if (isEdit) {
            // ListCountry()
            ListLookUpCountry();
        }
    }, [isEdit])

    useEffect(() => {
        if (isEdit && countryId !== '') {
            // ListStates({
            //     variables: {
            //         countryId: countryId
            //     }
            // })
            ListLookUpStates(countryId);
            // ListPostalCodeByCountry({
            //     variables: {
            //         countryId: countryId
            //     }
            // })
        }
    }, [countryId])

    useEffect(() => {
        // if (isEdit && postalCodeId !== '') {
        //     GetStateCityByPincode({
        //         variables: {
        //             postalCodeId: postalCodeId
        //         }
        //     })
        // }
    }, [postalCodeId])

    useEffect(() => {
        if (isEdit && stateId !== '') {
            ListLookUpCities(stateId)
        }
    }, [stateId])

    useEffect(() => {
        if (isEdit &&
            getValues('country') === COUNTRY_DEFAULT &&
            getValues('addressId') === '' &&
            ((countryReason !== 'selectOption' && countryReason !== 'clear') || countryReason === '')) {
            let country = countries?.filter((value) => { return value.name != null && value.name != '' && value.name === COUNTRY_DEFAULT })
            if (country.length !== 0) {
                setValue('country', country[0].name)
                setCountryId(country.countryId)
                setCountryIso2(country.iso2)
            }
        } else {
            setCountryReason('')
        }
    }, [watchCountry])

    useEffect(() => {
        if (isEdit && getValues('country') !== undefined && getValues('country') !== '' && getValues('country') !== null) {
            let country = countries?.filter((value) => {
                return value.name != null && value.name != '' && value.name.toLowerCase() === getValues('country').toLowerCase()
            })
            if (country.length !== 0) {
                setCountryId(country[0].countryId)
                setCountryIso2(country[0].iso2)
                // clearPincodeStateCity();
            }
        } else if (isEdit && (getValues('country') === null || getValues('country') === '' && countryId != '')) {
            setCountryId('')
            setCountryIso2('')
            // clearPincodeStateCity()
        }
    }, [watchCountry, isEdit, countries])

    // useEffect(() => {
    //     if (isEdit && getValues('postalCode') !== '' && getValues('postalCode') !== null && countryId !== '') {
    //         let postalCode = postalCodes?.filter((pc) => pc.postalCode !== null && pc.postalCode.trim() !== '' && pc.postalCode === getValues('postalCode'))
    //         if (postalCode.length !== 0) {
    //             setPostalCodeId(postalCode[0].postalCodeId)
    //         } else if (getValues('postalCode') !== undefined && getValues('postalCode') !== null && getValues('postalCode') !== '' && getValues('postalCode').length === 6) {
    //             setPostalCodeId('')
    //         }
    //     } else if (isEdit && (getValues('postalCode') === null || getValues('postalCode') === '')) {
    //         setPostalCodeId('')
    //         // clearStateCity();
    //     }
    // }, [watchPostalCode])

    useEffect(() => {
        if (isEdit && getValues('state') !== undefined && getValues('state') !== '' && getValues('state') !== null && countryId !== '') {
            let state = states?.filter((value) => { return value.name != null && value.name != '' && value.name.toLowerCase() === getValues('state').toLowerCase() })
            if (state.length !== 0) {
                setStateId(state[0].stateId)
            }
        } else if (isEdit && (getValues('state') === null || getValues('state') === '')) {
            setStateId('')
            // clearCity();
        }
    }, [watchState, isEdit, states, countryId])

    useEffect(() => {
        if (isEdit && getValues('cityTown') !== undefined && getValues('cityTown') !== '' && getValues('cityTown') !== null && countryId !== '') {
            let city = cities?.filter((value) => { return value.name != null && value.name != '' && value.name.toLowerCase() === getValues('cityTown').toLowerCase() })
            if (city.length !== 0) {
                setCityTownId(city[0].cityTownId)
            }
        } else if (isEdit && (getValues('cityTown') === null || getValues('cityTown') === '')) {
            setCityTownId('')
            //TODO clear
        }
    }, [watchCityTown, countryId])

    return (
        <div>
            {(createTitle != undefined && createTitle) &&
                <Box p={2}>
                    <Typography variant='subtitle1' style={{ textTransform: 'uppercase', color: '#1976d2', display: 'inline-flex', borderBottom: '2px solid #1976d2', paddingBottom: '5px', }}>{getValues('addressType')}</Typography>
                </Box>}
            <Box sx={{ flexGrow: 1 }}>
                <Stack spacing={2}>
                    <AuctionFieldSet className={onEditComponent ? classes.boxShadowNone : ''}>
                        {/* <AuctionLengend align="left"><strong>{t('Primary Contact')}</strong></AuctionLengend> */}
                        <Stack spacing={2} direction={{ xs: 'column' }}>
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}>
                                {requiredShowFields.contactName.show &&
                                    <Controller
                                        name="attention"
                                        control={control}
                                        rules={Validation.addressNameNotMandatory}
                                        render={({ field: { name, onChange, value } }) => (
                                            <TextField
                                                name={name}
                                                id="attention"
                                                label={t('Attention_Name')}
                                                value={value}
                                                onChange={onChange}
                                                type="text"
                                                variant="standard"
                                                fullWidth
                                                // required={isEdit && requiredShowFields.contactName.required}
                                                disabled={!isEdit}
                                                helperText={errors?.attention?.message}
                                                InputLabelProps={!isEdit ? { shrink: true } : {}}
                                                inputProps={
                                                    !isEdit && (getValues('attention') === '' || getValues('attention') === undefined) ? {
                                                        value: '-'
                                                    } : {}}
                                            />
                                        )}
                                    />
                                }
                                {requiredShowFields.contactPhone.show &&
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={Validation.phoneNumberNotMandatory}
                                        render={({ field: { name, onChange, value } }) => (
                                            <MuiPhoneNumber
                                                id="phone"
                                                name={name}
                                                label={t('Contact_Person_Phone_Number')}
                                                defaultCountry={"in"}
                                                value={value}
                                                onChange={(value, country) => {
                                                    if (value != null && value !== undefined && value !== '' && value.slice(1) === country.dialCode) {
                                                        onChange('');
                                                    } else {
                                                        onChange(value);
                                                    }
                                                }}
                                                disabled={!isEdit}
                                                disableDropdown={!isEdit}
                                                helperText={errors?.phone?.message}
                                                autoFormat={false} fullWidth
                                                // required={isEdit && requiredShowFields.contactPhone.required}
                                                variant="standard"
                                                countryCodeEditable={false}
                                                inputProps={
                                                    !isEdit && (getValues('phone') === '' || getValues('phone') === undefined) ? {
                                                        value: '-'
                                                    } : {}}
                                                InputLabelProps={!isEdit ? { shrink: true } : {}}
                                            />
                                        )}
                                    />}
                                {requiredShowFields.contactEmail.show &&
                                    <Controller
                                        name="email"
                                        control={control}
                                        // rules={[]}
                                        render={({ field: { name, onChange, value } }) => (
                                            <TextField
                                                name={name}
                                                id="email"
                                                label={t('Contact_Person_Email')}
                                                type="text"
                                                onChange={onChange}
                                                value={value}
                                                variant="standard"
                                                fullWidth
                                                // required={isEdit && requiredShowFields.contactEmail.required}
                                                disabled={!isEdit}
                                                helperText={errors?.email?.message}
                                                inputProps={
                                                    !isEdit && (getValues('email') === '' || getValues('email') === undefined) ? {
                                                        value: '-'
                                                    } : {}}
                                                InputLabelProps={!isEdit ? { shrink: true } : {}}
                                            />
                                        )}
                                    />
                                }
                            </Stack>
                            {requiredShowFields.address.show &&
                                <Fragment>
                                    <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                                        alignItems={errors?.addressLine1?.message || errors?.addressLine2?.message ? '' : 'end'}>
                                        <Controller
                                            name="addressLine1"
                                            control={control}
                                            rules={requiredShowFields.address.required ? { ...Validation.addressField, ...Validation.addressLine } : Validation.addressLineNotMandatory}
                                            render={({ field: { name, onChange, value, ref } }) => (
                                                <TextField
                                                    name={name}
                                                    id="address_line_1"
                                                    label={t('Address_Line_1')}
                                                    type="text"
                                                    onChange={(event) => {
                                                        let value = event.target.value.trimStart();
                                                        if (value.length <= 250) {
                                                            onChange(value);
                                                        }
                                                    }}
                                                    value={value}
                                                    variant="standard"
                                                    fullWidth multiline
                                                    required={isEdit && requiredShowFields.address.required}
                                                    disabled={!isEdit}
                                                    helperText={errors?.addressLine1?.message}
                                                    placeholder='House No, Street'
                                                    InputLabelProps={!isEdit ? { shrink: true } : {}}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="addressLine2"
                                            control={control}
                                            rules={Validation.addressLineNotMandatory}
                                            render={({ field: { name, onChange, value, ref } }) => (
                                                <TextField
                                                    name={name}
                                                    id="address_line_2"
                                                    label={t('Address_Line_2')}
                                                    type="text"
                                                    onChange={(event) => {
                                                        let value = event.target.value.trimStart();
                                                        if (value.length <= 250) {
                                                            onChange(value);
                                                        }
                                                    }}
                                                    value={value}
                                                    variant="standard"
                                                    fullWidth multiline
                                                    disabled={!isEdit}
                                                    helperText={errors?.addressLine2?.message}
                                                    placeholder='Flat / Apartment / Suite, Taluk, etc.,'
                                                    inputProps={
                                                        !isEdit && (getValues('addressLine2') === '' || getValues('addressLine2') === undefined) ? {
                                                            value: '-'
                                                        } : {}}
                                                    InputLabelProps={!isEdit ? { shrink: true } : {}}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}>
                                        <AddressAutocompleteControl
                                            errors={errors}
                                            name='country'
                                            control={control}
                                            rules={requiredShowFields.address.required ? Validation.addressField : []}
                                            required={isEdit && requiredShowFields.address.required}
                                            id="country"
                                            label={t('Country')}
                                            isEdit={isEdit}
                                            readOnly={true}
                                            freesolo={false}
                                            options={countries?.filter((option) => option.name?.trim() != '').map((option) => option.name)}
                                            loading={countryLoading}
                                            addressId={getValues('edgeId')}
                                            getValues={getValues}
                                            setCountryReason={setCountryReason}
                                            setValue={setValue}
                                            clearErrors={clearErrors}
                                        />
                                        <Controller
                                            name='postalCode'
                                            control={control}
                                            rules={requiredShowFields.address.required ? { ...Validation.addressField, ...CountryPincodeRegex[countryIso2] } : CountryPincodeRegex[countryIso2] }
                                            render={({ field: { name, onChange, value, ref }, fieldState: { error } }) => (
                                                <TextField
                                                    name={name}
                                                    required={isEdit && requiredShowFields.address.required}
                                                    id="postalCode"
                                                    label={t('Pin_Code')}
                                                    // type="number"
                                                    onChange={onChange}
                                                    value={value}
                                                    variant="standard"
                                                    fullWidth
                                                    disabled={!isEdit}
                                                    helperText={error?.message}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                        {/* <AddressAutocompleteControl
                                            errors={errors}
                                            name='postalCode'
                                            control={control}
                                            rules={requiredShowFields.address.required ? { ...Validation.addressField, ...Validation.indiaPincode } : Validation.indiaPincode}
                                            required={isEdit && requiredShowFields.address.required}
                                            id="postalCode"
                                            label={t('Pin_Code')}
                                            isEdit={isEdit}
                                            readOnly={false}
                                            freesolo={true}
                                            options={postalCodes?.filter((option) => option.postalCode?.trim() != '').map((option) => option.postalCode)}
                                            loading={pinCodeFetchLoading}
                                            addressId={getValues('edgeId')}
                                            getValues={getValues}
                                            handleOnChangeCall={handleClearUseState}
                                        /> */}
                                        <AddressAutocompleteControl
                                            errors={errors}
                                            freesolo={false}
                                            name='state'
                                            control={control}
                                            rules={requiredShowFields.address.required ? Validation.addressField : []}
                                            id="state"
                                            label={t('State')}
                                            isEdit={isEdit}
                                            readOnly={false}
                                            required={isEdit && requiredShowFields.address.required}
                                            options={states?.filter((option) => option.name?.trim() != '').map((option) => option.name)}
                                            // loading={stateFetchLoading || autofillLoading}
                                            loading={stateLoading}
                                            addressId={getValues('edgeId')}
                                            getValues={getValues}
                                            handleOnChangeCall={handlePincode}
                                        />
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} spacing={2}>
                                        <AddressAutocompleteControl
                                            errors={errors}
                                            freesolo={true}
                                            name='cityTown'
                                            control={control}
                                            rules={requiredShowFields.address.required ? Validation.addressField : []}
                                            id="cityTown"
                                            label={t('City_Town_District')}
                                            isEdit={isEdit}
                                            readOnly={false}
                                            required={isEdit && requiredShowFields.address.required}
                                            options={cities?.filter((option) => option.name?.trim() != '').map((option) => option.name)}
                                            // loading={cityFetchLoading || autofillLoading}
                                            loading={cityLoading}
                                            addressId={getValues('edgeId')}
                                            getValues={getValues}
                                            handleOnChangeCall={handlePincode}
                                        />
                                        <Controller
                                            name="landmark"
                                            control={control}
                                            rules={Validation.addressLineNotMandatory}
                                            render={({ field: { name, onChange, value, ref } }) => (
                                                <TextField
                                                    name={name}
                                                    id="landmark"
                                                    label={t('Landmark')}
                                                    type="text"
                                                    onChange={onChange}
                                                    value={value}
                                                    variant="standard"
                                                    fullWidth multiline
                                                    disabled={!isEdit}
                                                    helperText={errors?.landmark?.message}
                                                    placeholder='Flat / Apartment / Suite, Taluk, etc.,'
                                                    inputProps={
                                                        !isEdit && (getValues('landmark') === '' || getValues('landmark') === undefined) ? {
                                                            value: '-'
                                                        } : {}}
                                                    InputLabelProps={!isEdit ? { shrink: true } : {}}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Fragment>
                            }
                        </Stack>
                    </AuctionFieldSet>
                </Stack>
            </Box >
        </div>
    )
}

export default AddressDetail
