import { Table, TableContainer, Typography } from '@mui/material';
import { Stack } from '@mui/material';
import React from 'react';
import FileTableBody from './mui-table/FileTableBody';
import FileTableHeader from './mui-table/FileTableHeader';

/* Data key should match table header id*/
function createData(file_name, LastModified, population, size) {
    return { file_name, LastModified, population, size };
}

const FileTable = (props) => {

    const { columns, rows, loading, rowsPerPage, page } = props

    /* column id should match key of each cell of table body to map */
    return (
        <div>
            <TableContainer sx={{ maxHeight: '75vh', minHeight: rows.length > 0 ? '' : 200 }}>
                <Table stickyHeader aria-label="sticky table">
                    <FileTableHeader columns={columns} />
                    {rows.length != 0 &&
                        <FileTableBody tableRows={rows} columns={columns} rowsPerPage={rowsPerPage} page={page} />
                    }
                </Table>
                {rows.length === 0 && !loading && <Stack id="files-no-record" justifyContent='center' alignItems='center' margin='3rem'>
                    <img id="nodocument" src={process.env.PUBLIC_URL + '/images/no-document.svg'} style={{ width: '8%' }} alt="NoDocumentImage" />
                    <Typography color='textSecondary'>No Files at the moment</Typography>
                </Stack>}
            </TableContainer>
        </div>
    )
}

export default FileTable;

