import React, { 
    useState, 
    useContext, 
    useEffect 
}                               from 'react';
import { 
    Container,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography
}                               from '@mui/material';
import { makeStyles }           from '@mui/styles';
import { useTranslation }       from 'react-i18next';
import ItemDetail               from '../item-detail/ItemDetail';
import { useForm }              from "react-hook-form";
import ItemBidDetail            from '../item-detail/ItemBidDetail';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import { 
    AuctionFieldSet, 
    AuctionLengend 
}                               from '../../authentication/AuthStyle';
import Address                  from '../../profile/address/Address'; 
import { MannerOfSale }         from '../../common/StaticVariableDeclaration';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '4px !important'
    },
    paper: {
        borderRadius: '0px',
        overflow: 'scroll'
    },
    container: {
        margin: '10px'
    },
    saveBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

export const UpdateItem = ({auction_id, item, onClose, canEdit}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const auctionContext = useContext(AuctionDetailContext);
    const { register: itemDetailRegister, control: itemDetailControl, getValues: getItemDetailValues, 
        setValue: setItemDetailsValues, trigger: itemDetailsTrigger,
        formState: { errors: itemDetailError, isValid: itemDetailIsValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            id: null,
            itemRefNo: '',
            itemName: '',
            itemType: [],
            quantity: 0,
            description: '',
            unit: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',
            cityTown: '',
            postalCode: '',
            state: '',
            country: 'India',
            attention: '',
            phone: '',
            addressType: ''
        }
    });

    const { register: itemBidDetailRegister, getValues: getItemBidDetailValues, 
            setValue: setItemBidDetailsValues, control: itemDetailBidControl, trigger: itemBidDetailTrigger,
            formState: { errors: itemBidDetailError, isValid: itemBidDetailIsValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            reservePrice: 0,
            emdAmount: 0,
            bidStartPrice: 0,
            incrementalAmount: 0,
            autoExtension: false,
            autoExtensionMode: '',
            extendTimeWhenBidReceivedInLastMinute: 0,
            extendTimeBy: 0,
            incrementalAmountDuringExtension: 0,
            noOfExtension: 0,
            lastDateForEmd: null,
            startTime: null,
            endTime: null
        }
    });

    useEffect( () => {
        setItemDetailsValues('id', item.id);
        setItemDetailsValues('itemName', item.itemName);
        setItemDetailsValues('itemRefNo', item.itemRefNo);
        setItemDetailsValues('description', item.description);
        setItemDetailsValues('itemType', item.itemType ? item.itemType : []);
        setItemDetailsValues('quantity', item.quantity);
        setItemDetailsValues('unit', item.unit ? item.unit : '');
    }, [])

    const handleClose = (msg) => {
        setOpen(false);
        onClose(msg, canEdit);
    }

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' disableEscapeKeyDown>
                <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                    <Typography id="add-item-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                        {t('Item_Details')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{width: '100%' }}>
                                <ItemDetail register={itemDetailRegister} errors={itemDetailError}
                                    setValue={setItemDetailsValues} control={itemDetailControl}
                                    getValues={getItemDetailValues} trigger={itemDetailsTrigger}
                                    isNew={false} auction_id={auction_id} getItemBidDetailValues={getItemBidDetailValues}
                                    isValidForm={itemDetailIsValid} canEdit={canEdit}/>
                                <br/>
                                {auctionContext.auctionGetDetail.mannerOfSale === MannerOfSale.item && <ItemBidDetail auction_id={auction_id} register={itemBidDetailRegister} errors={itemBidDetailError}
                                    setValue={setItemBidDetailsValues} control={itemDetailBidControl}
                                    getValues={getItemBidDetailValues} trigger={itemBidDetailTrigger}
                                    auctionStartDate={auctionContext ? new Date(auctionContext.auctionGetDetail.startTime) : null}
                                    auctionEndDate={auctionContext ? new Date(auctionContext.auctionGetDetail.endTime) : null}
                                    isNew={false} getItemValues={getItemDetailValues}
                                    isValidForm={itemBidDetailIsValid} canEdit={canEdit}/>}
                                <br/>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <AuctionFieldSet>
                                            <AuctionLengend align="left"><strong>{t('Address')}</strong></AuctionLengend>
                                            {getItemDetailValues('id') && <Address isLoginUser={false} sourceId={getItemDetailValues('id')} address={[item.address]}
                                                    hasPermission={canEdit} allowMultipleAddress={false}/>}
                                        </AuctionFieldSet>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12}>
                        <Button id='cancel' size="small" variant="outlined" className={classes.saveBtn} onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Container>
    )
}