import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/AddOutlined';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
     Divider, IconButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import GraphQLErrors from '../../common/GraphQLErrors';
import { LIST_EMAIL } from '../../GraphQL/Queries';
import EmailRow from './EmailRow';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    }

}))

const ListEmailModal = ({personId, openPopup, setClose, isView, emails}) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [emailList, setEmailList] = useState([]);
    const [showPlusButton, setShowPlusButtom] = useState(true);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [listEmail, {loading: listingEmail, error: errorOnListingEmail, refetch}] = 
            useLazyQuery(LIST_EMAIL, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if(data.listEmail != null) {
                setEmailList([]);
                data.listEmail.map(phone => {
                    setEmailList(prevState => ([
                        ...prevState,
                        {
                            id: phone.id,
                            email: phone.email,
                            preferred: phone.preferred
                        }
                    ]))
                })
            }
        }
    })

    const addEmail = () => {
        setShowPlusButtom(false);
        setEmailList(
            prevState => ([
                {
                    id: null,
                    email: '',
                    preferred: false,
                    isNew: true
                },
                ...prevState,
            ])
        )
    } 

    const setCancel = (isCancel) => {
        setShowPlusButtom(true);
        listEmail({
            variables: {
                personId: personId,
            }
        })
    }

    const onSuccess = (msg) => {
        if(personId != null) {
            listEmail({
                variables: {
                    personId: personId,
                }
            })
        }
        setShowPlusButtom(true);
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    useEffect(() => {
        if(emails) {
            emails.map(email => {
                setEmailList(prevState => ([
                    ...prevState,
                    {
                        id: email.id,
                        email: email.email,
                        preferred: email.preferred,
                        isNew: false
                    }]
                ))
            })
        }
    }, [])

    // useEffect(() => {
    //     if(personId != null) {
    //         listEmail({
    //             variables: {
    //                 personId: personId,
    //             }
    //         })
    //     }
    // },[personId])

    const handleCloseModal = () => {
        setClose(false);
    }

    return (
        <Dialog open={openPopup} maxWidth='false'>
        <div style={{width: 430}}>
            <DialogTitle style={{padding: '10px 15px'}}>{t('Email')}</DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: '0px 15px 10px 15px !important', maxHeight: '400px !important',  overflow: 'auto'}}>
            {!isView && <Tooltip title={t("Add")}> 
                <IconButton id="add-email-modal" aria-label="add" size="small" color="primary" style={{float: 'right'}} disabled={!showPlusButton}>
                    <AddIcon onClick={addEmail} />
                </IconButton>
            </Tooltip>}
            <TableContainer component={Paper} style={{marginTop: isView ? "15px" : "0px"}}>
                <Table aria-label="email list table" size="small">
                    <TableHead style={{backgroundColor: '#dae2ef'}}>
                    <TableRow>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '53% !important'}}>{t('Email_Id')}</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '15% !important'}}>{t('Prefered')}</TableCell>
                        {!isView && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{width: '30% !important'}}>{t('Actions')}</TableCell>}
                    </TableRow>
                    </TableHead>
                    <TableBody id="email-list-modal">
                        {emailList.length === 0 && 
                            <TableRow hover>
                                <TableCell className={`${classes.tableCell}`} colSpan={3}>{t('No_Record_Found')}</TableCell>
                            </TableRow>
                        }
                        {emailList.length > 0 && emailList.map((email, index) => {
                            return (
                                <EmailRow index={index} key={email.id + index} email={email} setCancel={setCancel}
                                    personId={personId} refetch={refetch} onSuccess={onSuccess} isView={isView}/>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={handleCloseModal} id="cancel" variant="outlined" 
                    style={{float: 'right'}} >{t('Close')}</Button>
                </DialogActions>
            </div>
            {listingEmail && <AuctionLoader show={listingEmail} invisible={false} />}
            {errorOnListingEmail && <GraphQLErrors error={errorOnListingEmail} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </Dialog>
    )
}

export default ListEmailModal;