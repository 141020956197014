import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller } from "react-hook-form";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { textAlign } from '@mui/system';

export const DateTimePickerControl = (props) => {
    const { errors, control, name, rules, label, mandatory, minDateTime, maxDateTime, disablePastDate,
        disabled, trigger, clearable, setValue, setDateValidation } = props;
    const { t } = useTranslation();
    const [validationError, setValidationError] = useState(null);

    const clearInput = () => {
        if(setValue) {
            setValue(name, null);
        }
        if(trigger) {
            trigger();
        }
    }

    return (
        <Controller
            errors={errors}
            control={control}
            name={name}
            rules={rules}
            defaultValue={null}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState }) => {
                return <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        OpenPickerButtonProps={{id: `${name}-picker`}}
                        disabled={disabled}
                        inputRef={ref}
                        minDateTime={minDateTime}
                        maxDateTime={maxDateTime}
                        closeOnSelect
                        ampmInClock
                        disableIgnoringDatePartForTimeValidation
                        disablePast={disablePastDate ? disablePastDate : false}
                        showToolbar
                        timeIcon={<AccessTimeIcon />}
                        inputFormat="dd/MM/yyyy hh:mm a"
                        renderInput={(props) =>
                            <React.Fragment>
                                { clearable && value != null && <span style={{ width: '50px', float: 'right', textAlign: 'end', paddingTop: '10px'}}>
                                    <IconButton id={`${name}-clear-date`}>
                                        <Tooltip title={t('Clear')} onClick={(event) => clearInput(event, document)}>
                                            <CloseIcon color='primary' />
                                        </Tooltip>
                                    </IconButton>
                                </span>}
                                <TextField
                                    id={name}
                                    required={mandatory}
                                    sx={{ width: (clearable && value != null) ? 'calc(100% - 50px)' : '100%'}}
                                    variant='standard'
                                    // helperText={
                                    //     (error && error.message) ||
                                    //     (validationError == 'minDate' && (minDateTime == null ?  t('Invalid_Date')  : (t('Min_Date') + " " + t('Is') + " " + format(new Date(minDateTime), 'dd MMM yyyy hh:mm a')))) ||
                                    //     (validationError == 'maxDate' && (maxDateTime == null ?  t('Invalid_Date')  : (t('Max_Date') + " " + t('Is') + " " + format(new Date(maxDateTime), 'dd MMM yyyy hh:mm a')))) ||
                                    //     (validationError == 'minTime' && (minDateTime == null ?  t('Invalid_Date')  : (t('Min_Date') + " " + t('Is') + " " + format(new Date(minDateTime), 'dd MMM yyyy hh:mm a')))) ||
                                    //     (validationError == 'maxTime' && (maxDateTime == null ?  t('Invalid_Date')  : (t('Max_Date') + " " + t('Is') + " " + format(new Date(maxDateTime), 'dd MMM yyyy hh:mm a')))) ||
                                    //     ((validationError == 'invalidDate' || validationError == 'disablePast') && t('Invalid_Date'))
                                    // }
                                    helperText={
                                        (error && error.message) ||
                                        (validationError == 'minDate' && ((minDateTime instanceof Date && !isNaN(minDateTime)) ? (t('Min_Date') + " " + t('Is') + " " + format(new Date(minDateTime), 'dd MMM yyyy hh:mm a')) : t('Invalid_Date'))) ||
                                        (validationError == 'maxDate' && ((maxDateTime instanceof Date && !isNaN(maxDateTime)) ? (t('Max_Date') + " " + t('Is') + " " + format(new Date(maxDateTime), 'dd MMM yyyy hh:mm a')) : t('Invalid_Date'))) ||
                                        (validationError == 'minTime' && ((minDateTime instanceof Date && !isNaN(minDateTime)) ? (t('Min_Date') + " " + t('Is') + " " + format(new Date(minDateTime), 'dd MMM yyyy hh:mm a')) : t('Invalid_Date'))) ||
                                        (validationError == 'maxTime' && ((maxDateTime instanceof Date && !isNaN(maxDateTime)) ? (t('Max_Date') + " " + t('Is') + " " + format(new Date(maxDateTime), 'dd MMM yyyy hh:mm a')) : t('Invalid_Date'))) ||
                                        (validationError == 'disablePast' && t('Past_Date_Is_Not_Allowed')) ||
                                        (validationError == 'invalidDate' && t('Invalid_Date'))
                                    }
                                    {...props}
                                    // inputProps={{...props.inputProps, readOnly: true}}
                                    inputProps={{...props.inputProps}}
                                />
                            </React.Fragment>
                        }
                        label={label}
                        value={value}
                        onChange={(data) => {
                            onChange(data);
                            if(trigger) {
                                trigger();
                            }
                        }}
                        onError={ (reason) => {
                            if(reason != null) {
                                setValidationError(reason);
                                if(setDateValidation) {
                                    setDateValidation(prev => (prev.length > 0 ?  [...prev, name] : [name]));
                                }
                            } else {
                                setValidationError(null);
                                if(setDateValidation) {
                                    setDateValidation((prev) => [...prev.filter(data => data != name)]);
                                }
                            }
                            
                        }}
                    />
                </LocalizationProvider>
            }}
        />
    );
}