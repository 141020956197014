import { useMutation } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Checkbox, IconButton, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import GraphQLErrors from '../../common/GraphQLErrors';
import { Validation } from '../../common/Validation';
import { ADD_EMAIL, DELETE_EMAIL, UPDATE_EMAIL } from '../../GraphQL/Mutation';


const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    inputFiledPadding: {
        "& #email-Id": {
            padding: '0px !important'
        }
    },
    tableRow: {
        verticalAlign: 'initial !important'
    }
}))

const EmailRow = (props) => {

    const {
        email, personId, refetch, onSuccess, setCancel, isView, index
    } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(email.isNew ? email.isNew : false);
    const [ispreferred, setIsPreferred] = useState(false);

    const { register, trigger, getValues, setValue, control, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        defaultValues: {}
    })

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [addEmail, { loading: addingEmail, error: errorOnAddingEmail }] =
        useMutation(ADD_EMAIL, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.addEmail != null) {
                    setIsNew(false);
                    refetch();
                    onSuccess({ show: true, message: t('Email_Added_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const [updateEmail, { loading: updatingEmail, error: errorOnUpdatingEmail }] =
        useMutation(UPDATE_EMAIL, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateEmail != null) {
                    setIsNew(false);
                    refetch();
                    onSuccess({ show: true, message: t('Email_Updated_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            }
        })

    const [deleteEmail, { loading: deletingEmail, error: errorOnDeletingEmail }] =
        useMutation(DELETE_EMAIL, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.deleteEmail) {
                    onSuccess({ show: true, message: t('Email_Deleted_Successfully'), severity: 'success' });
                    setCancel(true);
                }
            },
        })

    const handleEdit = (selectedEmailDetail) => {
        if (selectedEmailDetail) {
            setIsEdit(true);
            setValue('id', selectedEmailDetail.id);
            setValue('email', selectedEmailDetail.email);
            setIsPreferred(selectedEmailDetail.preferred);
            trigger();
        }
    }

    const handlesave = () => {
        if (isNew) {
            addEmail({
                variables: {
                    personId: personId,
                    emailInput: {
                        id: null,
                        email: getValues('email'),
                        preferred: ispreferred
                    }
                }
            })
        } else {
            updateEmail({
                variables: {
                    personId: personId,
                    emailInput: {
                        id: getValues('id'),
                        email: getValues('email'),
                        preferred: ispreferred
                    }
                }
            })
        }
    }

    const deleteConfirmation = (selectedId) => {
        setSeekConfirmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Email_Id'),
            onAgree: () => handleDelete(selectedId),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const handleDelete = (selectedId) => {
        resetSeekConfirmation();
        if (selectedId) {
            deleteEmail({
                variables: {
                    personId: personId,
                    id: selectedId
                }
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const cancel = () => {
        setCancel(true);
    }

    const handlePreferredChange = (event) => {
        setIsPreferred(event.target.checked)
    }

    return (
        <Fragment>
            <TableRow id={`email${index}`} className={classes.tableRow}>
                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                    {(!isNew && !isEdit) && <Tooltip title={email.email}><div style={{ width: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{email.email}</div></Tooltip>}
                    {(isEdit || isNew) &&
                        <TextField id="email-Id" fullWidth className={classes.inputFiledPadding}
                            type="text" variant='outlined'
                            placeholder='Ex. test@gmail.com'
                            helperText={errors["email"] && errors["email"].message}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 33,
                                    padding: '5px'
                                }
                            }}
                            {...register("email", Validation.email)}
                        />}
                </TableCell>
                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                    {(isEdit || isNew) && <Checkbox size="small" id="preferred" checked={ispreferred} onClick={handlePreferredChange}></Checkbox>}
                    {(!isNew && !isEdit) && (email.preferred ? <CheckIcon style={{ fontSize: '1.25rem' }} /> : '-')}
                </TableCell>
                {!isView && <TableCell align="center">
                    {(!isEdit && !isNew) && <Tooltip title={t("Edit")}>
                        <IconButton id={`email-edit${index}`} aria-label="edit" size="small" color="primary" onClick={() => handleEdit(email)} >
                            <EditIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(!isEdit && !isNew) && <Tooltip title={t("Delete")}>
                        <IconButton id={`email-delete${index}`} aria-label="delete" size="small" color="secondary" onClick={() => deleteConfirmation(email.id)} >
                            <DeleteIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(isEdit || isNew) && <Tooltip title={t("Save")}>
                        <IconButton id={`email-save${index}`} aria-label="save" size="small" style={{ color: 'green' }}
                            onClick={handlesave} disabled={!isValid}>
                            <DoneIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                    {(isEdit || isNew) && <Tooltip title={t("Cancel")}>
                        <IconButton id={`email-cancel${index}`} aria-label="cancel" size="small" color="secondary" onClick={cancel}>
                            <ClearIcon style={{ fontSize: '1.25rem' }} />
                        </IconButton>
                    </Tooltip>}
                </TableCell>}
            </TableRow>
            {(addingEmail || updatingEmail || deletingEmail) && <AuctionLoader show={(addingEmail
                || updatingEmail || deletingEmail)} invisible={false} />}
            {(errorOnAddingEmail || errorOnUpdatingEmail || errorOnDeletingEmail) &&
                <GraphQLErrors error={(errorOnAddingEmail || errorOnUpdatingEmail || errorOnDeletingEmail)} show={false} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </Fragment>
    )
}

export default EmailRow