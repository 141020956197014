import React from 'react';
import AuctionSnackBar from './AuctionSnackBar';
import ErrorMsg from '../common/ErrorMsg';

const GraphQLErrors = (props) => {
    let data;
    if (props.error.networkError) {
        data = `${props.error.networkError}`
    }
    return (
        <ErrorMsg>
            {
                props.error.networkError ?
                    <AuctionSnackBar show={true} message={data} severity="error" />
                    : props.error.graphQLErrors?.map(e =>
                        <AuctionSnackBar key={e} show={true} message={e.message} severity="error" />)
            }
        </ErrorMsg>
    );
}

export default GraphQLErrors;
