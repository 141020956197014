import React, { 
    useState,
    useContext, 
    Fragment,
    useEffect
}                               from 'react';
import { NumericFormat }        from 'react-number-format';
import { makeStyles }           from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Grid,
    Button,
    Paper, Chip, Stack, IconButton, Tooltip
}                               from '@mui/material';
import ExpandMoreIcon           from '@mui/icons-material/ExpandMore';
import ItemList                 from '../../auction-item/item-list/ItemList';
import { useTranslation }       from 'react-i18next';
import UpdateLot                from '../lot-detail/UpdateLot';
import { 
    useMutation,
    useLazyQuery 
}                               from '@apollo/client';
import { DELETE_LOT }           from '../../GraphQL/Mutation';
import { 
    LIST_LOTS,
    LIST_ITEM_LOT_FILES,
    GET_SELLER_DETAILS
}                               from '../../GraphQL/Queries';
import AuctionConfirmDialog     from '../../common/AuctionConfirmDialog';
import LotImagesModal           from '../lot-images/LotImagesModal';
import EditIcon                 from '@mui/icons-material/EditOutlined';
import DeleteIcon               from '@mui/icons-material/DeleteOutline';
import ImageIcon                from '@mui/icons-material/ImageOutlined';
import DescriptionIcon          from '@mui/icons-material/Description';
import { UserContext }          from "../../common/context/UserContext";
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import { Roles, MannerOfSale, AutoExtensionMode, AddressType }  from '../../common/StaticVariableDeclaration'; 
import AuctionLoader            from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors            from '../../common/GraphQLErrors';
import AuctionSnackBar          from '../../common/AuctionSnackBar';
import FileList                 from '../../file-upload-download/FileList';
import { format }               from 'date-fns';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ScheduleRoundedIcon      from '@mui/icons-material/ScheduleRounded';
import LotAddressModal          from '../lot-detail/LotAddressModal';
import LocationOnIcon           from '@mui/icons-material/LocationOn';
import ItemImagesDetail from '../../auction-detail-image/ItemImagesDetail';
import LotImages from '../lot-images/LotImages';
import Address from '../../profile/address/Address';
import { DisplayLotChip } from '../../bidder/bid-process/bid-process-components/BidProcessComponents';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    dataHeader : {
        color: 'gray',
        marginBottom: '0px !important'
    },
    data: {
        fontWeight: 'bold'
    },
    headerBtn: {
        margin: '0px 4px 0px 4px !important'
    },
    expandedRowHeader : {
        background: 'rgba(17, 82, 147, 0.11) !important'
    },
    mainContent: {
        border: '0.1px solid gray',
        borderRadius: '6px',
        padding: '8px'
    },
    dataCell: {
        paddingBottom: '8px'
    },
    noImageIcons: {
        width: '11% !important',
        height: '11% !important',
        color: 'gray'
    },
    noImageText: {
        color: 'gray',
        fontSize: '2rem',
        textAlign: 'center'
    }
}))

const LotRow = ({lot, noOfLots, auction_id, mannerOfSale, canAdd, canDelete, canEdit, canUpload}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(noOfLots === 1 ? true : false);
    const [updateLot, setUpdateLot] = useState(false);
    const auctionContext = useContext(AuctionDetailContext).auctionGetDetail;
    const [showImageGalley, setShowImageGalley] = useState(false);
    const [showDocuments, setShowDocuments] = useState({
        documents: [],
        show: false
    });
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [showLotAddress, setShowLotAddress] = useState(false)
    const [addressLabel, setAddressLabel] = useState('Lot_Location')
    const [openAddress, setOpenAddress] = useState(false);
    const [sameAsSellerAddress, setSameAsSellerAddress] = useState(false);
    const [sellerAddress, setSellerAddress] = useState(null);

    const [deleteLot, {loading: deletingLot, error: errorOnDeletingLot}] = useMutation(DELETE_LOT, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteAuctionLot != null) {
                // console.log('deleteAuctionLot', data.deleteAuctionLot);
                showSuccessMsg({show: true, message: t('Lot_Deleted_Successfully'), severity: 'success'})
            }
        },
        refetchQueries: [LIST_LOTS]
    })

    const handleChange = (event, expanded) => {
        if (mannerOfSale !== MannerOfSale.goingConcern) {
            setExpanded(expanded);
        }
    }

    const updateLotDetails = (event) => {
        event.stopPropagation();
        setUpdateLot(true)
    }

    const deleteLotConfiration = (event) => {
        event.stopPropagation();
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete') + " " + lot.lotNumber + "?",
            onAgree: () => deleteLotDetails(),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const deleteLotDetails = () => {
        deleteLot({
            variables: {
                auctionId: auction_id,
                auctionLotId: lot.id
            }
        })
        resetSeekConfirmation();
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const showImages = (event) => {
        event.stopPropagation();
        setShowImageGalley(true)
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const getDocuments = (event) => {
        event.stopPropagation();
        setShowDocuments({
            documents: [],
            show: true
        })
    }

    const resetShowDocuments = (event) => {
        setShowDocuments({
            documents: [],
            show: false
        })
    }

    const showAddress = (event) => {
        event.stopPropagation();
        setShowLotAddress(true)
    }

    useEffect(() => {
        if(mannerOfSale === MannerOfSale.singleLot) {
            setAddressLabel(t('Lot_Location'));
        } else if(mannerOfSale === MannerOfSale.singleItem) {
            setAddressLabel(t('Item_Location'));
        } else if(mannerOfSale === MannerOfSale.goingConcern) {
            setAddressLabel(t('Lot_Location'));
        }
    }, [openAddress])

    const [getSellerDetails, {loading: fetchingSellerDetails, error: errorOnFetchingSellerDetails}] =
            useLazyQuery(GET_SELLER_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getSeller != null && data.getSeller.address.length > 0) {
                setSellerAddress(data.getSeller.address.find( (element) => { return element.addressType == AddressType.primaryContact }));
            }
        }
    })

    const AddAddress = () => {
        setSellerAddress(null)
        if(auctionContext.conductingFor.length > 0) {
            setSameAsSellerAddress(true);
        } else {
            setOpenAddress(true);
        } 
    }

    const AddSameAsSellerAddress = (isSameAsSeller) => {
        if(isSameAsSeller && auctionContext.conductingFor.length > 0) {
            getSellerDetails({
                variables: {
                    auctionId: auction_id,
                    userId: auctionContext.conductingFor[0].personId
                }
            })
        }
        setSameAsSellerAddress(false);
        setOpenAddress(true)
    }
    
    return (
        <div>
            {/* <Accordion style={{margin: '8px'}} defaultExpanded={expanded} onChange={handleChange}> */}
                {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="lot-content"
                    id="lot"
                    className={expanded ? classes.expandedRowHeader : ''}
                > */}
                    {!expanded && 
                    <Grid container>
                        {/* {mannerOfSale !== MannerOfSale.goingConcern && <Fragment>
                            <Grid item xs={2}> 
                                <Typography variant="subtitle2" gutterBottom className={classes.dataHeader}>{t('Lot_Number')}</Typography>
                                <Typography className={classes.data}>
                                    {lot.lotNumber ?
                                        lot.lotNumber
                                            :
                                        '-'
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="subtitle2" gutterBottom className={classes.dataHeader}>{t('Description')}</Typography>
                                <Typography className={classes.data}>
                                    {lot.description ? 
                                        lot.description
                                            : 
                                        '-'
                                    }
                                </Typography>
                            </Grid>
                        </Fragment>} */}
                        {/* <Grid item xs={2}> 
                            <Typography variant="subtitle2" gutterBottom className={classes.dataHeader}>{t('Reserve_Price')}</Typography>
                            <Typography className={classes.data}>
                                {lot.reservePrice ? 
                                    <NumericFormat value={lot.reservePrice} displayType={'text'} 
                                        thousandSeparator={true} thousandsGroupStyle="lakh"/>
                                        : 
                                    <span style={{textAlign: 'center'}}> - </span>
                                }
                            </Typography>
                        </Grid> */}
                    </Grid>}
                    {/* {expanded &&  */}
                        <Grid container direction="row" alignItems="center">
                             <Grid item xs={5}>
                                <LotImages auction_id={auction_id} lot_id={lot.id} lotAddress={lot.address}
                                    canUpload={canUpload} mannerOfSale={mannerOfSale !== MannerOfSale.goingConcern}/> 
                            </Grid>
                            { sameAsSellerAddress && <Grid item xs={7}>
                                <Stack spacing={2} direction='column'
                                    sx={{ padding: '10px', border: '1px solid orange', margin: '10px',
                                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                                        background: 'white',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Grid item xs={12} sx={{float: 'right'}}>
                                        <Tooltip title="Close" sx={{ float: 'right'}}>
                                            <IconButton
                                                id='close-icon'
                                                color="secondary"
                                                onClick={() => setSameAsSellerAddress(false)}>
                                                <Close color='error' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Stack spacing={2} direction='column' justifyContent='center' alignItems='center'>
                                        <Typography variant="subtitle1" gutterBottom sx={{ margin: '0px !important', textAlign: 'center'}}>{t('Lot_Address')}</Typography>
                                        <Button
                                            sx={{ textTransform: 'none !important', width: '200px' }}
                                            onClick={() => AddSameAsSellerAddress(true)}
                                            variant='contained'
                                            size='small'
                                        >
                                            {t('Same_As_Seller_Address')}
                                        </Button>
                                        <Button
                                            sx={{ marginBottom: '20px', textTransform: 'capitalize', width: '200px' }}
                                            onClick={() => AddSameAsSellerAddress(false)}
                                            variant='contained'
                                            size='small'
                                        >{t('New_Address')}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>}
                            {(openAddress && sellerAddress) &&
                                <Grid item xs={7}>
                                    <Address
                                        isLoginUser={false}
                                        sourceId={lot.id}
                                        hasPermission={canEdit}
                                        setOpenAddress={setOpenAddress}
                                        address={canEdit ? null : [lot.address]}
                                        allowMultipleAddress={false}
                                        newAddress={true}
                                        addressType={t('Lot_Location')}
                                        atlestOneMandatory={true}
                                        showCancel={true}
                                        aspectRatio={ "2.9 / 1.3"}
                                        newAddressDefaultData={sellerAddress}
                                    />
                                </Grid>
                            }
                            {(openAddress && !sellerAddress) &&
                                <Grid item xs={7}>
                                    <Address
                                        isLoginUser={false}
                                        sourceId={lot.id}
                                        hasPermission={canEdit}
                                        setOpenAddress={setOpenAddress}
                                        address={canEdit ? null : [lot.address]}
                                        allowMultipleAddress={false}
                                        newAddress={true}
                                        addressType={t('Lot_Location')}
                                        atlestOneMandatory={true}
                                        showCancel={true}
                                        aspectRatio={ "2.9 / 1.3"}
                                    />
                                </Grid>
                            }
                            {(!openAddress && lot.address != null) &&
                                <Grid item xs={7}>
                                    <Address
                                        isLoginUser={false}
                                        sourceId={lot.id}
                                        hasPermission={canEdit}
                                        setOpenAddress={setOpenAddress}
                                        address={canEdit ? null : [lot.address]}
                                        allowMultipleAddress={false}
                                        newAddress={true}
                                        addressType={t('Lot_Location')}
                                        atlestOneMandatory={true}
                                        showCancel={true}
                                        aspectRatio={ "2.9 / 1.3"}
                                    />
                                </Grid>
                            }
                           {(lot.address === null && !openAddress && !sameAsSellerAddress) && 
                                <Grid item xs={7}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center"
                                        style={{ aspectRatio: "2.9 / 1.3", background:'white',marginTop:'7px',borderRadius:'5px'}}>
                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <Chip sx={{ padding: '10px', marginBottom: '10px'  }} variant="outlined" color="warning" icon={<WarningTwoToneIcon />} label={t('Lot_Address_Mandatory')} />
                                            <br/>
                                            <AddLocationAltIcon className={classes.noImageIcons}/>
                                            <br/>
                                            <Typography className={classes.noImageText}>
                                                <div id="no-item-image">{t('No_Address_at_the_moment')}</div>
                                            </Typography>
                                            {canEdit && <Button onClick={AddAddress} variant='contained' size='small' startIcon={<AddLocationAltIcon />}>Add Address</Button>} 
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        {mannerOfSale !== MannerOfSale.goingConcern && <Grid container direction='row' style={{marginTop:'6px'}}>
                            <Fragment>
                                <Grid item xs={3}> 
                                    <DisplayLotChip title={t('Lot_Number')} value={lot.lotNumber ?lot.lotNumber : '-' } />
                                </Grid>
                                <Grid item xs={9}>
                                    <DisplayLotChip title={t('Description')} value={lot.description ?lot.description : '-' } />
                                </Grid>
                             </Fragment>
                        </Grid>}
                {/* <AccordionDetails> */}
                    <br/>
                    <ItemList isIndividualBid={false} auctionId={auction_id} lotId={lot.id} 
                        canAdd={canAdd} canEdit={canEdit} canDelete={canDelete} canUpload={canUpload}
                        multiItem={true}/>
                {/* </AccordionDetails> */}
            {/* </Accordion> */}
            {updateLot && <UpdateLot show={updateLot} lotDetails={lot} onClose={(event) => setUpdateLot(event)}
                auction_id={auction_id} onSuccess={showSuccessMsg} mannerOfSale={mannerOfSale}/>}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
            {/* {showImageGalley && <LotImagesModal auction_id={auction_id} lot_id={lot.id}
                show={showImageGalley} onClose={() => setShowImageGalley(false)} canUpload={canUpload}/>} */}
            {showDocuments.show && <FileList show={showDocuments.show} isMultiple={true} fileName={`${lot.id}/other documents`} 
                    files={showDocuments.documents} onClose={resetShowDocuments} auction_id={auction_id} canUpload={canUpload}
                    title={t('Lot_Files')}/>}
            {/* {showLotAddress && <LotAddressModal lotId={lot.id} show={showLotAddress} onClose={setShowLotAddress} canEdit={canEdit}
                title={addressLabel} lotAddress={canEdit ? null : lot.address}/>} */}
            {(deletingLot || fetchingSellerDetails) && <AuctionLoader show={deletingLot || fetchingSellerDetails} invisible={false} />}
            {(errorOnDeletingLot || errorOnFetchingSellerDetails) && <GraphQLErrors error={errorOnDeletingLot || errorOnFetchingSellerDetails} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} /> }
        </div>
    )
}

export default LotRow;
