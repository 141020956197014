import React, { useState, useEffect } from 'react';
import {
    TableContainer, Paper,
    Table, TableHead, TableCell, TableBody,
    TableRow, BottomNavigation, Tooltip,
    Popover, MenuList, MenuItem, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { FileIcon } from '../../common/files-upload/FileIcon';
import { format } from 'date-fns';
import { LIST_BIDDER_DOCUMENTS, GET_PRE_SIGNED_URL, LIST_FILE_VERSIONS } from '../../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import AuctionPagination from '../../common/pagination/AuctionPagination';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FcDownload } from "react-icons/fc";
import axios from 'axios';
import { fileSizeConversion } from '../../common/FileSizeConversion';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';

const useStyles = makeStyles((theme) => ({
    paper: {
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '0px',
        border: "1px solid rgb(238, 238, 238)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        margin: "auto auto ",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 10px !important"
    },
    tableData: {
        color: theme.tableDataColor
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    statusText: {
        fontWeight: 'bold',
        fontSize: '0.7rem'
    },
    tableHeadRow: {
        backgroundColor: theme.tableHearderBackgroundColor
    },
    fileNameDivBlock: {
        width: '115px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto',
        float: 'left'
    },
    fileSizeClass: {
        fontSize: '12px',
        color: theme.fileSizeColor,
        marginTop: '2px',
        float: 'right'
    },
    fileTypeColorClass: {
        color: theme.fileTypeDisplayColor,
        margin: 'auto'
    }

}))

const BidderDocumentUploadList = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [bidderUploadDocuments, setBidderUploadDocuments] = useState([]);
    const [totalNumberOfDocuments, setTotalNumberOfDocuments] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [download, setDownload] = useState(false);
    const [message, setMessage] = React.useState({
        show: false,
        message: null,
        severity: null
    })
    const [anchorElTemplateDownalod, setAnchorElTemplateDownalod] = React.useState(null);
    const openTemplateDownalod = Boolean(anchorElTemplateDownalod);
    const templateDownalodId = 'template-download-popover'
    const [versionList, setVersionList] = useState([]);
    const [popOverEvent, setPopOverEvent] = React.useState(null);
    const [documentDetail, setDocumentDetail] = useState({});
    const [templateUri, setTemplateUri] = useState('');
    const [anchorElFileDownalod, setAnchorElFileDownalod] = React.useState(null);
    const openFileDownalod = Boolean(anchorElFileDownalod);
    const fileDownalodId = 'file-download-popover';

    const [listBidderUploadDocuments, { loading: fetchingRequiredDocuments, error: errorOnFetchingRequiredDocuments, refetch }] =
        useLazyQuery(LIST_BIDDER_DOCUMENTS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setBidderUploadDocuments([]);
                if (data.listBidderDocuments != null) {
                    data.listBidderDocuments.documents.map(uploadDocument => {
                        setBidderUploadDocuments(prevState => ([
                            ...prevState,
                            {
                                id: uploadDocument.id,
                                name: uploadDocument.name,
                                submitFormat: uploadDocument.submitFormat,
                                requiresDigitalSignature: uploadDocument.requiresDigitalSignature,
                                isMandatory: uploadDocument.isMandatory,
                                s3Uri: uploadDocument.s3Uri,
                                templateS3Uri: uploadDocument.templateS3Uri,
                                startTime: uploadDocument.startTime,
                                endTime: uploadDocument.endTime,
                                addedBy: uploadDocument.addedBy,
                                removedBy: uploadDocument.removedBy,
                                updatedBy: uploadDocument.updatedBy,
                                referenceNumber: uploadDocument.referenceNumber,
                                requiredDocumentBrief: uploadDocument.requiredDocumentBrief,
                                submittedDocumentBrief: uploadDocument.submittedDocumentBrief,
                                toSubmit: uploadDocument.toSubmit,
                                templateS3UriSize: fileSizeConversion(uploadDocument.templateS3UriSize),
                                s3UriSize: fileSizeConversion(uploadDocument.s3UriSize),
                                templateS3UriFileName: uploadDocument.templateS3UriFileName,
                                s3UriFileName: uploadDocument.s3UriFileName,
                                templateS3UriFileLastModifiedTime: uploadDocument.templateS3UriFileLastModifiedTime,
                                s3UriFileLastModifiedTime: uploadDocument.s3UriFileLastModifiedTime
                            }
                        ]))
                    })
                    setTotalNumberOfDocuments(data.listBidderDocuments.count);
                }
            }
        })

    if (page === 0) {
        setPage(1);
    }

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    useEffect(() => {
        if (props.selectedBidderId != null || props.selectedBidderId != undefined) {
            listBidderUploadDocuments({
                variables: {
                    auctionId: props.auctionId,
                    pagination: {
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    },
                    bidderId: Number(props.selectedBidderId)
                }
            })
        }
    }, [rowsPerPage, page, props.selectedBidderId])

    const handleDownloadTemplate = (event, document, isTemplate) => {
        setPopOverEvent(event.currentTarget);
        setDocumentDetail(document);
        setTemplateUri(isTemplate);
        let uri;
        if (isTemplate == 'Template_Download') {
            uri = document.templateS3Uri;
        } else {
            uri = document.s3Uri;
        }
        getListVersions({
            variables: {
                fileName: uri
            }
        });
    }

    const [getListVersions, { loading: loadingFileVersions, error: errorOnLoadingFileVersions }] = useLazyQuery(LIST_FILE_VERSIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            let uri;
            if (data.listFileVersions.length > 1) {
                if (templateUri == 'Template_Download') {
                    setAnchorElTemplateDownalod(popOverEvent);
                } else {
                    setAnchorElFileDownalod(popOverEvent);
                }
                setVersionList(data.listFileVersions)
            } else {
                if (templateUri == 'Template_Download') {
                    uri = documentDetail.templateS3Uri;
                } else {
                    uri = documentDetail.s3Uri;
                }
                setPopOverEvent(null);
                getFileDownloaded({
                    variables: {
                        presignedUrl: {
                            fileName: uri,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'GET',
                            versionId: '',
                            isVersion: false,
                        }
                    }
                })
            }
        }
    })

    const [getFileDownloaded, { loading: gettingPresignedUrl, error: errorOnGettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: preSignUrlData => {
            if (preSignUrlData.getPresignedUrl) {
                let subURL = preSignUrlData.getPresignedUrl.split("?")[0];
                let fileName = subURL.substring(subURL.lastIndexOf('/') + 1);
                axios({
                    url: preSignUrlData.getPresignedUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    setTimeout(() => {
                        setDownload(false)
                    }, 1000)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURI(fileName));
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ show: true, message: error, severity: 'error' })
                    setDownload(false)
                })
            }
        }
    })

    const handleCloseTemplateDownload = () => {
        setAnchorElTemplateDownalod(null);
    }

    const handleCloseFileDownload = () => {
        setAnchorElFileDownalod(null);
    }

    const handleVersionDownload = (event, version) => {
        getFileDownloaded({
            variables: {
                presignedUrl: {
                    fileName: version.key,
                    bucket: process.env.REACT_APP_S3_BUCKET,
                    httpMethod: 'GET',
                    versionId: version.versionId,
                    isVersion: false,
                }
            }
        })
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.paper}>
                <Table aria-label="bidder documents table" stickyHeader>
                    <TableHead className={`${classes.tableHeadRow}`}>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '16%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Document_Type_Accepted_File_Types")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '21%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Document_Brief")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center"
                            style={{ width: '8%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Mandatory")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center"
                            style={{ width: '9%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Digital_Sign")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center"
                            style={{ width: '20%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Template")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center"
                            style={{ width: '20%', borderRight: '1px solid #c8d6eb' }}>
                            {t("Uploaded_File")}
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '5%' }}>
                            {t("Reference_Number")}
                        </TableCell>
                    </TableHead>
                    <TableBody id="bidder-uploaded-doc-list-view">
                        {bidderUploadDocuments.length === 0 &&
                            <TableRow hover>
                                <TableCell id={`bidder-view-no-records-found-row`} colSpan={7} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                            </TableRow>
                        }
                        {bidderUploadDocuments.length > 0 && bidderUploadDocuments.map((document, index) => {
                            return (<TableRow key={document.id + index} id={`bidder-view-doc${index}`}>
                                <TableCell id={`bidder-view-doc-name${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.name}
                                    <p className={classes.fileTypeColorClass}>{document.submitFormat.length > 0 ? '[' + document.submitFormat.join(", ") + ']' : '-'}</p></TableCell>
                                <TableCell id={`bidder-view-doc-brief${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.requiredDocumentBrief ? document.requiredDocumentBrief : '-'}</TableCell>
                                <TableCell id={`bidder-view-doc-mandatory${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.isMandatory && <CheckIcon style={{ fontSize: '28px' }} />}</TableCell>
                                <TableCell id={`bidder-view-doc-digital-sign${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.requiresDigitalSignature && <CheckIcon style={{ fontSize: '28px' }} />}</TableCell>
                                <TableCell id={`bidder-view-doc-template${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                    <React.Fragment>
                                        {document.templateS3Uri == "" && <div>{'-'}</div>}
                                        {document.templateS3Uri != "" &&
                                            <div style={{ width: 'fit-content', float: 'left' }}>
                                                <div>
                                                    <div className={classes.fileNameDivBlock}>
                                                        <span style={{ position: 'relative', top: '4px' }}><FileIcon style={{ position: 'relative', top: '4px' }} fileName={document.templateS3UriFileName} fontSize={'1rem'}></FileIcon></span>
                                                        <Tooltip title={document.templateS3UriFileName}><i>{' ' + document.templateS3UriFileName}</i></Tooltip>
                                                    </div>
                                                    <span className={classes.fileSizeClass}>{'(' + document.templateS3UriSize + ')'}</span>
                                                </div>
                                                <p style={{ margin: 'auto', clear: 'both' }}><span style={{ height: '0.7rem !important', width: '0.8rem !important', position: 'relative', top: '3px' }}>
                                                    <CalendarMonthIcon style={{ fontSize: '17px' }} /></span>
                                                    {format(new Date(document.templateS3UriFileLastModifiedTime), ' dd MMM yyyy hh:mm a')}
                                                </p>
                                            </div>}
                                        {document.templateS3Uri != "" &&
                                            <React.Fragment>
                                                <Tooltip title={t('Download_Template')} arrow>
                                                    <span id={`bidder-view-doc-download-template`} style={{ float: 'right' }}><FcDownload style={{ fontSize: '20px', marginTop: '19px', cursor: 'pointer' }} onClick={(event) => handleDownloadTemplate(event, document, 'Template_Download')} /></span>
                                                </Tooltip>
                                                <Popover
                                                    id={templateDownalodId}
                                                    open={openTemplateDownalod}
                                                    anchorEl={anchorElTemplateDownalod}
                                                    onClose={handleCloseTemplateDownload}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuList id="split-button-menu">
                                                        {versionList.map((option, index) => (
                                                            <MenuItem key={index} id={"view-template-version" + index}
                                                                onClick={(event) => handleVersionDownload(event, option)}>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontWeight: index === 0 ? 600 : 500 }}>{`Version ${versionList.length - index}`}</Typography>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontSize: '14px', marginleft: '6px', marginBottom: '2px', fontWeight: index === 0 ? 600 : 500 }}>
                                                                    &nbsp;({format(new Date(option.lastModified), ' dd MMM yyyy hh:mm:ss a ')})</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Popover>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                </TableCell>
                                <TableCell id={`bidder-view-doc-uploaded-file${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{
                                    <React.Fragment>
                                        {document.s3Uri == null && <div>{'-'}</div>}
                                        {document.s3Uri != null &&
                                            <div style={{ width: 'fit-content', float: 'left' }}>
                                                <div>
                                                    <div className={classes.fileNameDivBlock}>
                                                        <span style={{ position: 'relative', top: '4px' }}><FileIcon style={{ position: 'relative', top: '4px' }} fileName={document.s3UriFileName} fontSize={'1rem'}></FileIcon></span>
                                                        <Tooltip title={document.s3UriFileName}><i>{' ' + document.s3UriFileName}</i></Tooltip>
                                                    </div>
                                                    <span className={classes.fileSizeClass}>{'(' + document.s3UriSize + ')'}</span>
                                                </div>
                                                <p style={{ margin: 'auto', clear: 'both' }}><span style={{ height: '0.7rem !important', width: '0.8rem !important', position: 'relative', top: '3px' }}>
                                                    <CalendarMonthIcon style={{ fontSize: '17px' }} /></span>
                                                    {format(new Date(document.s3UriFileLastModifiedTime), ' dd MMM yyyy hh:mm a')}
                                                </p>
                                            </div>}
                                        {document.s3Uri != null &&
                                            <React.Fragment>
                                                <Tooltip title={t('Download_Uploaded_File')} arrow>
                                                    <span id={`bidder-view-download-uploaded-file${index}`} style={{ float: 'right' }}><FcDownload aria-describedby={fileDownalodId} style={{ fontSize: '20px', marginTop: '19px', cursor: 'pointer' }} onClick={(event) => handleDownloadTemplate(event, document, 'File_Download')} /></span>
                                                </Tooltip>
                                                <Popover
                                                    id={fileDownalodId}
                                                    open={openFileDownalod}
                                                    anchorEl={anchorElFileDownalod}
                                                    onClose={handleCloseFileDownload}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuList id="split-button-menu">
                                                        {versionList.map((option, index) => (
                                                            <MenuItem key={index} id={"view-upload-file-version" + index}
                                                                onClick={(event) => handleVersionDownload(event, option)}>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontWeight: index === 0 ? 600 : 500 }}>{`Version ${versionList.length - index}`}</Typography>
                                                                <Typography color={index === 0 ? "primary" : "none"} style={{ fontSize: '14px', marginleft: '6px', marginBottom: '2px', fontWeight: index === 0 ? 600 : 500 }}>
                                                                    &nbsp;({format(new Date(option.lastModified), ' dd MMM yyyy hh:mm:ss a ')})</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Popover>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }</TableCell>
                                <TableCell id={`bidder-view-ref-number${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.referenceNumber ? document.referenceNumber : '-'}</TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
                {totalNumberOfDocuments > 0 &&
                    <BottomNavigation style={{ padding: '12px' }}>
                        <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={totalNumberOfDocuments}
                            onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
                    </BottomNavigation>
                }
            </TableContainer>
            {(fetchingRequiredDocuments || gettingPresignedUrl || loadingFileVersions) && <AuctionLoader show={fetchingRequiredDocuments || gettingPresignedUrl || loadingFileVersions} invisible={false} />}
            {(errorOnFetchingRequiredDocuments || errorOnGettingPresignedUrl || errorOnLoadingFileVersions) && <GraphQLErrors error={errorOnFetchingRequiredDocuments
                || errorOnGettingPresignedUrl || errorOnLoadingFileVersions} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar>}
        </React.Fragment>
    );
}

export default BidderDocumentUploadList;