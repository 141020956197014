import React from 'react';
import { BsFileEarmarkMusicFill, BsFileEarmarkTextFill, BsFillFileEarmarkDiffFill, BsFillFileEarmarkExcelFill, BsFillFileEarmarkFill, BsFillFileEarmarkImageFill, BsFillFileEarmarkMedicalFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkPlayFill, BsFillFileEarmarkWordFill, BsFillFileEarmarkZipFill } from 'react-icons/bs';


export const commonExtension = {
    PDF: 'pdf',
    EXCEL: 'xls',
    WORDS: 'docs',
    WORD: 'doc',
    ZIP: 'zip',
    TXT: 'txt',
    PNG: 'png',
    JPEG: 'jpeg',
    MP3: 'mp3',
    MP4: 'mp4'
}


export const FileIcon = ({ fileName, fontSize }) => {
    const iconFontSize = fontSize != undefined ? fontSize : '1.7rem';
    let fileExtension = (fileName !== null && fileName !== undefined )? fileName.split('.').pop() : '';
    switch (fileExtension) {
        case commonExtension.PDF: return <BsFillFileEarmarkPdfFill style={{ fontSize: iconFontSize, color: '#cc1616' }} />
        case commonExtension.WORD: case commonExtension.WORDS: return <BsFillFileEarmarkWordFill style={{ fontSize: iconFontSize, color: '#196bf9' }} />
        case commonExtension.EXCEL: return <BsFillFileEarmarkExcelFill style={{ fontSize: iconFontSize, color: '#047704' }} />
        case commonExtension.ZIP: return <BsFillFileEarmarkZipFill style={{ fontSize: iconFontSize, color: 'rgb(176 155 14)' }} />
        case commonExtension.PNG: case commonExtension.JPEG: return <BsFillFileEarmarkImageFill style={{ fontSize: iconFontSize, color: 'rgb(168 21 110)' }} />
        case commonExtension.MP3: return <BsFileEarmarkMusicFill style={{ fontSize: iconFontSize, color: 'rgb(199 29 128)' }} />
        case commonExtension.MP4: return <BsFillFileEarmarkPlayFill style={{ fontSize: iconFontSize, color: 'rgb(170 4 4)' }} />
        case commonExtension.TXT: return <BsFileEarmarkTextFill style={{ fontSize: iconFontSize, color: 'rgb(0 195 220)' }} />
        default: return <BsFillFileEarmarkMedicalFill style={{ fontSize: iconFontSize, color: 'rgb(147 5 181)' }} />
    }
}
