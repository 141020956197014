import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react'
import { ThemeContext } from '../../../common/context/ThemeContext';

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        position: 'absolute',
        borderRadius: '15px',

    },
    dialogTitle: {
        // background: theme.palette.type === "light" ? 'linear-gradient(90deg, rgb(4 78 162) 35%, rgb(255 255 255) 100%)' :
        //     'linear-gradient(90deg, rgb(255 255 255) 35%, rgb(127 171 255) 100%)',
        // color: theme.palette.type === "light" ? '#fff' : '#000000'
    },
    dialogContent: {
        padding: '0px !important',
    },
    cross: {
        color: 'white',
        background: '#e32929',
        boxShadow: '3px 3px 3px grey',
        borderRadius: '4px'
    },
}))

const BidProcessDialog = (props) => {
    const themeContext = useContext(ThemeContext);
    const { title, open, handleClose, component } = props;
    const classes = useStyles(themeContext.state.theme);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth scroll='paper'>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                {title}
                            </Typography>
                            <Tooltip title="Close">
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleClose()}>
                                    <Close color='error' />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.dialogContent}>
                {component}
            </DialogContent>
        </Dialog>
    )
}

export default BidProcessDialog
