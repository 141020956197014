import { useLazyQuery } from '@apollo/client';
import { ReportGmailerrorredOutlined, Timelapse } from '@mui/icons-material';
import { Card, Chip, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiAlarmClock, GiTrophyCup } from 'react-icons/gi';
import { AuctionDetailContext } from '../auction/auction-detail/AuctionDetail';
import { AuctionDetailMenuItems } from '../auction/auction-detail/AuctionDetailStaticVar';
import { AuctionFieldSet, AuctionLengend } from '../authentication/AuthStyle';
import AuctionTimer from '../bidder/bid-process/AuctionTimer';
import { DisplayInfoChip } from '../bidder/bid-process/bid-process-components/BidProcessComponents';
import { AuctionInfoCard } from '../bidder/bid-process/EnglishForwardBid';
import AuctionSnackBar from '../common/AuctionSnackBar';
import { UserContext } from '../common/context/UserContext';
import { AuctionStatus, AutoExtensionMode, MannerOfSale } from '../common/StaticVariableDeclaration';
import { GET_AUCTION } from '../GraphQL/Queries';
import ActiveBidderList from './ActiveBidderList';
import { CurrencyFormatter } from '../utils/Utils';

const useStyles = makeStyles((theme) => ({
    price: {
        fontWeight: '600 !important'
    },
    extension: {
        boxShadow: 'rgb(173 210 221 / 9%) 0px 13px 27px -5px, rgb(208 221 226) 0px 8px 16px -8px !important',
        border: '1px solid rgb(171 207 225)',
        padding: '5px 10px !important'
    },
    bidderViewIcon: {
        color: '#5b8f64',
    },
    idChip: {
        background: theme.palette.type === 'light' ? '#f4f4f8' : '#262626',
        padding: '0.1rem 0.4rem',
        borderRadius: '4px',
        width: 'fit-content'
    },
    "@keyframes flicker": {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0.2
        }
    },
    flicker: {
        animationName: "$flicker",
        animationDuration: "800ms",
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out"
    },
    fastFlicker: {
        animationName: "$flicker",
        animationDuration: "200ms",
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out"
    },
}))

export const DisplayInfoWithLabel = ({ labelId, label, descriptionId, description }) => {
    return (
        <Stack direction='row'>
            <Typography id={labelId} color='GrayText' variant='subtitle2' sx={{ width: { xs: '100px', sm: '100px', md: '130px', lg: '130px' }, fontWeight: 'Bold' }}>
                {label}
            </Typography>
            <Typography variant='subtitle2'>:&nbsp;</Typography>
            <Typography id={descriptionId} color='textSecondary' variant='subtitle2' sx={{ width: { xs: 'calc(100% - 100px)', sm: 'calc(100% - 100px)', md: 'calc(100% - 130px)', lg: 'calc(100% - 130px)' }, fontWeight: 'Bold' }}>
                {description}
            </Typography>
        </Stack>
    )
}

const AuctionHall = (props) => {
    const { auctionId, lotId, bidderId, itemName, endDate, activeBidders, setActiveBidders } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const auctionDetailContext = useContext(AuctionDetailContext);
    const [isClosed, setIsClosed] = useState( auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed || auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.cancelled)
    const userContext = useContext(UserContext).rootContext;
    const [extensionNumber, setExtensionNumber] = useState(0);
    const [h1Bidder, setH1Bidder] = useState()
    const [activeBidderCount, setActiveBidderCount] = useState(0)
    const [auctionLotInfo, setAuctionLotInfo] = useState();
    const [listTrigger, setListTrigger] = useState(1);
    const [showExtensionMessage, setShowExtensionMessage] = useState({
        show: false,
        message: '',
        severity: 'info'
    });
    const [bidHeading, setBidHeading] = useState({
        bidLabel: t('Auction'),
        bidDescription: t('Asset_Brief'),
        idLabel: t('Auction'),
        idDescription: '',
    })
    const [intervalId, setIntervalId] = useState(null);

    const [getAuctionDetail, { loading: getAuctionLoading, error: getAuctionError }] = useLazyQuery(GET_AUCTION, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: auctions => {
            if (auctions.getAuction != null) {
                auctionDetailContext.setAuctionGetDetail(auctions.getAuction);
                setListTrigger(prevState => prevState + 1);
            }
        },
        onError: (error) => {
            console.log('getAuctionDetail -> NETWORK error :', error);
        }
    });

    const handleTerminated = () => {
        clearInterval(intervalId);
        setTimeout(() => { 
            getAuctionDetail({
                variables: { auctionId: auctionId }
            });
        }, 500);
        const listIntervalId = setInterval(() => {
            getAuctionDetail({
                variables: { auctionId: auctionId }
            });
        }, 5000);
        setIntervalId(listIntervalId);
    }

    const handleBidhistory = () => {
        auctionDetailContext.setSelectedList(AuctionDetailMenuItems.BIDDING_HISTORY.value);
    }

    const setLotInfoBasedOnMannerOfSale = (auctionLots, mannerOfSale, auctionInfo) => {
        if (auctionLotInfo !== undefined) {
            let previousEndDate = auctionLotInfo.endTime;
            //check if there is anyextension happened Going Concern, SingleLot and SingleItem
            if (new Date(auctionLots[0].endTime) > new Date(previousEndDate)) {
                setShowExtensionMessage({
                    show: true,
                    message: t('Auction_Extended_till') + ' ' + format(new Date(auctionInfo.endTime), 'dd MMM yyyy hh:mm'),
                    severity: 'info'
                })
                setTimeout(() => {
                    setShowExtensionMessage({
                        show: false,
                        message: '',
                        severity: 'info'
                    })
                }, 6000)
            }
        }
        auctionLots.map((value) => {
            return setAuctionLotInfo({
                id: value.id,
                endTime: value.endTime,
                autoExtension: value.autoExtension,
                autoExtensionMode: value.autoExtensionMode,
                extendTimeBy: value.extendTimeBy,
                extendTimeWhenBidReceivedInLastMinute: value.extendTimeWhenBidReceivedInLastMinute,
                startPrice: value.bidStartPrice,
                reservedPrice: value.reservePrice,
                incrementalPrice: value.incrementalAmount,
                currentHighestBid: 0,
                yourLastBid: 0,
                noOfExtension: value.noOfExtension,
                extensionTimeIncrementPrice: value.incrementalAmountDuringExtension > 0 ? value.incrementalAmountDuringExtension : value.incrementalAmount,
                incremental: value.incrementalAmount > 0 ? true : false,
                actualIncrementalPrice: value.actualEndTime < value.endTime ? (value.incrementalAmountDuringExtension > 0 ? value.incrementalAmountDuringExtension : value.incrementalAmount) : value.incrementalAmount,
                basePrice: value.bidStartPrice > 0 ? value.bidStartPrice : value.reservePrice,
                isExtended: ''
            })
        });
        switch (mannerOfSale) {
            case MannerOfSale.goingConcern:
                let goingConcernManner = userContext.enumList?.MannerOfSale?.filter((item) => MannerOfSale.goingConcern === item.dateBaseCode)
                setBidHeading({
                    bidLabel: t('Asset_Brief'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction_ID'),
                    idDescription: auctionInfo.auctionId,
                    mannerOfSaleUiCode: goingConcernManner.length > 0 ? goingConcernManner[0].uiCode : t('Going_Concern'),
                })
                return;
            case MannerOfSale.singleLot:
                let singleLotManner = userContext.enumList?.MannerOfSale?.filter((item) => MannerOfSale.singleLot === item.dateBaseCode)
                setBidHeading({
                    bidLabel: t('Asset_Brief'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction_ID'),
                    idDescription: auctionInfo.auctionId,
                    lotLabel: t('Lot_Number'),
                    lotNumber: auctionLots[0].lotNumber,
                    // lotDescription: auctionLots[0].description,
                    mannerOfSaleUiCode: singleLotManner.length > 0 ? singleLotManner[0].uiCode : t('Single_Lot')
                })
                return;
            case MannerOfSale.singleItem:
                let singleItemManner = userContext.enumList?.MannerOfSale?.filter((item) => MannerOfSale.singleItem === item.dateBaseCode)
                setBidHeading({
                    bidLabel: t('Asset_Brief'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction ID'),
                    idDescription: auctionInfo.auctionId,
                    mannerOfSaleUiCode: singleItemManner.length > 0 ? singleItemManner[0].uiCode : t('Single_Item'),
                })
                return;
        }
    }

    useEffect(() => {
        if (auctionDetailContext.auctionGetDetail !== undefined)
            setLotInfoBasedOnMannerOfSale(auctionDetailContext.auctionGetDetail.auctionLots, auctionDetailContext.auctionGetDetail.mannerOfSale, auctionDetailContext.auctionGetDetail)
    }, [auctionDetailContext.auctionGetDetail])

    useEffect(() => {
        if (AuctionStatus.closed === auctionDetailContext.auctionGetDetail.status.toLowerCase() || AuctionStatus.cancelled === auctionDetailContext.auctionGetDetail.status.toLowerCase()) {
            setIsClosed(true);
            if(auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.cancelled) {
                clearInterval(intervalId);
            }
        } else {
            setIsClosed(false);
        }
    }, [auctionDetailContext.auctionGetDetail.status])

    useEffect(() => {
        return () => {
            if(intervalId){
                clearInterval(intervalId);
            }
        }
    }, [intervalId])

    useEffect(() => {
        if (auctionDetailContext.auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionDetailContext.auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed) {
            const listIntervalId = setInterval(() => {
                getAuctionDetail({
                    variables: { auctionId: auctionDetailContext.auctionGetDetail.id }
                });
            }, 5000);
            setIntervalId(listIntervalId);
        } else if(auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed){
            let diff = new Date(auctionDetailContext.auctionGetDetail.currentTime).getTime() - new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].endTime).getTime();
            if(diff < 43200000) {
                // will excecute with in 12 hour.
                const listIntervalId = setInterval(() => {
                    getAuctionDetail({
                        variables: { auctionId: auctionDetailContext.auctionGetDetail.id }
                    });
                }, 5000);
                setIntervalId(listIntervalId);
            }  
        }
    }, [])

    return (
        <Fragment>
            {auctionLotInfo &&
                <Fragment>
                    <AuctionInfoCard>
                        <Grid container spacing={1}>
                            <Grid item xs={0.5} mt={2}>
                                <img src={process.env.PUBLIC_URL + '/images/bidnow.svg'} width='40px' height='40px'></img>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8.5} lg={8.5}>
                                <Stack spacing={0.8} pl={2}>
                                    <DisplayInfoWithLabel labelId='itemlabel' label={t('Asset_Brief')} descriptionId='itemdescription'
                                        description={bidHeading.bidDescription} />
                                    <DisplayInfoWithLabel labelId='mannerlabel' label={t('Manner_Of_Sale')} descriptionId='mannerdescription'
                                        description={bidHeading.mannerOfSaleUiCode} />
                                    <DisplayInfoWithLabel labelId='auction-id-label' label={bidHeading.idLabel} descriptionId='auction-id-description'
                                        description={bidHeading.idDescription} />
                                    {MannerOfSale.singleLot === auctionDetailContext.auctionGetDetail.mannerOfSale &&
                                        <DisplayInfoWithLabel labelId='lotlabel' label={bidHeading.lotLabel} descriptionId='lotdescription'
                                            description={bidHeading.lotNumber} />}
                                    <DisplayInfoWithLabel labelId='extensionlabel' label={t('Extension_Details')}
                                        descriptionId='extensiondescription' description={auctionLotInfo.autoExtension ?
                                            `${t('Auto')} / ${AutoExtensionMode.limitedExtension === auctionLotInfo.autoExtensionMode ? `${t('Limited_Extension')} / ${auctionLotInfo.noOfExtension} ${t('Extensions')}` :
                                            t('Unlimited_Extension')}` : t('No_Auto_Extension')}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} alignItems='center' justifyItems='end' textAlign='center' margin='auto'>
                                {!isClosed &&
                                    <Stack direction={{ xs: 'column' }} alignItems='center' spacing={0.5} justifyContent='center'>
                                        <Stack direction={{ xs: 'row' }} alignItems='center' spacing={2}>
                                            <GiAlarmClock fontSize='1.7rem' color='#0aa8b4' />
                                            <Typography id='remainingtimelabel' variant='subtitle2' color='textSecondary'>
                                                <Fragment> {t('Remaining_Time')}</Fragment>
                                                {/* {auctionDetailContext.auctionGetDetail.auctionLots[0].actualEndTime < auctionDetailContext.auctionGetDetail.auctionLots[0].endTime ?
                                                    <Fragment> {t('Extension_Time_Remaining')}</Fragment> : <Fragment> {t('Remaining_Time')}</Fragment>} */}
                                            </Typography>
                                        </Stack>
                                        <AuctionTimer
                                            date={auctionDetailContext.auctionGetDetail.auctionLots[0].endTime}
                                            title={t('Live_Ends_in')}
                                            isBid={true}
                                            terminated={handleTerminated}
                                        />
                                        {auctionLotInfo.autoExtension && extensionNumber > 0 &&
                                            <Stack direction={{ xs: 'row' }} alignItems='center' spacing={1} justifyContent='center' sx={{ paddingTop: '10px' }}>
                                                <Timelapse fontSize='1.7rem' color='#0aa8b4' />
                                                {auctionLotInfo.autoExtensionMode === AutoExtensionMode.limitedExtension && auctionLotInfo.noOfExtension === extensionNumber ?
                                                    <Typography id='completeextension' variant='subtitle2' color='textSecondary'>{t('Complete_extensions_in_no')}:</Typography>
                                                    : <Typography id='currentextension' variant='subtitle2' color='textSecondary'>{t('Current_extensions_in_nos')}:</Typography>
                                                }
                                                <Card className={classes.extension}>
                                                    {extensionNumber}
                                                </Card>
                                            </Stack>
                                        }
                                    </Stack>
                                }
                                {isClosed &&
                                    <Stack direction={{ xs: 'column' }} alignItems='center' spacing={0.5} justifyContent='center'>
                                        <Card className={classes.flicker}
                                            sx={{  mb: 2, p: 1, background: '#ff9f9f !important', display: 'inline-flex' }} >
                                            <Stack direction='row' spacing={1} justifyContent='flex-start'>
                                                <ReportGmailerrorredOutlined sx={{ color: '#730909' }} />
                                                <Typography id='auctionclosed'>{AuctionStatus.cancelled === auctionDetailContext.auctionGetDetail.status.toLowerCase()
                                                    ? t('Auction_Cancelled') : t('Auction_Closed')}</Typography>
                                            </Stack>
                                        </Card>
                                        {auctionLotInfo.autoExtension && extensionNumber > 0 &&
                                            <Stack direction={{ xs: 'row' }} alignItems='center' spacing={1} justifyContent='center' sx={{ paddingTop: '10px' }}>
                                                <Timelapse fontSize='1.7rem' color='#0aa8b4' />
                                                <Typography id='completeextension' variant='subtitle2' color='textSecondary'>{t('Complete_extensions_in_no')}:</Typography>
                                                <Card className={classes.extension}>
                                                    {extensionNumber}
                                                </Card>
                                            </Stack>
                                        }
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={0.3}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <DisplayInfoChip title={t('Reserve_Price')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.reservedPrice)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <DisplayInfoChip title={t('Bid_Start_Price')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.startPrice)} />
                            </Grid>
                            {auctionLotInfo.incremental &&
                                <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                    <DisplayInfoChip title={t('Incremental_Amount')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.incrementalPrice)} />
                                </Grid>}
                            {(auctionLotInfo.autoExtension && auctionLotInfo.incremental) &&
                                <Grid item xs={12} sm={6} md={4} lg={3.5}>
                                    <DisplayInfoChip title={t('Incremental_Amount_During_Extension')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.extensionTimeIncrementPrice)} />
                                </Grid>}
                        </Grid>
                        <Stack direction={{ xs: 'column' , sm: 'column', md: 'row', lg: 'row'}} alignItems='end' spacing={1} mt={1}>
                            <AuctionFieldSet sx={{ width: { xs: '100%', sm: '100%', md: '60%', lg: '60%' } }}>
                                <AuctionLengend style={{ fontSize: '1rem' }} id='h1-bidder-label'>{t('H1_Bidder')}</AuctionLengend>
                                {h1Bidder && <Grid container className='grid-container'>
                                    <Grid item xs={12} sm={12} md={1.4} lg={1.4} sx={{ paddingTop: '0px !important' }}>
                                        {/* <GiTrophyCup style={{ fontSize: '2.5rem', color: 'darkgoldenrod' }} /> */}
                                        <img src={process.env.PUBLIC_URL + '/images/bid-hit.svg'} width='50px' height='45px'></img>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{ paddingTop: '0px !important' }}>
                                        <Stack width='100%' spacing={0.5}>
                                            <Typography variant='subtitle2' color='textSecondary' id='h1-bidder-amt-label'>{t('Amount')}</Typography>
                                            <Typography color='textSecondary' className={classes.price} id='h1-bidder-amt'>
                                                {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, h1Bidder.amount)}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Divider flexItem sx={{ borderRightWidth: 3, marginRight: 3 }} />
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '0px !important' }}>
                                        <Stack width='100%' spacing={0.5}>
                                            <Typography variant='subtitle2' color='textSecondary' id='h1-bidder-date-label'>{t('Bid_Time')}</Typography>
                                            <Typography color='textSecondary' className={classes.price} id='h1-bidder-date'>
                                                {format(new Date(h1Bidder.timeOfBid), 'dd MMM yyyy hh:mm:ss:SSS a')}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>}
                                {activeBidderCount === 0 &&
                                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} color='indianred' id='no-bid-placed'>{t('No_Bids_Placed')}</Typography>
                                }
                            </AuctionFieldSet>
                            {(!isClosed) && <Stack width={{xs: '100%', sm: '100%', md: '40%', lg: '40%'}}
                                sx={{
                                    height: 'fit-content',
                                    width: 'fit-content',
                                    borderRadius: '5px', padding: activeBidderCount === 0 ? '0.7rem 1rem' : '1.5rem 1rem',
                                    background: '#f3fff0',
                                    border: (theme) => theme.palette.type === 'light' ? '1px solid #eeeeee' : '1px solid #d1e6eb',
                                    borderLeft: '4px solid #b2d0a4',
                                }}>
                                <Fragment>
                                    <Typography variant='subtitle2' color='textSecondary' id='h1-bidder-nextbid-label'>{t('Next_Possible_Bid')}</Typography>
                                    <Typography color='textSecondary' className={classes.price} id='h1-bidder-nextbid'>
                                        {h1Bidder && CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, h1Bidder.amount + auctionLotInfo.incrementalPrice)}
                                        {activeBidderCount === 0 && CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.basePrice)}
                                    </Typography>
                                </Fragment>
                            </Stack>}
                        </Stack>
                    </AuctionInfoCard>
                    <AuctionInfoCard style={{ padding: '0.5rem 1rem', marginTop: '5px' }}>
                        <Typography variant='h6' component='div' style={{ padding: '0.5rem' }}>
                            {t('Active_Bidders')}&nbsp;
                            <Chip id="active-bidder-count" label={<Typography>{activeBidderCount}</Typography>} size='small' color='info' />
                            <Link underline="always" id='view-bid-history' component="button" onClick={handleBidhistory} fontSize='large' style={{ float: 'right', marginTop: '5px' }}>
                                {t('View_Bid_History')}
                            </Link>
                        </Typography>
                        <ActiveBidderList key={listTrigger} noOfRequests={listTrigger}
                            auctionId={props.auctionId} bidOnId={lotId} h1Bidder={h1Bidder}
                            setH1Bidder={setH1Bidder} setExtensionNumber={setExtensionNumber} activeBidders={activeBidders} setActiveBidders={setActiveBidders}
                            activeBidderCount={activeBidderCount} setActiveBidderCount={setActiveBidderCount} />
                    </AuctionInfoCard>
                    {showExtensionMessage.show && <AuctionSnackBar show={showExtensionMessage.show} message={showExtensionMessage.message} severity={showExtensionMessage.severity} />}
                </Fragment>}
        </Fragment>
    )
}

export default AuctionHall
