import React, {
    useState,
    Fragment
}                                       from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    MenuItem,
    Select,
    Checkbox,
    TextField,
    FormControlLabel,
    InputAdornment,
    IconButton
}                                       from '@mui/material';
import { useTranslation }               from 'react-i18next'; 
import { Visibility, VisibilityOff }    from '@mui/icons-material';
import AuctionLoader                    from '../../common/auction-loader/AuctionLoader';
import { makeStyles }                   from '@mui/styles';
import FieldHelperText                  from '../../common/FieldHelperText';
import { APIAxiosAuctionInterceptor }   from '../../config/AxiosAuctionInterceptor';
import AuctionSnackBar                  from '../../common/AuctionSnackBar';
import axios                            from 'axios';

const useStyles = makeStyles((theme) => ({
    button: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const GenerateReport = ({show, onClose, type, auction_id, onSuccess, reportName, reportCd}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState({
        message: '',
        show: false,
        severity: ''
    });
    const reportTypes = [
        {name: 'PDF', value: 'PDF_A', fileType: 'pdf'},
        // {name: 'PDF_UA', value: 'PDF_UA', fileType: 'pdf'},
        // {name: 'EXCEL', value: 'EXCEL', fileType: 'xls'}
        // {name: 'DOCX', value: 'DOCX', fileType: 'docx'},
        // {name: 'DOC', value: 'DOC', fileType: 'doc'}
    ]
    const [reportDetails, setReportDetails] = useState({
        reportType: 'PDF_A',
        password: '',
        watermark: '',
        opaque: '0.3',
        digitalSign: false
    })

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const handleClose = () => {
        onClose(false);
    }

    const handleChange = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }))
    }

    const handleBooleanChange = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.checked
        }))
    }

    const generate = () => {
        setMessage({show: false, message: '', severity: 'error'});
        setLoading(true);
        let payload = {
            reportType: reportDetails.reportType,
            watermark: reportDetails.watermark ? {
                text: reportDetails.watermark,
                opaque: reportDetails.opaque
            } : null,
            appName: process.env.REACT_APP_NAME,
            password: reportDetails.password,
            digitalSign: reportDetails.digitalSign,
            reportOf: auction_id,
            presignedUrl: true,
            reportCd: reportCd
        }
        let reportType = reportTypes.find(reportType => reportType.value === reportDetails.reportType);
        APIAxiosAuctionInterceptor.post(`report/${type}/${auction_id}/null`,
            payload).then((response) => {
                axios({
                    url: response,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${reportName}.${reportType.fileType}`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url); 
                    setLoading(false);
                    onSuccess({show: true, message: t('Report_Generated_Successfully'), severity: 'success'});
                    handleClose();
                }).catch((error) => {
                    setMessage({ showMsg: true, message: error, severity: 'error' })
                    setLoading(false);
                })
            // const url = window.URL.createObjectURL(new Blob([response]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', `${reportName}.${reportType.fileType}`);
            // document.body.appendChild(link);
            // link.click();
            // link.remove();
            // window.URL.revokeObjectURL(url); 
            // setLoading(false);
            // onSuccess({show: true, message: t('Report_Generated_Successfully'), severity: 'success'});
            // handleClose();
        }).catch((error) => {
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    return (
        <Dialog open={show} fullWidth maxWidth='xs'>
            <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                <Typography id="generate-report-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                    {t('Generate_Report')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select id="reportType" name="report-type" value={reportDetails.reportType}
                                onChange={(event) => handleChange('reportType', event)} variant="standard" fullWidth>
                            {
                                reportTypes.map((reportType, index) => {
                                    return <MenuItem value={reportType.value} key={index}>{t(reportType.name)}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    {(reportDetails.reportType === 'PDF_A' || reportDetails.reportType === 'PDF_UA') && <Fragment>
                        <Grid item xs={12}>
                            <TextField id="password" label={t('Password')} variant="standard" fullWidth
                                type={showPassword ? "text" : "password"}
                                value={reportDetails.password} onChange={(event) => handleChange('password', event)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                id='eyepwdvisibility'
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{ maxLength: 30 }}
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="watermark" label={t('Watermark_Text')} variant="standard" fullWidth
                                value={reportDetails.watermark} onChange={(event) => handleChange('watermark', event)}
                                inputProps={{ maxLength: 30 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox id="with-digi-sign" checked={reportDetails.digitalSign} onChange={(event) => handleBooleanChange('digitalSign', event)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                            />} label={t('With_Digital_Signature')} />
                        </Grid>
                    </Fragment>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button id='generate-report-btn' type="submit" size="small" variant="contained"
                                onClick={generate} className={classes.button}>
                            {t('Generate')}
                        </Button>
                        <Button id='cancel-generate-report' size="small" variant="outlined" className={classes.button}
                                onClick={handleClose}>
                            {t('Cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            {loading && <AuctionLoader show={loading} invisible={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </Dialog>
    )
}

export default GenerateReport;