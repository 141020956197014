import { List } from "@mui/material"
import { styled } from "@mui/styles"

const imgLightUrl = process.env.PUBLIC_URL + '/images/sidebarlight.svg'
const imgDarkUrl = process.env.PUBLIC_URL + '/images/sidebardark.svg'
export const sideBarListWidth = '190px';
export const sideBarListCollapseWidth = '70px';

export const SideNavBar = styled(List)(({ theme, topBarHeight, ismenucollapse }) => ({
    position: 'absolute !important',
    width: ismenucollapse ? sideBarListCollapseWidth : sideBarListWidth,
    height: 'calc(100vh - 48px)',
    padding: '0px !important',
    // transition: 'width 0.3s ease',
    zIndex: 100,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${theme.palette.type === 'light' ? imgLightUrl : imgDarkUrl})`,
    backgroundColor: `${theme.palette.type === 'light' ? '#fff' : 'rgb(25 24 24)'}`,
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
}))

export const ListContainer = styled('div')(({ iscollapse }) => ({
    position: 'absolute',
    left: iscollapse ? sideBarListCollapseWidth : sideBarListWidth,
    width: iscollapse ? `calc(100% - ${sideBarListCollapseWidth})` : `calc(100% - ${sideBarListWidth} )`,
    // transition: 'all 0.3s ease',
    padding: '5px 10px',
    height: 'calc(100vh - 48px)',
    overflow: 'auto'
}))

export const MultiAuctionContainer = styled('div')(({}) => ({
    position: 'absolute',
    width: '100%',
    padding: '0px',
    height: 'calc(100vh - 48px)',
    overflow: 'auto',
    background: 'lavender'
}))