import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BidderDocumentList from './bidder-documents/BidderDocumentList';
import { makeStyles } from "@mui/styles";
import BidderViewDocumentList from './bidder-documents/BidderViewDocumentList';
import { AuctionStatus, Roles } from '../common/StaticVariableDeclaration';
import InformationDocumentList from './Information-documents/information-document-list/InformationDocumentList';
import { UserContext } from '../common/context/UserContext';

const useStyles = makeStyles((theme) => ({
  tab: {
    textTransform: 'none !important',
    fontWeight: '600 !important'
  },
  tabPadding: {
    '& .MuiBox-root': {
      // height: 'calc(100vh - 215px)',
      height: '72vh',
      overflow: 'auto'
    },
  },
  docBoxShadow: {
    width: '100%',
    boxShadow: theme.topBarBg.boxShadow,
  }

}));


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AuctionDocumentTabs = (props) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const myRef = React.useRef(null);
  const isCustomerSupport = (useContext(UserContext).rootContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
  const isAdmin = (useContext(UserContext).rootContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    myRef.current.scrollIntoView();
    if(props.setTourGuide) {
      props.setTourGuide({
        triggerEvent: false,
        triggerButton: false
      });
    }
  }, [])

  return (
    <Box className={classes.docBoxShadow} ref={myRef} key={props.roles}>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Document List Container"
      >
        <Tab id="info-doc-tab" label={t('Auction_Information_Document')} className={classes.tab} />
        <Tab id="bidder-doc-tab" label={t('Bidder_Documents')} className={classes.tab} />
      </Tabs>
      <TabPanel className={classes.tabPadding} style={{ backgroundColor: 'white' }} value={value} index={0}>
        {<InformationDocumentList
          auction_Id={props.auctionId}
          // showAdd={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          // showEdit={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          // showDelete={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          // showUpload={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed &&  (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          showAdd={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status === AuctionStatus.setup || props.status === AuctionStatus.publish_request)
            && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showEdit={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showDelete={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showUpload={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showActionColumn={true}
          isCustomerSupport={(isCustomerSupport && props.roles?.includes(Roles.support))}
          roles={props.roles}
          isAdmin={isAdmin}
        ></InformationDocumentList>}
      </TabPanel>
      <TabPanel className={classes.tabPadding} style={{ backgroundColor: 'white' }} value={value} index={1}>
        {!(props.roles.includes(Roles.requestedBidder) || props.roles.includes(Roles.rejectedBidder) ||
          props.roles.includes(Roles.bidder)) && <BidderDocumentList
          auction_Id={props.auctionId}
          showActionColumn={true}
          roles={props.roles}
            isCustomerSupport={(isCustomerSupport && props.roles?.includes(Roles.support))}
            showBidderDropdown={props.showBidderDropdown && (isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          // showAdd={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          // showEdit={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          // showDelete={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          // showUpload={props.status !== AuctionStatus.cancelled && props.status !== AuctionStatus.closed && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support))}
          showAdd={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status.toLowerCase() === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showEdit={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status.toLowerCase() === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showDelete={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status.toLowerCase() === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
          showUpload={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || ((props.status.toLowerCase() === AuctionStatus.setup || props.status === AuctionStatus.publish_request) && (props.roles?.includes(Roles.auctioneer) || props.roles?.includes(Roles.support)))}
        >
        </BidderDocumentList>}
        {(props.roles.includes(Roles.requestedBidder) || props.roles.includes(Roles.rejectedBidder) ||
          props.roles.includes(Roles.bidder)) &&
          <BidderViewDocumentList auctionId={props.auctionId} isBidder={true} bidderId={null}
            showActionColumn={isAdmin || (isCustomerSupport && props.roles?.includes(Roles.support)) || (props.status.toLowerCase() === AuctionStatus.scheduled && (props.roles.includes(Roles.requestedBidder) || props.roles.includes(Roles.bidder)) && !props.roles?.includes(Roles.rejectedBidder))}></BidderViewDocumentList>
        }
      </TabPanel>
    </Box>
  );

}

export default AuctionDocumentTabs;