import React, {
    useState
} from 'react';
import {
    Container,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Grid,
    DialogActions,
    Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
    PartyType,
    IndividualIdentificationType,
    AddressType
} from '../../common/StaticVariableDeclaration';
import BidderInput from '../add-bidder/BidderInput';
import {
    CREATE_BIDDER_AUTH_REP
} from '../../GraphQL/Mutation';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import {
    LIST_BIDDER_AUTH_REP
} from '../../GraphQL/Queries';
import TourIcon from '@mui/icons-material/Tour';

const useStyles = makeStyles((theme) => ({
    btn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const AddBidderAuthRep = ({ onClose, show, auction_id, onSuccess, bidder_id }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);

    const [tourGuide, setTourGuide] = useState({
        triggerEvent: false,
        triggerButton: false
    });

    const { register: authRepRegister, control: authRepControl, getValues: getAuthRepValues,
        setValue: setAuthRepValues, trigger: authRepTrigger, reset: authRepReset,
        formState: { errors: authRepError, isValid: authRepIsValid } } = useForm({
            mode: 'onChange',
            defaultValues: {
                individual: true,
                personId: '',
                addressId: '',
                phone: '',
                emailId: '',
                phoneNumber: '',
                attention: '',
                phone: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                landmark: '',
                cityTown: '',
                postalCode: '',
                state: '',
                addressType: AddressType.primaryContact,
                personId: '',
                addressId: ''
            }
        });

    const [addBidderAuthRep, { loading: addingAuthRep, error: errorOnAddingAuthRep }] = useMutation(CREATE_BIDDER_AUTH_REP, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addAuthorizedRepresentative != null) {
                onSuccess({ show: true, message: t('Auth_Rep_Added_Successfully'), severity: 'success' })
                handleClose();
            }
        },
        refetchQueries: [LIST_BIDDER_AUTH_REP]
    })

    const addAuthRep = () => {
        addBidderAuthRep({
            variables: {
                authorizedRepresentative: {
                    personId: getAuthRepValues('personId') ? getAuthRepValues('personId') : null,
                    individual: getAuthRepValues('individual'),
                    name: getAuthRepValues('name'),
                    identification: getIdentifications(getAuthRepValues),
                    address: [getAddress(getAuthRepValues)],
                    email: [{
                        email: getAuthRepValues('emailId')
                    }],
                    phoneNumber: [{
                        phoneNumber: getAuthRepValues('phoneNumber')
                    }]
                },
                auctionId: auction_id,
                bidderId: bidder_id
            }
        })
    }

    const getIdentifications = (getIdentificationValue) => {
        let identifications = [];
        if (getIdentificationValue('pan') && getIdentificationValue('pan') != '') {
            identifications.push({
                identificationType: IndividualIdentificationType.pan.type,
                identification: getIdentificationValue('pan')
            })
        }
        return identifications;
    }

    const getAddress = (getAddressValue) => {
        return {
            attention: getAddressValue('attention'),
            phone: getAddressValue('phone'),
            email: getAddressValue('email'),
            addressLine1: getAddressValue('addressLine1'),
            addressLine2: getAddressValue('addressLine2'),
            landmark: getAddressValue('landmark'),
            cityTown: getAddressValue('cityTown'),
            postalCode: getAddressValue('postalCode'),
            state: getAddressValue('state'),
            addressType: AddressType.primaryContact,
            country: getAddressValue('country')
        }
    }

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const updateTourGuide = () => {
        setTourGuide({
            triggerEvent: false,
            triggerButton: true
        });
        setTimeout(() => {
            setTourGuide({
                triggerEvent: true,
                triggerButton: true
            });
        }, 0);
    }

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='lg'>
                <DialogTitle style={{ textAlign: 'center', paddingTop: '10px' }}>
                    <Grid container>
                        <Typography variant="h6" gutterBottom color='primary' style={{ fontWeight: 'bold', width: '100%' }}>
                            {t('Auth_Rep_Details')}
                        </Typography>
                        {tourGuide.triggerButton && <Button
                            id='guide-me-link'
                            variant="contained"
                            startIcon={<TourIcon />}
                            sx={{ textTransform: 'none' }}
                            onClick={updateTourGuide}
                            className='guide-me'
                        >{t('Guide_Me')}
                        </Button>}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <BidderInput id="auth-rep-details" register={authRepRegister} errors={authRepError}
                                control={authRepControl} setValue={setAuthRepValues} getValues={getAuthRepValues}
                                showSearch={true} trigger={authRepTrigger} reset={authRepReset}
                                isEdit={true} showClear={true} tourGuide={tourGuide} setTourGuide={setTourGuide}>
                            </BidderInput>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id='close' size="small" variant="outlined" className={classes.btn}
                        onClick={handleClose}>
                        {t('Cancel')}
                    </Button>
                    <Button id='next' type="submit" size="small" variant="contained"
                        className={classes.btn} onClick={addAuthRep} disabled={!authRepIsValid}>
                        {t('Save')}
                    </Button>
                </DialogActions>
                {addingAuthRep && <AuctionLoader show={addingAuthRep} invisible={false} />}
                {errorOnAddingAuthRep && <GraphQLErrors error={errorOnAddingAuthRep} show={false} />}
            </Dialog>
        </Container>
    )
}

export default AddBidderAuthRep;