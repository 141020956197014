import React, { useState, Fragment } from 'react';
import {
    Container,
    Dialog,
    DialogTitle,
    Stepper,
    Step,
    StepButton,
    DialogContent,
    Grid,
    Box,
    DialogActions,
    Button,
    Divider,
    Typography,
    FormControlLabel,
    Switch
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import BidderInput from './BidderInput';
import {
    CREATE_BIDDER,
    CREATE_BIDDER_AUTH_REP
} from '../../GraphQL/Mutation';
import {
    IndividualIdentificationType,
    PartyType,
    SearchCriteria,
    CompanyIdentification,
    AddressType
} from '../../common/StaticVariableDeclaration';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import { LIST_BIDDER } from '../../GraphQL/Queries';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import TourIcon from '@mui/icons-material/Tour';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '4px !important'
    },
    paper: {
        borderRadius: '0px',
        overflow: 'scroll'
    },
    container: {
        margin: '10px'
    },
    backBtn: {
        float: 'left',
        margin: '0px 4px 0px 4px !important'
    },
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    },
    finishBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    },
    dialogOverlay: {
        overflow: 'auto !important'
    }
}))

const getSteps = () => {
    return ['Bidder_Details', 'Bidder_Auth_Rep_Details'];
}

const AddBidder = ({ onClose, show, auction_id }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const steps = getSteps();
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [tourGuide, setTourGuide] = useState({
        triggerEvent: false,
        triggerButton: false
    });

    const [sameAsAddress, setSameAsAddress] = useState(false);

    const { register: bidderRegister, control: bidderControl, getValues: getBidderValues,
        setValue: setBidderValues, trigger: bidderTrigger, reset: bidderReset, clearErrors: bidderClearErrors,
        formState: { errors: bidderError, isValid: bidderIsValid } } = useForm({
            mode: 'onChange',
            defaultValues: {
                individual: false,
                name: '',
                cin: '',
                pan: '',
                emailId: '',
                phoneNumber: '',
                emdAmount: 0,
                attention: '',
                phone: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                landmark: '',
                cityTown: '',
                postalCode: '',
                state: '',
                addressType: AddressType.primaryContact,
                personId: '',
                addressId: '',
                country: 'India'
            }
        });

    const { register: bidderAuthRepRegister, control: bidderAuthRepControl, getValues: getBidderAuthRepValues,
        setValue: setBidderAuthRepValues, trigger: bidderAuthRepTrigger, reset: bidderAuthRepReset,
        formState: { errors: bidderAuthRepError, isValid: bidderAuthRepIsValid } } = useForm({
            mode: 'onChange',
            defaultValues: {
                individual: true,
                name: '',
                cin: '',
                pan: '',
                emailId: '',
                phoneNumber: '',
                attention: '',
                phone: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                landmark: '',
                cityTown: '',
                postalCode: '',
                state: '',
                addressType: AddressType.primaryContact,
                personId: '',
                addressId: '',
                country: 'India',
                sameAsBidder: false
            }
        });

    const [createBidder, { loading: creatingBidder, error: errorOnCreatingBidder }] = useMutation(CREATE_BIDDER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setMessage({
                show: false,
                message: '',
                severity: ''
            })
            if (data.addBidder != null) {
                setBidderValues('id', data.addBidder.id);
                setBidderValues('personId', data.addBidder.personId);
                setMessage({
                    show: true,
                    message: t('Bidder_Added_Successfully'),
                    severity: 'success'
                })
                handleNext();
            }
        },
        refetchQueries: [LIST_BIDDER]
    })

    const [createBidderAuthRep, { loading: creatingBidderAuthRep, error: errorOnCreatingBidderAuthRep }] = useMutation(CREATE_BIDDER_AUTH_REP, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addAuthorizedRepresentative != null) {
                setLoading(true);
                setBidderAuthRepValues('personId', data.addAuthorizedRepresentative.personId);
                setMessage({
                    show: true,
                    message: t('Auth_Rep_Added_Successfully'),
                    severity: 'success'
                })
                setTimeout(() => {
                    setLoading(false);
                    handleClose();
                }, 500);
            }
        },
        refetchQueries: [LIST_BIDDER]
    })

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSameAsBidderChange = (event) => {
        if (event.target.checked) {
            setBidderAuthRepValues('individual', true);
            setBidderAuthRepValues('personId', getBidderValues('personId'));
            setBidderAuthRepValues('name', getBidderValues('name'));
            setBidderAuthRepValues('cin', getBidderValues('cin'));
            setBidderAuthRepValues('pan', getBidderValues('pan'));
            setBidderAuthRepValues('emailId', getBidderValues('emailId'));
            setBidderAuthRepValues('phoneNumber', getBidderValues('phoneNumber'));
            setBidderAuthRepValues('attention', getBidderValues('attention'));
            setBidderAuthRepValues('phone', getBidderValues('phone'));
            setBidderAuthRepValues('email', getBidderValues('email'));
            setBidderAuthRepValues('addressId', getBidderValues('addressId'));
            setBidderAuthRepValues('addressLine1', getBidderValues('addressLine1'));
            setBidderAuthRepValues('addressLine2', getBidderValues('addressLine2'));
            setBidderAuthRepValues('landmark', getBidderValues('landmark'));
            // setBidderAuthRepValues('cityTownId', getBidderValues('cityTownId'));
            setBidderAuthRepValues('country', getBidderValues('country'));
            setBidderAuthRepValues('cityTown', getBidderValues('cityTown'));
            setBidderAuthRepValues('postalCode', getBidderValues('postalCode'));
            // setBidderAuthRepValues('postalCodeId', getBidderValues('postalCodeId'));
            // setBidderAuthRepValues('stateId', getBidderValues('stateId'));
            setBidderAuthRepValues('state', getBidderValues('state'));
            setBidderAuthRepValues('addressType', getBidderValues('addressType'));
            setBidderAuthRepValues('sameAsBidder', true);
            bidderAuthRepTrigger();
        } else {
            bidderAuthRepReset();
        }
    };

    const handleAuthrepAddressSameAsBidder = (event) => {
        setSameAsAddress(event.target.checked);
        if (event.target.checked) {
            setBidderAuthRepValues('phone', getBidderValues('phone'));
            setBidderAuthRepValues('email', getBidderValues('email'));
            setBidderAuthRepValues('addressLine1', getBidderValues('addressLine1'));
            setBidderAuthRepValues('addressLine2', getBidderValues('addressLine2'));
            setBidderAuthRepValues('landmark', getBidderValues('landmark'));
            setBidderAuthRepValues('country', getBidderValues('country'));
            setBidderAuthRepValues('cityTown', getBidderValues('cityTown'));
            setBidderAuthRepValues('postalCode', getBidderValues('postalCode'));
            setBidderAuthRepValues('state', getBidderValues('state'));
            setBidderAuthRepValues('addressType', getBidderValues('addressType'));
        } else {
            setBidderAuthRepValues('phone', '');
            setBidderAuthRepValues('email', '');
            setBidderAuthRepValues('addressLine1', '');
            setBidderAuthRepValues('addressLine2', '');
            setBidderAuthRepValues('landmark', '');
            setBidderAuthRepValues('country', 'India');
            setBidderAuthRepValues('cityTown', '');
            setBidderAuthRepValues('postalCode', '');
            setBidderAuthRepValues('state', '');
            setBidderAuthRepValues('addressType', AddressType.primaryContact);
        }
    };

    const create = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
        createBidder({
            variables: {
                bidderInput: {
                    personId: getBidderValues('personId') ? getBidderValues('personId') : null,
                    individual: getBidderValues('individual'),
                    name: getBidderValues('name').trim(''),
                    restOfName: null,
                    identification: getIdentifications(getBidderValues),
                    address: [getAddress(getBidderValues)],
                    email: [{
                        email: getBidderValues('emailId')
                    }],
                    phoneNumber: [{
                        phoneNumber: getBidderValues('phoneNumber')
                    }]
                    // emdAmount: getBidderValues('emdAmount')
                },
                auctionId: auction_id
            }
        })
    }

    const createAuthRep = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
        createBidderAuthRep({
            variables: {
                authorizedRepresentative: {
                    personId: getBidderAuthRepValues('personId') ? getBidderAuthRepValues('personId') : null,
                    individual: getBidderAuthRepValues('individual'),
                    name: getBidderAuthRepValues('name'),
                    identification: getIdentifications(getBidderAuthRepValues),
                    address: [getAddress(getBidderAuthRepValues)],
                    email: [{
                        email: getBidderAuthRepValues('emailId')
                    }],
                    phoneNumber: [{
                        phoneNumber: getBidderAuthRepValues('phoneNumber')
                    }]
                },
                auctionId: auction_id,
                bidderId: getBidderValues('id')
            }
        })
    }

    const getIdentifications = (getIdentificationValue) => {
        let identifications = [];
        if (getIdentificationValue('cin') && getIdentificationValue('cin') != '') {
            identifications.push({
                identificationType: CompanyIdentification.cin.type,
                identification: getIdentificationValue('cin')
            })
        }
        if (getIdentificationValue('pan') && getIdentificationValue('pan') != '') {
            identifications.push({
                identificationType: IndividualIdentificationType.pan.type,
                identification: getIdentificationValue('pan')
            })
        }
        return identifications;
    }

    const getAddress = (getAddressValue) => {
        return {
            attention: getAddressValue('attention'),
            phone: getAddressValue('phone'),
            email: getAddressValue('email'),
            addressLine1: getAddressValue('addressLine1'),
            addressLine2: getAddressValue('addressLine2'),
            landmark: getAddressValue('landmark'),
            // cityTownId: getAddressValue('cityTownId'),
            cityTown: getAddressValue('cityTown'),
            postalCode: getAddressValue('postalCode'),
            // postalCodeId: getAddressValue('postalCodeId'),
            // stateId: getAddressValue('stateId'),
            state: getAddressValue('state'),
            addressType: AddressType.primaryContact,
            // countryId: getAddressValue('countryId'),
            country: getAddressValue('country')
        }
    }

    const updateTourGuide = () => {
        setTourGuide({
            triggerEvent: false,
            triggerButton: true
        });
        setTimeout(() => {
            setTourGuide({
                triggerEvent: true,
                triggerButton: true
            });
        }, 0);
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <BidderInput id="bidder-details" key='bidder-info' register={bidderRegister} errors={bidderError}
                        control={bidderControl} setValue={setBidderValues} getValues={getBidderValues}
                        showPartyTypeSelection={!getBidderValues('id')} showSearch={!getBidderValues('id')}
                        trigger={bidderTrigger} reset={bidderReset} showEMDAmountField={true}
                        isEdit={!getBidderValues('id')} showClear={true} clearErrors={bidderClearErrors} tourGuide={tourGuide} setTourGuide={setTourGuide}>
                    </BidderInput>
                )
            case 1:
                return (
                    getBidderValues('id') != '' && <Fragment>
                        <BidderInput
                            id="bidder-auth-rep-details" key='bidder-auth-rep-info' register={bidderAuthRepRegister} errors={bidderAuthRepError}
                            control={bidderAuthRepControl} setValue={setBidderAuthRepValues} getValues={getBidderAuthRepValues} showSearch={true}
                            trigger={bidderAuthRepTrigger} reset={bidderAuthRepReset} isEdit={true} showClear={true} tourGuide={tourGuide}
                            setTourGuide={setTourGuide} handleAuthrepAddressSameAsBidder={handleAuthrepAddressSameAsBidder} sameAsAddress={sameAsAddress}
                        >
                            {getBidderValues('individual') === true && <FormControlLabel id="same-as-authrep"
                                control={ <Switch name="same-as-bidder" checked={getBidderAuthRepValues('sameAsBidder')}/>}
                                label={t('Same_As_Bidder')} onChange={handleSameAsBidderChange}/>}
                        </BidderInput>
                    </Fragment>
                );
            default:
                return null;
        }
    }

    const getPageActions = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            {!getBidderValues('id') && <Button id='bidder-save-and-next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={create} disabled={!bidderIsValid}>
                                {t('Save_And_Next')}
                            </Button>}
                            {getBidderValues('id') && <Button id='bidder-next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={handleNext}>
                                {t('Next')}
                            </Button>}
                            <Button size="small" id="cancel-add-bidder" variant="outlined"
                                className={classes.nextBtn} onClick={handleClose}>
                                {!getBidderValues('id') ? t('Cancel') : t('Close')}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='back' size="small" variant="outlined"
                                className={classes.backBtn} onClick={handleBack}>
                                {t('Back')}
                            </Button>
                            {getBidderValues('id') && <Button id='submit' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={createAuthRep} disabled={!bidderAuthRepIsValid}>
                                {t('Submit')}
                            </Button>}
                            <Button id='close' size="small" variant="outlined" className={classes.nextBtn}
                                onClick={handleClose}>
                                {t('Close')}
                            </Button>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='lg'>
                <DialogTitle style={{ textAlign: 'center', paddingTop: '10px' }}>
                    <Grid container>
                        <Typography id="add-bidder-header" variant="h6" gutterBottom color='primary' style={{ fontWeight: 'bold', width: '100%' }}>
                            {t('Add_Bidder')}
                        </Typography>
                        {tourGuide.triggerButton && <Button
                            id='guide-me-link'
                            variant="contained"
                            startIcon={<TourIcon />}
                            sx={{ textTransform: 'none' }}
                            onClick={updateTourGuide}
                            className='guide-me'
                        >{t('Guide_Me')}
                        </Button>}
                    </Grid>
                    <Container maxWidth="md">
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={index} id={`step${index}`}>
                                    <StepButton color="inherit">
                                        {t(label)}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                </DialogTitle>
                <DialogContent className={classes.dialogOverlay}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%' }}>
                                {getStepContent(activeStep)}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Divider />
                    {getPageActions(activeStep)}
                </DialogActions>
                {(creatingBidder || creatingBidderAuthRep || loading) && <AuctionLoader show={creatingBidder || creatingBidderAuthRep || loading} invisible={false} />}
                {errorOnCreatingBidder && <GraphQLErrors error={errorOnCreatingBidder} show={false} />}
                {errorOnCreatingBidderAuthRep && <GraphQLErrors error={errorOnCreatingBidderAuthRep} show={false} />}
            </Dialog>
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </Container>
    )
}

export default AddBidder;