import React, { Fragment, useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import { PublicAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import { Container, Grid, Stack, Tooltip, Typography, IconButton, Card, Button} from '@mui/material';
import { UserContext } from '../common/context/UserContext';
import { DisplayInfoChip, DisplayInfoChips } from '../bidder/bid-process/bid-process-components/BidProcessComponents';
import { useLazyQuery } from '@apollo/client';
import { LIST_ITEM_LOT_FILES } from '../GraphQL/Queries';
import Lightbox                 from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import Inline                   from "yet-another-react-lightbox/plugins/inline";
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { AuctionDetailMenuItems } from '../auction/auction-detail/AuctionDetailStaticVar';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { ButtonGlow } from '../auction/auction-detail/auction-style/AuctionStyle';
import { AuctionDetailContext } from '../auction/auction-detail/AuctionDetail';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ImageIcon from '@mui/icons-material/Image';
import { CurrencyFormatter } from '../utils/Utils';

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    auctionTitleName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto'
    },
    noImageIcons: {
        width: '25% !important',
        height: '25% !important',
        color: 'gray'
    },
    noImageText: {
        color: 'gray',
        fontSize: '2rem',
        textAlign: 'center'
    }
  }));

const ItemImagesDetail = (props) => {
    const { auctionDetail, navigationId, backNavigation, sideNavigation, auctionListType, pagination, filtersValue, isMyBid } = props.location.state;
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [getAuctionDetail, setGetAuctionDetail ]= useState(null);
    const [auctionImageId, setAuctionImageId]= useState([]);
    const [images, setImages] = useState([]);
    const [timerDetail, setTimerDetail] = useState()
    const [uploadImage, setUploadImage] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const userContext = useContext(UserContext).rootContext;
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const auctionDetailContext = useContext(AuctionDetailContext);
    const userSession = useContext(UserContext).rootContext.userSession;
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

    const ItemCategoryListOfDescription = ({ list }) => {
            return  list.map((data, index) => 
                <Stack direction='column' spacing={1}>
                    <span key={index}>
                        {enumListObject.ItemType[data].uiCode}
                    </span>
                </Stack>)
        }

    const getAuction = () => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/auction/${auctionDetail.id}`).then((response) => {
            setAuctionImageId(response.auctionId);
            response.auctionLots.map((val) =>{
                setGetAuctionDetail(prevs => ({
                    ...prevs,
                    reservePrice: val.reservePrice,
                    emdAmount: val.emdAmount,
                    emdLastDate: val.lastDateForEmd,
                    itemType: val.itemType,
                    lotId: val.lotId,
                    auctionStartTime: response.startTime,
                    auctionEndTime: response.endTime,
                    auctionTitle: response.title,
                    auctionIds: response.id,
                    incrementalAmount: val.incrementalAmount,
                    lotAddress: val.address ? val.address.cityTown : null,
                    baseCurrency: response.baseCurrency
                }))
            });
            response.auctioneers.map((val) =>{
                setGetAuctionDetail(prevs => ({
                    ...prevs,
                    auctioneerName: val.name,
                    auctioneerEmail: val.email[0].email
                }))
            })
            response.conductingFor.map((val) =>{
                setGetAuctionDetail(prevs => ({
                    ...prevs,
                    sellerName: val.name,
                    sellerEmail: val.email[0].email
                }))
            })
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const groupImages = (response) => {
        response.map(image => {
            if (image.contentType.includes('video')) {
                setImages(prevState => ([
                    ...prevState,
                    {
                        // original: image.presignedUrl,
                        // contentType: image.contentType,
                        // key: image.s3ObjectFile.key
                        type: "video",
                        // width: 1280,
                        // height: 720,
                        sources: [
                            {
                                src: image.presignedUrl,
                                type: image.contentType
                            }
                        ],
                        key: image.s3ObjectFile.key,
                    }
                ]));
            } else if(image.contentType.includes('image')) {
                setImages(prevState => ([
                    ...prevState,
                    {
                        type: "image",
                        src: image.presignedUrl,
                        key: image.s3ObjectFile.key,
                    }
                ]));
            }
        })
    }

    const [listLotDocuments, { error, refetch }] = useLazyQuery(LIST_ITEM_LOT_FILES, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setLoadingImages(true);
            setImages([]);
            if (data.listLotOrItemFiles != null && data.listLotOrItemFiles.files.length > 0) {
                // data.listLotOrItemFiles.files.map(image => {
                //     if (image.contentType.includes('video') || image.contentType.includes('image')) {
                //         setImages(prevState => ([
                //             ...prevState,
                //             {
                //                 original: image.presignedUrl,
                //                 contentType: image.contentType,
                //                 key: image.s3ObjectFile.key
                //             }

                //         ]));
                //     }
                // })
                groupImages(data.listLotOrItemFiles.files);
            }
            setLoadingImages(false);
        }
    })

    const getPublicLotImages = () => {
        setLoadingImages(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/${auctionDetail.id}/${auctionDetail.auctionLots[0].id}/100/true/true?itemId=&startingToken=''`).then((response) => {
            setImages([]);
            if (response.files != null && response.files.length > 0) {
                groupImages(response.files);
            }
            setLoadingImages(false);
        }).catch(function (error) {
            setLoadingImages(false);
            setMessage({show: false, message: '', severity: ''})
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        });
    }

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleAuctionDetailImage = (navigation) => {
        history.push({
            pathname: "/auction-detail",
            state: {
                auctionDetail: auctionDetail,
                navigationId: navigationId,
                backNavigation: backNavigation,
                sideNavigation: navigation,
                auctionListType: auctionListType,
                pagination: pagination,
                filtersValue: filtersValue,
                isMyBid: isMyBid
            }
        })
    }

    const handleBackNavigation = () => {
        history.push({
            pathname: backNavigation,
            state: {
                userRole: navigationId,
                auctionListType: auctionListType,
                pagination: pagination,
                filtersValue: filtersValue,
                isMyBid: isMyBid
            }
        })
    };


    // const resetUploadImage = (event) => {
    //     setUploadImage(event);
    //     refetch();
    // }

    const inline = {
        style: {
            width: "100%",
            maxWidth: "900px",
            aspectRatio: "3 / 2",
            margin: "0px",
            // background: "white"
        },
    };

    useEffect(() => {
        getAuction();
        if (userContext.userSession.isAuthenticated) {
            listLotDocuments({
                variables: {
                    auctionId: auctionDetail.id,
                    lotId: auctionDetail.auctionLots[0].id,
                    isPresignedUrl: true,
                    limit: 100,
                    startingToken: '',
                    isPicture: true
                }
            })
        } else {
            getPublicLotImages();
        }
    }, [])

    return (
        <Fragment>
            { getAuctionDetail && <div style={{ background: 'white', height: '100%'}}>
                <Grid style={{ background: '#f1f5fd', border: '1px solid #f6f6f6', padding: '0.8rem', fontWeight: '600'}}>
                    <Grid container style={{ padding: '0px 20px',  }}>
                        <Grid style={{ width: '70%'}}>
                            <div className={classes.auctionTitleName} style={{ width: '95%', float: 'left'}}>{getAuctionDetail.auctionTitle}</div>
                        </Grid>
                        <Grid style={{ width: '30%'}}>
                            <div className={classes.auctionTitleName} style={{ float: 'right' }}>Auction ID:{auctionImageId}</div>
                        </Grid>
                    </Grid>
                    {/* <span style={{paddingLeft: '34px', fontSize:'20px', fontWeight:'bold'}}>{getAuctionDetail.auctionTitle}</span>
                    <span style={{paddingLeft: '34px', fontSize:'20px', fontWeight:'bold', marginLeft: '900px'}}>Auction ID:{auctionImageId}</span> */}
                </Grid>
                <Grid>
                    <Stack direction='row' mb={1} spacing={1}>
                        <Container style={{maxWidth:1390,padding:'20px'}} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ border: '1px solid #f1f1f1', padding: '0px', borderRadius: '2px', margin: '5px 8px 8px 10px' }}>
                                    { (!loadingImages && images.length > 0) ?
                                        <Lightbox
                                            // imageFit="cover"
                                            styles={{ container: { backgroundColor: "#f0f2f4" } }}
                                            slides={images}
                                            inline={inline}
                                            open={true}
                                            carousel={{
                                                spacing: 0,
                                                padding: 0,
                                                imageFit: "cover",

                                            }}
                                            plugins={[Inline]}
                                            fullscreen={true }
                                            thumbnails={{
                                                position: 'bottom',
                                                width: 20,
                                                height: 20,
                                                border: 1,
                                                borderRadius: 4,
                                                padding: 2,
                                                gap: 8,
                                            }}
                                        /> :
                                        <Grid container direction="row" justifyContent="center" alignItems="center"
                                            style={{display: 'grid', height: '50vh'}}>
                                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                                <ImageIcon className={classes.noImageIcons}/>
                                                <Typography className={classes.noImageText}>
                                                    <div id="no-item-image">{t('Images_Videos_Is_Not_Uploaded')}</div>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                {/* <Stack direction='row' mb={1} spacing={2} style={{padding:'10px'}}> */}
                                    <Grid container spacing={1} style={{margin: '10px 8px 8px 5px' }}>
                                        <Grid item xs={4}>
                                            <DisplayInfoChip title={t('Reserve_Price')} value={CurrencyFormatter(getAuctionDetail.baseCurrency, getAuctionDetail.reservePrice)}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DisplayInfoChip title={t('EMD_Amount')} value={CurrencyFormatter(getAuctionDetail.baseCurrency, getAuctionDetail.emdAmount)}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DisplayInfoChip title={t('Incremental_Amount')} value={CurrencyFormatter(getAuctionDetail.baseCurrency, getAuctionDetail.incrementalAmount)} />
                                        </Grid>
                                    </Grid>
                                {/* </Stack> */}
                            </Grid>
                        </Container>
                        <Container direction='column' style={{padding:'0px'}}>
                            <Grid container direction='row' spacing={2} style={{marginTop:'2px', paddingLeft: '0px'}}>
                                <Grid item xs={12} sm={6} md={4} lg={8}>
                                    <DisplayInfoChips title={t('Asset_Type')} value={getAuctionDetail.itemType?.length > 0 ? <ItemCategoryListOfDescription list={getAuctionDetail.itemType} /> : ' --'} />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2} style={{marginTop:'2px', paddingLeft: '0px'}}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips title={t('Auction_Start_Date_Time') + '(' + dateTimeZoneValue + ')'} value={format(new Date(getAuctionDetail.auctionStartTime), 'dd-MM-yyyy hh:mm a')} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips title={t('Auction_End_Date_Time') + '(' + dateTimeZoneValue + ')'} value={format(new Date(getAuctionDetail.auctionEndTime), 'dd-MM-yyyy hh:mm a')} />
                                </Grid>
                                <Grid  item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips title={t('LastDate_for_Emd')  + '(' + dateTimeZoneValue + ')'}
                                        value={getAuctionDetail.emdLastDate ? format(new Date(getAuctionDetail.emdLastDate), 'dd-MM-yyyy hh:mm a') : '--'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2} style={{marginTop:'2px', paddingLeft: '0px'}}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips title={t('Auctioneer_Name')} value={(getAuctionDetail.auctioneerName)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips title={t('Auctioneer_Email')} value={(getAuctionDetail.auctioneerEmail)} />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2} style={{marginTop:'2px', paddingLeft: '0px'}}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips  title={t('Seller_Name')} value={(getAuctionDetail.sellerName) ? (getAuctionDetail.sellerName) : '--'} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips  title={t('Seller_Email')} value={(getAuctionDetail.sellerEmail) ? (getAuctionDetail.sellerEmail) :'--'} />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2} style={{marginTop:'2px', paddingLeft: '0px'}}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <DisplayInfoChips  title={t('Location')} value={getAuctionDetail.lotAddress ?  getAuctionDetail.lotAddress : '--'} />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2} style={{marginTop:'2px', paddingLeft: '0px'}}>
                                {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Card><ButtonGlow variant='contained' size='small' color='primary'>EMD deposit ends in 5 days.</ButtonGlow></Card>
                                    </Grid>                       */}
                            </Grid>
                        </Container>
                    </Stack>
                    
                    {loading && <AuctionLoader show={loading} invisible={false} />}
                </Grid>
                <Grid style={{ position: 'absolute', bottom: '0px', width: '100%', borderTop: '1px solid #f6f6f6', padding: '0.8rem', background: 'ghostwhite' }}>
                    <Tooltip title={t('Back_to_Auctions')} arrow style={{ margin: '0px 10px' }}>
                        <Button variant="outlined" id='auction-list-back' color="primary"
                            onClick={handleBackNavigation} startIcon={<ChevronLeftIcon />}
                        >
                            {t('Go_Back')}
                        </Button>
                    </Tooltip>
                    <Tooltip title={t('View_More_Details')} arrow style={{ float: 'right', margin: '0px 10px' }}>
                        <Button variant="contained" id='download-documents' color="primary"
                            onClick={() => handleAuctionDetailImage(AuctionDetailMenuItems.AUCTION_DETAILS.value)} endIcon={<ReadMoreIcon />}
                        >
                            {t('More_Details')}
                        </Button>
                    </Tooltip>
                    <Tooltip title={t('Navigation_To_Documents')} arrow style={{ float: 'right', margin: '0px 10px' }}>
                        <Button variant="contained" id='download-documents' color="primary"
                            onClick={() => handleAuctionDetailImage(AuctionDetailMenuItems.DOCUMENTS.value)} endIcon={<SimCardDownloadIcon />}
                        >
                            {t('Documents')}
                        </Button>
                    </Tooltip>
                    {/* <span style={{paddingLeft: '34px', fontSize:'20px', fontWeight:'bold', textAlign:'center'}}>Documents&nbsp;&nbsp; <Tooltip title={t('Navigation_To_Documents')} arrow>
                        <IconButton id={'download-documents'} color="primary" sx={{ padding: '4px !important' }}
                            onClick={() => handleDocumentView()}>
                            <SimCardDownloadIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        </Tooltip>
                    </span> */}
                </Grid>
            </div>}
        </Fragment>
    )
}

export default ItemImagesDetail