import { Cancel, CheckCircle, NoteAlt } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import Popper from '@mui/material/Popper';

export const NumberCheck = new RegExp(/[0-9]/);
export const UpperLetter = new RegExp(/[A-Z]/);
export const LowerLetter = new RegExp(/[a-z]/);
export const SpecialChar = new RegExp(/[^A-Z a-z 0-9]/);
export const MinPwdLength = 7;
export const MaxPwdLength = 51;

const useStyles = makeStyles( (theme) => ({
  popperOutline: {
    zIndex: '10',
    padding: '1rem 2rem',
    background: 'white',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important'
  },
  infoCard: {
    padding: '0.2rem 2rem',
    borderRadius: '6px !important',
    margin:'0.5rem 0rem',
    fontSize: '14px'
  },
  errorInfo: {
    borderBottom: '1px solid #ff7f7f',
    color: 'red',
    background: '#ffcccc'
  },
  successInfo: {
    borderBottom: '1px solid darkseagreen',
    color: 'green',
    background: '#ccffcc'
  },
  infoIcon: {
    fontSize: 'medium !important',
    verticalAlign: 'bottom !important',
    marginRight: '1rem !important',
    float: 'right'
  }
}))

const PasswordPolicy = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const isPasswordPolicyOpen = Boolean(anchorEl);
  const classes = useStyles();

  useEffect(() => {
    setAnchorEl(props.open);
  }, [props.open])

  return (
    <Fragment>
      <Popper id={'password-policy-popup'} open={isPasswordPolicyOpen} anchorEl={anchorEl} placement='bottom' className={classes.popperOutline}>
        <h4 style={{ textAlign: 'center', margin: '0rem 0rem 0.5rem', paddingBottom: '10px'}}>Password requirements</h4>
        <div style={{ textAlign: 'left', marginTop: '0.5rem' }}>
          <div className={props.pwdLength ? [classes.infoCard, classes.successInfo].join(' ') : [classes.infoCard, classes.errorInfo].join(' ')}>
            Contains at least 8 characters {props.pwdLength ? <CheckCircle className={classes.infoIcon} /> : <Cancel className={classes.infoIcon} />}
          </div>
          <div className={props.maxPwdLength ? [classes.infoCard, classes.successInfo].join(' ') : [classes.infoCard, classes.errorInfo].join(' ')}>
            Contains at most 50 characters {props.maxPwdLength ? <CheckCircle className={classes.infoIcon} /> : <Cancel className={classes.infoIcon} />}
          </div>
          <div className={props.isUpper ? [classes.infoCard, classes.successInfo].join(' ') : [classes.infoCard, classes.errorInfo].join(' ')}>
            Contains at least 1 uppercase letter {props.isUpper ? <CheckCircle className={classes.infoIcon} /> : <Cancel className={classes.infoIcon} />}
          </div>
          <div className={props.isLower ? [classes.infoCard, classes.successInfo].join(' ') : [classes.infoCard, classes.errorInfo].join(' ')}>
            Contains at least 1 lowercase letter {props.isLower ? <CheckCircle className={classes.infoIcon} /> : <Cancel className={classes.infoIcon} />}
          </div>
          <div className={props.isNumber ? [classes.infoCard, classes.successInfo].join(' ') : [classes.infoCard, classes.errorInfo].join(' ')}>
            Contains at least 1 number {props.isNumber ? <CheckCircle className={classes.infoIcon} /> : <Cancel className={classes.infoIcon} />}
          </div>
          <div className={props.isSpecial ? [classes.infoCard, classes.successInfo].join(' ') : [classes.infoCard, classes.errorInfo].join(' ')}>
            Contains at least 1 special character {props.isSpecial ? <CheckCircle className={classes.infoIcon} /> : <Cancel className={classes.infoIcon} />}
          </div>
          <Stack style={{
            background: 'rgb(222, 235, 255)',
            color: 'rgb(0, 82, 204)',
            borderRadius: '6px',
            padding: '0.5rem',
            textAlign: 'left',
            marginTop: '10px'
          }} direction='column'>
            <Stack direction='row' spacing={0.5}>
              <NoteAlt style={{ fontSize: "1.2rem"}} />
              <span>Note:</span>
              <span style={{ color: "darkgoldenrod", paddingLeft: "4px", fontSize: "14px" }}>Make sure your Password is Strong.</span>
            </Stack>
            <Typography style={{ color: grey[900], marginLeft: "71px", fontSize: "12px", fontStyle: "italic" }}>Using Names, Date of Births, Spouse Name, Pet Name, Locations etc. is not advisable</Typography>
          </Stack>
        </div>
      </Popper>
    </Fragment>
  )
      {/* {props.open ?
      <Card style={{ padding: '1rem', zIndex: 4, position: "fixed", left: props.left ? props.left : '40%' }} elevation={10}>
        Password requirements
        <div style={{ textAlign: 'left', marginTop: '0.5rem' }}>
          <div style={props.pwdLength ? { color: 'green' } : { color: 'red' }}>{props.pwdLength ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />} Contains at least 8 characters </div>
          <div style={props.maxPwdLength ? { color: 'green' } : { color: 'red' }}>{props.maxPwdLength ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />} Contains at most 50 characters </div>
          <div style={props.isUpper ? { color: 'green' } : { color: 'red' }}>{props.isUpper ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 uppercase letter </div>
          <div style={props.isLower ? { color: 'green' } : { color: 'red' }}>{props.isLower ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 lowercase letter</div>
          <div style={props.isNumber ? { color: 'green' } : { color: 'red' }}>{props.isNumber ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 number</div>
          <div style={props.isSpecial ? { color: 'green' } : { color: 'red' }}>{props.isSpecial ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 special character</div>
          <Stack style={{
            background: 'rgb(222, 235, 255)',
            color: 'rgb(0, 82, 204)',
            borderRadius: '3px',
            padding: '0.5rem',
            textAlign: 'left',
            marginTop: '10px'
          }} direction='column'>
            <Stack direction='row' spacing={0.5}>
              <NoteAlt style={{ fontSize: "1.2rem"}} />
              <span>Note:</span>
              <span style={{ color: "darkgoldenrod", paddingLeft: "4px", fontSize: "14px" }}>Make sure your Password is Strong.</span>
            </Stack>
            <Typography style={{ color: grey[900], marginLeft: "71px", fontSize: "12px", fontStyle: "italic" }}>Using Names, Date of Births, Spouse Name, Pet Name, Locations etc. is not advisable</Typography>
          </Stack>
        </div>
      </Card> : <React.Fragment />} */}
}

export default PasswordPolicy