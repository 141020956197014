import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';
import { ThemeProvider } from './common/context/ThemeContext';
import {
  ApolloProvider, ApolloClient,
  InMemoryCache, from
} from '@apollo/client';
import { errorLink, httpLink, authMiddleware } from './config/apollo.config';
import reportWebVitals from './reportWebVitals';
// import GoogleSEO from './utils/GoogleSEO';
import './i18n'

const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink()]),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    {/* <GoogleSEO /> */}
    <React.StrictMode>
      <Router>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Router>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
