import { Close } from '@mui/icons-material';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemText,
  MenuItem, TextField, Tooltip, Typography, Stack
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LIST_MESSAGE_CODE } from '../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import { useServerCurrentDateTime } from '../utils/Utils';

const useStyles = makeStyles((theme) => ({
  close: {
    '&:hover': {
      backgroundColor: "lightGray",
    }
  },
  dialogTitle: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    backgroundColor: 'aliceblue',
    color: 'black',
    padding: '10px !important'
  },
  title: {
    flexGrow: 1,
  },
  dialogActions: {
    padding: '8px 25px !important',
    justifyContent: 'space-between !important'
  }
}));

function CommunicationsEmailFilter(props) {
  const { dialogOpen, setDialogOpen, date, onClose, auctionId, communicationEmailList, setCommunicationEmailList, page, setPage, itemPerPage, setItemPerPage,isFilterApplied, setIsFilterApplied,
    storedEmail, setStoredEmail, storedEmailDescription, setStoredEmailDescription, storedEmailStartDate, setStoredEmailStartDate, storedEmailEndDate, setStoredEmailEndDate,
    storedStatus, setStoredStatus } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const serverCurrentTime = useServerCurrentDateTime();
  const [startDate, setStartDate] = useState(isFilterApplied ? storedEmailStartDate ? storedEmailStartDate : [] : date)
  const [endDate, setEndDate] = useState(isFilterApplied ? (storedEmailEndDate ? storedEmailEndDate : []) : serverCurrentTime)
  const [messageTo, setMessageTo] = useState(isFilterApplied ? storedEmail ? storedEmail : [] : [])
  const [listMessageCodeCd, setListMessageCodeCd] = useState([])
  const [codeCc, setCodeCc] = useState([])
  const [codeBcc, setCodeBcc] = useState([])
  const [codeTo, setCodeTo] = useState([])
  const [messageBcc, setMessageBcc] = useState([])
  const [messageCc, setMessageCc] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(isFilterApplied ? storedEmailDescription ? storedEmailDescription : null : null)
  const [statusName, setStatusName] = useState(isFilterApplied ? storedStatus ? storedStatus : [] : [])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMessageTo(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatusName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeBcc = (event) => {
    const {
      target: { value },
    } = event;
    setMessageBcc(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeCc = (event) => {
    const {
      target: { value },
    } = event;
    setMessageCc(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onChangeMessage = (event) => {
    setSelectedMessage(event.target.value);
  }

  const [userListMessageCode] = useLazyQuery(LIST_MESSAGE_CODE, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLoading(false)
      setListMessageCodeCd(
        data.listMessageCodeCd
      )
    }
  })

  const listContact = (event) => {
    for (let i = 0; i <= 4; i++) {
      AxiosAuctionInterceptor.get(`audit/message-contacts/${props.auctionId}/${i}/${true}`).
        then((response) => {
          if (i === 1) {
            setCodeTo(response[1])
          } else if (i === 3) {
            setCodeCc(response[3])
          } else if (i === 4) {
            setCodeBcc(response[4])
          }
          setLoading(false)
        }).catch((error) => {
          console.log("error", error)
        })
    }
  }

  const emailFilter = (selectedMessage, endDate, startDate, messageTo) => {
    let payload = {
      receiver: messageTo.length > 0 ? {
        1: messageTo
      } : {},
      statusCds:
        statusName
      ,
      information: selectedMessage,
      startRange: startDate,
      endRange: endDate,
      email: true,
      page: page,
      size: itemPerPage,
      asc: true
    }
    AxiosAuctionInterceptor.post(`audit/email-filter/${props.auctionId}`, payload).
      then((response) => {
        setCommunicationEmailList(response.emails)
        setStoredEmail(messageTo)
        setStoredEmailDescription(selectedMessage)
        setStoredEmailStartDate(startDate)
        setStoredEmailEndDate(endDate)
        setStoredStatus(statusName)
        setIsFilterApplied(true)
      }).catch((error) => {
        console.log("error", error)
      })
  }

  const handleEmailFilter = (event) => {
    props.emailFilter(selectedMessage, endDate, startDate, messageTo, statusName, 1)
    setDialogOpen(false)
    setPage(1)
  }

  const onClearFields = () => {
    setMessageTo([])
    setStartDate(date)
    setEndDate(serverCurrentTime)
    setSelectedMessage(null)
    setStatusName([])
  }

  useEffect(() => {
    setLoading(true)
    if (listMessageCodeCd.length > 0) {
      listContact()
    }
  }, [listMessageCodeCd])


  useEffect(() => {
    setLoading(true)
    userListMessageCode({
      variables: {
        tableName: 'status'
      }
    })
  }, [])

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog fullwidth open={dialogOpen} onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle}>
            <Typography id='email-name' variant="h5" component="div" className={classes.title}>
              {t("Email Search")}
            </Typography>
            <Tooltip title={("Close")}>
              <IconButton
                color="secondary"
                onClick={() => handleClose()}>
                <Close color='error' />
              </IconButton>
            </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" spacing={2} py={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                size='small'
                fullWidth
                id="toType"
                select
                label={t("To")}
                SelectProps={{
                  multiple: true,
                  value: messageTo,
                  onChange: handleChange,
                  renderValue: (selected) => {
                    let displayToTypes = [];
                    for (let i = 0; i < selected.length; i++) {
                      let to = codeTo.find((tType) => tType === selected[i]);
                      if (to) {
                        displayToTypes.push(to)
                      }
                    }
                    return displayToTypes.join(', ');
                  }
                }}
                variant="outlined"
              >
                {codeTo?.map((to) => (
                  <MenuItem key={to} value={to}>
                    <Checkbox checked={messageTo.includes(to)} />
                    <ListItemText primary={to} />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size='small'
                fullWidth
                id="status"
                select
                label={t("Status")}
                SelectProps={{
                  multiple: true,
                  value: statusName,
                  onChange: handleChangeStatus,
                  renderValue: (selected) => {
                    let displayToTypes = [];
                    for (let i = 0; i < selected.length; i++) {
                      let status = listMessageCodeCd.find((tType) => tType.codeCd === selected[i]);
                      if (status) {
                        displayToTypes.push(status.code)
                      }
                    }
                    return displayToTypes.join(', ');
                  }

                }}
                variant="outlined"
              >
                {listMessageCodeCd?.map((status) => (
                  <MenuItem key={status.codeCd} value={status.codeCd}>
                    <Checkbox checked={statusName.includes(status.codeCd)} />
                    <ListItemText primary={status.code} />
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            {/* <Stack direction="row" spacing={2}>
              <TextField
                size='small'
                fullWidth
                id="CcType"
                select
                label={t("Cc")}
                SelectProps={{
                  multiple: true,
                  value: messageCc,
                  onChange: handleChangeCc,
                  renderValue: (selected) => {
                    let displayCcTypes = [];
                    for (let i = 0; i < selected.length; i++) {
                      let Cc = codeCc.find((cType) => cType === selected[i]);
                      if (Cc) {
                        displayCcTypes.push(Cc)
                      }
                    }
                    return displayCcTypes.join(', ');
                  }
                }}
                variant="outlined"
              >
                {codeCc?.map((Cc) => (
                  <MenuItem key={Cc} value={Cc}>
                    <Checkbox checked={messageCc?.includes(Cc)} />
                    <ListItemText primary={Cc} />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size='small'
                fullWidth
                id="bccType"
                select
                label={t("Bcc")}
                SelectProps={{
                  multiple: true,
                  value: messageBcc,
                  onChange: handleChangeBcc,
                  renderValue: (selected) => {
                    let displayBccTypes = [];
                    for (let i = 0; i < selected.length; i++) {
                      let bcc = codeBcc.find((bType) => bType === selected[i]);
                      if (bcc) {
                        displayBccTypes.push(bcc)
                      }
                    }
                    return displayBccTypes.join(', ');
                  }

                }}
                variant="outlined"
              >
                {codeBcc?.map((bcc) => (
                  <MenuItem key={bcc} value={bcc}>
                    <Checkbox checked={messageBcc?.includes(bcc)} />
                    <ListItemText primary={bcc} />
                  </MenuItem>
                ))}
              </TextField>
            </Stack> */}
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  closeOnSelect={false}
                  ampmInClock
                  // disablePast
                  showToolbar
                  minDateTime={new Date(date)}
                  maxDateTime={serverCurrentTime}
                  timeIcon={<AccessTimeIcon />}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  value={startDate}
                  label="Start Date"
                  OpenPickerButtonProps={{ id: `email-start-time-picker` }}
                  renderInput={(props) =>
                    <TextField
                      fullWidth
                      id='email-start-date'
                      variant='outlined'
                      size='small'
                      {...props}
                      inputProps={{ ...props.inputProps, readOnly: true }}
                    />
                  }
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  closeOnSelect={false}
                  ampmInClock
                  disableIgnoringDatePartForTimeValidation
                  // disablePast
                  showToolbar
                  minDateTime={new Date(startDate)}
                  maxDateTime={serverCurrentTime}
                  timeIcon={<AccessTimeIcon />}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  value={endDate}
                  label="End Date"
                  OpenPickerButtonProps={{ id: `email-end-time-picker` }}
                  renderInput={(props) =>
                    <TextField
                      fullWidth
                      id="email-end-date"
                      variant='outlined'
                      size='small'
                      // helperText={'invalid date'}
                      {...props}
                      inputProps={{ ...props.inputProps, readOnly: true }}
                    />
                  }
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={2}  >
              <TextField id="description" label="Subject" variant="outlined" size='small' style={{ width: 550 }}
                value={selectedMessage}
                onChange={onChangeMessage}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button styles={{ float: "left" }} variant='outlined' onClick={onClearFields}>
            {t("Reset")}
          </Button>
          <Button styles={{ float: "right" }} color="primary" variant='contained' onClick={handleEmailFilter} >
            {t("Search")}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <AuctionLoader show={loading} invisible={false} />}
    </div >
  )
}
export default CommunicationsEmailFilter