import React, { useState }          from 'react'
import {
    Dialog,
    DialogContent,
    Container,
    Grid,
    DialogActions,
    Button,
    DialogTitle,
    Typography
}                                   from '@mui/material';
import ItemImages                   from './ItemImages';
import { useTranslation }           from 'react-i18next';

const ItemImagesModal = ({show, onClose, item_id, auction_id, lot_id, canUpload }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(show ? show : false);

    const handleClose = () => {
        setOpen(false);
        onClose();
    }

    return (
        <div>
            <Dialog open={open} fullWidth maxWidth='lg' disableEscapeKeyDown>
                <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                    <Typography id="add-bidder-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                        {t('Item_Images_Videos')}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{minHeight: '70vh'}}>
                    <Container maxWidth='lg'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ItemImages item_id={item_id} auction_id={auction_id} lot_id={lot_id}
                                    canUpload={canUpload}/> 
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button id="close-item-images" onClick={handleClose} style={{float: 'right'}} variant="outlined">
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ItemImagesModal;