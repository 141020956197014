import { useLazyQuery, useMutation } from "@apollo/client";
import { Add, Delete, Edit } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionActions, Button, Divider } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import AuctionLoader from "../common/auction-loader/AuctionLoader";
import AuctionSnackBar from "../common/AuctionSnackBar";
import { UserContext } from "../common/context/UserContext";
import GraphQLErrors from "../common/GraphQLErrors";
import { AuctionStatus, AppHostDomain } from "../common/StaticVariableDeclaration";
import { PublicAxiosAuctionInterceptor } from "../config/AxiosAuctionInterceptor";
import { CREATE_TERMS_CONDITIONS, DELETE_TERMS_CONDITION, UPDATE_TERMS_CONDITIONS } from "../GraphQL/Mutation";
import { GET_TERMS_AND_CONDITION } from "../GraphQL/Queries";
import QuillEditor from "./QuillEditor";
import 'quill/dist/quill.snow.css';
import AuctionConfirmDialog from '../common/AuctionConfirmDialog';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    margin: '8px'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: theme.accordinBackgroundColor,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        // marginLeft: theme.spacing(0),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const useStyles = makeStyles((theme) => ({
    btn: {
        float: 'right !important',
        position: 'relative !important',
        right: '15px !important'
    },
    quillContainer: {
        border: '0px !important'
    },
    main: {
        height: '65vh !important', overflow: 'auto !important', pointerEvents: 'auto !important'
    }
}));

const TermsConditions = (props) => {

    const [termsConditionValue, setTermsConditionValue] = useState('');
    const [isreadOnlyMode, setReadOnlyMode] = useState(true);
    const [getTermsValue, setTermsValue] = useState({});
    const { t } = useTranslation();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel2');
    const canModify = (props.canModify && process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle) ? props.canModify : false;
    const userContext = useContext(UserContext).rootContext;
    const [loading, setLoading] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const [createTermsConditions, { loading: creatingTermsAndConditions, error: errorOnCreatingTermsAndConditions }] =
        useMutation(CREATE_TERMS_CONDITIONS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.createTermsCondition !== null) {
                    setReadOnlyMode(true);
                    onSuccess({ show: true, message: t('Terms_and_Conditions_Added_Successfully'), severity: 'success' })
                    refetch();
                }
            },
    })

    const [updateTermsConditions, { loading: updatingTermsAndCondition, error: errorOnUpdatingTermsAndCondition }] =
        useMutation(UPDATE_TERMS_CONDITIONS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateTermsCondition !== null) {
                    setReadOnlyMode(true);
                    onSuccess({ show: true, message: t('Terms_and_Conditions_Updated_Successfully'), severity: 'success' })
                    refetch();
                }
            },
            refetchQueries: [GET_TERMS_AND_CONDITION]
        })

    const [deleteTermsCondition, { loading: deletingTermsAndCondition, error: errorOndeletingTermsAndCondition }] =
        useMutation(DELETE_TERMS_CONDITION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.deleteTermsCondition !== null) {
                    setReadOnlyMode(true);
                    onSuccess({ show: true, message: t('Terms_and_Conditions_Deleted_Successfully'), severity: 'success' })
                    refetch();
                }
            },
        })

    const [getTermsAndConditions, { loading: fetchingTermsAndCondition, error: errorOnFetchingTermsAndCondition, refetch }] =
        useLazyQuery(GET_TERMS_AND_CONDITION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (data.getTermsCondition !== null) {
                    if (data.getTermsCondition.id !== null && data.getTermsCondition.termsAndCondition != '' && data.getTermsCondition.termsAndCondition !== null) {
                        setReadOnlyMode(true);
                        setTermsValue(data.getTermsCondition);
                        setTermsConditionValue(data.getTermsCondition.termsAndCondition);
                        document.getElementById('auctioneerTandC').innerHTML = data.getTermsCondition.termsAndCondition;
                    } else {
                        setReadOnlyMode(true);
                        setTermsConditionValue('')
                        setTermsValue(data.getTermsCondition);
                        document.getElementById('auctioneerTandC').innerHTML = t('No_Terms_and_Conditions_Added');
                    }
                }
            }
        })

    const getPublicApiTermsAndConditions = () => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/terms/${props.auctionId}`).then((response) => {
            if (response !== null) {
                if (response.id !== null && response.termsAndCondition != '' && response.termsAndCondition !== null) {
                    setReadOnlyMode(true);
                    setTermsValue(response);
                    setTermsConditionValue(response.termsAndCondition);
                    document.getElementById('auctioneerTandC').innerHTML = response.termsAndCondition;
                } else {
                    setReadOnlyMode(false);
                    setTermsConditionValue('')
                    setTermsValue(response);
                    document.getElementById('auctioneerTandC').innerHTML = 'No Terms and Conditions Added';
                }
                setLoading(false);
            }
        }).catch(function (error) {
            setLoading(false);
            onSuccess({show: false, message: '', severity: ''})
            onSuccess({show: true, message: error, severity: 'error'})
        });
    }

    const handleSave = () => {
        if (getTermsValue.id === null || getTermsValue === undefined) {
            createTermsConditions({
                variables: {
                    auctionId: props.auctionId,
                    termsConditionInput: {
                        termsAndCondition: termsConditionValue
                    }
                }
            })
        } else {
            updateTermsConditions({
                variables: {
                    auctionId: props.auctionId,
                    termsConditionInput: {
                        id: getTermsValue.id,
                        termsAndCondition: termsConditionValue
                    }
                }
            })
        }
    }

    const handleTCValueChange = (value) => {
        setTermsConditionValue(value);
    }

    const handleDeleteTC = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To') + t('Delete_TC')} `,
            onDisAgree: resetDeleteConfirm,
            onAgree: () => {
                resetDeleteConfirm();
                if (getTermsValue.id !== null || getTermsValue !== undefined) {
                    deleteTermsCondition({
                        variables: {
                            auctionId: props.auctionId,
                            termsConditionId: getTermsValue.id,
                        }
                    })
                }
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const resetDeleteConfirm = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const onSuccess = (successMessage) => {
        setMessage({
            show: successMessage.show,
            message: successMessage.message,
            severity: successMessage.severity
        })
        if (successMessage.severity != 'error') {
            setTimeout(() => {
                resetMessage();
            }, 1500);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    const handleEdit = () => {
        setReadOnlyMode(false);
    }

    const handleCancel = () => {
        setReadOnlyMode(false);
        refetch();
    }

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            getTermsAndConditions({
                variables: {
                    auctionId: props.auctionId,
                }
            })
        } else {
            getPublicApiTermsAndConditions();
        }
    }, [])

    return (
        <React.Fragment>
            {/* <Accordion style={{ borderBottom: '1px solid #e0e0e0' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>{t('Service_Provider_Terms_and_Conditions')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div id='serviceProvidertandc'></div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <br></br> */}
            <Accordion expanded={expanded === 'panel2'}
                sx={{
                    pointerEvents: "none"
                }}
                onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>{t('Auctioneer_Terms_and_Conditions')}</Typography>
                </AccordionSummary>
                {!fetchingTermsAndCondition &&
                    <Fragment>
                        <AccordionDetails className={classes.main}>
                            {(isreadOnlyMode) &&
                                <div className={`ql-container ql-snow ${classes.quillContainer}`}>
                                    <div id='auctioneerTandC' className='ql-editor'></div>
                                </div>}
                            {((!isreadOnlyMode && canModify && !props.canEdit) || (!isreadOnlyMode && !canModify && props.canEdit))  &&
                             <QuillEditor class="intro"
                                termsConditionValue={termsConditionValue}
                                handleTCValueChange={handleTCValueChange}
                                handleDeleteTC={handleDeleteTC}
                                theme={'bubble'}
                                isreadOnlyMode={isreadOnlyMode} />}
                        </AccordionDetails>
                        <Divider />
                        {(canModify || props.canEdit ) && <AccordionActions sx={{ pointerEvents: 'auto' }}>
                            {((((termsConditionValue !== '' && isreadOnlyMode && canModify && !deletingTermsAndCondition) && !props.canEdit) || 
                            ((termsConditionValue !== '' && isreadOnlyMode && !canModify && !deletingTermsAndCondition) && props.canEdit))) && 
                                <Button size='small' id="remove" variant='contained' color='error' className={classes.btn}
                                    onClick={handleDeleteTC} startIcon={<Delete />}>
                                    {t('Remove_Terms_And_Conditions')}
                            </Button>}
                            {(((isreadOnlyMode && canModify) && !props.canEdit) || ((isreadOnlyMode && !canModify) && props.canEdit)) &&
                                <Button size='small' id="edit" variant='contained' color='primary' className={classes.btn}
                                    disabled={deletingTermsAndCondition}
                                    onClick={handleEdit} startIcon={termsConditionValue === '' ? <Add /> : <Edit />}>
                                {termsConditionValue === '' ? t('Add') : t('Edit')}
                            </Button>}
                            {((!isreadOnlyMode && canModify && !props.canEdit) || (!isreadOnlyMode && !canModify && props.canEdit)) &&
                                <Button size='small' id="cancel" variant='outlined' color='primary' className={classes.btn}
                                    onClick={handleCancel} >
                                    {t('Cancel')}
                                </Button>}
                            {((!isreadOnlyMode && canModify && !props.canEdit) || (!isreadOnlyMode && !canModify && props.canEdit)) &&
                                <Button size='small' id="save" variant='contained' color='primary' className={classes.btn}
                                    disabled={termsConditionValue === ''} onClick={handleSave} >
                                    {t('Save')}
                                </Button>}
                        </AccordionActions>}
                    </Fragment>
                }
            </Accordion>
            {(updatingTermsAndCondition || fetchingTermsAndCondition || creatingTermsAndConditions || loading || deletingTermsAndCondition) &&
                <AuctionLoader show={(updatingTermsAndCondition || fetchingTermsAndCondition || creatingTermsAndConditions || loading || deletingTermsAndCondition)} invisible={false} />}
            {(errorOnUpdatingTermsAndCondition || errorOnFetchingTermsAndCondition || errorOnCreatingTermsAndConditions || errorOndeletingTermsAndCondition) &&
                <GraphQLErrors error={(errorOnUpdatingTermsAndCondition || errorOnFetchingTermsAndCondition || errorOnCreatingTermsAndConditions || errorOndeletingTermsAndCondition)} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar>}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
        </React.Fragment>
    )
}

export default TermsConditions;
