import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import { Button, Chip, Grid, IconButton, Popover, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleFill } from 'react-icons/ri';
import AuctionFilter from '../auction-list/AuctionFilter';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../common/AuctionSnackBar';
import { UserContext } from "../common/context/UserContext";
import GraphQLErrors from '../common/GraphQLErrors';
import { AuctionStatus, Roles } from '../common/StaticVariableDeclaration';
import { PublicAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import { SEARCH_AUCTION } from '../GraphQL/Queries';
import { AuctionFilterContext } from '../common/context/AuctionFilterContext';
import { CurrencyFormatter } from '../utils/Utils';
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles((theme) => ({
    defaultBg: {
        // background: theme.table.toolbarBg,
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    toolbarButton: {
        float: 'right !important',
        paddingTop: '10px !important',
        paddingRight: '10px !important',
        paddingLeft: '10px !important',
        textTransform: 'capitalize !important'
    },
    tableToolbarTitle: {
        color: theme.table.toolbarTitleColor
    },
    removeAllClass: {
        color: theme.palette.primary.main,
        fontSize: '14px', 
        cursor: 'pointer'
    }
}));

const getAuctionTypeDescription = (auctionListType) => {
    switch (auctionListType) {
        case AuctionStatus.open: return 'Open_Auctions'
            break;
        case AuctionStatus.scheduled: return 'Scheduled_Auctions'
            break;
        case AuctionStatus.setup: return 'Auctions_Under_Setup'
            break;
        case AuctionStatus.closed: return 'Archived_Closed'
            break;
        case AuctionStatus.cancelled: return 'Cancelled_Auctions'
            break;
        case AuctionStatus.favourite: return 'Bookmark_Auctions'
            break;
        default: return 'Open_Auctions'
            break;
    }
}

const EnhancedTableToolbar = (props) => {

    const { auctionListType, createAuction, roles, triggerGetAuctionList,
        triggerFilter, setTriggerFilter, listInterval } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const userSession = useContext(UserContext).rootContext.userSession;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = 'filter-popover';
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserContext).rootContext;
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const auctionFilterContext = useContext(AuctionFilterContext);
    const [isShowFilterAppliedMsg, setIsShowFilterAppliedMsg] = useState(false);
    const [isTemp, setIsTemp] = useState(triggerFilter? triggerFilter : false);
    const currency = "inr";
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [searchAuction, { loading: searchAuctionListLoading, error: errorOnSearchAuctionList }] = useLazyQuery(SEARCH_AUCTION, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: auctions => {
            if (auctions.searchAuctions != null) {
                auctionFilterContext.setTotalNumberOfRows(auctions.searchAuctions.count);
                auctionFilterContext.setAuctionList(auctions.searchAuctions.auction);
                setFilterDetails({
                    'Country': auctionFilterContext.filterValueObject.Country !== '' ? auctionFilterContext.filterValueObject.Country : null,
                    'CountryId': auctionFilterContext.filterValueObject.CountryId !== '' ? auctionFilterContext.filterValueObject.CountryId : null,
                    'State': auctionFilterContext.filterValueObject.State !== '' ? auctionFilterContext.filterValueObject.State : null,
                    'StateId': auctionFilterContext.filterValueObject.StateId !== '' ? auctionFilterContext.filterValueObject.StateId : null,
                    'City': auctionFilterContext.filterValueObject.City !== '' ? auctionFilterContext.filterValueObject.City : null,
                    'CityId': auctionFilterContext.filterValueObject.CityId !== '' ? auctionFilterContext.filterValueObject.CityId : null,
                    'Category': auctionFilterContext.filterValueObject.Category?.length > 0 ? auctionFilterContext.filterValueObject.Category : null,
                    'startLastDateForEmd': auctionFilterContext.filterValueObject.startLastDateForEmd ? auctionFilterContext.filterValueObject.startLastDateForEmd :null,
                    'endLastDateForEmd': auctionFilterContext.filterValueObject.endLastDateForEmd ? auctionFilterContext.filterValueObject.endLastDateForEmd : null,
                    'AuctionStartDate': auctionFilterContext.filterValueObject.AuctionStartDate ? auctionFilterContext.filterValueObject.AuctionStartDate : null,
                    'AuctionEndDate': auctionFilterContext.filterValueObject.AuctionEndDate ? auctionFilterContext.filterValueObject.AuctionEndDate : null,
                    'ReserveMinPrice': auctionFilterContext.filterValueObject.ReserveMinPrice ? auctionFilterContext.filterValueObject.ReserveMinPrice : null,
                    'ReserveMaxPrice': auctionFilterContext.filterValueObject.ReserveMaxPrice ? auctionFilterContext.filterValueObject.ReserveMaxPrice : null,
                    'EmdMinPrice': auctionFilterContext.filterValueObject.EmdMinPrice ? auctionFilterContext.filterValueObject.EmdMinPrice : null,
                    'EmdMaxPrice': auctionFilterContext.filterValueObject.EmdMaxPrice ? auctionFilterContext.filterValueObject.EmdMaxPrice : null,
                    'isFilterApplied': auctionFilterContext.filterValueObject.isFilterApplied
                })
                if(isShowFilterAppliedMsg) {
                    onSuccess({show: true, message: t('Filters_Applied_SuccessFully'), severity: 'success'});
                }
            }
        }
    });

    const handleFilterPublicApiCall = (filterQueryParamValue) => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/auction/search/${auctionFilterContext.page}/${auctionFilterContext.pageSize}?status=${auctionListType}`,{params: filterQueryParamValue}).then((response) => {
            if(response.auction) {
                auctionFilterContext.setTotalNumberOfRows(response.count);
                auctionFilterContext.setAuctionList(response.auction);
                setFilterDetails({
                    'Country': auctionFilterContext.filterValueObject.Country !== '' ? auctionFilterContext.filterValueObject.Country : null,
                    'CountryId': auctionFilterContext.filterValueObject.CountryId !== '' ? auctionFilterContext.filterValueObject.CountryId : null,
                    'State': auctionFilterContext.filterValueObject.State !== '' ? auctionFilterContext.filterValueObject.State : null,
                    'StateId': auctionFilterContext.filterValueObject.StateId !== '' ? auctionFilterContext.filterValueObject.StateId : null,
                    'City': auctionFilterContext.filterValueObject.City !== '' ? auctionFilterContext.filterValueObject.City : null,
                    'CityId': auctionFilterContext.filterValueObject.CityId !== '' ? auctionFilterContext.filterValueObject.CityId : null,
                    'Category': auctionFilterContext.filterValueObject.Category?.length > 0 ? auctionFilterContext.filterValueObject.Category : null,
                    'startLastDateForEmd': auctionFilterContext.filterValueObject.startLastDateForEmd ? auctionFilterContext.filterValueObject.startLastDateForEmd : null,
                    'endLastDateForEmd': auctionFilterContext.filterValueObject.endLastDateForEmd ? auctionFilterContext.filterValueObject.endLastDateForEmd : null,
                    'AuctionStartDate': auctionFilterContext.filterValueObject.AuctionStartDate ? auctionFilterContext.filterValueObject.AuctionStartDate : null,
                    'AuctionEndDate': auctionFilterContext.filterValueObject.AuctionEndDate ? auctionFilterContext.filterValueObject.AuctionEndDate : null,
                    'ReserveMinPrice': auctionFilterContext.filterValueObject.ReserveMinPrice ? auctionFilterContext.filterValueObject.ReserveMinPrice : null,
                    'ReserveMaxPrice': auctionFilterContext.filterValueObject.ReserveMaxPrice ? auctionFilterContext.filterValueObject.ReserveMaxPrice : null,
                    'EmdMinPrice': auctionFilterContext.filterValueObject.EmdMinPrice ? auctionFilterContext.filterValueObject.EmdMinPrice : null,
                    'EmdMaxPrice': auctionFilterContext.filterValueObject.EmdMaxPrice ? auctionFilterContext.filterValueObject.EmdMaxPrice : null,
                    'isFilterApplied': auctionFilterContext.filterValueObject.isFilterApplied ? auctionFilterContext.filterValueObject.isFilterApplied : false
                })
                setLoading(false);
                if(isShowFilterAppliedMsg) {
                    onSuccess({show: true, message: t('Filters_Applied_SuccessFully'), severity: 'success'});
                }
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({show: false, message: '', severity: ''})
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        });
    }

    const handleClick = (event) => {
        auctionFilterContext.setFilterValueObject({
            CountryId: auctionFilterContext.filtersValue.CountryId ? auctionFilterContext.filtersValue.CountryId : null,
            Country: auctionFilterContext.filtersValue.Country ? auctionFilterContext.filtersValue.Country : null,
            StateId: auctionFilterContext.filtersValue.StateId ? auctionFilterContext.filtersValue.StateId : null,
            State: auctionFilterContext.filtersValue.State ? auctionFilterContext.filtersValue.State : null,
            CityId: auctionFilterContext.filtersValue.CityId ? auctionFilterContext.filtersValue.CityId : null,
            City: auctionFilterContext.filtersValue.City ? auctionFilterContext.filtersValue.City : null,
            startLastDateForEmd: auctionFilterContext.filtersValue.startLastDateForEmd ? auctionFilterContext.filtersValue.startLastDateForEmd : null,
            endLastDateForEmd: auctionFilterContext.filtersValue.endLastDateForEmd ? auctionFilterContext.filtersValue.endLastDateForEmd : null,
            AuctionStartDate: auctionFilterContext.filtersValue.AuctionStartDate ? auctionFilterContext.filtersValue.AuctionStartDate : null,
            AuctionEndDate: auctionFilterContext.filtersValue.AuctionEndDate ? auctionFilterContext.filtersValue.AuctionEndDate : null,
            EmdMinPrice: auctionFilterContext.filtersValue.EmdMinPrice ? auctionFilterContext.filtersValue.EmdMinPrice : null,
            EmdMaxPrice: auctionFilterContext.filtersValue.EmdMaxPrice ? auctionFilterContext.filtersValue.EmdMaxPrice : null,
            ReserveMinPrice: auctionFilterContext.filtersValue.ReserveMinPrice ? auctionFilterContext.filtersValue.ReserveMinPrice : null,
            ReserveMaxPrice: auctionFilterContext.filtersValue.ReserveMaxPrice ? auctionFilterContext.filtersValue.ReserveMaxPrice : null,
            Category: auctionFilterContext.filtersValue.Category ? auctionFilterContext.filtersValue.Category : []
        })
        setAnchorEl(event.currentTarget);
    };

    const setFilterDetails = (filters) => {
        auctionFilterContext.setFiltersValue(filters);
        setAnchorEl(null);
        auctionFilterContext.setSearchText(null);
    }

    const handleDelete = (deletedValue) => {
        let filterValueObjectLength = Object.keys(auctionFilterContext.filterValueObject).filter((key) => auctionFilterContext.filterValueObject[key] != '' && 
            auctionFilterContext.filterValueObject[key] != null && key != 'isFilterApplied' && key != 'address');
        let isFilterApplied = (filterValueObjectLength.length > 1) ? true : false;
        if(deletedValue === 'EmdMinPrice') {
            if(auctionFilterContext.filterValueObject.EmdMaxPrice != null) {
                if(filterValueObjectLength.length == 2 ) {
                    isFilterApplied = false;
                }
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'EmdMinPrice':  null,
                'EmdMaxPrice':  null,
                'isFilterApplied': isFilterApplied

            }))
        } else if(deletedValue === 'EmdMaxPrice') {
            if(auctionFilterContext.filterValueObject.EmdMinPrice != null) {
                if(filterValueObjectLength.length == 2 ) {
                    isFilterApplied = false;
                }
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'EmdMaxPrice':  null,
                'isFilterApplied': isFilterApplied

            }))
        } else if(deletedValue === 'ReserveMinPrice') {
            if(auctionFilterContext.filterValueObject.ReserveMaxPrice != null) {
                if(filterValueObjectLength.length == 2 ) {
                    isFilterApplied = false;
                }
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'ReserveMinPrice':  null,
                'ReserveMaxPrice':  null,
                'isFilterApplied': isFilterApplied

            }))
        } else if(deletedValue === 'startLastDateForEmd') {
            if(auctionFilterContext.filterValueObject.endLastDateForEmd != null) {
                if(filterValueObjectLength.length == 2 ) {
                    isFilterApplied = false;
                }
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'startLastDateForEmd':  null,
                'endLastDateForEmd':  null,
                'isFilterApplied': isFilterApplied

            }))
        } else if(deletedValue === 'AuctionStartDate') {
            if(auctionFilterContext.filterValueObject.AuctionEndDate != null) {
                if(filterValueObjectLength.length == 2 ) {
                    isFilterApplied = false;
                }
            } 
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'AuctionStartDate':  null,
                'AuctionEndDate':  null,
                'isFilterApplied': isFilterApplied

            }))
        } else if(deletedValue === 'Country') {
            if(filterValueObjectLength.length == 2 ) {
                isFilterApplied = false;
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'CountryId': null,
                'Country': null,
                'isFilterApplied': isFilterApplied
            }))
        } else if(deletedValue === 'State') {
            if(filterValueObjectLength.length == 2 ) {
                isFilterApplied = false;
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'StateId': null,
                'State': null,
                'isFilterApplied': isFilterApplied
            }))
        } else if(deletedValue === 'City') {
            if(filterValueObjectLength.length == 2 ) {
                isFilterApplied = false;
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                'CityId': null,
                'City': null,
                'isFilterApplied': isFilterApplied
            }))
        } else {
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                [deletedValue]:  null,
                'isFilterApplied': isFilterApplied
            }))
        }
        auctionFilterContext.setPage(1);
        auctionFilterContext.setPageSize(10);
        setIsTemp(true);
    }

    const handleFilterApiCall = () => {
        let address = null;
        if(auctionFilterContext.filterValueObject['Country'] != null || auctionFilterContext.filterValueObject['State'] != null || auctionFilterContext.filterValueObject['City'] != null) {
            address = {
                cityTown: auctionFilterContext.filterValueObject['City'],
                state: auctionFilterContext.filterValueObject['State'] ,
                country: auctionFilterContext.filterValueObject['Country']
            }
        } 
        searchAuction({
            variables: {
                pagination: { 
                    page: auctionFilterContext.page, 
                    limit: auctionFilterContext.pageSize, 
                    asc: false 
                },
                searchAuctionInput: {
                    status: auctionListType,
                    address: address,
                    startEmdAmount: auctionFilterContext.filterValueObject['EmdMinPrice'] ? auctionFilterContext.filterValueObject['EmdMinPrice']: 0,
                    endEmdAmount: auctionFilterContext.filterValueObject['EmdMaxPrice'] ? auctionFilterContext.filterValueObject['EmdMaxPrice']: null,
                    startReservePrice: auctionFilterContext.filterValueObject['ReserveMinPrice'] ? auctionFilterContext.filterValueObject['ReserveMinPrice']: 0,
                    endReservePrice: auctionFilterContext.filterValueObject['ReserveMaxPrice'] ? auctionFilterContext.filterValueObject['ReserveMaxPrice']: null,
                    startTime: auctionFilterContext.filterValueObject['AuctionStartDate'] ? auctionFilterContext.filterValueObject['AuctionStartDate']: null,
                    endTime: auctionFilterContext.filterValueObject['AuctionEndDate'] ? auctionFilterContext.filterValueObject['AuctionEndDate']: null,
                    startLastDateForEmd: auctionFilterContext.filterValueObject['startLastDateForEmd'] ? auctionFilterContext.filterValueObject['startLastDateForEmd']: null,
                    endLastDateForEmd: auctionFilterContext.filterValueObject['endLastDateForEmd'] ? auctionFilterContext.filterValueObject['endLastDateForEmd']: null,
                    itemType: auctionFilterContext.filterValueObject['Category'] ? auctionFilterContext.filterValueObject['Category'] : []
                },
                roles: (userContext.selectedMenu == 'auction' && auctionListType == AuctionStatus.open && !isAdmin) ?
                [Roles.auctioneer, Roles.support, Roles.bidder, Roles.bidderAuthorizedRepresentative] : []
            } 
        })    
    }

    const showFilterData = (key, value, object) => {
        if(key === 'ReserveMinPrice' && object['ReserveMaxPrice'] !== null) {
            return `Reserve Price : ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['ReserveMinPrice'])}
                - ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['ReserveMaxPrice'])}`  
        } else if(key === 'ReserveMinPrice' && object['ReserveMaxPrice'] === null) {
            return `Reserve Price : Above ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['ReserveMinPrice'])}`  
        } else if(key === 'ReserveMaxPrice'  && object['ReserveMinPrice'] === null) {
            return `Reserve Price : Below ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['ReserveMaxPrice'])}`  
        } else if(key === 'EmdMinPrice' && object['EmdMaxPrice'] !== null) {
            return `Emd Amount : ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['EmdMinPrice'])}
                - ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['EmdMaxPrice'])}`   
        } else if(key === 'EmdMinPrice' && object['EmdMaxPrice'] === null) {
            return`Emd Amount : Above ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['EmdMinPrice'])}`  
        } else if(key === 'EmdMaxPrice'  && object['EmdMinPrice'] === null) {
            return `Emd Amount : Below ${CurrencyFormatter(currency, auctionFilterContext.filtersValue['EmdMaxPrice'])}`  
        } else if(key === 'startLastDateForEmd' && object['endLastDateForEmd'] !== null) {
            return `Last Date to Pay EMD : ${format(new Date(auctionFilterContext.filtersValue['startLastDateForEmd']), 'dd/MM/yyyy')} - ${format(new Date(auctionFilterContext.filtersValue['endLastDateForEmd']), 'dd/MM/yyyy')}` 
        } else if(key === 'startLastDateForEmd' && object['endLastDateForEmd'] === null) {
            return`Last Date to Pay EMD : From ${format(new Date(auctionFilterContext.filtersValue['startLastDateForEmd']), 'dd/MM/yyyy')}`  
        } else if(key === 'endLastDateForEmd'  && object['startLastDateForEmd'] === null) {
            return `Last Date to Pay EMD : Till ${format(new Date(auctionFilterContext.filtersValue['endLastDateForEmd']), 'dd/MM/yyyy')}`  
        } else if(key === 'AuctionStartDate' && object['AuctionEndDate'] !== null) {
            return `Auction Date : ${format(new Date(auctionFilterContext.filtersValue['AuctionStartDate']), 'dd/MM/yyyy')} - ${format(new Date(auctionFilterContext.filtersValue['AuctionEndDate']), 'dd/MM/yyyy')}` 
        } else if(key === 'AuctionStartDate' && object['AuctionEndDate'] === null) {
            return `Auction Date : From ${format(new Date(auctionFilterContext.filtersValue['AuctionStartDate']), 'dd/MM/yyyy')}`  
        } else if(key === 'AuctionEndDate'  && object['AuctionStartDate'] === null) {
            return `Auction Date : Till ${format(new Date(auctionFilterContext.filtersValue['AuctionEndDate']), 'dd/MM/yyyy')}`  
        } else if(key === 'Category') {
            return `Category: ${auctionFilterContext.filtersValue['Category'].map(data => enumListObject.ItemType[data].uiCode)}`
        } else if(key === 'Country' || key === 'State' || key === 'City') {
            return `${key}: ${value}`
        } 
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
        auctionFilterContext.setFilterValueObject({
            CountryId: null,
            Country: null,
            StateId: null,
            State: null,
            CityId: null,
            City: null,
            AuctionStartDate: null,
            AuctionEndDate: null,
            startLastDateForEmd: null,
            endLastDateForEmd: null,
            EmdMinPrice: null,
            EmdMaxPrice: null,
            ReserveMinPrice: null,
            ReserveMaxPrice: null,
            Category: [],
            isFilterApplied: false
        })
    }

    const onSuccess = (successMessage) => {
        setMessage({
            show: successMessage.show,
            message: successMessage.message,
            severity: successMessage.severity
        })
        if (successMessage.severity != 'error') {
            setTimeout(() => {
                resetMessage();
            }, 1500);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    const handleClearAll = () => {
        setAnchorEl(null);
        auctionFilterContext.setPage(1);
        auctionFilterContext.setPageSize(10);
        setIsTemp(true);
        auctionFilterContext.setFilterValueObject({
            CountryId: null,
            Country: null,
            StateId: null,
            State: null,
            CityId: null,
            City: null,
            AuctionStartDate: null,
            AuctionEndDate: null,
            startLastDateForEmd: null,
            endLastDateForEmd: null,
            EmdMinPrice: null,
            EmdMaxPrice: null,
            ReserveMinPrice: null,
            ReserveMaxPrice: null,
            Category: [],
            isFilterApplied: false
        })
    }

    const handleQueryParamsOnDelete = () => {
        let filterQueryParamValue = {};
        if(auctionFilterContext.filterValueObject.Country != '' && auctionFilterContext.filterValueObject.Country != null) {
            filterQueryParamValue['country'] = auctionFilterContext.filterValueObject.Country;
        }
        if(auctionFilterContext.filterValueObject.State != '' && auctionFilterContext.filterValueObject.State != null) {
            filterQueryParamValue['state'] = auctionFilterContext.filterValueObject.State;
        }
        if(auctionFilterContext.filterValueObject.City != '' && auctionFilterContext.filterValueObject.City != null) {
            filterQueryParamValue['city'] = auctionFilterContext.filterValueObject.City;
        } 
        if(auctionFilterContext.filterValueObject.Category?.length > 0) {
            filterQueryParamValue['itemType'] = auctionFilterContext.filterValueObject.Category.join(',');
        }
        if(auctionFilterContext.filterValueObject.startLastDateForEmd != null) {
            filterQueryParamValue['startLastDateForEmd'] = auctionFilterContext.filterValueObject.startLastDateForEmd;
        } 
        if(auctionFilterContext.filterValueObject.endLastDateForEmd != null) {
            filterQueryParamValue['endLastDateForEmd'] = auctionFilterContext.filterValueObject.endLastDateForEmd;
        }
        if(auctionFilterContext.filterValueObject.AuctionStartDate != null) {
            filterQueryParamValue['startTime'] = auctionFilterContext.filterValueObject.AuctionStartDate;
        }
        if(auctionFilterContext.filterValueObject.AuctionEndDate != null) {
            filterQueryParamValue['endTime'] = auctionFilterContext.filterValueObject.AuctionEndDate;
        }
        if(auctionFilterContext.filterValueObject.ReserveMinPrice != null) {
            filterQueryParamValue['startReservePrice'] = auctionFilterContext.filterValueObject.ReserveMinPrice;
        }
        if(auctionFilterContext.filterValueObject.ReserveMaxPrice != null) {
            filterQueryParamValue['endReservePrice'] = auctionFilterContext.filterValueObject.ReserveMaxPrice;
        }
        if(auctionFilterContext.filterValueObject.EmdMinPrice != null) {
            filterQueryParamValue['startEmdAmount'] = auctionFilterContext.filterValueObject.EmdMinPrice;
        }
        if(auctionFilterContext.filterValueObject.EmdMaxPrice != null) {
            filterQueryParamValue['endEmdAmount'] = auctionFilterContext.filterValueObject.EmdMaxPrice;
        }
        handleFilterPublicApiCall(filterQueryParamValue);
    }

    // start code from filter 
    const handleFiltersSave = () => {
        setIsShowFilterAppliedMsg(true);
        let checkingEmptyFilteredList = Object.keys(auctionFilterContext.filterValueObject).filter((key) => auctionFilterContext.filterValueObject[key] != '' && auctionFilterContext.filterValueObject[key] != null);
        if(checkingEmptyFilteredList.length > 0) {
            setIsTemp(true);
            setAnchorEl(null);
            let address = null;
            let startLastDateForEmd = null;
            let endLastDateForEmd = null;
            let auctionStartDate = null;
            let auctionEndDate = null;
            if(auctionFilterContext.filterValueObject.CountryId != null || auctionFilterContext.filterValueObject.StateId != '' || auctionFilterContext.filterValueObject.CityId != '') {
                address = {
                    cityTown: auctionFilterContext.filterValueObject.City ? auctionFilterContext.filterValueObject.City : null,
                    state: auctionFilterContext.filterValueObject.State ? auctionFilterContext.filterValueObject.State : null,
                    country: auctionFilterContext.filterValueObject.Country ? auctionFilterContext.filterValueObject.Country : null
                }
            } 
            if(auctionFilterContext.filterValueObject.startLastDateForEmd != null ){
                startLastDateForEmd = new Date(auctionFilterContext.filterValueObject.startLastDateForEmd)
            }
            if(auctionFilterContext.filterValueObject.endLastDateForEmd != null ) {
                endLastDateForEmd = new Date(auctionFilterContext.filterValueObject.endLastDateForEmd)
                endLastDateForEmd.setUTCHours(23,59,59,0);
                endLastDateForEmd.setUTCDate(endLastDateForEmd.getUTCDate()+1);
                endLastDateForEmd.setHours(endLastDateForEmd.getHours()-5);
                endLastDateForEmd.setMinutes(endLastDateForEmd.getMinutes()-30);
            }
            if(auctionFilterContext.filterValueObject.AuctionStartDate != null) {
                auctionStartDate = new Date(auctionFilterContext.filterValueObject.AuctionStartDate)
            } 
            if(auctionFilterContext.filterValueObject.AuctionEndDate != null) {
                auctionEndDate = new Date(auctionFilterContext.filterValueObject.AuctionEndDate)
                auctionEndDate.setUTCHours(23,59,59,0);
                auctionEndDate.setUTCDate(auctionEndDate.getUTCDate()+1);
                auctionEndDate.setHours(auctionEndDate.getHours()-5);
                auctionEndDate.setMinutes(auctionEndDate.getMinutes()-30);
            }
            auctionFilterContext.setFilterValueObject(prevstate => ({
                ...prevstate,
                address: address,
                startLastDateForEmd: startLastDateForEmd,
                endLastDateForEmd: endLastDateForEmd,
                AuctionStartDate: auctionStartDate,
                AuctionEndDate: auctionEndDate,
                isFilterApplied: true
            }))
            auctionFilterContext.setPage(1);
            auctionFilterContext.setPageSize(10);
        } else {
            showSuccessMsg({show: true, message: t('No_Filters_Chosen_to_Apply_Filter'), severity: 'error'})
        }
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setTimeout(() => {
                setMessage(msg);
            }, 100)
        }
    }

    useEffect(() => {
        if(auctionFilterContext.filtersValue.isFilterApplied) {
            auctionFilterContext.setFilterValueObject({
                CountryId: auctionFilterContext.filtersValue.CountryId ? auctionFilterContext.filtersValue.CountryId : null,
                Country: auctionFilterContext.filtersValue.Country ? auctionFilterContext.filtersValue.Country : null,
                StateId: auctionFilterContext.filtersValue.StateId ? auctionFilterContext.filtersValue.StateId : null,
                State: auctionFilterContext.filtersValue.State ? auctionFilterContext.filtersValue.State : null,
                CityId: auctionFilterContext.filtersValue.CityId ? auctionFilterContext.filtersValue.CityId : null,
                City: auctionFilterContext.filtersValue.City ? auctionFilterContext.filtersValue.City : null,
                startLastDateForEmd: auctionFilterContext.filtersValue.startLastDateForEmd ? auctionFilterContext.filtersValue.startLastDateForEmd : null,
                endLastDateForEmd: auctionFilterContext.filtersValue.endLastDateForEmd ? auctionFilterContext.filtersValue.endLastDateForEmd : null,
                AuctionStartDate: auctionFilterContext.filtersValue.AuctionStartDate ? auctionFilterContext.filtersValue.AuctionStartDate : null,
                AuctionEndDate: auctionFilterContext.filtersValue.AuctionEndDate ? auctionFilterContext.filtersValue.AuctionEndDate : null,
                EmdMinPrice: auctionFilterContext.filtersValue.EmdMinPrice ? auctionFilterContext.filtersValue.EmdMinPrice : null,
                EmdMaxPrice: auctionFilterContext.filtersValue.EmdMaxPrice ? auctionFilterContext.filtersValue.EmdMaxPrice : null,
                ReserveMinPrice: auctionFilterContext.filtersValue.ReserveMinPrice ? auctionFilterContext.filtersValue.ReserveMinPrice : null,
                ReserveMaxPrice: auctionFilterContext.filtersValue.ReserveMaxPrice ? auctionFilterContext.filtersValue.ReserveMaxPrice : null,
                Category: auctionFilterContext.filtersValue.Category ? auctionFilterContext.filtersValue.Category : [],
                isFilterApplied: true

            })
            setIsShowFilterAppliedMsg(false);
            setIsTemp(true);
        }
    }, [auctionFilterContext.page, auctionFilterContext.pageSize])

    useEffect(() => {
        if(auctionFilterContext.filtersValue.isFilterApplied && auctionFilterContext.refresh) {
            auctionFilterContext.setRefresh(false);
            auctionFilterContext.setFilterValueObject({
                CountryId: auctionFilterContext.filtersValue.CountryId ? auctionFilterContext.filtersValue.CountryId : null,
                Country: auctionFilterContext.filtersValue.Country ? auctionFilterContext.filtersValue.Country : null,
                StateId: auctionFilterContext.filtersValue.StateId ? auctionFilterContext.filtersValue.StateId : null,
                State: auctionFilterContext.filtersValue.State ? auctionFilterContext.filtersValue.State : null,
                CityId: auctionFilterContext.filtersValue.CityId ? auctionFilterContext.filtersValue.CityId : null,
                City: auctionFilterContext.filtersValue.City ? auctionFilterContext.filtersValue.City : null,
                startLastDateForEmd: auctionFilterContext.filtersValue.startLastDateForEmd ? auctionFilterContext.filtersValue.startLastDateForEmd : null,
                endLastDateForEmd: auctionFilterContext.filtersValue.endLastDateForEmd ? auctionFilterContext.filtersValue.endLastDateForEmd : null,
                AuctionStartDate: auctionFilterContext.filtersValue.AuctionStartDate ? auctionFilterContext.filtersValue.AuctionStartDate : null,
                AuctionEndDate: auctionFilterContext.filtersValue.AuctionEndDate ? auctionFilterContext.filtersValue.AuctionEndDate : null,
                EmdMinPrice: auctionFilterContext.filtersValue.EmdMinPrice ? auctionFilterContext.filtersValue.EmdMinPrice : null,
                EmdMaxPrice: auctionFilterContext.filtersValue.EmdMaxPrice ? auctionFilterContext.filtersValue.EmdMaxPrice : null,
                ReserveMinPrice: auctionFilterContext.filtersValue.ReserveMinPrice ? auctionFilterContext.filtersValue.ReserveMinPrice : null,
                ReserveMaxPrice: auctionFilterContext.filtersValue.ReserveMaxPrice ? auctionFilterContext.filtersValue.ReserveMaxPrice : null,
                Category: auctionFilterContext.filtersValue.Category ? auctionFilterContext.filtersValue.Category : [],
                isFilterApplied: true

            })
            setIsShowFilterAppliedMsg(false);
            setIsTemp(true);
        }
    }, [auctionFilterContext.refresh])

    useEffect(() => {
        if(auctionFilterContext.filterValueObject.isFilterApplied) {
            setIsTemp(true);
        }
    }, [])

    useEffect(() => {
        if(isTemp) {
            setIsShowFilterAppliedMsg(false);
            clearInterval(listInterval.current);
            if(auctionFilterContext.filterValueObject.isFilterApplied) {
                setTriggerFilter(false);
                if(userContext.userSession.isAuthenticated) {
                    handleFilterApiCall();
                    listInterval.current = setInterval(() => {
                        handleFilterApiCall();
                    }, 120000);
                } else {
                    handleQueryParamsOnDelete();
                    listInterval.current = setInterval(() => {
                        handleQueryParamsOnDelete();
                    }, 120000);
                }
            } else {
                auctionFilterContext.setFiltersValue({
                    'Country': null,
                    'CountryId': null,
                    'State': null,
                    'StateId': null,
                    'City': null,
                    'CityId': null,
                    'Category': null,
                    'startLastDateForEmd': null,
                    'endLastDateForEmd': null,
                    'AuctionStartDate': null,
                    'AuctionEndDate': null,
                    'ReserveMinPrice': null,
                    'ReserveMaxPrice': null,
                    'EmdMinPrice': null,
                    'EmdMaxPrice': null,
                    'isFilterApplied': false
                })
                triggerGetAuctionList();
                listInterval.current = setInterval(() => {
                    triggerGetAuctionList()
                }, 120000);
            }
            setIsTemp(false);
        }
    }, [auctionFilterContext.filterValueObject, isTemp]) 

    return (
        <GridToolbarContainer sx={{ padding: '0px' }} className={classes.defaultBg}>
            <Grid container spacing={2} sx={{margin: '10px 20px'}}>
                <Grid item xs={4} sx={{ paddingTop: '0px !important', display: 'inline-flex', alignItems: 'center' }}>
                    <GavelRoundedIcon sx={{ float: 'left' }} className={classes.tableToolbarTitle} />
                    <Typography className={classes.tableToolbarTitle}
                        sx={{ flex: '1 1 100%', float: 'left !important' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        float="left"
                    >
                        { t(getAuctionTypeDescription(auctionListType)) }
                        {!auctionFilterContext.refresh && 
                            <Tooltip title={t('Refresh')} arrow>
                                <IconButton id="refresh-auction-list" size="medium"
                                    onClick={() => auctionFilterContext.setRefresh(true)}
                                    style={{ border: '1px solid whitesmoke', marginLeft: '10px' }}
                                    color="secondary">
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={8} sx={{ paddingTop: '0px !important' }}>
                    {userSession.isAuthenticated && (roles.includes(Roles.auctioneer) || roles.length == 0) &&
                        <Button
                            id='create-auction'
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ textTransform: 'none', float: 'right !important'}}
                            onClick={createAuction}
                            >{t('Create_Auction')}
                        </Button>
                    }
                    {roles.length == 0 && <React.Fragment>
                        <Button id="auction-filter"
                            variant="text"
                            aria-describedby={id}
                            sx={{textTransform: 'none', float: 'right !important', paddingBottom: '4px',
                             marginRight: '10px', background: anchorEl != null ? 'rgb(25 110 210 / 26%)' : 'none'}}
                            startIcon={<FilterAltIcon color="primary" style={{fontSize: '18px'}}/>}
                            onClick={handleClick}
                            >
                                {t('Filter')}
                        </Button>
                        {open && <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '80%', border: "1px solid #d7c8f5" }
                            }}
                            BackdropProps={{
                                invisible: true,
                                sx:{
                                    opacity: '0.3 !important',
                                    backgroundColor: 'rgb(0, 0, 0, 0.5) !important',
                                    zIndex: '-1 !important',
                                }
                            }}
                        >
                            <Tooltip title={t('Close')} arrow>
                                <IconButton onClick={handlePopoverClose} style={{float: 'right', fontSize: '30px', color: 'mediumpurple',
                                        marginRight: '10px', padding: '0px', position: 'relative', top: '10px'}}>
                                    <RiCloseCircleFill />
                                </IconButton>
                            </Tooltip>
                            <AuctionFilter setFilterValueObject={auctionFilterContext.setFilterValueObject} filterValueObject={auctionFilterContext.filterValueObject}
                                handleFiltersSave={handleFiltersSave} handleClearAll={handleClearAll}/>
                        </Popover>}
                    </React.Fragment>}
                    {props.children}
                    {/* <GridToolbarExport className={classes.toolbarButton} />
                    <GridToolbarFilterButton className={classes.toolbarButton} /> */}
                    {/* <GridToolbarColumnsButton className={classes.toolbarButton} />
                    onDelete={()=> handleDelete(data)} */}
                </Grid>
            </Grid>

            {auctionFilterContext.filtersValue.isFilterApplied && <Grid container spacing={1} sx={{paddingLeft: '5px', paddingRight: '5px'}}>
                <Grid item xs={12} sx={{borderTop: '1px solid #dbdae0', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px' }}>
                    <Typography style={{fontSize: '13px', color: 'dimgrey'}}>{t('Active_Filters')}
                        &nbsp;<span>
                            {Object.keys(auctionFilterContext.filtersValue).map((key) => {
                                    if (auctionFilterContext.filtersValue[key] !== undefined && auctionFilterContext.filtersValue[key] !== null && auctionFilterContext.filtersValue[key] !== '' && key != 'CountryId' 
                                        && key != 'StateId' && key != 'CityId' && key != 'isFilterApplied'
                                    ) {
                                        return <React.Fragment>
                                            { showFilterData(key ,auctionFilterContext.filtersValue[key], auctionFilterContext.filtersValue) != null &&
                                                <Chip id={`chip-value${key}`} style={{margin: '1px'}} size="small"
                                                    label={showFilterData(key ,auctionFilterContext.filtersValue[key], auctionFilterContext.filtersValue)}
                                                    onDelete={()=> handleDelete(key)}
                                                />
                                            } &nbsp;
                                            </React.Fragment>
                                    } 
                                })
                            }
                            <span id="remove-all" className={classes.removeAllClass} onClick={handleClearAll}>{'Remove All...'}</span>
                        </span>
                    </Typography>
                </Grid>
            </Grid>}
            {(searchAuctionListLoading || loading) && <AuctionLoader show={(searchAuctionListLoading || loading)} 
                    invisible={false} />}
            {(errorOnSearchAuctionList) && <GraphQLErrors error={(errorOnSearchAuctionList)} show={false} />} 
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </GridToolbarContainer>
    );
}

EnhancedTableToolbar.propTypes = {
    auctionListType: PropTypes.string.isRequired,
};

export default React.memo(EnhancedTableToolbar)