import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { PublicAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import AuctionSnackBar from '../common/AuctionSnackBar';


const moment = require("moment-timezone");

const CurrentStack = styled(Stack)(({ theme }) => ({
    padding: '0.25rem 0.5rem',
    // background: theme.palette.type === 'light' ? '#f4f4f8' : '#262626',
    borderRadius: '1rem',
    borderLeft: '4px solid whitesmoke',
    borderBottom: '4px solid whitesmoke',
    borderTop: '1px solid whitesmoke',
    borderRight: '1px solid whitesmoke',
    marginRight: '10px',
}));

const AuctionCurrentTimer = ({ currentDate }) => {

    const [intervalId, setIntervalId] = useState(null);
    const [date, setDate] = useState(new Date(currentDate));
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const resetMessage = () => {
        setMessage({ show: false, message: '', severity: '' })
    }

    useEffect(() => {
        return () => clearInterval(intervalId);
    }, [intervalId])

    useEffect(() => {
        const diffInServerToSystemTime = Math.abs(new Date().getTime() - new Date(currentDate).getTime());
        const diffType = new Date(currentDate).getTime() > new Date().getTime();
        const timer = setInterval(() => {
            if(diffType) {
                setDate( new Date(new Date().getTime() +  diffInServerToSystemTime) );
            } else {
                setDate( new Date(new Date().getTime() -  diffInServerToSystemTime) );
            }
        }, 1000)
        sessionStorage.setItem('eAuctionDiffInServerTime', diffInServerToSystemTime);
        sessionStorage.setItem('eAuctionServerTimeType', diffType);
        setIntervalId(timer);
    }, [currentDate]);

    const updateChangeInSystemTime = () => { 
        PublicAxiosAuctionInterceptor.get(`rest/time`).then((response) => {
            if (response.currentTime != null) {
                let diff = Math.abs(new Date().getTime() - new Date(response.currentTime).getTime());
                let compareDiff = Math.abs(diff - Number(sessionStorage.getItem('eAuctionDiffInServerTime')));
                if(compareDiff > 500) {
                    const diffType = new Date(response.currentTime).getTime() > new Date().getTime();
                    const timer = setInterval(() => {
                        if(diffType) {
                            setDate( new Date(new Date().getTime() +  diff) );
                        } else {
                            setDate( new Date(new Date().getTime() -  diff) );
                        }
                    }, 1000)
                    sessionStorage.setItem('eAuctionDiffInServerTime', diff);
                    sessionStorage.setItem('eAuctionServerTimeType', diffType);
                    setIntervalId(timer);
                }
            }
        }).catch(function (error) {
            setMessage({show: true, message: error.message, severity: 'error'})
        });
    }

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                updateChangeInSystemTime();
            }
          };
          document.addEventListener('visibilitychange', handleVisibilityChange);
          return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [])

    return (
        <CurrentStack direction='row'>
            <Typography color='textSecondary' fontSize='14px'>
                {format(new Date(date), 'dd MMM yyy - hh:mm:ss a')} ({dateTimeZoneValue}) 
            </Typography>
            {message.show && <AuctionSnackBar
                show={message.show}
                message={message.message}
                severity={message.severity}
                handleResetOnClose={resetMessage}>
            </AuctionSnackBar>}
        </CurrentStack>
    )
}

export default AuctionCurrentTimer;