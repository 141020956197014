import { Cancel, EventAvailable } from '@mui/icons-material';
import SensorsIcon from '@mui/icons-material/Sensors';
import StarIcon from '@mui/icons-material/Star';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import UpdateIcon from '@mui/icons-material/Update';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { default as React, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { UserContext } from "../../common/context/UserContext";
import { AuctionStatus, Roles } from '../../common/StaticVariableDeclaration';
import EAuctionListTable from '../auction-list-table/EAuctionListTable';
import './AuctionListContainer.css';
import AuctionListSideNav from './side-nav/AuctionListSideNav';
import { ListContainer } from './side-nav/AuctionListSideNavStyle';
import { NbidRedirectDialog } from './NbidRedirectDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    flexGrow: 1
  },
  container: {
    left: '0px !important',
    width: '100% !important'
  },
}));

const AuctionListContainer = (props) => {

  const userContextValues = useContext(UserContext).rootContext;
  const classes = useStyles();
  const { t } = useTranslation();
  const rolesList = props.location.state.userRole;
  const successMessage = props.location.state.successMessage;
  const [menuCollapse, setMenuCollapse] = useState(false)
  const [selectedValue, setSelectedValue] = useState(props.location.state.auctionListType ?
    (userContextValues.userSession.isAuthenticated ? props.location.state.auctionListType : AuctionStatus.scheduled)
    : AuctionStatus.open);
  const [pagination, setPagination] = useState(props.location.state.pagination);
  const [filtersValue, setFiltersValue] = useState(props.location.state.filtersValue);
  // const [nBidRedirect, setNBidRedirect] = useState(sessionStorage.getItem('nbidNav') == 'show' ? true : false);
  const isCustomerSupport = (userContextValues?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
  const isAdmin = (userContextValues?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;

  const AuctionListMenuItemsRender = [
    { title: t('Live'), id: 'tab-live', icon: <SensorsIcon />, value: AuctionStatus.open, show: true },
    { title: t('Upcoming'), id: 'tab-upcoming', icon: <UpcomingIcon />, value: AuctionStatus.scheduled, show: true },
    { title: t('Under_Setup'), id: 'tab-setup', icon: <UpdateIcon />, value: AuctionStatus.setup,
      show: (rolesList.includes(Roles.auctioneer) || isCustomerSupport || isAdmin)
    },
    { title: t('Archived_Closed'), id: 'tab-closed', icon: <EventAvailable />, value: AuctionStatus.closed,
      show: (rolesList.includes(Roles.auctioneer) || props.location.state.isMyBid || isCustomerSupport || isAdmin)
    },
    { title: t('Cancelled'), id: 'tab-cancelled', icon: <Cancel />, value: AuctionStatus.cancelled,
      show: (rolesList.includes(Roles.auctioneer) || props.location.state.isMyBid || isCustomerSupport || isAdmin)
    },
    {
      title: t('Bookmark'), id: 'tab-bookmark', icon: <StarIcon />, value: AuctionStatus.favourite,
      show: (userContextValues.userSession.isAuthenticated && !rolesList.includes(Roles.auctioneer) && !props.location.state.isMyBid) ? true : false
    }
  ]

  const handleChange = (newValue) => {
    setPagination({ page: 1, pageSize: 10 })
    setSelectedValue(newValue);
    setFiltersValue({})
  };

  // const handleNBidRedirectClose = () => {
  //   sessionStorage.setItem('nbidNav', 'hide');
  //   setNBidRedirect(false);
  // }

  // useEffect(() => {
  //   console.log('Auction-list-container....', userContextValues);
  // }, [])

  return (
    <React.Fragment>
      {selectedValue != null && <Box className={classes.root}>
        {userContextValues.userSession.isAuthenticated && <AuctionListSideNav menuItemsRender={AuctionListMenuItemsRender} handleChange={handleChange} selectedValue={selectedValue}
          menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} />}
        <ListContainer iscollapse={+menuCollapse} className={!userContextValues.userSession.isAuthenticated ? classes.container : ''}>
          {selectedValue === AuctionStatus.open && <EAuctionListTable
            auctionListType={AuctionStatus.open}
            roles={rolesList}
            menuCollapse={menuCollapse}
            pagination={pagination}
            filtersValue={filtersValue} 
            isMyBid={props.location.state.isMyBid}
            handleChange={handleChange}
          />}
          {selectedValue === AuctionStatus.scheduled && <EAuctionListTable
            auctionListType={AuctionStatus.scheduled}
            roles={rolesList}
            menuCollapse={menuCollapse}
            pagination={pagination}
            filtersValue={filtersValue} 
            isMyBid={props.location.state.isMyBid}
            handleChange={handleChange}
          />}
          {selectedValue === AuctionStatus.setup && <EAuctionListTable
            auctionListType={AuctionStatus.setup}
            roles={rolesList}
            menuCollapse={menuCollapse}
            pagination={pagination}
            filtersValue={filtersValue} 
            isMyBid={props.location.state.isMyBid}
            handleChange={handleChange}
          />}
          {selectedValue === AuctionStatus.closed && <EAuctionListTable
            auctionListType={AuctionStatus.closed}
            roles={rolesList}
            menuCollapse={menuCollapse}
            pagination={pagination}
            filtersValue={filtersValue} 
            isMyBid={props.location.state.isMyBid}
            handleChange={handleChange}
          />}
          {selectedValue === AuctionStatus.cancelled && <EAuctionListTable
            auctionListType={AuctionStatus.cancelled}
            roles={rolesList}
            menuCollapse={menuCollapse}
            pagination={pagination}
            filtersValue={filtersValue} 
            isMyBid={props.location.state.isMyBid}
            handleChange={handleChange}
          />}
          {selectedValue === AuctionStatus.favourite && <EAuctionListTable
            auctionListType={AuctionStatus.favourite}
            roles={rolesList}
            menuCollapse={menuCollapse}
            pagination={pagination}
            filtersValue={filtersValue} 
            isMyBid={props.location.state.isMyBid}
            handleChange={handleChange}
          />}
        </ListContainer>
        {(successMessage && successMessage.show) && <AuctionSnackBar show={successMessage.show} message={successMessage.message} severity={'success'} />}
        {/* { nBidRedirect &&  <NbidRedirectDialog open={nBidRedirect} onClose={handleNBidRedirectClose} />} */}
      </Box>}
    </React.Fragment>
  );

}
export default React.memo(AuctionListContainer);