import React, {
    useState,
    Fragment,
    useContext,
    useEffect
}                                   from 'react';
import {
    Container,
    DialogTitle,
    Dialog,
    Typography,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepButton,
    Box,
    Divider
}                                   from '@mui/material';
import { makeStyles }               from '@mui/styles';
import { useTranslation }           from 'react-i18next';
import { useForm }                  from 'react-hook-form';
import { useMutation }              from '@apollo/client';
import BidderInput                  from '../add-bidder/BidderInput';
import {
    IndividualIdentificationType,
    IdentificationTypes,
    CompanyIdentification,
    AddressType
}                                   from '../../common/StaticVariableDeclaration';
import {
    CREATE_BIDDER,
    CREATE_BIDDER_AUTH_REP
}                                   from '../../GraphQL/Mutation';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../common/GraphQLErrors';
import BidderViewDocumentList       from '../../auction-document/bidder-documents/BidderViewDocumentList';
import EMDList                      from '../emd-amount/emd-list/EMDList';
import AuctionConfirmDialog         from '../../common/AuctionConfirmDialog';
import { UserContext }              from '../../common/context/UserContext';
import { AxiosAuctionInterceptor }  from '../../config/AxiosAuctionInterceptor';
import AuctionSnackBar              from '../../common/AuctionSnackBar';
import TourIcon from '@mui/icons-material/Tour';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '4px !important'
    },
    paper: {
        borderRadius: '0px',
        overflow: 'scroll'
    },
    container: {
        margin: '10px'
    },
    backBtn: {
        float: 'left',
        margin: '0px 4px 0px 4px !important'
    },
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    },
    finishBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const getSteps = (individual) => {
    if (individual) {
        return [{label: 'Bidder_Details', step: 'bidderDetails'}, {label: 'Documents', step: 'documents'},
            {label: 'EMD_Details', step: 'emdDetails'}];
    } else {
        return [{label: 'Bidder_Details', step: 'bidderDetails'}, {label: 'Bidder_Auth_Rep_Details', step: 'bidderAuthRepDetails'},
                {label: 'Documents', step: 'documents'}, {label: 'EMD_Details', step: 'emdDetails'}];
    }
}

const RequestForBid = ({ onClose, show, auction_id, onSuccess, seller_id, auctionCreationDate, updateAuctionContext,
        allowUpdateReceivedEMDField }) => {
    const context = useContext(UserContext).rootContext;
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps(context?.userSession?.session?.individual);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({showMsg: false, message: '', severity: ''})

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [tourGuide, setTourGuide] = useState({
        triggerEvent: false,
        triggerButton: false
    });

    const { register: bidderRegister, control: bidderControl, getValues: getBidderValues,
        setValue: setBidderValues, trigger: bidderTrigger, reset: bidderReset,
        formState: { errors: bidderError, isValid: bidderIsValid } } = useForm({
            mode: 'onChange',
            defaultValues: {
                individual: false,
                name: '',
                cin: '',
                pan: '',
                emailId: '',
                phoneNumber: '',
                emdAmount: 0,
                attention: '',
                phone: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                landmark: '',
                cityTown: '',
                postalCode: '',
                state: '',
                addressType: AddressType.primaryContact,
                personId: '',
                addressId: '',
                loginId: ''
            }
        });
    
    const { register: bidderAuthRepRegister, control: bidderAuthRepControl, getValues: getBidderAuthRepValues, 
            setValue: setBidderAuthRepValues, trigger: bidderAuthRepTrigger, reset: bidderAuthRepReset,
            formState: { errors: bidderAuthRepError, isValid: bidderAuthRepIsValid  }} = useForm({
        mode: 'onChange',
        defaultValues: {
            roleEdgeId: '',
            individual: true,
            name: '',
            cin: '',
            pan: '',
            emailId: '',
            phoneNumber: '',
            attention: '',
            phone: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',
            cityTown: '',
            postalCode: '',
            state: '',
            addressType: AddressType.primaryContact,
            personId: '',
            addressId: '',
            country: 'India'
        }
    });

    const [requestForBid, { loading: requestingToBid, error: errorOnRequestingToBid }] = useMutation(CREATE_BIDDER, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addBidder != null) {
                setBidderValues('id', data.addBidder.id);
                setBidderValues('personId', data.addBidder.personId);
                setBidderValues('loginId', data.addBidder.loginId)
                bidderTrigger();
                handleNext();
                // onSuccess({show: true, message:t('Request_Submitted_Successfully'), severity: 'success'})
                // handleClose();
            }
        }
    })

    const [createBidderAuthRep, {loading: creatingBidderAuthRep, error: errorOnCreatingBidderAuthRep}] = useMutation(CREATE_BIDDER_AUTH_REP, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addAuthorizedRepresentative != null) {
                setBidderAuthRepValues('personId', data.addAuthorizedRepresentative.personId);
                setBidderAuthRepValues('roleEdgeId', data.addAuthorizedRepresentative.roleEdgeId);
                handleNext();
            }
        }
    })

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const requestConfirmation = () => {
        if (context?.userSession?.session?.individual) {
            setSeekConfitmation({
                show: true,
                title: t('Confirmation'),
                message:<Fragment>
                            <Typography>
                                {t('Confirm_Authorized_Person_Detail_While_Request_To_Bid')} "<strong>{getBidderValues('name')}</strong>".
                            </Typography>
                            <br/>
                            <Grid container>
                                <Grid item xs={6} style={{textAlign: 'right', padding: '4px'}}>
                                    <Typography ><strong>{t('Authorized_Representative_Name')} : </strong></Typography>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'left', padding: '4px'}}>
                                    <Typography>{context?.userSession?.session?.name}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right', padding: '4px'}}>
                                    <Typography ><strong>{t('Email_Id')} : </strong></Typography>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'left', padding: '4px'}}>
                                    <Typography>{context?.userSession?.session?.email}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right', padding: '4px'}}>
                                    <Typography ><strong>{t('Phone_Number')} : </strong></Typography>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'left', padding: '4px'}}>
                                    <Typography>{context?.userSession?.session?.phoneNumber}</Typography>
                                </Grid>
                            </Grid>
                    </Fragment>
                ,
                onAgree: () => sendRequest(),
                onDisAgree: () => resetSeekConfirmation(),
                isCancel: true,
                agreeBtnLabel: t('OK'),
                disAgreeBtnLabel: t('Cancel')
            })
        } else {
            sendRequest();
        }
    }

    const sendRequest = () => {
        resetSeekConfirmation();
        requestForBid({
            variables: {
                bidderInput: {
                    personId: getBidderValues('personId') ? getBidderValues('personId') : null,
                    individual: getBidderValues('individual'),
                    name: getBidderValues('name'),
                    identification: getIdentifications(getBidderValues),
                    address: [getAddress(getBidderValues)],
                    email: [{
                        email: getBidderValues('emailId')
                    }],
                    phoneNumber: [{
                        phoneNumber: getBidderValues('phoneNumber')
                    }]
                },
                auctionId: auction_id
            }
        })
    }

    const createAuthRep = () => {
        createBidderAuthRep({
            variables: {
                authorizedRepresentative: {
                    personId: getBidderAuthRepValues('personId') ? getBidderAuthRepValues('personId') : null,
                    individual: getBidderAuthRepValues('individual'),
                    name: getBidderAuthRepValues('name'),
                    identification: getIdentifications(getBidderAuthRepValues),
                    address: [getAddress(getBidderAuthRepValues)],
                    email: [{
                        email: getBidderAuthRepValues('emailId')
                    }],
                    phoneNumber: [{
                        phoneNumber: getBidderAuthRepValues('phoneNumber')
                    }]
                },
                auctionId: auction_id,
                bidderId: getBidderValues('id')
            }
        })
    }

    const getIdentifications = (getIdentificationValue) => {
        let identifications = [];
        if (getIdentificationValue('cin') && getIdentificationValue('cin') != '') {
            identifications.push({
                identificationType: CompanyIdentification.cin.type,
                identification: getIdentificationValue('cin')
            })
        }
        if (getIdentificationValue('pan') && getIdentificationValue('pan') != '') {
            identifications.push({
                identificationType: IndividualIdentificationType.pan.type,
                identification: getIdentificationValue('pan')
            })
        }
        return identifications;
    }

    const getAddress = (getAddressValue) => {
        return {
            attention: getAddressValue('attention'),
            phone: getAddressValue('phone'),
            email: getAddressValue('email'),
            addressLine1: getAddressValue('addressLine1'),
            addressLine2: getAddressValue('addressLine2'),
            landmark: getAddressValue('landmark'),
            cityTown: getAddressValue('cityTown'),
            postalCode: getAddressValue('postalCode'),
            state: getAddressValue('state'),
            province: getAddressValue('province'),
            addressType: AddressType.primaryContact,
            country: getAddressValue('country')
        }
    }

    const handleClose = () => {
        setOpen(false);
        onClose(false);
        if(getBidderValues('id')) {
            updateAuctionContext(true);
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    useEffect(() => {
        if (!context?.userSession?.session?.individual) {
            getUserInformation(context?.userSession?.session.userId);
        }
    }, [])

    const getUserInformation = (userId) => {
        if (userId != null) {
            setLoading(true);
            AxiosAuctionInterceptor.get(`user/${userId}`).then(response => {
                if (response != null) {
                    let person = response;
                    setBidderValues('personId', person.personId, { shouldValidate: true, shouldDirty: true });
                    setBidderValues('name', person.name, { shouldValidate: true, shouldDirty: true });
                    for (let i = 0; i < person.email.length; i++) {
                        if (person.email[i].preferred) {
                            setBidderValues('emailId', person.email[i].email, { shouldValidate: true, shouldDirty: true });
                        }
                    }
                    for (let i = 0; i < person.phoneNumber.length; i++) {
                        if (person.phoneNumber[i].preferred) {
                            setBidderValues('phoneNumber', person.phoneNumber[i].phoneNumber, { shouldValidate: true, shouldDirty: true });
                        }
                    }
                    for (let i = 0; i < person.identification.length; i++) {
                        if (person.identification[i].identificationType === IdentificationTypes.pan) {
                            setBidderValues('pan', person.identification[i].identification)
                        } else if (person.identification[i].identificationType === IdentificationTypes.cin) {
                            setBidderValues('cin', person.identification[i].identification)
                        }
                    }
                    if (person.address.length > 0) {
                        let primaryContact = person.address.find(address => address.addressType === AddressType.primaryContact);
                        if (primaryContact) {
                            setBidderValues('addressId', primaryContact.addressId);
                            setBidderValues('attention', primaryContact.attention);
                            setBidderValues('phone', primaryContact.phone);
                            setBidderValues('email', primaryContact.email);
                            setBidderValues('addressLine1', primaryContact.addressLine1);
                            setBidderValues('addressLine2', primaryContact.addressLine2);
                            setBidderValues('landmark', primaryContact.landmark);
                            setBidderValues('cityTown', primaryContact.cityTown);
                            setBidderValues('postalCode', primaryContact.postalCode);
                            setBidderValues('state', primaryContact.state);
                            setBidderValues('country', primaryContact.country)
                            setBidderValues('addressType', primaryContact.addressType);
                        } else {
                            setBidderValues('addressId', person.address[0].addressId);
                            setBidderValues('attention', person.address[0].attention);
                            setBidderValues('phone', person.address[0].phone);
                            setBidderValues('email', person.address[0].email);
                            setBidderValues('addressLine1', person.address[0].addressLine1);
                            setBidderValues('addressLine2', person.address[0].addressLine2);
                            setBidderValues('landmark', person.address[0].landmark);
                            setBidderValues('cityTown', person.address[0].cityTown);
                            setBidderValues('postalCode', person.address[0].postalCode);
                            setBidderValues('state', person.address[0].state);
                            setBidderValues('country', person.address[0].country);
                            setBidderValues('addressType', person.address[0].addressType);
                        }
                    } else {
                        setBidderValues('addressId', '');
                        setBidderValues('attention', '');
                        setBidderValues('phone', '');
                        setBidderValues('email', '');
                        setBidderValues('addressLine1', '');
                        setBidderValues('addressLine2', '');
                        setBidderValues('landmark', '');
                        setBidderValues('cityTown', '');
                        setBidderValues('postalCode', '');
                        setBidderValues('state', '');
                        setBidderValues('addressType', AddressType.primaryContact);
                    }
                    bidderTrigger();
                }
                setLoading(false);
            }).catch(error => {
                if (error.message) {
                    setMessage({showMsg: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
                }
                setLoading(true);
            })
        }
    }

    const updateTourGuide = () => {
        setTourGuide({
            triggerEvent: false,
            triggerButton: true
        });
        setTimeout(() => {
            setTourGuide({
                triggerEvent: true,
                triggerButton: true
            });
        }, 0);
    }
    
    const getStepContent = (stepIndex) => {
        let step = steps[stepIndex].step;
        
        switch (step) {
            case 'bidderDetails':
                return (
                    <BidderInput key='bidder-info' register={bidderRegister} errors={bidderError}
                        control={bidderControl} setValue={setBidderValues} getValues={getBidderValues}
                        showPartyTypeSelection={context?.userSession?.session?.individual} showSearch={context?.userSession?.session?.individual}
                        trigger={bidderTrigger} reset={bidderReset} isEdit={!getBidderValues('id')} 
                        showClear={context?.userSession?.session?.individual}  tourGuide={tourGuide} setTourGuide={setTourGuide}>
                    </BidderInput>
                )
            case 'bidderAuthRepDetails':
                return (
                    getBidderValues('id') != '' && <Fragment>
                        <BidderInput id="bidder-auth-rep-details" key='bidder-auth-rep-info' register={bidderAuthRepRegister} errors={bidderAuthRepError}
                                control={bidderAuthRepControl} setValue={setBidderAuthRepValues} 
                                getValues={getBidderAuthRepValues} showSearch={!getBidderAuthRepValues('roleEdgeId')}
                                trigger={bidderAuthRepTrigger} reset={bidderAuthRepReset} isEdit={!getBidderAuthRepValues('roleEdgeId')}
                                showClear={true}  tourGuide={tourGuide} setTourGuide={setTourGuide}>
                        </BidderInput>
                    </Fragment>
                );
            case 'documents':
                return (
                    <div>
                        <BidderViewDocumentList auctionId={auction_id} bidderId={null}
                            // bidderId={getBidderValues('personId')}
                            showActionColumn={true} setTourGuide={setTourGuide}
                        />
                    </div>
                )
            case 'emdDetails':
                return (
                    <div>
                        <EMDList auction_id={auction_id} user_id={getBidderValues('personId')}
                            seller_id={seller_id} bidder_id={getBidderValues('id')}
                            allowAdd={true} allowEdit={true} allowDelete={true}
                            auctionCreationDate={auctionCreationDate}
                            allowUpdateReceivedEMDField={allowUpdateReceivedEMDField}
                            bidder_login_id={getBidderValues('loginId')}
                            setTourGuide={setTourGuide}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    const getPageActions = (stepIndex) => {
        let step = steps[stepIndex].step;
        switch (step) {
            case 'bidderDetails':
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            {!getBidderValues('id') && <Button id='bidder-save-and-next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={requestConfirmation} disabled={!bidderIsValid}>
                                {t('Save_And_Next')}
                            </Button>}
                            {getBidderValues('id') && <Button id='next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={() => handleNext()}>
                                {t('Next')}
                            </Button>}
                            <Button id='cancel' size="small" variant="outlined"
                                className={classes.nextBtn} onClick={handleClose}>
                                {getBidderValues('id') ? t('Close') : t('Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case 'bidderAuthRepDetails':
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='back' size="small" variant="outlined"
                                className={classes.backBtn} onClick={() => handleBack()}>
                                {t('Back')}
                            </Button>
                            {!getBidderAuthRepValues('roleEdgeId') && <Button id='bidder-auth-rep-save-and-next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={createAuthRep} disabled={!bidderAuthRepIsValid}>
                                {t('Save_And_Next')}
                            </Button>}
                            {getBidderAuthRepValues('roleEdgeId') && <Button id='bidder-autp-rep-next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={() => handleNext()}>
                                {t('Next')}
                            </Button>}
                            <Button id='cancel' size="small" variant="outlined"
                                className={classes.nextBtn} onClick={handleClose}>
                                {getBidderAuthRepValues('roleEdgeId') ? t('Close') : t('Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case 'documents':
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='back' size="small" variant="outlined"
                                className={classes.backBtn} onClick={() => handleBack()}>
                                {t('Back')}
                            </Button>
                            {getBidderValues('id') && <Button id='next' type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={() => handleNext()}>
                                {t('Next')}
                            </Button>}
                            <Button id='close' size="small" variant="outlined" className={classes.nextBtn}
                                onClick={handleClose}>
                                {t('Close')}
                            </Button>
                        </Grid>
                    </Grid>
                );
            case 'emdDetails':
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='back' size="small" variant="outlined"
                                className={classes.backBtn} onClick={() => handleBack(1)}>
                                {t('Back')}
                            </Button>
                            <Button id='close' size="small" variant="outlined" className={classes.nextBtn}
                                onClick={handleClose}>
                                {t('Close')}
                            </Button>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='lg'>
                <DialogTitle style={{ textAlign: 'center', paddingTop: '10px' }}>
                    <Grid container>
                        <Typography variant="h6" gutterBottom color='primary' style={{ fontWeight: 'bold', width: '100%' }}>
                            {t('Request_To_Participate_As_Bidder')}
                        </Typography>
                        {tourGuide.triggerButton && <Button
                            id='guide-me-link'
                            variant="contained"
                            startIcon={<TourIcon />}
                            sx={{ textTransform: 'none' }}
                            onClick={updateTourGuide}
                            className='guide-me'
                        >{t('Guide_Me')}
                        </Button>}
                    </Grid>
                    <Container maxWidth="md">
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => (
                                <Step key={index} id={`step${index}`}>
                                    <StepButton color="inherit">
                                        {t(step.label)}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%' }}>
                                {getStepContent(activeStep)}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Divider />
                    {getPageActions(activeStep)}
                </DialogActions>
                {(requestingToBid || creatingBidderAuthRep || loading) && <AuctionLoader show={requestingToBid || creatingBidderAuthRep || loading} invisible={false} />}
                {(errorOnRequestingToBid || errorOnCreatingBidderAuthRep) && <GraphQLErrors error={errorOnRequestingToBid || errorOnCreatingBidderAuthRep} show={false} />}
            </Dialog>
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree}
                agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel}
            />}
            {message.showMsg && <AuctionSnackBar show={message.showMsg} message={message.message} severity={message.severity} />}
        </Container>
    )
}

export default RequestForBid;