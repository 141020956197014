import React,{
    useState,
    useEffect
}                                   from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Grid,
    Button
}                                   from '@mui/material';
import { makeStyles }               from '@mui/styles';
import { useTranslation }           from 'react-i18next'; 
import LotInput                     from './LotInput';
import { useForm }                  from 'react-hook-form';
import { UPDATE_LOT }               from '../../GraphQL/Mutation';
import { useMutation }              from '@apollo/client';
import { LIST_LOTS }                from '../../GraphQL/Queries';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../common/GraphQLErrors';

const useStyles = makeStyles((theme) => ({
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const UpdateLot = ({show, lotDetails, onClose, auction_id, onSuccess, mannerOfSale}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);

    const { register, control, getValues, setValue, trigger, reset,
            formState: { errors, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            lotNumber: '',
            description: '',
            autoExtension: '',
            autoExtensionMode: '',
            extendTimeWhenBidReceivedInLastMinute: '',
            extendTimeBy: '',
            reservePrice: 0,
            emdAmount: 0,
            lastDateForEmd: null,
            incrementalAmount: 0,
            bidStartPrice: 0,
            incrementalAmountDuringExtension: 0,
            noOfExtension: 0
        }
    });

    const [updateLot, {loading: updatingLot, error: errorOnUpdatingLot}] = useMutation(UPDATE_LOT, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateAuctionLot != null) {
                if (onSuccess) {
                    onSuccess({show: true, message: t('Lot_Updated_Successfully'), severity: 'success'})
                }
                setTimeout(() => {
                    handleClose();
                }, 500);
            }
        },
        refetchQueries: [LIST_LOTS]
    })

    const handleClose = () => {
        setOpen(false);
        onClose(false)
    }

    const update = () => {
        updateLot({
            variables: {
                auctionLotInput: {
                    id: lotDetails.id,
                    lotNumber: getValues('lotNumber'),
                    description: getValues('description'),
                    autoExtension: getValues('autoExtension'),
                    autoExtensionMode: getValues('autoExtensionMode'),
                    extendTimeWhenBidReceivedInLastMinute: getValues('extendTimeWhenBidReceivedInLastMinute'),
                    extendTimeBy: getValues('extendTimeBy'),
                    reservePrice: getValues('reservePrice'),
                    emdAmount: getValues('emdAmount'),
                    lastDateForEmd: getValues('lastDateForEmd'),
                    bidStartPrice: getValues('bidStartPrice'),
                    incrementalAmount: getValues('incrementalAmount'),
                    incrementalAmountDuringExtension: getValues('incrementalAmountDuringExtension'),
                    noOfExtension: getValues('noOfExtension'),
                    startTime: getValues('startTime'),
                    endTime: getValues('endTime')
                },
                auctionId: auction_id,
                auctionLotId: lotDetails.id
            }
        })
    }

    useEffect(() => {
        if (lotDetails) {
            setValue('id', lotDetails.id);
            setValue('lotNumber', lotDetails.lotNumber, {shouldValidate: true});
            setValue('description', lotDetails.description, {shouldValidate: true});
            setValue('autoExtension', lotDetails.autoExtension, {shouldValidate: true});
            setValue('autoExtensionMode', lotDetails.autoExtensionMode, {shouldValidate: true});
            setValue('extendTimeWhenBidReceivedInLastMinute', lotDetails.extendTimeWhenBidReceivedInLastMinute, {shouldValidate: true});
            setValue('extendTimeBy', lotDetails.extendTimeBy, {shouldValidate: true});
            setValue('reservePrice', lotDetails.reservePrice, {shouldValidate: true});
            setValue('emdAmount', lotDetails.emdAmount, {shouldValidate: true});
            setValue('bidStartPrice', lotDetails.bidStartPrice, {shouldValidate: true});
            setValue('lastDateForEmd', lotDetails.lastDateForEmd, {shouldValidate: true});
            setValue('incrementalAmount', lotDetails.incrementalAmount, {shouldValidate: true});
            setValue('incrementalAmountDuringExtension', lotDetails.incrementalAmountDuringExtension, {shouldValidate: true});
            setValue('noOfExtension', lotDetails.noOfExtension, {shouldValidate: true})
            setValue('startTime', lotDetails.startTime, {shouldValidate: true})
            setValue('endTime', lotDetails.endTime, {shouldValidate: true})
        }
    }, [])
    

    return (
        <Dialog open={open} fullWidth maxWidth='lg'>
            <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                <Typography id="update-lot-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                    {t('Lot_Details')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LotInput register={register} control={control} errors={errors} setValue={setValue} 
                        getValues={getValues} trigger={trigger} mannerOfSale={mannerOfSale}/>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button type="submit" size="small" id="update-lot" variant="contained"
                                className={classes.nextBtn} onClick={update} disabled={!isValid}>
                            {t('Save')}
                        </Button>
                        <Button size="small" id="cancel-update-lot" variant="outlined"
                                className={classes.nextBtn} onClick={handleClose}>
                            {t('Cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            {updatingLot && <AuctionLoader show={updatingLot} invisible={false} />}
            {errorOnUpdatingLot && <GraphQLErrors error={errorOnUpdatingLot} show={false} />}
        </Dialog>
    )
}

export default UpdateLot;