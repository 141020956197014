import { gql } from '@apollo/client';

const LIST_ADDRESS = gql`
    query ListAddress($sourceId: Int!, $pagination: Pagination!) {
         listAddress(sourceId: $sourceId, pagination: $pagination) {
            address {
                addressId
                addressLine1
                addressLine2
                addressType
                attention
                cityTown
                country
                landmark
                phone
                email
                postalCode
                state
            }
        }
    }
`

const LIST_LOGIN_USER_ADDRESS = gql`
    query ListLoginUserAddress($pagination: Pagination!) {
         listLoginUserAddress(pagination: $pagination) {
            address {
                addressId
                addressLine1
                addressLine2
                addressType
                attention
                cityTown
                country
                landmark
                phone
                email
                postalCode
                state
            }
        }
    }
`

const LIST_COUNTRIES = gql`
    query ListCountry {
    listCountry {
        countryId
        name
        }
    }
`

const LIST_STATES = gql`
query listState($countryId: Int!) {
    listState(countryId: $countryId) {
        stateId
        name
    }
}
`

const LIST_CITIES = gql`
query listCity($stateId: Int!){
    listCity(stateId: $stateId) {
        cityId
        name
  }
}`

const LIST_POSTALCODE = gql`
query listPostalCode($cityId: Int!) {
    listPostalCode(cityId: $cityId) {
        postalCodeId
        postalCode
  }
}`

const LIST_POSTALCODE_BY_COUNTRY = gql`
query listPostalCode($countryId: Int!) {
    listPostalCode(countryId: $countryId) {
    postalCodeId
	postalCode
  }
}`

const GET_STATE_CITY_BY_PINCODE = gql`
query getStateAndCity($postalCodeId : Int!) {
    getStateAndCity(postalCodeId : $postalCodeId ) {
        stateId
        state
        cityId
        city
  }
}`

const GET_ADDRESS = gql`
query get($edgeId: Int!)  {
    get(edgeId : $edgeId ) {
    countryId
    country
    stateId
    province
    cityTown
    cityTownId
    postalCode
    postalCodeId
  }
}`

const GET_USER_DETAILS = gql`
    query GetUserDetails{
    getUserSetting {
        is_system_admin
        theme
    }
}`

const LIST_CLIENT = gql`
    query ListClient($pagination: Pagination) {
        listClient(pagination: $pagination) {
            client {
                personId
                individual
                name
                restOfName
                loginUser
                address {
                    addressLine1
                    addressLine2
                    addressType
                    attention
                    cityTown
                    country
                    addressId
                    landmark
                    phone
                    postalCode
                    state
                    latitude
                    longitude
                    phone
                    attention
                    email
                }
                identification {
                    id
                    identification
                    identificationType
                    otherIdentificationType
                }
                email {
                    id
                    email
                    preferred
                }
                phoneNumber {
                    id
                    phoneNumber
                    preferred
                }
            }
            count
        }
    }
`

const GET_CLIENT = gql`
    query GetClient($clientId: Int!) {
        getClient(clientId: $clientId) {
            client {
                personId
                individual
                name
                restOfName
                loginUser
                address {
                    addressLine1
                    addressLine2
                    addressType
                    attention
                    cityTown
                    country
                    addressId
                    landmark
                    phone
                    postalCode
                    state
                    latitude
                    longitude
                    phone
                    attention
                    email
                }
                identification {
                    id
                    identification
                    identificationType
                    otherIdentificationType
                }
                email {
                    id
                    email
                    preferred
                }
                phoneNumber {
                    id
                    phoneNumber
                    preferred
                }
            }
        }
    }
`

const GET_GST_TREATMENT = gql`
    query GetGSTTreatment {
        dropdown(name: "gst_treatment") {
            description
            id
            value
        }
    }
`

const GET_LOGIN_USER_GST_DETAILS = gql`
    query getGstDetails {
        getGstDetails {
            gst_treatment
            identification {
                id
                identification
                identification_type
                valid_from
                valid_to
            }
        }
    }
`
const GET_PRE_SIGNED_URL = gql`
    query GetPreSignedUrl($presignedUrl: PresignedUrl) {
        getPresignedUrl(presignedUrl: $presignedUrl)
    }
`
const GET_PRE_SIGNED_URL_SUBMIT_DOC = gql`
    query GetPresignedUrlSubmitDocument($auctionId: Int, $bidderId: Int, $presignedUrl: PresignedUrl, $oldFilePath: String) {
        getPresignedUrlSubmitDocument(auctionId: $auctionId, bidderId: $bidderId, presignedUrl: $presignedUrl, oldFilePath: $oldFilePath)
    }
`

const GET_PRE_SIGNED_URL_TEMPLATE = gql`
    query GetPresignedUrlTemplate($auctionId: Int, $presignedUrl: PresignedUrl, $documentType: String, $toSubmit: Boolean, $oldFilePath: String) {
        getPresignedUrlTemplate(auctionId: $auctionId, presignedUrl: $presignedUrl, documentType: $documentType, toSubmit: $toSubmit, oldFilePath: $oldFilePath)
    }
`

const GET_AUCTION_LIST = gql`
    query ListAuction($status: String, $pagination: Pagination!, $roles: [String]) {
        listAuction(status: $status, pagination: $pagination, roles: $roles ) {
            auction {
                id
                auctionId
                referenceNumber
                title
                auctionType
                auctionMethod
                biddingAccess
                baseCurrency
                startTime
                endTime
                description
                registrationStartTime
                registrationEndTime
                mannerOfSale
                biddingAccess
                startResultShareTime
                endResultShareTime
                status
                auctioneers {
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                    }
                }
                roles
                auctionLots {
                    id
                    auctionId
                    lotNumber
                    description
                    startTime
                    endTime
                    autoExtension
                    autoExtensionMode
                    extendTimeWhenBidReceivedInLastMinute
                    extendTimeBy
                    reservePrice
                    emdAmount
                    lastDateForEmd
                    bidStartPrice
                    incrementalAmount
                    incrementalAmountDuringExtension
                    winningPrice
                    noOfExtension
                    itemType
                    lastDateForKyc
                }
                conductingFor {
                    loginId
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                    }
                    bankDetail {
                        id
                        bankAccountNumber
                        accountType
                        ifsc
                        micr
                        bankName
                        branchName
                        bankHolderName
                        userId
                        edgeId
                        otherAccountType
                        lotId
                        itemId
                    }
                }
                favouriteAuction
                createdOn
                lastDateToFinalizeBidder
            }
            count
        }
    }
`

const LIST_REQUIRED_DOCUMENTS = gql`
    query ListRequiredDocuments($auctionId: Int, $bidderId: Int, $pagination: Pagination, $showDeleted: Boolean, $toSubmit: Boolean) {
        listRequiredDocuments(auctionId: $auctionId, bidderId: $bidderId, pagination: $pagination, showDeleted: $showDeleted, toSubmit: $toSubmit) {
            documents {
                id
                name
                submitFormat
                requiresDigitalSignature
                isMandatory
                s3Uri
                templateS3Uri
                startTime
                endTime
                addedBy
                removedBy
                updatedBy
                referenceNumber
                requiredDocumentBrief
                submittedDocumentBrief
                toSubmit
                templateS3UriSize
                s3UriSize
                templateS3UriFileName
                s3UriFileName
                templateS3UriFileLastModifiedTime
                s3UriFileLastModifiedTime
            }
            count
            bidderId
        }
    }
`

const LIST_DOCUMENT_TYPES = gql`
query ListDocumentTypes($auctionId: Int, $toSubmit: Boolean){
    listDocumentTypes(auctionId: $auctionId, toSubmit: $toSubmit)
}
`

// const LIST_BIDDERS_DOCUMENTS = gql`
//     query ListBiddersDocuments($auctionId: String, $pagination: Pagination, $userId: String){
//         listBidderDocuments(auctionId: $auctionId, pagination: $pagination, userId: $userId){
//             documents {
//                 id
//                 name
//                 submitFormat
//                 requiresDigitalSignature
//                 isMandatory
//                 s3Uri
//                 templateS3Uri
//                 startTime
//                 endTime
//                 addedBy
//                 removedBy
//                 updatedBy
//                 referenceNumber
//                 requiredDocumentBrief
//                 submittedDocumentBrief
//                 toSubmit
//                 templateS3UriSize
//                 templateS3UriFileName
//                 s3UriFileName
//                 s3UriSize
//             }
//             count
//         }
//     }
// `

const GET_MY_SETTINGS = gql`
    query GetMySetting{
        getMySetting {
            bannerColor
            theme
            currentTime
            noOfAuctioneerSupportRole
            tourGuide
    }
}`

const GET_SYSTEM_DATE_TIME = gql`
    query GetSystemDateTime{
        getMySetting {
            currentTime
    }
}`

const SEARCH_PARTY = gql`
query SearchParty($searchInput: SearchInput) {
    searchUser(searchInput : $searchInput) {
        id
        name
        identification
        email
        phoneNumber
    }
}
`
const GET_ROLE = gql`
query getUserRoles($auctionId:Int, $userId:Int){
    getRole(auctionId:$auctionId, userId: $userId) {
            auctionId
            userId
            name
            email
            phoneNumber
            roleDetails {
                id
                role
                startTime
                endTime
                grantedBy
                revokedBy
                granter
                revoker
                remarks
            }
        }
    }
`
const LIST_ROLE = gql`
query listUserRoles($auctionId: Int!, $pagination: Pagination) {
    listRole(auctionId: $auctionId, pagination: $pagination) {
        count
        userRole{
            auctionId
            userId
            loginId
            name
            email
            phoneNumber
            roleDetails {
                id
                role
                startTime
                endTime
                grantedBy
                revokedBy
                granter
                revoker
                remarks
                active
            }
        }
    }
    }
`

const LIST_BANK_DETAIL_FOR_LOGINUSER = gql`
    query ListBankDetailForLoginUser($pagination: Pagination) {
        listBankDetailForLoginUser(pagination: $pagination) {
            bankDetail{
                id
                bankAccountNumber
                accountType
                ifsc
                micr
                bankName
                branchName
                bankHolderName
                userId
                edgeId
                otherAccountType
                startTime
                endTime
            }
            count
        }
    }
`

const GET_BANK_DETAILS = gql`
    query GetBankDetails($bankDetailId: Int) {
        getBankDetail(bankDetailId: $bankDetailId) {
            id
            bankAccountNumber
            accountType
            ifsc
            micr
            bankName
            branchName
            bankHolderName
            userId
            edgeId
            otherAccountType
        }
    }
  `

const LIST_ITEMS = gql`
    query ListItem($auctionId: Int, $lotId: Int, $pagination: Pagination, $isActive: Boolean) {
        listItem(auctionId: $auctionId, lotId: $lotId, pagination: $pagination, isActive: $isActive) {
            count
            item {
                id
                itemName
                itemRefNo
                description
                itemType
                unit
                quantity
                address {
                    addressId
                    cityTown
                    phone
                    attention
                    email
                    addressLine1
                    addressLine2
                    country
                    postalCode
                    state
                    cityTown
                    landmark
                    addressType
                    sourceId
                }
            }
        }
    }
`

const LIST_SELLER_DETAILS = gql`
    query ListSeller($auctionId: Int!, $pagination: Pagination) {
        listSeller(auctionId: $auctionId, pagination: $pagination) {
            seller {
                personId
                individual
                name
                restOfName
                dobDoi
                loginId
                identification {
                    id
                    identificationType
                    identification
                    otherIdentificationType
                    startTime
                    endTime
                }
                email {
                    id
                    email
                    preferred
                    startTime
                    endTime
                }
                phoneNumber {
                    id
                    phoneNumber
                    preferred
                    lastVerifiedOn
                    deletedOn
                }
                bankDetail {
                    id
                    bankAccountNumber
                    accountType
                    ifsc
                    micr
                    bankName
                    branchName
                    bankHolderName
                    userId
                    edgeId
                    startTime
                    endTime
                }
            }
            count
        }
    }
`

const GET_PARTY_DETAILS = gql`
    query GetUserDetails($userId: Int!) {
        getUserDetails(userId: $userId) {
            personId
            individual
            name
            restOfName
            dobDoi
            identification {
                id
	            identificationType
                otherIdentificationType
	            identification
            }
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                startTime
                endTime
                phone
                attention
                email
            }
            email {
                id
                email
                preferred
            }
            phoneNumber {
                id
                phoneNumber
                preferred
            }
        }
    }
`

const GET_ITEM_OWNER_DETAILS = gql`
    query GetItemOwner($auctionId: Int!, $userId: Int!) {
        getItemOwner(auctionId: $auctionId, userId: $userId) {
            userId
            auctionId
            individual
            name
            restOfName
            identification {
                id
                identificationType
                identification
                startTime
                endTime
            }
            address {
                edgeId
                userId
                addressId
                addressLine1
                addressLine2
                landmark
                cityTown
                cityTownId
                postalCode
                postalCodeId
                stateId
                province
                countryId
                country
                addressType
                phone
                attention
                startTime
                endTime
            }
            email {
                id
                email
                preferred
                startTime
                endTime
            }
            phoneNumber {
                id
                phoneNumber
                preferred
                startTime
                endTime
            }
        }
    }

`

const listBididderHistory = gql` query ListBidHistory($auctionId: String, $bidOnId: String, $bidderId: String, $pagination: Pagination) {
    listBidHistory(auctionId: $auctionId, bidOnId: $bidOnId, bidderId: $bidderId, pagination: $pagination){
        bids
        count
    }
}
`
const GET_AUCTION = gql`
    query GetAuction($auctionId: Int) {
        getAuction(auctionId: $auctionId) {
            id
            auctionId
            referenceNumber
            title
            description
            auctionType
            auctionMethod
            auctionVariant
            baseCurrency
            mannerOfSale
            biddingAccess
            createdBy
            createdOn
            status
            registrationStartTime
            registrationEndTime
            startTime
            endTime
            actualEndTime
            startResultShareTime
            endResultShareTime
            hasTermsAndCondition
            roles
            auctionLots {
                id
                auctionId
                lotNumber
                description
                startTime
                endTime
                actualEndTime
                autoExtension
                autoExtensionMode
                extendTimeWhenBidReceivedInLastMinute
                extendTimeBy
                reservePrice
                emdAmount
                lastDateForEmd
                bidStartPrice
                incrementalAmount
                incrementalAmountDuringExtension
                winningPrice
                noOfExtension
                itemType
                lastDateForKyc
            }
            auctioneers {
                personId
                individual
                name
                restOfName
                identification {
                    id
                    identificationType
                    identification
                }
                address {
                    addressId
                    sourceId
                    addressLine1
                    addressLine2
                    landmark
                    cityTown
                    postalCode
                    state
                    country
                    addressType
                    startTime
                    endTime
                    phone
                    attention
                    email
                }
                email {
                    id
                    email
                    preferred
                    startTime
                    endTime
                }
                phoneNumber {
                    id
                    phoneNumber
                    preferred
                    lastVerifiedOn
                    deletedOn
                }
            }
            conductingFor {
                loginId
                personId
                individual
                name
                restOfName
                identification {
                    id
                    identificationType
                    identification
                }
                bankDetail {
                    id
                    bankAccountNumber
                    accountType
                    ifsc
                    micr
                    bankName
                    branchName
                    bankHolderName
                    userId
                    edgeId
                    otherAccountType
                    lotId
                    itemId
                }
            }
            createdOn
            lastDateToFinalizeBidder
            viewCount
            currentTime
        }
    }
`
const GET_AUCTION_HIGHEST_BID = gql`
    query GetAuctionHighestBid($auctionId: Int, $bidOnId: Int, $bidderId: Int) {
        getLatestBid(auctionId: $auctionId, bidOnId: $bidOnId, bidderId: $bidderId) {
            id
            amount
            systemDateTime
            extensionNumber
        }
    }
`
const GET_BIDDERS_LATEST_BID = gql`
    query GetBiddersLatestBid($auctionId: Int, $bidOnId: Int) {
        getBidderLatestBid(auctionId: $auctionId, bidOnId: $bidOnId) {
            id
            amount
            systemDateTime
        }
    }
`

const LIST_ACTIVE_BIDDERS = gql`
    query ListActiveBidders($auctionId: Int, $bidOnId: Int, $pagination: Pagination) {
        listAuctionBids(auctionId: $auctionId, bidOnId: $bidOnId, pagination: $pagination) {
            bids {
                rank
                amount
                startTime
                bidderId
                bidOnId
                ipAddress
            }
            count
            systemDateTime
            extensionNumber
        }
    }
`

const GET_BIDDER_RANK = gql`
    query GetBidderRank($auctionId: Int, $bidOnId: Int) {
        getBidderRank(auctionId: $auctionId, bidOnId: $bidOnId)
    }
`

const GET_BID = gql`
    query GetBid($auctionId: Int, $bidOnId: Int) {
        getBid(auctionId: $auctionId, bidOnId: $bidOnId) {
            id
            rank
            amount
            name
            bidOnId
            lotOrItem
            bidderId
            systemDateTime
            ipAddress
            extensionNumber
            latestBidAmount
            bidderRank
            auction {
                id
                auctionId
                referenceNumber
                title
                description
                auctionType
                auctionMethod
                auctionVariant
                baseCurrency
                mannerOfSale
                biddingAccess
                createdBy
                createdOn
                status
                registrationStartTime
                registrationEndTime
                startTime
                endTime
                actualEndTime
                startResultShareTime
                endResultShareTime
                roles
                auctionLots {
                    id
                    auctionId
                    lotNumber
                    description
                    startTime
                    endTime
                    actualEndTime
                    autoExtension
                    autoExtensionMode
                    extendTimeWhenBidReceivedInLastMinute
                    extendTimeBy
                    reservePrice
                    emdAmount
                    lastDateForEmd
                    bidStartPrice
                    incrementalAmount
                    incrementalAmountDuringExtension
                    winningPrice
                    noOfExtension
                    itemType
                    lastDateForKyc
                }
                auctioneers {
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                    }
                    address {
                        addressId
                        sourceId
                        addressLine1
                        addressLine2
                        landmark
                        cityTown
                        postalCode
                        state
                        country
                        addressType
                        startTime
                        endTime
                        phone
                        attention
                        email
                    }
                    email {
                        id
                        email
                        preferred
                        startTime
                        endTime
                    }
                    phoneNumber {
                        id
                        phoneNumber
                        preferred
                        lastVerifiedOn
                        deletedOn
                    }
                }
                conductingFor {
                    loginId
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                    }
                }
                createdOn
                lastDateToFinalizeBidder
                viewCount
            }
            bids {
                rank
                amount
                startTime
                name
                bidderId
                bidOnId
                lotOrItem
                ipAddress
                performedBy
                performedByInString
                bidderId
            }
        }
    }
`

const BID_HISTORY = gql`
    query ListBidHistory($auctionId: Int, $bidOnId: Int, $bidderId: Int, $pagination: Pagination,$isLot: Boolean){
        listBidHistory(auctionId: $auctionId, bidOnId: $bidOnId, bidderId: $bidderId, pagination: $pagination, isLot: $isLot){
            bids {
                id
                rank
                amount
                startTime
                name
                bidderId
                bidOnId
                lotOrItem
                systemDateTime
                ipAddress
                bidStatus
                remarks
                performedBy
                performedByInString
            }
            count
        }
    }
`

const LIST_BIDDER_BID_HISTORY = gql`
    query ListBidderBidHistory($auctionId: Int, $bidOnId: Int, $pagination: Pagination){
        listBidderBidHistory(auctionId: $auctionId, bidOnId: $bidOnId, pagination: $pagination){
            bids {
                id
                rank
                amount
                startTime
                name
                bidderId
                bidOnId
                lotOrItem
                systemDateTime
                ipAddress
                bidStatus
                remarks
                performedBy
                performedByInString
            }
            count
        }
    }
`

const GET_AUCTION_LOT = gql`
    query ListAuctionLot($auctionId: Int){
        getAuction(auctionId: $auctionId){
            id
            referenceNumber
            title
            auctionType
            auctionMethod
            biddingAccess
            baseCurrency
            startTime
            endTime
            description
            registrationStartTime
            registrationEndTime
            mannerOfSale
            auctionLots {
                id
                auctionId
                lotNumber
                description
                startTime
                endTime
                autoExtension
                autoExtensionMode
                extendTimeWhenBidReceivedInLastMinute
                extendTimeBy
                reservePrice
                emdAmount
                lastDateForEmd
                bidStartPrice
                incrementalAmount
                incrementalAmountDuringExtension
                winningPrice
                noOfExtension
                lastDateForKyc
            }
        }

    }
`

const AUDIT_TABLE = gql`
query listByAuditOf($auditOf: Int!, $pagination: Pagination){
    listByAuditOf(auditOf: $auditOf, pagination: $pagination){
        count
        audit {
            auditId
            applicationEventId
            activityCd
            auditOf
            description
            ipAddress
            rowCreatedBy
            rowCreatedByInString
            rowCreatedOn
            eventLocale
            activityLocale
        }

    }
}
`
const LIST_FILE_VERSIONS = gql`
    query ListFileVersions($fileName: String){
        listFileVersions(fileName: $fileName){
            versionId
            key
            isLatest
            lastModified
            size
        }
    }
`

const LIST_BIDDER = gql`
    query ListBidder($auctionId: Int!, $bidderStatus: String, $pagination: Pagination){
        listBidder(auctionId: $auctionId, bidderStatus: $bidderStatus, pagination: $pagination){
            bidder {
                id
                bidderStatus
                personId
                individual
                name
                restOfName
                remarks
                loginId
                identification {
                    id
                    identificationType
                    identification
                    startTime
                    endTime
                    otherIdentificationType
                }
                address {
                    addressId
                    addressLine1
                    addressLine2
                    landmark
                    cityTown
                    postalCode
                    state
                    country
                    addressType
                    startTime
                    endTime
                    phone
                    attention
                    email
                }
                email {
                    id
                    email
                    preferred
                    startTime
                    endTime
                }
                phoneNumber {
                    id
                    phoneNumber
                    preferred
                }
                authorizedRepresentative {
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                        startTime
                        endTime
                        otherIdentificationType
                    }
                    address {
                        addressId
                        addressLine1
                        addressLine2
                        landmark
                        cityTown
                        postalCode
                        state
                        country
                        addressType
                        startTime
                        endTime
                        phone
                        attention
                        email
                    }
                    email {
                        id
                        email
                        preferred
                        startTime
                        endTime
                    }
                    phoneNumber {
                        id
                        phoneNumber
                        preferred
                    }
                }
            }
            count
        }
    }
`

const GET_PERSON_DETAILS = gql`
    query getPersonDetails($userId: Int!){
        getUserDetails(userId: $userId){
            personId
            individual
            name
            restOfName
            identification {
                id
                identificationType
                identification
                startTime
                endTime
            }
            address {
                addressId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                startTime
                endTime
                phone
                attention
                email
            }
            email {
                id
                email
                preferred
                startTime
                endTime
            }
            phoneNumber {
                id
                phoneNumber
                preferred
            }
        }
    }
`

const GET_BIDDER_DETAILS = gql`
    query GetBidder($auctionId: Int!, $bidderId: String!){
        getBidder(auctionId: $auctionId, bidderId: $bidderId){
            id
            bidderStatus
            personId
            individual
            name
            restOfName
            identification {
                id
                identificationType
                identification
                otherIdentificationType
                startTime
                endTime
            }
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                latitude
                longitude
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                startTime
                endTime
                phone
                attention
                email
            }
            email {
                id
                email
                preferred
                startTime
                endTime
            }
            phoneNumber {
                id
                phoneNumber
                preferred
                lastVerifiedOn
                deletedOn
            }
        }
    }
`

const LIST_ACTIVITY = gql`
   query{
    listActivity{
        activityCd
        code
    }
}
`
const LIST_EVENT = gql`
    query listEvent($applicationId: Int!) {
        listEvent(applicationId: $applicationId){
            eventCd
            code
        }
    }
`

const LIST_FILTER = gql`
    query ListForFilter($auditOf: Int!, $auditResourceInput: AuditResourceInput!, $pagination: Pagination){
      listForFilter(auditOf: $auditOf, auditResourceInput: $auditResourceInput, pagination: $pagination){
        count
        audit {
            auditId
            applicationEventId
            activityCd
            auditOf
            description
            ipAddress
            rowCreatedBy
            rowCreatedByInString
            rowCreatedOn
            eventLocale
            activityLocale

        }
    }
}
`

const LIST_USERS = gql`
    query ListUser($auditOf: Int!){
        listUser(auditOf: $auditOf){
            id
            loginId
            name

        }
    }
`

const LIST_BIDDER_AUTH_REP = gql`
    query ListBidderAuthRep($auctionId: Int!, $bidderId: String!){
        listAuthorizedRepresentative(auctionId: $auctionId, bidderId: $bidderId){
            personId
            roleId
            individual
            name
            restOfName
            role
            startTime
            endTime
            sameAsBidder
            loginId
            identification {
                id
                identificationType
                identification
                startTime
                endTime
                otherIdentificationType
            }
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                startTime
                endTime
                phone
                attention
                email
            }
            email {
                id
                email
                preferred
                startTime
                endTime
            }
            phoneNumber {
                id
                phoneNumber
                preferred
            }
        }
    }
`
const HELP_DESK = gql`
    query HelpDesk($auctionId: Int){
        helpDesk(auctionId: $auctionId){
            email
            phoneNumber
            name
            roleDetails{
                role
            }
        }
    }
`

const LIST_ITEM_LOT_FILES = gql`
    query ListItemLotFiles($auctionId: Int, $lotId: Int, $itemId: Int, $isPresignedUrl: Boolean,
                $limit: Int, $startingToken: String, $isPicture: Boolean){
        listLotOrItemFiles(auctionId: $auctionId, lotId: $lotId, itemId: $itemId, isPresignedUrl: $isPresignedUrl,
                limit: $limit, startingToken: $startingToken, isPicture: $isPicture){
            files {
                s3ObjectFile {
                    key
                    lastModified
                    eTag
                    size
                    storageClass
                }
                presignedUrl
                contentType
            }
            count
        }
    }
`

const LIST_BIDDER_FOR_DROPDOWN = gql`
    query ListBidderName($auctionId: Int!){
        listBidderName(auctionId: $auctionId){
            id
            name
            personId
        }
    }
`

const LIST_BIDDER_DOCUMENTS = gql`
    query ListBidderDocuments($auctionId: Int, $pagination: Pagination, $bidderId: Int) {
        listBidderDocuments(auctionId: $auctionId, pagination: $pagination, bidderId: $bidderId) {
            documents {
                id
                name
                submitFormat
                requiresDigitalSignature
                isMandatory
                s3Uri
                templateS3Uri
                startTime
                endTime
                addedBy
                removedBy
                updatedBy
                referenceNumber
                requiredDocumentBrief
                submittedDocumentBrief
                toSubmit
                templateS3UriSize
                s3UriSize
                templateS3UriFileName
                s3UriFileName
                templateS3UriFileLastModifiedTime
                s3UriFileLastModifiedTime
            }
            count

        }
    }
`

const GET_SELLER_DETAILS = gql`
    query GetSeller($auctionId: Int!, $userId: Int!) {
        getSeller(auctionId: $auctionId, userId: $userId) {
            personId
            individual
            name
            restOfName
            dobDoi
            loginId
            identification {
                id
	            identificationType
                otherIdentificationType
	            identification
            }
            address {
                addressId
                sourceId
                addressLine1
                addressLine2
                landmark
                cityTown
                postalCode
                state
                country
                addressType
                startTime
                endTime
                phone
                attention
                email
            }
            email {
                id
                email
                preferred
            }
            phoneNumber {
                id
                phoneNumber
                preferred
            }
        }
    }

`

const LIST_IDENTIFICATION = gql`
    query ListIdentification($personId: Int!) {
        listIdentification(personId: $personId) {
            id
            identificationType
            identification
            otherIdentificationType
            startTime
            endTime
        }
    }
`

const GET_IDENTIFICATION = gql`
    query GetIdentification($personId: Int!, $id: Int!) {
        getIdentification(personId: $personId, id: $id) {
            id
            identificationType
            identification
            otherIdentificationType
            startTime
            endTime
        }
    }
`

const LIST_PHONENUMBER = gql`
    query ListPhoneNumber($personId: Int!) {
        listPhoneNumber(personId: $personId) {
            id
            phoneNumber
            preferred
            lastVerifiedOn
            deletedOn
        }
    }
`

const GET_PHONENUMBER = gql`
    query getPhoneNumber($personId: Int!, $id: Int!) {
        getPhoneNumber(personId: $personId, id: $id) {
            id
            phoneNumber
            preferred
            lastVerifiedOn
            deletedOn
        }
    }
`

const LIST_EMAIL = gql`
    query ListEmail($personId: Int!) {
        listEmail(personId: $personId) {
            id
            email
            preferred
            startTime
            endTime
        }
    }
`

const GET_TERMS_AND_CONDITION = gql`
    query GetTermsCondition($auctionId: Int){
        getTermsCondition(auctionId: $auctionId){
            id
            termsAndCondition
            startTime
            defaultTermsAndCondition
            acceptedByAnyBidder
            acceptedTermsAndCondition
            bidMade
        }
    }
`

const LIST_LOTS = gql`
    query ListLots($auctionId: Int, $pagination: Pagination!){
        listAuctionLot(auctionId: $auctionId, pagination: $pagination){
            auctionLot {
                id
                auctionId
                lotNumber
                description
                startTime
                endTime
                autoExtension
                autoExtensionMode
                extendTimeWhenBidReceivedInLastMinute
                extendTimeBy
                reservePrice
                emdAmount
                lastDateForEmd
                bidStartPrice
                incrementalAmount
                incrementalAmountDuringExtension
                winningPrice
                viewCount
                address {
                    addressId
                    addressLine1
                    addressLine2
                    landmark
                    cityTown
                    postalCode
                    state
                    country
                    addressType
                    startTime
                    endTime
                    phone
                    attention
                    email
                }
                noOfExtension
                lastDateForKyc
            }
        }
    }
`

const LIST_AUCTION_BANK_DETAIL = gql`
    query BankDetailListInsideAuction($userId: Int, $auctionId: Int, $pagination: Pagination) {
        bankDetailListInsideAuction(userId: $userId, auctionId: $auctionId, pagination: $pagination) {
            bankDetail{
                id
                bankAccountNumber
                accountType
                ifsc
                micr
                bankName
                branchName
                bankHolderName
                userId
                edgeId
                startTime
                endTime
            }
            count
        }
    }
`

const LIST_BANK_DETAIL_BY_USER_ID = gql`
    query BankDetailListByUserId($userId: Int, $pagination: Pagination) {
        listBankDetailForUser(userId: $userId, pagination: $pagination) {
            bankDetail{
                id
                bankAccountNumber
                accountType
                ifsc
                micr
                bankName
                branchName
                bankHolderName
                userId
                edgeId
                otherAccountType
                startTime
                endTime
            }
            count
        }
    }
`

const GET_EMAIL = gql`
    query GetEmail($personId: Int!, $id: Int!) {
        getEmail(personId: $personId, id: $id) {
            id
            email
            preferred
            startTime
            endTime
        }
    }
`

const LIST_EMD = gql`
    query ListEMDs($auctionId: Int!, $userId: Int){
        listEmdDetail(auctionId: $auctionId, userId: $userId){
            id
            transferredEdgeId
            fromBankId
            toBankId
            amount
            transactionDate
            userId
            transactionRefNo
            modeOfPayment
            lotItemId
            received
            toBankDetail {
                id
                bankAccountNumber
                accountType
                ifsc
                bankName
                branchName
                bankHolderName
            }
            fromBankDetail {
                id
                bankAccountNumber
                accountType
                ifsc
                bankName
                branchName
                bankHolderName
            }
        }
    }
`

const SEARCH_AUCTION = gql`
    query SearchAuctions($pagination: Pagination, $searchAuctionInput: SearchAuctionInput, $roles: [String]) {
        searchAuctions(pagination: $pagination, searchAuctionInput: $searchAuctionInput, roles: $roles) {
            auction {
                id
                auctionId
                referenceNumber
                title
                auctionType
                auctionMethod
                biddingAccess
                baseCurrency
                startTime
                endTime
                description
                registrationStartTime
                registrationEndTime
                mannerOfSale
                biddingAccess
                startResultShareTime
                endResultShareTime
                status
                auctioneers {
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                    }
                }
                roles
                auctionLots {
                    id
                    auctionId
                    lotNumber
                    description
                    startTime
                    endTime
                    autoExtension
                    autoExtensionMode
                    extendTimeWhenBidReceivedInLastMinute
                    extendTimeBy
                    reservePrice
                    emdAmount
                    lastDateForEmd
                    bidStartPrice
                    incrementalAmount
                    incrementalAmountDuringExtension
                    winningPrice
                    noOfExtension
                    itemType
                    lastDateForKyc
                }
                conductingFor {
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                    }
                }
                favouriteAuction
                createdOn
                lastDateToFinalizeBidder
            }
            count
        }
    }
`

const LIST_FILES = gql`
    query ListFiles($auctionId: Int, $path: String, $isPresignedUrl: Boolean,
                $limit: Int, $startingToken: String){
        listFile(auctionId: $auctionId, path: $path, isPresignedUrl: $isPresignedUrl, limit: $limit,
                    startingToken: $startingToken){
            files {
                s3ObjectFile {
                    key
                    lastModified
                    eTag
                    size
                    storageClass
                }
                presignedUrl
                contentType
            }
            count
        }
    }
`

const SEARCH_BIDDER = gql`
    query SearchBidder($auctionId: Int!, $bidderStatus: String, $searchText: String!, $pagination: Pagination){
        searchBidder(auctionId: $auctionId, bidderStatus: $bidderStatus, searchText: $searchText, pagination: $pagination){
            bidder {
                id
                bidderStatus
                personId
                individual
                name
                restOfName
                remarks
                identification {
                    id
                    identificationType
                    identification
                    startTime
                    endTime
                    otherIdentificationType
                }
                address {
                    addressId
                    addressLine1
                    addressLine2
                    landmark
                    cityTown
                    postalCode
                    state
                    country
                    addressType
                    startTime
                    endTime
                    phone
                    attention
                    email
                }
                email {
                    id
                    email
                    preferred
                    startTime
                    endTime
                }
                phoneNumber {
                    id
                    phoneNumber
                    preferred
                }
                authorizedRepresentative {
                    personId
                    individual
                    name
                    restOfName
                    identification {
                        id
                        identificationType
                        identification
                        startTime
                        endTime
                        otherIdentificationType
                    }
                    address {
                        addressId
                        addressLine1
                        addressLine2
                        landmark
                        cityTown
                        postalCode
                        state
                        country
                        addressType
                        startTime
                        endTime
                        phone
                        attention
                        email
                    }
                    email {
                        id
                        email
                        preferred
                        startTime
                        endTime
                    }
                    phoneNumber {
                        id
                        phoneNumber
                        preferred
                    }
                }
            }
            count
        }
    }
`

const LIST_MESSAGE = gql`
    query ListByMessageOf($messageOf: Int!, $pagination: Pagination ){
        listByMessageOf(messageOf: $messageOf, pagination: $pagination){
            count
            messages {
                messageId
                messageOf
                sender
                receiver
                onBehalf
                information
                rowCreatedOn
                messageStatusId
                serviceProviderMessageId
                statusCd
                statusLocale
                statusRowCreatedOn
                messageStatus {
                        messageStatusId
                        messageId
                        statusCd
                        statusLocale
                        serviceProviderMessageId
                        rowCreatedOn
                }
            }
        }
    }
`

const LIST_EXISTING_ADDRESS_FOR_ITEM = gql`
    query listAddress($auctionId: Int){
        addressList(auctionId: $auctionId){
            addressId
            addressLine1
            addressLine2
            landmark
            cityTown
            postalCode
            state
            country
            addressType
            phone
            attention
            email
        }
    }
`

const LIST_EMAIL_MESSAGE = gql`
    query ListEmailByMessageOf($messageOf: Int!, $pagination: Pagination ){
        listEmailByMessageOf(messageOf: $messageOf, pagination: $pagination){
            count
            emails {
                messageId
                messageOf
                sender
                receiver
                onBehalf
                information
                rowCreatedOn
                messageStatusId
                serviceProviderMessageId
                statusCd
                statusLocale
                statusRowCreatedOn
                messageStatus{
                    messageStatusId
                    messageId
                    statusCd
                    statusLocale
                    serviceProviderMessageId
                    rowCreatedOn
                }
                cC
                bCC
                informationDetail{
                    subject
                    body
                }

            }
        }
    }
`

const LIST_MESSAGE_CODE = gql`
query ListMessageCodeCd($tableName: String!){
    listMessageCodeCd(tableName: $tableName){
        codeCd
        code
        description
    }
}
`

export {
    LIST_ADDRESS, LIST_LOGIN_USER_ADDRESS, LIST_COUNTRIES, LIST_STATES, LIST_CITIES, LIST_POSTALCODE, LIST_POSTALCODE_BY_COUNTRY, GET_STATE_CITY_BY_PINCODE, GET_ADDRESS, GET_USER_DETAILS,
    GET_GST_TREATMENT, LIST_CLIENT, GET_CLIENT, GET_LOGIN_USER_GST_DETAILS, GET_PRE_SIGNED_URL, GET_PRE_SIGNED_URL_SUBMIT_DOC, GET_PRE_SIGNED_URL_TEMPLATE, GET_AUCTION_LIST,
    LIST_REQUIRED_DOCUMENTS, LIST_DOCUMENT_TYPES, GET_MY_SETTINGS, GET_SYSTEM_DATE_TIME, SEARCH_PARTY, GET_BANK_DETAILS, LIST_ITEMS, GET_AUCTION,
    GET_BIDDERS_LATEST_BID, GET_BIDDER_RANK, GET_AUCTION_HIGHEST_BID, LIST_ACTIVE_BIDDERS, GET_BID, BID_HISTORY, LIST_BIDDER_BID_HISTORY, AUDIT_TABLE, LIST_FILE_VERSIONS,
    GET_ROLE, LIST_ROLE, LIST_SELLER_DETAILS, GET_PARTY_DETAILS, GET_ITEM_OWNER_DETAILS, LIST_ACTIVITY,
    LIST_EVENT, LIST_FILTER, LIST_USERS, LIST_BIDDER, GET_PERSON_DETAILS, GET_BIDDER_DETAILS, LIST_BIDDER_FOR_DROPDOWN,
    LIST_BIDDER_DOCUMENTS, LIST_ITEM_LOT_FILES, LIST_LOTS, LIST_BIDDER_AUTH_REP, LIST_AUCTION_BANK_DETAIL, HELP_DESK, LIST_MESSAGE, LIST_EMAIL_MESSAGE, LIST_MESSAGE_CODE, LIST_EMD,
    GET_SELLER_DETAILS, LIST_IDENTIFICATION, GET_IDENTIFICATION, LIST_PHONENUMBER, GET_PHONENUMBER, LIST_EMAIL, GET_EMAIL,
    GET_TERMS_AND_CONDITION, LIST_FILES, LIST_BANK_DETAIL_FOR_LOGINUSER, LIST_BANK_DETAIL_BY_USER_ID, SEARCH_BIDDER,
    SEARCH_AUCTION, LIST_EXISTING_ADDRESS_FOR_ITEM
}