import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/AddOutlined';
import {
    IconButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import GraphQLErrors from '../../common/GraphQLErrors';
import { LIST_EMAIL } from '../../GraphQL/Queries';
import EmailRow from './EmailRow';
import ListEmailModal from './ListEmailModal';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    showMoreClass: {
        position: 'relative',
        top: '7px',
        float: 'right',
        fontSize: '14px !important',
        cursor: 'pointer'
    },

}))

const ListEmail = ({ personId, isEdit, emails }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [emailList, setEmailList] = useState([]);
    const [openShowMoreModal, setOpenShowMoreModal] = React.useState(false);
    const [showPlusButton, setShowPlusButtom] = useState(true);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [listEmail, { loading: listingEmail, error: errorOnListingEmail, refetch }] =
        useLazyQuery(LIST_EMAIL, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (data.listEmail != null) {
                    setEmailList([]);
                    data.listEmail.map(email => {
                        setEmailList(prevState => ([
                            ...prevState,
                            {
                                id: email.id,
                                email: email.email,
                                preferred: email.preferred,
                                isNew: false
                            }
                        ]))
                    })
                }
            }
        })

    const addEmail = () => {
        setShowPlusButtom(false)
        setEmailList(
            prevState => ([
                {
                    id: null,
                    email: '',
                    preferred: false,
                    isNew: true
                },
                ...prevState,
            ])
        )
    }

    const setCancel = (isCancel) => {
        setShowPlusButtom(true);
        listEmail({
            variables: {
                personId: personId,
            }
        })
    }

    const onSuccess = (msg) => {
        if (personId != null) {
            listEmail({
                variables: {
                    personId: personId,
                }
            })
        }
        setShowPlusButtom(true);
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    // useEffect(() => {
    //     if(personId != null) {
    //         listEmail({
    //             variables: {
    //                 personId: personId,
    //             }
    //         })
    //     }
    // },[personId])

    const handleClickOpenShowMoreDialog = () => {
        setOpenShowMoreModal(true);
    };

    const handleCloseShowMoreModal = () => {
        setOpenShowMoreModal(false);
    };

    useEffect(() => {
        if (emails) {
            emails.map(email => {
                setEmailList(prevState => ([
                    ...prevState,
                    {
                        id: email.id,
                        email: email.email,
                        preferred: email.preferred,
                        isNew: false
                    }]
                ))
            })
        }
    }, [])

    return (
        <div>
            {isEdit && <Tooltip title={t("Add")}>
                <IconButton id="add-email" aria-label="add" size="small" color="primary" style={{ float: 'right' }} disabled={!showPlusButton}>
                    <AddIcon onClick={addEmail} />
                </IconButton>
            </Tooltip>}
            <TableContainer component={Paper} style={{ marginTop: !isEdit ? "35px" : "0px" }}>
                <Table aria-label="email list table" size="small">
                    <TableHead style={{ backgroundColor: '#dae2ef' }}>
                        <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '53% !important' }}>{t('Email_Id')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '15% !important' }}>{t('Prefered')}</TableCell>
                            {isEdit && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{ width: '30% !important' }}>{t('Actions')}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody id="emmail-list">
                        {(emailList.length > 0 && emailList[0].isNew === false) && emailList.map((email, index) => {
                            return email.preferred && <EmailRow index={index} key={email.id + index} email={email} setCancel={setCancel}
                                personId={personId} refetch={refetch} onSuccess={onSuccess} isView={!isEdit} />
                        })}
                        {(emailList.length > 0 && emailList[0].isNew === true) && emailList.map((email, index) => {
                            return (
                                <React.Fragment>
                                    {email.isNew && <EmailRow index={index} email={email} setCancel={setCancel}
                                        personId={personId} refetch={refetch} onSuccess={onSuccess} isView={!isEdit} />}
                                    {email.preferred && <EmailRow index={index} key={email.id + index} email={email} setCancel={setCancel}
                                        personId={personId} refetch={refetch} onSuccess={onSuccess} isView={!isEdit} />}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {(emailList.length > 1 && emailList[0].isNew == false) && <Typography id="show-more-email" color='primary' className={classes.showMoreClass} onClick={handleClickOpenShowMoreDialog} >{t('Show_More')}</Typography>}
            {openShowMoreModal && <ListEmailModal openPopup={openShowMoreModal} setClose={handleCloseShowMoreModal}
                personId={personId} isView={!isEdit} emails={emailList}></ListEmailModal>}
            {listingEmail && <AuctionLoader show={listingEmail} invisible={false} />}
            {errorOnListingEmail && <GraphQLErrors error={errorOnListingEmail} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar>}
        </div>
    )
}

export default ListEmail;