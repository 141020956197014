import React, { useState, useRef } from 'react'
import {
    Card, Typography, Grid, InputAdornment, IconButton,
    TextField, Button, Link
} from '@mui/material';
import { GppMaybe } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { useHistory } from 'react-router-dom';
import { JustifyBox, ContentBox, AlertInfoBox, RootDiv, IMG } from '../AuthStyle';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import { AuthAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import AuctionReCaptcha from '../../common/AuctionReCaptcha';
import * as Validators from '../../common/form-validators';
import PasswordPolicy, {
    NumberCheck, UpperLetter,
    LowerLetter, SpecialChar, MinPwdLength, MaxPwdLength
} from '../../common/PasswordPolicy';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { AppHostDomain } from '../../common/StaticVariableDeclaration';


const AutoAccountPasswordChange = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [pwdLength, setPwdLength] = useState(false);
    const [maxPwdLength, setMaxPwdLength] = useState(false);
    const [isUpper, setIsUpper] = useState(false);
    const [isLower, setIsLower] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [restPwd, setResetPwd] = useState({
        username: props.location.state.username,
        errorMessage: '',
        showErrorMessage: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false,
        password: '',
        confirmpassword: '',
        errors: {},
        isValidForm: false,
        showSuccessMessage: false,
        successMessage: ''
    })

    const sendOtpRef = useRef(null);


    const setStateData = (id, value) => {
        setResetPwd(prevState => ({
            ...prevState,
            [id]: value
        }))
        setTouched(prevState => ({
            ...prevState,
            [id]: true,
        }))
    }

    const onInputChange = (event) => {
        setResetPwd(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const clearErrors = (event) => {
        setErrors((prevState) => ({
            ...prevState,
            [event.target.id]: ''
        }))
    }

    const handlePopoverClose = (event) => {
        setOpen(false);
        if (!pwdLength || !maxPwdLength || !isUpper || !isLower || !isNumber || !isSpecial) {
            setErrors((prevState) => ({
                ...prevState,
                password: (!pwdLength && 'Minimum length should be 8 character') ||
                            (!maxPwdLength && 'Maximum length should be 50 character') ||
                            (!isUpper && 'Provide Atleast one uppercase letter') ||
                            (!isLower && 'Provide Atleast one lowercase letter') ||
                            (!isNumber && 'Provide Atleast one number') ||
                            (!isSpecial && 'Provide Atleast one special character')
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                password: ''
            }))
            if (restPwd.confirmpassword !== event.target.value) {
                setErrors((prevState) => ({
                    ...prevState,
                    confirmpassword: "Password does not match"
                }))
            } else {
                setErrors((prevState) => ({
                    ...prevState,
                    confirmpassword: ''
                }))
            }
        }
    }

    const onPasswordChange = (event) => {
        const pwdValue = event.target.value
        setResetPwd(prevState => ({
            ...prevState,
            [event.target.id]: pwdValue
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
        setPwdLength(pwdValue.length > MinPwdLength ? true : false);
        setMaxPwdLength(pwdValue.length < MaxPwdLength ? true : false);
        setIsUpper(UpperLetter.test(pwdValue) ? true : false);
        setIsLower(LowerLetter.test(pwdValue) ? true : false);
        setIsNumber(NumberCheck.test(pwdValue) ? true : false);
        setIsSpecial(SpecialChar.test(pwdValue) ? true : false);
        setOpen(event.target);
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(restPwd).map(([key]) => {
            if (key === 'password' || key === 'confirmpassword') {
                const newError = validate[key](restPwd[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    const validatePasswords = (event) => {
        if (restPwd.password !== event.target.value) {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: "Password does not match"
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: ''
            }))
        }
    }

    const validate = {
        password: password => Validators.requiredValidation('Password', password),
        confirmpassword: confirmpassword => (Validators.requiredValidation('Confirm Password', confirmpassword) ||
            Validators.equalValidation('Password', confirmpassword, restPwd.password))
    };

    const sendOtp = async event => {
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                event.preventDefault();
                setStateData('loading', true);
                setStateData('showErrorMessage', false);
                AuthAxiosAuctionInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
                    loginId: restPwd.username, password: restPwd.password
                }).then(response => {
                    setStateData('errorMessage', '');
                    setStateData('loading', false);
                    if (response) {
                        history.push({
                            pathname: "/reset-password", state: {
                                password: restPwd.password,
                                username: restPwd.username
                            }
                        })
                    }
                }).catch((error) => {
                    setStateData('loading', false);
                    if (error.message) {
                        if(error.message.includes('ReCaptcha Expired')) {
                            setIsRefresh(true);
                            setTimeout(() => {
                                setIsRefresh(false);
                                sendOtpRef.current.click();
                            }, 2000);
                        } else {
                            setStateData('errorMessage', error.message);
                            setStateData('showErrorMessage', true);
                        }
                    }
                    setIsRefresh(true);
                    setTimeout(() => {
                        setIsRefresh(false);
                    }, 1000);
                })
            }
        })
    }

    const login = () => {
        history.push("/sign-in")
    }

    const toggleShowPassword = () => setStateData('showPassword', !restPwd.showPassword);
    const toggleShowConfirmPassword = () => setStateData('showConfirmPassword', !restPwd.showConfirmPassword);

    return (
        <RootDiv>
            <Card className='card' sx={{ maxWidth: '600px !important' }}>
                <Grid container>
                    {/* <Grid item lg={5} md={5} sm={12} xs={12}>
                        <JustifyBox p={4} height="100%">
                            <IMG
                                src={process.env.PUBLIC_URL + '/forgotpassword.svg'}
                                alt="forgotpassword"
                            />
                        </JustifyBox>
                    </Grid> */}
                    <Grid item xs={12}>
                        <ContentBox style={{ padding: '3rem' }}>
                            <Grid item xs={12} className="text-center">
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + '/images/auction.svg'} height={50} alt='E-AuctionLogo'></img> }
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + '/images/nbid.png'} height={45} alt='NbidLogo'></img> }
                            </Grid>
                            <br />
                            <Typography component="h1" variant="h5" gutterBottom className="text-center">
                                {t('Reset_Password')}
                            </Typography>
                            <AlertInfoBox variant="body2" sx={{ justifyContent: 'center' }}>
                                <InfoRoundedIcon color='warning' sx={{ padding: '5px'}} />
                                {t('Continue_To_Reset_Your_Password')}
                            </AlertInfoBox>
                            <Grid container style={{ paddingTop: '8px' }}>
                                <Grid item xs={12}>
                                    <TextField id="password" label={t('New_Password')} type={restPwd.showPassword ? "text" : "password"}
                                        style={{ margin: '8px 8px 8px 0px' }} value={restPwd.password}
                                        onChange={onPasswordChange}
                                        onClick={clearErrors}
                                        onBlur={handlePopoverClose}
                                        autoComplete="new-password"
                                        helperText={touched['password'] && errors.password}
                                        error={touched['password'] && errors.password}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowPassword}
                                                    >
                                                        {restPwd.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <PasswordPolicy open={open} pwdLength={pwdLength} isUpper={isUpper} isLower={isLower} isNumber={isNumber} isSpecial={isSpecial} maxPwdLength={maxPwdLength} />
                                    <TextField id="confirmpassword" label={t('Confirm_Password')} type={restPwd.showConfirmPassword ? "text" : "password"}
                                        style={{ margin: '8px 8px 8px 0px' }} value={restPwd.confirmpassword}
                                        onChange={onInputChange}
                                        onBlur={validatePasswords}
                                        autoComplete="new-password"
                                        helperText={touched['confirmpassword'] && errors.confirmpassword}
                                        error={touched['confirmpassword'] && errors.confirmpassword}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowConfirmPassword}
                                                    >
                                                        {restPwd.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="text-center" style={{ marginTop: '15px' }}>
                                    <Link onClick={login} style={{ float: 'left', fontSize: '12px', cursor: 'pointer' }}>{t('Back_to_Sign_In')}</Link>
                                    <Button id="pwd-reset-send-otp" ref={sendOtpRef} size="small" variant="contained" color="primary" style={{ float: 'right' }}
                                        onClick={sendOtp}
                                        disabled={restPwd.loading || !pwdLength || !maxPwdLength || !isUpper || !isLower || !isNumber || !isSpecial
                                            || (restPwd.password !== restPwd.confirmpassword)}>
                                        {t('Send_Otp')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ContentBox>
                    </Grid>
                </Grid>
            </Card>
            {restPwd.loading && <AuctionLoader show={true} invisible={false} />}
            {restPwd.showErrorMessage &&
                <AuctionSnackBar show={restPwd.showErrorMessage} message={restPwd.errorMessage} severity="error" reset={() => setStateData('errorMessage', null)}></AuctionSnackBar>}
            {restPwd.showSuccessMessage &&
                <AuctionSnackBar show={restPwd.showSuccessMessage} message={restPwd.successMessage} severity="success" reset={() => setStateData('successMessage', null)}></AuctionSnackBar>}
            {!isRefresh && <AuctionReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
        </RootDiv>
    )
}

export default AutoAccountPasswordChange
