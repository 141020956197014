import React, { 
    useContext,
    useEffect,
    useState 
}                                   from 'react';
import {
    Container,
    Grid,
    TextField,
    MenuItem,
    Tooltip,
    Fab
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next';
import { makeStyles }               from '@mui/styles';
import { Validation }               from '../../common/Validation';
import { NumericFormat }            from 'react-number-format';
import { UserContext }              from '../../common/context/UserContext';
import { SelectControl }            from "../../custom-form-control/SelectControl";   
import { DateTimePickerControl }    from '../../custom-form-control/DateTimePickerControl';
import { useWatch }                 from 'react-hook-form';
import { NumberFormatController }   from '../../custom-form-control/NumberFormatController';
import { AutoExtensionMode }        from '../../common/StaticVariableDeclaration';
import { Check, Clear, Edit }       from '@mui/icons-material';
import { 
    AuctionFieldSet, 
    AuctionLengend 
}                                   from '../../authentication/AuthStyle';
import { useMutation }              from '@apollo/client';
import { UPDATE_ITEM }              from '../../GraphQL/Mutation';
import { LIST_ITEMS }               from '../../GraphQL/Queries';
import AuctionSnackBar              from '../../common/AuctionSnackBar';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../common/GraphQLErrors';

const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: theme.boxShadow
    },
    topFab: {
        width: '34px !important',
        height: '34px !important',
        background: `${theme.palette.background.paper} !important`,
    },
    checkFab: {
        border: `1px solid ${theme.palette.success.main} !important`,
        '&[disabled]': {
            border: `1px solid ${theme.palette.text.secondary} !important`,
            pointerEvents: 'none',
            boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important'
        }
    },
    closeFab: {
        border: `1px solid ${theme.palette.error.main} !important`
    }
}))

const ItemBidDetail = ({auction_id, isNew, register, errors, control, setValue, 
    getValues, trigger, auctionStartDate, auctionEndDate, getItemValues, isValidForm,
    canEdit}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const autoExtension = useWatch({ control, name: 'autoExtension' });
    const autoExtensionMode = useWatch({ control, name: 'autoExtensionMode' });
    const startTime = useWatch({ control, name: 'startTime' });
    const [isEdit, setIsEdit] = useState(false);
    const [tempValue, setTempValue] = useState('');
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [updateItem, {loading: updatingItem, error: errorOnUpdatingItem}] = useMutation(UPDATE_ITEM, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateItem != null) {
                setMessage({
                    show: true,
                    message: t('Item_Bid_Details_Updated_Successfully'),
                    severity: 'success'
                })
                setIsEdit(false);
            }
        },
        refetchQueries: [LIST_ITEMS]
    })

    useEffect(() => {
        if (!autoExtension) {
            setValue('autoExtensionMode', null);
            setValue('extendTimeWhenBidReceivedInLastMinute', 0);
            setValue('extendTimeBy', 0);
            setValue('incrementalAmountDuringExtension', 0);
            trigger('autoExtension');
            trigger('autoExtensionMode');
            trigger('extendTimeWhenBidReceivedInLastMinute');
            trigger('extendTimeBy');
            trigger('incrementalAmountDuringExtension');
        }
    }, [autoExtension]);

    useEffect(() => {
        if(autoExtensionMode === AutoExtensionMode.unlimitedExtension) {
            setValue('noOfExtension', 0);
            trigger('noOfExtension');
        }
    }, [autoExtensionMode]);

    const enableEdit = () => {
        setIsEdit(true);
        setTempValue(getValues());
    }

    const cancelEdit = () => {
        setIsEdit(false);
        setValue('reservePrice', tempValue.reservePrice);
        setValue('emdAmount', tempValue.emdAmount);
        setValue('bidStartPrice', tempValue.bidStartPrice);
        setValue('incrementalAmount', tempValue.incrementalAmount);
        setValue('autoExtension', tempValue.autoExtension);
        setValue('autoExtensionMode', tempValue.autoExtensionMode);
        setValue('extendTimeWhenBidReceivedInLastMinute', tempValue.extendTimeWhenBidReceivedInLastMinute);
        setValue('extendTimeBy', tempValue.extendTimeBy);
        setValue('noOfExtension', tempValue.noOfExtension);
        setValue('lastDateForEmd', tempValue.lastDateForEmd);
        setValue('startTime', tempValue.startTime);
        setValue('endTime', tempValue.endTime);
        trigger();
        setTempValue('');
    }   

    const updateItemBidValues = () => {
        updateItem({
            variables: {
                auctionId: auction_id,
                itemInput: {
                    id: getItemValues('id'),
                    itemName: getItemValues('itemName'),
                    itemRefNo: getItemValues('itemRefNo'),
                    description: getItemValues('description'),
                    itemType: getItemValues('itemType'),
                    quantity: Number(getItemValues('quantity')),
                    unit: getItemValues('unit')
                }
            }
        })
    }

    return (
        <div>
            <AuctionFieldSet>
                <AuctionLengend align="left"><strong>{t('Bid_Details')}</strong></AuctionLengend>
                <Grid container spacing={3}>
                    {(!isNew && canEdit) && <Grid item xs={12} sx={{paddingTop: '0px !important'}}>
                        {!isEdit && <span style={{float: 'right', position: 'relative', top: '8px'}}>
                            <Tooltip title={t('Edit')}>
                                <Fab className={`${classes.topFab} ${classes.editFab}`} color='primary' 
                                    onClick={enableEdit}>
                                <Edit color='primary' />
                                </Fab>
                            </Tooltip>
                        </span>}
                        {isEdit && <span style={{float: 'right', position: 'relative', top: '8px'}}>
                            <Tooltip title={t('Save')}>
                                <Fab className={`${classes.topFab} ${classes.checkFab}`} style={{position: 'relative', right: '10px'}} color='success'
                                    onClick={updateItemBidValues} disabled={!isValidForm}>
                                    <Check color='success' />
                                </Fab>
                            </Tooltip>
                            <Tooltip title={t('Cancel')}>
                            <Fab className={`${classes.topFab} ${classes.closeFab}`} color='error' onClick={cancelEdit}>
                                <Clear color='error' />
                            </Fab>
                            </Tooltip>
                        </span>}
                    </Grid>}
                    <Grid item xs={4}>
                        <NumberFormatController 
                            mandatory={(isNew || isEdit) ? true : false}
                            errors={errors}
                            control={control}
                            name="reservePrice"
                            rules={Validation.minAmount}
                            label={t('Reserve_Price')}
                            required={isNew || isEdit}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberFormatController 
                            mandatory={(isNew || isEdit) ? true : false}
                            errors={errors}
                            control={control}
                            name="emdAmount"
                            rules={Validation.minAmount}
                            label={t('EMD_Amount')}
                            required={isNew || isEdit}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="lastDateForEmd"
                            label={t('Last_Date_For_Emd_Amount')}
                            maxDateTime={auctionEndDate}
                            disablePastDate={true}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectControl
                            name='autoExtension'
                            control={control}
                            errors={errors}
                            label={t('Auto_Extension')}
                            getValues={getValues}
                            disabled={!isNew && !isEdit}
                        >
                            <MenuItem value={true} key={1}>{t('Yes')}</MenuItem>
                            <MenuItem value={false} key={0}>{t('No')}</MenuItem>
                        </SelectControl>
                    </Grid>
                    {autoExtension && <Grid item xs={4}>
                        <SelectControl
                            name='autoExtensionMode'
                            control={control}
                            errors={errors}
                            rules={Validation.required}
                            label={t('Auto_Extension_Mode')}
                            getValues={getValues}
                            disabled={!isNew && !isEdit}
                            required={isNew || isEdit}
                        >
                            {enumListOptions.AutoExtensionMode.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id}>{item.uiCode}</MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>}
                    { autoExtensionMode == AutoExtensionMode.limitedExtension && <Grid item xs={4}>
                        <TextField id="noOfExtension" label={t('No_Of_Extensions')} fullWidth required={isNew || isEdit}
                            helperText={errors?.noOfExtension && errors?.noOfExtension.message}
                            placeholder={t('No_Of_Extensions')} type="text" variant='standard'
                            {...register('noOfExtension', Validation.number)}
                            disabled={!isNew && !isEdit}
                            
                        />
                    </Grid>}
                    {autoExtension && <Grid item xs={4}>
                        <TextField id="extend-time-when-bid-received-in-last-minute" 
                            {...register("extendTimeWhenBidReceivedInLastMinute", Validation.required)}
                            helperText={errors["extendTimeWhenBidReceivedInLastMinute"] && errors["extendTimeWhenBidReceivedInLastMinute"].message}
                            label={t('Extend_Time_When_Bid_Received_Last_Minute')} variant="standard" fullWidth required={isNew || isEdit}
                            type="number"
                            disabled={!isNew && !isEdit}/>
                    </Grid>}
                    {autoExtension && <Grid item xs={4}>
                        <TextField id="extend-time-by" label={t('Extend_Time_By')} 
                            {...register("extendTimeBy", Validation.required)}
                            type="number"
                            required={isNew || isEdit}
                            helperText={errors["extendTimeBy"] && errors["extendTimeBy"].message}
                            variant="standard" fullWidth disabled={!isNew && !isEdit}/>
                    </Grid>}
                    <Grid item xs={4}>
                        <NumberFormatController 
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="bidStartPrice"
                            rules={Validation.amount}
                            label={t('Bid_Start_Price')}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberFormatController 
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="incrementalAmount"
                            rules={Validation.amount}
                            label={t('Incremental_Amount')}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                    {/* {autoExtension && <Grid item xs={4}>
                        <NumberFormatController 
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="incrementalAmountDuringExtension"
                            rules={Validation.amount}
                            label={t('Incremental_Amount_During_Extension')}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>} */}
                    <Grid item xs={4}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="startTime"
                            label={t('Bidding_Start_Date_And_Time')}
                            minDateTime={auctionStartDate}
                            maxDateTime={auctionEndDate}
                            disablePastDate={true}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="endTime"
                            label={t('Bidding_End_Date_And_Time')}
                            minDateTime={startTime ? startTime : auctionStartDate}
                            maxDateTime={auctionEndDate}
                            disablePastDate={true}
                            disabled={!isNew && !isEdit}
                        />
                    </Grid>
                </Grid>
            </AuctionFieldSet>
            {updatingItem && <AuctionLoader show={updatingItem} invisible={false} />}
            {(errorOnUpdatingItem) && <GraphQLErrors error={errorOnUpdatingItem} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </div>
    )
}

export default ItemBidDetail;