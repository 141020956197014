import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import { RootDiv } from '../authentication/AuthStyle';
import { AuctionStatus } from '../common/StaticVariableDeclaration';
import { UserContext } from '../common/context/UserContext';

export const GotoLanding = () => {

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if(!userContext.rootContext.userSession.isAuthenticated) {
            history.push({
                pathname: "/auction-list",
                state: {
                    userRole: [],
                    auctionListType: AuctionStatus.open,
                    pagination: {
                        page: 1,
                        pageSize: 10
                    },
                    isMyBid: false
                }
            })
        }
    }, [userContext.rootContext])

    useEffect(() => {
        sessionStorage.setItem('tourGuide', false);
        sessionStorage.setItem('isMfaEnabled', false);
        if(userContext.rootContext.userSession.isAuthenticated) {
            userContext.setRootContext(prevState => ({
                ...prevState,
                userSession: { isAuthenticated: false, session: null },
                loginUserRoles: [],
                currentDateTime: null,
                selectedMenu: 'auction',
                isUserAuctioneer: []
              })
            )
        }
    }, [])

    return (
        <RootDiv>
            {(loading) && <AuctionLoader show={loading} />}
        </RootDiv>
    )
}