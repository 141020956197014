import { docAcceptableMimeType, excelAcceptableMimeType, 
    imageAcceptableMimeType, pdfAcceptableMimeType, 
    pptAcceptableMimeType } from "./files-upload/FileUploadStaticVar";

const fileRestrictionTypes = (filetypes) => {

    let restrictedFileTypes = {};
    
    if( filetypes.length > 0 ) {
        filetypes.forEach(element => {
            if(element == 'PDF') {
                restrictedFileTypes = {...restrictedFileTypes, ...pdfAcceptableMimeType}
            } if(element == 'WORD') {
                restrictedFileTypes = {...restrictedFileTypes, ...docAcceptableMimeType}
            } if(element == 'EXCEL') {
                restrictedFileTypes = {...restrictedFileTypes, ...excelAcceptableMimeType}
            } if(element == 'JPEG' || element == 'PNG') {
                restrictedFileTypes = {...restrictedFileTypes, ...imageAcceptableMimeType}
            } if(element == 'PPT') {
                restrictedFileTypes = {...restrictedFileTypes, ...pptAcceptableMimeType}
            }
        });
        return restrictedFileTypes;
    } else {
        return restrictedFileTypes;
    }

}

export { fileRestrictionTypes };
