import { Close } from '@mui/icons-material';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemText,
  MenuItem, TextField, Tooltip, Typography, Stack
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LIST_MESSAGE_CODE } from '../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import { useServerCurrentDateTime } from '../utils/Utils';

const useStyles = makeStyles((theme) => ({
  close: {
    '&:hover': {
      backgroundColor: "lightGray",
    }
  },
  dialogTitle: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    backgroundColor: 'aliceblue',
    color: 'black',
    padding: '10px !important',
  },
  title: {
    flexGrow: 1,
  },
  dialogActions: {
    padding: '8px 25px !important',
    justifyContent: 'space-between !important'
  }
}));


function CommunicationsFilter(props) {
  const { dialogOpen, setDialogOpen, onSearch, onClose, date, storedMessage, setStoredMessage, setCommunicationSmsList, isFilterApplied, setIsFilterApplied,
    storedSmsDescription, setStoredSmsDescription, storedSmsStartDate, setStoredSmsStartDate, storedSmsEndDate, setStoredSmsEndDate, page, setPage, itemPerPage, setItemPerPage} = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const serverCurrentTime = useServerCurrentDateTime();
  const [startDate, setStartDate] = useState(isFilterApplied ? storedSmsStartDate ? storedSmsStartDate : [] : date)
  const [endDate, setEndDate] = useState(isFilterApplied ? storedSmsEndDate ? storedSmsEndDate : [] : serverCurrentTime)
  const [messageTo, setMessageTo] = useState(isFilterApplied ? storedMessage ? storedMessage : [] : [])
  const [listMessageCodeCd, setListMessageCodeCd] = useState([])
  const [codeTo, setCodeTo] = useState([])
  const [selectedMessage, setSelectedMessage] = useState(isFilterApplied ? storedSmsDescription ? storedSmsDescription : null : null)
  const [open, setOpen] = useState([false])
  const [statusName, setStatusName] = useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMessageTo(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatusName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onChangeMessage = (event) => {
    setSelectedMessage(event.target.value);
  }

  const [userListMessageCode] = useLazyQuery(LIST_MESSAGE_CODE, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setListMessageCodeCd(
        data.listMessageCodeCd
      )
    }
  })

  const listContact = (event) => {
    for (let i = 0; i <= 1; i++) {
      AxiosAuctionInterceptor.get(`audit/message-contacts/${props.auctionId}/${i}/${false}`).
        then((response) => {
          if (i === 1) {
            setCodeTo(response[1])
            // } else if (i === 3) {
            //   setCodeCc(response.data[3])
            // } else if (i === 4) {
            //   setCodeBcc(response.data[4])
          }
        }).catch((error) => {
          console.log("error", error)
        })
    }
  }

  const smsFilter = (selectedMessage, endDate, startDate, messageTo) => {
    let payload = {
      receiver: messageTo.length > 0 ? {
        1: messageTo
      } : {},
      statusCds:
        statusName
      ,
      information: selectedMessage,
      startRange: startDate,
      endRange: endDate,
      email: false,
      page: page,
      size: itemPerPage,
      asc: true
    }
    AxiosAuctionInterceptor.post(`audit/sms-filter/${props.auctionId}`, payload).
      then((response) => {
        setCommunicationSmsList(response.messages)
        setStoredMessage(messageTo)
        setStoredSmsDescription(selectedMessage)
        setStoredSmsStartDate(startDate)
        setStoredSmsEndDate(endDate)
        setIsFilterApplied(true)
      }).catch((error) => {
        console.log("error", error)
      })
  }

  const handleChangeFilter = (event) => {
    smsFilter(selectedMessage, endDate, startDate, messageTo, 1)
    setDialogOpen(false)
    setPage(1)
  }

  const onClearFields = () => {
    setMessageTo([])
    setStartDate(date)
    setEndDate(serverCurrentTime)
    setSelectedMessage([])
    setStatusName([])
  }

  useEffect(() => {
    if (listMessageCodeCd.length > 0) {
      listContact()
    }
  }, [listMessageCodeCd])

  useEffect(() => {
    userListMessageCode({
      variables: {
        tableName: 'status'
      }
    })
  }, [])

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog id='sms-dialog' fullwidth open={dialogOpen} onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle}>
            <Typography id='sms-name' variant="h5" component="div" className={classes.title}>
            {t("SMS_Search")}
            </Typography>
            <Tooltip title={t("Close")}>
              <IconButton
                color="secondary"
                onClick={() => handleClose()}>
                <Close color='error' />
              </IconButton>
            </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" spacing={2} py={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                size='small'
                fullWidth
                id="to-type"
                select
                label={t("To")}
                SelectProps={{
                  multiple: true,
                  value: messageTo,
                  onChange: handleChange,
                  renderValue: (selected) => {
                    let displayToTypes = [];
                    for (let i = 0; i < selected.length; i++) {
                      let to = codeTo.find((tType) => tType === selected[i]);
                      if (to) {
                        displayToTypes.push(to)
                      }
                    }
                    return displayToTypes.join(', ');
                  }

                }}
                variant="outlined"
              >
                {codeTo?.map((to) => (
                  <MenuItem key={to} value={to}>
                    <Checkbox checked={messageTo.includes(to)} />
                    <ListItemText primary={to} />
                  </MenuItem>
                ))}
              </TextField>
              {/* <TextField
                size='small'
                fullWidth
                id="status"
                select
                label={t("Status")}
                SelectProps={{
                  multiple: true,
                  value: statusName,
                  onChange: handleChangeStatus,
                  renderValue: (selected) => {
                    let displayToTypes = [];
                    for (let i = 0; i < selected.length; i++) {
                      let status = listMessageCodeCd.find((tType) => tType.codeCd === selected[i]);
                      if (status) {
                        displayToTypes.push(status.code)
                      }
                    }
                    return displayToTypes.join(', ');
                  }

                }}
                variant="outlined"
              >
                {listMessageCodeCd?.map((status) => (
                  <MenuItem key={status.codeCd} value={status.codeCd}>
                    <Checkbox checked={statusName.includes(status.codeCd)} />
                    <ListItemText primary={status.code} />
                  </MenuItem>
                ))}
              </TextField> */}
            </Stack>
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  closeOnSelect={false}
                  ampmInClock
                  // disablePast
                  showToolbar
                  minDateTime={new Date(date)}
                  maxDateTime={serverCurrentTime}
                  timeIcon={<AccessTimeIcon />}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  value={startDate}
                  label="Start Date"
                  OpenPickerButtonProps={{ id: `sms-start-time-picker` }}
                  renderInput={(props) =>
                    <TextField
                      id='sms-start-date'
                      fullWidth
                      variant='outlined'
                      size='small'
                      {...props}
                      inputProps={{ ...props.inputProps, readOnly: true }}
                    />
                  }
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  closeOnSelect={false}
                  ampmInClock
                  disableIgnoringDatePartForTimeValidation
                  // disablePast
                  showToolbar
                  minDateTime={new Date(startDate)}
                  maxDateTime={serverCurrentTime}
                  timeIcon={<AccessTimeIcon />}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  value={endDate}
                  label="End Date"
                  OpenPickerButtonProps={{ id: `sms-end-time-picker` }}
                  renderInput={(props) =>
                    <TextField
                      id="sms-end-date"
                      fullWidth
                      variant='outlined'
                      size='small'
                      // helperText={'invalid date'}
                      {...props}
                      inputProps={{ ...props.inputProps, readOnly: true }}
                    />
                  }
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={1}>
              <TextField id="description" label="Message" variant="outlined" size='small' style={{ width: 550 }}
                value={selectedMessage}
                onChange={onChangeMessage}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button id='resetButton' styles={{ float: "left" }} variant='outlined' onClick={onClearFields}>
            {t("Reset")}
          </Button>
          <Button id='smsFilterSearch' styles={{ float: "right" }} color="primary" variant='contained' onClick={handleChangeFilter}>
            {t("Search")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default CommunicationsFilter
