
import CloseIcon from '@mui/icons-material/Close';
import {
    Button, Dialog, DialogContent, DialogContentText,
    Grid, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import SendIcon from '@mui/icons-material/Send';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DraftsIcon from '@mui/icons-material/Drafts';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

const useStyles = makeStyles((theme) => ({
    details: {
        color: theme.roleDetailsColor.color,
        padding: '0.8rem 0rem 0.8rem 0.8rem',
        fontSize: '20px !important'
    },
    closeBtn: {
        marginTop: '0.5rem !important',
        float: 'right',
        color: `${theme.roleDetailsColor.color} !important`
    },
    container: {
        background: theme.roleDetailsColor.containerColor
    },
    tableHead: {
        background: `${theme.rolesHeaderBachground} !important`
    }

}));

function StatusTable(props) {
    const { auctionId, messageId } = props;
    const { t } = useTranslation();
    const classes = useStyles()
    const [open, setOpen] = useState(props.show);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [messageList, setMessageList] = useState([]);
    const columns = [
        { id: 'status', label: t('Status'), minWidth: 15 },
        { id: 'icon', minWidth: 2},
        { id: 'time', label: t('Time'), minWidth: 25 }      
    ];

   const handleClickOpen = () => {
    setDialogOpen(true);
  };

    const handleClose = () => {
        setOpen(false);
        props.setShowStatusHistory({
            show: false,
        });
       
    }

    const statusTableApi = () => {
        setLoading(true);
        AxiosAuctionInterceptor.get(`audit/email-histories/${auctionId}/${messageId}`).then((response) => {
           setMessageList(response)
            setLoading(false);
        }).catch((error) => {
           setLoading(false);
        });
    }

    useEffect(() => {
      statusTableApi()
    }, [])


    return (
        <div id='status-div'>
            <Dialog  open={props.show} aria-labelledby="status-detail-title" id='dialogStatus'
                aria-describedby="status-detail-description" fullWidth
                maxWidth="xs">
                <Grid container className={classes.container} >
                    <Grid item xs={6} >
                        <Typography id="status-detail-title" className={classes.details} >
                            {t("Status_History")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button id='btnClose' className={classes.closeBtn} onClick={handleClose}><CloseIcon /></Button>
                    </Grid>
                </Grid>
                <DialogContent id='content'>
                    <DialogContentText id="status-detail-description" >
                        <Grid item xs={12}>
                            <TableContainer id='tablecontainer'>
                                <Table id='table' stickyHeader aria-label="sticky-table-header" >
                                    <TableHead id='tableHead' className={classes.tableHead}>
                                        <TableRow id='tableRow'>
                                            {columns.map((column, index) => (
                                                <TableCell id={`cell-column${index}`} key={index} style={{ width: column.minWidth + "%", fontWeight: 'bold', fontSize: '15px' }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody' >
                                        {messageList.map((row, index) => (
                                           <TableRow key={index}>
                                                <TableCell id='tableCell' size='small'>
                                                    <span>{row.statusLocale}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {row.statusLocale === "Click" ? <AdsClickIcon /> : ''}
                                                    {row.statusLocale === "Open"? <DraftsIcon /> : ''}
                                                    {row.statusLocale === "Delivery" ? <MarkunreadIcon /> : ''}
                                                    {row.statusLocale === "Send" ? <SendIcon /> : ''}
                                                    {row.statusLocale === "Initiate" ? <MailOutlineIcon /> : ''}
                                                    {row.statusLocale === "Reject" ? <CloseTwoToneIcon color='error' /> : ''}
                                                    {row.statusLocale === "Hard bounce" ? <CloseTwoToneIcon color='error' /> : ''}
                                                </TableCell>
                                                <TableCell id= 'table-cell-two' size='small'>
                                                    <span>{format(new Date(row.rowCreatedOn), 'dd-MM-yyyy hh:mm a')}</span>
                                                </TableCell>
                                            </TableRow>   
                                    ))}        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
               {loading && <AuctionLoader show={loading} invisible={false} />}
            </Dialog>
        </div>
    )
}

export default StatusTable;