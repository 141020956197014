import { BottomNavigation, Button, ButtonGroup, IconButton, Paper, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { Close, TaskAltOutlined } from '@mui/icons-material';
import CommunicationsFilter from './CommunicationsFilter';
import { useLazyQuery } from '@apollo/client';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import AuctionPagination from '../common/pagination/AuctionPagination';
import { LIST_EMAIL_MESSAGE, LIST_MESSAGE } from '../GraphQL/Queries';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CommunicationsEmail from './CommunicationsEmail';
import CommunicationsEmailFilter from './CommunicationsEmailFilter';
import SearchIcon from '@mui/icons-material/Search';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StatusTable from './/StatusTable';

const useStyles = makeStyles((theme) => ({
  filtermsg: {
    color: theme.palette.success.main,
    marginLeft: '1.5rem',
    borderRadius: '2rem',
    width: 'fit-content',
    float: 'right',
    border: `1px solid ${theme.palette.success.main} !important`,
    padding: "0px 5px"
  },
  tableHead: {
    fontWeight: "bold !important", fontSize: "14px !important", padding: '10px 15px !important',
  },
  tableCell: {
    padding: '12px 15px !important',
  },
  clear: {
    padding: '5px !important',
    marginLeft: '0px !important'
  },
  searchText: {
    borderRight: `1px solid ${theme.palette.success.main} !important`,
    paddingRight: '5px !important',
    fontSize: '14px !important'
  },
}));


function Communications(props) {
  const [page, setPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [communicationEmailList, setCommunicationEmailList] = useState([])
  const { t } = useTranslation()
  const classes = useStyles()
  const containerRef = useRef(null)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState(false)
  const [sms, setSms] = useState(true)
  const [communicationSmsList, setCommunicationSmsList] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [storedMessage, setStoredMessage] = useState([])
  const [storedSmsDescription, setStoredSmsDescription] = useState([])
  const [storedSmsStartDate, setStoredSmsStartDate] = useState([])
  const [storedSmsEndDate, setStoredSmsEndDate] = useState([])
  const [storedEmail, setStoredEmail] = useState([])
  const [storedEmailDescription, setStoredEmailDescription] = useState(null)
  const [storedEmailStartDate, setStoredEmailStartDate] = useState([])
  const [storedEmailEndDate, setStoredEmailEndDate] = useState([])
  const [storedStatus, setStoredStatus] = useState([]);
  const [showStatusHistory, setShowStatusHistory] = useState({ show: false })


  const [userListMessage, { loading: messageLoading, error: errorOnMessageLoad }] = useLazyQuery(LIST_MESSAGE, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCommunicationSmsList([])
      if (data.listByMessageOf != null) {
        data.listByMessageOf.messages.map(message => {
          setCommunicationSmsList(prevState => ([
            ...prevState,
            {
              sender: message.sender,
              receiver: message.receiver,
              onBehalf: message.onBehalf,
              information: message.information,
              statusLocale: message.statusLocale,
              informationHtml: message.information,
              rowCreatedOn: message.rowCreatedOn,
              messageId: message.messageId
            }
          ]))
        })
        setCount(data.listByMessageOf.count)
      }
      setIsFilterApplied(false)
    }
  })

  useEffect(() => {
    if (sms) {
      if (isFilterApplied) {
        smsFilter(storedSmsDescription, storedSmsEndDate, storedSmsStartDate, storedMessage, page)
      } else {
        userListMessage({
          variables: {
            messageOf: props.auctionId,
            pagination: { limit: itemPerPage, page: page, asc: false },
          }
        })
      }
    }
  }, [itemPerPage, page])

  useEffect(() => {
    if (email) {
      if (isFilterApplied) {
        emailFilter(storedEmailDescription, storedEmailEndDate, storedEmailStartDate, storedEmail, storedStatus,page)
      } else {
        userListEmailMessage({
          variables: {
            messageOf: props.auctionId,
            pagination: { limit: itemPerPage, page: page, asc: false },
          }
        })
      }
    }
  }, [itemPerPage, page])

  const handleClickOpen = () => {
    // setPage(1);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const emailFilter = (selectedMessage, endDate, startDate, messageTo, statusName, page) => {
    setIsFilterApplied(false)
    let payload = {
      receiver: messageTo.length > 0 ? {
        1: messageTo
      } : {},
      statusCds:
        statusName
      ,
      information: selectedMessage,
      startRange: startDate,
      endRange: endDate,
      email: true,
      page: page,
      size: itemPerPage,
      asc: true
    }
    AxiosAuctionInterceptor.post(`audit/email-filter/${props.auctionId}`, payload).
      then((response) => {
        setCommunicationEmailList(response.emails)
        setStoredEmail(messageTo)
        setStoredEmailDescription(selectedMessage)
        setStoredEmailStartDate(startDate)
        setStoredEmailEndDate(endDate)
        setIsFilterApplied(true)
        setStoredStatus(statusName)
        setCount(response.count)
      }).catch((error) => {
        console.log("error", error)
      })
  }

   const smsFilter = (selectedMessage, endDate, startDate, messageTo, statusName, page) => {
    let payload = {
      receiver: messageTo.length > 0 ? {
        1: messageTo
      } : {},
      statusCds:
        statusName
      ,
      information: selectedMessage,
      startRange: startDate,
      endRange: endDate,
      email: false,
      page: page,
      size: itemPerPage,
      asc: true
    }
    AxiosAuctionInterceptor.post(`audit/sms-filter/${props.auctionId}`, payload).
      then((response) => {
        setCommunicationSmsList(response.messages)
        setStoredMessage(messageTo)
        setStoredSmsDescription(selectedMessage)
        setStoredSmsStartDate(startDate)
        setStoredSmsEndDate(endDate)
        setIsFilterApplied(true)
      }).catch((error) => {
        console.log("error", error)
      })
  }

  const handleClearFilter = () => {
    userListMessage({
      variables: {
        messageOf: props.auctionId,
        pagination: { limit: itemPerPage, page: page, asc: false },
      }
    })
  }

  const handleClearEmailFilter = () => {
    userListEmailMessage({
      variables: {
        messageOf: props.auctionId,
        pagination: { limit: itemPerPage, page: page, asc: false },
      }
    })
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  }

  const onRowsPerPageChange = (event) => {
    setPage(1)
    setItemPerPage(event.target.value);
  }

  const [userListEmailMessage, { loading: emailMessageLoading, error: errorOnEmailMessageLoad }] = useLazyQuery(LIST_EMAIL_MESSAGE, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setIsFilterApplied(false)
      setCommunicationEmailList([])
      setCommunicationEmailList(
        data.listEmailByMessageOf.emails
      )
      setCount(
        data.listEmailByMessageOf.count
      )
    }
  })

  const handleChange = () => {
    setSms(false)
    setEmail(true);
    userListEmailMessage({
      variables: {
        messageOf: props.auctionId,
        pagination: { limit: itemPerPage, page: page, asc: false },
      }
    })
  }

  const handleChangeSms = () => {
    setPage(1)
    setEmail(false)
    setSms(true);
    userListMessage({
      variables: {
        messageOf: props.auctionId,
        pagination: { limit: itemPerPage, page: page, asc: false },
      }
    })
  }

  return (
    <div>
      <Stack direction='row' mb={1} justifyContent='end' alignItems='center' spacing={5}>
        {/* <ToggleButtonGroup
          id='toggle-group'
          size='medium'
          color="primary"
          variant={sms ? 'contained' : 'outlined'}
          value={sms || email}
          exclusive
          aria-label="Platform"
        >
          <ToggleButton id='toggle-sms' 
          variant={sms ? 'contained' : 'outlined'} onClick={handleChangeSms} value={sms}>{t("sms")}</ToggleButton>
          <ToggleButton id='toggle-email' variant='contained' onClick={handleChange} value={email}>{t("email")}</ToggleButton>
        </ToggleButtonGroup> */}
        {<ButtonGroup variant="outlined" aria-label="text button group" size="small">
          <Stack direction='row'>
            <Button id="button-sms" color="primary" variant={sms ? 'contained' : 'outlined'}
                          onClick={handleChangeSms}  >{t('sms')}</Button>
                        <Button id="button-email" color="success" variant={email ? 'contained' : 'outlined'}
                          onClick={handleChange}  >{t('email')}</Button>

          </Stack>
                       
         </ButtonGroup>}
        {isFilterApplied && sms && <Slide in={isFilterApplied} unmountOnExit direction='down' container={containerRef.current}>
          <div className={classes.filtermsg}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <TaskAltOutlined fontSize='small' />
              <Typography className={classes.searchText} variant='body1' fontWeight='bold'>{t('Search_is_applied')}</Typography>
              <Tooltip title="Clear">
                <IconButton className={classes.clear}
                  id='clear-filter'
                  color="secondary"
                  onClick={handleClearFilter}>
                  <Close id='clear' color='error' />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>
        </Slide>}
        {isFilterApplied && email && <Slide in={isFilterApplied} unmountOnExit direction='down' container={containerRef.current}>
          <div className={classes.filtermsg}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <TaskAltOutlined fontSize='small' />
              <Typography className={classes.searchText} variant='body1' fontWeight='bold'>{t('Search_is_applied')}</Typography>
              <Tooltip title="Clear">
                <IconButton className={classes.clear} id='clear-filter'
                  color="secondary"
                  onClick={handleClearEmailFilter}>
                  <Close id='clear' color='error' />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>
        </Slide>}
        <Button id='btn' variant="contained" color='primary' size='small' onClick={handleClickOpen}>
          <SearchIcon />&nbsp;
          {t('Search')}
        </Button>
      </Stack>
      {<TableContainer component={Paper} ref={containerRef}>
        <Table stickyHeader aria-label="sticky table" id='table'>
          <TableHead id='tablerow' style={{ backgroundColor: "#dae2ef" }}>
            {/* {email && <TableCell></TableCell>} */}
            <TableCell className={classes.tableHead}>{t('Sender')}</TableCell>
            <TableCell className={classes.tableHead}>{t('Receiver')}</TableCell>
            {email && <TableCell className={classes.tableHead}>{t('Subject')}</TableCell>}
            {sms && <TableCell className={classes.tableHead}>{t('Message')}</TableCell>}
            <TableCell className={classes.tableHead}>{t('Sent_On')}</TableCell>
            <TableCell className={classes.tableHead}>{t('Status')}</TableCell>
          </TableHead>
          <TableBody>
            {sms && communicationSmsList.length === 0 &&
              <TableRow>
                <TableCell colSpan={6}>
                  {t("No_Records_Found")}
                </TableCell>
              </TableRow>
            }
            {email && communicationEmailList.length === 0 &&
              <TableRow>
                <TableCell colSpan={6}>
                  {t("No_Records_Found")}
                </TableCell>
              </TableRow>
            }
            {
              sms && communicationSmsList.length > 0 && communicationSmsList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align='left' className={classes.tableCell} component="th" scope="row">{row.sender} </TableCell>
                  <TableCell align="left" className={classes.tableCell}>{row.receiver}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>{row.information}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>{format(new Date(row.rowCreatedOn), 'dd/MM/yyyy hh:mm a')}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Tooltip title={t('View_Status_History')}>
                      <IconButton aria-label="action" onClick={() => setShowStatusHistory({ show: true, messageId: row.messageId })}>
                          <VisibilityIcon align="left" className={classes.icons} id='visible' color='primary' />
                      </IconButton>
                    </Tooltip>
                    {row.statusLocale}
                  </TableCell>
                </TableRow>
              ))
            }
            {email && communicationEmailList.length > 0 && communicationEmailList.map((rowDetails) => {
              return (
                <CommunicationsEmail rowDetails={rowDetails} auctionId={props.auctionId} />
              )
            })}
          </TableBody>
        </Table>
        {
          <BottomNavigation style={{ padding: '12px' }}>
            <AuctionPagination rowsPerPage={itemPerPage} page={page} totalItems={count}
              onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
          </BottomNavigation>
        }
      </TableContainer>}
      {email && dialogOpen && <CommunicationsEmailFilter dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} onClose={handleClose} communicationEmailList={communicationEmailList} isFilterApplied={isFilterApplied}
        setIsFilterApplied={setIsFilterApplied} page={page} setPage={setPage} itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} date={props.date} auctionId={props.auctionId} setCommunicationEmailList={setCommunicationEmailList}
        storedEmail={storedEmail} setStoredEmail={setStoredEmail} storedEmailDescription={storedEmailDescription} emailFilter={emailFilter}
        setStoredEmailDescription={setStoredEmailDescription} storedEmailStartDate={storedEmailStartDate} setStoredEmailStartDate={setStoredEmailStartDate}
        storedEmailEndDate={storedEmailEndDate} setStoredEmailEndDate={setStoredEmailEndDate} storedStatus={storedStatus} setStoredStatus={setStoredStatus}
      />}
      {sms && dialogOpen && <CommunicationsFilter dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} onClose={handleClose} communicationSmsList={communicationSmsList}
        page={page} setPage={setPage} itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} auctionId={props.auctionId} date={props.date} setCommunicationSmsList={setCommunicationSmsList} isFilterApplied={isFilterApplied}
        setIsFilterApplied={setIsFilterApplied} storedMessage={storedMessage} setStoredMessage={setStoredMessage} storedSmsDescription={storedSmsDescription}
        setStoredSmsDescription={setStoredSmsDescription} storedSmsStartDate={storedSmsStartDate} setStoredSmsStartDate={setStoredSmsStartDate}
        storedSmsEndDate={storedSmsEndDate} setStoredSmsEndDate={setStoredSmsEndDate} />}
      {messageLoading || emailMessageLoading && <AuctionLoader show={messageLoading || emailMessageLoading} invisible={false} />}
      {showStatusHistory.show && <StatusTable show={showStatusHistory.show} setShowStatusHistory={setShowStatusHistory} auctionId={props.auctionId} messageId={showStatusHistory.messageId}  />}
    </div >
  )
}

export default Communications
