import React, {
    useState,
    useEffect, useContext
}                                   from 'react';
import {
    Container,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Grid,
    TextField,
    Tooltip,
    Fab,
    Button,
    DialogActions
}                                   from '@mui/material';
import { makeStyles }               from '@mui/styles';
import { useTranslation }           from 'react-i18next';
import { 
    useForm,
    Controller 
}                                   from 'react-hook-form';
import { Check, Clear, Edit }       from '@mui/icons-material';
import { useMutation }              from '@apollo/client';
import {
    AuctionFieldSet,
    AuctionLengend
}                                   from '../../authentication/AuthStyle';
import Address                      from '../../profile/address/Address';
import ListIdentification           from '../../common/identification/ListIdentification';
import ListPhoneNumber              from '../../contact/phone_number/ListPhoneNumber';
import ListEmail                    from '../../contact/email/ListEmail';
import { Validation }               from '../../common/Validation';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../common/GraphQLErrors';
import { UPDATE_BIDDER_AUTH_REP }   from '../../GraphQL/Mutation';
import { LIST_BIDDER_AUTH_REP }     from '../../GraphQL/Queries';
import {
    PartyType
}                                   from '../../common/StaticVariableDeclaration';
import { UserContext }          from '../../common/context/UserContext';

const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: theme.boxShadow
    },
    topFab: {
        width: '34px !important',
        height: '34px !important',
        background: `${theme.palette.background.paper} !important`,
    },
    checkFab: {
        border: `1px solid ${theme.palette.success.main} !important`
    },
    closeFab: {
        border: `1px solid ${theme.palette.error.main} !important`
    }
}))

const UpdateBidderAuthRep = ({show, onClose, auction_id, bidder_id, authRep, canEdit}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const [isEdit, setIsEdit] = useState(false);
    const [tempValue, setTempValue] = useState('')
    const userContext = useContext(UserContext).rootContext;

    const { register, control, getValues, setValue, trigger, reset,
            formState: { errors, isValid }} = useForm({
        mode: 'onChange', 
        defaultValues: {
            personId: '',
            name: '',
            roleId: '',
            individual: true
        }
    });

    const [updateBidderAuthRep, {loading: updatingBidderAuthRep, error: errorOnUpdatingBidderAuthRep}] = useMutation(UPDATE_BIDDER_AUTH_REP, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateAuthorizedRepresentative != null) {
                let bidder = data.updateAuthorizedRepresentative;
                setValue('personId', bidder.personId);
                setValue('roleId', bidder.roleId);
                setValue('name', bidder.name, { shouldValidate: true});
                setValue('individual', bidder.individual, { shouldValidate: true});
                setIsEdit(false);
            }
        },
        refetchQueries: [LIST_BIDDER_AUTH_REP]
    })

    useEffect(() => {
        setValue('personId', authRep.personId);
        setValue('name', authRep.name, { shouldValidate: true});
        setValue('roleId', authRep.roleId);
        setValue('address', authRep.address);
        setValue('email', authRep.email);
        setValue('phoneNumber', authRep.phoneNumber);
        setValue('identification', authRep.identification);
        setValue('individual', authRep.individual);
    }, [authRep])
     

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const editBidder = () => {
        setIsEdit(true);
        setTempValue(getValues());
    }

    const handleCancel = () => {
        setValue('name', tempValue.name, {shouldValidate: true});
        setTempValue('');
        setIsEdit(false);
    }

    const handleUpdate = () => {
        updateBidderAuthRep({
            variables: {
                authorizedRepresentative: {
                    personId: getValues('personId'),
                    individual: getValues('individual'),
                    name: getValues('name').trim(''),
                },
                auctionId: auction_id,
                bidderId: bidder_id,
                authRepRoleId: getValues('roleId')
            }
        })
    }

    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='lg'>
                <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                    <Typography variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                        {t('Auth_Rep_Details')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <AuctionFieldSet>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={Validation.required}
                                            render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="name"
                                                label={t('Name')} 
                                                type="text"
                                                variant="standard"
                                                fullWidth
                                                required
                                                disabled={!isEdit}
                                                helperText={errors?.name?.message}
                                                InputLabelProps={{ shrink: field.value }}  
                                            />
                                            )}
                                        />
                                    </Grid>
                                    { canEdit && <Grid item xs={12} sm={12} ms={12} lg={6}>
                                        {!isEdit && <span style={{float: 'right', position: 'relative', bottom: '12px'}}>
                                            <Tooltip title={t('Edit')}>
                                                <Fab id="auth-rep-edit-btn" className={`${classes.topFab} ${classes.editFab}`} color='primary' 
                                                    onClick={editBidder}>
                                                <Edit color='primary' />
                                                </Fab>
                                            </Tooltip>
                                        </span>}
                                        {isEdit && <span style={{float: 'right', position: 'relative', bottom: '12px'}}>
                                            <Tooltip title={t('Save')}>
                                                <Fab id="auth-rep-save-btn" className={`${classes.topFab} ${classes.checkFab}`} style={{position: 'relative', right: '10px'}} color='success'
                                                    onClick={handleUpdate} disabled={!isValid}>
                                                    <Check color='success' />
                                                </Fab>
                                            </Tooltip>
                                            <Tooltip title={t('Cancel')}>
                                            <Fab id="auth-rep-cancel-edit-btn" className={`${classes.topFab} ${classes.closeFab}`} color='error' onClick={handleCancel}>
                                                <Clear color='error' />
                                            </Fab>
                                            </Tooltip>
                                        </span>}
                                    </Grid>}
                                </Grid>
                            </AuctionFieldSet>
                            <br/>
                            <Grid conatiner spacing={1} style={{display: 'flex'}}>
                                <Grid item xs={4} lg={4} style={{width: '100%'}}>
                                    <AuctionFieldSet style={{height: '100%'}}>
                                        <AuctionLengend align="left"><strong>{t('Identification')}</strong></AuctionLengend>
                                        <div style={{marginTop: '-22px'}}>
                                            {getValues('personId') && <ListIdentification individual={getValues('individual')} personId={getValues('personId')}
                                                identifications={getValues('identification')}
                                                isEdit={canEdit && (authRep.loginId ? authRep.loginId == userContext.userSession.session.userId : true)}/>}
                                        </div>
                                    </AuctionFieldSet>
                                </Grid>
                                <Grid item xs={4} lg={4} style={{width: '100%'}}>
                                    <AuctionFieldSet style={{height: '100%'}}>
                                        <AuctionLengend align="left"><strong>{t('Phone_Number')}</strong></AuctionLengend>
                                        <div style={{marginTop: '-22px'}}>
                                            {getValues('personId') && <ListPhoneNumber personId={getValues('personId')}
                                                isEdit={canEdit && (authRep.loginId ? authRep.loginId == userContext.userSession.session.userId : true)}
                                                phoneNumbers={getValues('phoneNumber')}/>}
                                        </div>
                                    </AuctionFieldSet>
                                </Grid>
                                <Grid item xs={4} lg={4} style={{width: '100%'}}>
                                    <AuctionFieldSet style={{height: '100%'}}>
                                        <AuctionLengend align="left"><strong>{t('Email')}</strong></AuctionLengend>
                                        <div style={{marginTop: '-22px'}}>
                                            {getValues('personId') && <ListEmail personId={getValues('personId')}
                                                emails={getValues('email')}
                                                isEdit={canEdit && (authRep.loginId ? authRep.loginId == userContext.userSession.session.userId : true)}/>}
                                        </div>
                                    </AuctionFieldSet>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12}>
                                    <AuctionFieldSet>
                                        <AuctionLengend align="left"><strong>{t('Address')}</strong></AuctionLengend>
                                        {getValues('personId') && <Address isLoginUser={false} sourceId={getValues('personId')} 
                                            address={getValues('address')} hasPermission={canEdit}/>}
                                    </AuctionFieldSet>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id='close' size="small" variant="outlined" className={classes.btn} 
                            onClick={handleClose}>
                        {t('Close')}
                    </Button>
                </DialogActions>
                {updatingBidderAuthRep && <AuctionLoader show={updatingBidderAuthRep} invisible={false} />}
                {errorOnUpdatingBidderAuthRep && <GraphQLErrors error={errorOnUpdatingBidderAuthRep} show={false} />}
            </Dialog>
        </Container>
    )
}

export default UpdateBidderAuthRep;