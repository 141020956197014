import { alpha, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { FaRupeeSign } from "react-icons/fa";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const DisplayInfoChip = (props) => {
    const { title, value } = props;
    return (
        <Stack direction='column'
            sx={(theme) => ({
                borderRadius: '5px', padding: '0.25rem 0.6rem',
                background: theme.palette.type === 'light' ? 'aliceblue' : theme.palette.background.paper,
                borderLeft: theme.palette.type === 'light' ? '4px solid #cfdee8' : '4px solid #d1e6eb',
            })}
        >
            <Typography id={`label${title}`} variant='subtitle2' color='textSecondary'>{title}</Typography>
            <Stack direction='row' spacing={0.5} justifyContent='start' alignItems='center'
                sx={{ color: (theme) => theme.palette.type === 'light' ? '#066276' : theme.palette.primary.main }}>
                <Typography id={`value${title}`} variant='subtitle1' sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Stack>
        </Stack>
    )
}

export const DisplayInfoChips = (props) => {
    const { title, value } = props;
    return (
        <Stack direction='column'
            sx={(theme) => ({
                borderRadius: '5px', padding: '0.25rem 0.6rem',
                background: theme.palette.type === 'light' ? 'white' : theme.palette.background.paper,
                borderLeft: theme.palette.type === 'light' ? '4px solid #cfdee8' : '4px solid #d1e6eb',
            })}
        >
            <Stack direction='row'>
            <Typography id={`label${title}`} variant='subtitle2' color='textSecondary'>{title}</Typography>
            {title === "Location" && <LocationOnIcon fontSize='small' color='primary'/>}
            </Stack>
            <Stack direction='row' spacing={0.5} justifyContent='start' alignItems='center'
                sx={{ color: (theme) => theme.palette.type === 'light' ? '#066276' : theme.palette.primary.main }}>
                <Typography id={`value${title}`} variant='subtitle1' sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Stack>
        </Stack>
    )
}

export const BidderPriceView = (props) => {
    const { icon, title, value, labelId, className } = props;
    return (
        <Stack direction='row' alignItems='center' spacing={2} width='100%'
        className={className}
            sx={{
                borderRadius: '5px', padding: '0.9rem 0.6rem',
            }}>
            {/* <CustomIconDisplay bg={bg} color={color}> */}
            {icon}
            {/* </CustomIconDisplay> */}
            <Stack direction='column'>
                <Typography id={labelId} color='textSecondary' variant='subtitle2'>{title}</Typography>
                {value}
            </Stack>
        </Stack>
    )
}

export const DisplayLotChip = (props) => {
    const {  title, value} = props;
    return (
        <Stack direction='row' alignItems='center' spacing={2} width='100%'
        // className={className}
        sx={(theme) => ({
            borderRadius: '5px', padding: '0.25rem 0.6rem',
            background: theme.palette.type === 'light' ? 'white' : theme.palette.background.paper,
            borderLeft: theme.palette.type === 'light' ? '4px solid #cfdee8' : '4px solid #d1e6eb',
        })}>
            <Stack direction='column'>
                <Typography color='textSecondary' variant='subtitle2'>{title}</Typography>
                {value}
            </Stack>
        </Stack>
    )
}

export const ButtonStyled = styled(Button)(({ theme }) => ({
    boxShadow: '0 5px 18px -5px rgba(50,50,93,0.25),0 3px 16px -8px rgba(0,0,0,0.3)',
    background: `${theme.palette.primary.main} !important`,
    // fontWeight: 'bold !important',
    color: '#fff9f9',
    '&[disabled]': {
        color: '#fff9f9',
        background: `${alpha(theme.palette.primary.main, 0.5)} !important`
    },
    '&:hover': {
        boxShadow: '0 5px 27px -5px rgba(50,50,93,0.25),0 10px 16px -8px rgba(0,0,0,0.3)',
    }
}))

const CustomIconDisplay = styled('div')(({ theme, bg, color }) => ({
    padding: '0.3rem',
    borderRadius: '0.8rem',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
}))