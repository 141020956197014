import React, { useEffect, useState }      from 'react';
// import ImageGallery             from 'react-image-gallery';
import {
    Grid,
    IconButton,
    Tooltip,
    Typography
}                               from '@mui/material';
import { makeStyles }           from '@mui/styles';
import { useMutation }          from '@apollo/client';
import { useTranslation }       from 'react-i18next';
import DeleteIcon               from '@mui/icons-material/Delete';
import Lightbox                 from "yet-another-react-lightbox";
import Fullscreen               from "yet-another-react-lightbox/plugins/fullscreen";
import Video                    from "yet-another-react-lightbox/plugins/video";
import Inline                   from "yet-another-react-lightbox/plugins/inline";
import Thumbnails               from "yet-another-react-lightbox/plugins/thumbnails";
import { LIST_ITEM_LOT_FILES }  from '../../GraphQL/Queries';
import { DELETE_FILE }          from '../../GraphQL/Mutation';
import AuctionConfirmDialog     from '../../common/AuctionConfirmDialog';
import AuctionSnackBar          from '../../common/AuctionSnackBar';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import ImageIcon                from '@mui/icons-material/Image';
import './image.css'
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";

const useStyles = makeStyles((theme) => ({
    imageCount: {
        color: 'white',
        paddingTop: '14px',
        paddingLeft: '4px'
    }
}))

const AuctionImageGallery = ({ inputImages, canDelete, setUploadImage,lotAddress }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [showDelete, setShowDelete] = useState(true);

    const [deleteFile, {loading: deletingFile, error: errorOnDeletingFile}] = useMutation(DELETE_FILE, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteFile != null) {
                setMessage({
                    show: true,
                    message: t('Image_Video_Deleted_Successfully'),
                    severity: 'success'
                })
            }
        },
        refetchQueries: [LIST_ITEM_LOT_FILES]
    })

    const [images] = useState(inputImages ? 
        inputImages.map((image, index) => {
            if ((image.contentType).includes('video')) {
                return {
                    type: "video",
                    width: 1280,
                    height: 720,
                    sources: [
                        {
                            src: image.original,
                            type: image.contentType
                        }
                    ],
                    key: image.key,
                }
            } else if((image.contentType).includes('image')) {
                return {
                    type: "image",
                    src: image.original,
                    key: image.key,
                }
            }
        }) : 
    []);

    const DeleteButton = () => {
        return (
            <Tooltip title={t('Delete_Image')}>
                <IconButton id={`delete-image-${currentImageIndex}`} color="secondary" onClick={() => deleteSelectedFile(images[currentImageIndex])}> 
                    <DeleteIcon /> 
                </IconButton>
            </Tooltip>
        )
    }

    const UploadImages = () =>{
        return (
            <Tooltip title={t('Add_Image')}>
                <IconButton id={'item-image-upload'} onClick={() => {setUploadImage(true)}} style={{color:'white'}}>
                    <ImageIcon  />
                </IconButton>
            </Tooltip>
        )
    }

    const ImageCount = () => {
        return (
            <Typography id={'item-image-count'} style={{color:'white'}}>
                {currentImageIndex + 1}/{images.length}
            </Typography>
        )
    }

    const deleteSelectedFile = (file) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Selected_Image_Video'),
            onAgree: () => {
                deleteFile({
                    variables: {
                        fileName: file.key
                    }
                })
                resetSeekConfirmation();
            },
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const zoomEventListener = () => {
        if (document.fullscreenElement) {
            setShowDelete(false);
        } else {
            setShowDelete(true);
        }
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', zoomEventListener);
        return () => document.removeEventListener("fullscreenchange", zoomEventListener)
    }, [])

    return (
        <div>
            <Grid container >
                <Grid item xs={12}>
                    <Lightbox
                        // imageFit="cover"
                        styles={{ container: { backgroundColor: "black" ,borderRadius:'5px', margin: lotAddress ? '14px 0px -2px 0px' : '8px 0px -2px 0px', height:lotAddress && '94%' } }}
                        open={true}
                        slides={images}
                        plugins={[Fullscreen, Video, Inline, Thumbnails,Slideshow]}
                        inline={{style: {aspectRatio: "2.5 / 1.6"}}}
                        thumbnails={{ 
                            position:  !showDelete ?'bottom' : '',
                            width: !showDelete ? 80 :'',
                            height: !showDelete ? 40:'',
                            border: !showDelete ? 1 : '',
                            borderRadius: !showDelete ? 4 :'',
                            padding: !showDelete ? 2 : "",
                            gap: !showDelete ? 8 : ""
                        }}
                        slideshow={{autoplay:true}}
                        carousel={{
                            spacing: 0,
                            padding: 0,
                            imageFit: "cover",

                        }}
                        // video={{autoPlay:true}}
                        on={{
                            view : (index) => {setCurrentImageIndex(index)}
                        }}
                        toolbar={{ buttons: [((canDelete && showDelete) && <DeleteButton key='delete'/>), (canDelete && showDelete && <UploadImages key='image'/>), <ImageCount key='count'/>]
                        }}
                    />
                    {/* <ImageGallery items={images} thumbnailPosition='left' lazyLoad={true} 
                        showNav={false} showPlayButton={false} showBullets={true} showIndex={true}
                        style={{maxHeight: '70vh !important'}}/> */}
                </Grid>
            </Grid>
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} 
                onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </div>
    )
}

export default AuctionImageGallery;