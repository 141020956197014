import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/AddOutlined';
import {
    IconButton, Paper, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Tooltip,
    Dialog, DialogTitle, Divider, DialogContent,
    DialogActions, Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import GraphQLErrors from '../../common/GraphQLErrors';
import { LIST_PHONENUMBER } from '../../GraphQL/Queries';
import PhoneNumberRow from './PhoneNumberRow';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "5px 7px !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    showMoreClass: {
        position: 'relative', 
        top: '7px', 
        float: 'right', 
        fontSize: '14px !important', 
        cursor: 'pointer'
    },

}))

const ListPhoneNumberModal = ({personId, openPopup, setClose, isView, phoneNumbers}) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [showPlusButton, setShowPlusButtom] = useState(true);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [listPhoneNumber, {loading: listingPhoneNumber, error: errorOnListingPhoneNumber, refetch}] = 
            useLazyQuery(LIST_PHONENUMBER, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if(data.listPhoneNumber != null) {
                setPhoneNumberList([]);
                data.listPhoneNumber.map(phone => {
                    setPhoneNumberList(prevState => ([
                        ...prevState,
                        {
                            id: phone.id,
                            phoneNumber: phone.phoneNumber,
                            preferred: phone.preferred,
                            lastVerifiedOn: phone.lastVerifiedOn,
                            deletedOn: phone.deletedOn,
                            isNew: false
                        }
                    ]))
                })
            }
        }
    })

    const addPhoneNumber = () => {
        setShowPlusButtom(false);
        setPhoneNumberList(
            prevState => ([
                {
                    id: null,
                    phoneNumber: '',
                    preferred: false,
                    lastVerifiedOn: null,
                    deletedOn: null,
                    isNew: true
                },
                ...prevState,
            ])
        )
    } 

    const setCancel = (isCancel) => {
        setShowPlusButtom(true);
        listPhoneNumber({
            variables: {
                personId: personId,
            }
        })
    }

    const onSuccess = (msg) => {
        if(personId != null) {
            listPhoneNumber({
                variables: {
                    personId: personId,
                }
            })
        }
        setShowPlusButtom(true);
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    // useEffect(() => {
    //     if(personId != null) {
    //         listPhoneNumber({
    //             variables: {
    //                 personId: personId,
    //             }
    //         })
    //     }
    // },[personId])

    useEffect(() => {
        if(phoneNumbers){
            phoneNumbers.map(phone => {
                setPhoneNumberList(prevState => ([
                    ...prevState,
                    {
                        id: phone.id,
                        phoneNumber: phone.phoneNumber,
                        preferred: phone.preferred,
                        lastVerifiedOn: phone.lastVerifiedOn,
                        deletedOn: phone.deletedOn,
                        isNew: false
                    }
                ]))
            })
        }
    }, [])

    const handleCloseModal = () => {
        setClose(false);
    }

    return (
        <Dialog open={openPopup} maxWidth='false'>
            <div style={{width: 430}}>
                <DialogTitle style={{padding: '10px 15px'}}>{t('Phone_Number')}</DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: '0px 15px 10px 15px !important', maxHeight: '400px !important',  overflow: 'auto'}}>
                    {!isView && <Tooltip title={t("Add")}> 
                        <IconButton aria-label="add" id="add-phone-number-modal" size="small" color="primary" style={{float: 'right'}} disabled={!showPlusButton}>
                            <AddIcon onClick={addPhoneNumber} />
                        </IconButton>
                    </Tooltip>}
                    <TableContainer component={Paper} style={{marginTop: isView ? "15px" : "0px"}}>
                        <Table aria-label="phone number table" size="small">
                            <TableHead style={{backgroundColor: '#dae2ef'}}>
                            <TableRow>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '60% !important'}}>{t('Phone_Number')}</TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{width: '10% !important'}}>{t('Prefered')}</TableCell>
                                {!isView && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center" style={{width: '30% !important'}}>{t('Actions')}</TableCell>}
                            </TableRow>
                            </TableHead>
                            <TableBody id="phone-number-list-modal">
                                {phoneNumberList.length === 0 && 
                                    <TableRow hover>
                                        <TableCell className={`${classes.tableCell}`} colSpan={3}>{t('No_Record_Found')}</TableCell>
                                    </TableRow>
                                }
                                {phoneNumberList.length > 0 && phoneNumberList.map((phone, index) => {
                                    return (
                                        <PhoneNumberRow index={index} key={phone.id + index} phone={phone} setCancel={setCancel}
                                        personId={personId} refetch={refetch} onSuccess={onSuccess} isView={isView}/>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={handleCloseModal} id="cancel" variant="outlined" 
                    style={{float: 'right'}} >{t('Close')}</Button>
                </DialogActions>
            </div>
            {listingPhoneNumber && <AuctionLoader show={listingPhoneNumber} invisible={false} />}
            {errorOnListingPhoneNumber && <GraphQLErrors error={errorOnListingPhoneNumber} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </Dialog>
    )
}

export default ListPhoneNumberModal;