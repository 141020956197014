import React from 'react';
import './AnimatedSuccessSVG.css';

const AnimatedSuccessSVG = () => {
  return (
        <div className="check-container">
            <div className="check-background">
                <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div className="check-shadow"></div>
        </div>
  )
}

export default AnimatedSuccessSVG
