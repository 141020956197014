import { IconButton,  Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuctionStatus } from '../common/StaticVariableDeclaration';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';


export const HomePageLink = () => {
    let history = useHistory();
    const { t } = useTranslation();

    const goToLandingPage = () => {
        history.push({
        pathname: "/landing-page",
        state: {
            userRole: [],
            auctionListType: AuctionStatus.scheduled,
            pagination: {
            page: 1,
            pageSize: 10
            },
            isMyBid: false
        }
        })
    }

    return (
        <Tooltip title={t('Home_Page')} arrow>
            <IconButton id={'home-page'} color="primary" sx={{ float: 'right', marginLeft: '2px' }}
                onClick={() => goToLandingPage()}>
                <HomeTwoToneIcon />
            </IconButton>
        </Tooltip>  
    )
}