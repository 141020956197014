import { makeStyles }                               from "@mui/styles";
import React, {useState, useEffect, useCallback, useContext}    from "react";
import { useTranslation }                           from 'react-i18next';
import { Dialog, DialogContent, DialogTitle,
    DialogActions, Divider, Button, Grid,
    TextField, MenuItem, FormGroup, FormControlLabel,
    Checkbox,Stack,Card,ListItemText,
    Typography}       from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FileTypes, FilePath } from '../../common/StaticVariableDeclaration';
import { Validation } from '../../common/Validation';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CREATE_REQUIRED_DOCUMENT, UPDATE_REQUIRED_DOCUMENT } from "../../GraphQL/Mutation";
import { GET_PRE_SIGNED_URL, GET_PRE_SIGNED_URL_TEMPLATE, LIST_DOCUMENT_TYPES, LIST_REQUIRED_DOCUMENTS } from "../../GraphQL/Queries";
import { useMutation, useLazyQuery } from '@apollo/client';
import { AxiosAuctionInterceptor } from "../../config/AxiosAuctionInterceptor";
import { useDropzone } from 'react-dropzone';
import { CloudUpload } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import AuctionSnackBar from '../../common/AuctionSnackBar';
import AuctionLoader  from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors  from '../../common/GraphQLErrors';
import { UserContext } from "../../common/context/UserContext";
import { collectionFileExtensionSet, executableExtensionSet, executableMimeTypeSet, restrictTypeCode } from "../../common/files-upload/FileUploadStaticVar";

export const DashedCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    border: '2px dashed #9dc3eb',
    borderRadius: '15px !important',
    [theme.breakpoints.down('md')]: {
        minWidth: '300px',
        minHeight: '125px',
        padding: '10px !important',
    },
    [theme.breakpoints.up("md")]: {
        minWidth: '300px',
        minHeight: '150px',
        padding: '10px !important',
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: '300px',
        minHeight: '125px',
        padding: '0px !important'
    },
    boxShadow: 'none',
    background: 'rgb(243 243 250) !important',
    justifyContent: 'center',
    position: 'sticky',
    top: 0
}))

const useStyles = makeStyles((theme) => ({
    cloudUploadClass: {
        color: theme.cloudIconColor,
        fontSize: '2.2rem !important'
    },
    fileIconClass: {
        marginBottom:'-2px',
        fontSize:'20px',
        color: theme.fileIconColor
    },
    fileNameClass: {
        fontSize:'15px',
        color: theme.fileIconColor,
        paddingLeft: '3px'
    },
    browserBtnClass: {
        height:'25px !important',
        borderRadius: '8px !important',
        boxShadow: '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3) !important',
        background: theme.browseFileBtnBgColor,
        textTransform: 'none !important',
        fontWeight: 'bold',
        marginBottom: '10px !important'
    }

}));

const filter = createFilterOptions();

const BidderDocumentDetail = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedFileTypes, setSelectedFileTypes] = React.useState([]);
    const [selectedFile, setFile] = useState([]);
    const [message, setMessage] = React.useState({
        show: false,
        message: null,
        severity: null
    })
    const [loading, setLoading] = useState(false);
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const [bidderDocDropDownList, setBidderDocDropDown] = useState([]);

    const { register, getValues, trigger, setValue, control, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            isMandatory: false,
            requiresDigitalSignature: false,
            submitFormat: [],
            requiredDocumentBrief: ''

        }
    });

    const [createBidderDocument, { loading: creatingRequiredDocument, error: errorOnCreatingRequiredDocument }] =
        useMutation(CREATE_REQUIRED_DOCUMENT, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.createRequiredDocumentDetails != null) {
                    props.setOpenPopup(false);
                    onSuccessModal({ show: true, message: t('Document_Added_Successfully'), severity: 'success' });
                }
                else {
                    setLoading(false);
                }
            },
            refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const [updateRequiredDocument, { loading: updatingRequiredDocument, error: errorOnUpdatingRequiredDocument }] =
        useMutation(UPDATE_REQUIRED_DOCUMENT, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateRequiredDocument) {
                    props.setOpenPopup(false);
                    onSuccessModal({ show: true, message: t('Document_Updated_Successfully'), severity: 'success' });
                }
                else {
                    setLoading(false);
                }
            },
            refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const [listDocumentTypes, { loading: documentTypes, error: errorOnDocumentTypes }] = useLazyQuery(LIST_DOCUMENT_TYPES, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.listDocumentTypes !== null) {
                setBidderDocDropDown(data.listDocumentTypes)
            }
        }
    });

    const setCancel = (isCancel) => {
        props.setCancel(isCancel);
    }

    const onSuccessModal = (successMessage) => {
        props.onSuccess(successMessage);
    }

    const handleFileTypeChange = (event) => {
        setSelectedFileTypes(event.target.value);
        setValue('submitFormat', selectedFileTypes);
    };

    const onFileChange = event => {
        setFile(undefined);
        setMessage({show: false, message: '', severity: ''})
        const file = event.target.files[0];
        setFile(file);
    };

    const handleSave = () => {
        setLoading(true);
        if(props.isNew) {
            if(selectedFile == undefined || selectedFile.length == 0) {
                save(null);
            } else {
                uploadFiles({
                    variables: {
                        auctionId: props.auctionId,
                        presignedUrl : {
                            fileName: process.env.REACT_APP_S3_FOLDER + '/' + props.auctionId + FilePath.requestDocumentFromBidderPath + '/' + getValues('name') + '/' + selectedFile[0].name,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod:'PUT',
                            versionId:''
                        },
                        documentType: getValues('name'),
                        toSubmit: true,
                        oldFilePath: null
                }})
            }
        } else {
            update();
        }

    }

    const [uploadFiles, { loading: uploadingFile, error: errorOnUploadingFile }] = useLazyQuery(GET_PRE_SIGNED_URL_TEMPLATE, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        onCompleted: (data) => {
            if (data.getPresignedUrlTemplate !== null) {
                setLoading(false);
                onFileUpload(data.getPresignedUrlTemplate);
            } else {
                setLoading(false);
            }
        }
    });

    const onFileUpload = (url) => {
        setLoading(true);
        AxiosAuctionInterceptor.put(url, selectedFile[0], {
            headers: {
                'Content-Type': selectedFile[0].type
            },
        }).then(function (response) {
            save(url)
            setLoading(false);

        }).catch(function (error) {
            setLoading(false);
            setMessage({show: true, message: error, severity: 'error'})
        });
    };

    const save = (presignedUrl) => {
        if (props.auctionId) {
            createBidderDocument({
                variables: {
                    auctionId: props.auctionId,
                    documentInput: {
                        name: getValues("name"),
                        isMandatory: getValues("isMandatory"),
                        requiresDigitalSignature: getValues("requiresDigitalSignature"),
                        toSubmit: true,
                        requiredDocumentBrief: getValues("requiredDocumentBrief"),
                        submitFormat: getValues("submitFormat"),
                        templateS3Uri: presignedUrl
                    },
                    pagination: {
                        limit: props.rowsPerPage,
                        page: 1,
                        asc: false
                    }
                }
            })
        }
    }

    const update = () => {
        if (props.auctionId) {
            updateRequiredDocument({
                variables: {
                    auctionId: props.auctionId,
                    documentInput: {
                        id: props.documentDetail.id,
                        name: getValues('name'),
                        isMandatory: getValues('isMandatory'),
                        requiresDigitalSignature: getValues('requiresDigitalSignature'),
                        submitFormat: getValues('submitFormat'),
                        templateS3Uri: props.documentDetail.templateS3Uri,
                        toSubmit: props.documentDetail.toSubmit,
                        requiredDocumentBrief: getValues('requiredDocumentBrief')
                    }
                }
            })
        }
    }

    useEffect(() => {
        if(props.isNew == false && props.documentDetail != null) {
            setValue("name", props.documentDetail.name);
            setValue("submitFormat", props.documentDetail.submitFormat);
            setValue("requiredDocumentBrief", props.documentDetail.requiredDocumentBrief);
            setValue("isMandatory", props.documentDetail.isMandatory);
            setValue("requiresDigitalSignature", props.documentDetail.requiresDigitalSignature);
            setValue("templateS3Uri", props.documentDetail.templateS3Uri);
            setValue("toSubmit", props.documentDetail.toSubmit);
            setSelectedFileTypes(props.documentDetail.submitFormat);
            trigger();
        }

    }, [])

    const onDrop = useCallback(acceptedFile => {
        setFile(acceptedFile);
    }, [])

    function restrictExecutableType(file) {
        if (executableMimeTypeSet.includes(file.type) || executableExtensionSet.includes(file.name.split('.').pop())) {
            return {
                code: restrictTypeCode.executable
            };
        }
        if (collectionFileExtensionSet.includes(file.name.split('.').pop())) {
            return {
                code: restrictTypeCode.collection
            }
        }
        if (file.name === 'mvnw') {
            return {
                code: restrictTypeCode.executable
            }
        }
    }

    const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone(
        {
            onDrop, noClick: true, multiple: false,
            validator: restrictExecutableType
        }
    )

    useEffect(() => {
        // enumListOptions.BidderDocument.map((type) => {
        //     if(props.existingTypes?.includes(type.uiCode)) {
        //     } else {
        //         setBidderDocDropDown(prevState => [
        //             ...prevState,
        //             type.uiCode
        //         ])
        //     }
        // })
        listDocumentTypes({
            variables: {
                auctionId: props.auctionId,
                toSubmit: true
            }
        });
    }, [])

    return (
        <React.Fragment>
        <Dialog open={props.openPopup} maxWidth="false">
            <div style={{width: 460}}>
            <DialogTitle>{t('Document_Details')}</DialogTitle>
            <Divider />
            <DialogContent style={{paddingTop: '15px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                        onChange={(event, newValue, reason) => {
                            if(reason == 'clear') {
                                setValue('name', '')
                                trigger('name')
                            }
                            if(newValue == null || newValue.trim() == '') {
                            } else {
                                if (typeof newValue === 'string') {
                                    setValue('name', newValue.trim())
                                    trigger('name');
                                } else if (newValue && newValue.inputValue) {
                                    setValue('name', newValue.inputValue.trim())
                                    trigger('name');
                                } else {
                                    setValue('name', newValue.trim());
                                    trigger('name');
                                }
                            }
                        }}
                        value={getValues('name')}
                        filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue);
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="document-type"
                        options={bidderDocDropDownList}
                        getOptionLabel={(option) => {
                            return option;
                        }}
                        loading={documentTypes}
                        renderOption={(props, option) => <li {...props}>{option}</li>}
                        freeSolo
                        disabled={props.isNew == false}
                        renderInput={(params) => {
                            return (
                                <TextField {...params} label={t("Document_Type")} variant='standard' required={props.isNew == true} placeholder={t("Select_or_Add_New_Type")} />

                            )
                        }}

                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            fullWidth
                            id="file-type"
                            select
                            label={t("Acceptable_File_Types")}
                            SelectProps={{
                                multiple: true,
                                value: selectedFileTypes,
                                onChange: handleFileTypeChange,
                                renderValue: (selected) => selected.join(', ')

                            }}
                            variant="standard"
                            {...register('submitFormat')}
                            >
                            {FileTypes.map((option, index) => (
                                <MenuItem key={index} value={option.fileTypes}>
                                <Checkbox checked={selectedFileTypes.includes(option.fileTypes)} />
                                <ListItemText primary={option.fileTypes} />
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={12}>
                        <Controller
                            name='requiredDocumentBrief'
                            control={control}
                            render={({ field: { name, onChange, value, ref } }) => (
                                <TextField
                                    id="document-brief"
                                    name={name}
                                    value={value}
                                    fullWidth
                                    label={t('Docuement_Brief')}
                                    multiline
                                    rows={2}
                                    onChange={(event) => {
                                        let value = event.target.value.trimStart();
                                        let finalValue = value.replace(/[\n\r]/g, '');
                                        if (finalValue.length <= 250) {
                                            onChange(finalValue);
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormGroup>
                            <FormControlLabel style={{paddingLeft: '8px'}} control={<input type='checkbox' id="is-mandatory" {...register("isMandatory")} />} label={<Typography style={{fontSize: '15px', marginLeft: '6px'}}>{t('Mandatory')}</Typography>} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormGroup>
                            <FormControlLabel style={{paddingLeft: '8px'}} control={<input type='checkbox' id="digital-sign" {...register("requiresDigitalSignature")} />} label={<Typography style={{fontSize: '15px', marginLeft: '6px'}}>{t('Digital_Sign')}</Typography>} />
                        </FormGroup>
                    </Grid>
                    {props.isNew == true && <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DashedCard
                            style={{
                                minHeight: '120px !important',
                                border: isDragActive ? '2px dashed #0a72d8' : '2px dashed #9dc3eb',
                            }}
                            elevation={0} {...getRootProps({ className: 'dropzone' })}>
                             <React.Fragment>
                                <input id="bidder-doc-drop-file" className="input-zone" {...getInputProps()} />
                                <Stack spacing={1} width={{ xs: '100%' }} justifyContent='center' alignItems='center'>
                                    <CloudUpload className={classes.cloudUploadClass} />
                                    {isDragActive ?
                                        <React.Fragment><Typography color='primary' style={{marginTop: '0px !important'}}>{t('Release_to_drop_the_files_here')}</Typography></React.Fragment> :
                                        <React.Fragment><Typography style={{marginTop: '0px'}} color='primary'>{t('Drag_and_drop_template_here')}</Typography>
                                            <Typography style={{marginTop: '0px'}} color='primary'>OR</Typography>
                                            <Button className={classes.browserBtnClass} style={{ padding: '1rem 2rem'}} variant='contained' id="bidder-browse-file" onClick={open}>{t('Browse_File')}</Button></React.Fragment>}
                                    {selectedFile.length > 0 &&
                                         <p style={{margin: 'auto', width: '80%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center'}}>
                                            <FileCopyIcon color="primary" className={classes.fileIconClass}/>
                                            <span className={classes.fileNameClass}>{decodeURI(selectedFile[0].name)}</span>
                                        </p>
                                    }
                                </Stack>
                            </React.Fragment>
                        </DashedCard>
                    </Grid>}
                </Grid>
                {(uploadingFile || creatingRequiredDocument || loading || updatingRequiredDocument) && <AuctionLoader show={uploadingFile || creatingRequiredDocument || loading || updatingRequiredDocument} invisible={false} />}
                        {(errorOnCreatingRequiredDocument || errorOnUpdatingRequiredDocument || errorOnUploadingFile || errorOnDocumentTypes) && <GraphQLErrors error={errorOnCreatingRequiredDocument || errorOnUpdatingRequiredDocument || errorOnUploadingFile || errorOnDocumentTypes} show={false} />}
                {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity}></AuctionSnackBar> }
                {fileRejections.length > 0 && <AuctionSnackBar show={fileRejections.length > 0 ? true : false}
                    message={`${fileRejections[0].file.name} - ${t('Executable_File_Is_Detected_Executable_File_Cannot_Be_Uploaded')}`} severity={'error'} />}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="primary" id="bidder-doc-close"
                    onClick={setCancel}>
                    {t('Cancel')}
                </Button>
                <Button variant="contained" color="primary" id="bidder-doc-save"
                    onClick={handleSave} disabled={getValues('name') == '' || getValues('name') == null}>
                    {t('Save')}
                </Button>
            </DialogActions>
            </div>
        </Dialog>
         </React.Fragment>
    )
}

export default BidderDocumentDetail;