import React, { useContext, useEffect, useState }           from "react";
import { useTranslation }                                   from 'react-i18next';
import { Grid, 
    TextField, 
    Button, 
    MenuItem,
    IconButton,
    Typography
}                                                           from '@mui/material';
import '../../UserProfile.css';
import { Box }                                              from "@mui/system";
import { useForm }                                          from "react-hook-form";
import { Validation }                                       from '../../../common/Validation';
import { UserContext }                                      from "../../../common/context/UserContext";
import { useMutation }                                      from '@apollo/client';
import { 
    CREATE_BANK_DETAILS, 
    CREATE_LOGINUSER_BANK_DETAILS, 
    UPDATE_BANK_DETAILS, 
    UPDATE_BANK_DETAILS_FOR_LOGINUSER 
}                                                           from '../../../GraphQL/Mutation';
import { 
    LIST_BANK_DETAIL_FOR_LOGINUSER, 
    GET_BANK_DETAILS,
    LIST_AUCTION_BANK_DETAIL,
    LIST_LOTS,
    LIST_ITEMS,
    LIST_BANK_DETAIL_BY_USER_ID
}                                                           from '../../../GraphQL/Queries';
import AuctionSnackBar                                      from '../../../common/AuctionSnackBar';
import AuctionLoader                                        from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors                                        from '../../../common/GraphQLErrors';
import { useLazyQuery }                                     from '@apollo/client';
import { makeStyles }                                       from "@mui/styles";
import BankDetailInput                                      from './BankDetailInput';
import { PublicAxiosAuctionInterceptor } from "../../../config/AxiosAuctionInterceptor";
import { useServerCurrentDateTime } from '../../../utils/Utils';

const BankDetails = (props) => {

    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [lotId, setLotId] = useState(null);
    const [itemId, setItemId] = useState(null);
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserContext).rootContext;
    const [existingBankAccounts, setExistingBankAccounts] = useState([]);
    const [selectedBankAccount, setSelectedBankAccount] = useState('');
    const serverCurrentTime = useServerCurrentDateTime();

    const { register, getValues, control, trigger, setValue, watch, resetField, 
            reset, formState: { errors, isValid, isDirty }} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            id: null,
            bankAccountNumber: '',
            accountType: 'savings_account',
            ifsc: '',
            micr: '',
            bankName: '',
            branchName: '',
            bankHolderName: '',
            userId: '',
            edgeId: '',
            otherAccountType: '',
            auctionId: ''
        }
    });

    const [listBankDetail, {loading: listingBankDetails, error: errorOnListingBankDetails}] = 
            useLazyQuery(LIST_BANK_DETAIL_BY_USER_ID, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setExistingBankAccounts(data.listBankDetailForUser.bankDetail);
        }
    })

    const [createBankDetails, {loading: creatingBankDetails, error: errorOnCreatingBankDetails}] = 
            useMutation(CREATE_BANK_DETAILS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.createBankDetailForAuction != null) {
                if(props.isModal == true) {
                    props.setCancel(true);
                    props.onSuccessModal({show: true, message: t('Added_Successfully'), severity: 'success'});
                } else {
                    onSuccess({show: true, message: t('Added_Successfully'), severity: 'success'});
                }
                if (props.onSuccess) {
                    props.onSuccess();
                }
                
            }
        },
        refetchQueries: [LIST_AUCTION_BANK_DETAIL]
    })

    const [createloginUserBankDetails, {loading: creatingLoginUserBankDetails, error: errorOnCreatingLoginUserBankDetails}] = 
            useMutation(CREATE_LOGINUSER_BANK_DETAILS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.createBankDetailForLoginUser != null) {
                if(props.isModal == true) {
                    props.setCancel(true);
                    props.onSuccessModal({show: true, message: t('Added_Successfully'), severity: 'success'});
                } else {
                    onSuccess({show: true, message: t('Added_Successfully'), severity: 'success'});
                }
                if (props.onSuccess) {
                    props.onSuccess();
                }
                
            }
        },
        refetchQueries: [LIST_BANK_DETAIL_FOR_LOGINUSER]
    })

    const [updateBankDetails, {loading: updatingBankDetails, error: errorOnUpdatingBankDetails}] = 
            useMutation(UPDATE_BANK_DETAILS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateBankDetailForAuction != null) {
                if(props.isModal == true) {
                    props.setCancel(true);
                    props.onSuccessModal({show: true, message: t('Updated_Successfully'), severity: 'success'});
                }
            }
        },
        refetchQueries: [LIST_AUCTION_BANK_DETAIL]
    })

    const [updateBankDetailsForLoginUser, {loading: updatingBankDetailsForLoginUser, error: errorOnUpdatingBankDetailsForLoginUser}] = 
            useMutation(UPDATE_BANK_DETAILS_FOR_LOGINUSER, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateBankDetailForLoginUser != null) {
                if(props.isModal == true) {
                    props.setCancel(true);
                    props.onSuccessModal({show: true, message: t('Updated_Successfully'), severity: 'success'});
                }
            }
        },
        refetchQueries: [LIST_BANK_DETAIL_FOR_LOGINUSER]
    })

    const [listLots, {loading: fetchingLots, error: errorOnFetchingLots}] = useLazyQuery(LIST_LOTS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.listAuctionLot != null && data.listAuctionLot.auctionLot.length > 0) {
                setLotId(data.listAuctionLot.auctionLot[0].id);
                listItems({
                    variables: {
                        auctionId: props.auctionId,
                        lotId: data.listAuctionLot.auctionLot[0].id,
                        pagination: {
                            page: 1,
                            limit: 100,
                            asc: false
                        },
                        isActive: true
                    }
                })
            }
        }
    })

    const [listItems, {loading: fetchingItems, error: errorOnFetchingItems, refetch}] = useLazyQuery(LIST_ITEMS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.listItem != null && data.listItem.item.length > 0) {
                setItemId(data.listItem.item[0].id);
            }
        }
    })

    const handleSave = () => {
        if(props.auctionId) {
            createBankDetails({
                variables: {
                    auctionId: props.auctionId,
                    bankDetailInput : {
                        id: getValues('id'),
                        bankAccountNumber: getValues('bankAccountNumber'),
                        accountType: getValues('accountType'),
                        ifsc: getValues('ifsc'),
                        micr: getValues('micr').trim() == '' ? null : getValues('micr'),
                        bankName: getValues('bankName'),
                        branchName: getValues('branchName'),
                        userId: props.userId,
                        bankHolderName: getValues('bankHolderName'),
                        otherAccountType: getValues('otherAccountType'),
                        lotId: lotId
                    }
                }
            })
        } else {
            createloginUserBankDetails({
                variables: {
                    bankDetailInput : {
                        id: null,
                        bankAccountNumber: getValues('bankAccountNumber'),
                        accountType: getValues('accountType'),
                        ifsc: getValues('ifsc'),
                        micr: getValues('micr').trim() == '' ? null : getValues('micr'),
                        bankName: getValues('bankName'),
                        branchName: getValues('branchName'),
                        userId: props.userId,
                        bankHolderName: getValues('bankHolderName'),
                        otherAccountType: getValues('otherAccountType')
                    }
                }
            })
        }
    }

    const handleCancel = () => {
        resetField();
        props.setCancel(true);
    }

    const onSuccess = (successMessage) => {
        // props.setCancel(true);
        setMessage({
            show: successMessage.show,
            message: successMessage.message,
            severity: successMessage.severity
        })
        if (successMessage.severity != 'error') {
            setTimeout(() => {
                resetMessage();
            }, 1500);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    const [getBankDetails, {loading: fetchingBankDetails, error: errorOnFetchingBankDetails}] =
        useLazyQuery(GET_BANK_DETAILS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if(data.getBankDetail != null) {
                    setValue('id', data.getBankDetail.id)
                    setValue('bankAccountNumber', data.getBankDetail.bankAccountNumber, { shouldValidate: true })
                    setValue('accountType', data.getBankDetail.accountType.toLowerCase(), { shouldValidate: true })
                    setValue('bankHolderName', data.getBankDetail.bankHolderName, { shouldValidate: true })
                    setValue('bankName', data.getBankDetail.bankName, { shouldValidate: true })
                    setValue('branchName', data.getBankDetail.branchName, { shouldValidate: true })
                    setValue('edgeId', data.getBankDetail.edgeId, { shouldValidate: true })
                    setValue('ifsc', data.getBankDetail.ifsc, { shouldValidate: true })
                    setValue('micr', data.getBankDetail.micr, { shouldValidate: true })
                    setValue('otherAccountType', data.getBankDetail.otherAccountType, { shouldValidate: true })
                    setValue('userId', data.getBankDetail.userId, { shouldValidate: true })
                }
            },
        })

        const handleGetBankDetailsApi = () => {
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/bank-detail/${props.bankDetailId}`).then((data) => {
                if(data!= null) {
                    setValue('id', data.id)
                    setValue('bankAccountNumber', data.bankAccountNumber, { shouldValidate: true })
                    setValue('accountType', data.accountType.toLowerCase(), { shouldValidate: true })
                    setValue('bankHolderName', data.bankHolderName, { shouldValidate: true })
                    setValue('bankName', data.bankName, { shouldValidate: true })
                    setValue('branchName', data.branchName, { shouldValidate: true })
                    setValue('edgeId', data.edgeId, { shouldValidate: true })
                    setValue('ifsc', data.ifsc, { shouldValidate: true })
                    setValue('micr', data.micr, { shouldValidate: true })
                    setValue('otherAccountType', data.otherAccountType, { shouldValidate: true })
                    setValue('userId', data.userId, { shouldValidate: true })
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({show: false, message: '', severity: ''})
                setMessage({show: true, message: error, severity: 'error'})
            });
        }

    const handleUpdate = () => {
        if(props.auctionId) {
            updateBankDetails({
                variables: {
                    auctionId: props.auctionId,
                    bankDetailInput : {
                        id: getValues('id'),
                        bankAccountNumber: getValues('bankAccountNumber'),
                        accountType: getValues('accountType'),
                        ifsc: getValues('ifsc'),
                        micr: getValues('micr') == null || getValues('micr') == '' ? null : getValues('micr'),
                        bankName: getValues('bankName'),
                        branchName: getValues('branchName'),
                        userId: getValues('userId'),
                        bankHolderName: getValues('bankHolderName'),
                        otherAccountType: getValues('otherAccountType')
                    }
                }
            })
        } else {
            updateBankDetailsForLoginUser({
                variables: {
                    bankDetailInput : {
                        id: getValues('id'),
                        bankAccountNumber: getValues('bankAccountNumber'),
                        accountType: getValues('accountType'),
                        ifsc: getValues('ifsc'),
                        micr: getValues('micr') == null || getValues('micr') == '' ? null : getValues('micr'),
                        bankName: getValues('bankName'),
                        branchName: getValues('branchName'),
                        bankHolderName: getValues('bankHolderName'),
                        otherAccountType: getValues('otherAccountType'),
                    }
                }
            })
        }
    }

    useEffect(() => {
        if(props.bankDetailId) {
            if (userContext.userSession.isAuthenticated) {
                getBankDetails({
                    variables: {
                        bankDetailId: props.bankDetailId
                    }
                })
            } else {
               handleGetBankDetailsApi() 
            }
        }
    }, [])

    useEffect(() => {
        if(userContext.userSession.isAuthenticated && userContext.selectedMenu != 'profile') {
            listLots({
                variables: {
                    auctionId: props.auctionId,
                    pagination: {
                        page: 1,
                        limit: 100,
                        asc: false
                    }
                }
            })
            listBankDetail({
                variables: {
                    userId: props.userId,
                    pagination: { 
                        page: 1,
                        limit: 100,
                        asc: false
                    },
                }
            });
        } 
    }, [])

    const setSelectedBankAccountDetails = (event) => {
        let bankAccount = event.target.value;
        setSelectedBankAccount(bankAccount);
        setValue('id', bankAccount.id, {shouldValidate: true});
        setValue('bankAccountNumber', bankAccount.bankAccountNumber, {shouldValidate: true});
        setValue('accountType', bankAccount.accountType, {shouldValidate: true});
        setValue('ifsc', bankAccount.ifsc, {shouldValidate: true});
        setValue('micr', bankAccount.micr ? bankAccount.micr : '', {shouldValidate: true});
        setValue('bankName', bankAccount.bankName, {shouldValidate: true});
        setValue('branchName', bankAccount.branchName ? bankAccount.branchName : '', {shouldValidate: true});
        setValue('bankHolderName', bankAccount.bankHolderName, {shouldValidate: true});
        setValue('otherAccountType', bankAccount.otherAccountType, {shouldValidate: true});
    }

    const clearSelectedBankAccount = () => {
        setSelectedBankAccount('');
        reset();
    }

    return (
        <React.Fragment>
            { userContext.selectedMenu != 'profile' ?
                <React.Fragment>
                    { !fetchingBankDetails &&
                        <Box mt={2} mb={2}> 
                            {(existingBankAccounts.length == 0 && props.sellerLoginUserId != null) && 
                                <React.Fragment>
                                    <Typography style={{textAlign: 'center', color: 'brown', fontWeight: 600}}>{t('No_Banks_Account_Added')}</Typography>
                                    <Typography style={{textAlign: 'center', color: 'peru', fontSize: '14px'}}>{t('Contact_To_Update_In_My_Profile')}</Typography>
                                </React.Fragment>
                            }
                            {(existingBankAccounts.length > 0 && props.showExistingBankDetails && props.isNew) && <Box sx={{pl: 3, pr: 3}}>
                            <Grid container justifyContent='center'> 
                                <Grid item xs={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        id="select-existing-bank-details"
                                        select
                                        label={t('Select_Bank_Account')}
                                        onChange={(event) => {
                                            setSelectedBankAccountDetails(event)
                                        }}
                                        value={selectedBankAccount}
                                        >
                                        {existingBankAccounts.map((existingBankAccount) => {
                                            if(new Date(existingBankAccount.endTime).getTime() > serverCurrentTime.getTime()) {
                                                return (
                                                    <MenuItem value={existingBankAccount} key={existingBankAccount.id}
                                                        disabled={ props.existingBankIds ? props.existingBankIds.includes(existingBankAccount.id) : false}>
                                                        {existingBankAccount.bankHolderName} ( {existingBankAccount.bankAccountNumber} )
                                                    </MenuItem>
                                                )
                                            }
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="text" style={{float: 'right'}}
                                            onClick={clearSelectedBankAccount}>
                                        {t('Clear')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>}
                        {((existingBankAccounts.length > 0 && props.sellerLoginUserId != null) || (props.sellerLoginUserId == null)) && <BankDetailInput register={register} errors={errors} getValues={getValues}
                                watch={watch} resetField={resetField} isView={props.isView || selectedBankAccount != ''} isLoginUser={props.sellerLoginUserId != null && (selectedBankAccount == '' && userContext.selectedMenu != 'profile')} control={control} setValue={setValue} trigger={trigger}/>}
                        </Box>}
                    <Grid>
                        {(props.isNew == true && !props.isView && ((existingBankAccounts.length > 0 && props.sellerLoginUserId != null) || (props.sellerLoginUserId == null))) && <Button onClick={handleSave} id="save" variant='contained' color='primary' style={{float: 'right', marginRight: '30px'}}
                            disabled={!isValid}>{t('Save')}</Button>}
                        {(props.isNew == false && !props.isView) && <Button onClick={handleUpdate} id="update" variant='contained' color='primary' style={{float: 'right', marginRight: '30px'}}
                            disabled={!isValid}>{t('Update')}</Button>}
                        {(props.isModal == true && !props.isView) && <Button onClick={handleCancel} id="cancel" variant="outlined" style={{float: 'right', marginRight: '10px'}} >Cancel</Button>}
                        {(props.isModal == true && props.isView) && <Button onClick={handleCancel} id="close" variant="outlined" style={{float: 'right', marginRight: '10px', marginRight: '30px'}} >Close</Button>}
                        
                    </Grid>
                </React.Fragment> : 
                <React.Fragment>
                    {!fetchingBankDetails && <Box mt={2} mb={2}> 
                        <BankDetailInput register={register} errors={errors} getValues={getValues}
                                watch={watch} resetField={resetField} isView={props.isView} isLoginUser={false} control={control} setValue={setValue} trigger={trigger}/>
                    </Box>}
                    <Grid>
                        {(props.isNew == true && !props.isView) && <Button onClick={handleSave} id="save" variant='contained' color='primary' style={{float: 'right', marginRight: '30px'}}
                            disabled={!isValid}>{t('Save')}</Button>}
                        {(props.isNew == false && !props.isView) && <Button onClick={handleUpdate} id="update" variant='contained' color='primary' style={{float: 'right', marginRight: '30px'}}
                            disabled={!isValid}>{t('Update')}</Button>}
                        {(props.isModal == true && !props.isView) && <Button onClick={handleCancel} id="cancel" variant="outlined" style={{float: 'right', marginRight: '10px'}} >Cancel</Button>}
                        {(props.isModal == true && props.isView) && <Button onClick={handleCancel} id="close" variant="outlined" style={{float: 'right', marginRight: '10px', marginRight: '30px'}} >Close</Button>}
                        
                    </Grid>
                </React.Fragment>
            }
            
            {(creatingBankDetails || fetchingBankDetails || updatingBankDetails || creatingLoginUserBankDetails || updatingBankDetailsForLoginUser || loading) && 
                <AuctionLoader show={(creatingBankDetails || fetchingBankDetails || updatingBankDetails || creatingLoginUserBankDetails || updatingBankDetailsForLoginUser || loading)} invisible={false} />}
            {(errorOnCreatingBankDetails || errorOnFetchingBankDetails || errorOnUpdatingBankDetails || errorOnCreatingLoginUserBankDetails || errorOnUpdatingBankDetailsForLoginUser) && 
                <GraphQLErrors error={(errorOnCreatingBankDetails || errorOnFetchingBankDetails || errorOnUpdatingBankDetails || errorOnCreatingLoginUserBankDetails || errorOnUpdatingBankDetailsForLoginUser)} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </React.Fragment>
    );
}

export default BankDetails;