import { useLazyQuery } from "@apollo/client";
import { Autocomplete, Button, Checkbox, Grid, ListItemText, MenuItem, TextField, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { NumericFormat } from "react-number-format";
import { AuctionFieldSet, AuctionLengend } from "../authentication/AuthStyle";
import AuctionLoader from "../common/auction-loader/AuctionLoader";
import AuctionSnackBar from "../common/AuctionSnackBar";
import { UserContext } from "../common/context/UserContext";
import GraphQLErrors from "../common/GraphQLErrors";
import { LookUpAxiosAuctionInterceptor, PublicAxiosAuctionInterceptor } from "../config/AxiosAuctionInterceptor";
import { LIST_CITIES, LIST_COUNTRIES, LIST_STATES, SEARCH_AUCTION } from "../GraphQL/Queries";
import { AuctionFilterContext } from '../common/context/AuctionFilterContext';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';

const useStyles = makeStyles((theme) => ({
    smallFont: {
        fontSize: '12px !important'
    },
    categorySelect: {
        "& #category-select": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '20vw'
        }
    },
    auctionLegendClass: {
        borderRadius: '10px !important',
        backgroundColor: 'ghostwhite !important',
        fontSize: '14px !important',
        fontWeight: "400 !important",
        color: 'mediumpurple !important',
        border: '1px solid #efefef !important'
    },
    btnClass: {
        marginTop: '10px !important',
        marginBottom: '15px !important',
        marginRight: '15px !important'
    }
}))

const AuctionFilter = ({ handleClearAll, setFilterValueObject, filterValueObject, handleFiltersSave}) => {

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const userContext = useContext(UserContext).rootContext;
    const enumListOptions = userContext.enumList;
    const classes = useStyles();
    const [countryLoading, setCountryLoading] = useState();
    const [cityLoading, setCityLoading] = useState(false);
    const [stateLoading, setStateLoading] = useState(false);
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const auctionFilterContext = useContext(AuctionFilterContext);

    // const [ListCountry, { loading: countryFetchLoading, error: countryFetchError }] = useLazyQuery(LIST_COUNTRIES, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         if (data.listCountry) {
    //             setCountries(data.listCountry)
    //         }

             //Set India as Default
            //  data.listCountry.map((value) => {
            //     if (value.name === 'India') {
            //         setAuctionFilterObject(prevState => ({
            //             ...prevState,
            //             Country: {id: value.countryId, name: value.name}
            //         }));
            //     }
            // })
    //     }
    // })

    const ListLookUpCountry = () => {
        LookUpAxiosAuctionInterceptor.get(`geo/countries`).then(function (response) {
            if (response) {
                setCountries(response)
            }
            setCountryLoading(false);
        }).catch(function (error) {
            setCountryLoading(false);
            console.log(error)
        });
    }

    // const ListCountryPublicApi = () => {
    //     PublicAxiosAuctionInterceptor.get(`${process.env.REACT_APP_AWS_LOOK_UP_ENDPOINT}geo/countries`).then(function (response) {
    //         if (response) {
    //             setCountries(response)
    //             Set India as Default
    //             if (getValues('addressId') === '') {
    //                 response.map((value) => {
    //                     if (value.name === 'India') {
    //                         setValue('country', value.name)
    //                         setCountryId(value.countryId)
    //                     }
    //                 })
    //             }
    //         }
    //         setCountryLoading(false);
    //     }).catch(function (error) {
    //         setCountryLoading(false);
    //         console.log(error)
    //     });
    // }

    // const ListCountryPublicApi = () => {
    //     setCountryLoading(true);
    //     PublicAxiosAuctionInterceptor.get(`rest/country`).then((response) => {
    //         if(response) {
    //             setCountries(response);
    //         }
    //         setCountryLoading(false);
    //     }).catch(function (error) {
    //         setCountryLoading(false);
    //         setMessage({show: false, message: '', severity: ''})
    //         setMessage({showMsg: true, message: error, severity: 'error'})
    //     });
    // }

    // const [ListStates, { loading: stateFetchLoading, error: stateFetchError }] = useLazyQuery(LIST_STATES, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         setStates(data.listState)
    //     }
    // })

    const ListLookUpStates = () => {
        LookUpAxiosAuctionInterceptor.get(`geo/country/${filterValueObject.CountryId}/states`).then(function (response) {
            if (response) {
                setStates(response);
            }
            setStateLoading(false);
        }).catch(function (error) {
            setStateLoading(false);
            // setMessage({ show: false, message: '', severity: '' });
            // setMessage({ show: true, message: error, severity: 'error' });
        });
    }

    // const ListStatesPublicApi = () => {
    //     setStateLoading(true);
    //     PublicAxiosAuctionInterceptor.get(`rest/state/${filterValueObject.CountryId}`).then((response) => {
    //         if(response) {
    //             setStates(response);
    //         }
    //         setStateLoading(false);
    //     }).catch(function (error) {
    //         setStateLoading(false);
    //         setMessage({show: false, message: '', severity: ''})
    //         setMessage({showMsg: true, message: error, severity: 'error'})
    //     });
    // }

    // const [ListCities, { loading: cityFetchLoading, error: cityFetchError }] = useLazyQuery(LIST_CITIES, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         setCities(data.listCity)
    //     }
    // })

    const ListLookUpCities = () => {
        LookUpAxiosAuctionInterceptor.get(`geo/state/${filterValueObject.StateId}/cities`).then(function (response) {
            if (response) {
                setCities(response)
            }
            setCityLoading(false);
        }).catch(function (error) {
            setCityLoading(false);
            // setMessage({ show: false, message: '', severity: '' });
            // setMessage({ show: true, message: error, severity: 'error' });
        });
    }

    // const ListCitiesPublicApi = () => {
    //     setCityLoading(true);
    //     PublicAxiosAuctionInterceptor.get(`rest/city/${filterValueObject.StateId}`).then((response) => {
    //         if(response) {
    //             setCities(response);
    //         }
    //         setCityLoading(false);
    //     }).catch(function (error) {
    //         setCityLoading(false);
    //         setMessage({show: false, message: '', severity: ''})
    //         setMessage({showMsg: true, message: error, severity: 'error'})
    //     });
    // }

    useEffect(() => {
            ListLookUpCountry();
    }, [])

    useEffect(() => {
        if (filterValueObject.CountryId !== null && filterValueObject.CountryId !== '') {
            ListLookUpStates();
        }
    }, [filterValueObject.CountryId])

    useEffect(() => {
        if(filterValueObject.StateId !== null && filterValueObject.StateId !== '') {
            ListLookUpCities();
        }
    }, [filterValueObject.StateId])

    const handleReset = () => {
        setFilterValueObject({
            CountryId: '',
            Country : '',
            StateId: '',
            State: '',
            CityId: '',
            City: '',
            // Country: {id: '', name: ''},
            // State: {id: '', name: ''},
            // City: {id: '', name: ''},
            AuctionStartDate: null,
            AuctionEndDate: null,
            startLastDateForEmd: null,
            endLastDateForEmd: null,
            EmdMinPrice: '',
            EmdMaxPrice: '',
            ReserveMinPrice: '',
            ReserveMaxPrice: '',
            Category: []
        })
    }

    const handleClear = () => {
        if(auctionFilterContext.filtersValue.isFilterApplied){
            handleClearAll();
        } else {
            showSuccessMsg({show: true, message: t('No_Filters_Applied_to_Clear'), severity: 'error'});
        }
        // let checkingEmptySelectedFilterlist = Object.keys(filterValueObject).filter((key) => filterValueObject[key] != '' && filterValueObject[key] != null && filterValueObject[key].id != '')
        // if(checkingEmptySelectedFilterlist.length > 0) {
        //     handleClearAll()
        // } else {
        //     showSuccessMsg({show: true, message: t('No_Filters_Applied_to_Clear'), severity: 'error'})
        // }
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setTimeout(() => {
                setMessage(msg);
            }, 100)
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    return (
        <Fragment>
            <Grid container spaicing={2} style={{padding: '10px', fontSize: '14px'}}>
                <Grid item sm={8} style={{paddingRight: '20px'}}>
                    <AuctionFieldSet style={{paddingBottom: '10px'}}>
                        <AuctionLengend className={classes.auctionLegendClass} align="left">{t('Filter_by_Place')}</AuctionLengend>
                        <Grid container spacing={2}>
                            <Grid item sm={4}>
                                <Autocomplete
                                    disablePortal
                                    id="country-select"
                                    options={countries}
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    value={(filterValueObject.Country != null && filterValueObject.Country != '') ? { id: filterValueObject.CountryId, name: filterValueObject.Country} : null}
                                    size="small"
                                    onChange={(event, data) => {
                                        setFilterValueObject(prevState => ({
                                            ...prevState,
                                            CountryId: data != null ? data.countryId : null,
                                            Country: data != null ? data.name : null,
                                            StateId: filterValueObject.StateId != null ? null : null,
                                            State: filterValueObject.State != null ? null : null,
                                            CityId: filterValueObject.CityId != null ? null : null,
                                            City: filterValueObject.City != null ? null : null
                                            // Country: {id: data != null ? data.countryId : '', name: data != null ?  data.name : ''}
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label={t('Country')}
                                    size="small"
                                    classes={{ input: classes.smallFont }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {(countryLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />}
                                    />
                            </Grid>
                        <Grid item sm={4}>
                            <Autocomplete
                                disablePortal
                                id="state-select"
                                options={states}
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                value={(filterValueObject.State != null && filterValueObject.State != '') ? { id: filterValueObject.StateId, name: filterValueObject.State } : null}
                                size="small"
                                onChange={(event, data) => {
                                    setFilterValueObject(prevState => ({
                                        ...prevState,
                                        StateId: data != null ? data.stateId : null,
                                        State: data != null ? data.name : null,
                                        CityId: filterValueObject.CityId != null ? null : null,
                                        City: filterValueObject.City != null ? null : null
                                        // State: {id: data != null ? data.stateId : '', name: data != null ? data.name : ''}
                                    }));
                                }}
                                renderInput={(params) => <TextField {...params} label={t('State')}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {(stateLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />}
                                />
                        </Grid>
                        <Grid item sm={4}>
                            <Autocomplete
                                disablePortal
                                id="city-select"
                                options={cities}
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                size="small"
                                value={(filterValueObject.City != null && filterValueObject.City != '') ? { id: filterValueObject.CityId, name :filterValueObject.City} : null}
                                onChange={(event, data) => {
                                    setFilterValueObject(prevState => ({
                                        ...prevState,
                                        CityId: data != null ? data.cityId : null,
                                        City: data != null ? data.name : null
                                        // City: {id: data != null ? data.cityId : '', name: data != null ? data.name : ''}
                                    }));
                                }}
                                renderInput={(params) => <TextField {...params} label={t('City_Town_District')}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {(cityLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />}
                            />
                        </Grid>
                       </Grid>
                    </AuctionFieldSet>
                </Grid>
                <Grid item sm={4}>
                    <AuctionFieldSet style={{paddingBottom: '10px'}}>
                        <AuctionLengend className={classes.auctionLegendClass} align="left">{t('Filter_by_Category')}</AuctionLengend>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <TextField
                                    fullWidth
                                    id="category-select"
                                    select
                                    label={t('Category')}
                                    size="small"
                                    className={classes.categorySelect}
                                    value={filterValueObject.Category}
                                    SelectProps={{
                                        multiple: true,
                                        value: filterValueObject.Category,
                                        onChange: (e) => {
                                            setFilterValueObject(prevState => ({
                                                ...prevState,
                                                Category: e.target.value
                                            }))
                                        },
                                        renderValue: (selected) => {
                                            let displayItemTypes = [];
                                            for (let i = 0; i < selected.length; i++) {
                                                let item = enumListOptions.ItemType.find((iType) => iType.dateBaseCode === selected[i]);
                                                if (item) {
                                                    displayItemTypes.push(item.uiCode)
                                                }
                                            }
                                            return displayItemTypes.join(', ');
                                        }
                                    }}
                                    >
                                    {enumListOptions.ItemType.map((option, index) => (
                                        <MenuItem key={index} value={option.dateBaseCode}>
                                        <Checkbox checked={filterValueObject.Category?.includes(option.dateBaseCode)} />
                                        <ListItemText primary={option.uiCode} />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </AuctionFieldSet>
                </Grid>
                <Grid item sm={6} style={{paddingTop: '15px'}}>
                    <AuctionFieldSet>
                        <AuctionLengend className={classes.auctionLegendClass} align="left">{t('Filter_by_Date_Range')}</AuctionLengend>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Grid container spacing={1}>
                                    <Grid item sm={4} style={{position: 'relative', top: '17px'}}>
                                        <span>{t('Last_Date_To_Pay_EMD')}</span>
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '20px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                OpenPickerButtonProps={{id: 'start-last-date-for-emd-picker'}}
                                                label={t('From')}
                                                value={filterValueObject.startLastDateForEmd != null ? new Date(filterValueObject.startLastDateForEmd) : null}
                                                inputFormat="dd/MM/yyyy"
                                                id="start-last-date-for-emd"
                                                onChange={(stateDate) => {
                                                    setFilterValueObject(prevState => ({
                                                        ...prevState,
                                                        startLastDateForEmd: stateDate,
                                                        endLastDateForEmd : null
                                                    }));
                                                }}
                                                // onChange={(value) => handleDateChange(value, 'startLastDateForEmd')}
                                                renderInput={(params) => <TextField fullWidth  size='small'
                                                    {...params} inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    placeholder: t('Select_Date')
                                                  }}/>
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '3px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                OpenPickerButtonProps={{id: 'end-last-date-for-emd-picker'}}
                                                label={t('To')}
                                                fullWidth
                                                value={filterValueObject.endLastDateForEmd}
                                                id="end-last-date-for-emd"
                                                inputFormat="dd/MM/yyyy"
                                                minDate={filterValueObject.startLastDateForEmd != null ? new Date(filterValueObject.startLastDateForEmd) : null}
                                                onChange={(endDate) => {
                                                    setFilterValueObject(prevState => ({
                                                        ...prevState,
                                                        endLastDateForEmd: endDate
                                                    }));
                                                }}
                                                // onChange={(value) => handleDateChange(value, 'endLastDateForEmd')}
                                                renderInput={(params) => <TextField fullWidth  size='small' {...params} inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    placeholder: t('Select_Date')
                                                  }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container spacing={1}>
                                    <Grid item sm={4} style={{position: 'relative', top: '17px'}}>
                                        <span>{t('Auction_Date')}</span>
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '20px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                OpenPickerButtonProps={{id: 'auction-start-date-picker'}}
                                                label={t('From')}
                                                value={filterValueObject.AuctionStartDate}
                                                placeholder='-select date-'
                                                id="auction-start-date"
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(startDate) => {
                                                    setFilterValueObject(prevState => ({
                                                        ...prevState,
                                                        AuctionStartDate: startDate,
                                                        AuctionEndDate: null
                                                    }));
                                                }}
                                                // onChange={(value) => handleDateChange(value, 'AuctionStartDate')}
                                                renderInput={(params) => <TextField fullWidth  size='small' {...params} inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    placeholder: t('Select_Date')
                                                  }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '3px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                OpenPickerButtonProps={{id: 'auction-end-date-picker'}}
                                                label={t('To')}
                                                fullWidth
                                                value={filterValueObject.AuctionEndDate}
                                                id="auction-end-date"
                                                inputFormat="dd/MM/yyyy"
                                                minDate={filterValueObject.AuctionStartDate != null ? new Date(filterValueObject.AuctionStartDate) : null}
                                                onChange={(endDate) => {
                                                    setFilterValueObject(prevState => ({
                                                        ...prevState,
                                                        AuctionEndDate: endDate
                                                    }));
                                                }}
                                                // onChange={(value) => handleDateChange(value, 'AuctionEndDate')}
                                                renderInput={(params) => <TextField fullWidth  size='small' {...params} inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    placeholder: t('Select_Date')
                                                  }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AuctionFieldSet>
                </Grid>
                <Grid item sm={6} style={{paddingTop: '15px'}}>
                    <AuctionFieldSet>
                        <AuctionLengend className={classes.auctionLegendClass} align="left">{t('Filter_by_Price_Range')}</AuctionLengend>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Grid container spacing={1}>
                                    <Grid item sm={4} style={{position: 'relative', top: '17px'}}>
                                        <span>{t('Reserve_Price')}</span>
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '20px'}}>
                                        <NumericFormat id="min-reserve-price" label={t("Min")}
                                            size="small"
                                            customInput={TextField}
                                            variant="outlined"
                                            thousandSeparator={true}
                                            value={filterValueObject.ReserveMinPrice}
                                            decimalScale={2}
                                            onValueChange={({ value }) => {
                                                setFilterValueObject(prevState => ({
                                                    ...prevState,
                                                    ReserveMinPrice: (Number(value))
                                                }));
                                            }}
                                            thousandsGroupStyle="lakh"
                                            allowNegative={false}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '3px'}}>
                                        <NumericFormat id="max-reserve-price" label={t("Max")}
                                            size="small"
                                            customInput={TextField}
                                            variant="outlined"
                                            thousandSeparator={true}
                                            value={filterValueObject.ReserveMaxPrice}
                                            decimalScale={2}
                                            onValueChange={({ value }) => {
                                                setFilterValueObject(prevState => ({
                                                    ...prevState,
                                                    ReserveMaxPrice: (Number(value))
                                                }));
                                            }}
                                            thousandsGroupStyle="lakh"
                                            allowNegative={false}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container spacing={1}>
                                    <Grid item sm={4} style={{position: 'relative', top: '17px'}}>
                                        <span>{t('EMD_Amount')}</span>
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '20px'}}>
                                        <NumericFormat id="emd-start-price" label={t("Min")}
                                            size="small"
                                            customInput={TextField}
                                            variant="outlined"
                                            thousandSeparator={true}
                                            value={filterValueObject.EmdMinPrice}
                                            decimalScale={2}
                                            onValueChange={({ value }) => {
                                                setFilterValueObject(prevState => ({
                                                    ...prevState,
                                                    EmdMinPrice: (Number(value))
                                                }));
                                            }}
                                            thousandsGroupStyle="lakh"
                                            allowNegative={false}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={4} style={{position: 'relative', right: '3px'}}>
                                        <NumericFormat id="emd-end-price" label={t("Max")}
                                            size="small"
                                            customInput={TextField}
                                            variant="outlined"
                                            thousandSeparator={true}
                                            value={filterValueObject.EmdMaxPrice}
                                            decimalScale={2}
                                            onValueChange={({ value }) => {
                                                setFilterValueObject(prevState => ({
                                                    ...prevState,
                                                    EmdMaxPrice: (Number(value))
                                                }));
                                            }}
                                            thousandsGroupStyle="lakh"
                                            allowNegative={false}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AuctionFieldSet>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Button id="apply-filter" size="small" className={classes.btnClass} style={{float: 'right'}} variant="contained" color="primary"
                    onClick={handleFiltersSave}>
                    {t('Apply_Filter')}
                </Button>
                <Button id="reset-btn" size="small" className={classes.btnClass} style={{float: 'right'}}  variant="outlined" color="primary"
                    onClick={handleReset}>
                    {t('Reset_Field')}
                </Button>
                <Button id="remove-filter" size="small" style={{marginTop: '10px', marginBottom: '15px', marginLeft: '15px'}}  variant="outlined" color="primary"
                    onClick={handleClear}>
                    {t('Remove_Filter')}
                </Button>
            </Grid>
            {/* {(<GraphQLErrors error={(cityFetchError)} show={false} />}  */}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </Fragment>
    )
}

export default AuctionFilter;