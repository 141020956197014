import { useLazyQuery } from '@apollo/client';
import { Phone } from '@mui/icons-material';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuctionFieldSet } from "../../authentication/AuthStyle";
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import { UserContext } from "../../common/context/UserContext";
import GraphQLErrors from '../../common/GraphQLErrors';
import PartySearch from '../../common/search/PartySearch';
import {
    AddressType,
    CompanyIdentification, IdentificationTypes, IndividualIdentificationType,
    PartyType, SearchCriteria, PartyCodeCd
} from '../../common/StaticVariableDeclaration';
import { Validation } from '../../common/Validation';
import { GET_PARTY_DETAILS, SEARCH_PARTY } from '../../GraphQL/Queries';
import AddressDetail from '../../profile/address/AddressDetail';
import CustomizedTourComponent from '../../common/customized-tour-component/CustomizedTourComponent';
import { useServerCurrentDateTime } from '../../utils/Utils';

const sellerTourConfig = [
    {
        header: 'Seller Type',
        body: 'Select proper type of seller here.',
        target: '#party-type',
        disableBeacon: true
    },
    {
        header: 'Existing Seller Details',
        body: 'Search here for required seller details if the party already exist in the system.',
        target: '#search-party-details',
        disableBeacon: false
    }
]

const CreatePartyDetails = ({ register, control, watch, errors, setValue, getValues, isValid, trigger,
    onSelectedPartyType, resetField, reset, unregister, clearErrors, tourGuide, setTourGuide, tourButtonVisibility }) => {
    const { t } = useTranslation();
    const serverCurrentTime = useServerCurrentDateTime();
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const [selectedType, setSelectedType] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [searchCriteria] = useState([
        SearchCriteria.email,
        SearchCriteria.name,
        SearchCriteria.phoneNumber
    ])

    const individualIdentificationTypes = [
        IdentificationTypes.pan,
        IdentificationTypes.other
    ]

    const comapnyIdentificationTypes = [
        IdentificationTypes.cin,
        IdentificationTypes.pan,
        IdentificationTypes.other
    ]

    let [identificationTypes, setIdentificationTypes] = useState(comapnyIdentificationTypes)
    const watchIdentificationType = useWatch({ control, name: "identificationType" });
    const [renderIdentificationField, setRenderIdentificationField] = useState(true);
    const myRef = React.useRef(null);
    const [tour, setTour] = useState({
        run: false,
        steps: []
    });

    const addTourSteps = (items) => {
        setTour({
            run: false,
            steps: []
        });
        setTimeout(() => {
            setTour({
                run: true,
                steps: items
            })
        }, 0);
    }

    const getPhoneNumber = (phoneNumber) => {
        let phone = null;
        phone = phoneNumber.length > 0 && phoneNumber.find((element => element.preferred === true))
        if (phone !== null || phone !== undefined) {
            return phone.phoneNumber;
        }
    }

    const getEmail = (email) => {
        let emailId = null;
        emailId = email.length > 0 && email.find((element => element.preferred === true))
        if (emailId !== null || email !== undefined) {
            return emailId.email;
        }
    }

    const getIdentificationType = (userDetails) => {
        let identification = null;
        if (userDetails.individual === true && userDetails.identification.length > 0) {
            identification = userDetails.identification?.find((element => element.identificationType === IdentificationTypes.pan));
            if (identification !== null || identification !== undefined) {
                setValue('identification', identification.identification);
                return identification.identificationType;
            } else {
                setValue('identification', userDetails.identification[0].identification);
                setValue('otherIdentificationType', userDetails.identification[0].otherIdentificationType);
                trigger();
                return userDetails.identification[0].identificationType;
            }
        } else if (userDetails.individual === false && userDetails.identification.length > 0) {
            identification = userDetails.identification?.find((element => element.identificationType === IdentificationTypes.cin || element.identificationType === IdentificationTypes.pan));
            if (identification !== null && identification !== undefined) {
                setValue('identification', identification.identification);
                trigger();
                return identification.identificationType;
            } else {
                setValue('identification', userDetails.identification[0].identification);
                setValue('otherIdentificationType', userDetails.identification[0].otherIdentificationType);
                trigger();
                return userDetails.identification[0].identificationType;
            }
        } else if (userDetails.individual === true || userDetails.individual === false && userDetails.identification.length === 0) {
            setValue('identification', '');
            setValue('otherIdentificationType', '');
            trigger();
            return userDetails.individual === true ? IdentificationTypes.pan : IdentificationTypes.cin;
        }
    }

    const [getSelectedPartyDetails, { loading: fetchingPartyDetails, error: errorOnFetchingPartyDetails }] =
        useLazyQuery(GET_PARTY_DETAILS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (data.getUserDetails != null) {
                    setValue('personId', data.getUserDetails.personId);
                    setValue('name', data.getUserDetails.name);
                    setValue('individual', data.getUserDetails.individual);
                    setValue('phoneNumber', getPhoneNumber(data.getUserDetails.phoneNumber));
                    setValue('emailId', getEmail(data.getUserDetails.email));
                    setValue('identificationType', getIdentificationType(data.getUserDetails));
                    setValue('identification', getValues('identification'));
                    setValue('otherIdentificationType', getValues('otherIdentificationType'))
                    setValue('userId', data.getUserDetails.userId);
                    setValue('dobDoi', data.getUserDetails.dobDoi);
                    trigger();
                    if (data.getUserDetails.address.length > 0) {
                        let address = data.getUserDetails.address.find((address) => address.addressType === AddressType.primaryContact)
                        if (address) {
                            // setIsEdit(false);
                            setValue('addressId', address.addressId !== null ? address.addressId : null);
                            setValue('addressLine1', address.addressLine1 !== null ? address.addressLine1 : null);
                            setValue('addressLine2', address.addressLine2 !== null ? address.addressLine2 : null);
                            setValue('landmark', address.landmark !== null ? address.landmark : null);
                            setValue('cityTown', address.cityTown !== null ? address.cityTown : null);
                            setValue('postalCode', address.postalCode !== null ? address.postalCode : null);
                            setValue('postalCodeId', address.postalCodeId !== null ? address.postalCodeId : null);
                            setValue('state', address.state !== null ? address.state : null);
                            setValue('province', address.province !== null ? address.province : null);
                            setValue('country', address.country !== null ? address.country : null);
                            setValue('addressType', address.addressType !== null ? address.addressType : null);
                            setValue('phone', address.phone !== null ? address.phone : null);
                            setValue('attention', address.attention !== null ? address.attention : null);
                        } else {
                            // setIsEdit(false);
                            setValue('addressId', data.getUserDetails.address[0].addressId !== null ? data.getUserDetails.address[0].addressId : null);
                            setValue('addressLine1', data.getUserDetails.address[0].addressLine1 !== null ? data.getUserDetails.address[0].addressLine1 : null);
                            setValue('addressLine2', data.getUserDetails.address[0].addressLine2 !== null ? data.getUserDetails.address[0].addressLine2 : null);
                            setValue('landmark', data.getUserDetails.address[0].landmark !== null ? data.getUserDetails.address[0].landmark : null);
                            setValue('cityTown', data.getUserDetails.address[0].cityTown !== null ? data.getUserDetails.address[0].cityTown : null);
                            setValue('postalCode', data.getUserDetails.address[0].postalCode !== null ? data.getUserDetails.address[0].postalCode : null);
                            setValue('state', data.getUserDetails.address[0].state !== null ? data.getUserDetails.address[0].state : null);
                            setValue('province', data.getUserDetails.address[0].province !== null ? data.getUserDetails.address[0].province : null);
                            setValue('country', data.getUserDetails.address[0].country !== null ? data.getUserDetails.address[0].country : null);
                            setValue('addressType', data.getUserDetails.address[0].addressType !== null ? data.getUserDetails.address[0].addressType : null);
                            setValue('phone', data.getUserDetails.address[0].phone !== null ? data.getUserDetails.address[0].phone : null);
                            setValue('attention', data.getUserDetails.address[0].attention !== null ? data.getUserDetails.address[0].attention : null);
                        }
                    } else {
                        setValue('addressId', '');
                        setValue('attention', '');
                        setValue('phone', '');
                        setValue('addressLine1', '');
                        setValue('addressLine2', '');
                        setValue('landmark', '');
                        setValue('cityTown', '');
                        setValue('postalCode', '');
                        setValue('state', '');
                        setValue('province', '');
                        setValue('addressType', AddressType.primaryContact);
                    }
                }
            }
        })

    const setSelectedUser = (selectedUser) => {
        getSelectedPartyDetails({
            variables: {
                userId: selectedUser.id
            }
        })
    }

    const handleChange = (event) => {
        clearAll();
        setSelectedType(event.target.value);
        onSelectedPartyType(event.target.value);
        if (event.target.value === true) {
            identificationTypes = [];
            setIdentificationTypes(individualIdentificationTypes);
            setValue('identificationType', IdentificationTypes.pan);
        } else {
            identificationTypes = [];
            setIdentificationTypes(comapnyIdentificationTypes);
            setValue('identificationType', IdentificationTypes.cin);
        }
    }

    const handleIdentificationTypeChange = (event) => {
        if (event === IdentificationTypes.cin || event === IdentificationTypes.pan) {
            resetField('otherIdentificationType');
            resetField('identification');
        } else if (event === IdentificationTypes.other) {
            resetField('identification');
        }
    }

    useEffect(() => {
        if (getValues('identificationType') === IdentificationTypes.other && getValues('personId') === null) {
            setRenderIdentificationField(false);
            unregister('identification');
            setTimeout(() => setRenderIdentificationField(true), 0);
        } else if (getValues('personId') != null) {
            let temp = getValues('identification');
            setRenderIdentificationField(false);
            unregister('identification');
            setTimeout(() => setRenderIdentificationField(true), 0);
            setValue('identification', temp);
        }
        trigger('identification');
    }, [watch('identificationType')])

    useEffect(() => {
        if(getValues('identification') != null && (getValues('identification').length == 0 || getValues('identification').length == 1 || getValues('identification').trim() == '')) {
            trigger('identification');
        }
        if (getValues('identificationType') === IdentificationTypes.other) {
            trigger('otherIdentificationType');
        }
    }, [watch('identification')])

    const clearAll = () => {
        reset();
        // resetField('name', '');
        // resetField('identification', '');
        // resetField('otherIdentificationType', '');
        // resetField('emailId', '');
        // resetField('phoneNumber', '');
        // resetField('phone', '');
        // resetField('personId', '');
        // resetField('addressLine1', '');
        // resetField('addressLine2', '');
        // resetField('landmark', '');
        // resetField('attention', '');
        // resetField('dobDoi', '');
        // resetField('addressType', AddressType.primaryContact);
        // resetField('userId', '');
        // resetField('cityTown', '');
        // resetField('cityTownId', '');
        // resetField('postalCode', '');
        // resetField('postalCodeId', '');
        // resetField('stateId', '');
        // resetField('province', '');
        // resetField('countryId', '');
        // resetField('country', '');
        // resetField('addressType', '');
        // resetField('phone', '');
        // resetField('addressId', '');
    }

    const handleDateChange = (newValue) => {
        if (newValue != 'Invalid Date' && newValue != null) {
            setValue('dobDoi', newValue);
        }
    }

    // const [getSearchedParty, { loading: fetchingSearchedPartyDetails, error: errorOnFetchingSearchedPartyDetails }] = useLazyQuery(SEARCH_PARTY, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         data.searchUser.map((user) => {
    //             setSelectedUser(user);
    //         })
    //     }
    // });

    // const handleIdentificationValue = (event) => {
    //     if(event.target.value !== null && event.target.value.trim() !== '') {
    //         getSearchedParty({
    //             variables: {
    //                 searchInput : {
    //                     searchBy: 'identification',
    //                     personType: getValues('personType'),
    //                     searchText: event.target.value.trim(),
    //                     cognitoUsers: false,
    //                     identificationType: getValues('identificationType')
    //                 }
    //             }
    //         })
    //     }
    // }

    // const handlePhoneNumberValue = (event) => {
    //     if(event.target.value !== null && event.target.value.trim() != '' && event.target.value !== '' && event.target.value !== '+91') {
    //         getSearchedParty({
    //             variables: {
    //                 searchInput : {
    //                     searchBy: SearchCriteria.phoneNumber.searchTypeValue,
    //                     personType: getValues('personType'),
    //                     searchText: event.target.value.trim(),
    //                     cognitoUsers: false,
    //                     identificationType: SearchCriteria.phoneNumber.searchTypeValue
    //                 }
    //             }
    //         })
    //     }
    // }

    // const handleEmailValue = (event) => {
    //     if(event.target.value !== null && event.target.value.trim() != '') {
    //         getSearchedParty({
    //             variables: {
    //                 searchInput : {
    //                     searchBy: SearchCriteria.email.searchTypeValue,
    //                     personType: getValues('personType'),
    //                     searchText: event.target.value.trim(),
    //                     cognitoUsers: false,
    //                     identificationType: SearchCriteria.email.searchTypeValue
    //                 }
    //             }
    //         })
    //     }
    // }

    const changeCase = (event) => {
        setValue('identification', '');
        if (event.target.value != null && event.target.value != '') {
            if (getValues('identificationType') === IdentificationTypes.pan ||
                getValues('identificationType') === IdentificationTypes.cin) {
                setValue('identification', event.target.value.toUpperCase());
                trigger('identification');
            } else {
                setValue('identification', event.target.value.trim());
            }
        }
    }

    useEffect(() => {
        myRef.current.scrollIntoView();
        console.log('createpartyDetails...', tourGuide, tourButtonVisibility);
        if(tourGuide.triggerEvent && tourButtonVisibility) {
            addTourSteps(sellerTourConfig);
        }
    }, [tourGuide.triggerEvent])

    useEffect(() => {
        setTourGuide( (prevState) => ({
            ...prevState,
            triggerButton: tourButtonVisibility
        }));
    }, [tourButtonVisibility])

    return (
        <React.Fragment>
            <Grid container spacing={2} ref={myRef}>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField
                        fullWidth
                        size="small"
                        id="party-type"
                        select
                        label="Seller Type"
                        onChange={handleChange}
                        value={selectedType}
                        sx={{
                            "& .MuiInputBase-root": {
                                height: 45
                            }
                        }}
                    >
                        {PartyCodeCd.map((option, index) => (
                            <MenuItem key={index} value={option.dateBaseCode}>
                                {option.uiCode}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={5} >
                    <PartySearch key={selectedType} style={{ position: "relative !important", bottom: "3px !important" }} individual={selectedType}
                        identificationTypes={selectedType === true ? [IndividualIdentificationType.pan] : [CompanyIdentification.cin, CompanyIdentification.pan]}
                        defaultValue={selectedType === true ? SearchCriteria.email : SearchCriteria.name}
                        setDropDownValue={searchCriteria} onSelect={setSelectedUser} cognitoUsers={false} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={5}>
                    <Typography id="clear-all" color="primary" style={{ float: 'right', position: 'relative', top: '20px', right: '10px', cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={clearAll}>{t('Clear')}</Typography>
                </Grid>
            </Grid>
            <br />
            <AuctionFieldSet>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={6} lg={watchIdentificationType === IdentificationTypes.other ? 4 : 6}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ ...Validation.name, ...Validation.required }}
                            render={({ field: { name, onChange, value, ref } }) => (
                                <TextField id="name" label={t('Name')} fullWidth
                                    name={name}
                                    inputRef={ref}
                                    value={value}
                                    onChange={onChange}
                                    type="text" variant='standard'
                                    disabled={!isEdit || getValues('personId') != ''}
                                    helperText={errors["name"] && errors["name"].message}
                                    InputLabelProps={{ shrink: value ? true : false }}
                                    required={getValues('personId') == ''}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} ms={6} lg={watchIdentificationType === IdentificationTypes.other ? 2 : 3}>
                        <Controller
                            name="identificationType"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    inputRef={ref}
                                    label={t('Identification_Type')}
                                    fullWidth
                                    variant='standard'
                                    id="identification-type"
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChange={(event) => {
                                        handleIdentificationTypeChange(event.target.value)
                                        onChange(event.target.value);
                                    }}
                                    value={value}
                                    disabled={!isEdit || getValues('personId')}
                                    InputLabelProps={{ shrink: value ? true : false }}
                                >
                                    {identificationTypes.map((type) => {
                                        let identification = null;
                                        identification = enumListOptions.IdentificationType.find(option => option.dateBaseCode === type);
                                        if (identification) {
                                            return (<option key={identification.id} value={identification.dateBaseCode}>
                                                {identification.uiCode}
                                            </option>)
                                        }
                                    })}
                                </TextField>
                            )}
                        />
                    </Grid>
                    {watchIdentificationType === IdentificationTypes.other && <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Controller
                            name="otherIdentificationType"
                            control={control}
                            rules={{ validate: value => (getValues('identification') == null || getValues('identification').trim() == '')
                                || (getValues('identification') != null && getValues('identification').trim() != ''
                                    && ((value == null || value.trim() == '') ? 'Field is required' : true))
                            }}
                            render={({ field: { name, onChange, value, ref } }) => (
                                <TextField id="other-identification" label={t('Other_Identification_Type')} fullWidth
                                    required={(getValues('identification') != null && getValues('identification').trim() != '') ? true : false}
                                    name={name}
                                    inputRef={ref}
                                    value={value}
                                    onChange={onChange}
                                    type="text" variant='standard' defaultValue=''
                                    disabled={!isEdit || getValues('personId')}
                                    InputLabelProps={{ shrink: value ? true : false }}
                                    helperText={errors["otherIdentificationType"] && errors["otherIdentificationType"].message}
                                />
                            )}
                        />

                    </Grid>}
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        {renderIdentificationField && <Controller
                            name="identification"
                            control={control}
                            rules={(getValues('identification') == null || getValues('identification').trim() == '') ? { validate: value => true} :
                                ((getValues('identificationType') === IdentificationTypes.pan && Validation.pan)
                                || (getValues('identificationType') === IdentificationTypes.cin && Validation.cin))
                            }
                            render={({ field: { name, onChange, value, ref } }) => (
                                <TextField id="identification" label={watchIdentificationType != null && watchIdentificationType.toUpperCase()} fullWidth
                                    name={name}
                                    inputRef={ref}
                                    value={value}
                                    // onChange={onChange}
                                    onChange={changeCase}
                                    type="text" variant='standard' defaultValue={getValues('identification')}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors["identification"] && errors["identification"].message}
                                    InputLabelProps={{ shrink: value ? true : false }}
                                // onBlur={handleIdentificationValue}
                                />
                            )}
                        />}
                    </Grid>
                    <Grid item xs={12} sm={12} ms={12} lg={4}>
                        <Controller
                            name="dobDoi"
                            control={control}
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        label={selectedType === true ? t('Date_of_Birth') : t('Date_of_Incorporation')}
                                        inputFormat="dd/MM/yyyy"
                                        value={field.value}
                                        onChange={handleDateChange}
                                        maxDate={serverCurrentTime}
                                        OpenPickerButtonProps={{ id: `dobDoi-picker` }}
                                        disabled={!isEdit || getValues('personId')}
                                        renderInput={(params) => <TextField variant="standard" id="dobBoi" fullWidth {...params}
                                            inputProps={{ ...params.inputProps, readOnly: true }} />}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{ ...Validation.mobileNumberValidation, ...Validation.required }}
                            render={({ field: { name, onChange, value, ref } }) => (
                                <MuiPhoneNumber
                                    id="phone-number"
                                    name={name}
                                    label={t('Phone_Number')}
                                    defaultCountry={"in"}
                                    value={value}
                                    onChange={onChange}
                                    autoFormat={false}
                                    fullWidth
                                    variant="standard"
                                    countryCodeEditable={false}
                                    required={getValues('personId') == ''}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors["phoneNumber"] && errors["phoneNumber"].message}
                                    inputProps={{
                                        ref: ref
                                    }}
                                // onBlur={handlePhoneNumberValue}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Controller
                            name="emailId"
                            control={control}
                            rules={{ ...Validation.email }}
                            render={({ field: { name, onChange, value, ref } }) => (
                                <TextField id="email" label={t('Email')} variant="standard" fullWidth
                                    name={name}
                                    inputRef={ref}
                                    value={value}
                                    onChange={onChange}
                                    required={getValues('personId') == ''}
                                    disabled={!isEdit || getValues('personId')}
                                    helperText={errors["emailId"] && errors["emailId"].message}
                                    InputLabelProps={{ shrink: value ? true : false }}
                                // onBlur={handleEmailValue}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </AuctionFieldSet>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} ms={12} ls={12} style={{ marginTop: '10px' }}>
                    <AddressDetail register={register} control={control} setValue={setValue} getValues={getValues} errors={errors}
                        isEdit={isEdit && (getValues('addressId') === null || getValues('addressId') === '' || getValues('addressId') === undefined)}
                        isValid={isValid} createTitle={true} clearErrors={clearErrors}/>
                </Grid>
            </Grid>
            {(fetchingPartyDetails) && <AuctionLoader show={(fetchingPartyDetails)}
                invisible={false} />}
            {(errorOnFetchingPartyDetails) && <GraphQLErrors error={(errorOnFetchingPartyDetails)} show={false} />}
            <CustomizedTourComponent tour={tour} setTour={setTour} />
        </React.Fragment>
    );
}

export default CreatePartyDetails;

