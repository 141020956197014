import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const themeDarkMoon = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "#db3131 !important" },
        root: {
          color: "#9e9e9e !important",
          "&$focused": {
            color: "#9e9e9e !important"
          }
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      color: '#fff'
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: { color: "#fff" }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input:disabled': {
            '-webkit-text-fill-color': '#ffffffcf'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            '-webkit-text-fill-color': '#ffffffcf'
          }
        },
        root: {
          borderBottom: "1px solid #fff !important"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "color": "#b2aeae"
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: { color: "#b2aeae" },
        positionEnd: { color: "#b2aeae" },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: { color: "#b2aeae" }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: { borderBottom: ' 1px solid rgb(88 84 84) !important' }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: { background: 'inherit !important', padding: '10px 20px' }
      }
    }
    // MuiTabPanel: {
    //   styleOverrides: {
    //     root: { color: "#9e9e9e !important" }
    //   }
    // }
  },
  palette: {
    type: 'dark',
    // mode: dark,
    primary: {
      main: '#2a83ad'
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
    },
    background: {
      default: '#000000',
      paper: '#262626',
    },
    text: {
      primary: '#fff !important',
      secondary: grey[500],
    },
    action: {
      disabled: "#b2aeae"
    },
    divider: "#5e5e5e",
    link: {
      main: '#778899',
    }
  },
  topBarBg: {
    background: '#000000 !important',
    color: '#fff !important',
    // boxShadow: '0px 1px 4px 0 rgb(0 0 0 / 30%), 0 0 0 0px rgb(0 0 0 / 10%) !important',
    boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
    borderBottom: '1px solid #d8d8d84d'
  },
  sideNav: {
    navBg: '#262626 !important',
    navIconBg: '#000000',
    navSelectedTextColor: 'palegoldenrod',
    navSelectedArea: 'lightsteelblue'
  },
  table: {
    bg: '#262626 !important',
    headerBg: '#f5f5f5 !important',
    headerHover: '#fafafa',
    selectBg: 'rgba(25, 118, 210, 0.08) !important',
    toolbarBg: '#f5f5f5',
    trAltColor: '#fafafa',
    dataViewColor: 'darkgoldenrod',
    tagColor: 'lightsteelblue',
    toolbarTitleColor: 'darkgoldenrod'
  },
  boxShadow: 'rgb(255 255 255 / 10%) 0px 1px 1px 0px inset, rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px !important',
  selectedBg: '#262626 !important',
  search: {
    bg: 'rgba(92, 100, 125, 0.5) !important',
  },
  tableHearderBg: 'steelblue',
  outlinedTextFieldBorderColor: '#c4c4c4 !important',
  bgColors: {
    alert: 'mistyrose',
    success: '#f8faf2'
  },
  contentColors: {
    alert: 'orange',
    success: '#6e8332'
  },
  step: {
    contentBg: '#fafafa',
    contentBorderColor: '#f5f5f7',
    stepHeaderBg: 'lightblue'
  },
  flashButton: {
    background: 'darkorange',
    color: 'black'
  },
  tableHearderBackgroundColor: '#dae2ef',
  fileSizeColor: 'brown',
  fileTypeDisplayColor: 'darkmagenta',
  tableDataColor: 'rgb(62, 80, 96) !important',
  cloudIconColor: 'rgb(191 217 241) !important',
  fileIconColor: 'mediumvioletred',
  browseFileBtnBgColor: '#0a68c5 !important',
  tabPanelBgColor: 'white',
  rolesMailColor: 'gray',
  rolesGreenColor: 'green',
  rolesColor: '#EBECF0',
  roleDetailsColor: {
    color: 'white',
    containerColor: '#4682B4',
    fabEmail: 'orange',
    fabPhone: 'green',
    date: 'gray'
  },
  rolesColorTheme: {
    color: 'bule',
    borderColor: '#c8d6eb'
  },
  backColor: '#ddead1',
  rolesHeaderBachground: '#dae2ef',
  rolesHeaderColor: 'blue',
  tableHearderForRoleDetails: 'lightgray',
  tableDataBorderColor: 'rgb(231, 235, 240) !important',
  tableDataColor: 'rgb(62, 80, 96) !important',
  accordinBackgroundColor: '#dae2ef !important',
  bankAccountInfoColor: '#c37715',
  eVotingSwitchColorTheme: {
    backgroundColor: 'rgb(49 131 173)',
    borderColor: 'rgb(49 131 173)',
    color: 'white',
  }

});

export const themeFullMoon = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "#db3131 !important" },
        root: {
          color: "dimgrey !important"
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: { borderBottom: '1px solid rgb(231, 235, 240) !important' }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: { background: 'inherit !important', padding: '10px 20px' }
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
    },
    background: {
      default: '#f7f9fb',
      paper: '#fff',
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
    },
    link: {
      main: '#778899',
    },
  },
  topBarBg: {
    background: '#fcfcff !important',
    color: '#000000 !important',
    // boxShadow: '0px 1px 4px 0 rgb(0 0 0 / 30%), 0 0 0 0px rgb(0 0 0 / 10%) !important'
    boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
  },
  sideNav: {
    navBg: '#1976d2',
    navIconBg: '#fff',
    navSelectedTextColor: 'palegoldenrod',
    navSelectedArea: 'lightsteelblue'
  },
  table: {
    bg: '#fff !important',
    headerBg: '#f5f5f5 !important',
    headerHover: '#fafafa',
    selectBg: 'rgba(25, 118, 210, 0.08) !important',
    toolbarBg: 'ghostwhite',
    trAltColor: '#fafafa',
    dataViewColor: 'darkgoldenrod',
    tagColor: 'lightsteelblue',
    toolbarTitleColor: 'darkgoldenrod'
  },
  boxShadow: '0px 10px 20px 0 rgb(0 0 0 / 20%), 0 0 0 0px rgb(0 0 0 / 10%) !important',
  selectedBg: '#F0F8FF !important',
  search: {
    bg: 'rgb(225 235 250)',
  },
  tableHearderBg: 'lightblue',
  outlinedTextFieldBorderColor: '#c4c4c4 !important ',
  bgColors: {
    alert: 'mistyrose',
    success: '#f8faf2'
  },
  contentColors: {
    alert: 'orange',
    success: '#6e8332'
  },
  step: {
    contentBg: '#fafafa',
    contentBorderColor: '#f5f5f7',
    stepHeaderBg: 'lightblue'
  },
  flashButton: {
    background: 'darkorange',
    color: 'black'
  },
  tableHearderBackgroundColor: '#dae2ef',
  fileSizeColor: 'brown',
  fileTypeDisplayColor: 'darkmagenta',
  tableDataColor: 'rgb(62, 80, 96) !important',
  cloudIconColor: 'rgb(191 217 241) !important',
  fileIconColor: 'mediumvioletred',
  browseFileBtnBgColor: '#0a68c5 !important',
  tabPanelBgColor: 'white',
  rolesMailColor: 'gray',
  rolesGreenColor: 'green',
  rolesColor: '#EBECF0',
  roleDetailsColor: {
    color: 'white',
    containerColor: '#4682B4',
    fabEmail: 'orange',
    fabPhone: 'green',
    date: 'gray'
  },
  rolesColorTheme: {
    color: 'bule',
    borderColor: '#c8d6eb'
  },
  rolesColorThemeLight: {
    color: 'white'
  },
  backColor: '#ddead1',
  rolesHeaderBachground: '#dae2ef',
  rolesHeaderColor: 'black',
  tableHearderForRoleDetails: 'lightgray',
  tableDataBorderColor: 'rgb(231, 235, 240) !important',
  tableDataColor: 'rgb(62, 80, 96) !important',
  accordinBackgroundColor: '#dae2ef !important',
  bankAccountInfoColor: '#c37715',
  eVotingSwitchColorTheme: {
    backgroundColor: 'rgb(49 131 173)',
    borderColor: 'rgb(49 131 173)',
    color: 'white',
  }

});

export const themeHalfMoon = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "#db3131 !important" },
        root: {
          color: "#9e9e9e !important",
          "&$focused": {
            color: "#9e9e9e !important"
          }
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: { color: "#fff" }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: { color: "#b2aeae" }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input:disabled': {
            WebkitTextFillColor: '#ffffffcf'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: '#ffffffcf'
          }
        },
        root: {
          borderBottom: "1px solid #fff !important"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "color": "#b2aeae"
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: { color: "#b2aeae" },
        positionEnd: { color: "#b2aeae" },
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: { borderBottom: ' 1px solid rgb(88 84 84) !important' }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: { background: 'inherit !important', padding: '10px 20px' }
      }
    }
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
    },
    background: {
      default: '#212121',
      paper: '#424242',
    },
    text: {
      primary: '#fff !important',
      secondary: grey[500],
    },
    action: {
      disabled: "#b2aeae"
    },
    divider: "#5e5e5e",
    link: {
      main: '#778899',
    }
  },
  topBarBg: {
    background: '#212121 !important',
    color: '#fff !important',
    // boxShadow: '0px 1px 4px 0 rgb(0 0 0 / 30%), 0 0 0 0px rgb(0 0 0 / 10%) !important',
    boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
    borderBottom: '1px solid #d8d8d84d'
  },
  sideNav: {
    navBg: '#424242 !important',
    navIconBg: '#212121',
    navSelectedTextColor: 'palegoldenrod',
    navSelectedArea: 'lightsteelblue'
  },
  table: {
    bg: '#424242 !important',
    headerBg: '#f5f5f5 !important',
    headerHover: '#fafafa',
    selectBg: 'rgba(25, 118, 210, 0.08) !important',
    toolbarBg: '#f5f5f5',
    trAltColor: '#fafafa',
    dataViewColor: 'darkgoldenrod',
    tagColor: 'lightsteelblue',
    toolbarTitleColor: 'darkgoldenrod'
  },
  boxShadow: 'rgb(255 255 255 / 10%) 0px 1px 1px 0px inset, rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px !important',
  selectedBg: '#424242 !important',
  search: {
    bg: 'rgba(92, 100, 125, 0.5) !important',
  },
  tableHearderBg: 'steelblue',
  outlinedTextFieldBorderColor: '#c4c4c4 !important',
  bgColors: {
    alert: 'mistyrose',
    success: '#f8faf2',
  },
  contentColors: {
    alert: 'orange',
    success: '#6e8332'
  },
  step: {
    contentBg: '#fafafa',
    contentBorderColor: '#f5f5f7',
    stepHeaderBg: 'lightblue'
  },
  flashButton: {
    background: 'darkorange',
    color: 'black'
  },
  tableHearderBackgroundColor: '#dae2ef',
  fileSizeColor: 'brown',
  fileTypeDisplayColor: 'darkmagenta',
  tableDataColor: 'rgb(62, 80, 96) !important',
  cloudIconColor: 'rgb(191 217 241) !important',
  fileIconColor: 'mediumvioletred',
  browseFileBtnBgColor: '#0a68c5 !important',
  tabPanelBgColor: 'white',
  rolesMailColor: 'gray',
  rolesGreenColor: 'green',
  rolesColor: '#EBECF0',
  roleDetailsColor: {
    color: 'white',
    containerColor: '#4682B4',
    fabEmail: 'orange',
    fabPhone: 'green',
    date: 'gray'
  },

  rolesColorTheme: {
    color: 'bule',
    borderColor: '#c8d6eb',
  },
  backColor: '#ddead1',
  rolesHeaderBachground: '#dae2ef',
  rolesHeaderColor: 'black',
  tableHearderForRoleDetails: 'lightgray',
  tableDataBorderColor: 'rgb(231, 235, 240) !important',
  tableDataColor: 'rgb(62, 80, 96) !important',
  accordinBackgroundColor: '#dae2ef !important',
  bankAccountInfoColor: '#c37715',
  eVotingSwitchColorTheme: {
    backgroundColor: 'rgb(49 131 173)',
    borderColor: 'rgb(49 131 173)',
    color: 'white',
  }

});
