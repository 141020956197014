import React, {
    useState,
    useEffect,
    Fragment,
    useContext
}                                           from 'react';
import { useLazyQuery }                     from '@apollo/client';
import {
    Grid,
    Button,
    Typography
}                                           from '@mui/material';
import InsertPhotoIcon                      from '@mui/icons-material/InsertPhoto';
import { useTranslation }                   from 'react-i18next';
import { LIST_ITEM_LOT_FILES }              from '../../GraphQL/Queries';
import AuctionImageGallery                  from '../../common/image-gallery/AuctionImageGallery';
import UploadDialog                         from '../../common/files-upload/UploadDialog';
import ImageIcon                            from '@mui/icons-material/Image';
import { makeStyles }                       from '@mui/styles';
import {
    imageAcceptableMimeType,
    videoAcceptableMimeType
}                                           from '../../common/files-upload/FileUploadStaticVar';
import AuctionLoader                        from '../../common/auction-loader/AuctionLoader';
import { UserContext }                      from '../../common/context/UserContext';
import { PublicAxiosAuctionInterceptor }    from '../../config/AxiosAuctionInterceptor';
import AuctionSnackBar                      from '../../common/AuctionSnackBar';

const useStyles = makeStyles((theme) => ({
    noImageIcons: {
        width: '25% !important',
        height: '25% !important',
        color: 'gray'
    },
    noImageText: {
        color: 'gray',
        fontSize: '2rem',
        textAlign: 'center'
    }
}))

const ItemImages = ({auction_id, item_id, lot_id, canUpload}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [uploadImage, setUploadImage] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [showUpload, setShowUpload] = useState(canUpload);
    const userContext = useContext(UserContext).rootContext;
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [listItemDocuments, { loading, error, refetch }] = useLazyQuery(LIST_ITEM_LOT_FILES, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setLoadingImages(true);
            setImages([]);
            if (data.listLotOrItemFiles != null && data.listLotOrItemFiles.files.length > 0) {
                data.listLotOrItemFiles.files.map(image => {
                    if (image.contentType.includes('video') || image.contentType.includes('image')) {
                        setImages(prevState => ([
                            ...prevState,
                            {
                                original: image.presignedUrl,
                                // thumbnail: image.presignedUrl,
                                contentType: image.contentType,
                                key: image.s3ObjectFile.key
                            }
                        ]));
                    }
                })
            }
            setLoadingImages(false);
        }
    })

    const getPublicItemImages = () => {
        setLoadingImages(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/${auction_id}/${lot_id}/100/true/true?itemId=${item_id}&startingToken=''`).then((response) => {
            setImages([]);
            if (response.files != null && response.files.length > 0) {
                response.files.map((image, index) => {
                    if (image.contentType.includes('video') || image.contentType.includes('image')) {
                        setImages(prevState => ([
                            ...prevState,
                            {
                                id: index + 1,
                                original: image.presignedUrl,
                                // thumbnail: image.presignedUrl,
                                contentType: image.contentType,
                                key: image.s3ObjectFile.key
                            }
                        ]));
                    }
                })
            }
            setLoadingImages(false);
        }).catch(function (error) {
            setLoadingImages(false);
            setMessage({show: false, message: '', severity: ''})
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        });
    }

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            listItemDocuments({
                variables: {
                    auctionId: auction_id,
                    lotId: lot_id,
                    itemId: item_id,
                    isPresignedUrl: true,
                    limit: 100,
                    startingToken: '',
                    isPicture: true
                }
            })
        } else {
            getPublicItemImages();
        }
    }, [])

    const resetUploadImage = (event) => {
        setUploadImage(event);
        refetch();
    }

    return (
        <div>
            {!loading && <Fragment>
                <Grid container spacing={3}>
                    {/* {(showUpload && images.length > 0) && <Grid item xs={12}>
                        <Button id="upload-item-image" variant="contained" size="small" startIcon={<InsertPhotoIcon />} style={{float: 'right'}}
                                onClick={() => {setUploadImage(true)}}>
                            {t('Upload_Images_Videos')}
                        </Button>
                    </Grid>} */}
                    <Grid item xs={12}>
                        {(!loadingImages && !loading && images.length === 0) &&
                            <Grid container direction="row" justifyContent="center" alignItems="center"
                                style={{display: 'grid', height: '50vh'}}>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <ImageIcon className={classes.noImageIcons}/>
                                    <Typography className={classes.noImageText}>
                                        <div id="no-item-image">{t('Images_Videos_Is_Not_Uploaded')}</div>
                                        <div>
                                        <br/>
                                        {showUpload && <Button id="upload-item-image" variant="contained" size="small" startIcon={<InsertPhotoIcon />} onClick={() => {setUploadImage(true)}}>
                                            {t('Upload_Images_Videos')}
                                        </Button>}
                                        </div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {(!loadingImages && !loading && images.length > 0) && <AuctionImageGallery inputImages={images} canDelete={showUpload} setUploadImage={setUploadImage}/>}
                    </Grid>
                </Grid>
                {uploadImage && <UploadDialog
                    dialogOpen={uploadImage}
                    setDialogOpen={resetUploadImage}
                    isMultiple={true}
                    fileName={`${auction_id}/${lot_id}/${item_id}/pictures and videos`}
                    title={t('Upload_Item_Images_Videos')}
                    acceptableFiles={{...videoAcceptableMimeType, ...imageAcceptableMimeType}}
                />}
            </Fragment>}
            {(loading || loadingImages) && <AuctionLoader show={true} invisible={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </div>
    )
}

export default ItemImages;