import { Box, Dialog, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StatusTable from './/StatusTable';

const useStyles = makeStyles((theme) => ({
  filtermsg: {
    color: theme.palette.success.main,
    marginLeft: '1.5rem',
    borderRadius: '0.725rem',
    width: 'fit-content',
    float: 'right',
  },
  tableHead: {
    fontWeight: "bold !important", fontSize: "14px !important", padding: '10px 15px !important',
  },
  tableCell: {
    padding: '12px 15px !important',
  },
  icons: {
        fontSize: '1.25rem !important',
 }
}));

const CommunicationsEmail = (props) => {
  const { rowDetails ,auctionId} = props
  const { t } = useTranslation()
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showStatusHistory, setShowStatusHistory] = useState({ show: false })


  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <TableRow>
        {/* <TableCell id="table-cell"> */}
        {/* <IconButton
            id='icon-button'
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        {/* </TableCell> */}
        <TableCell align="left" className={classes.tableCell}>{rowDetails.sender}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{rowDetails.receiver.map(mail => {
          return <div>{mail}</div>
        })}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{rowDetails.informationDetail.subject}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{format(new Date(rowDetails.rowCreatedOn), 'dd/MM/yyyy hh:mm a')}</TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <Tooltip title={t('View_Status_History')}>
            <IconButton aria-label="action" onClick={() => setShowStatusHistory({ show: true, messageId: rowDetails.messageId })}>
                <VisibilityIcon align="left" className={classes.icons} id='visible' color='primary' />
            </IconButton>
          </Tooltip>
          {rowDetails.statusLocale}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Cc')}</TableCell>
                    <TableCell>{t('Bcc')}</TableCell>
                    <TableCell>Body</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{rowDetails.cC}</TableCell>
                    <TableCell>{rowDetails.bCC}</TableCell>
                    <TableCell>{rowDetails.informationDetail.body}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
      {showStatusHistory.show && <StatusTable show={showStatusHistory.show} setShowStatusHistory={setShowStatusHistory} auctionId={auctionId} messageId={showStatusHistory.messageId}  />}
    </React.Fragment>
  )
}
export default CommunicationsEmail
