import { useMutation } from '@apollo/client'
import { alpha, Box, Button, Divider, Fab, Slide, Stack, Tooltip } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog'
import GraphQLErrors from '../../common/GraphQLErrors'
import { CREATE_ADDRESS, CREATE_LOGIN_USER_ADDRESS, DELETE_ADDRESS, UPDATE_ADDRESS, UPDATE_LOGIN_USER_ADDRESS } from '../../GraphQL/Mutation'
import AddressDetail from './AddressDetail'
import AuctionLoader from '../../common/auction-loader/AuctionLoader'
import { RequiredFields } from './address-common/RequiredFieldsAddress'
import { Check, Clear, Delete, DeleteOutlineRounded, Edit } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { AuctionFieldSet } from '../../authentication/AuthStyle'

const useStyles = makeStyles((theme) => ({
  topFab: {
    width: '34px !important',
    height: '34px !important',
    background: `${theme.palette.background.paper} !important`,
    float: 'right',
    zIndex: 'unset !important'
  },
  checkFab: {
    // margin: '-20px 0px !important',
    border: `1px solid ${theme.palette.success.main} !important`,
    '&[disabled]': {
      border: `1px solid ${theme.palette.text.secondary} !important`,
      pointerEvents: 'none',
      boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important'
    }
  },
  closeFab: {
    // margin: '-20px 42px !important',
    border: `1px solid ${theme.palette.error.main} !important`
  },
  editFab: {
    // margin: '-20px 0px !important',
  },
  deleteFab: {
    // margin: '-20px 42px !important',
  }
}))

function AddressTab(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isEdit, setIsEdit, requiredShowFields, sourceId, isLoginUser, hasPermission, deletePermission, listLoading,showCancel } = props;
  const { register: addressRegister, trigger, watch, control: addressControl, setValue: addressSetValue, getValues: addressGetValues, formState: { errors: addressErrors, isValid: addressIsValid }, reset, clearErrors: addressClearErrors } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {
      addressId: props.data.addressId,
      attention: props.data.attention,
      phone: props.data.phone,
      email: props.data.email,
      addressLine1: props.data.addressLine1,
      addressLine2: props.data.addressLine2,
      cityTown: props.data.cityTown,
      country: props.data.country,
      postalCode: props.data.postalCode,
      state: props.data.state,
      addressType: props.data.addressType,
      landmark: props.data.landmark
    }
  });

  const [seekConfirmation, setSeekConfitmation] = useState({
    show: false,
    title: '',
    message: '',
    onAgree: '',
    isCancel: false,
    agreeBtnLabel: t('Yes'),
    disAgreeBtnLabel: t('No')
  })

  const [createAddress, { loading: createLoading, error: createError }] = useMutation(CREATE_ADDRESS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.addAddress) {
        setIsEdit(false)
        props.refreshlistAddress()
      }
    }
  })

  const [createAddressForLoginUser, { loading: createLoginUserLoading, error: createLoginUserError }] = useMutation(CREATE_LOGIN_USER_ADDRESS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.addAddressForLoginUser) {
        setIsEdit(false)
        props.refreshlistAddress()
      }
    }
  })

  const [updateAddress, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ADDRESS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.updateAddress) {
        setIsEdit(false)
        props.refreshlistAddress()

      }
    }
  })

  const [updateAddressForLoginUser, { loading: updateLoginUserLoading, error: updateLoginUserError }] = useMutation(UPDATE_LOGIN_USER_ADDRESS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.updateAddressForLoginUser) {
        setIsEdit(false)
        props.refreshlistAddress()
      }
    }
  })

  const [deleteAddress, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ADDRESS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data != null && data.deleteAddress) {
        setIsEdit(false)
        props.refreshlistAddress(0)
      }
    }
  })

  const deleteAdd = () => {
    setSeekConfitmation(prevState => ({
      ...prevState,
      show: true,
      icon: <DeleteOutlineRounded color='primary' style={{ fontSize: '2.5rem' }} />,
      title: t('Delete'),
      message: <Fragment>{t('Are_you_sure_you_want_to_delete_address_of_type')}&nbsp;<b>{addressGetValues('addressType')}</b>&nbsp;?</Fragment>,
      onAgree: deleteAddressData,
      onDisAgree: resetConfirmation,
      agreeBtnLabel: t('Yes'),
      disAgreeBtnLabel: t('No')
    }))
  }

  const deleteAddressData = () => {
    resetConfirmation();
    deleteAddress({
      variables: {
        addressId: addressGetValues('addressId')
      }
    })
  }

  const resetConfirmation = () => {
    setSeekConfitmation(prevState => ({
      ...prevState,
      show: false,
      title: '',
      message: '',
      onAgree: '',
      isCancel: false,
      agreeBtnLabel: t('Yes'),
      disAgreeBtnLabel: t('No')
    }))
  }

  const saveAddress = async () => {
    trigger();
    if (addressIsValid) {
      if (isLoginUser) {
        saveLoginUserAddress();
      } else {
        saveSourceAddress();
      }
    }
  }

  const saveSourceAddress = async () => {
    if (addressGetValues('addressId') === '') {
      createAddress({
        variables: {
          address: {
            addressLine1: addressGetValues('addressLine1')?.trim(),
            attention: addressGetValues('attention')?.trim(),
            phone: addressGetValues('phone')?.trim(),
            email: addressGetValues('email')?.trim(),
            addressLine2: addressGetValues('addressLine2')?.trim(),
            cityTown: addressGetValues('cityTown')?.trim(),
            country: addressGetValues('country')?.trim(),
            postalCode: addressGetValues('postalCode')?.trim(),
            state: addressGetValues('state')?.trim(),
            addressType: addressGetValues('addressType')?.trim(),
            landmark: addressGetValues('landmark')?.trim(),
          },
          sourceId: sourceId
        }
      })
    } else {
      updateAddress({
        variables: {
          address: {
            addressId: addressGetValues('addressId')?.trim(),
            addressLine1: addressGetValues('addressLine1')?.trim(),
            attention: addressGetValues('attention')?.trim(),
            phone: addressGetValues('phone')?.trim(),
            email: addressGetValues('email')?.trim(),
            addressLine2: addressGetValues('addressLine2')?.trim(),
            cityTown: addressGetValues('cityTown')?.trim(),
            country: addressGetValues('country')?.trim(),
            postalCode: addressGetValues('postalCode')?.trim(),
            state: addressGetValues('state')?.trim(),
            addressType: addressGetValues('addressType')?.trim(),
            landmark: addressGetValues('landmark')?.trim(),
          }, sourceId: sourceId
        }
      })
    }
  }

  const saveLoginUserAddress = async () => {
    if (addressGetValues('addressId') === '') {
      createAddressForLoginUser({
        variables: {
          address: {
            addressLine1: addressGetValues('addressLine1')?.trim(),
            attention: addressGetValues('attention')?.trim(),
            phone: addressGetValues('phone')?.trim(),
            email: addressGetValues('email')?.trim(),
            addressLine2: addressGetValues('addressLine2')?.trim(),
            cityTown: addressGetValues('cityTown')?.trim(),
            country: addressGetValues('country')?.trim(),
            postalCode: addressGetValues('postalCode')?.trim(),
            state: addressGetValues('state')?.trim(),
            addressType: addressGetValues('addressType')?.trim(),
            landmark: addressGetValues('landmark')?.trim(),
          }
        }
      })
    } else {
      updateAddressForLoginUser({
        variables: {
          address: {
            addressId: addressGetValues('addressId')?.trim(),
            addressLine1: addressGetValues('addressLine1')?.trim(),
            attention: addressGetValues('attention')?.trim(),
            phone: addressGetValues('phone')?.trim(),
            email: addressGetValues('email')?.trim(),
            addressLine2: addressGetValues('addressLine2')?.trim(),
            cityTown: addressGetValues('cityTown')?.trim(),
            country: addressGetValues('country')?.trim(),
            postalCode: addressGetValues('postalCode')?.trim(),
            state: addressGetValues('state')?.trim(),
            addressType: addressGetValues('addressType')?.trim(),
            landmark: addressGetValues('landmark')?.trim(),
          }
        }
      })
    }
  }

  const enableEdit = () => {
    setIsEdit(!isEdit);
  }

  const cancelAddress = (onlyForLotAddress) => {
    if(onlyForLotAddress && showCancel){
      props.setOpenAddress(false)
    }
    setIsEdit(false);
    reset({
      sourceId: sourceId,
      attention: props.data.attention,
      phone: props.data.phone,
      email: props.data.email,
      addressLine1: props.data.addressLine1,
      addressLine2: props.data.addressLine2,
      cityTown: props.data.cityTown,
      country: props.data.country,
      postalCode: props.data.postalCode,
      state: props.data.state,
      addressType: props.data.addressType,
      landmark: props.data.landmark,
      addressId: props.data.addressId
    })
    props.closeModels(!isEdit);
  }

  return (
    <Slide direction='left' timeout={450} in={true}>
      <div style={{ marginTop: '10px'}}>
        <AuctionFieldSet style={{ paddingTop: '5px !important',padding:hasPermission ? '0.7rem 1rem 1.2rem 1rem' : '1rem 1rem 3.2rem 1rem' }}>
          <Stack>
            {(hasPermission && !deleteLoading && !listLoading && !isEdit && addressGetValues('addressId') != '') &&
              <Stack direction='row' justifyContent='end' spacing={2}>
                <Tooltip title={t('Edit')}>
                  <Fab id='address-edit' className={`${classes.topFab} ${classes.editFab}`} style={{ float: 'right' }} color='primary' onClick={() => enableEdit()}>
                    <Edit color='primary' />
                  </Fab>
                </Tooltip>
                {deletePermission && <Tooltip title={t('Delete')}>
                  <Fab id='address-delete' className={`${classes.topFab} ${classes.deleteFab}`} style={{ float: 'right' }} color='primary' onClick={() => deleteAdd()}>
                    <Delete color='error' />
                  </Fab>
                </Tooltip>}
              </Stack>
            }
            {(isEdit && !(updateLoginUserLoading || updateLoading) && addressGetValues('addressId') != '') &&
              <Stack direction='row' justifyContent='end' spacing={2}>
                <Tooltip title={t('Save')} disableHoverListener={Object.keys(addressErrors).length !== 0}>
                  <Fab id='address-save' className={`${classes.topFab} ${classes.checkFab}`} style={{ float: 'right' }} color='success'
                    onClick={() => saveAddress()} disabled={Object.keys(addressErrors).length !== 0}>
                    <Check color={Object.keys(addressErrors).length !== 0 ? 'disabled' : 'success'} />
                  </Fab>
                </Tooltip>
                <Tooltip title={t('Cancel')}>
                  <Fab id='address-cancel' className={`${classes.topFab} ${classes.closeFab}`} style={{ float: 'right' }} color='error' onClick={() => cancelAddress(false)}>
                    <Clear color='error' />
                  </Fab>
                </Tooltip>
              </Stack>
            }
            <AddressDetail register={addressRegister} watch={watch} requiredShowFields={requiredShowFields} control={addressControl}
              setValue={addressSetValue} getValues={addressGetValues} errors={addressErrors} isEdit={!(createLoading || updateLoading || deleteLoading || createLoginUserLoading || updateLoginUserLoading) && isEdit}
              isValid={addressIsValid} onEditComponent={true} clearErrors={addressClearErrors}/>
          </Stack>
          {(isEdit && !(createLoginUserLoading || createLoading) && addressGetValues('addressId') === '') &&
            <Fragment>
              <Button id="save" variant='contained' color='primary' onClick={saveAddress} style={{ margin: '10px 6px 4px 6px', float: 'right' }}>{t('Save')} </Button>
              <Button id="cancel" variant='contained' color="inherit" onClick={() => cancelAddress(true)} style={{ margin: '10px 6px 4px 6px', color: "#000000", float: 'right' }}>{t('Cancel')}</Button>
            </Fragment>
          }
        </AuctionFieldSet>
        <Box style={{ float: 'right', display: 'none' }}>
          {isEdit &&
            <Fragment>
              <Button id="cancel" variant='contained' color="inherit" onClick={() => cancelAddress(true)} style={{ margin: '4px 6px', color: "#000000" }}>{t('Cancel')}</Button>
              <Button id="save" variant='contained' color='primary' onClick={saveAddress} style={{ margin: '4px 6px' }}>{addressGetValues('addressId') == '' ? t('Save') : t('Update')}</Button>
            </Fragment>
          }
          {(hasPermission && addressGetValues('addressId') != '' && !isEdit) &&
            <Fragment>
              <Button id="delete" variant="contained" onClick={deleteAdd} color="secondary" size="small" style={{ margin: '12px 6px' }}>
                {t('Delete')}
              </Button>
              <Button id="edit" variant="contained" onClick={enableEdit} color="primary" size="small" style={{ margin: '12px 6px' }}>
                {t('Edit')}
              </Button>
            </Fragment>}
        </Box>
        {(createLoading || updateLoading || deleteLoading || createLoginUserLoading || updateLoginUserLoading) &&
          <AuctionLoader show={createLoading || updateLoading || deleteLoading || createLoginUserLoading || updateLoginUserLoading} invisible={false} />}
        {(createError || createLoginUserError) && <GraphQLErrors error={(createError || createLoginUserError)} show={false} />}
        {(updateError || updateLoginUserError) && <GraphQLErrors error={(updateError || updateLoginUserError)} show={false} />}
        {deleteError && <GraphQLErrors error={deleteError} show={false} />}
        {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show} icon={seekConfirmation.icon}
          title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
          onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree} />}
      </div>
    </Slide>
  )
}

export default AddressTab