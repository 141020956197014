import { CloseRounded, MilitaryTechRounded, NextPlanRounded, PriorityHighRounded, WarningRounded } from '@mui/icons-material';
import { Autocomplete, Box, Button, Divider, FormLabel, Paper, Slide, Stack, TextField, Tooltip, Typography, alpha, createFilterOptions } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPatchCheck } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { ToWords } from 'to-words';
import BidHistory from '../../auction-bid-history/BidHistory';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import { AxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import BidConfirmation from '../BidConfirmation';
import { FiftyTrillion, PositiveIntOnly } from './BidProcessStaticVar';
import { BidderPriceView, ButtonStyled } from './bid-process-components/BidProcessComponents';
import BidProcessDialog from './bid-process-components/BidProcessDialog';
import { CurrencyFormatter } from '../../utils/Utils';
import { CurrencyFormat, AuctionStatus } from '../../common/StaticVariableDeclaration';

const filter = createFilterOptions();

const BID_OPEN_AMOUNT_CHECK_STRING = "Bid Open Amoun";

export const AuctionInfoCard = styled('div')(({ theme }) => ({
    border: theme.palette.type === 'light' ? '1px solid #eeeeee' : '1px solid rgb(60,60,60)',
    padding: '0.6rem',
    background: theme.palette.background.paper,
    boxShadow: '0 22px 37px 6px rgb(0 0 0 / 4%)'

}))

export const AuctionInfoCards = styled('div')(({ theme }) => ({
    border: theme.palette.type === 'light' ? '1px solid #eeeeee' : '1px solid rgb(60,60,60)',
    padding: '0.6rem',
    background: theme.palette.background.paper,
    boxShadow: '0 22px 37px 6px rgb(0 0 0 / 4%)',
    width: '-4px'

}))

export const BidSuccess = styled('div')(({ theme }) => ({
    background: theme.palette.success.main,
    color: '#fff',
    padding: '0.8rem',
    marginLeft: '1rem',
    borderRadius: '0.725rem'
}))

const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        borderColor: '#38d !important',
        boxShadow: '0 0 10px rgb(51 136 221 / 50%)'
    },
    price: {
        fontWeight: '600 !important'
    },
    idChip: {
        background: '#eeeeee', padding: '0.1rem 0.4rem',
        borderRadius: '4px',
        width: 'fit-content'
    },
    h1BidderIcon: {
        color: '#5b8f64',
    },
    NotH1BidderIcon: {
        color: '#d34e31',
    },
    rank: {
        fontSize: '2.1rem !important'
    },
    lastBid: {
        fontSize: '1.9rem !important'
    },
    nextbid: {
        fontSize: '1.6rem !important'
    },
    currentHighestBid: {
        color: 'darkorange !important', fontWeight: '600 !important', display: 'flex', alignItems: 'center'
    },
    warningMsg: {
        background: '#f9f9e0', color: '#956505', borderRadius: '5px', border: '1px solid #f5deb0'
    },
    h1BidderBg: {
        background: '#f3fff0 !important',
        border: theme.palette.type === 'light' ? '1px solid #eeeeee !important' : '1px solid #d1e6eb !important',
        borderLeft: '4px solid #b2d0a4 !important',
    },
    notH1BidderBg: {
        background: '#fff4f0 !important',
        border: theme.palette.type === 'light' ? '1px solid #eeeeee !important' : '1px solid #d1e6eb !important',
        borderLeft: '4px solid #d34e31 !important',
    },
    paper: {
        borderRadius: '0px !important',
        margin: '0px 1px',
        boxShadow: 'none !important'
    }
}))

const EnglishForwardBid = (props) => {
    const { auctionId, lotId, auctionLotInfo, bidHeading, isClosed, actualIncrementalPrice, bidTransactionId, setBidTransactionId,
        yourLastBid, yourRank, currentHighestBid, previousHighBid, getLatestsBids, bidHistory } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const auctionDetailContext = useContext(AuctionDetailContext);
    const [createBidLoading, setCreateBidLoading] = useState(false);
    const [bidHisDialogOpen, setBidHisDialogOpen] = useState(false);
    const [triggerBidHistory, setTriggerBidHistory] = useState(new Date());
    const containerRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    // const [toWordLoading, setToWordLoading] = useState(false);
    const [bidConfirmTime, setBidConfirmTime] = useState(null);
    const [biddingFields, setBiddingFields] = useState({
        inputBid: '',
        myBidInWords: '',
        myBidInPrice: 0,
        differenceWithBestBid: 0
    })
    const [message, setMessage] = useState({ showMesg: false, message: "", severity: '' })
    const [value, setValue] = React.useState(null);
    const bidFactores = [{ ui: '1', db: 1 }, { ui: '2', db: 2 }, { ui: '3', db: 3 }, { ui: '4', db: 4 },
    { ui: '5', db: 5 }, { ui: '6', db: 6 }, { ui: '7', db: 7 }, { ui: '8', db: 8 }, { ui: '9', db: 9 }, { ui: '10', db: 10 }];
    const firstBidFactores = [{ ui: t('Bid_Start_Price'), db: 0 }, { ui: '1', db: 1 }, { ui: '2', db: 2 }, { ui: '3', db: 3 }, { ui: '4', db: 4 },
    { ui: '5', db: 5 }, { ui: '6', db: 6 }, { ui: '7', db: 7 }, { ui: '8', db: 8 }, { ui: '9', db: 9 }, { ui: '10', db: 10 }];
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        bidPrice: '',
        priceInWords: '',
        auctionName: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('OK')
    })
    const [errors, setErrors] = useState({
        incrementalError: '',
        nonIncrementalError: ''
    })
    const [isMaxLimit, setIsMaxLimit] = useState({
        yourBidX: '',
        isMax: false,
        yourAmt: 0
    });
    const maxContainerRef = useRef();
    const toPWords = new ToWords({
        localeCode: CurrencyFormat[auctionDetailContext.auctionGetDetail.baseCurrency].locale,
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { // can be used to override defaults for the selected locale
                name: CurrencyFormat[auctionDetailContext.auctionGetDetail.baseCurrency].wordingInteger,
                plural: CurrencyFormat[auctionDetailContext.auctionGetDetail.baseCurrency].wordingInteger,
                symbol: '',
                fractionalUnit: {
                    name: CurrencyFormat[auctionDetailContext.auctionGetDetail.baseCurrency].wordingDecimal,
                    plural: CurrencyFormat[auctionDetailContext.auctionGetDetail.baseCurrency].wordingDecimal,
                    symbol: '',
                },
            }
        }
    });
    const inputsHandler = (newValue) => {
        // setToWordLoading(true);
        let basePrices = currentHighestBid === 0 ? auctionLotInfo.basePrice : currentHighestBid
        let incrementalValue;
        let bidValue;
        //Non incremental validation
        if (!auctionLotInfo.incremental) {
            if (newValue <= currentHighestBid || newValue < auctionLotInfo.basePrice) {
                if (currentHighestBid === 0) {
                    setErrors({
                        incrementalError: '', nonIncrementalError: <Fragment>{t('Bid_Must_Be_Greater_Than_Or_Equal_To')} {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, basePrices)}</Fragment>
                    })
                } else {
                    setErrors({
                        incrementalError: '', nonIncrementalError: <Fragment>{t('Bid_Validation')} {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, basePrices)}</Fragment>
                    })
                }
                clearCalulatedFields();
                return;
            }
            else if (!PositiveIntOnly.test(newValue)) {
                if (newValue < basePrices) {
                    setErrors({
                        incrementalError: '', nonIncrementalError: <Fragment>{t('Bid_Validation')} {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, basePrices)}</Fragment>
                    })
                    clearCalulatedFields();
                    return;
                } else {
                    setErrors({ incrementalError: '', nonIncrementalError: '' })
                }
            }
            else if (newValue > FiftyTrillion) {
                setErrors({ incrementalError: '', nonIncrementalError: t('Value_must_be_less_than_10_Trillion') })
                clearCalulatedFields();
                return;
            }
            else {
                setErrors({ incrementalError: '', nonIncrementalError: '' })
            }
        }
        if (newValue === null || newValue === '') {
            setBiddingFields({
                inputBid: 0,
                myBidInWords: '',
                myBidInPrice: 0,
                differenceWithBestBid: 0
            })
            return;
        }
        if (auctionLotInfo.incremental) {
            if (newValue.db !== undefined) {
                incrementalValue = newValue.db
            } else {
                incrementalValue = newValue
            }
            bidValue = basePrices + (incrementalValue * actualIncrementalPrice);
            setBiddingFields({
                inputBid: newValue,
                myBidInWords: toPWords.convert(bidValue, { currency: true }),
                myBidInPrice: bidValue,
                differenceWithBestBid: ((bidValue - basePrices > 0) ? (bidValue - basePrices) : 0)
            })
            // getAmountInWord(bidValue);
        } else {
            setBiddingFields({
                inputBid: newValue,
                myBidInWords: toPWords.convert(newValue, { currency: true }),
                myBidInPrice: newValue,
                differenceWithBestBid: newValue - basePrices > 0 ? newValue - basePrices : 0
            })
        }
    }

    // const getAmountInWord = (amount) => {
    //     setMessage({ showMesg: false, message: '', severity: '' })
    //     AxiosAuctionInterceptor.get(`${process.env.REACT_APP_AWS_ENDPOINT}bid/amount/${auctionId}/${amount}`).then(function (response) {
    //         if (response) {
    //             setBiddingFields(prevState => ({
    //                 ...prevState,
    //                 myBidInWords: response.amountInWords,
    //             }))
    //         }
    //         setToWordLoading(false);
    //     }).catch(function (error) {
    //         setToWordLoading(false)
    //         setBiddingFields(prevState => ({
    //             ...prevState,
    //             myBidInWords: '',
    //         }))
    //         setMessage({ showMesg: true, message: error.message, severity: "error" })
    //     });
    // }

    const checkMaxBidInPriceForIndia = (xValue) => {
        let basePrices = currentHighestBid === 0 ? auctionLotInfo.basePrice : currentHighestBid
        let bidPriceValue = basePrices + (currentHighestBid === 0 ? (xValue - 1) * actualIncrementalPrice : xValue * actualIncrementalPrice);
        let testResult = (bidPriceValue > FiftyTrillion) ? true : false
        return testResult;
    }

    const createReconfirmBid = (payload) => {
        setMessage({ showMesg: false, message: '', severity: '' })
        setCreateBidLoading(true);
        AxiosAuctionInterceptor.post(`${process.env.REACT_APP_AWS_ENDPOINT}bid/${auctionId}`, payload).then(function (response) {
            if (response != null && response) {
                setShowSuccess(true);
                setTriggerBidHistory(response.amount)
                getLatestsBids();
                // //Check for extension Timing to update the Auctio Context
                // if (onlyMinutes <= auctionLotInfo.extendTimeWhenBidReceivedInLastMinute) {
                //     setTimeout(() => {
                //         getLatestsBids();
                //     }, 2000)
                // }
                setBidTransactionId(null);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 4500);
            }
            setCreateBidLoading(false);
            resetConfirmation();
            setBiddingFields({
                inputBid: 0, myBidInWords: '',
                myBidInPrice: 0, differenceWithBestBid: 0,
                differenceWithStartPrice: 0
            })
            setValue(null);
        }).catch(function (error) {
            setCreateBidLoading(false);
            if(error.message == 'Network Error') {
                setMessage({ showMesg: true, message: t('Please_Check_Internet_Connection'), severity: "error" });
            } else {
                if(error.message.includes('rejected')){
                    closeConfirmationDialog();
                }
                setMessage({ showMesg: true, message: error.message, severity: "error" })
            }
        });
    }
    const createConfirmBid = (payload) => {
        setMessage({ showMesg: false, message: '', severity: '' })
        setCreateBidLoading(true);
        AxiosAuctionInterceptor.post(`${process.env.REACT_APP_AWS_ENDPOINT}bid/${auctionId}`, payload).then(function (response) {
            if (response != null && response) {
                setBidTransactionId(response.idString);
                setBidConfirmTime(response.startTime);
            }
            setCreateBidLoading(false);
        }).catch(function (error) {
            setCreateBidLoading(false);
            if(error.message == 'Network Error' || error.message == 'Please Check internet connection') {
                setMessage({ showMesg: true, message: t('Please_Check_Internet_Connection'), severity: "error" });
                submitButton();
            } else {
                if(error.message.includes('rejected')){
                    closeConfirmationDialog();
                }
                setMessage({ showMesg: true, message: error.message, severity: "error" });
            }
        });
    }

    const resetConfirmation = () => {
        setBidConfirmTime(null);
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            bidPrice: '',
            priceInWords: '',
            auctionName: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('OK')
        }))
    }
    const sendRequest = (bidTransId) => {
        // setOpen(false);
        let payload = {
            bidOnId: lotId,
            amount: auctionLotInfo.incremental ? biddingFields.myBidInPrice : biddingFields.inputBid,
            bidId: bidTransId
        }
        createReconfirmBid(payload);
    }

    const clearBidData = () => {
        setCreateBidLoading(false);
        resetConfirmation();
        setBiddingFields({
            inputBid: 0, myBidInWords: '',
            myBidInPrice: 0, differenceWithBestBid: 0,
            differenceWithStartPrice: 0
        })
        setValue(null);
        setIsMaxLimit({
            yourBidX: '',
            isMax: false,
            yourAmt: 0
        });
        setErrors({ incrementalError: '', nonIncrementalError: '' });
        setBidTransactionId(null);
    }

    const clearCalulatedFields = () => {
        setBiddingFields({
            myBidInWords: '',
            myBidInPrice: 0,
            inputBid: 0,
            differenceWithBestBid: 0
        })
        // setIsMaxLimit(false);
    }

    const reConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t('Bid_Confirmation'),
            bidPrice: biddingFields.myBidInPrice,
            priceInWords: biddingFields.myBidInWords,
            auctionId: bidHeading.idDescription,
            bidDifferenceInPrice: currentHighestBid > 0 ? (biddingFields.myBidInPrice - currentHighestBid) : 0,
            bidPercentage: currentHighestBid > 0 ? (((biddingFields.myBidInPrice - currentHighestBid) / currentHighestBid) * 100) : 0,
            onAgree: (id) => {
                sendRequest(id)
            },
            onDisAgree: clearBidData,
            agreeBtnLabel: t('Re_Confirm'),
            disAgreeBtnLabel: t('Cancel')
        }))
    }

    const onFirstConfirm = (e) => {
        reConfirmation();
        let payload = {
            bidOnId: lotId,
            amount: auctionLotInfo.incremental ? biddingFields.myBidInPrice : biddingFields.inputBid
        }
        createConfirmBid(payload);
    }

    const submitButton = (e) => {
        setBidConfirmTime(null);
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t('Bid_Confirmation'),
            bidPrice: !auctionLotInfo.incremental ? biddingFields.inputBid : biddingFields.myBidInPrice,
            priceInWords: biddingFields.myBidInWords,
            auctionId: bidHeading.idDescription,
            bidDifferenceInPrice: currentHighestBid > 0 ? (biddingFields.myBidInPrice - currentHighestBid) : 0,
            bidPercentage: currentHighestBid > 0 ? (((biddingFields.myBidInPrice - currentHighestBid) / currentHighestBid) * 100) : 0,
            onAgree: onFirstConfirm,
            onDisAgree: clearBidData,
            agreeBtnLabel: t('Confirm'),
            disAgreeBtnLabel: t('Cancel')
        }))
    }

    const closeConfirmationDialog = () => {
        setBidTransactionId(null);
        resetConfirmation();
        setBiddingFields({
            inputBid: 0, myBidInWords: '',
            myBidInPrice: 0, differenceWithBestBid: 0,
            differenceWithStartPrice: 0
        })
        setValue(null);
    }

    useEffect(() => {
        if (currentHighestBid > previousHighBid && biddingFields.myBidInPrice > 0) {
            setErrors({ incrementalError: '', nonIncrementalError: '' })
            setErrors({ incrementalError: t('Current_Highest_Bid_Has_Been_Changed_Please_Update_Your_Bid'), nonIncrementalError: ''})
        }
    }, [currentHighestBid])

    useEffect(() => {
        if(isClosed) {
            resetConfirmation();
        }
    }, [isClosed])

    // const navigateToHelpDesk = () => {
    //     console.log('navigateToHelpDesk')
    //     auctionDetailContext.setSelectedList(AuctionDetailMenuItems.HELP_DESK.value);
    // }

    return (
        <Stack spacing={1}>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} spacing={1}>
                <Stack width={{ xs: '100%', sm: '100%', md: '100%', lg: '25%' }}>
                    <AuctionInfoCard>
                        <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} spacing={1}>
                            <Stack direction='column'
                                width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }} spacing={2}>
                                {yourRank !== 0 && <BidderPriceView
                                    labelId={'yourranklabel'}
                                    title={t('Your_Rank')}
                                    className={yourRank === 1 ? classes.h1BidderBg : classes.notH1BidderBg}
                                    value={<Typography id='yourrank' color='textSecondary' className={classes.price}>
                                        {yourRank}
                                    </Typography>}
                                    icon={<MilitaryTechRounded className={[yourRank === 1 ? classes.h1BidderIcon : classes.NotH1BidderIcon, classes.rank].join(' ')} />} />}
                                <BidderPriceView
                                    labelId={'yourlastbidlabel'}
                                    title={t('Your_last_Bid')}
                                    className={yourRank === 1 ? classes.h1BidderBg : classes.notH1BidderBg}
                                    value={<Typography id='yourlastbid' color='textSecondary' className={classes.price}>
                                        {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, yourLastBid)}
                                    </Typography>}
                                    icon={<PriorityHighRounded className={[yourRank === 1 ? classes.h1BidderIcon : classes.NotH1BidderIcon, classes.lastBid].join(' ')} />} />
                                {(!isClosed && auctionLotInfo.incremental) &&
                                    <BidderPriceView
                                        labelId={'nextposiiblebidlabel'}
                                        title={t('Next_Possible_Bid')}
                                        className={classes.h1BidderBg}
                                        value={
                                            <Typography id='nextposiiblebid' color='textSecondary' variant='subtitle2' className={classes.price}>
                                                {t('Minimum_of_1x')}<br></br>
                                                {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, currentHighestBid > 0 ?
                                                    (currentHighestBid + actualIncrementalPrice) : (auctionLotInfo.basePrice))}
                                                &nbsp;{t('Or_Higher_Multiples')}
                                            </Typography>
                                        }
                                        icon={<NextPlanRounded color='gray' className={[classes.h1BidderIcon, classes.nextbid].join(' ')} />} />
                                }
                            </Stack>
                        </Stack>
                    </AuctionInfoCard>
                </Stack>
                {isClosed && <Stack direction='column' spacing={1} p={{ xs:'10px 15px' }}
                    width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%' }} ref={containerRef}
                    sx={{
                        background: 'white',
                        boxShadow: 'rgba(184,114,6, 0.5) 0px 0px 10px 0px',
                        borderRadius: '5px',
                        border: '1px solid lightsteelblue',
                        marginTop: '8px !important'
                    }}
                >
                    {currentHighestBid > 0 ?
                        <Fragment>
                            <AuctionInfoCard>
                                <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
                                    {/* <GiTrophyCup style={{ fontSize: '4rem', color: 'darkgoldenrod' }} /> */}
                                    <img src={process.env.PUBLIC_URL + '/images/bid-hit.svg'} width='50px' height='60px'></img>
                                    <Stack direction='column' spacing={0}>
                                        <Typography id='winbidlabel' color='textSecondary' variant='h5'>{t('Winning_Bid_Price')}</Typography>
                                        <Typography id='winbid' variant='h4' className={classes.currentHighestBid}>
                                            {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, currentHighestBid)}</Typography>
                                    </Stack>
                                </Stack>
                            </AuctionInfoCard>
                            <Stack justifyContent='space-between' alignItems='start' direction='row' p={2}>
                                <Stack direction='column' spacing={0}>
                                    <Typography id='winbidinwordlabel' color='textSecondary'>{t('Winning_Bid_Price_in_words')}</Typography>
                                    <Typography id='winbidinword' variant='h6'>{toPWords.convert(currentHighestBid, { currency: true })}</Typography>
                                </Stack>
                            </Stack>
                        </Fragment> :
                        <AuctionInfoCard>
                            <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} color='indianred' id='no-bid-placed'>{t('No_Bids_Placed')}</Typography>
                            </Stack>
                        </AuctionInfoCard>
                    }
                </Stack>}
                {!isClosed &&
                    <Stack direction='column' spacing={1} p={{ xs:'10px 15px' }}
                        width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%' }} ref={containerRef}
                        sx={{
                            background: 'white',
                            boxShadow: 'rgba(184,114,6, 0.5) 0px 0px 10px 0px',
                            borderRadius: '5px',
                            border: '1px solid lightsteelblue',
                            marginTop: '8px !important'
                        }}
                    >
                        <Slide in={showSuccess} unmountOnExit direction='down' container={containerRef.current}>
                            <BidSuccess>
                                <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                                    <BsPatchCheck style={{ fontSize: 'xx-large' }} />
                                    <Typography id='successmsg' variant='body1' fontWeight='bold'>{t('Your_Bid_submitted_successfully')}</Typography>
                                </Stack>
                            </BidSuccess>
                        </Slide>
                        <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}
                            sx={{ borderRadius: '5px', justifyContent: 'center', border: '2px solid antiquewhite', marginBottom: '2px' }}>
                            {/* <GiTrophyCup style={{ fontSize: '3rem', color: 'darkgoldenrod' }} /> */}
                            <img src={process.env.PUBLIC_URL + '/images/bid-hit.svg'} width='50px' height='60px'></img>
                            <Stack direction='column' spacing={0}>
                                <Typography id='currenthighbidlabel'>{t('Current_Highest_Bid')}</Typography>
                                {currentHighestBid > 0 && <Typography id='currenthighbidvalue' variant='h6' className={classes.currentHighestBid}>
                                    {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, currentHighestBid)}
                                </Typography>}
                                {currentHighestBid === 0 && <Typography id='currenthighbidvalue' className={classes.currentHighestBid}>
                                    {t('Be_the_first_in_line_to_bid')}</Typography>}
                            </Stack>
                        </Stack>
                        {/* <Divider flexItem /> */}
                        {auctionLotInfo.incremental &&
                            <Stack spacing={1} ref={maxContainerRef}>
                                <FormLabel id='bidlabel' sx={{fontSize: '14px'}}>{t('Select_Enter_your_Bid_Minimum_1x')}</FormLabel>
                                <Stack direction='row'>
                                    <Autocomplete
                                        value={value}
                                        fullWidth
                                        disableClearable
                                        onChange={(event, newValue) => {
                                            let amt = (newValue.db * actualIncrementalPrice) + (currentHighestBid === 0 ? auctionLotInfo.basePrice : currentHighestBid)
                                            if (newValue.db !== '' && newValue.db !== null && checkMaxBidInPriceForIndia(Number(newValue.db))) {
                                                setIsMaxLimit({
                                                    yourBidX: newValue.db,
                                                    isMax: true,
                                                    yourAmt: amt
                                                });
                                                setErrors({ incrementalError: t('Value_must_be_less_than') + CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, 50000000000000), nonIncrementalError: '' })
                                                clearCalulatedFields();
                                            } else {
                                                setIsMaxLimit({
                                                    yourBidX: '',
                                                    isMax: false,
                                                    yourAmt: 0
                                                });
                                                setErrors({ incrementalError: '', nonIncrementalError: '' })
                                                setValue(newValue)
                                                if (newValue.db === 0) {
                                                    inputsHandler(newValue.db);
                                                } else {
                                                    inputsHandler(newValue.ui)
                                                }
                                            }
                                        }}
                                        onInputChange={(event, newValue) => {
                                            // setValue(newValue)
                                            if (newValue === '' || newValue === BID_OPEN_AMOUNT_CHECK_STRING) {
                                                clearBidData();
                                                return;
                                            }
                                            if (newValue !== '' && newValue !== null && checkMaxBidInPriceForIndia(Number(newValue))) {
                                                setIsMaxLimit({
                                                    yourBidX: newValue,
                                                    isMax: true,
                                                    yourAmt: (newValue * actualIncrementalPrice) + (currentHighestBid === 0 ? auctionLotInfo.startPrice : currentHighestBid)
                                                });
                                                setErrors({ incrementalError: t('Value_must_be_less_than') + CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, 50000000000000), nonIncrementalError: '' })
                                                clearCalulatedFields();
                                            } else if (newValue !== '' && newValue !== null && !PositiveIntOnly.test(Number(newValue))) {
                                                Number(newValue) > 0 ? setErrors({
                                                    incrementalError: `${t('Invalid_Input_Possible_values_are')}
                                            ${parseInt(Number(newValue))} and ${parseInt(Number(newValue)) + 1}`,
                                                    nonIncrementalError: ''
                                                }) :
                                                    setErrors({
                                                        incrementalError: t('Invalid_Input_Possible_values_are_one_two_so_on'),
                                                        nonIncrementalError: ''
                                                    })
                                                clearCalulatedFields();

                                            } else {
                                                setIsMaxLimit({
                                                    yourBidX: '',
                                                    isMax: false,
                                                    yourAmt: 0
                                                });
                                                setErrors({ incrementalError: '', nonIncrementalError: '' })
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => Number(inputValue) === option.db && inputValue == option.ui);
                                            if (inputValue !== '' && !isExisting && PositiveIntOnly.test(Number(inputValue)) && !checkMaxBidInPriceForIndia(Number(inputValue))) {
                                                filtered.push({
                                                    ui: parseInt(inputValue).toString(),
                                                });
                                            }
                                            return filtered;
                                        }}
                                        renderOption={(props, option) => {
                                            let optionLength = option.ui.length;
                                            return (
                                                <Fragment>
                                                    <li {...props} style={{ borderBottom: '1px solid #eeeeee' }}>
                                                        <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: (optionLength < 9 || option.db === 0) ? 'row' : 'column' }}
                                                            spacing={2} justifyContent='space-between' width='100%'>
                                                            <Typography id='optionxbid'>{option.db === 0 ? option.ui : `${option.ui}x`}</Typography>
                                                            <Typography id='optionxbidprice' sx={{ fontStyle: 'italic' }} color='primary' variant='subtitle1'>
                                                                {(!option.db && option.db !== 0) &&
                                                                    CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, (currentHighestBid === 0 ?
                                                                        auctionLotInfo.startPrice : currentHighestBid) + (actualIncrementalPrice * Number(option.ui)))
                                                                }
                                                                {(option.db === 0) &&
                                                                    CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.startPrice)
                                                                }
                                                                {(option.db > 0) &&
                                                                    CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, (currentHighestBid === 0 ?
                                                                        auctionLotInfo.startPrice : currentHighestBid) + (actualIncrementalPrice * option.db))
                                                                }
                                                            </Typography>
                                                        </Stack>
                                                        {/* <Divider flexItem sx={{ borderBottomWidth: 1 }} /> */}
                                                    </li>
                                                </Fragment>
                                            )
                                        }}
                                        onBlur={() => setIsMaxLimit({
                                            yourBidX: '',
                                            isMax: false,
                                            yourAmt: 0
                                        })}
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="bid_by_x"
                                        options={currentHighestBid > 0 ? bidFactores : firstBidFactores}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.ui;
                                        }}
                                        freeSolo
                                        renderInput={(params) =>
                                            (
                                            <>
                                                <TextField placeholder={t('Enter_Bid')} color='primary'
                                                    variant='outlined'{...params} fullWidth size='small'
                                                    type={biddingFields.myBidInPrice === auctionLotInfo.startPrice ? 'text': 'number'}
                                                    helperText={errors?.incrementalError}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        classes: {
                                                            notchedOutline: classes.notchedOutline
                                                        },
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: { color: 'orangered', fontWeight: '700' }
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                    <Tooltip title={t('Clear')} arrow>
                                        <Button id='clearbid'
                                            variant='outlined'
                                            onClick={clearBidData}
                                            sx={{ padding: '0px 10px !important', minWidth: 'auto !important'}}
                                        >
                                            <CloseRounded color='primary' />
                                        </Button>
                                    </Tooltip>
                                    {isMaxLimit.isMax && <Box id='max-limit-info-box'
                                        sx={{
                                            background: (theme) => theme.palette.background.paper,
                                            border: '1px solid #eeeeee',
                                            padding: '8px',
                                            position: 'absolute', marginTop: '70px',
                                            zIndex: '1',
                                            borderTop: (theme) => `3px solid ${theme.palette.error.main}`,
                                            boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%),0 30px 60px -30px rgb(0 0 0 / 30%) !important',
                                            borderRadius: '5px 5px 8px 8px',
                                            '&:after': {
                                                content: '" "',
                                                borderBottom: (theme) => `11px solid ${theme.palette.error.main}`,
                                                borderLeft: '8px solid transparent',
                                                borderRight: '8px solid transparent',
                                                position: 'relative',
                                                top: '-149px',
                                                right: '-200px',
                                            }
                                        }}>
                                        <Stack justifyContent='center' alignItems='center' spacing={1}>
                                            <Typography component='div' variant='subtitle2' textAlign='center' sx={{
                                                background: (theme) => alpha(theme.palette.info.main, 0.09),
                                                color: (theme) => theme.palette.info.main,
                                                padding: '5px',
                                                borderRadius: '5px',
                                                fontWeight: 'bold'
                                            }}>
                                                Bid Amount for Bid {isMaxLimit.yourBidX}x is {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, isMaxLimit.yourAmt)}
                                            </Typography>
                                            <Typography component='div' variant='subtitle2' textAlign='center' id='max-limit-error-message'>
                                                Your Bid Amount should be less than maximum value <br /><span style={{ color: 'green', fontWeight: 'bold' }}> {CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, 50000000000000)}</span>. <br />Please Contact Auctioneer.
                                            </Typography>
                                            {/* <Button size='small' color='primary' variant='contained' sx={{ width: 'fit-content' }}
                                                onClick={() => {
                                                    console.log('publish')
                                                    auctionDetailContext.setSelectedList(AuctionDetailMenuItems.HELP_DESK.value)
                                                }}>
                                                View Help Desk</Button> */}
                                        </Stack>
                                    </Box>}
                                </Stack>
                            </Stack>
                        }
                        {!auctionLotInfo.incremental &&
                            <Stack>
                                <FormLabel htmlFor='filled-number' id='bidprice'>{t('Enter_Your_Bid_Price')}</FormLabel>
                                <Stack direction='row'>
                                    <NumericFormat
                                        autoFocus
                                        customInput={TextField}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        variant="outlined"
                                        id="filled-number"
                                        fullWidth
                                        onValueChange={({ value }) => {
                                            inputsHandler(Number(value))
                                        }}
                                        decimalScale={2}
                                        size='small'
                                        value={biddingFields.inputBid}
                                        name='inputBid'
                                        helperText={errors?.nonIncrementalError}
                                        InputProps={{
                                            classes: {
                                                // root: classes.cssOutlinedInput,
                                                // focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            }
                                        }}
                                    />
                                    <Tooltip title={t('Clear')} arrow>
                                        <Button id='clearbid'
                                            variant='outlined'
                                            onClick={clearBidData}
                                            sx={{ padding: '0px 10px !important', minWidth: 'auto !important'}}
                                        >
                                            <CloseRounded color='primary' />
                                        </Button>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        }
                        <Fragment>
                            <Stack justifyContent='space-between' alignItems='start' direction='row' pt={1.5} pb={0.5} sx={{marginTop:'0px !important'}}>
                                <FormLabel id='pricelabel' sx={{ width: { xs: '30%', fontSize: '14px' } }}>{t('Bid_Amount')}</FormLabel>
                                <Typography id='price' sx={{ width: { xs: '70%' }, textAlign: 'end', fontWeight: 600 }}>
                                    {biddingFields.myBidInPrice === 0 ? '-' : <Fragment>{CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, biddingFields.myBidInPrice)}</Fragment>}
                                </Typography>
                            </Stack>
                            <Divider flexItem />
                        </Fragment>
                        <Stack justifyContent='space-between' alignItems='start' direction='row' pt={1.5} pb={0.5} sx={{marginTop:'0px !important', marginBottom:'0px !important'}}>
                            <FormLabel id='priceinwordlabel' sx={{ width: { xs: '30%' }, fontSize: '14px' }}>{t('Bid_Amount_In_Words')}</FormLabel>
                            {/* {toWordLoading && <Typography color='primary' className='dot-loading' style={{ marginRight: '14px' }}>{t('Converting_Bid_Amount_InWords')}</Typography>} */}
                            <Typography id='priceinword' sx={{ width: { xs: '70%' }, textAlign: 'end', textTransform: 'capitalize', fontSize: '14px', fontWeight: 600 }}>
                                {biddingFields.myBidInWords == '' ? '-' : <Fragment>{biddingFields.myBidInWords}</Fragment>}
                            </Typography>
                        </Stack>
                        {/* {!auctionLotInfo.incremental &&
                            <Fragment>
                                <Divider flexItem />
                                <Stack justifyContent='space-between' alignItems='start' direction='row' pt={1.5} pb={0.5}>
                                    <FormLabel id='highdifflabel' sx={{ width: { xs: '40%' } }}>{t('Highest_Bid_Difference')}</FormLabel>
                                    <Typography id='highdiff' sx={{ width: { xs: '60%' }, textAlign: 'end' }}>
                                        {biddingFields.myBidInPrice === 0 ? '-' : <Fragment>{CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, biddingFields.differenceWithBestBid)}</Fragment>}
                                    </Typography>
                                </Stack>
                            </Fragment>
                        } */}
                        <Stack>
                            {(!createBidLoading) &&
                                <ButtonStyled id='placemybid' variant='contained' onClick={submitButton} disabled={
                                    (auctionLotInfo.incremental ? ((biddingFields.myBidInPrice === 0 && biddingFields.inputBid <= 0) || errors.incrementalError !== '') :
                                        errors.nonIncrementalError !== '' || biddingFields.inputBid <= currentHighestBid ||
                                        biddingFields.inputBid < auctionLotInfo.basePrice)}
                                    color='primary'>{t('Place_Bid')}</ButtonStyled>
                            }
                        </Stack>
                    </Stack> 
                }
                <Stack width={{ xs: '100%', sm: '100%', md: '100%', lg: '40%' }} sx={{marginTop: '1px !important'}}>
                    <Paper className={classes.paper}>
                        <Stack justifyContent='space-between' direction='row' py={0.5} px={1.2} alignItems='center'>
                            <Typography id='bidhistory' sx={{ fontSize: '16px', color: 'darkgoldenrod' }}>{t('Your_Bid_History')}</Typography>
                            {bidHistory.length > 0 && <Button id='viewbidhistory' sx={{ fontSize: '14px' }} onClick={() => setBidHisDialogOpen(true)} color='primary'>{t('View_All_History')}</Button>}
                        </Stack>
                    </Paper>
                    <BidHistory
                        key={bidHistory}
                        biddersBidHistory={bidHistory}
                        isBid={true}
                        auctionStatus={auctionDetailContext.auctionGetDetail.status.toLowerCase()}
                        showLess={true}
                        setBidHisDialogOpen={setBidHisDialogOpen}
                    />
                </Stack>
            </Stack >
            {!isClosed &&
                <Stack id='warningmsg' direction='row' spacing={1} p={1} className={classes.warningMsg} justifyContent='center' alignItems='start'
                    sx={{position: 'fixed', bottom: '0px', padding: '2px 8px', marginRight: '10px !important'}} >
                    <WarningRounded />
                    <Typography variant='subtitle2'><strong>{t('Disclaimer')}:</strong></Typography>
                    <Typography variant='subtitle2' sx={{fontSize: '12px'}}>{t('You_are_advised_not_to_wait_till_last_minute')}{t('internet_connectivity_system_crash_power_failure')}.&nbsp;{t('If_Your_Bid_Is_Unsuccessful_for_Internet_Issue_We_Are_Not_Responsible')}</Typography>
                </Stack>
            }
            {bidHisDialogOpen && <BidProcessDialog title={t('Bid_History')} open={bidHisDialogOpen} handleClose={() => setBidHisDialogOpen(false)}
                component={<BidHistory auctionId={props.auctionId} bidderId={null} bidOnId={lotId} key={triggerBidHistory} pagination={true} isBidder={true}
                    auctionStatus={auctionDetailContext.auctionGetDetail.status.toLowerCase()} />} />}
            {seekConfirmation.show && auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.open &&
                <BidConfirmation
                    open={seekConfirmation.show}
                    seekConfirmation={seekConfirmation}
                    loading={createBidLoading}
                    bidTransactionId={bidTransactionId}
                    bidConfirmTime={bidConfirmTime}
                    currentHighestBid={currentHighestBid}
                    diffInIncreamentVsExtension={Math.abs(auctionLotInfo.extensionTimeIncrementPrice - auctionLotInfo.incrementalPrice) > 0 ? true : false}
                    currentExtension={auctionLotInfo.extensionNumber}
                    baseCurrency={auctionDetailContext.auctionGetDetail.baseCurrency}
                />
            }
            { message.showMesg && <AuctionSnackBar show={true} message={message.message} severity={message.severity} />}
            {createBidLoading && <AuctionLoader show={createBidLoading} invisible={false} />}
        </Stack >
    )
}

export default EnglishForwardBid
