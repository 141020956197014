import React, { useState, useRef } from 'react'
import {
    Card, Typography, Grid, InputAdornment, IconButton,
    TextField, Button, Link, ToggleButtonGroup, ToggleButton, Box
} from '@mui/material';
import { GppMaybe } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { useHistory } from 'react-router-dom';
import { JustifyBox, ContentBox, AlertInfoBox, RootDiv, IMG } from '../AuthStyle';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import { AuthAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import AuctionReCaptcha from '../../common/AuctionReCaptcha';
import * as Validators from '../../common/form-validators';
import PasswordPolicy, {
    NumberCheck, UpperLetter,
    LowerLetter, SpecialChar, MinPwdLength, MaxPwdLength
} from '../../common/PasswordPolicy';
import {MdAttachEmail} from "react-icons/md"
import {FaMobileAlt} from "react-icons/fa"
import MuiPhoneNumber from "material-ui-phone-number";
import { makeStyles } from '@mui/styles';
import { AppHostDomain } from '../../common/StaticVariableDeclaration';

const useStyles = makeStyles(() => ({
    container: {
        fontWeight: "bold !important", 
        fontSize: '12px !important',
        paddingRight: '1em !important',
    },
    EmailColor:{
        background: '#1976d2 !important',
        color: 'white !important',
        borderRadius: "50px 0px 0px 50px !important",
    },
    mobileNumber:{
        background: '#1976d2 !important',
        color: 'white !important',
        borderRadius: "0px 50px 50px 0px !important",
    },
    defaultMobileColor:{
        borderRadius: "0px 50px 50px 0px !important",
    },
    defaultEmailColor:{
        borderRadius: "50px 0px 0px 50px !important",
    }
    
}))

const ForgotPassword = () => {
    let history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [open, setOpen] = useState(false)
    const [pwdLength, setPwdLength] = useState(false);
    const [maxPwdLength, setMaxPwdLength] = useState(false);
    const [isUpper, setIsUpper] = useState(false);
    const [isLower, setIsLower] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [restPwd, setResetPwd] = useState({
        username: '',
        errorMessage: '',
        showErrorMessage: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false,
        password: '',
        confirmpassword: '',
        errors: {},
        isValidForm: false,
        showSuccessMessage: false,
        successMessage: ''
    })
    const [emailOrPhone, setEmailOrPhone] = useState('email')
    const [extension, setExtension] = useState('+91')

    const sendOtpRef = useRef(null);

    const onUserNameChange = (event) => {
        setResetPwd(prevState => ({
            ...prevState,
            username: event.target.value
        }))
        setTouched(prevState => ({
            ...prevState,
            username: true,
        }))
    }

    // const handleValidation = (fieldValue) => {
    //     setIsValidForm(true);
    //     setError('')
    //     if (!fieldValue) {
    //         setIsValidForm(false);
    //         setError(t('Email_Address_CellPhone_Required'));
    //     } else {
    //         if(/^[0-9+]+$/.test(fieldValue)) {
    //             //Check for cellphone validity
    //         } else if (!(/(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValue))) {
    //             setIsValidForm(false);
    //             setError(t('Invalid_Email_Address_CellPhone'));
    //         }

    const setStateData = (id, value) => {
        setResetPwd(prevState => ({
            ...prevState,
            [id]: value
        }))
        setTouched(prevState => ({
            ...prevState,
            [id]: true,
        }))
    }

    const onInputChange = (event) => {
        setResetPwd(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const clearErrors = (event) => {
        setErrors((prevState) => ({
            ...prevState,
            [event.target.id]: ''
        }))
    }

    const onUsernameBlur = (event) => {
        let error = validate['username'](event.target.value)
        if (event.target.value) {
            setErrors((prevState) => ({
                ...prevState,
                username: error ? error : ''
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                username: ''
            }))
        }
    }

    const handlePopoverClose = (event) => {
        setOpen(false);
        if (!pwdLength || !maxPwdLength || !isUpper || !isLower || !isNumber || !isSpecial) {
            setErrors((prevState) => ({
                ...prevState,
                password: (!pwdLength && 'Minimum length should be 8 character') ||
                            (!maxPwdLength && 'Maximum length should be 50 character') ||
                            (!isUpper && 'Provide Atleast one uppercase letter') ||
                            (!isLower && 'Provide Atleast one lowercase letter') ||
                            (!isNumber && 'Provide Atleast one number') ||
                            (!isSpecial && 'Provide Atleast one special character')
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                password: ''
            }))
            if (restPwd.confirmpassword !== event.target.value) {
                setErrors((prevState) => ({
                    ...prevState,
                    confirmpassword: "Password does not match"
                }))
            } else {
                setErrors((prevState) => ({
                    ...prevState,
                    confirmpassword: ''
                }))
            }
        }
    }

    const onPasswordChange = (event) => {
        const pwdValue = event.target.value
        setResetPwd(prevState => ({
            ...prevState,
            [event.target.id]: pwdValue
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
        setPwdLength(pwdValue.length > MinPwdLength ? true : false);
        setMaxPwdLength(pwdValue.length < MaxPwdLength ? true : false);
        setIsUpper(UpperLetter.test(pwdValue) ? true : false);
        setIsLower(LowerLetter.test(pwdValue) ? true : false);
        setIsNumber(NumberCheck.test(pwdValue) ? true : false);
        setIsSpecial(SpecialChar.test(pwdValue) ? true : false);
        setOpen(event.target);
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(restPwd).map(([key]) => {
            if (key === 'username' || key === 'password' || key === 'confirmpassword') {
                const newError = validate[key](restPwd[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    const validatePasswords = (event) => {
        if (restPwd.password !== event.target.value) {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: "Password does not match"
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                confirmpassword: ''
            }))
        }
    }

    const validate = {
        password: password => Validators.requiredValidation('Password', password),
        confirmpassword: confirmpassword => (Validators.requiredValidation('Confirm Password', confirmpassword) ||
            Validators.equalValidation('Password', confirmpassword, restPwd.password)),
        username: username => (Validators.requiredValidation('Username', username) || (emailOrPhone === 'mobile' ? Validators.mobileNumberValidation(extension + username) : Validators.emailValidation(username))),
    };

    // const handleValidation = (fieldValue) => {
    //     setIsValidForm(true);
    //     setError('')
    //     if (!fieldValue) {
    //         setIsValidForm(false);
    //         setError("Email Id is required");
    //     } else if (!(/(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValue))) {
    //         setIsValidForm(false);
    //         setError("Invalid Email Id");
    //     }
    // }

    const sendOtp = async event => {
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                event.preventDefault();
                setStateData('loading', true);
                setStateData('showErrorMessage', false);
                AuthAxiosAuctionInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
                    loginId: emailOrPhone === 'mobile' ? extension + restPwd.username : restPwd.username, password: restPwd.password
                }).then(response => {
                    setStateData('errorMessage', '');
                    setStateData('loading', false);
                    if (response) {
                        history.push({
                            pathname: "/reset-password", state: {
                                password: restPwd.password,
                                username: emailOrPhone === 'mobile' ? extension + restPwd.username : restPwd.username
                            }
                        })
                    }
                }).catch((error) => {
                    setStateData('loading', false);
                    if (error.message) {
                        if(error.message.includes('ReCaptcha Expired')) {
                            setIsRefresh(true);
                            setTimeout(() => {
                                setIsRefresh(false);
                                sendOtpRef.current.click();
                            }, 2000);
                        } else {
                            setStateData('errorMessage', error.message);
                            setStateData('showErrorMessage', true);
                        }
                    }
                    setIsRefresh(true);
                    setTimeout(() => {
                        setIsRefresh(false);
                    }, 1000);
                })
            }
        })
    }

    const login = () => {
        history.push("/sign-in")
    }

    const toggleShowPassword = () => setStateData('showPassword', !restPwd.showPassword);
    const toggleShowConfirmPassword = () => setStateData('showConfirmPassword', !restPwd.showConfirmPassword);

    const handleLoginDetails = (event, newValue) => {
        setErrors((prevState) => ({
            ...prevState,
            username: ''
        }))
        setResetPwd((prevState) => ({
            ...prevState,
            username: ''
        }))
        setEmailOrPhone(newValue === null ? emailOrPhone : newValue)
    }
    const handleMobileNumberChange = (fieldName, event) => {
        if(event.target.value === '' || /^[0-9]+$/.test(event.target.value)){
            setResetPwd(prev => ({
                ...prev,
                username: event.target.value
            }))
        }
    }

    const onMobileNumberChange = (value) =>{
        setExtension(value)
      }

    return (
        <RootDiv>
            <Card className='card' sx={{ maxWidth: '600px !important' }}>
                <Grid container>
                    {/* <Grid item lg={5} md={5} sm={12} xs={12}>
                        <JustifyBox p={4} height="30%" style={{ alignItems: 'baseline' }}>
                            <img src={process.env.PUBLIC_URL + '/images/nbid.png'} height={70} alt='NeslPDALogo'></img>
                        </JustifyBox>
                        <JustifyBox p={4} height="70%" style={{ alignItems: 'baseline' }}>
                            <IMG
                                src={process.env.PUBLIC_URL + '/forgotpassword.svg'}
                                alt="forgotpassword"
                            />
                        </JustifyBox>
                    </Grid> */}
                    <Grid item xs={12}>
                        <ContentBox style={{ padding: '32px' }}>
                            <Grid item xs={12} className="text-center">
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + '/images/auction.svg'} height={50} alt='E-AuctionLogo'></img> }
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + '/images/nbid.png'} height={45} alt='NbidLogo'></img> }
                            </Grid>
                            <br />
                            <Typography component="h1" variant="h5" gutterBottom className="text-center">
                                {t('Forgot_Password')}
                            </Typography>
                            <br />
                            <AlertInfoBox sx={{ justifyContent: 'center' }}>
                                <GppMaybe />
                                {t('Ensure_that_your_Username_is_registered_with_us')}
                            </AlertInfoBox>
                            <div  style={{textAlign:'center', marginTop:'1rem', marginBottom:'1rem'}}>
                            <ToggleButtonGroup
                                    value={emailOrPhone}
                                    exclusive
                                    size="small"
                                    onChange={handleLoginDetails}
                                    aria-label="text alignment"
                                  >
                                    {/* <Tooltip title="Login using Email"> */}
                                      <ToggleButton
                                        value="email"
                                        aria-label="left aligned"
                                        className={`${classes.container} ${emailOrPhone === "email" ? classes.EmailColor : classes.defaultEmailColor}`}
                                      >
                                        <MdAttachEmail fontSize='large'/>
                                        &nbsp; email
                                      </ToggleButton>
                                    {/* </Tooltip> */}
                                    {/* <Tooltip title="Login using Mobile Number"> */}
                                      <ToggleButton
                                        value="mobile"
                                        aria-label="centered" 
                                        className={`${classes.container} ${emailOrPhone === "mobile" ? classes.mobileNumber : classes.defaultMobileColor}`}

                                      >
                                        <FaMobileAlt fontSize='large'/> Mobile Number
                                      </ToggleButton>
                                    {/* </Tooltip> */}
                                  </ToggleButtonGroup>
                                  </div>
                            <Grid container style={{ paddingTop: '8px' }}>
                                <Grid item xs={12}>
                                {emailOrPhone === 'email' ? 
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                   <TextField id="username" label={t('Username_Email')} style={{ margin: '8px 8px 8px 0px' }}
                                        fullWidth value={restPwd.username}
                                        onClick={clearErrors}
                                        onBlur={onUsernameBlur}
                                        autoFocus
                                        onChange={onUserNameChange} helperText={touched['username'] && errors.username}
                                        error={touched['username'] && errors.username}
                                        autoComplete="off"
                                    /></Box> : <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <TextField 
                                    id="phonenumber"
                                    name="phonenumber"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    label={t("Username_Mobile_Number")}
                                    variant="outlined" fullWidth
                                    error={(errors.username)}
                                    helperText={errors.username}
                                    value={ emailOrPhone === 'mobile' && restPwd.username}
                                    onChange={(event, country) => {
                                            handleMobileNumberChange('phoneNumber', event, country)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MuiPhoneNumber
                                                    id="input-with-icon-adornment"
                                                    name="extension"
                                                    defaultCountry={"in"}
                                                    value={extension}
                                                    onChange={(event) => onMobileNumberChange(event)}
                                                    autoFormat={false}
                                                    style={{margin: "8px 0px", color: "black !important", 
                                                    width: extension.length > 3 ? extension.length === 4 ? '4.8rem' : extension.length === 5 ? '5.3rem' : '6.3rem' : '4rem'}}
                                                    required
                                                    disableDropdown={restPwd.loading}
                                                    countryCodeEditable={false}
                                                    disabled
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    />
                           </Box>}
                                    <TextField id="password" label={t('New_Password')} type={restPwd.showPassword ? "text" : "password"}
                                        style={{ margin: '8px 8px 8px 0px' }} value={restPwd.password}
                                        onChange={onPasswordChange}
                                        onClick={clearErrors}
                                        onBlur={handlePopoverClose}
                                        autoComplete="new-password"
                                        helperText={touched['password'] && errors.password}
                                        error={touched['password'] && errors.password}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowPassword}
                                                    >
                                                        {restPwd.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <PasswordPolicy open={open} pwdLength={pwdLength} isUpper={isUpper} isLower={isLower} isNumber={isNumber} isSpecial={isSpecial} maxPwdLength={maxPwdLength} />
                                    <TextField id="confirmpassword" label={t('Confirm_Password')} type={restPwd.showConfirmPassword ? "text" : "password"}
                                        style={{ margin: '8px 8px 8px 0px' }} value={restPwd.confirmpassword}
                                        onChange={onInputChange}
                                        onBlur={validatePasswords}
                                        autoComplete="new-password"
                                        helperText={touched['confirmpassword'] && errors.confirmpassword}
                                        error={touched['confirmpassword'] && errors.confirmpassword}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowConfirmPassword}
                                                    >
                                                        {restPwd.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="text-center" style={{ marginTop: '15px' }}>
                                    <Link onClick={login} style={{ float: 'left', fontSize: '12px', cursor: 'pointer' }}>{t('Back_to_Sign_In')}</Link>
                                    <Button id="pwd-reset-send-otp" ref={sendOtpRef} size="small" variant="contained" color="primary" style={{ float: 'right' }}
                                        onClick={sendOtp}
                                        disabled={restPwd.loading || !pwdLength || !maxPwdLength || !isUpper || !isLower || !isNumber || !isSpecial
                                            || (restPwd.password !== restPwd.confirmpassword) || validate['username'](restPwd.username)}>
                                        {t('Send_Otp')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ContentBox>
                    </Grid>
                </Grid>
            </Card>
            {restPwd.loading && <AuctionLoader show={true} invisible={false} />}
            {restPwd.showErrorMessage &&
                <AuctionSnackBar show={restPwd.showErrorMessage} message={restPwd.errorMessage} severity="error" reset={() => setStateData('errorMessage', null)}></AuctionSnackBar>}
            {restPwd.showSuccessMessage &&
                <AuctionSnackBar show={restPwd.showSuccessMessage} message={restPwd.successMessage} severity="success" reset={() => setStateData('successMessage', null)}></AuctionSnackBar>}
            {!isRefresh && <AuctionReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
        </RootDiv>
    )
}

export default ForgotPassword
