import React, { Fragment } from 'react';
import { Tooltip } from '@mui/material';
import { withStyles }               from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    },
}))(Tooltip);

const FieldHelperText = ({helperText, width, fontSize}) => {
    return (
        <HtmlTooltip title={
                <Fragment>
                    {helperText}
                </Fragment>
            }
            arrow
        >
            <InfoIcon style={{width: width ? width : '16px', fontSize: fontSize ? fontSize : 'inherit'}} />
        </HtmlTooltip>
    )
}

export default FieldHelperText