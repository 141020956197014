import { useLazyQuery } from '@apollo/client';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dialog, DialogActions, DialogTitle, FormControl, IconButton, InputLabel, ListItemIcon, OutlinedInput, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LIST_ACTIVITY, LIST_EVENT, LIST_USERS } from '../GraphQL/Queries'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationId } from '../common/StaticVariableDeclaration';
import { Close } from '@mui/icons-material';
import ReportGenerate from './ReportGenerate';
import { useServerCurrentDateTime } from '../utils/Utils';

const useStyles = makeStyles((theme) => ({
  close: {
    '&:hover': {
      backgroundColor: "lightGray",
    }
  },
  dialogTitle: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    backgroundColor: 'aliceblue',
    color: 'black',
    padding: '10px !important'
  },
  title: {
    flexGrow: 1,
    padding: '4px 10px !important',
  },
  dialogActions: {
    padding: '8px 25px !important',
    justifyContent: 'space-between !important'
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

function AuditFilter(props) {
  const { open, setOpen, date, auctionId, onSearch, onClose, filterValues, setFilterValues, setIsFilterApplied, page, itemPerPage, generateRpt, onSuccess } = props;
  const classes = useStyles();
  const [arry] = useState(['asc', 'desc'])
  const [listActivity, setListActivity] = useState([]);
  const [listEvent, setListEvent] = useState([]);
  const [listUsers, setListUsers] = useState([])
  const serverCurrentTime = useServerCurrentDateTime();
  // const [eventName, setEventName] = useState(filterValues.eventCd ? filterValues.eventCd : []);
  // const [activityName, setActivityName] = useState(filterValues.activityCd ? filterValues.activityCd : []);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(filterValues.startRange !== null ? filterValues.startRange : date);
  const [endDate, setEndDate] = useState(filterValues.endRange !== null ? filterValues.endRange : (serverCurrentTime));
  const [selectedUser, setSelectedUser] = useState(filterValues.rowCreatedBy ? filterValues.rowCreatedBy : []);
  const [selectedDescription, setSelectedDescription] = useState(filterValues.description ? filterValues.description : '');
  const [sortOrder, setSortOrder] = useState(filterValues.sortOrder ? filterValues.sortOrder : 'desc');
  const [selectedActivity, setSelectedActivity] = useState(filterValues.activityCd ? filterValues.activityCd : []);
  const [selectedEvent, setSelectedEvent] = useState(filterValues.eventCd ? filterValues.eventCd : []);
  const isAllSelected =
    listEvent.length > 0 && selectedEvent.length === listEvent.length;
  const isAllActivitySelected =
    listActivity.length > 0 && selectedActivity.length === listActivity.length;

  const handleChangeEvent = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedEvent(selectedEvent.length === listEvent.length ? [] : listEvent.map(item => item.eventCd));
      return;
    }
    setSelectedEvent(value);
  };

  const handleChangeActivity = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedActivity(selectedActivity.length === listActivity.length ? [] : listActivity.map(item => item.activityCd));
      return;
    }
    setSelectedActivity(value);
  };

  const handleChangePerform = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUser(typeof value === 'string' ? value.split(',') : value);
  };

  const onChangeDescription = (event) => {
    setSelectedDescription(event.target.value);
  }

  const handleChangefilter = (event) => {
    onSearch({
      eventCd: selectedEvent,
      activityCd: selectedActivity,
      rowCreatedBy: selectedUser,
      description: selectedDescription,
      startRange: startDate === null ? date : startDate,
      endRange: endDate === null ? serverCurrentTime : endDate,
      sortOrder: sortOrder
    })
    setOpen(false);
  }

  const onClearFields = () => {
    setSelectedEvent([])
    setSelectedActivity([])
    setStartDate(date)
    setEndDate(serverCurrentTime)
    setSelectedUser([])
    setSelectedDescription('')
    setSortOrder('desc')
    // setIsFilterApplied(false)
    // setFilterValues({
    //   eventCd: [],
    //   activityCd: [],
    //   rowCreatedBy: [],
    //   description: '',
    //   startRange: null,
    //   endRange: null,
    //   sortOrder: 'desc',
    //   isReset: true
    // })
  }

  const [userListActivity] = useLazyQuery(LIST_ACTIVITY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setListActivity(
        data.listActivity
      )
    }
  })

  const [userListEvent] = useLazyQuery(LIST_EVENT, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setListEvent(
        data.listEvent
      )
    }
  })

  const [userListUsers] = useLazyQuery(LIST_USERS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setListUsers(
        data.listUser
      )
    },

  })

  const handleClose = () => {
    onClose()
  };

  const changeAsc = (event, newValue) => {
    setSortOrder(newValue)
  }

  const getEventRenderValue = (list) => {
    if(listEvent.length > 0) {
      return list?.map(data => listEvent?.find(item => item.eventCd == data).code);
    } else {
      return [];
    }
  }

  const getActivityRenderValue = (list) => {
    if(listActivity.length > 0) {
      return list.map(data => listActivity.find(item => item.activityCd == data).code);
    } else {
      return [];
    }
  }

  useEffect(() => {
    userListActivity()
    if (listEvent.length === 0) {
      userListEvent({
        variables: {
          applicationId: ApplicationId.eAuction
        }
      })
    }
    userListUsers({
      variables: {
        auditOf: auctionId
      }
    })
  }, [open])

  return (
    <div>
      <Dialog id='dialog' fullWidth open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle} >
                  <Typography id='audit-name' variant="h5" component="div" className={classes.title}>
                    {generateRpt ? t('Generate_Audit_Report') : t('Audit_Trail_Search')}
                  </Typography>
                  <Tooltip title="Close">
                    <IconButton
                      id='close-icon'
                      color="secondary"
                      onClick={() => handleClose()}>
                      <Close color='error' />
                    </IconButton>
                  </Tooltip>
              </DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" spacing={2} py={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                id='event-textfield'
                size='small'
                fullWidth
                variant="outlined"
                select
                label={t("Event")}
                SelectProps={{
                  multiple: true,
                  value: selectedEvent,
                  onChange: handleChangeEvent,
                  renderValue: (selected) => getEventRenderValue(selected).join(", ")
                }}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : ""
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selectedEvent.length > 0 && selectedEvent.length < listEvent.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="All Events"
                  />
                </MenuItem>
                {listEvent?.map((event) => (
                  <MenuItem key={event.eventCd} value={event.eventCd}>
                    <ListItemIcon>
                      <Checkbox checked={selectedEvent.includes(event.eventCd)} />
                      {/* <Checkbox checked={selected.indexOf(event) > -1} /> */}
                    </ListItemIcon>
                    <ListItemText primary={event.code} />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id='activity-textfield'
                size='small'
                fullWidth
                variant="outlined"
                select
                label={t("Activity")}
                SelectProps={{
                  multiple: true,
                  value: selectedActivity,
                  onChange: handleChangeActivity,
                  renderValue: (selected) => getActivityRenderValue(selected).join(", ")
                }}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllActivitySelected ? classes.selectedAll : ""
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllActivitySelected}
                      indeterminate={
                        selectedActivity.length > 0 && selectedActivity.length < listActivity.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="All Activity"
                  />
                </MenuItem>
                {listActivity?.map((activity) => (
                  <MenuItem key={activity.activityCd} value={activity.activityCd}>
                    <ListItemIcon>
                      <Checkbox checked={selectedActivity.includes(activity.activityCd)} />
                      {/* <Checkbox checked={selected.indexOf(event) > -1} /> */}
                    </ListItemIcon>
                    <ListItemText primary={activity.code} />
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  closeOnSelect
                  ampmInClock
                  // disablePast
                  showToolbar
                  minDateTime={new Date(date)}
                  maxDateTime={serverCurrentTime}
                  timeIcon={<AccessTimeIcon />}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  value={startDate}
                  label="Start Date"
                  OpenPickerButtonProps={{ id: `audit-start-time-picker` }}
                  renderInput={(props) =>
                    <TextField
                      id="audit-start-date"
                      fullWidth
                      variant='outlined'
                      size='small'
                      {...props}
                      inputProps={{ ...props.inputProps, readOnly: true }}
                    />
                  }
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    if (new Date(newValue).getTime() >= new Date(endDate).getTime()) {
                      setEndDate(null)
                    }
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  closeOnSelect
                  ampmInClock
                  disableIgnoringDatePartForTimeValidation
                  // disablePast
                  showToolbar
                  minDateTime={new Date(startDate)}
                  maxDateTime={serverCurrentTime}
                  timeIcon={<AccessTimeIcon />}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  value={endDate}
                  label="End Date"
                  OpenPickerButtonProps={{ id: `audit-end-time-picker` }}
                  renderInput={(props) =>
                    <TextField
                      id="audit-end-date"
                      fullWidth
                      variant='outlined'
                      size='small'
                      // helperText={'invalid date'}
                      {...props}
                      inputProps={{ ...props.inputProps, readOnly: true }}
                    />
                  }
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={2} >
              <TextField
                size='small'
                fullWidth
                id="performed-by"
                select
                label={t('Performed_By')}
                SelectProps={{
                  multiple: true,
                  value: selectedUser,
                  onChange: handleChangePerform,
                  renderValue: (selected) => {
                    let displayPerformTypes = [];
                    for (let p = 0; p < selected.length; p++) {
                      let user = listUsers.find((pType) => pType.loginId === selected[p]);
                      if (user) {
                        displayPerformTypes.push(user.name)
                      }
                    }
                    return displayPerformTypes.join(', ');
                  }

                }}
                variant="outlined"
              >
                {listUsers?.map((user) => (
                  <MenuItem key={user.id} value={user.loginId}>
                    <Checkbox checked={selectedUser.includes(user.loginId)} />
                    <ListItemText primary={user.name} />
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                options={arry}
                fullWidth
                onChange={changeAsc}
                value={sortOrder}
                id="sort-by"
                renderInput={(params) => (
                  <TextField {...params} label="Sort By" variant="outlined" id='sort' size='small' />
                )}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <TextField id="description" label="Description" variant="outlined" size='small' style={{ width: 550 }}
                value={selectedDescription}
                onChange={onChangeDescription}
              />
            </Stack>
          </Stack>
          {generateRpt && <ReportGenerate auctionId={props.auctionId} selectedEvent={selectedEvent} selectedActivity={selectedActivity} date={date} selectedUser={selectedUser} selectedDescription={selectedDescription} startDate={startDate} endDate={endDate}
            page={page} itemPerPage={itemPerPage} onClose={handleClose} setOpen={setOpen} onSuccess={onSuccess} onClearFields={onClearFields} />}
        </DialogContent>
        {!generateRpt && <DialogActions className={classes.dialogActions}>
          <Button id='resetButton' styles={{ float: "left" }} variant='outlined' onClick={onClearFields}>
            {t("Reset")}
          </Button>
          <Button id='auditFilterSearch' styles={{ float: "right" }} color="primary" variant='contained' onClick={handleChangefilter}
            disabled={startDate == null || endDate == null}>
            {t("Search")}
          </Button>
        </DialogActions>}
      </Dialog>
    </div>
  );
}

export default AuditFilter