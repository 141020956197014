import React, { Component, Fragment } from 'react';
import {
    TextField, Typography, Button,
    Grid, Link, CircularProgress, InputAdornment,
    IconButton, Card, Box, FormControlLabel, Checkbox,
    ButtonGroup
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import MuiPhoneNumber from "material-ui-phone-number";
import { Auth } from 'aws-amplify';
import AuctionSnackBar from '../common/AuctionSnackBar';
import * as Validators from '../common/form-validators.js';
import { JustifyBox, RootDiv, IMG } from './AuthStyle';
import { withTranslation } from 'react-i18next';
import PasswordPolicy, {
    NumberCheck, UpperLetter,
    LowerLetter, SpecialChar, MinPwdLength, MaxPwdLength
} from '../common/PasswordPolicy';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import withStyles from '@mui/material';
import PoweredBy from './PoweredBy';
import TermsAndConditions from './TermsAndCondition';
import axios from 'axios';
import { ApplicationClientId, AppHostDomain } from '../common/StaticVariableDeclaration';
import AuctionReCaptcha from '../common/AuctionReCaptcha';
import { AuthAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import NbidTermsAndConditions from './NbidTermsAndConditions';
import { HomePageLink } from '../home/HomePageLink';

const styles = {
    labelAsterisk: {
        color: "red"
    }
};

export class Register extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            username: "",
            email: "",
            phonenumber: "",
            password: "",
            open: false,
            pwdLength: false,
            maxPwdLength: false,
            isNumber: false,
            isLower: false,
            isUpper: false,
            isSpecial: false,
            confirmpassword: "",
            loading: false,
            showPassword: false,
            showConfirmPassword: false,
            acceptterms: false,
            showTermsAndConditions: false,
            cognito: {
                showMsg: false,
                message: null,
                severity: null
            },
            errors: {
            },
            isValidForm: false,
            refreshCaptcha: false,
            captcha: '',
            individual: true
        };
    }

    toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

    toggleShowConfirmPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

    handlePopoverOpen = () => this.setState({ open: true });

    handlePopoverClose = () => this.setState({ open: false });

    signIn = () => {
        this.props.history.push("/sign-in");
    }

    handleSubmit = event => {
        event.preventDefault();
        this.validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                this.signUp();
            }
        })
    }

    validate = {
        name: name => Validators.requiredValidation('Name', name),
        password: password => Validators.requiredValidation('Password', password),
        confirmpassword: confirmpassword => (Validators.requiredValidation('Confirm Password', confirmpassword)
            || Validators.equalValidation('Confirm Password', this.state.password, confirmpassword)),
        email: email => (Validators.requiredValidation('Username', email) || Validators.emailValidation(email)),
        phonenumber: phonenumber => (Validators.requiredValidation('Mobile Number', phonenumber) || Validators.mobileNumberValidation(phonenumber))
    };

    showTermsAndCond = (event) => {
        this.setState({
            showTermsAndConditions: true
        });
    }

    onCheckboxChange = (isAccept) => {
        this.setState({
            acceptterms: isAccept,
            showTermsAndConditions: false
        });
    }

    validateForm = async () => {
        this.setState(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        Object.entries(this.state).map(([key]) => {
            if (key === 'email' || key === 'name' || key === 'phonenumber' || key === 'password' || key === 'confirmpassword') {
                let newError = this.validate[key](this.state[key])
                if(key === 'password' && !newError) {
                    newError = (!this.state.pwdLength && 'Minimum length should be 8 character') ||
                    (!this.state.maxPwdLength && 'Maximum length should be 50 character') ||
                    (!this.state.isUpper && 'Provide Atleast one uppercase letter') ||
                    (!this.state.isLower && 'Provide Atleast one lowercase letter') ||
                    (!this.state.isNumber && 'Provide Atleast one number') ||
                    (!this.state.isSpecial && 'Provide Atleast one special character')
                }
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    }
                }
            }
        })
        this.setState(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    signUp = async () => {
        this.setState({ loading: true });
        this.setState({ cognito: { showMsg: false, message: null, severity: null } });
        const { password, email, name, phonenumber, captcha, individual } = this.state;
        let payload = {
            firstName: name,
            email: email,
            phoneNumber: phonenumber,
            password: password,
            registeredClientId: ApplicationClientId.eAuction,
            authorities: ['SCOPE_read'],
            individual: individual
        }
        AuthAxiosAuctionInterceptor.post(`user/create-account-with-captcha?recaptcha=${captcha}`, payload).then(response => {
            this.setState({ loading: false });
            this.props.history.push({
                pathname: '/sign-up/success',
                state: { emailid: email, phoneNumber: phonenumber, isVerifyLater: true }
            })
        }).catch(error => {
            this.setState({
                loading: false,
                refreshCaptcha: true
            });
            setTimeout(() => {
                this.setState({
                    refreshCaptcha: false
                });
            }, 1000);
            if (error.message) {
                this.setState({ cognito: { showMsg: true, message: error.message, severity: 'error' } });
            } else {
                this.setState({ cognito: { showMsg: true, message: 'Try again', severity: 'error' } });
            }
        })
        // try {
        //     Auth.signUp({
        //         username: email,
        //         password: password,
        //         attributes: {
        //             email: email,
        //             name: name,
        //             phone_number: phonenumber
        //         }
        //     }).then(res => {
        //         this.setState({ loading: false });
        //         this.props.history.push({
        //             pathname: '/sign-up/success',
        //             state: { emailid: email, phoneNumber: phonenumber, isVerifyLater: true }
        //         })
        //     }, error => {
        //         this.setState({ loading: false });
        //         this.setState({ cognito: { showMsg: true, message: error.message, severity: 'error' } });
        //         console.log(this.state.cognito);
        //     });
        // } catch (error) {
        //     this.setState({ loading: false });
        //     this.setState({ cognito: { showMsg: true, message: error.message, severity: 'error' } });
        // }
    }

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onInputNameChange = event => {
        let value = event.target.value.trimStart();
        this.setState({
            [event.target.id]: value.replace(/  +/g, ' ')
        });
    }

    onInputEmailChange = event => {
        let value = event.target.value.trim();
        this.setState({
            [event.target.id]: value !== '' ? value.toLowerCase() : ''
        })
    }

    onMobileNumberChange = (fieldName, value) => {
        this.setState({
            [fieldName]: value
        });
    }

    onPasswordChange = event => {
        let pwdValue = event.target.value;
        this.setState(prevState => ({
            [event.target.id]: pwdValue,
            pwdLength: pwdValue.length > MinPwdLength ? true : false,
            maxPwdLength: pwdValue.length < MaxPwdLength ? true : false,
            isNumber: NumberCheck.test(pwdValue) ? true : false,
            isUpper: UpperLetter.test(pwdValue) ? true : false,
            isLower: LowerLetter.test(pwdValue) ? true : false,
            isSpecial: SpecialChar.test(pwdValue) ? true : false,
            open: event.target
        }));
    }

    setCaptcha = event => {
        this.setState({
            captcha: event
        });
    }

    onPartyTypeChange = (value) => {
        this.setState({
            individual: value
        });
    }

    render() {
        const { t } = this.props;
        let { pwdLength, isNumber, isUpper, isSpecial, open, isLower, maxPwdLength } = this.state
        return (
            <RootDiv>
                <Card className="card">
                    <Grid container>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle &&
                                <JustifyBox p={4} height="100%" style={{ background: 'rgba(0, 0, 0, 0.01)', }}>
                                    <IMG
                                        src={process.env.PUBLIC_URL + '/posting_photo.svg'}
                                        alt="Sign Up image"
                                    />
                                </JustifyBox>
                            }
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid &&
                                <JustifyBox p={4} height="30%" style={{ background: 'rgba(0, 0, 0, 0.01)', alignItems: 'baseline'}}>
                                    <img src={process.env.PUBLIC_URL + './images/nesl-pda.png'} height={50} alt='NeslPDALogo'></img>
                                </JustifyBox>
                            }
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid &&
                                <JustifyBox p={4} height="70%" style={{ background: 'rgba(0, 0, 0, 0.01)', alignItems: 'baseline'}}>
                                    <IMG
                                        src={process.env.PUBLIC_URL + '/posting_photo.svg'}
                                        alt="Sign Up image"
                                    />
                                </JustifyBox>
                            }
                        </Grid>
                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <Box p={4}>
                                <Grid item xs={12} className="text-center" sx={{ marginLeft: '40px'}} >
                                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + './images/auction.svg'} height={50} alt='E-AuctionLogo'></img> }
                                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + './images/nbid.png'} height={45} alt='NbidLogo'></img> }
                                    <HomePageLink />
                                </Grid>
                                <Typography component="h1" variant="h5" className="text-center" style={{padding: "4px"}}>
                                    {t('Sign_Up')}
                                </Typography>
                                <form onSubmit={this.handleSubmit} noValidate style={{ width: '100%' }}>
                                    <Grid container className="text-center" style={{padding: '12px 0px 0px 0px'}}>
                                        <Grid item xs={12}>
                                            <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                                                <Button id="individual" color="primary" variant={this.state.individual ? "contained" : "outlined"}
                                                    onClick={() => this.onPartyTypeChange(true)}>{t('Individual')}</Button>
                                                <Button id="institute" color="primary" variant={!this.state.individual ? "contained" : "outlined"}
                                                    onClick={() => this.onPartyTypeChange(false)}>{t('Institution')}</Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField id="name" label={t('Name')} style={{ margin: '8px 8px 8px 0px' }}
                                                fullWidth value={this.state.name}
                                                variant='standard'
                                                onChange={this.onInputNameChange}
                                                // error={this.state.errors['name'] !== undefined}
                                                required
                                                helperText={this.state.errors['name']}
                                                disabled={this.state.loading}
                                                InputLabelProps={{
                                                    FormLabelClasses: {
                                                        required: { color: "#db3131 !important" }
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                            <TextField id="email" label={t('Username_Email_Address')}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth value={this.state.email}
                                                variant='standard'
                                                onChange={this.onInputEmailChange} helperText={this.state.errors['email']}
                                                // error={this.state.errors['email'] !== undefined}
                                                autoComplete="off" required
                                                disabled={this.state.loading}
                                            />
                                            <MuiPhoneNumber
                                                id="phonenumber"
                                                name="phonenumber"
                                                label={t('Mobile_Number')}
                                                defaultCountry={"in"}
                                                value={this.state.phonenumber}
                                                helperText={this.state.errors['phonenumber']}
                                                // error={this.state.errors['phonenumber'] !== undefined}
                                                onChange={(event) => this.onMobileNumberChange('phonenumber', event)}
                                                autoFormat={false}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                                required
                                                autoComplete="off"
                                                countryCodeEditable={false}
                                                disabled={this.state.loading} disableDropdown={this.state.loading}
                                            />
                                            <TextField id="password" label={t('Password')} type={this.state.showPassword ? "text" : "password"}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth value={this.state.password}
                                                onChange={this.onPasswordChange} helperText={this.state.errors['password']}
                                                variant='standard'
                                                // error={this.state.errors['password'] !== undefined}
                                                // onClick={this.handlePopoverOpen}
                                                onBlur={this.handlePopoverClose}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                id='eyepwdvisibility'
                                                                aria-label="toggle password visibility"
                                                                onClick={this.toggleShowPassword}
                                                            >
                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                autoComplete="off" required disabled={this.state.loading}
                                            />
                                            <PasswordPolicy open={open} pwdLength={pwdLength} isUpper={isUpper} isLower={isLower} isNumber={isNumber} isSpecial={isSpecial} maxPwdLength={maxPwdLength} />
                                            <TextField id="confirmpassword" label={t('Confirm_Password')} type={this.state.showConfirmPassword ? "text" : "password"}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth value={this.state.confirmpassword}
                                                onChange={this.onInputChange} helperText={this.state.errors['confirmpassword']}
                                                variant='standard'
                                                // error={this.state.errors['confirmpassword'] !== undefined}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                id='eyeconfirmvisibility'
                                                                aria-label="toggle password visibility"
                                                                onClick={this.toggleShowConfirmPassword}
                                                            >
                                                                {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                autoComplete="off"
                                                required disabled={this.state.loading}
                                            />
                                            <FormControlLabel control={
                                                <Checkbox onChange={this.showTermsAndCond}
                                                    id="accept-terms" checked={this.state.acceptterms} color="primary" style={{ margin: '8px 8px 8px 0px' }}
                                                />
                                            }
                                                label="Accept Terms & Conditions" disabled={this.state.loading}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" id="signup" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                                disabled={this.state.loading || !this.state.acceptterms}>
                                                {t('Sign_Up')}
                                                {this.state.loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} container justifyContent="center">
                                            <Typography variant="body2">
                                                {t('Already_have_an_account_?')} <Link onClick={this.signIn} className="pointer">{t('Sign_in')}</Link>
                                            </Typography>
                                        </Grid>
                                        { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle &&
                                            <Grid item xs={12} container marginTop="10px" justifyContent="center">
                                                <PoweredBy />
                                            </Grid>
                                        }
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                {this.state.loading && <AuctionLoader show={this.state.loading} />}
                {this.state.showTermsAndConditions && <Fragment>
                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle ? 
                        <TermsAndConditions show={this.state.showTermsAndConditions} acceptTermsAndConditions={this.onCheckboxChange} />
                    :
                        <NbidTermsAndConditions show={this.state.showTermsAndConditions} acceptTermsAndConditions={this.onCheckboxChange} />
                    }
                </Fragment>}
                   
                {this.state.cognito.showMsg && <AuctionSnackBar show={this.state.cognito.showMsg} message={this.state.cognito.message}
                    severity={this.state.cognito.severity}></AuctionSnackBar>}
                {!this.state.refreshCaptcha && <AuctionReCaptcha captcha={this.setCaptcha} refresh={() => this.setState({
                    refreshCaptcha: false
                })} />}
            </RootDiv>
        )
    }
}

export default withTranslation()(Register);