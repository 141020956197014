import React, { 
    Fragment,
    useContext,
    useEffect
}                                       from 'react';
import { 
    Controller,
    useWatch
}                                       from 'react-hook-form';
import {
    Grid,
    TextField,
    MenuItem
}                                       from '@mui/material';
import { useTranslation }               from 'react-i18next'; 
import { Validation }                   from '../../common/Validation';
import { NumberFormatController }       from '../../custom-form-control/NumberFormatController';
import { SelectControl }                from '../../custom-form-control/SelectControl';
import { UserContext }                  from "../../common/context/UserContext";
import { AutoExtensionMode, MannerOfSale }                 from '../../common/StaticVariableDeclaration'; 
import { DateTimePickerControl }        from '../../custom-form-control/DateTimePickerControl';

const LotInput = ({register, control, errors, setValue, getValues, trigger, mannerOfSale,
        auctionStartDate, auctionEndDate}) => {
    const { t } = useTranslation();
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const autoExtension = useWatch({ control, name: 'autoExtension' });
    const autoExtensionMode = useWatch({ control, name: 'autoExtensionMode' });
    const startTime = useWatch({ control, name: 'startTime' });

    const autoExtensionOption = [
        { id: '01', value: true, description: 'Yes' },
        { id: '02', value: false, description: 'No' }
    ];

    useEffect(() => {
        if(!autoExtension) {
            setValue('autoExtensionMode', null);
            setValue('extendTimeWhenBidReceivedInLastMinute', 0);
            setValue('extendTimeBy', 0);
            setValue('incrementalAmountDuringExtension', 0);
            setValue('noOfExtension', 0);
            trigger('autoExtension');
            trigger('autoExtensionMode');
            trigger('extendTimeWhenBidReceivedInLastMinute');
            trigger('extendTimeBy');
            trigger('incrementalAmountDuringExtension');
            trigger('noOfExtension');
        }
    }, [autoExtension])

    useEffect(() => {
        if(autoExtensionMode === AutoExtensionMode.unlimitedExtension) {
            setValue('noOfExtension', 0);
            trigger('noOfExtension');
        }
    }, [autoExtensionMode])
    
    return (
        <div>
            <Grid container spacing={2}>
              {(mannerOfSale !== MannerOfSale.item && mannerOfSale !== MannerOfSale.goingConcern) && <Grid item xs={4}>
                  <Controller
                      name="lotNumber"
                      control={control}
                      rules={Validation.required}
                      render={({ field }) => (
                      <TextField
                          {...field}
                          id="lotNumber"
                          label={t('Lot_Number')}
                          variant="standard"
                          fullWidth
                          helperText={errors?.lotNumber?.message}
                          InputLabelProps={{ shrink: field.value }}  
                          required
                      />
                      )}
                  />
              </Grid>}
              <Grid item xs={4}>
                  <NumberFormatController 
                      mandatory={true}
                      errors={errors}
                      control={control}
                      name="reservePrice"
                      rules={Validation.minAmount}
                      label={t('Reserve_Price')}
                  />
              </Grid>
              <Grid item xs={4}>
                  <NumberFormatController 
                      mandatory={true}
                      errors={errors}
                      control={control}
                      name="emdAmount"
                      rules={Validation.minAmount}
                      label={t('EMD_Amount')}
                      required
                  />
              </Grid>
              <Grid item xs={4}>
                  <DateTimePickerControl
                        mandatory={false}
                        errors={errors}
                        control={control}
                        name="lastDateForEmd"
                        label={t('Last_Date_For_Emd_Amount')}
                        // minDateTime={}
                        disablePastDate={true}
                    />
              </Grid>
              <Grid item xs={4}>
                  <SelectControl
                      mandatory={true}
                      name="autoExtension"
                      control={control}
                      errors={errors}
                      label={t('Auto_Extension')}
                      setValue={setValue}
                      getValues={getValues}
                  >
                      {autoExtensionOption.map(item => (
                          <MenuItem value={item.value} key={item.id}>{item.description}</MenuItem>
                      ))}
                  </SelectControl>
              </Grid>
              {autoExtension && <Grid item xs={4}>
                  <SelectControl
                      mandatory={true}
                      name="autoExtensionMode"
                      control={control}
                      rules={Validation.selectRequired}
                      errors={errors}
                      label={t('Auto_Extension_Mode')}
                      setValue={setValue}
                      getValues={getValues}
                  >
                      {enumListOptions.AutoExtensionMode.map(item => (
                          <MenuItem value={item.dateBaseCode} key={item.id}>{item.uiCode}</MenuItem>
                      ))}
                  </SelectControl>
              </Grid>}
              { autoExtensionMode == AutoExtensionMode.limitedExtension && <Grid item xs={4}>
                        <TextField id="noOfExtension" label={t('No_Of_Extensions')} fullWidth required
                            helperText={errors?.noOfExtension && errors?.noOfExtension.message}
                            placeholder={t('No_Of_Extensions')} type="text" variant='standard'
                            {...register('noOfExtension', Validation.number)}
                        />
                    </Grid>}
              {autoExtension && <Grid item xs={4}>
                  <TextField id="extendTimeWhenBidReceivedInLastMinute" label={t('Extend_Time_When_Bid_Received_Last_Minute')} fullWidth required
                      helperText={errors?.extendTimeWhenBidReceivedInLastMinute && errors?.extendTimeWhenBidReceivedInLastMinute?.message}
                      placeholder={t('Extend_Time_When_Bid_Received_Last_Minute')} type="text" variant='standard'
                      {...register("extendTimeWhenBidReceivedInLastMinute", Validation.number)}
                  />
              </Grid>}
              {autoExtension && <Grid item xs={4}>
                  <TextField id="extendTimeBy" label={t('Extend_Time_By')} fullWidth required
                      helperText={errors?.extendTimeBy && errors?.extendTimeBy?.message}
                      placeholder={t('Extend_Time_By')} type="text" variant='standard'
                      {...register("extendTimeBy", Validation.number)}
                  />
              </Grid>}
              <Grid item xs={4}>
                  <NumberFormatController 
                      mandatory={false}
                      errors={errors}
                      control={control}
                      name="bidStartPrice"
                      rules={Validation.amount}
                      label={t('Bid_Start_Price')}
                  />
              </Grid>
              <Grid item xs={4}>
                  <NumberFormatController 
                      mandatory={false}
                      errors={errors}
                      control={control}
                      name="incrementalAmount"
                      rules={Validation.amount}
                      label={t('Incremental_Amount')}
                  />
              </Grid>
              {autoExtension && <Grid item xs={4}>
                    <NumberFormatController 
                        mandatory={false}
                        errors={errors}
                        control={control}
                        name="incrementalAmountDuringExtension"
                        rules={Validation.amount}
                        label={t('Incremental_Amount_During_Extension')}
                  />
              </Grid>}
              {mannerOfSale === MannerOfSale.lot && <Fragment>
                    <Grid item xs={4}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="startTime"
                            label={t('Bidding_Start_Date_And_Time')}
                            minDateTime={auctionStartDate}
                            maxDateTime={auctionEndDate}
                            disablePastDate={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="endTime"
                            label={t('Bidding_End_Date_And_Time')}
                            minDateTime={startTime ? startTime : auctionStartDate}
                            maxDateTime={auctionEndDate}
                            disablePastDate={true}
                        />
                    </Grid>
                </Fragment>}
              <Grid item xs={12}>
                  <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                      <TextField
                          {...field}  
                          id="description"
                          label={t('Description')}
                          variant="standard"
                          fullWidth
                          multiline
                          rows={2}
                          helperText={errors?.description?.message}
                          InputLabelProps={{ shrink: field.value }}  
                      />
                      )}
                  />
              </Grid>
            </Grid>
        </div>
    )
}

export default LotInput;