import {
    Divider, Grid, IconButton, InputAdornment, Select, TextField,
    FormControlLabel, Checkbox
} from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../common/AuctionSnackBar';
import { ApplicationId } from '../common/StaticVariableDeclaration';
import { AuctionAxiosDownloadInterceptor } from '../config/AxiosAuctionInterceptor';
import { useServerCurrentDateTime } from '../utils/Utils';

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        padding: '8px 25px !important',
        justifyContent: 'space-between !important'
    },
    button: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    },
    divider: {
        margin: '0px -21px !important',
        padding: '10px',
    }
}));

function ReportGenerate(props) {
    const { onClose, open, setOpen, auctionId, selectedEvent, selectedActivity, date,
        selectedUser, selectedDescription, startDate, endDate, page, itemPerPage, onSuccess, onClearFields } = props;
    const classes = useStyles();
    const serverCurrentTime = useServerCurrentDateTime();
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        message: '',
        show: false,
        severity: ''
    });

    const handleClose = () => {
        onClose(false);
    }

    const [reportDetails, setReportDetails] = useState({
        reportType: 'PDF_A',
        password: '',
        watermark: '',
        opaque: '0.3',
        digitalSign: false
    })

    const handleChange = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }))
    }

    const handleBooleanChange = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.checked
        }))
    }

    const generateReport = () => {
        setLoading(true)
        setMessage({ show: false, message: '', severity: 'error' });
        let reportType = reportTypes.find(reportType => reportType.value === reportDetails.reportType);
        let payload = {
            activityCd:
                selectedActivity
            ,
            rowCreatedBy:
                selectedUser
            ,
            description: selectedDescription,
            startRange: startDate === null ? date : startDate,
            endRange: endDate === null ? serverCurrentTime : endDate,
            eventCd: selectedEvent,
            page: page,
            size: itemPerPage,
            asc: true,
            report: {
                reportType: reportDetails.reportType,
                appName: process.env.REACT_APP_NAME,
                template: ApplicationId.auditTrial,
                barCode: false,
                digitalSign: reportDetails.digitalSign,
                password: reportDetails.password,
                watermark: reportDetails.watermark ? {
                    text: reportDetails.watermark,
                    opaque: reportDetails.opaque
                } : null,
                reportOf: auctionId,
                presignedUrl: false,
                reportCd: 1
            }
        }
        AuctionAxiosDownloadInterceptor.post(`audit/audit-report/${auctionId}`,
            payload
        ).then((response) => {
            setLoading(false)
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url
            link.setAttribute('download', `Audit Trail Report.${reportType.fileType}`);
            document.body.appendChild(link);
            link.click();
            setOpen(false)
            onSuccess({ show: true, message: t('Report_Generated_Successfully'), severity: 'success' });
        }).catch((error) => {
            if (error.message) {
                setLoading(false)
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            }
            else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        })
    }

    const reportTypes = [
        { name: 'PDF', value: 'PDF_A', fileType: 'pdf' },
        // { name: 'PDF_UA', value: 'PDF_UA', fileType: 'pdf' },
        // { name: 'EXCEL', value: 'EXCEL', fileType: 'xls' },
        // { name: 'DOCX', value: 'DOCX', fileType: 'docx' },
        // { name: 'DOC', value: 'DOC', fileType: 'doc' }
    ]

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const handleDataBoolean = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.checked
        }))
    }

    const ResetReportFields = () => {
        onClearFields()
        setReportDetails(prev => ({
            ...prev,
            password: '',
            watermark: ''
        }))

    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Select id="reportType" name="report-type" value={reportDetails.reportType}
                        onChange={(event) => handleChange('reportType', event)} variant="outlined" size='small' fullWidth>
                        {
                            reportTypes.map((reportType, index) => {
                                return <MenuItem value={reportType.value} key={index}>{t(reportType.name)}</MenuItem>
                            })
                        }
                    </Select>
                </Grid>
                {(reportDetails.reportType === 'PDF_A' || reportDetails.reportType === 'PDF_UA') && <Fragment>
                    <Grid item xs={6}>
                        <TextField id="password" label={t('Password')} variant="outlined" size='small' fullWidth
                            type={showPassword ? "text" : "password"}
                            value={reportDetails.password} onChange={(event) => handleChange('password', event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            id='eyepwdvisibility'
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="watermark" label={t('Watermark')} variant="outlined" size='small' fullWidth
                            value={reportDetails.watermark} onChange={(event) => handleChange('watermark', event)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={reportDetails.digitalSign} onChange={(event) => handleBooleanChange('digitalSign', event)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label={t('With_Digital_Signature')} />
                    </Grid>
                </Fragment>}
            </Grid>
            {/* <br></br> */}
            <Divider className={classes.divider} />
            {/* <br></br> */}
            <Grid container style={{ paddingTop: '10px' }}>
                <Grid item xs={12}>
                    <Button id='resetButton' size='small' styles={{ float: "left" }} variant='outlined' onClick={ResetReportFields}>
                        {t("Reset")}
                    </Button>
                    <Button id='generate-report-btn' type="submit" size="small" className={classes.button} variant="contained" styles={{ float: "right" }}
                        onClick={generateReport}>
                        {t('Generate')}
                    </Button>
                </Grid>
            </Grid>
            {loading && <AuctionLoader show={loading} invisible={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} ></AuctionSnackBar>}
        </React.Fragment>
    );
}
export default ReportGenerate
