import React, {
    useState,
    useEffect,
    useContext
}                               from 'react';
import { makeStyles }           from '@mui/styles';
import { useTranslation }       from 'react-i18next';
import {
    Grid,
    Tooltip,
    Fab,
    TextField
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
    useLazyQuery,
    useMutation
} from '@apollo/client';
import {
    GET_BIDDER_DETAILS,
    LIST_BIDDER
} from '../../GraphQL/Queries';
import { UPDATE_BIDDER_DETAIL } from '../../GraphQL/Mutation';
import {
    AuctionFieldSet,
    AuctionLengend
}                               from '../../authentication/AuthStyle';
import Address                  from '../../profile/address/Address';
import ListIdentification       from '../../common/identification/ListIdentification';
import ListPhoneNumber          from '../../contact/phone_number/ListPhoneNumber';
import ListEmail                from '../../contact/email/ListEmail';
import { Check, Clear, Edit }   from '@mui/icons-material';
import { Validation }           from '../../common/Validation';
import { Controller }           from "react-hook-form";
import AuctionLoader            from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors            from '../../common/GraphQLErrors';
import { UserContext }          from '../../common/context/UserContext';

const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: theme.boxShadow
    },
    topFab: {
        width: '34px !important',
        height: '34px !important',
        background: `${theme.palette.background.paper} !important`,
        zIndex: 'unset !important'
    },
    checkFab: {
        border: `1px solid ${theme.palette.success.main} !important`
    },
    closeFab: {
        border: `1px solid ${theme.palette.error.main} !important`
    }
}))

const UpdateBidder = ({ auction_id, bidder_id, canEdit, setBidderName, bidder, bidder_login_id }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false);
    const [tempValue, setTempValue] = useState('');
    const userContext = useContext(UserContext).rootContext;

    const { register, control, getValues, setValue, trigger, reset,
        formState: { errors, isValid } } = useForm({
            mode: 'onChange'
        });

    const [getBidder, { loading: fetchingBidder, error: errorOnFetchingBidder }] = useLazyQuery(GET_BIDDER_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            let bidder = data.getBidder;
            setValue('id', bidder.id);
            setValue('personId', bidder.personId);
            setValue('name', bidder.name, { shouldValidate: true });
            setValue('individual', bidder.individual, { shouldValidate: true });
            setValue('identification', bidder.identification);
            setValue('address', bidder.address);
            setValue('email', bidder.email);
            setValue('phoneNumber', bidder.phoneNumber);
        }
    })

    const [updateBidder, { loading: updatingBidder, error: errorOnUpdatingBidder }] = useMutation(UPDATE_BIDDER_DETAIL, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateBidder != null) {
                let bidder = data.updateBidder;
                setValue('id', bidder.id);
                setValue('personId', bidder.personId);
                setValue('name', bidder.name, { shouldValidate: true });
                setValue('individual', bidder.individual, { shouldValidate: true });
                setIsEdit(false);
                if (setBidderName) {
                    setBidderName(bidder.name);
                }
            }
        }
        // refetchQueries: [LIST_BIDDER]
    })

    useEffect(() => {
        if(auction_id && bidder_id) {
            getBidder({
                variables: {
                    auctionId: auction_id,
                    bidderId: bidder_id
                }
            })   
        }
    }, [auction_id, bidder_id])

    const editBidder = () => {
        setIsEdit(true);
        setTempValue(getValues());
    }

    const handleUpdate = () => {
        updateBidder({
            variables: {
                bidderInput: {
                    personId: getValues('personId'),
                    individual: getValues('individual'),
                    name: getValues('name').trim(''),
                },
                auctionId: auction_id,
                bidderId: bidder_id
            }
        })
    }

    const handleCancel = () => {
        setValue('name', tempValue.name, { shouldValidate: true });
        setTempValue('');
        setIsEdit(false);
    }

    return (
        <div>
            <Grid>
                <AuctionFieldSet>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={Validation.required}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="name"
                                        label={t('Name')}
                                        type="text"
                                        variant="standard"
                                        fullWidth
                                        required={isEdit}
                                        disabled={!isEdit}
                                        helperText={errors?.name?.message}
                                        InputLabelProps={{ shrink: field.value }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} ms={12} lg={6}>
                            {(!isEdit && canEdit &&
                            // bidder_login_id === userContext.userSession.session.userId
                                (bidder_login_id ? false : true)) && <span style={{float: 'right', position: 'relative', bottom: '34px'}}>
                                <Tooltip title={t('Edit')}>
                                    <Fab id="edit-bidder-details" className={`${classes.topFab} ${classes.editFab}`} color='primary'
                                        onClick={editBidder}>
                                        <Edit color='primary' />
                                    </Fab>
                                </Tooltip>
                            </span>}
                            {isEdit && <span style={{float: 'right', position: 'relative', bottom: '34px'}}>
                                <Tooltip title={t('Save')}>
                                    <Fab id="save-bidder-details" className={`${classes.topFab} ${classes.checkFab}`} style={{position: 'relative', right: '10px'}} color='success'
                                        onClick={handleUpdate} disabled={!isValid || updatingBidder}>
                                        <Check color='success' />
                                    </Fab>
                                </Tooltip>
                                <Tooltip title={t('Cancel')}>
                                <Fab id="cancel-bidder-details-update" className={`${classes.topFab} ${classes.closeFab}`} color='error' onClick={handleCancel}>
                                    <Clear color='error' />
                                </Fab>
                                </Tooltip>
                            </span>}
                        </Grid>
                    </Grid>
                </AuctionFieldSet>
            </Grid>
            <br/>
            <Grid conatiner spacing={1} style={{display: 'flex'}}>
                <Grid item xs={4} lg={4} style={{width: '100%'}}>
                    <AuctionFieldSet style={{height: '100%'}}>
                        <AuctionLengend align="left"><strong>{t('Identification')}</strong></AuctionLengend>
                        <div style={{marginTop: '-22px'}}>
                        {getValues('personId') && <ListIdentification individual={getValues('individual')} personId={getValues('personId')}
                            identifications={getValues('identification')} isEdit={canEdit && (bidder_login_id ? bidder_login_id == userContext.userSession.session.userId : true)}
                            loginId={bidder_login_id}/>}
                        </div>
                    </AuctionFieldSet>
                </Grid>
                <Grid item xs={4} lg={4} style={{width: '100%'}}>
                    <AuctionFieldSet style={{height: '100%'}}>
                        <AuctionLengend align="left"><strong>{t('Phone_Number')}</strong></AuctionLengend>
                        <div style={{marginTop: '-22px'}}>
                            {getValues('personId') && <ListPhoneNumber personId={getValues('personId')} phoneNumbers={getValues('phoneNumber')}
                                isEdit={canEdit && (bidder_login_id ? bidder_login_id == userContext.userSession.session.userId : true)}
                                loginId={bidder_login_id}/>}
                        </div>
                    </AuctionFieldSet>
                </Grid>
                <Grid item xs={4} lg={4} style={{width: '100%'}}>
                    <AuctionFieldSet style={{height: '100%'}}>
                        <AuctionLengend align="left"><strong>{t('Email')}</strong></AuctionLengend>
                        <div style={{marginTop: '-22px'}}>
                            {getValues('personId') && <ListEmail personId={getValues('personId')} emails={getValues('email')}
                                isEdit={canEdit && (bidder_login_id ? bidder_login_id == userContext.userSession.session.userId : true)}
                                loginId={bidder_login_id}/>}
                        </div>
                    </AuctionFieldSet>
                </Grid>
            </Grid>
            <br/>
            <Grid container>
                <Grid item xs={12}>
                    <AuctionFieldSet>
                        <AuctionLengend align="left"><strong>{t('Address')}</strong></AuctionLengend>
                        {getValues('personId') != null && <Address hasPermission={canEdit} isLoginUser={false}
                            // hasPermission={canEdit && ((bidder.loginId !== null && bidder.loginId !== undefined) ? false : true)}
                            sourceId={getValues('personId')} address={getValues('address')} atlestOneMandatory={true} />}
                    </AuctionFieldSet>
                </Grid>
            </Grid>
            {(fetchingBidder || updatingBidder) && <AuctionLoader show={fetchingBidder || updatingBidder} invisible={false} />}
            {errorOnFetchingBidder && <GraphQLErrors error={errorOnFetchingBidder} show={false} />}
            {errorOnUpdatingBidder && <GraphQLErrors error={errorOnUpdatingBidder} show={false} />}
        </div>
    )
}

export default UpdateBidder;