import React, {
    Fragment,
    useState,
    useContext,
    useEffect
} from 'react';
import {
    Container,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Stepper,
    Step,
    StepButton,
    Typography,
    TextField,
    MenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ItemDetail from '../item-detail/ItemDetail';
import ItemBidDetail from '../item-detail/ItemBidDetail';
import { useForm } from "react-hook-form";
import {
    CREATE_ITEM,
    UPDATE_ITEM
} from '../../GraphQL/Mutation';
import {
    LIST_ITEMS,
    LIST_EXISTING_ADDRESS_FOR_ITEM
} from '../../GraphQL/Queries';
import {
    useMutation,
    useLazyQuery
} from '@apollo/client';
import AddressDetail from '../../profile/address/AddressDetail';
import {
    AuctionFieldSet,
    AuctionLengend
} from '../../authentication/AuthStyle';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import ItemImages from '../item-images/ItemImages';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { AddressType } from '../../common/StaticVariableDeclaration';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import {
    AddressFieldsForCommon
} from '../../profile/address/address-common/RequiredFieldsAddress';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '4px !important'
    },
    paper: {
        borderRadius: '0px',
        overflow: 'scroll'
    },
    container: {
        margin: '10px'
    },
    backBtn: {
        float: 'left',
        margin: '0px 4px 0px 4px !important'
    },
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    },
    finishBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const getSteps = (isIndividualBid) => {
    if (isIndividualBid) {
        return ['Item_Details', 'Item_Bid_Detail', 'Item_Images_Videos'];
    } else {
        return ['Item_Details', 'Item_Images_Videos'];
    }
}

const AddItem = ({ show, onClose, isIndividualItemBid, auctionId, lotId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [isIndividualBid, setIsIndividualBid] = React.useState(isIndividualItemBid);
    const steps = getSteps(isIndividualItemBid);
    const auctionContext = useContext(AuctionDetailContext);
    const { register: itemDetailRegister, control: itemDetailControl, getValues: getItemDetailValues,
        setValue: setItemDetailsValues, trigger: itemDetailTrigger, clearErrors: itemDetailClearErrors,
        formState: { errors: itemDetailError, isValid: itemDetailIsValid, dirtyFields } } = useForm({
            mode: 'onChange',
            defaultValues: {
                itemType: [],
                quantity: 1,
                unit: '',
                attention: '',
                phone: '',
                addressLine1: '',
                addressLine2: '',
                postalCode: '',
                addressId: '',
                landmark: '',
                state: '',
                cityTown: '',
                country: 'India'
            }
        });
    const { register: itemBidDetailRegister, getValues: getItemBidDetailValues,
        setValue: setItemBidDetailsValues, control: itemDetailBidControl, trigger: itemBidDetailTrigger,
        formState: { errors: itemBidDetailError, isValid: itemBidDetailIsValid } } = useForm({
            mode: 'onChange',
            defaultValues: {
                reservePrice: 0,
                emdAmount: 0,
                bidStartPrice: 0,
                incrementalAmount: 0,
                autoExtension: false,
                autoExtensionMode: '',
                extendTimeWhenBidReceivedInLastMinute: 0,
                extendTimeBy: 0,
                incrementalAmountDuringExtension: 0
            }
        });
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [existingAddress, setExistingAddress] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const [listItemAddress, { loading: fetchingItemAddresses, error: errorOnFetchingItemAddresses, refetch }] = useLazyQuery(LIST_EXISTING_ADDRESS_FOR_ITEM, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            data.addressList.map((address, index) => {
                setExistingAddress(prevState => ([
                    ...prevState,
                    {
                        addressId: index + 1,
                        addressLine1: address.addressLine1,
                        addressLine2: address.addressLine2,
                        landmark: address.landmark,
                        cityTown: address.cityTown,
                        state: address.state,
                        postalCode: address.postalCode,
                        country: address.country,
                        phone: '',
                        attention: ''
                    }
                ]))
            })
        }
    })

    const [createItem, { loading: creatingItem, error: errorOnCreatingItem }] = useMutation(CREATE_ITEM, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.addItem != null) {
                setItemDetailsValues('id', data.addItem[0].id);
                if (data.addItem[0].address) {
                    setItemDetailsValues('addressId', data.addItem[0].address.addressId);
                    setItemDetailsValues('sourceId', data.addItem[0].address.sourceId);
                }
                setMessage({
                    show: true,
                    message: t('Item_Added_Successfully'),
                    severity: 'success'
                })
                handleNext();
            }
        },
        refetchQueries: [LIST_ITEMS]
    })

    const [updateItem, { loading: updatingItem, error: errorOnUpdatingItem }] = useMutation(UPDATE_ITEM, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateItem != null) {
                handleNext();
                setMessage({
                    show: true,
                    message: t('Item_Updated_Successfully'),
                    severity: 'success'
                })
            }
        },
        refetchQueries: [LIST_ITEMS]
    })

    const handleAddressSelect = (event) => {
        let displayAddress;
        displayAddress = existingAddress.find(address => address.addressId === event.target.value);
        setSelectedAddress(event.target.value)
        setItemDetailsValues('phone', displayAddress.phone, { shouldValidate: true });
        setItemDetailsValues('attention', displayAddress.attention, { shouldValidate: true });
        setItemDetailsValues('addressLine1', displayAddress.addressLine1, { shouldValidate: true });
        setItemDetailsValues('addressLine2', displayAddress.addressLine2, { shouldValidate: true });
        setItemDetailsValues('landmark', displayAddress.landmark, { shouldValidate: true });
        setItemDetailsValues('cityTown', displayAddress.cityTown, { shouldValidate: true });
        setItemDetailsValues('state', displayAddress.state, { shouldValidate: true });
        setItemDetailsValues('postalCode', displayAddress.postalCode, { shouldValidate: true });
        setItemDetailsValues('country', displayAddress.country, { shouldValidate: true });
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <Fragment>
                        <ItemDetail register={itemDetailRegister} errors={itemDetailError}
                            setValue={setItemDetailsValues} control={itemDetailControl}
                            getValues={getItemDetailValues} trigger={itemDetailTrigger}
                            isNew={true} getItemBidDetailValues={getItemBidDetailValues} />
                        <br />
                        <Grid container>
                            <Grid item xs={12}>
                                <AuctionFieldSet>
                                    <AuctionLengend align="left"><strong>{t('Address')}</strong></AuctionLengend>
                                    {existingAddress.length > 0 && <Grid container>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={6} style={{ padding: '6px' }}>
                                            <TextField
                                                fullWidth
                                                id="select-existing-address"
                                                select
                                                label={t('Select_Address_From_Existing_List')}
                                                SelectProps={{
                                                    value: selectedAddress,
                                                    onChange: handleAddressSelect,
                                                    renderValue: (selected) => {
                                                        let displayAddress;
                                                        displayAddress = existingAddress.find(address => address.addressId === selected);
                                                        return (
                                                            <Fragment>
                                                                <span>{displayAddress.addressLine1}</span>
                                                                <span>{displayAddress.addressLine2 && ", " + displayAddress.addressLine2}</span>
                                                                <span>{displayAddress.landmark && ", " + displayAddress.landmark}</span>
                                                                <span>{displayAddress.cityTown && ", " + displayAddress.cityTown}</span>
                                                                <span>{displayAddress.state && ", " + displayAddress.state}</span>
                                                                <span>{displayAddress.postalCode && " - " + displayAddress.postalCode}</span>
                                                                <span>{displayAddress.country && ", " + displayAddress.country}</span>
                                                            </Fragment>
                                                        );
                                                    }
                                                }}
                                                variant="outlined"
                                                size="small"
                                            >
                                                {existingAddress.map((address, index) => (
                                                    <MenuItem key={index} value={address.addressId}>
                                                        {address.addressLine1}
                                                        {address.addressLine2 && ", " + address.addressLine2}
                                                        {address.landmark && ", " + address.landmark}
                                                        {address.cityTown && ", " + address.cityTown}
                                                        {address.state && ", " + address.state}
                                                        {address.postalCode && " - " + address.postalCode}
                                                        {address.country && ", " + address.country}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>}
                                    <AddressDetail register={itemDetailRegister} control={itemDetailControl}
                                        setValue={setItemDetailsValues} getValues={getItemDetailValues}
                                        errors={itemDetailError} isEdit={true}
                                        requiredShowFields={AddressFieldsForCommon}
                                        clearErrors={itemDetailClearErrors}
                                    />
                                </AuctionFieldSet>
                            </Grid>
                        </Grid>
                    </Fragment>
                );
            case 1:
                return (
                    <AuctionFieldSet>
                        <ItemBidDetail register={itemBidDetailRegister} errors={itemBidDetailError}
                            setValue={setItemBidDetailsValues} control={itemDetailBidControl}
                            getValues={getItemBidDetailValues} trigger={itemBidDetailTrigger}
                            auctionStartDate={auctionContext ? new Date(auctionContext.auctionGetDetail.startTime) : null}
                            auctionEndDate={auctionContext ? new Date(auctionContext.auctionGetDetail.endTime) : null}
                            isNew={true} getItemValues={getItemDetailValues}
                        />
                        <br />
                    </AuctionFieldSet>
                );
            case 2:
                return (
                    <Container maxWidth='xl'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ItemImages item_id={getItemDetailValues('id')}
                                    auction_id={auctionId} lot_id={lotId} canUpload={true} />
                            </Grid>
                        </Grid>
                    </Container>

                );
            default:
                return null;
        }
    }

    const getPageActions = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <Grid item xs={12}>
                        {!getItemDetailValues('id') && <Button id='save-and-next' type="submit" size="small" variant="contained"
                            className={classes.nextBtn} onClick={() => create()}
                            disabled={!itemDetailIsValid}
                        >
                            {t('Save_And_Next')}
                        </Button>}
                        {getItemDetailValues('id') && <Button id='next' type="submit" size="small" variant="contained"
                            className={classes.nextBtn} onClick={handleNext}>
                            {t('Next')}
                        </Button>}
                        {getItemDetailValues('id') && <Button id='update-and-next' type="submit" size="small" variant="contained"
                            className={classes.nextBtn} onClick={updateItemValues}
                            disabled={!itemDetailIsValid}>
                            {t('Update_And_Next')}
                        </Button>}
                        <Button id='cancel' size="small" variant="outlined"
                            className={classes.nextBtn} onClick={handleClose} >
                            {t('Cancel')}
                        </Button>
                    </Grid>
                );
            case 1:
                return (
                    <Grid item xs={12}>
                        <Button id='back' size="small" variant="outlined"
                            className={classes.backBtn} onClick={handleBack}>
                            {t('Back')}
                        </Button>
                        <Button id='next' size="small" variant="contained"
                            className={classes.nextBtn} onClick={handleNext} >
                            {t('Next')}
                        </Button>
                        <Button id='save-and-next' type="submit" size="small" variant="contained"
                            className={classes.nextBtn} onClick={updateItemValues}
                            disabled={!itemBidDetailIsValid}>
                            {t('Save_And_Next')}
                        </Button>
                        <Button id='close-bidder-detail' type="submit" size="small" variant="outlined"
                            className={classes.finishBtn} onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Grid>
                );
            case 2:
                return (
                    <Grid item xs={12}>
                        <Button id='back' size="small" variant="outlined"
                            className={classes.backBtn} onClick={handleBack}>
                            {t('Back')}
                        </Button>
                        <Button id='finish' type="submit" size="small" variant="contained"
                            className={classes.finishBtn} onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Grid>
                );
            default:
                return null;
        }
    }

    const handleNext = () => {
        if (!isIndividualBid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (!isIndividualBid) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const create = () => {
        setMessage({ show: false, message: '', severity: 'error' });
        if (validateAddress()) {
            createItem({
                variables: {
                    itemInput: [{
                        itemName: getItemDetailValues('itemName'),
                        itemRefNo: getItemDetailValues('itemRefNo'),
                        description: getItemDetailValues('description'),
                        itemType: getItemDetailValues('itemType'),
                        quantity: Number(getItemDetailValues('quantity')),
                        unit: getItemDetailValues('unit'),
                        address: (getItemDetailValues('addressLine1') && getItemDetailValues('cityTown') &&
                            getItemDetailValues('postalCode') && getItemDetailValues('state') && getItemDetailValues('country')) ? {
                            addressLine1: getItemDetailValues('addressLine1'),
                            addressLine2: getItemDetailValues('addressLine2'),
                            landmark: getItemDetailValues('landmark'),
                            cityTown: getItemDetailValues('cityTown'),
                            postalCode: getItemDetailValues('postalCode'),
                            state: getItemDetailValues('state'),
                            country: getItemDetailValues('country'),
                            attention: getItemDetailValues('attention'),
                            phone: getItemDetailValues('phone'),
                            addressType: AddressType.primaryContact
                        } : null
                    }],
                    auctionId: auctionId,
                    lotId: lotId,
                    isBulkUpload: false
                }
            })
        }
    }

    const validateAddress = () => {
        if (getItemDetailValues('addressLine1') && getItemDetailValues('country') && getItemDetailValues('postalCode') &&
            getItemDetailValues('state') && getItemDetailValues('state')) {
            return true;
        } else if (getItemDetailValues('addressLine1') || getItemDetailValues('addressLine2') || getItemDetailValues('postalCode') ||
            getItemDetailValues('state') || getItemDetailValues('state') || getItemDetailValues('attention') ||
            getItemDetailValues('phone') || getItemDetailValues('landmark')) {
            setTimeout(() => {
                setMessage({ show: true, message: t('Please_Fill_The_Address_Details'), severity: 'error' });
            }, 100);
            return false;
        } else {
            return true;
        }
    }

    const updateItemValues = () => {
        updateItem({
            variables: {
                auctionId: auctionId,
                itemInput: {
                    id: getItemDetailValues('id'),
                    itemName: getItemDetailValues('itemName'),
                    itemRefNo: getItemDetailValues('itemRefNo'),
                    description: getItemDetailValues('description'),
                    itemType: getItemDetailValues('itemType'),
                    quantity: Number(getItemDetailValues('quantity')),
                    unit: getItemDetailValues('unit'),
                    address: (getItemDetailValues('addressLine1') && getItemDetailValues('cityTown') &&
                        getItemDetailValues('postalCode') && getItemDetailValues('state') && getItemDetailValues('country')) ? {
                        addressId: getItemDetailValues('addressId'),
                        sourceId: getItemDetailValues('sourceId'),
                        addressLine1: getItemDetailValues('addressLine1'),
                        addressLine2: getItemDetailValues('addressLine2'),
                        landmark: getItemDetailValues('landmark'),
                        cityTown: getItemDetailValues('cityTown'),
                        postalCode: getItemDetailValues('postalCode'),
                        state: getItemDetailValues('state'),
                        country: getItemDetailValues('country'),
                        attention: getItemDetailValues('attention'),
                        phone: getItemDetailValues('phone'),
                        addressType: getItemDetailValues('addressType'),
                    } : null
                }
            }
        })
    }

    useEffect(() => {
        listItemAddress({
            variables: {
                auctionId: auctionId
            }
        })
    }, [])


    return (
        <Container maxWidth='lg'>
            <Dialog open={open} fullWidth maxWidth='lg' disableEscapeKeyDown>
                <DialogTitle style={{ textAlign: 'center', paddingTop: '10px' }}>
                    <Typography id="add-item-header" variant="h6" gutterBottom color='primary' style={{ fontWeight: 'bold' }}>
                        {t('Add_Item')}
                    </Typography>
                    <Container maxWidth="md" style={{ paddingTop: '20px' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepButton color="inherit">
                                        {t(label)}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%' }}>
                                {getStepContent(activeStep)}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12}>
                            {getPageActions(activeStep)}
                        </Grid>
                    </Grid>
                </DialogActions>
                {(creatingItem || updatingItem) && <AuctionLoader show={creatingItem || updatingItem} invisible={false} />}
            </Dialog>
            {(errorOnCreatingItem) && <GraphQLErrors error={errorOnCreatingItem} show={false} />}
            {(errorOnUpdatingItem) && <GraphQLErrors error={errorOnUpdatingItem} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </Container>
    )
}

export default AddItem;