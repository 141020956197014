import React from "react";

export const AuctionCreateSchema = {
    referenceNumber: 'referenceNumber',
    auctionTitle: 'title',
    auctionBrief: 'description',
    auctioneerName: 'auctioneerName',
    auctioneerEmail: 'auctioneerEmail',
    companyName: 'companyName',
    reservePrice: 'reservePrice',
    emdAmount: 'emdAmount',
    incrementalAmount: 'incrementalAmount',
    extensionTime: 'extensionTime',
    auctionStartDate: 'startTime',
    auctionEndDate: 'endTime',
    registrationStartDate: 'registrationStartTime',
    registrationEndDate: 'registrationEndTime',
    auctionType: 'auctionType',
    auctionMethod: 'auctionMethod',
    baseCurrency: 'baseCurrency',
    mannerOfSale: 'mannerOfSale',
    biddingAccess: 'biddingAccess',
    auctionVariant: 'auctionVariant',
    autoExtension: 'autoExtension',
    autoExtensionMode: 'autoExtensionMode',
    extendTimeWhenBidReceivedInLastMinute: 'extendTimeWhenBidReceivedInLastMinute',
    extendTimeBy: 'extendTimeBy',
    lastDateForEmd: 'lastDateForEmd',
    bidStartPrice: 'bidStartPrice',
    startResultShareTime: 'startResultShareTime',
    endResultShareTime:'endResultShareTime',
    incrementalAmountDuringExtension: 'incrementalAmountDuringExtension',
    winningPrice: 'winningPrice',
    itemType: 'itemType',
    noOfExtension: "noOfExtension",
    lotNumber: "lotNumber",
    lotDescription: "description",
    lastDateToFinalizeBidder: "lastDateToFinalizeBidder",
    lastDateForKyc: "lastDateForKyc"


    // firstName: {
    //     controlName: 'firstName',
    //     validation: {
    //         required: {value: true, message: "Field is required"},
    //         maxLength: {value: 10, message: "Entered Maximum lenth of 10"}
    //     }
    // }
}
