import { makeStyles }                               from "@mui/styles";
import React                                        from "react";
import { useTranslation }                           from 'react-i18next';
import { Dialog, DialogContent, DialogTitle }       from "@mui/material";
import BankDetails                                  from "./BankDetail";

const useStyles = makeStyles((theme) => ({
    titleBg: {
        backgroundColor: theme.tableHearderBg,
        textAlign: 'center'
    }
}))

const BankDetailModal = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const setCancel = (isCancel) => {
        props.setCancel(isCancel);
    }

    const onSuccessModal = (successMessage) => {
        props.onSuccess(successMessage);
    }

    return (
        <Dialog open={props.openPopup} maxWidth='md' fullWidth scroll='paper'>
            <DialogTitle className={`${classes.titleBg}`}>{t('Bank_Account_Details')}</DialogTitle>
            <DialogContent sx={{padding: '0px !important', paddingBottom: '10px !important'}}>
                <BankDetails setCancel={setCancel} bankDetailId={props.bankDetailId} isNew={props.isNew} isModal={true}
                    onSuccessModal={onSuccessModal} userId={props.userId} auctionId={props.auctionId} isView={props.isView}
                    showExistingBankDetails={props.showExistingBankDetails} sellerLoginUserId={props.sellerLoginUserId}
                    existingBankIds={props.existingBankIds}/>
            </DialogContent>
        </Dialog>
    )
}

export default BankDetailModal;