import { useLazyQuery } from "@apollo/client";
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Amplify, Auth } from 'aws-amplify';
import React, { Suspense, useContext, useEffect, useState, useMemo, lazy } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
import AuctionListContainer from './auction-list/auction-list-container/AuctionListContainer';
import { ConfirmOtpVerification } from './authentication/ConfirmOtpVerification';
import ForgotPassword from './authentication/forgotpassword/ForgotPassword';
import ResetPassword from './authentication/forgotpassword/ResetPassword';
import Login from './authentication/Login';
import Register from './authentication/Register';
import VerifyUser from './authentication/VerifyUser';
import aws from './aws-exports';
import AuctionLoader from './common/auction-loader/AuctionLoader';
import { ThemeContext } from './common/context/ThemeContext';
import { UserContext } from './common/context/UserContext';
import { requireAuthentication } from './common/HOC/RequireAuthetication';
import TopBar from './nav-bar/TopBar';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuctionStatus, AppHostDomain } from './common/StaticVariableDeclaration';
import { PublicAxiosAuctionInterceptor } from "./config/AxiosAuctionInterceptor";
import { GET_MY_SETTINGS } from './GraphQL/Queries';
import { GotoLanding } from './nav-bar/GotoLanding';
import ItemImagesDetail from "./auction-detail-image/ItemImagesDetail";
import AutoAccountPasswordChange  from './authentication/forgotpassword/AutoAccountPasswordChange';
import GraphQLErrors from './common/GraphQLErrors';
import AuctionSnackBar from './common/AuctionSnackBar';

Amplify.configure({
  Auth: {
    userPoolId: aws.aws_cognito_userPoolId,
    userPoolWebClientId: aws.aws_cognito_userPoolWebClientId

  }
})

const RootContextValues = (props) => {
  const { children, rootContext, setRootContext } = props;
    return useMemo(() => {
      return (
        <UserContext.Provider value={{ rootContext, setRootContext }} >
          {children}
        </UserContext.Provider>
      )
    }, [rootContext])
}

const App = () => {

  const theme = useContext(ThemeContext);
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    message: '',
    severity: ''
  })
  const [rootContext, setRootContext] = useState({
    userSession: { isAuthenticated: false, session: null },
    enumList: null,
    enumListObject: null,
    loginUserRoles: [],
    selectedMenu: 'auction',
    currentDateTime: null,
    isUserAuctioneer: [] 
  });

  function lazyWithPreload(url) {
    const Component = React.lazy(url);
    Component.preload = url;
    return Component;
  }

  const Profile = lazyWithPreload(() => import('./profile/UserProfile'));
  const Client = lazyWithPreload(() => import('./profile/client/Client'));
  const LazyAuctionListContainer = lazyWithPreload(() => import('./auction-list/auction-list-container/AuctionListContainer'));
  const EnglishForwardBid = lazyWithPreload(() => import('./bidder/bid-process/EnglishForwardBid'));
  const AuctionDetail = lazyWithPreload(() => import('./auction/auction-detail/AuctionDetail'));
  const ContactUs = lazyWithPreload(() => (process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle) ? import('./authentication/contactUs') : import('./authentication/ContactUsNesl'));
  const LazyMultiAuctionContainer = lazyWithPreload(() => import('./multi-auction/MultiAuctionList'));
  // const LazyMultiAuctionContainer = lazyWithPreload(() => import('./multi-auction/CustomMultiAuctionList'));

  const fetchEnumList = () => {
    setMessage({ show: false, message: '', severity: '' });
    fetchEnumArrays();
    fetchEnumObjects();
  }

  const fetchEnumArrays = async () => {
    PublicAxiosAuctionInterceptor.get('rest/all-enum-list').then(resp => {
      setRootContext(prevState => ({
        ...prevState,
        enumList: resp
        })
      );
    }, errors => {
      console.log('error calling API service :... all-enum-list...', errors);
      setMessage({ show: true, message: errors.message, severity: 'error' });
    });
  }

  const fetchEnumObjects = async () => {
    PublicAxiosAuctionInterceptor.get('rest/all-enum-list-object').then(resp => {
      setRootContext(prevState => ({
        ...prevState,
        enumListObject: resp
        })
      );
    }, errors => {
      setMessage({ show: true, message: errors.message, severity: 'error' });
      console.log('error calling API service :... all-enum-list...', errors);
    });
  }

  const [getUserDetails, { loading: getUserLoading, error: getUserError }] = useLazyQuery(GET_MY_SETTINGS, {
    errorPolicy: 'all', fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      sessionStorage.setItem('tourGuide', data?.getMySetting?.tourGuide);
      setRootContext(prevState => ({
        ...prevState,
        currentDateTime: data?.getMySetting?.currentTime,
        isUserAuctioneer: data?.getMySetting?.noOfAuctioneerSupportRole
        })
      );
      theme.dispatch({ type: data?.getMySetting?.theme });
      setTimeout(() => {
        Profile.preload();
        Client.preload();
      }, 3000);
    },
    onError: (error) => {
      console.log(error);
    },
    caches: (data) => {
      // console.log(data);
    }
  })

  const goToLandingPage = () => {
    // console.log('.......goToLandingPage......')
    // **************  Uncomment for isolated landing component ******************
    // theme.dispatch({ type: 'light' });
    // history.push({ pathname: "/landing" })
    history.push({
      pathname: "/landing-page",
      state: {
        userRole: [],
        auctionListType: AuctionStatus.scheduled,
        pagination: {
          page: 1,
          pageSize: 10
        },
        isMyBid: false
      }
    })
  }

  /* let onLoad = async () => {
    try {
      await Auth.currentSession().then(res => {
        Auth.currentUserInfo().then((userDetails) => {
          if (userDetails.username) {
            setUserSession({
              isAuthenticated: true, session: {
                accessToken: res.idToken.jwtToken,
                name: userDetails.attributes.name,
                email: userDetails.attributes.email,
                phone_number: userDetails.attributes.phone_number,
                userId: userDetails.attributes.sub,
              },
              roles: {
                agent: true,
                auctioneer: true
              }
            });
            sessionStorage.setItem('token', res.idToken.jwtToken);
            getUserDetails();
            // fetchEnumList();
            console.log('.......user exist......')
            history.push({
              pathname: "/auction-list",
              state: {
                userRole: [],
                auctionListType: AuctionStatus.open,
                pagination: {
                  page: 1,
                  pageSize: 10
                }
              }
            })
          } else {
            // console.log('.......user name not exist......', userDetails)
            goToLandingPage();
          }
        }, error => {
          // console.log('.......currentUserInfo not exist......', error)
          goToLandingPage();
        });
      }, error => {
        // console.log('.......currentSession not exist......', error)
        goToLandingPage();
      });
    }
    catch (e) {
      if (e !== 'No current user') {
        console.log('error', e);
      }
    }
    console.log('....INNNNNNNNN...isAuthenticating ......', isAuthenticating);
    goToLandingPage();
    setIsAuthenticating(false);
  } */
 
  let onLoad = async () => {
    goToLandingPage();
    setIsAuthenticating(false);
    setTimeout(() => {
      LazyAuctionListContainer.preload();
      EnglishForwardBid.preload();
      AuctionDetail.preload();
      ContactUs.preload();
      LazyMultiAuctionContainer.preload();
    }, 3000);
  }

  useEffect(() => {
    sessionStorage.setItem('tourGuide', false);
    sessionStorage.setItem('isMfaEnabled', false);
    // sessionStorage.setItem('nbidNav', (process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid) ? 'show' : 'hide');
    fetchEnumList();
    onLoad();
  }, []);

  // const AuctionDetail = lazy(() => import('./auction/auction-detail/AuctionDetail'));
  
  return (
    <Suspense fallback={<AuctionLoader show={true} />}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        {(rootContext.enumList != null && rootContext.enumListObject != null) ? <React.Fragment>
        <RootContextValues rootContext={rootContext} setRootContext={setRootContext} >
          {(!getUserLoading && !getUserError) ? <div className="app">
            <Switch>
              <Route exact path="/sign-in" component={() => <Login getUser={getUserDetails} />} />
              <Route exact path="/sign-up" component={Register} />
              <Route exact path="/sign-up/verify" component={VerifyUser} />
              <Route exact path="/sign-up/success" component={ConfirmOtpVerification} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/goto-landing" component={GotoLanding} />
              <Route exact path="/auto-account-password-change" component={AutoAccountPasswordChange} />
              {/* {(rootContext.enumList != null && rootContext.enumListObject != null) ? <React.Fragment> */}
                <ThemeProvider theme={theme.state.theme}>
                  <CssBaseline />
                  <div className="app-header">
                    <TopBar />
                  </div>
                  <div className="app-content">
                    <Route exact path="/landing-page" component={requireAuthentication(AuctionListContainer, true)} />
                    <Route exact path="/profile" component={requireAuthentication(Profile)} />
                    <Route exact path="/client" component={requireAuthentication(Client)} />
                    <Route exact path="/auction-list" component={requireAuthentication(LazyAuctionListContainer, true)} />
                    <Route exact path="/bidders-page" component={requireAuthentication(EnglishForwardBid)} />
                    <Route exact path="/auction-detail" component={requireAuthentication(AuctionDetail, true)} />
                    <Route exact path="/auction-detail-image" component={requireAuthentication(ItemImagesDetail, true)} />
                    <Route exact path="/my-auction-list" component={requireAuthentication(LazyAuctionListContainer)} />
                    <Route exact path="/my-bids-list" component={requireAuthentication(LazyAuctionListContainer)} />
                    <Route exact path="/multi-auction-bidder" component={requireAuthentication(LazyMultiAuctionContainer)} />
                    <Route exact path="/contact-us" component={ContactUs} />
                  </div>
                  {/* **************  Uncomment for isolated landing component ****************** */}
                  {/* {!userSession.isAuthenticated && <React.Fragment>
                    <Route exact path="/" component={AuctionLandingPage} />
                    <Route exact path="/landing" component={AuctionLandingPage} />
                  </React.Fragment>}
                  {userSession.isAuthenticated && <React.Fragment>
                    <CssBaseline />
                    <div className="app-header">
                      <TopBar />
                    </div>
                    <div className="app-content">
                      <Route exact path="/" component={requireAuthentication(AuctionListContainer, true)} />
                      <Route exact path="/profile" component={requireAuthentication(Profile)} />
                      <Route exact path="/client" component={requireAuthentication(Client)} />
                      <Route exact path="/auction-create" component={requireAuthentication(AuctionCreateStepper)} />
                      <Route exact path="/auction-list" component={requireAuthentication(AuctionListContainer, true)} />
                      <Route path="/bidders-page" component={requireAuthentication(Bid)} />
                      <Route exact path="/auction-detail" component={requireAuthentication(AuctionDetail, true)} />
                      <Route exact path="/my-auction-list" component={requireAuthentication(AuctionListContainer)} />
                    </div>
                  </React.Fragment>} */}
                </ThemeProvider >
            </Switch >
          </div > : 
          <React.Fragment>
            { getUserError && !getUserLoading && <GraphQLErrors error={getUserError} show={false} /> }
            { getUserLoading && <AuctionLoader show={true} /> }
          </React.Fragment>}
        </RootContextValues >
        </React.Fragment > : 
        <React.Fragment>
          { message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} /> }
          { !message.show &&  <AuctionLoader show={true} /> }
        </React.Fragment>}
      </GoogleReCaptchaProvider >
    </Suspense >
  );
}

export default React.memo(App);
