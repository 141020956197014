
import React, { Fragment, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    FormControl,
    InputLabel,
    Input,
    FormHelperText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import AuctionSnackBar from './AuctionSnackBar';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%'
    },
    dialogAction: {
        padding: '1rem 3.5rem !important',
        justifyContent: 'center !important',
        gap: '1rem !important'
    },
    icon: {
        color: `${theme.palette.primary.main} !important`,
        fontSize: '2.5rem !important'
    },
    ramarksHelperText: {
        color: `${theme.palette.primary.main} !important`,
        textAlign: 'right !important'
    }
}))

const AuctionConfirmDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { icon } = {
        ...props, icon: props.icon || <HiOutlineCheckCircle className={classes.icon} />
    }
    const [open, setOpen] = useState(props.show);
    const [title] = useState(props.title);
    const [message] = useState(props.message);
    const [isCancel] = useState(props.isCancel != null ? props.isCancel : true)
    const [agreeBtnLabel] = useState(props.agreeBtnLabel != null ? props.agreeBtnLabel : 'Yes');
    const [disAgreeBtnLabel] = useState(props.disAgreeBtnLabel != null ? props.disAgreeBtnLabel : 'No');
    const [withConfirmText] = useState(props.withConfirmText ? props.withConfirmText : false);
    const [confirmTextLabel] = useState(props.confirmTextLabel ? props.confirmTextLabel : 'Remarks');
    const [isConfirmTextMandatory] = useState(props.isConfirmTextMandatory ? props.isConfirmTextMandatory : false);
    const [confirmText, setConfirmText] = useState('');
    const [maxConfirmTextLength] = useState(props.maxConfirmTextLength ? props.maxConfirmTextLength : 250);

    const [snackbarMsg, setSnackbarMsg] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const handleDisAgree = () => {
        setOpen(false);
        props.onDisAgree();
    };

    const confirmTextAdd = (e) => {
        setConfirmText(e.target.value.replace(/\s+/g, '') === '' ? '' : e.target.value)
    }

    const handleAgree = () => {
        if (isConfirmTextMandatory && confirmText === '') {
            setSnackbarMsg({ show: true, message: `${confirmTextLabel} ${t('Is_Mandatory')}`, severity: 'error' });
        } else {
            setOpen(false);
            props.onAgree(confirmText);
        }
        setTimeout(() => {
            setSnackbarMsg({ show: false, message: '', severity: '' });
        }, 2500);
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">
                    <Stack justifyContent='center' alignItems='center' sx={{ gap: '0.6rem' }}>
                        {icon}
                        <Fragment>{title}</Fragment>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description" style={{ textAlign: 'center' }}>
                        {message}
                    </DialogContentText>
                    {withConfirmText &&
                        <FormControl
                            margin="dense"
                            autoFocus
                            fullWidth required={isConfirmTextMandatory}
                            variant="standard"
                        >
                            <InputLabel htmlFor="remarks-input">{t(confirmTextLabel)}</InputLabel>
                            <Input type="text" id="remarks-input" aria-describedby="remarks-input-text"
                                inputProps={{ maxLength: maxConfirmTextLength }} sx={{ marginTop: '1rem' }} multiline
                                onChange={confirmTextAdd} value={confirmText} />
                            <FormHelperText id="reamrks-helper-text" className={classes.ramarksHelperText}>
                                {confirmText.length}/{maxConfirmTextLength}
                            </FormHelperText>
                        </FormControl>
                    }

                    {/* <TextField
                        autoFocus
                        margin="dense"
                        value={confirmText}
                        onChange={confirmTextAdd}
                        id="name"
                        label={t(confirmTextLabel)}
                        type="text"
                        fullWidth
                        required={isConfirmTextMandatory}
                        variant="standard"
                        multiline
                        inputProps={{ maxLength: 250 }}
                        // rows={2}
                        sx={{ marginTop: '1rem' }}
                        helperText
                    /> */}
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    {/* <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}> */}
                    {isCancel && <Button id="popup-no" className={classes.fullWidth} onClick={handleDisAgree} color="primary" variant='outlined'>
                        {disAgreeBtnLabel}
                    </Button>}
                    <Button onClick={handleAgree} id="popup-yes" className={classes.fullWidth} color="primary" variant='contained' autoFocus>
                        {agreeBtnLabel}
                    </Button>
                    {/* </Stack> */}
                </DialogActions>
            </Dialog>
            {snackbarMsg.show && <AuctionSnackBar show={snackbarMsg.show} message={snackbarMsg.message} severity={snackbarMsg.severity} />}
        </div>
    );
}

export default AuctionConfirmDialog;
