import React, { 
    useState,
    useEffect 
}                                   from 'react';
import {
    Typography,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    Dialog,
    DialogTitle
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next'; 
import { useForm }                  from 'react-hook-form';
import EMDInput                     from './EMDInput';
import { UPDATE_EMD }               from '../../../GraphQL/Mutation';
import { LIST_EMD }                 from '../../../GraphQL/Queries';
import { useMutation }              from '@apollo/client';
import AuctionLoader                from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../../common/GraphQLErrors';
import { makeStyles }               from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const UpdateEMDDetail = ({onClose, show, auction_id, bidder_id, seller_id, user_id, emdDetails, onSuccess,
        auctionCreationDate, allowUpdateReceivedEMDField, bidder_login_id}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(show ? show : false);

    const { register, control, getValues, setValue, trigger, reset,
            formState: { errors, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            fromBankId: null,
            toBankId: null,
            amount: 0,
            transactionDate: null,
            auctionId: '',
            userId: '',
            transactionRefNo: '',
            modeOfPayment: '',
            received: 0
        }
    });

    const [updateEMD, {loading: updatingEMD, error: errorOnUpdatingEMD}] = useMutation(UPDATE_EMD, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.updateEmdDetail != null) {
                if (onSuccess) {
                    onSuccess({show: true, message: t('EMD_Updated_Successfully'), severity: 'success'})
                }
                handleClose();
            }
        },
        refetchQueries: [LIST_EMD]
    })


    const handleClose = () => {
        setOpen(false);
        onClose(false);
    }

    const update = () => {
        updateEMD({
            variables: {
                emdDetailInput: {
                    fromBankId: getValues('fromBankId'),
                    toBankId: getValues('toBankId'),
                    amount: getValues('amount'),
                    transactionDate: getValues('transactionDate'),
                    userId: user_id,
                    transactionRefNo: getValues('transactionRefNo'),
                    modeOfPayment: getValues('modeOfPayment'),
                    lotItemId: getValues('lotItemId'),
                    received: getValues('received'),
                    transferredEdgeId: getValues('transferredEdgeId')
                },
                auctionId: auction_id
            }
        })
    }

    useEffect(() => {
        if(emdDetails) {
            setValue('fromBankId', emdDetails.fromBankDetail.id, {shouldValidate: true});
            setValue('toBankId', emdDetails.toBankDetail.id, {shouldValidate: true});
            setValue('amount', emdDetails.amount, {shouldValidate: true});
            setValue('transactionDate', emdDetails.transactionDate, {shouldValidate: true});
            setValue('transactionRefNo', emdDetails.transactionRefNo, {shouldValidate: true});
            setValue('modeOfPayment', emdDetails.modeOfPayment, {shouldValidate: true});
            setValue('lotItemId', emdDetails.lotItemId);
            setValue('received', emdDetails.received);
            setValue('transferredEdgeId', emdDetails.transferredEdgeId);
        }
    }, [emdDetails])
    

    return (
        <Dialog open={open} fullWidth maxWidth='xs'>
            <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                <Typography id="update-emd-amount-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                    {t('EMD_Details')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <EMDInput register={register} control={control} getValues={getValues} trigger={trigger}
                            errors={errors} auction_id={auction_id} bidder_id={bidder_id} seller_id={seller_id}
                            user_id={user_id} isValid={isValid} auctionCreationDate={auctionCreationDate}
                            allowUpdateReceivedEMDField={allowUpdateReceivedEMDField} bidder_login_id={bidder_login_id}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button id='update-emd' type="submit" size="small" variant="contained"
                                onClick={update} 
                                disabled={!isValid} 
                                className={classes.button}>
                            {t('Save')}
                        </Button>
                        <Button id='close-update-emd' size="small" variant="outlined" className={classes.button}
                                onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            {updatingEMD && <AuctionLoader show={updatingEMD} invisible={false} />}
            {errorOnUpdatingEMD && <GraphQLErrors error={errorOnUpdatingEMD} show={false} />}
        </Dialog>
    )
}

export default UpdateEMDDetail;