import { Button, keyframes, TableHead } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export const ButtonGlow = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232)) !important',
    fontWeight: 'bold !important',
    transition: '0.2s all ease-in-out',
    transform: "scale3d(1, 1, 1)",
    boxShadow: 'none',
    display: 'inline-flex',
    animation: 'Button-transform 1s linear infinite',
    '&:hover': {
        animation: 'none',
        boxShadow: '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3) !important',
        '& .icon': {
            animation: 'none',
        }
    },
    '& .icon': {
        fontSize: '1.2rem',
        paddingRight: '0.2rem',
        animation: 'icon 1s linear infinite',
    }
}))

export const contentContainer = makeStyles((theme) => ({
    root: {
        padding: `${theme.breakpoints.up("md") ? '0px 24px !important' : '0px 16px !important'}`
    }
}))

export const StickyHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    zIndex: 1000,
    // background: theme.palette.background.paper,
    background: theme.palette.type === 'light' ? 'rgb(41 171 226 / 20%)' : 'rgb(61 62 62)',
    color: theme.palette.type === 'light' ? 'rgb(7 91 128)' : 'rgb(216 219 220)',
    width: 'fit-content',
    minWidth: '15%',
    borderRadius: '0px 0px 5px 5px',
    padding: '5px 10px',
    fontWeight: 'bold',
    display: 'inline-flex',
    justifyContent: 'center'
}))

export const SectionContent = styled('div')(({ theme }) => ({
    marginTop: '10px',
    width: '100%'
}))

export const PaperHeader = styled(TableHead)(({ theme }) => ({
    '& tr td, & tr th': {
        // color: '#0777c1 !important',
        background: theme.palette.type === 'light' ? '#fafafa !important' : '#454647 !important',
        fontWeight: 'bold !important',
        fontSize: '16px'
    },
}))

export const AuctionStatusIndicator = styled('div')(({ theme, color, bg }) => ({
    padding: '4px 16px',
    fontSize: '0.9rem',
    fontWeight: 700,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    borderRadius: '16px',
    background: bg,
    color: color,
    marginLeft: '0.5rem',
    transform: 'none',
    maxWidth: 'fit-content'
}))