import { useLazyQuery } from '@apollo/client';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import { Grid, IconButton, Link, Tooltip, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCopyOutline } from 'react-icons/io5';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../common/GraphQLErrors';
import { HELP_DESK } from '../GraphQL/Queries';
import { UserContext } from '../common/context/UserContext';
import { PublicAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';

const useStyles = makeStyles((theme) => ({
  coloredAvatar: {
    backgroundColor: theme.palette.primary.main + ' !important',
    color: '#fff !important'
  },
  avatarContainer: {
    display: 'flex',
    marginBottom: '1.2rem',
    justifyContent: 'center'
  },
  icon: {
    color: 'grey',
    marginRight: '10px'
  },
  headerSize: {
    fontSize: '14px !important',
    fontWeight: 'bold !important'
  },
  detailSize: {
    fontSize: '14px !important'
  },
}));

function HelpDesk(props) {
  const [helpDesk, setHelpDesk] = useState([])
  const { t } = useTranslation();
  const classes = useStyles();
  const userContext = useContext(UserContext).rootContext;
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({
    show: false,
    message: '',
    severity: ''
  })
  const enumListObject = useContext(UserContext).rootContext.enumListObject;

  const [userHelpDesk, { loading: helpDeskLoad, error: errorOnHelpDeskLoad }] = useLazyQuery(HELP_DESK, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setHelpDesk(data.helpDesk)
    }
  })

  useEffect(() => {
    if (userContext.userSession.isAuthenticated) {
      userHelpDesk({
        variables: {
          auctionId: props.auctionId,
          isActive: true
        }
      })
    } else {
      setLoading(true);
      PublicAxiosAuctionInterceptor.get(`rest/auction/${props.auctionId}/help-desk`).then((response) => {
        setHelpDesk(response)
        setLoading(false);
      }).catch(function (error) {
        setMessage({ show: false, message: '', severity: '' });
        setLoading(false);
        if (error.message) {
          setMessage({
            show: true,
            message: error.message,
            severity: 'error'
          });
        } else {
          setMessage({
            show: true,
            message: t('Try_Again'),
            severity: 'error'
          });
        }
      });
    }
  }, [])

  return (
    <Grid container spacing={1} id="helpdesk-card">
      {helpDesk.map((value, index) => {
        return (
          <Grid id={`card-type-${index}`} item xs={12} sm={12} md={6} lg={4}>
            <Card style={{ height: '100%' }}>
              <CardContent id={`content-type-${index}`} style={{ padding: '16px 12px' }}>
                <Typography id='name' variant='h6' align="center" color={"#078d36"} style={{ marginBottom: '0.4rem', textTransform: 'capitalize' }} >
                  {value.roleDetails.map((role, index) => {
                    return index === value.roleDetails.length - 1 ? enumListObject.Role[(role.role).toLowerCase()].uiCode : enumListObject.Role[(role.role).toLowerCase()].uiCode + ' & '
                  })}
                </Typography>
                <Typography>
                  <div id='avatar-container' className={classes.avatarContainer}>
                    <Avatar className={classes.coloredAvatar}>{value.name[0].toUpperCase()}</Avatar>
                  </div>
                </Typography>
                <Stack id='name-email' spacing={2}>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <PersonIcon className={classes.icon} fontSize='20px' />
                    <Typography className={classes.headerSize} color='text-secondary'>{t('Name')}:</Typography>
                    <span style={{ wordBreak: 'break-word' }} className={classes.detailSize}>{value.name}</span>
                  </Stack>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <MailIcon className={classes.icon} fontSize='20px' />
                    <Typography className={classes.headerSize} color='textSecondary'>{t('Email')}:</Typography>
                    <a id='anchor' href={'mailto: ' + value.email} target="_blank" className={classes.detailSize}>{value.email}</a>
                    <Tooltip id='copy' title={t('Copy_to_clipboard')} arrow>
                      <IconButton id='icon-button' style={{ padding: '3px' }} size='small' onClick={() => { navigator.clipboard.writeText(`${value.email}`) }}>
                        <IoCopyOutline />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Stack id='phone-number' direction='row' alignItems='center' spacing={1}>
                    <PhoneIcon style={{ color: 'grey', marginRight: '10px' }} fontSize='20px' />
                    <Typography className={classes.headerSize} color='textSecondary'>{t('Contact')}:</Typography>
                    <span className={classes.detailSize}>{value.phoneNumber}</span>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            {helpDeskLoad && <AuctionLoader show={helpDeskLoad} invisible={false} />}
            {errorOnHelpDeskLoad && <GraphQLErrors error={errorOnHelpDeskLoad} show={false} />}
          </Grid>
        )
      })}
    </Grid>
  )
}
export default HelpDesk