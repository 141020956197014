import React, { useEffect, useState, useContext } from "react";
import { Grid, Select, TextField, FormControl, InputLabel, MenuItem, FormHelperText, ListItemText, Checkbox }
    from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Validation } from "../common/Validation";
import { AuctionCreateSchema } from "../schema/AuctionCreateSchema";
import { DateTimePickerControl } from "../custom-form-control/DateTimePickerControl";
import { SelectControl } from "../custom-form-control/SelectControl";
import { UserContext } from "../common/context/UserContext";
import { NumberFormatController } from '../custom-form-control/NumberFormatController';
// import { MultiSelectControl } from '../custom-form-control/MultiSelectControl';
import { AutoExtensionMode, MannerOfSale, AuctionType, AuctionMethod, BiddingAccess, Roles }
    from '../common/StaticVariableDeclaration';
import { MultiSelectTextControl } from '../custom-form-control/MultiSelectTextControl';
import { ExtendAuctionTimeInput } from '../custom-form-control/ExtendAuctionTimeInput';
import { useServerCurrentDateTime } from '../utils/Utils';

const useStyles = makeStyles((theme) => ({
    gridContent: {
        marginBottom: '10px',
        padding: '10px 20px !important'
    },
    stepContainer: {
        padding: '4px'
    },
    stepContent: {
        border: `1px solid ${theme.step.contentBorderColor}`,
        background: theme.step.contentBg
    },
    disabledDiv: {
        padding: '4px',
        pointerEvents: 'none',
        opacity: '0.4',
    }
}));

export const AuctionCreate = (props) => {
    const { 
        auctionRegister,
        auctionControl,
        auctionErrors,
        auctionGetValues,
        auctionWatch,
        lotRegister,
        lotControl,
        lotErrors,
        lotGetValues,
        lotWatch,
        auctionSetValue,
        lotSetValue,
        auctionTrigger,
        lotTrigger,
        disable,
        lotIsValid,
        auctionIsValid,
        lotUnRegister,
        setDateValidation,
        create,
        setTourGuide,
        lotSetFocus
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const autoExtensionOption = [
        { id: '01', value: true, description: 'Yes' },
        { id: '02', value: false, description: 'No' }
    ];
    const [initial, setInitial] = useState(true);
    const [autoExtensionDependancy, setAutoExtensionDependancy] = useState(true);
    const [noOfExtensionDependancy, setNoOfExtensionDependancy] = useState(false); 
    const [mannerOfSaleDependancy, setMannerOfSaleDependancy] = useState(true);
    const [incrementDuringExtensionRefresh, setIncrementDuringExtensionRefresh] = useState(true);
    const [bidOpenAmountRefresh, setBidOpenAmountRefresh] = useState(true);
    const [maxDateOnAuctionStartDate, setMaxDateOnAuctionStartDate] = useState(null)
    const myRef = React.useRef(null);
    const userContext = useContext(UserContext).rootContext;
    const isCustomerSupport = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const serverCurrentTime = useServerCurrentDateTime();

    useEffect(() => {
        if(auctionGetValues(AuctionCreateSchema.mannerOfSale) == MannerOfSale.singleLot) {
            setMannerOfSaleDependancy(true);
        } else {
            lotUnRegister(AuctionCreateSchema.lotNumber);
            lotUnRegister(AuctionCreateSchema.lotDescription);
            setMannerOfSaleDependancy(false);
        }
    }, [auctionWatch(AuctionCreateSchema.mannerOfSale)])

    useEffect(() => {
        if((!initial || !create) && auctionGetValues(AuctionCreateSchema.auctionEndDate)) {
            lotTrigger(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute);
        }
    }, [auctionWatch(AuctionCreateSchema.auctionEndDate)])

    useEffect(() => {
        if(!initial || !create) {
            if(!lotGetValues(AuctionCreateSchema.autoExtension)) {
                lotUnRegister(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute);
                lotUnRegister(AuctionCreateSchema.extendTimeBy);
                lotUnRegister(AuctionCreateSchema.noOfExtension);
                lotSetValue(AuctionCreateSchema.incrementalAmountDuringExtension, lotGetValues(AuctionCreateSchema.incrementalAmount));
                lotTrigger(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute);
                setAutoExtensionDependancy(false);
                setNoOfExtensionDependancy(false);
                lotTrigger(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute);
            } else {
                if(!initial) {
                    lotSetValue(AuctionCreateSchema.incrementalAmountDuringExtension, null);
                }
                if(create) {
                    lotSetValue(AuctionCreateSchema.autoExtensionMode, AutoExtensionMode.unlimitedExtension);
                }
                setAutoExtensionDependancy(true);
            }
            lotTrigger(AuctionCreateSchema.incrementalAmountDuringExtension);
            lotTrigger(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute);
        }
    }, [lotWatch(AuctionCreateSchema.autoExtension)])

    useEffect(() => {
        if(!initial || !create) {
            if(lotGetValues(AuctionCreateSchema.autoExtensionMode) == AutoExtensionMode.unlimitedExtension) {
                lotUnRegister(AuctionCreateSchema.noOfExtension);
                setNoOfExtensionDependancy(false);
            }
            else {
                setNoOfExtensionDependancy(true);
            }
        }
    }, [lotWatch(AuctionCreateSchema.autoExtensionMode)])

    useEffect(() => {
        if((!initial || !create) && lotGetValues(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute)) {
            lotTrigger(AuctionCreateSchema.extendTimeBy);
        }
    },[lotWatch(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute)])

    const getExtensionTimeValidationInMinutes = () => {
        if(auctionGetValues(AuctionCreateSchema.auctionEndDate) && auctionGetValues(AuctionCreateSchema.auctionStartDate)) {
            let diff = (new Date(auctionGetValues(AuctionCreateSchema.auctionEndDate)).getTime() - new Date(auctionGetValues(AuctionCreateSchema.auctionStartDate)).getTime())/(1000*60);
            return diff > 0 ? diff : null;
        } else {
            return null;
        }
        
    }

    useEffect( () => {
        if(auctionGetValues(AuctionCreateSchema.auctionStartDate)) {
            let newDate = new Date(auctionGetValues(AuctionCreateSchema.auctionStartDate));
            setMaxDateOnAuctionStartDate(new Date(newDate.setDate(newDate.getDate() - 1)));
            if((!initial || !create)) {
                lotTrigger(AuctionCreateSchema.lastDateForEmd);
                lotTrigger(AuctionCreateSchema.lastDateToFinalizeBidder);
                lotTrigger(AuctionCreateSchema.lastDateForKyc);
            }
        }
    }, [auctionWatch(AuctionCreateSchema.auctionStartDate)])

    useEffect(() => {
        if(!initial) {
            if(lotGetValues(AuctionCreateSchema.autoExtension)) {
                if(lotGetValues(AuctionCreateSchema.incrementalAmountDuringExtension) > 0) {
                    setIncrementDuringExtensionRefresh(false);
                    lotSetValue(AuctionCreateSchema.incrementalAmountDuringExtension, null);
                    setTimeout(() => setIncrementDuringExtensionRefresh(true), 0);
                }
            } else {
                setIncrementDuringExtensionRefresh(false);
                lotSetValue(AuctionCreateSchema.incrementalAmountDuringExtension, lotGetValues(AuctionCreateSchema.incrementalAmount));
                setTimeout(() => setIncrementDuringExtensionRefresh(true), 0);
            }
            lotTrigger(AuctionCreateSchema.incrementalAmountDuringExtension);
        }
    }, [lotWatch(AuctionCreateSchema.incrementalAmount)])

    useEffect(() => {
        if(!initial) {
            if(lotGetValues(AuctionCreateSchema.bidStartPrice) > 0 ) {
                setBidOpenAmountRefresh(false);
                lotSetValue(AuctionCreateSchema.bidStartPrice, null);
                setTimeout(() => setBidOpenAmountRefresh(true), 0);
                lotTrigger(AuctionCreateSchema.bidStartPrice);
            }
            lotTrigger(AuctionCreateSchema.emdAmount);
        }
    }, [lotWatch(AuctionCreateSchema.reservePrice)])

    useEffect(() => {
        myRef.current.scrollIntoView(true);
        setInitial(false);
        if(setTourGuide) {
            setTourGuide({
                triggerEvent: false,
                triggerButton: false
            });
        }
    },[])

    return (
        //<form>
        <React.Fragment>
             <Grid item xs={12} className={disable == true ? classes.disabledDiv : classes.stepContainer} ref={myRef}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={12} sm={12} md={12} lg={8}>
                        <TextField id="auctionTitle" label={t('Auction_Title')} fullWidth required
                            helperText={auctionErrors[AuctionCreateSchema.auctionTitle] && auctionErrors[AuctionCreateSchema.auctionTitle].message}
                            placeholder={t('Auction_Title')} type="text" variant='standard' minRows={1} maxRows={2} multiline
                            {...auctionRegister(AuctionCreateSchema.auctionTitle, Validation.longName)}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <TextField id="auctionReferenceNumber" label={t('Auction_Reference_Number')} fullWidth
                            helperText={auctionErrors[AuctionCreateSchema.referenceNumber] && auctionErrors[AuctionCreateSchema.referenceNumber].message}
                            placeholder={t('Auction_Reference_Number')} type="text" variant='standard'
                            {...auctionRegister(AuctionCreateSchema.referenceNumber, Validation.shortName)}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={12} md={12} lg={8}>
                        <TextField id="auctionBrief" label={t('Auction_Brief')} fullWidth required
                            helperText={auctionErrors[AuctionCreateSchema.auctionBrief] && auctionErrors[AuctionCreateSchema.auctionBrief].message}
                            placeholder={t('Auction_Brief')} type="text" variant='standard' minRows={1} maxRows={2} multiline
                            {...auctionRegister(AuctionCreateSchema.auctionBrief, Validation.longName)}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.auctionType}
                            control={auctionControl}
                            rules={Validation.selectRequired}
                            errors={auctionErrors}
                            label={t('Auction_Type')}
                            setValue={auctionSetValue}
                            getValues={auctionGetValues}
                        >
                            {enumListOptions.AuctionType.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id} disabled={item.dateBaseCode != AuctionType.english}>
                                    {item.uiCode} <i>{item.dateBaseCode != AuctionType.english ? t('Under_Development') : ''}</i>
                                </MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.auctionMethod}
                            control={auctionControl}
                            rules={Validation.selectRequired}
                            errors={auctionErrors}
                            label={t('Auction_Method')}
                            setValue={auctionSetValue}
                            getValues={auctionGetValues}
                        >
                            {enumListOptions.AuctionMethod.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id} disabled={item.dateBaseCode != AuctionMethod.forward}>
                                    {item.uiCode} <i>{item.dateBaseCode != AuctionMethod.forward ? t('Under_Development') : ''}</i>
                                </MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.biddingAccess}
                            control={auctionControl}
                            rules={Validation.selectRequired}
                            errors={auctionErrors}
                            label={t('Bidding_Access')}
                            setValue={auctionSetValue}
                            getValues={auctionGetValues}
                        >
                            {enumListOptions.BidderService.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id} disabled={item.dateBaseCode != BiddingAccess.limited}>
                                    {item.uiCode} <i>{item.dateBaseCode != BiddingAccess.limited ? t('Under_Development') : ''}</i>
                                </MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.mannerOfSale}
                            control={auctionControl}
                            rules={Validation.selectRequired}
                            errors={auctionErrors}
                            label={t('Manner_Of_Sale')}
                            setValue={auctionSetValue}
                            getValues={auctionGetValues}
                        >
                            {enumListOptions.MannerOfSale.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id} disabled={item.dateBaseCode == MannerOfSale.multiLot || item.dateBaseCode == MannerOfSale.multiItem}>
                                    {item.uiCode} <i>{(item.dateBaseCode == MannerOfSale.multiLot || item.dateBaseCode == MannerOfSale.multiItem) ? t('Under_Development') : ''}</i>
                                </MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={12} md={12} lg={12}>
                        <MultiSelectTextControl
                            mandatory={true}
                            name={AuctionCreateSchema.itemType}
                            control={lotControl}
                            rules={Validation.selectRequired}
                            errors={lotErrors}
                            label={auctionGetValues(AuctionCreateSchema.mannerOfSale) == MannerOfSale.goingConcern ?  t('Going_Concern_Category') : (
                                auctionGetValues(AuctionCreateSchema.mannerOfSale) == MannerOfSale.singleLot ? t('Lot_Category') : t('Item_Category')
                            )}
                            options={enumListOptions.ItemType}
                            optionsObject={enumListObject.ItemType}
                        >
                        </MultiSelectTextControl>
                    </Grid>
                    {mannerOfSaleDependancy &&
                        <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                            <TextField id="lotNumber" label={t('Lot_Number')} fullWidth required
                                helperText={lotErrors[AuctionCreateSchema.lotNumber] && lotErrors[AuctionCreateSchema.lotNumber].message}
                                placeholder={t('Lot_Number')} type="text" variant='standard'
                                {...lotRegister(AuctionCreateSchema.lotNumber, Validation.shortNameRequired)}
                            />
                        </Grid>
                    }
                    {mannerOfSaleDependancy && 
                        <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={8}>
                            <TextField id="lot-description" label={t('Lot_Description')} fullWidth
                                helperText={lotErrors[AuctionCreateSchema.lotDescription] && lotErrors[AuctionCreateSchema.lotDescription].message}
                                placeholder={t('Lot_Description')} type="text" variant='standard'
                                {...lotRegister(AuctionCreateSchema.lotDescription, Validation.longNameNotMandatory)}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={6} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={true}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.registrationStartDate}
                            rules={Validation.dateTime}
                            label={t('Registration_Start_Date')}
                            minDateTime={create ? serverCurrentTime : null}
                            disablePastDate={create ? true : false}
                            trigger={auctionTrigger}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={true}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.registrationEndDate}
                            rules={Validation.dateTime}
                            label={t('Registration_End_Date')}
                            minDateTime={auctionGetValues(AuctionCreateSchema.registrationStartDate) != null ? new Date(auctionGetValues(AuctionCreateSchema.registrationStartDate)) : null}
                            disablePastDate={create ? true : false}
                            trigger={auctionTrigger}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={6} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={true}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.auctionStartDate}
                            rules={Validation.dateTime}
                            label={t('Auction_Start_Date_Time')}
                            minDateTime={(isCustomerSupport || isAdmin) ? null : new Date(new Date(serverCurrentTime.setMilliseconds(0)).setSeconds(0))}
                            disablePastDate={(isCustomerSupport || isAdmin) ? false : true}
                            trigger={auctionTrigger}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={true}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.auctionEndDate}
                            rules={Validation.dateTime}
                            label={t('Auction_End_Date_Time')}
                            minDateTime={ (isCustomerSupport || isAdmin || auctionGetValues(AuctionCreateSchema.auctionStartDate) == null) ? null : 
                                new Date(new Date(auctionGetValues(AuctionCreateSchema.auctionStartDate)).setTime(new Date(auctionGetValues(AuctionCreateSchema.auctionStartDate)).getTime() + 300000))
                            }
                            disablePastDate={(isCustomerSupport || isAdmin) ? false : true}
                            trigger={auctionTrigger}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl 
                            mandatory={false}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.lastDateForKyc}
                            // rules={Validation.dateTime}
                            label={t('Last_Date_For_KYC_Submission')}
                            maxDateTime={(isCustomerSupport || isAdmin) ? null : maxDateOnAuctionStartDate}
                            // disablePastDate={create ? true : false}
                            trigger={lotTrigger}
                            setDateValidation={setDateValidation}
                            clearable={true}
                            setValue={lotSetValue}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.lastDateForEmd}
                            // rules={Validation.dateTime}
                            label={t('Last_Date_For_Emd_Amount')}
                            // minDateTime={auctionGetValues(AuctionCreateSchema.registrationStartDate) != null ? new Date(auctionGetValues(AuctionCreateSchema.registrationStartDate)) : null}
                            maxDateTime={(isCustomerSupport || isAdmin) ? null : maxDateOnAuctionStartDate}
                            // disablePastDate={create ? true : false}
                            trigger={lotTrigger}
                            setDateValidation={setDateValidation}
                            clearable={true}
                            setValue={lotSetValue}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.lastDateToFinalizeBidder}
                            label={t('Last_Date_To_Approve_Bidder')}
                            // minDateTime={auctionGetValues(AuctionCreateSchema.registrationStartDate) != null ? new Date(auctionGetValues(AuctionCreateSchema.registrationStartDate)) : null}
                            maxDateTime={(isCustomerSupport || isAdmin) ? null : maxDateOnAuctionStartDate}
                            // disablePastDate={create ? true : false}
                            trigger={auctionTrigger}
                            clearable={true}
                            setValue={auctionSetValue}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={6} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.startResultShareTime}
                            label={t('Result_Share_Start_Time')}
                            minDateTime={auctionGetValues(AuctionCreateSchema.auctionEndDate) != null ? new Date(auctionGetValues(AuctionCreateSchema.auctionEndDate)) : null}
                            disablePastDate={create ? true : false}
                            trigger={auctionTrigger}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={6}>
                        <DateTimePickerControl
                            mandatory={false}
                            errors={auctionErrors}
                            control={auctionControl}
                            name={AuctionCreateSchema.endResultShareTime}
                            label={t('Result_Share_End_Time')}
                            minDateTime={auctionGetValues(AuctionCreateSchema.startResultShareTime) != null ? new Date(auctionGetValues(AuctionCreateSchema.startResultShareTime)) : null}
                            disablePastDate={create ? true : false}
                            trigger={auctionTrigger}
                            setDateValidation={setDateValidation}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.baseCurrency}
                            control={auctionControl}
                            rules={Validation.selectRequired}
                            errors={auctionErrors}
                            label={t('Base_Currency')}
                            setValue={auctionSetValue}
                            getValues={auctionGetValues}
                            disabled={true}
                        >
                            {enumListOptions.Currency.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id}>{item.uiCode}</MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <NumberFormatController 
                            mandatory={true}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.reservePrice}
                            rules={Validation.minAmount}
                            label={t('Reserve_Price')}
                        />
                    </Grid>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <NumberFormatController 
                            mandatory={true}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.emdAmount}
                            rules={{
                                required: { value: true, message: "Field is required" },
                                validate: value => (value > 0 && value < lotGetValues(AuctionCreateSchema.reservePrice))
                                    || (value >= lotGetValues(AuctionCreateSchema.reservePrice) ? 'Value should be less than Reserve Price' : 'Value should be more than Zero')
                            }}
                            label={t('EMD_Amount')}
                        />
                    </Grid>
                    {bidOpenAmountRefresh && <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <NumberFormatController 
                            mandatory={true}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.bidStartPrice}
                            rules={{
                                required: { value: true, message: "Field is required" },
                                validate: value => (value >= lotGetValues(AuctionCreateSchema.reservePrice) && value <= 50000000000000)
                                    || ((value == null || value == '') && 'Field is required')
                                    || (value < lotGetValues(AuctionCreateSchema.reservePrice) && t('Value_Should_Not_be_Less_Than_Reserve_Price'))
                                    || (value > 50000000000000 && 'Value should not be more than 5,00,00,00,00,00,000')
                            }}
                            label={t('Bid_Start_Price')}
                        />
                    </Grid>}
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <NumberFormatController 
                            mandatory={true}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.incrementalAmount}
                            rules={Validation.minAmount}
                            label={t('Incremental_Amount')}
                        />
                    </Grid>
                    {/* <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <TextField id="winningPrice" label={t('Winning_Price')} fullWidth
                            helperText={lotErrors[AuctionCreateSchema.winningPrice] && lotErrors[AuctionCreateSchema.winningPrice].message}
                            placeholder={t('Winning_Price')} type="number" variant='standard'
                            {...lotRegister(AuctionCreateSchema.winningPrice, Validation.amount)}
                        />
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12} className={disable == true ? classes.disabledDiv : classes.stepContainer}>
                <Grid container className={classes.stepContent}>
                    <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.autoExtension}
                            control={lotControl}
                            errors={lotErrors}
                            label={t('Auto_Extension')}
                            setValue={lotSetValue}
                            getValues={lotGetValues}
                        >
                            {autoExtensionOption.map(item => (
                                <MenuItem value={item.value} key={item.id}>{item.description}</MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>
                    { autoExtensionDependancy && <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <TextField id="extendTimeWhenBidReceivedInLastMinute" label={t('Extend_Time_When_Bid_Received_Last_Minute')} fullWidth required
                            helperText={lotErrors[AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute] && lotErrors[AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute].message}
                            placeholder={t('Extend_Time_When_Bid_Received_Last_Minute')} type="text" variant='standard'
                            {...lotRegister(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute, {
                                required: { value: true, message: "Field is required" },
                                min: { value: (lotGetValues(AuctionCreateSchema.autoExtension) ? 1 : null), message: "Value should be more than or equal to 1" },
                                max: { value: getExtensionTimeValidationInMinutes(), message: "Value should not be more than Auction Duration" },
                                pattern: { value: /^[0-9]+$/, message: 'Invalid Value' }
                            })}
                        />
                    </Grid>}
                    { autoExtensionDependancy && <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        {/* <TextField id="extendTimeBy" label={t('Extend_Time_By')} fullWidth required
                            helperText={lotErrors[AuctionCreateSchema.extendTimeBy] && lotErrors[AuctionCreateSchema.extendTimeBy].message}
                            placeholder={t('Extend_Time_By')} type="text" variant='standard'
                            {...lotRegister(AuctionCreateSchema.extendTimeBy, {
                                required: { value: true, message: "Field is required" },
                                min: { value: lotGetValues(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute), message: `Value should not be less than ${t('Extend_Time_When_Bid_Received_Last_Minute')}` },
                                max: { value: 1000, message: "Value should be less than a day" },
                                pattern: { value: /^[0-9]+$/, message: 'Invalid Value' }
                            })}
                        /> */}
                        <ExtendAuctionTimeInput
                            mandatory={true}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.extendTimeBy}
                            label={t('Extend_Time_By')}
                            rules={{
                                required: { value: true, message: "Field is required" },
                                min: { value: lotGetValues(AuctionCreateSchema.extendTimeWhenBidReceivedInLastMinute), message: `Value should not be less than ${t('Extend_Time_When_Bid_Received_Last_Minute')}` },
                                max: { value: 1000, message: "Value should be less than a day" },
                                pattern: { value: /^[0-9]+$/, message: 'Invalid Value' }
                            }}
                            setValue={lotSetValue}
                            getValues={lotGetValues}
                            trigger={lotTrigger}
                            setFocus={lotSetFocus}
                        />
                    </Grid>}
                    { autoExtensionDependancy && incrementDuringExtensionRefresh && <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <NumberFormatController
                            mandatory={true}
                            errors={lotErrors}
                            control={lotControl}
                            name={AuctionCreateSchema.incrementalAmountDuringExtension}
                            // rules={Validation.amount}
                            label={t('Incremental_Amount_During_Extension')}
                            rules={{
                                required: { value: true, message: "Field is required" },
                                validate: value => (value >= lotGetValues(AuctionCreateSchema.incrementalAmount) && value <= 50000000000000)
                                    || ((value == null || value == '') && 'Field is required')
                                    || (value < lotGetValues(AuctionCreateSchema.incrementalAmount) && t('Value_Should_Not_be_Less_Than_Incremental_Amount'))
                                    || (value > 50000000000000 && 'Value should not be more than 5,00,00,00,00,00,000')
                            }}
                        />
                    </Grid>}
                    { autoExtensionDependancy && <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <SelectControl
                            mandatory={true}
                            name={AuctionCreateSchema.autoExtensionMode}
                            control={lotControl}
                            rules={Validation.selectRequired}
                            errors={lotErrors}
                            label={t('Auto_Extension_Mode')}
                            setValue={lotSetValue}
                            getValues={lotGetValues}
                        >
                            {enumListOptions.AutoExtensionMode.map(item => (
                                <MenuItem value={item.dateBaseCode} key={item.id}>{item.uiCode}</MenuItem>
                            ))}
                        </SelectControl>
                    </Grid>}
                    { autoExtensionDependancy && noOfExtensionDependancy && <Grid className={classes.gridContent} item xs={12} sm={6} md={6} lg={4}>
                        <TextField id="noOfExtension" label={t('No_Of_Extensions')} fullWidth required
                            helperText={lotErrors[AuctionCreateSchema.noOfExtension] && lotErrors[AuctionCreateSchema.noOfExtension].message}
                            placeholder={t('No_Of_Extensions')} type="text" variant='standard'
                            {...lotRegister(AuctionCreateSchema.noOfExtension, {...Validation.number, max: { value: 100, message: "Value should not be more than 100" },})}
                        />
                    </Grid>}
                </Grid>
            </Grid>
            {/* <Grid className={classes.gridContent} item xs={12} sm={12} md={6} lg={4}>
                <SelectControl
                    name={AuctionCreateSchema.auctionVariant}
                    control={auctionControl}
                    rules={Validation.name}
                    errors={auctionErrors}
                    label={t('Auction_Variant')}
                    defaultValue={enumListOptions.AuctionVariant[0].uiCode}
                >
                    {enumListOptions.AuctionVariant.map(item => (
                        <MenuItem value={item.dateBaseCode} key={item.id}>{item.uiCode}</MenuItem>
                    ))}
                </SelectControl>
            </Grid> */}
        </React.Fragment>
    );
}