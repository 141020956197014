import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Popper, Grow, Paper, ClickAwayListener, Stack } from '@mui/material';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

export const ExtendAuctionTimeInput = (props) => {
  const { errors, control, name, rules, label, mandatory, setValue, getValues, trigger, setFocus } = props;
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputBlur, setInputBlur] = useState(null);
  const [selectBlur, setSelectBlur] = useState(null);
  const { t } = useTranslation();
  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
    setInputBlur(false);
    setSelectBlur(false);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    setOpenFilter(false);
  };

  const handleMenuItemClick = (value) => {
    setValue(name, value);
    handleClose();
    trigger(name);
  };

  useEffect(() => {
    if(inputBlur != null && selectBlur!= null ) {
      if((selectBlur && inputBlur)) {
        handleClose();
      } else {
        setOpenFilter(true);
      }
    }
  }, [inputBlur, selectBlur])


  return (
    <Controller
      errors={errors}
      control={control}
      name={name}
      rules={rules}
      render={({ field: { name, onChange, value, ref } }) => (
        <>
          <Stack >
            <TextField fullWidth
                required={mandatory}
                variant="standard"
                id={name} 
                label={label}
                onClick={handleOpen}
                onFocus={handleOpen}
                onBlur={ () => setInputBlur(true)}
                inputRef={ref}
                value={value}
                onChange={onChange}
                helperText={errors[name] && errors[name].message}
            />
          </Stack>
          <Popper
            placement='bottom-start'
            open={openFilter}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left bottom',
                  }}
                >
                  <Paper onFocus={() => setSelectBlur(false)} onBlur={ () => setSelectBlur(true)}>
                      <ClickAwayListener onClickAway={() => setSelectBlur(true)}
                      >
                      <MenuList
                        id="composite-input-select"
                        aria-labelledby={name}
                      >
                        <MenuItem onClick={() => handleMenuItemClick(5)}>{t('Minutes_5')}</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick(10)}>{t('Minutes_10')}</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick(15)}>{t('Minutes_15')}</MenuItem>
                      </MenuList>
                      </ClickAwayListener>
                  </Paper>
                </Grow>
            )}
          </Popper>
        </>
      )}
    />
  );
}
