import React, {Fragment, useContext, useEffect, useState} from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useTranslation } from 'react-i18next';
import MillisecondsToDuration from '../../utils/MillisecondsToDuration';
import { CurrencyFormatter } from '../../utils/Utils';
import { UserContext } from '../../common/context/UserContext';
import { AutoExtensionMode } from '../../common/StaticVariableDeclaration';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../common/GraphQLErrors';
import { GET_AUCTION } from '../../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';

const moment = require("moment-timezone");

export const AuctionBriefToPublish = (props) => {

    const { t } = useTranslation();
    const [auctionDetail, setAuctionDetail] = useState(null);
    const [auctionDuration, setAuctionDuration] = useState(null);
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
    const enumListObject = useContext(UserContext).rootContext.enumListObject;

    const [getAuctionDetail, { loading: getAuctionLoading, error: getAuctionError }] = useLazyQuery(GET_AUCTION, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: false,
        onCompleted: auctions => {
            if (auctions.getAuction != null) {
                setAuctionDetail(auctions.getAuction);
                console.log('GET_AUCTION', auctions.getAuction)
                setAuctionDuration(MillisecondsToDuration(new Date(auctions.getAuction.startTime), new Date(auctions.getAuction.endTime)))
            }
        },
        onError: (error) => {
            console.log('getAuctionDetail -> NETWORK error :', error);
        }
    });

    useEffect(() => {
        console.log('AuctionBriefToPublish', props)
        getAuctionDetail({
            variables: { auctionId: props.auctionId }
        });
    }, [])

    return (
        <Fragment>
            { auctionDetail && <Stack spacing={1} direction='column' sx={{ background: 'aliceblue', borderRadius: '10px', padding: '10px' }}>
                <Stack  spacing={1} direction='row' justifyContent='center' alignItems='center'>
                    <InfoRoundedIcon color='info' />
                    <Typography variant='subtitle1' color='primary'>{ t('Auction_Summary') }</Typography>
                </Stack>
                <Grid container>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row'>
                            {/* <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} /> */}
                            <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Auction_Start_Date_Time') }:</Typography>
                        </Stack> 
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row' alignItems='center'>
                            <CalendarMonthRoundedIcon sx={{ fontSize: '14px', color: 'slategray' }} />
                            <Typography fontSize='14px' fontWeight='600'>{format(new Date(auctionDetail.startTime), 'dd MMM yyyy hh:mm a')}</Typography>
                            <Typography fontSize='14px' style={{ color: 'slategray'}}>({dateTimeZoneValue})</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row'>
                            {/* <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} /> */}
                            <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Auction_End_Date_Time') }:</Typography>
                        </Stack> 
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row' alignItems='center'>
                            <CalendarMonthRoundedIcon sx={{ fontSize: '14px', color: 'slategray' }} />
                            <Typography fontSize='14px' fontWeight='600'>{format(new Date(auctionDetail.endTime), 'dd MMM yyyy hh:mm a')}</Typography>
                            <Typography fontSize='14px' style={{ color: 'slategray'}}>({dateTimeZoneValue})</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row'>
                            {/* <ScheduleRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} /> */}
                            <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Auction_Duration') }:</Typography>
                        </Stack> 
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row' alignItems='center'>
                            <ScheduleRoundedIcon sx={{ fontSize: '14px', color: 'slategray' }} />
                            {auctionDuration && <Typography fontSize='14px' fontWeight='600'>
                                { auctionDuration.days > 0 && auctionDuration.days + ' ' + (auctionDuration.days > 1 ? t('days') : t('day')) }
                                { auctionDuration.hours > 0 && (auctionDuration.days > 0 ? ', ' : '') + auctionDuration.hours + ' ' + (auctionDuration.hours > 1 ? t('hours') : t('hour')) }
                                { auctionDuration.minutes > 0 && ((auctionDuration.hours > 0 || auctionDuration.days > 0) ? ', ' : '') + auctionDuration.minutes + ' ' + (auctionDuration.minutes > 1 ? t('minutes') : t('minute')) }
                            </Typography>}
                        </Stack>
                    </Grid>
                    {auctionDetail.auctionLots[0]?.bidStartPrice && <Fragment>
                        <Grid item xs={6}>
                            <Stack spacing={0.5} direction='row'>
                                {/* <CurrencyRupeeRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} /> */}
                                <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Bid_Start_Price') }:</Typography>
                            </Stack> 
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5} direction='row'>
                                <Typography fontSize='14px' fontWeight='600'>
                                    {CurrencyFormatter(auctionDetail.baseCurrency, auctionDetail.auctionLots[0].bidStartPrice)}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Fragment>}
                    {auctionDetail.auctionLots[0]?.incrementalAmount && <Fragment>
                        <Grid item xs={6}>
                            <Stack spacing={0.5} direction='row'>
                                {/* <CurrencyRupeeRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} /> */}
                                <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Incremental_Amount') }:</Typography>
                            </Stack> 
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5} direction='row'>
                                <Typography fontSize='14px' fontWeight='600'>
                                    {CurrencyFormatter(auctionDetail.baseCurrency, auctionDetail.auctionLots[0].incrementalAmount)}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Fragment>}
                    {auctionDetail.auctionLots[0].autoExtension == true && <Fragment>
                        <Grid item xs={6}>
                            <Stack spacing={0.5} direction='row'>
                                {/* <CurrencyRupeeRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} /> */}
                                <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Incremental_Amount_During_Extension') }:</Typography>
                            </Stack> 
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5} direction='row'>
                                <Typography fontSize='14px' fontWeight='600'>
                                    {CurrencyFormatter(auctionDetail.baseCurrency, auctionDetail.auctionLots[0].incrementalAmountDuringExtension)}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Fragment>}
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row'>
                            <Typography fontSize='14px' sx={{textAlign: 'left'}}>{ t('Extension_Details') }:</Typography>
                        </Stack> 
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={0.5} direction='row'>
                            { auctionDetail.auctionLots[0].autoExtension == false &&
                                <Typography fontSize='14px' fontWeight='600'>{t('No_Extensions')}</Typography>
                            }
                            { auctionDetail.auctionLots[0].autoExtension == true &&
                                <Typography fontSize='14px' fontWeight='600' sx={{textAlign: 'left'}}>
                                    {enumListObject.AutoExtensionMode[auctionDetail.auctionLots[0].autoExtensionMode].uiCode + ' / '}
                                    {auctionDetail.auctionLots[0].autoExtensionMode == AutoExtensionMode.limitedExtension &&
                                        auctionDetail.auctionLots[0].noOfExtension + ' ' + t('extensions') + ' / '}
                                    { t('Auction_Extended_By') + ' ' + auctionDetail.auctionLots[0].extendTimeBy + ' ' + t('minutes') + ' ' +
                                        t('Valid_Bid_Received_In_Last') + ' ' + auctionDetail.auctionLots[0].extendTimeWhenBidReceivedInLastMinute + ' ' + t('minutes')
                                    }
                                </Typography>
                            }
                        </Stack>
                    </Grid>
                </Grid>
                {getAuctionLoading && <AuctionLoader show={getAuctionLoading} invisible={false} />}
                {getAuctionError && <GraphQLErrors error={getAuctionError} show={false} />}
            </Stack>}
        </Fragment>
    )
}