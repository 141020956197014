import React, { useState } from 'react';
import {
    Card, Typography, Grid,
    TextField, Button, CircularProgress, Link, ToggleButtonGroup, ToggleButton, InputAdornment, Box
} from '@mui/material';
import { GppMaybe } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import AuctionSnackBar from '../common/AuctionSnackBar'
import { ContentBox, AlertInfoBox, RootDiv } from './AuthStyle';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import AuctionReCaptcha from '../common/AuctionReCaptcha';
import { AuthAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import {MdAttachEmail} from "react-icons/md"
import {FaMobileAlt} from "react-icons/fa"
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from 'react-phone-number-input';
import { makeStyles } from '@mui/styles';
import { AppHostDomain } from '../common/StaticVariableDeclaration';

const useStyles = makeStyles(() => ({
    container: {
        fontWeight: "bold !important",
        fontSize: '12px !important',
        paddingRight: '1em !important'
    },
    EmailColor:{
        background: '#1976d2 !important',
        color: 'white !important',
        borderRadius: "50px 0px 0px 50px !important",
    },
    mobileNumber:{
        background: '#1976d2 !important',
        color: 'white !important',
        borderRadius: "0px 50px 50px 0px !important",
    },
    defaultMobileColor:{
        borderRadius: "0px 50px 50px 0px !important",
    },
    defaultEmailColor:{
        borderRadius: "50px 0px 0px 50px !important",
    }

}))

const VerifyUser = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setError] = useState();
    const [isValidForm, setIsValidForm] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [emailOrPhone, setEmailOrPhone] = useState('email')
    const [extension, setExtension] = useState('+91')

    const handleValidation = (fieldValue) => {
        let valid = true;
        setError('')
        if (!fieldValue) {
            valid = false;
            setError("Email Id or Mobile number is required");
        } else {
            if(emailOrPhone === 'email'){
                if(!(/(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValue))) {
                        valid = false;
                        setError(t('Invalid_Email_Address'));
                }
            } else {
                if (fieldValue != undefined && fieldValue != '' && !isValidPhoneNumber( extension + fieldValue)) {
                        valid = false;
                        setError(t('Invalid_Mobile_number'));
                    }
            }
        }
        return valid;
        // setIsValidForm(true);
        // setError('')
        // if (!fieldValue) {
        //     setIsValidForm(false);
        //     setError(t('Email_Address_CellPhone_Required'));
        // } else {
        //     if(/^[0-9+]+$/.test(fieldValue)) {
        //         //Check for cellphone validity
        //     } else if(!(/(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValue))) {
        //         setIsValidForm(false);
        //         setError(t('Invalid_Email_Address_CellPhone'));
        //     }
        // }
    }

    const sendOtp = async event => {
        handleValidation(username);
        setErrorMessage('');
        let isValid = handleValidation(username);
        if (isValid) {
            event.preventDefault();
            setLoading(true);
            AuthAxiosAuctionInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=verify-signup`, {
                loginId: emailOrPhone === 'email' ? username : extension + username
            }).then(response => {
                setErrorMessage('');
                setLoading(false);
                history.push({
                    pathname: "/sign-up/success", state: {
                        emailid: emailOrPhone === 'email' && username,
                        phoneNumber: emailOrPhone === 'mobile' && extension + username,
                        isVerifyLater: false
                    }
                })
            //     if(/^[0-9+]+$/.test(username)) {
            //         history.push({
            //             pathname: "/sign-up/success", state: {
            //                 phoneNumber: username,
            //                 isVerifyLater: false
            //             }
            //         })
            //     } else {
            //         history.push({
            //             pathname: "/sign-up/success", state: {
            //                 emailid: username,
            //                 isVerifyLater: false
            //             }
            //         })
            //     }
            }).catch((error) => {
                setLoading(false);
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setErrorMessage(error.message);
                }
            })
        }
    }

    const login = () => {
        history.push("/sign-in")
    }

    const onMobileNumberChange = (value) =>{
        setExtension(value)
      }

    const handleMobileNumberChange = (fieldName, event) => {
        if(event.target.value === '' || /^[0-9]+$/.test(event.target.value)){
            setUsername(event.target.value)
        }
    }
    const handleLoginDetails = (event, newValue) => {
        setError('')
        setUsername('')
        setEmailOrPhone(newValue === null ? emailOrPhone : newValue)
    }

    return (
        <React.Fragment>
            <RootDiv>
                <Card className='card' sx={{ maxWidth: '600px !important' }}>
                    <ContentBox height="100%">
                        <Grid container style={{ paddingTop: '8px' }}>
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && 
                                <Grid item xs={12} sx={{ textAlign : 'center', paddingBottom: '10px' }}>
                                    <img src={process.env.PUBLIC_URL + '/images/auction.svg'} height={50} alt='E-AuctionLogo'></img>
                                </Grid>
                            }
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && 
                                <Grid item xs={12} sx={{ textAlign : 'center', paddingBottom: '10px' }}>
                                    <img src={process.env.PUBLIC_URL + '/images/nbid.png'} height={45} alt='NbidLogo'></img>
                                </Grid>
                            }
                            {/* { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && 
                                <Grid item xs={6} sx={{ textAlign : 'end' }}>
                                    <img src={process.env.PUBLIC_URL + '/images/nesl-pda.png'} height={50} alt='NeslPDALogo'></img>
                                </Grid>
                            } */}
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5" gutterBottom className="text-center">
                                    {t('Account_Verification')}
                                </Typography>
                                <br />
                                <Typography variant="body2" component="p" className="text-center">
                                    <strong>{t('Please_provide_the_registered_email_address_or_cellphone_to_send_OTP')}</strong>
                                </Typography>
                                <AlertInfoBox sx={{ justifyContent: 'center' }}>
                                    <GppMaybe />
                                    {t('Ensure_that_your_Username_is_registered_with_us')}
                                </AlertInfoBox>
                                <div  style={{textAlign:'center', marginTop:'1rem', marginBottom:'1rem'}}>
                            <ToggleButtonGroup
                                    value={emailOrPhone}
                                    exclusive
                                    size="small"
                                    onChange={handleLoginDetails}
                                    aria-label="text alignment"
                                  >
                                    {/* <Tooltip title="Login using Email"> */}
                                      <ToggleButton
                                        value="email"
                                        aria-label="left aligned"
                                        className={`${classes.container} ${emailOrPhone === "email" ? classes.EmailColor : classes.defaultEmailColor}`}

                                      >
                                        <MdAttachEmail fontSize='large'/>
                                        &nbsp; email
                                      </ToggleButton>
                                    {/* </Tooltip> */}
                                    {/* <Tooltip title="Login using Mobile Number"> */}
                                      <ToggleButton
                                        value="mobile"
                                        aria-label="centered"
                                        className={`${classes.container} ${emailOrPhone === "mobile" ? classes.mobileNumber : classes.defaultMobileColor}`}

                                      >
                                        <FaMobileAlt  fontSize='large'/> Mobile Number
                                      </ToggleButton>
                                    {/* </Tooltip> */}
                                  </ToggleButtonGroup>
                                  </div>
                                  { emailOrPhone === 'email' ? <TextField id="email" label={t('Username_Email')}
                                    style={{ margin: '8px 8px 8px 0px' }} fullWidth value={username}
                                    onChange={(e) => setUsername(e.target.value)} helperText={errors}
                                    error={ errors } required autoFocus
                                    // onBlur={() => handleValidation(username)}
                                    disabled={loading} autoComplete="off"
                                /> : <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <TextField
                            id="phonenumber"
                            name="phonenumber"
                            // type="number"
                            style={{margin: "8px 8px 8px 0px"}}
                            label={t("Username_Mobile_Number")}
                            variant="outlined" fullWidth
                            error={(errors)}
                            helperText={errors}
                            value={ emailOrPhone === 'mobile' && username}
                            onChange={(event, country) => {
                                    handleMobileNumberChange('phoneNumber', event, country)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MuiPhoneNumber
                                            id="input-with-icon-adornment"
                                            name="extension"
                                            defaultCountry={"in"}
                                            value={extension}
                                            onChange={(event) => onMobileNumberChange(event)}
                                            autoFormat={false}
                                            style={{margin: "8px 0px", color: "black !important",
                                            width: extension.length > 3 ? extension.length === 4 ? '4.8rem' : extension.length === 5 ? '5.3rem' : '6.3rem' : '4rem'}}
                                            required
                                            disableDropdown={loading}
                                            countryCodeEditable={false}
                                            disabled
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            />
                   </Box>}

                            </Grid>
                            <Grid item xs={12} className="text-center" style={{ marginTop: '15px' }}>
                                <Link onClick={login} style={{ float: 'left', fontSize: '12px', cursor: 'pointer' }}>{t('Back_to_Sign_In')}</Link>
                                <Button size="small" variant="contained" color="primary" style={{ float: 'right' }}
                                    onClick={sendOtp}
                                    disabled={loading}>
                                    {t('Send_OTP')}
                                    {loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </ContentBox>
                </Card>
                {loading && <AuctionLoader show={loading} />}
                {errorMessage !== '' && errorMessage !== undefined ?
                    <AuctionSnackBar show={true} message={errorMessage} severity="error" reset={() => setErrorMessage(null)}></AuctionSnackBar> : null}
                {!isRefresh && <AuctionReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
            </RootDiv>
        </React.Fragment>
    );
}

export default VerifyUser;