import { useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { BottomNavigation, Button, Grid, IconButton, MenuItem, MenuList, Paper, Popover, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { format } from 'date-fns';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionConfirmDialog from '../../common/AuctionConfirmDialog';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { UserContext } from '../../common/context/UserContext';
import { FileIcon } from '../../common/files-upload/FileIcon';
import UploadDialog from '../../common/files-upload/UploadDialog';
import { fileSizeConversion } from '../../common/FileSizeConversion';
import GraphQLErrors from '../../common/GraphQLErrors';
import AuctionPagination from '../../common/pagination/AuctionPagination';
import { FilePath, Roles } from '../../common/StaticVariableDeclaration';
import { PublicAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import { DELETE_REQUIRED_DOCUMENT, UPDATE_REQUIRED_DOCUMENT } from '../../GraphQL/Mutation';
import {
    GET_PRE_SIGNED_URL, LIST_BIDDER_FOR_DROPDOWN, LIST_FILE_VERSIONS, LIST_REQUIRED_DOCUMENTS
} from '../../GraphQL/Queries';
import BidderDocumentDetail from '../bidder-documents-detail/BidderDocumentDetail';
import ReuploadPopUp from '../ReuploadPopUp';
import BidderDocumentUploadList from './BidderDocumentUploadList';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const useStyles = makeStyles((theme) => ({
    paper: {
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '0px',
        border: "1px solid rgb(238, 238, 238)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        margin: "auto auto ",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 10px !important",
    },
    tableData: {
        color: theme.tableDataColor
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    '.MuiInputLabel-root': {
        position: 'relative !important',
        top: '20px !important'
    },
    tableHeadRow: {
        backgroundColor: theme.tableHearderBackgroundColor
    },
    fileNameDivBlock: {
        width: '115px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto',
        float: 'left'
    },
    fileSizeClass: {
        fontSize: '12px',
        color: theme.fileSizeColor,
        marginTop: '2px',
        float: 'right'
    },
    fileTypeColorClass: {
        color: theme.fileTypeDisplayColor,
        margin: 'auto'
    },
    infoMsg: {
        color: theme.bankAccountInfoColor,
        fontWeight: 'bold',
        fontSize: '14px'
    },

}))

const BidderDocumentList = ({ auction_Id, showActionColumn, roles, isCustomerSupport, showBidderDropdown, showAdd, showEdit, showDelete, showUpload }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [auctionId] = useState(auction_Id);
    const [bidderDocuments, setBidderDocuments] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalNumberOfDocuments, setTotalNumberOfDocuments] = useState(0);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [biddersList, setBidders] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [documentDetail, setDocumentDetail] = useState({});
    const [isReupload, setIsReupload] = useState(false);
    const [fileUploadDialog, setFileUploadDialog] = useState(false);
    const [versionList, setVersionList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedBidderId, setSelectedBidder] = useState(null);
    const [popOverEvent, setPopOverEvent] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = 'version-download';
    const [isDelete, setIsDelete] = useState(false);
    const [existingTypes, setExistingTypes] = useState([]);
    const [showBidder, setShowBidder] = useState(showBidderDropdown ? showBidderDropdown : false);
    const [loading, setLoading] = useState(false)
    const userContext = useContext(UserContext).rootContext;
    const [canAdd] = useState(showAdd ? showAdd : false);
    const [canEdit] = useState(showEdit ? showEdit : false);
    const [canDelete] = useState(showDelete ? showDelete : false);
    const [canUpload] = useState(showUpload ? showUpload : false);
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [listRequiredDocuments, { loading: fetchingRequiredDocuments, error: errorOnFetchingRequiredDocuments, refetch }] =
        useLazyQuery(LIST_REQUIRED_DOCUMENTS, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setBidderDocuments([]);
                setExistingTypes([]);
                if (data.listRequiredDocuments != null) {
                    data.listRequiredDocuments.documents.map(requiredDocument => {
                        setExistingTypes(prevState => ([
                            ...prevState,
                            requiredDocument.name
                        ]))
                        setBidderDocuments(prevState => ([
                            ...prevState,
                            {
                                id: requiredDocument.id,
                                name: requiredDocument.name,
                                submitFormat: requiredDocument.submitFormat,
                                requiresDigitalSignature: requiredDocument.requiresDigitalSignature,
                                isMandatory: requiredDocument.isMandatory,
                                s3Uri: requiredDocument.s3Uri,
                                templateS3Uri: requiredDocument.templateS3Uri,
                                startTime: requiredDocument.startTime,
                                endTime: requiredDocument.endTime,
                                addedBy: requiredDocument.addedBy,
                                removedBy: requiredDocument.removedBy,
                                updatedBy: requiredDocument.updatedBy,
                                referenceNumber: requiredDocument.referenceNumber,
                                requiredDocumentBrief: requiredDocument.requiredDocumentBrief,
                                submittedDocumentBrief: requiredDocument.submittedDocumentBrief,
                                toSubmit: requiredDocument.toSubmit,
                                templateS3UriSize: fileSizeConversion(requiredDocument.templateS3UriSize),
                                templateS3UriFileName: requiredDocument.templateS3UriFileName,
                                templateS3UriFileLastModifiedTime: requiredDocument.templateS3UriFileLastModifiedTime,
                            }
                        ]))
                    })
                    setTotalNumberOfDocuments(data.listRequiredDocuments.count);
                }
            }
        })

    const [deleteRequiredDocument, { loading: deletingRequiredDocument, error: errorOnDeletingRequiredDocument }] =
        useMutation(DELETE_REQUIRED_DOCUMENT, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.deleteRequiredDocument) {
                    showSuccessMsg({ show: true, message: t('Document_Deleted_Successfully'), severity: 'success' });
                    if (page !== 1) {
                        let lastPageRows = totalNumberOfDocuments % rowsPerPage;
                        let noOfPage = ~~(totalNumberOfDocuments / rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                        if (lastPageRows == 1 && page == noOfPage) {
                            setPage(1);
                        } else {
                            refetch();
                        }
                    } else {
                        refetch();
                    }
                }
            },
            // refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const [updateRequiredDocument, { loading: updatingRequiredDocument, error: errorOnUpdatingRequiredDocument }] =
        useMutation(UPDATE_REQUIRED_DOCUMENT, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.updateRequiredDocument) {
                    showSuccessMsg({ show: true, message: t('Document_Uploaded_Successfully'), severity: 'success' });
                }
            },
            refetchQueries: [LIST_REQUIRED_DOCUMENTS]
        })

    const [listBiddersForDropdown, { loading: fetchingBidders, error: errorOnFetchingBidders }] = useLazyQuery(LIST_BIDDER_FOR_DROPDOWN, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setBidders([]);
            if (data.listBidderName != null) {
                setBidders(data.listBidderName);
            }
        }
    })

    const addNewDocument = () => {
        setOpenPopup(true);
        setIsNew(true);
    }

    const setCancel = (isCancel) => {
        setOpenPopup(false);
    }

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            listRequiredDocuments({
                variables: {
                    auctionId: auctionId,
                    bidderId: null,
                    pagination: {
                        page: page,
                        limit: rowsPerPage,
                        asc: false
                    },
                    showDeleted: false,
                    toSubmit: true
                }
            })
        } else {
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/document/${auctionId}/${page}/${rowsPerPage}/false/true`).then((response) => {
                setBidderDocuments([]);
                response.documents.map(requiredDocument => {
                    setBidderDocuments(prevState => ([
                        ...prevState,
                        {
                            id: requiredDocument.id,
                            name: requiredDocument.name,
                            submitFormat: requiredDocument.submitFormat,
                            requiresDigitalSignature: requiredDocument.requiresDigitalSignature,
                            isMandatory: requiredDocument.isMandatory,
                            s3Uri: requiredDocument.s3Uri,
                            templateS3Uri: requiredDocument.templateS3Uri,
                            startTime: requiredDocument.startTime,
                            endTime: requiredDocument.endTime,
                            addedBy: requiredDocument.addedBy,
                            removedBy: requiredDocument.removedBy,
                            updatedBy: requiredDocument.updatedBy,
                            referenceNumber: requiredDocument.referenceNumber,
                            requiredDocumentBrief: requiredDocument.requiredDocumentBrief,
                            submittedDocumentBrief: requiredDocument.submittedDocumentBrief,
                            toSubmit: requiredDocument.toSubmit,
                            templateS3UriSize: fileSizeConversion(requiredDocument.templateS3UriSize),
                            templateS3UriFileName: requiredDocument.templateS3UriFileName,
                            templateS3UriFileLastModifiedTime: requiredDocument.templateS3UriFileLastModifiedTime,
                            isNew: false
                        }
                    ]))
                })
                setTotalNumberOfDocuments(response.count);
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({ show: false, message: '', severity: '' })
                setMessage({ show: true, message: error, severity: 'error' })
            });
        }
    }, [rowsPerPage, page])

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setTimeout(() => {
                setMessage(msg);
            }, 100)
        }
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    }

    useEffect(() => {
        if (roles?.includes(Roles.auctioneer || Roles.support) || isCustomerSupport) {
            listBiddersForDropdown({
                variables: {
                    auctionId: auctionId,
                }
            })
        }
    }, [])

    const deleteDocumentConfirmation = (documentId) => {
        setIsDelete(true);
        resetSeekConfirmation();
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_Document'),
            onAgree: () => deleteDocument(documentId),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const deleteDocument = (documentId) => {
        resetSeekConfirmation();
        if (documentId) {
            deleteRequiredDocument({
                variables: {
                    auctionId: auctionId,
                    documentId: documentId
                }
            })
        }
    }

    const updateDocument = (document) => {
        setOpenPopup(true);
        setIsNew(false);
        setDocumentDetail(document);
    }

    const handleUpload = (document) => {
        setIsDelete(false);
        setDocumentDetail(document);
        setFileUploadDialog(false);
        if (document.templateS3Uri == '') {
            setFileUploadDialog(true);
        } else {
            setSeekConfitmation({
                show: true,
                title: t('Reupload'),
                message: t('Do_You_Want_To_Reupload_With_Same_File_Name'),
                onAgree: () => handleUserInput(true),
                onDisAgree: () => handleUserInput(false),
                isCancel: true
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const handleUserInput = (isReupload) => {
        setIsReupload(isReupload)
        resetSeekConfirmation();
        setFileUploadDialog(true);
    }

    const recordFileUpload = (presignedUrl) => {
        resetSeekConfirmation();
        if (auctionId) {
            updateRequiredDocument({
                variables: {
                    auctionId: auctionId,
                    documentInput: {
                        id: documentDetail.id,
                        name: documentDetail.name,
                        isMandatory: documentDetail.isMandatory,
                        requiresDigitalSignature: documentDetail.requiresDigitalSignature,
                        submitFormat: documentDetail.submitFormat,
                        templateS3Uri: presignedUrl,
                        toSubmit: documentDetail.toSubmit,
                        requiredDocumentBrief: documentDetail.requiredDocumentBrief
                    }
                }
            })
        }
    }

    const handlePublicApi = (event, document) => {
        setAnchorEl(null);
        setAnchorEl(event.currentTarget);
        setVersionList([]);
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/list-versions?fileName=${document.templateS3Uri}`).then((response) => {
            if (response.length > 1) {
                setVersionList(response)
                setLoading(false);
            } else {
                setAnchorEl(null);
                setPopOverEvent(null);
                downloadPublicApiFile(document.templateS3Uri, '');
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' })
            setMessage({ show: true, message: error, severity: 'error' })
        });
    }

    const handleDownload = (event, document) => {
        setPopOverEvent(event.currentTarget);
        setDocumentDetail(document);
        if (document.templateS3Uri != '') {
            if (userContext.userSession.isAuthenticated) {
                getListVersions({
                    variables: {
                        fileName: document.templateS3Uri
                    }
                });
            } else {
                handlePublicApi(event, document);
            }
        } else {
            showSuccessMsg({ show: true, message: t('Template_Is_Not_Uploaded_By_Auctioneer_Support'), severity: 'error' })
        }
    }

    const [getListVersions, { loading: loadingFileVersions, error: errorOnLoadingFileVersions }] = useLazyQuery(LIST_FILE_VERSIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.listFileVersions.length > 1) {
                setAnchorEl(popOverEvent);
                setVersionList(data.listFileVersions)
            } else {
                setPopOverEvent(null);
                setAnchorEl(null);
                getFileDownloaded({
                    variables: {
                        presignedUrl: {
                            fileName: documentDetail.templateS3Uri,
                            bucket: process.env.REACT_APP_S3_BUCKET,
                            httpMethod: 'GET',
                            versionId: '',
                            isVersion: false,
                        }
                    }
                })
            }
        }
    })

    const [getFileDownloaded, { loading: gettingPresignedUrl, error: errorOnGettingPresignedUrl }] = useLazyQuery(GET_PRE_SIGNED_URL, {
        fetchPolicy: 'network-only',
        onCompleted: preSignUrlData => {
            if (preSignUrlData.getPresignedUrl) {
                let subURL = preSignUrlData.getPresignedUrl.split("?")[0];
                let fileName = subURL.substring(subURL.lastIndexOf('/') + 1);
                axios({
                    url: preSignUrlData.getPresignedUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    setTimeout(() => {
                        // setDownload(false)
                    }, 1000)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute('download', decodeURI(fileName));

                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ show: true, message: error, severity: 'error' })
                    // setDownload(false)
                })
            }
        }
    })

    const handleSelectedBidder = (event) => {
        setSelectedBidder(event.target.value)
    }

    const handleVersionDownload = (event, version) => {
        if (userContext.userSession.isAuthenticated) {
            getFileDownloaded({
                variables: {
                    presignedUrl: {
                        fileName: version.key,
                        bucket: process.env.REACT_APP_S3_BUCKET,
                        httpMethod: 'GET',
                        versionId: version.versionId,
                        isVersion: false,
                    }
                }
            })
        } else {
            downloadPublicApiFile(version.key, version.versionId)
        }
    }

    const downloadPublicApiFile = (url, version_id) => {
        setLoading(true);
        PublicAxiosAuctionInterceptor.get(`rest/file/get-presigned-url/${process.env.REACT_APP_S3_BUCKET}?fileName=${url}&versionId=${version_id}`).then((response) => {
            if (response) {
                let subURL = response.split("?")[0];
                let fileName = subURL.substring(subURL.lastIndexOf('/') + 1);
                axios({
                    url: response,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute('download', decodeURI(fileName));
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ show: true, message: error, severity: 'error' })
                    // setDownload(false)
                })
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' })
            setMessage({ show: true, message: error, severity: 'error' })
        });
    }

    const handleClose = () => {
        setAnchorEl(null);
        setVersionList([]);
    };

    return (
        <React.Fragment>
            <Grid container>
                { (showBidder && selectedBidderId != null) ? 
                    <Grid item xs={12} sx={{padding: '5px 0px'}}>
                        { showBidder && selectedBidderId != null &&
                            <IconButton color="secondary" sx={{ padding: '3px', border: '1px solid', borderRadius: '5px', float: 'right' }}
                                onClick={() => setSelectedBidder(null)}>
                                <CloseRoundedIcon />
                            </IconButton>
                        }
                        <TextField
                            id="select-bidder"
                            select
                            fullWidth
                            label="Select Bidder"
                            size="small"
                            value={selectedBidderId}
                            onChange={handleSelectedBidder}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 33
                                },
                                width: '20% !important',
                                float: 'right'
                            }}
                        >
                            <MenuItem value={null}>{biddersList.length > 0 ? t('Clear') : t('No_Bidders_Available')}</MenuItem>
                            {biddersList.map((option) => (
                                <MenuItem key={option.personId} value={option.personId}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <span className={classes.infoMsg} style={{ paddingRight: '10px', float: 'right'}}>
                            { t('Bidder_Submission_Documents_Of_Bidder') }
                        </span>
                    </Grid>
                :
                     <Fragment>
                        <Grid item xs={8} sx={{padding: '5px 0px'}}>
                            <span className={classes.infoMsg}>
                                { t('Bidder_Submittion_Documents_Info') }
                            </span>
                        </Grid>
                        <Grid item xs={4} sx={{ padding: '5px 0px 5px 5px' }}>
                            {(selectedBidderId == null && canAdd) && <Button variant="contained" id="add-bidder-doc" size="small" startIcon={<AddIcon />}
                                sx={{ float: 'right', marginLeft: '10px' }} onClick={addNewDocument}>
                                <span>{t('Add')}</span>
                            </Button>}
                            { showBidder && <TextField
                                id="select-bidder"
                                select
                                fullWidth
                                label="Select Bidder"
                                size="small"
                                value={selectedBidderId}
                                onChange={handleSelectedBidder}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 33
                                    },
                                    width: '60% !important',
                                    float: 'right'
                                }}
                            >
                                <MenuItem value={null}>{biddersList.length > 0 ? t('Clear') : t('No_Bidders_Available')}</MenuItem>
                                {biddersList.map((option) => (
                                    <MenuItem key={option.personId} value={option.personId}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField> }
                        </Grid>
                     </Fragment>               
                }
                {selectedBidderId == null && <TableContainer component={Paper} className={classes.paper}>
                    <Table aria-label="bidder documents table" stickyHeader>
                        <TableHead className={`${classes.tableHeadRow}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '20%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Document_Type_Accepted_File_Types")}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '29%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Document_Brief")}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                                style={{ width: '9%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Mandatory")}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                                style={{ width: '9%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Digital_Sign")}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHead}`}
                                style={{ width: '17%', borderRight: '1px solid #c8d6eb' }}>
                                {t("Template")}
                            </TableCell>
                            {showActionColumn && <TableCell className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: '13%' }} align="center">{(roles?.includes(Roles.auctioneer || Roles.support) || isCustomerSupport) ? t("Actions") : t("Download")}</TableCell>}
                        </TableHead>
                        <TableBody id="bidder-document-list">
                            {bidderDocuments.length === 0 &&
                                <TableRow hover>
                                    <TableCell id={'no-bidder-doc-row'} colSpan={7} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                </TableRow>
                            }
                            {bidderDocuments.length > 0 && bidderDocuments.map((document, index) => {
                                return (<TableRow key={document.id + index} id={`bidder-doc${index}`}>
                                    <TableCell id={`bidder-doc-name${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.name}
                                        <p className={classes.fileTypeColorClass}>
                                            {document.submitFormat.length > 0 ? '[' + document.submitFormat.join(", ") + ']' : '-'}
                                        </p></TableCell>
                                    <TableCell id={`bidder-doc-brief${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.requiredDocumentBrief ? document.requiredDocumentBrief : '-'}</TableCell>
                                    <TableCell id={`bidder-doc-manadtory${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.isMandatory ? <CheckIcon style={{ fontSize: '28px' }} /> : <React.Fragment>{'-'}</React.Fragment>}</TableCell>
                                    <TableCell id={`bidder-doc-digital-sig${index}`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{document.requiresDigitalSignature ? <CheckIcon style={{ fontSize: '28px' }} /> : <React.Fragment>{'-'}</React.Fragment>}</TableCell>
                                    <TableCell id={`bidder-doc-action`} className={`${classes.tableCell} ${classes.tableData}`} align="left">{
                                        <React.Fragment>
                                            {document.templateS3Uri == '' && <span>{'-'}</span>}
                                            {document.templateS3Uri != '' && <div style={{ width: 'fit-content' }}>
                                                <div>
                                                    <div className={classes.fileNameDivBlock}>
                                                        <span style={{ position: 'relative', top: '4px' }}><FileIcon style={{ position: 'relative', top: '4px' }} fileName={document.templateS3UriFileName} fontSize={'1rem'}></FileIcon></span>
                                                        <Tooltip title={document.templateS3UriFileName}><i>{document.templateS3UriFileName}</i></Tooltip>
                                                    </div>
                                                    <span className={classes.fileSizeClass}>{'(' + document.templateS3UriSize + ')'}</span>
                                                </div>
                                                <p style={{ margin: 'auto', clear: 'both' }}><span style={{ height: '0.7rem !important', width: '0.8rem !important', position: 'relative', top: '3px' }}>
                                                    <CalendarMonthIcon style={{ fontSize: '17px' }} /></span>
                                                    {format(new Date(document.templateS3UriFileLastModifiedTime), ' dd MMM yyyy hh:mm a')}</p>

                                            </div>}
                                        </React.Fragment>
                                    }</TableCell>
                                    <TableCell className={`${classes.tableCell} ${classes.tableData}`} align="center">
                                        {canUpload && <Tooltip title={document.templateS3Uri == '' ? t('Upload_File_document') : t('Reupload_File_document')} arrow>
                                            <span id={`upload-bidder-doc${index}`} style={{ position: 'relative', top: '10px', cursor: 'pointer', padding: '5px' }}><img src={process.env.PUBLIC_URL + './images/fileUpload.png'} height={22} width={22}
                                                onClick={() => handleUpload(document)}></img></span>
                                        </Tooltip>}
                                        <Tooltip title={t('Download_File_Documet')} arrow>
                                            <span id={`download-bidder-doc${index}`} style={{ position: 'relative', top: '10px', cursor: 'pointer', padding: '5px' }}><img src={process.env.PUBLIC_URL + './images/fileDownload.png'} height={22} width={22}
                                                onClick={(event) => handleDownload(event, document)}></img></span>
                                        </Tooltip>
                                        {canEdit && <Tooltip title={t('Edit')} arrow>
                                            <IconButton id={`bidder-doc-edit${index}`} aria-label="edit" color="primary" style={{ padding: '5px' }} onClick={() => updateDocument(document)}>
                                                <EditIcon style={{ fontSize: '1.25rem' }} />
                                            </IconButton>
                                        </Tooltip>}
                                        {canDelete && <Tooltip title={t('Delete')} arrow>
                                            <IconButton id={`bidder-doc-delete${index}`} aria-label="delete" color="secondary" style={{ padding: '5px' }} onClick={() => deleteDocumentConfirmation(document.id)}>
                                                <DeleteIcon style={{ fontSize: '1.25rem' }} />
                                            </IconButton>
                                        </Tooltip>}
                                    </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                    {totalNumberOfDocuments > 0 &&
                        <BottomNavigation style={{ padding: '12px' }}>
                            <AuctionPagination rowsPerPage={rowsPerPage} page={page} totalItems={totalNumberOfDocuments}
                                onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
                        </BottomNavigation>
                    }
                </TableContainer>}
            </Grid>
            {selectedBidderId != null && <BidderDocumentUploadList selectedBidderId={selectedBidderId} auctionId={auctionId}></BidderDocumentUploadList>}
            {<UploadDialog
                dialogOpen={fileUploadDialog}
                setDialogOpen={setFileUploadDialog}
                isMultiple={false}
                auctionId={auctionId}
                fileName={auctionId + FilePath.requestDocumentFromBidderPath + '/' + documentDetail.name}
                title={t('Upload_Template_Files')}
                reUploadFile={isReupload}
                onSuccess={recordFileUpload}
                isTemplatePresigned={true}
                documentType={documentDetail.name}
                oldFilePath={!isReupload ? documentDetail.templateS3Uri : null}
            />
            }
            {openPopup && <BidderDocumentDetail openPopup={openPopup} setCancel={setCancel} isNew={isNew} auctionId={auctionId} rowsPerPage={rowsPerPage} onSuccess={showSuccessMsg}
                setOpenPopup={setOpenPopup} documentDetail={documentDetail} existingTypes={existingTypes} />}
            {versionList.length > 0 && <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuList id="split-button-menu">
                    {versionList.map((option, index) => (
                        <MenuItem key={index} id={"bidder-doc-version" + index}
                            onClick={(event) => handleVersionDownload(event, option)}>
                            <Typography color={index === 0 ? "primary" : "none"} style={{ fontWeight: index === 0 ? 600 : 500 }}>{`Version ${versionList.length - index}`}</Typography>
                            <Typography color={index === 0 ? "primary" : "none"} style={{ fontSize: '14px', marginleft: '6px', marginBottom: '2px', fontWeight: index === 0 ? 600 : 500 }}>
                                &nbsp;({format(new Date(option.lastModified), ' dd MMM yyyy hh:mm:ss a ')})</Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>}
            {(fetchingRequiredDocuments || fetchingBidders || deletingRequiredDocument || gettingPresignedUrl || loadingFileVersions || updatingRequiredDocument || loading) &&
                <AuctionLoader show={fetchingRequiredDocuments || fetchingBidders || deletingRequiredDocument || gettingPresignedUrl || loadingFileVersions || updatingRequiredDocument || loading} invisible={false} />}
            {(errorOnFetchingRequiredDocuments || errorOnLoadingFileVersions || errorOnFetchingBidders || errorOnDeletingRequiredDocument || errorOnGettingPresignedUrl || errorOnUpdatingRequiredDocument)
                && <GraphQLErrors error={errorOnFetchingRequiredDocuments || errorOnFetchingBidders || errorOnDeletingRequiredDocument
                    || errorOnGettingPresignedUrl || errorOnLoadingFileVersions || errorOnUpdatingRequiredDocument} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity}></AuctionSnackBar>}
            {isDelete && seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
            {!isDelete && seekConfirmation.show && <ReuploadPopUp open={seekConfirmation.show} title={seekConfirmation.title} handleClose={resetSeekConfirmation}
                message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree}
                agreeLabel={t('Yes_Same_File_Name')} disagreeLabel={t('No_Different_File_Name')} />}
        </React.Fragment>
    );
}

export default BidderDocumentList;