import {
    InputBase
}                                   from '@mui/material';
import { styled, alpha }            from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'initial'
}));

const StyledInputBase = styled(InputBase)(({ theme, initialWidth, expandableWidth }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
    paddingLeft: `calc(${theme.spacing(1)})`,
    paddingRight: '12px',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: initialWidth,
        '&:focus': {
        width: expandableWidth,
        },
    },
    },
}));

export { Search, SearchIconWrapper, StyledInputBase};