import React, { 
    Fragment,
    useContext,
    useState
}      from 'react';
import { 
    TableRow,
    TableCell,
    Tooltip,
    Chip,
    IconButton
}                               from '@mui/material';
import EditIcon                 from '@mui/icons-material/EditOutlined';
import ThumbUpIcon              from '@mui/icons-material/ThumbUp';
import ThumbDownIcon            from '@mui/icons-material/ThumbDown';
import PersonIcon               from '@mui/icons-material/Person';
import EmailIcon                from '@mui/icons-material/Email';
import PhoneIcon                from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {  
    useMutation 
}                               from '@apollo/client';
import { UPDATE_BIDDER_STATUS } from '../../GraphQL/Mutation';
import { LIST_BIDDER }          from '../../GraphQL/Queries';
import { AuctionDetailContext }       from '../../auction/auction-detail/AuctionDetail';
import { BidderStatus,
    PartyCodeCd }         from '../../common/StaticVariableDeclaration';
import AuctionLoader            from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors            from '../../common/GraphQLErrors';
import AuctionConfirmDialog     from '../../common/AuctionConfirmDialog';
import { makeStyles }           from '@mui/styles';
import { useTranslation }       from 'react-i18next'; 
import AuctionSnackBar          from '../../common/AuctionSnackBar';
import FieldHelperText          from '../../common/FieldHelperText';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        // borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        // borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    button: {
        margin: '4px !important'
    },
    textIconContainer: {
        display: 'flex'
    },
    textIcon: {
        fontSize: '1.2rem !important',
        paddingRight: '3px'
    },
    statusText: {
        fontWeight: 'bold',
        fontSize: '0.7rem'
    },
    expandedRow: {
        padding: '6px'
    }
}))

const BidderRow = ({index, bidder, auction_id, showBidderDetails, canEdit, canChangeStatus, page, refetch, 
        setPage, noOfBidders, rowsPerPage}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const auctionContext = useContext(AuctionDetailContext);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const [updateStatus, {loading: updatingBidderStatus, error: errorOnUpdatingBidderStatus}] = useMutation(UPDATE_BIDDER_STATUS, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.modifyStatus) {
                setMessage({
                    show: true,
                    message: t('Bidder_Status_Updated_Successfully'),
                    severity: 'success'
                })
                if (page !== 1) {
                    let lastPageRows = noOfBidders % rowsPerPage;
                    let noOfPage = ~~(noOfBidders/rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                    if(lastPageRows == 1 && page == noOfPage){
                        setPage(1);
                    } else {
                        refetch();
                    }
                } else {
                    refetch();
                }
            }
        }
        // refetchQueries: [LIST_BIDDER]
    })

    const updateBidderDetail = (bidder, isEdit) => {
        if (showBidderDetails) {
            showBidderDetails({
                bidder: bidder,
                isEdit: isEdit
            })
        }
    }

    const updateBidderStatusConfiration = (bidder, status) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To') + status + " " + bidder.name + "?",
            onAgree: (remarks) => updateBidderStatus(bidder, status, remarks),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true,
            withConfirmText: status === BidderStatus.reject ? true : false,
            isConfirmTextMandatory: status === BidderStatus.reject ? true : false
        })
    }

    const updateBidderStatus = (bidder, status, remarks) => {
        updateStatus({
            variables: {
                auctionId: auction_id,
                bidderId: bidder.id,
                status: status,
                remarks: remarks
            }
        })
        resetSeekConfirmation();
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    return (
        <Fragment>
            <TableRow id={`bidder${index}`}>
                <TableCell component="th" scope="row" id={`bidder-name${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                    <div style={{display: 'flex'}}>
                        <div style={{padding: '6px 6px 6px 0px'}}>{bidder.name}</div>
                        {bidder.loginId && <div id="registered-user-icon"><img className={classes.textIcon} src={process.env.PUBLIC_URL + './images/registered-user.png'} height={25} alt='Registered User'/></div>}
                    </div>
                    {
                        bidder.identification.length > 0 ?
                            <div>
                                <Tooltip title={t(bidder.identification[0].identificationType)} arrow>
                                    <em>
                                        {bidder.identification[0].identification}
                                    </em>
                                </Tooltip>
                            </div>
                        :
                            <div></div>
                    }
                </TableCell>
                <TableCell id={`bidder-person-type${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                    {PartyCodeCd.find(code => code.dateBaseCode === bidder.individual).uiCode}
                </TableCell>
                <TableCell id={`bidder-contact-info${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                    <div className={classes.textIconContainer}><PhoneIcon className={classes.textIcon}/>
                        {bidder.phoneNumber.length > 0 ? bidder.phoneNumber[0].phoneNumber : '-'}
                    </div>
                    <div className={classes.textIconContainer}><EmailIcon className={classes.textIcon}/>
                        {bidder.email.length > 0 ? bidder.email[0].email : '-'}
                    </div>
                </TableCell>
                <TableCell id={`bidder-auth-person${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                    {
                        bidder.authorizedRepresentative.length > 0 ? 
                            <Fragment>
                                <div className={classes.textIconContainer}><PersonIcon className={classes.textIcon}/>
                                    {bidder.authorizedRepresentative[0].name ? bidder.authorizedRepresentative[0].name : '-'}
                                </div>
                                <div className={classes.textIconContainer}><PhoneIcon className={classes.textIcon}/>
                                    {bidder.authorizedRepresentative[0].phoneNumber.length > 0 ? bidder.authorizedRepresentative[0].phoneNumber[0].phoneNumber : '-'}
                                </div>
                                <div className={classes.textIconContainer}><EmailIcon className={classes.textIcon}/>
                                    {bidder.authorizedRepresentative[0].email.length > 0 ? bidder.authorizedRepresentative[0].email[0].email : '-'}
                                </div>
                            </Fragment>
                        : 
                            '-'
                    }
                </TableCell>
                <TableCell id={`bidder-status${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                    {bidder.bidderStatus === BidderStatus.approve && <Chip className={classes.statusText} label={t('APPROVED')} color="success" size="small" />}
                    {bidder.bidderStatus === BidderStatus.reject &&  <Chip className={classes.statusText} label={t('REJECTED')} color="error" size="small" />}
                    {bidder.bidderStatus === BidderStatus.potential &&  <Chip className={classes.statusText} label={t('PENDING')} color="warning" size="small"/>}
                    {bidder.remarks && <FieldHelperText helperText={bidder.remarks} width={'25px'} fontSize={'1.3rem'}/>}
                </TableCell>
                <TableCell id={`bidder-actions${index}`} className={`${classes.tableCell} ${classes.tableData}`} style={{textAlign: 'center'}}>
                    {canEdit && <Tooltip title={t('Edit')} arrow>
                        <IconButton id={`bidder-update-btn${index}`} size="small" color="primary" onClick={() => updateBidderDetail(bidder, true)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title={t('View')} arrow>
                        <IconButton id={`bidder-view-btn${index}`} size="small" color="primary" onClick={() => updateBidderDetail(bidder, false)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    {canChangeStatus && 
                        <Fragment>
                            {(bidder.bidderStatus === BidderStatus.potential || bidder.bidderStatus === BidderStatus.reject) && 
                                <Tooltip title={t('Approve_Bidder')} arrow>
                                    <IconButton id={`bidder-approve-btn${index}`} size="small" onClick={() => updateBidderStatusConfiration(bidder, BidderStatus.approve)} style={{color: 'green'}}>
                                        <ThumbUpIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(bidder.bidderStatus === BidderStatus.potential || bidder.bidderStatus === BidderStatus.approve) && 
                                <Tooltip title={t('Reject_Bidder')} arrow>
                                    <IconButton id={`bidder-reject-btn${index}`} size="small" onClick={() => updateBidderStatusConfiration(bidder, BidderStatus.reject)} color="secondary">
                                        <ThumbDownIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Fragment>
                    }
                </TableCell>
            </TableRow>
            {updatingBidderStatus && <AuctionLoader show={updatingBidderStatus} invisible={false} />}
            {errorOnUpdatingBidderStatus && <GraphQLErrors error={errorOnUpdatingBidderStatus} show={false} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} 
                onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
                withConfirmText={seekConfirmation.withConfirmText}
                isConfirmTextMandatory={seekConfirmation.isConfirmTextMandatory}
            />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </Fragment>
    )
}

export default BidderRow;