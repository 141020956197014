import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../../common/GraphQLErrors';
import { ACCEPT_TERMS_AND_CONDITION } from '../../../GraphQL/Mutation';
import { GET_TERMS_AND_CONDITION } from '../../../GraphQL/Queries';
import 'quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        borderRadius: '10px !important',
        minWidth: '800px !important'
    },
    quillContainer: {
        border: '0px !important'
    },
}))

const BidTermsAndCondition = (props) => {
    const { open, onAccept, onDecline, auctionId } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [termsAndCondition, setTermsAndCondition] = useState('')

    const [getTermsAndConditions, { loading: fetchingTermsAndCondition, error: errorOnFetchingTermsAndCondition }] =
        useLazyQuery(GET_TERMS_AND_CONDITION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (data.getTermsCondition != null) {
                    if (data.getTermsCondition.id === null || data.getTermsCondition.acceptedTermsAndCondition) {
                        return onAccept();
                    }
                    setTermsAndCondition(data.getTermsCondition);
                    // document.getElementById('defaulttandc').innerHTML = data.getTermsCondition.defaultTermsAndCondition
                    document.getElementById('auctioneertandc').innerHTML = data.getTermsCondition.termsAndCondition
                }
            }
        })

    const [acceptTermsAndConditions, { loading: acceptingTermsAndCondition, error: errorOnAcceptingTermsAndCondition }] =
        useMutation(ACCEPT_TERMS_AND_CONDITION, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (data.acceptTermsCondition) {
                    onAccept();
                }
            }
        })

    const handleAccept = () => {
        acceptTermsAndConditions({
            variables: {
                auctionId: auctionId,
                termsConditionId: termsAndCondition.id
            }
        })
    }

    useEffect(() => {
        getTermsAndConditions({
            variables: {
                auctionId: auctionId,
            }
        })
    }, [getTermsAndConditions])

    return (
        <Fragment>
            {termsAndCondition && <Dialog open={open && termsAndCondition.id !== null} classes={{ paper: classes.dialogWrapper }} scroll='paper' maxWidth='md'>
                <DialogTitle >
                    <Typography color='primary' variant='h5' sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('Terms_and_Condition')}</Typography>
                    <Typography color='GrayText' variant='subtitle1' sx={{ float: 'left', marginTop: '1rem' }}>{t('Last_Updated')}: {format(new Date(termsAndCondition.startTime), 'dd MMM yyyy - hh:mm:ss a')}</Typography>
                </DialogTitle>
                <Divider flexItem />
                <DialogContent>
                    {termsAndCondition?.defaultTermsAndCondition &&
                        <Stack spacing={0.5}>
                            <Typography sx={{ fontWeight: 'bold' }}>{t('Service_Provider_Terms_and_Conditions')}</Typography>
                            <div id='defaulttandc'></div>
                        </Stack>}
                    {termsAndCondition?.termsAndCondition &&
                        <Stack spacing={0.5}>
                            <Typography sx={{ fontWeight: 'bold' }}>{t('Auction_Terms_and_Condition')}</Typography>
                            <div className={`ql-container ql-snow ${classes.quillContainer}`}>
                                <div id='auctioneertandc' className='ql-editor'></div>
                            </div>
                        </Stack>}
                </DialogContent>
                <Divider flexItem />
                <Stack direction='row' justifyContent='center' m={2} spacing={3}>
                    <Button onClick={onDecline} variant='outlined' color='primary'
                            disabled={!open || acceptingTermsAndCondition}>
                        {t('Decline')}
                    </Button>
                    <Button onClick={handleAccept} variant='contained' color='primary'
                            disabled={!open || acceptingTermsAndCondition}>
                        {t('Accept')}
                    </Button>
                </Stack>
            </Dialog>}
            {(fetchingTermsAndCondition || acceptingTermsAndCondition) &&
                <AuctionLoader show={(fetchingTermsAndCondition || acceptingTermsAndCondition)} invisible={false} />}
            {errorOnFetchingTermsAndCondition &&
                <GraphQLErrors error={errorOnFetchingTermsAndCondition} show={false} />}
            {errorOnAcceptingTermsAndCondition &&
                <GraphQLErrors error={errorOnAcceptingTermsAndCondition} show={false} />}
        </Fragment>
    )
}

export default BidTermsAndCondition
