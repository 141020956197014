import { KeyboardArrowRightRounded } from '@mui/icons-material';
import { alpha, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles'
import React, { Fragment } from 'react'
import { MenuBar, MenuListIcon, MenuListItem, MenuListText, MenuSvgIcon, SideNavHeader } from '../../../auction/auction-detail/side-nav/side-nav-style/SideNavStyle';
import { SideNavBar } from './AuctionListSideNavStyle';

const useStyles = makeStyles((theme) => ({
    menuItem: {
        borderLeft: '3px solid #298ce2 !important',
        backgroundColor: theme.palette.type === "light" ? alpha(theme.palette.primary.main, 0.04) + ' !important' : 'rgb(142 162 171 / 20%) !important',
    }
}))

const AuctionListSideNav = (props) => {
    const { selectedValue, handleChange, menuCollapse, setMenuCollapse, setSelectedList, handleBackNavigation,
        menuItemsRender } = props;
    const classes = useStyles();

    const toggleMenuCollapse = () => {
        setMenuCollapse(!menuCollapse)
    }

    return (
        // <Slide direction="right" in={true} timeout={500} unmountOnExit>
        <SideNavBar ismenucollapse={+menuCollapse}>
            <SideNavHeader height={'5px'}>
                {/* <Button id='poll-info' aria-label="back-to-poll" color="primary" size="large" sx={{ textTransform: 'none', width: '100%' }}
                    onClick={handleBackNavigation}>
                    <IoMdArrowRoundBack style={{ fontSize: 'larger' }} />&nbsp; {menuCollapse ? '' : 'Back to Auction'}
                </Button> */}
                <MenuSvgIcon component={KeyboardArrowRightRounded} onClick={toggleMenuCollapse} menucollapse={+menuCollapse} />
            </SideNavHeader>
            {/* <Divider /> */}
            <MenuBar gap='0.5rem'>
                {menuItemsRender.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {item.show &&
                                <Tooltip title={item.title} disableHoverListener={!menuCollapse} placement='right' key={index} arrow>
                                    <MenuListItem button id={item.id} style={menuCollapse ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}
                                        key={index} onClick={(e) => { handleChange(item.value) }}
                                        isselected={selectedValue === item.value ? +true : +false} className={selectedValue === item.value ? classes.menuItem : ''}>
                                        <Stack direction={menuCollapse ? 'column' : 'row'} justifyContent='center' alignItems='center'>
                                            <MenuListIcon className='icon'>
                                                {item.icon}
                                            </MenuListIcon>
                                            {!menuCollapse && <MenuListText className='text'>
                                                <Typography className='typo'
                                                    style={menuCollapse ? { fontSize: '10px', margin: '0px' } : { margin: '0px 0px 0px 12px' }}>
                                                    {item.title}</Typography>
                                            </MenuListText>}
                                        </Stack>
                                    </MenuListItem>
                                </Tooltip>
                            }
                        </Fragment>
                    )
                })}
            </MenuBar>
        </SideNavBar >
        // </Slide>
    )
}

export default AuctionListSideNav
