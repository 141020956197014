import React, {
    Fragment,
    useState,
    useEffect,
    useContext
} from 'react';
import {
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    Tooltip,
    IconButton,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import FieldHelperText from '../../../common/FieldHelperText';
import AddIcon from '@mui/icons-material/AddOutlined';
import AddEMDDetail from '../emd-detail/AddEMDDetail';
import { LIST_EMD, LIST_SELLER_DETAILS } from '../../../GraphQL/Queries';
import { DELETE_EMD } from '../../../GraphQL/Mutation';
import {
    useLazyQuery,
    useMutation
} from '@apollo/client';
import AuctionLoader from '../../../common/auction-loader/AuctionLoader';
import GraphQLErrors from '../../../common/GraphQLErrors';
import { NumericFormat } from 'react-number-format';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import AuctionConfirmDialog from '../../../common/AuctionConfirmDialog';
import { UserContext } from '../../../common/context/UserContext';
import UpdateEMDDetail from '../emd-detail/UpdateEMDDetail';
import AuctionSnackBar from '../../../common/AuctionSnackBar';
import FileList from '../../../file-upload-download/FileList';
import DescriptionIcon from '@mui/icons-material/Description';
import { Stack } from '@mui/material';
import { useServerCurrentDateTime } from '../../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: "rgb(26, 32, 39)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '10px',
        border: "1px solid rgb(238, 238, 238)",
        backgroundImage: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        margin: "40px auto",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,
            "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    button: {
        margin: '4px !important'
    }

}))

const EMDList = ({ auction_id, bidder_id, seller_id, user_id, allowAdd, allowEdit, allowDelete, auctionCreationDate,
    mannerOfSale, allowUpdateReceivedEMDField, bidder_login_id, setTourGuide }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [emdAmounts, setEmdAmounts] = useState([]);
    const [showAddEMDDetail, setShowAddEMDAmount] = useState(false);
    const serverCurrentTime = useServerCurrentDateTime();
    const [updateEMDDetail, setUpdateEMDAmount] = useState({
        show: false,
        emdDetails: ''
    });
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const columns = [
        { name: 'Transaction_Date', width: '14%', helperText: '' },
        { name: 'Mode_of_Payment', width: '14%', helperText: '' },
        { name: 'Amount', width: '10%', helperText: '' },
        { name: 'Transaction_Ref', width: '17%', helperText: '' },
        { name: 'Transferred_From', width: '17%', helperText: '' },
        { name: 'Transferred_To', width: '17%', helperText: '' }
    ]
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [showAdd, setShowAdd] = useState(allowAdd ? allowAdd : false);
    const [showEdit, setShowEdit] = useState(allowEdit ? allowEdit : false);
    const [showDelete, setShowDelete] = useState(allowDelete ? allowDelete : false);
    const [showDocuments, setShowDocuments] = useState({
        show: false,
        canUpload: false,
        emdId: ''
    });
    const [sellerBankDetail, setSellerBankDetail] = useState(false)

    const [listEMDs, { loading: fetchingEMDs, error: errorOnFetchingEMDs }] = useLazyQuery(LIST_EMD, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setEmdAmounts([]);
            if (data.listEmdDetail != null) {
                setEmdAmounts(data.listEmdDetail);
            }
        }
    })

    const [listSellerDetails, { loading: loadingSeller, error: errorOnFetchingSeller }] = useLazyQuery(LIST_SELLER_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.listSeller !== null && data.listSeller.seller.length > 0) {
                // setSellerBankDetail(data.listSeller.seller[0].bankDetail ? true : false)
                setSellerBankDetail(data.listSeller.seller[0])
            }
        }
    })

    const [deleteEMD, { loading: deletingEMD, error: errorOnDeletingEMD }] = useMutation(DELETE_EMD, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteEmdDetail) {
                showSuccessMsg({ show: true, message: t('EMD_Deleted_Successfully'), severity: 'success' })
            }
        },
        refetchQueries: [LIST_EMD]
    })

    const deleteEMDConfiration = (emdAmount) => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Delete_EMD_With_Date') + " " + format(new Date(emdAmount.transactionDate), 'dd MMM yyyy') + "?",
            onAgree: () => deleteSelectedEMD(emdAmount),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const deleteSelectedEMD = (emd) => {
        deleteEMD({
            variables: {
                auctionId: auction_id,
                emdDetailId: emd.transferredEdgeId
            }
        })
        resetSeekConfirmation();
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const updateEMD = (emd) => {
        setUpdateEMDAmount({
            show: true,
            emdDetails: emd
        })
    }

    const resetUpdateEMD = (event) => {
        setUpdateEMDAmount({
            show: false,
            emdDetails: ''
        })
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    const getDocuments = (emdId) => {
        setShowDocuments({
            show: true,
            canUpload: allowEdit,
            emdId: emdId
        })
    }

    const resetShowDocuments = (event) => {
        setShowDocuments({
            show: false,
            canUpload: false,
            emdId: ''
        })
    }

    useEffect(() => {
        listEMDs({
            variables: {
                auctionId: auction_id,
                userId: user_id
            }
        })
        if(setTourGuide) {
            setTourGuide({
                triggerEvent: false,
                triggerButton: false
            });
        }
    }, [])

    useEffect(() => {
        if (showAdd) {
            listSellerDetails({
                variables: {
                    auctionId: auction_id,
                    pagination: {
                        page: 1,
                        limit: 5,
                        asc: false
                    },
                }
            })
        }
    }, [showAdd])

    const showEmdAmountDetails = () => {
        if ((sellerBankDetail ? true : false) && sellerBankDetail.bankDetail != null && (new Date(sellerBankDetail.bankDetail.endTime) > serverCurrentTime)) {
            setShowAddEMDAmount(true)
        } else {
            setMessage({ show: false, message: '', severity: '' });
            setTimeout(() => setMessage({ show: true, message: t('Atleast_One_Seller_Bank_Account_Is_Required'), severity: 'warning' }), 0);
        }
    }

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction='row' style={{ float: 'right' }}>
                        {showAdd && <Fragment>
                            <Button id="add-emd-btn" variant="contained" size="small" startIcon={<AddIcon />} style={{ float: 'right' }}
                                onClick={showEmdAmountDetails} className={classes.button}>
                                {t('Add')}
                            </Button>
                        </Fragment>}
                    </Stack>
                    <TableContainer component={Paper} className={classes.paper}>
                        <Table aria-label="required documents table" stickyHeader id="list-of-emd">
                            <TableHead>
                                {columns.map(column => {
                                    return (
                                        <TableCell key={column.name} className={`${classes.tableCell} ${classes.tableHead}`} style={{ width: column.width }}>
                                            {t(column.name)}
                                            {column.helperText && <FieldHelperText helperText={t(column.helperText)} />}
                                        </TableCell>
                                    )
                                })}
                                <TableCell className={`${classes.tableCell} ${classes.tableHead}`} align="center">{t("Actions")}</TableCell>
                            </TableHead>
                            <TableBody>
                                {emdAmounts.length === 0 &&
                                    <TableRow hover>
                                        <TableCell colSpan={8} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                    </TableRow>
                                }
                                {
                                    emdAmounts.length > 0 && emdAmounts.map((emd, index) => {
                                        return (
                                            <TableRow hover key={index}>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {format(new Date(emd.transactionDate), 'dd MMM yyyy')}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {emd.modeOfPayment ?
                                                        enumListObject.ModeOfPayment ? enumListObject.ModeOfPayment[emd.modeOfPayment].uiCode : emd.modeOfPayment
                                                        :
                                                        '-'
                                                    }
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`} style={{ textAlign: 'right' }}>
                                                    {emd.amount ?
                                                        <NumericFormat value={emd.amount} displayType={'text'}
                                                            thousandSeparator={true} thousandsGroupStyle="lakh" />
                                                        :
                                                        <span style={{ textAlign: 'center' }}> - </span>}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {emd.transactionRefNo ? emd.transactionRefNo : '-'}
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {emd.fromBankDetail ?
                                                        <Fragment>
                                                            <div>{emd.fromBankDetail.bankHolderName}</div>
                                                            <div>{emd.fromBankDetail.bankAccountNumber}</div>
                                                            <div>{emd.fromBankDetail.bankName}</div>
                                                        </Fragment>
                                                        :
                                                        '-'

                                                    }
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {emd.toBankDetail ?
                                                        <Fragment>
                                                            <div>{emd.toBankDetail.bankHolderName}</div>
                                                            <div>{emd.toBankDetail.bankAccountNumber}</div>
                                                            <div>{emd.toBankDetail.bankName}</div>
                                                        </Fragment>
                                                        :
                                                        '-'

                                                    }
                                                </TableCell>
                                                <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                                    {showEdit &&
                                                        <Tooltip title={t('Edit')} arrow>
                                                            <IconButton id={`emd-update-btn${index}`} size="small" color="primary" onClick={() => { updateEMD(emd) }}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>}
                                                    <Tooltip title={t('Documents')} arrow>
                                                        <IconButton id={`emd-document-btn${index}`} size="small" onClick={() => getDocuments(emd.id)}
                                                            color="primary">
                                                            <DescriptionIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {showDelete &&
                                                        <Tooltip title={t('Delete')} arrow>
                                                            <IconButton id={`emd-delete-btn${index}`} size="small" color="secondary" onClick={() => deleteEMDConfiration(emd)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {errorOnFetchingEMDs && <GraphQLErrors error={errorOnFetchingEMDs} show={false} />}
                {errorOnDeletingEMD && <GraphQLErrors error={errorOnDeletingEMD} show={false} />}
                {showAddEMDDetail && <AddEMDDetail show={showAddEMDDetail} auction_id={auction_id} bidder_id={bidder_id}
                    user_id={user_id} seller_id={seller_id} onClose={setShowAddEMDAmount}
                    onSuccess={showSuccessMsg} auctionCreationDate={auctionCreationDate} mannerOfSale={mannerOfSale}
                    allowUpdateReceivedEMDField={allowUpdateReceivedEMDField} bidder_login_id={bidder_login_id} />}
                {updateEMDDetail.show && <UpdateEMDDetail show={updateEMDDetail.show} auction_id={auction_id} bidder_id={bidder_id}
                    user_id={user_id} seller_id={seller_id} onClose={resetUpdateEMD} emdDetails={updateEMDDetail.emdDetails}
                    onSuccess={showSuccessMsg} auctionCreationDate={auctionCreationDate}
                    allowUpdateReceivedEMDField={allowUpdateReceivedEMDField} bidder_login_id={bidder_login_id} />}
                {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                    title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                    onDisAgree={seekConfirmation.onDisAgree}
                />}
                {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
                {showDocuments.show && <FileList show={showDocuments.show} isMultiple={false}
                    fileName={`${bidder_id}/emd proof documents/${showDocuments.emdId}`} onClose={resetShowDocuments} auction_id={auction_id}
                    canUpload={showDocuments.canUpload} />}
            </Grid>
            {(fetchingEMDs || deletingEMD || loadingSeller) && <AuctionLoader show={fetchingEMDs || deletingEMD || loadingSeller} invisible={false} />}
        </Fragment>
    )
}

export default EMDList;