import React, { useLayoutEffect, useState } from 'react';

export const GetWindowSize = (reload) => {
    const [windowSize, setWindowSize] = useState([0, 0]);

    const updateWindowSize = () => {
        if(!reload) {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        updateWindowSize();
        return () => window.removeEventListener('resize', updateWindowSize);
    }, [reload])
    
    return [windowSize[0], windowSize[1]]
}