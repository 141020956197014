import React, { useState, useRef } from 'react';
import {
    Dialog, DialogTitle, Button, DialogContent, Link,
    DialogContentText, DialogActions, Divider, Typography, Grid, styled
} from '@mui/material';

const TCText = styled('p')(() => ({
    fontFamily: 'auto',
}))

const TCLi = styled('li')(() => ({
    fontFamily: 'auto',
}))

const TCOl = styled('ol')(() => ({
    fontFamily: 'auto',
}))

const TermsAndConditions = (props) => {
    const {show, acceptTermsAndConditions} = props;

    const privacyPolicyRef = useRef(null);

    const acceptHandler = (isAccept) => {
        acceptTermsAndConditions(isAccept);
    }

    const moveToprivacyPolicyRef = () => {
        privacyPolicyRef.current.scrollIntoView(true);
    }

    return (
        <Dialog maxWidth="lg" open={show} scroll='paper' aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">
                <Grid container>
                    <Grid item xs={3}>
                        <img src={process.env.PUBLIC_URL + './images/auction.svg'} height={50} alt='E-AuctionLogo'></img>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', fontFamily: 'auto' }}>Terms & Conditions</Grid>
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'baseline' }}>
                       <img src={process.env.PUBLIC_URL + './images/enkindleLogo.png'} height={60} alt='EnkindleLogo'></img>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: '16px 40px', WebkitFontSmoothing: 'auto'}}>
                <DialogContentText id="scroll-dialog-description">
                    <TCText>
                        This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                    </TCText>
                    <TCText>
                        This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy and Terms of Service for access or usage of the Site.
                    </TCText>
                    <TCText>
                        This website <Link href='https://www.trusteauction.com/' target="_blank" underline="always">
                            trusteauction.com
                        </Link> (hereinafter referred to as "<strong>Site</strong>", which expression shall include any versions of the website designed for use on mobile phones and other devices and associated domain names, trademarks and mobile applications) and product/ services accessed through the Site ("<strong>Products</strong>", "<strong>Applications</strong>" and "<strong>Platform</strong>") are owned, operated and managed by National E-Governance Services Ltd, hereinafter referred to as "<strong>ETPL</strong>", "<strong>Company</strong>", "<strong>We</strong>", "<strong>Our</strong>" and "<strong>Us</strong>". We provide a secure platform and an online marketplace for our end-users/clients to conduct electronic auctions in an easy to manage environment. 
                    </TCText>
                    <TCText>
                        Any individual, administrator or entity (hereinafter referred to as "<strong>User</strong>" and "<strong>You</strong>") may register to or use the Site and/or Products offered by Us. The Users of our Products are either <strong>Auctioneers</strong> (those that create and conduct the e-auctions) or <strong>Bidders</strong> (those who participate and place bids in the e-auctions). Auctioneers pay Us to use our Products while the Bidders do not have to pay Us to participate in the e-auctions. 
                    </TCText>
                    <TCText>
                        Unless otherwise agreed with ETPL, your agreement will always include a minimum terms and conditions as set out in this document, referred to as “Universal Terms”. Your agreement with ETPL may also include others terms referred to as Additional Terms. Universal Terms along with Additional Terms form a legally binding agreement between you and ETPL collectively referred to as Terms of Service. The Users have to agree to the “Terms of Service” ("<strong>Terms</strong>") and "<strong>Privacy Policy</strong>" in order to register and avail services.
                    </TCText>
                    <TCText>
                        These Terms constitute a binding contract between ETPL and the Users. The User’s registration, use or access to the Products shall be governed by these Terms. If the User does not agree to these Terms, the User must not access or use the Site in any manner. Further, if the User does not have the legal authority to enter these Terms or does not understand these Terms of any part thereof, the User shall must not access or use the Site and/or Products.
                    </TCText>
                    <TCText>
                        The User’s use of the Products and specifically, the User’s acceptance of these Terms shall be deemed to be a representation from the User that the User is fully able and competent to lawfully enter into contracts and abide by and comply with these Terms. 
                    </TCText>
                    <TCText>
                        By the User’s use of the Site, User hereby gives unconditional consent to: (i) these Terms; (ii) the <Typography sx={{ color: 'cornflowerblue', cursor: 'pointer', display: 'inline', fontSize: '14px'}}  onClick={moveToprivacyPolicyRef} >Privacy Policy</Typography>; and (iii) any other notice, disclaimer, policy, or term of use, by whatever name called, which may be applicable to the use of the Products from time to time.
                    </TCText>
                    <TCText>
                        For the purpose of interpretation of these Terms, words importing the singular include the plural and vice versa, pronouns importing a gender include each of the masculine, feminine and neuter genders, and where a word or phrase is defined, other parts of speech and grammatical forms of that word or phrase shall have such corresponding meanings.
                    </TCText>
                    <ul>
                        <li>
                            <TCText>
                                <strong>How Does it Work: </strong>Our Site provides a platform that offers secure, reliable and easy to use Application that can be used for e-auction and such ancillary services (“<strong>Platform</strong>”). Users (Auctioneers/Bidders) on successful registration on the Platform, and subsequent verification will be given access to the Platform. The Users have to register their details and subsequently log on to the Platform. The Auctioneer thereafter shall publish all details of the e-auction that is to be conducted. E-auction notice and process documents shall be uploaded in system for public viewing by the Auctioneer. Auctioneer can add those Bidders who have made EMD payment and completed necessary formalities as required by them for that specific auction. Prospective Bidders can submit their interest in participating in an auction by uploading necessary information/ documents for the Auctioneer to verify through the e-auction Platform/ Site. Pursuant to the document verification process, the Auctioneer may at their sole discretion approve or reject request received from any prospective Bidder. Thereafter, the e-auction process shall commence and end at the pre-determined time schedule.
                            </TCText>
                            <TCText>
                                Bidders are required to accept terms and conditions of the eauction adopted by the Service Provider and Auctioneer (if made available). A Bidder can start bidding, in the online e-Auction, from the Start Price or Reserve Price and given in the Sale Notice. Once a Bid is submitted it cannot be withdrawn or cancelled. Subsequent Bids placed by other interested Bidders need to be placed above the current highest bid (H1). Bids will have to be made in multiples of the increment amount. Decision of the Auctioneer regarding declaration of successful Bidder shall be final and binding on all Bidders. Auctioneer can cancel the eauction process at any time without assigning any reasons.
                            </TCText>
                            <TCText>
                                The bidding process of the e-auction is conducted live on the Platform and the bid details shall have restricted view with identity of the Bidder masked for the entire time of the auction. We maintain audit trails to record every activity/event pertaining to process of e-auction. In addition to these Terms, the specific terms and conditions that govern each auction shall be as per the terms contained in each respective auction notices and documents. The Users will be solely held liable for inaccuracy of data or misrepresentation of data provided by them. The Site shall keep a record of the User’s IP address to prevent fraud or abuse
                            </TCText>
                            <TCText>
                                The communication between the Auctioneer and Bidder pre or post the Event/ Auction are not the responsibility of the Company. We make not guarantee about goods or services offered by an Auctioneer or Bidder nor give any assurances that a Bidder will Bid at an auction or provide the Auctioneer with products or services or payment. The selection of the winner of the auction is at the sole discretion of the Auctioneer.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Auctioneer Conduct: </strong>The Auctioneer will undertake to act in a responsible manner and agrees not to manipulate any event. Auctioneer will comply with applicable legislations at all times. The Auctioneer will ensure all information provided on the Site/ Platform and to the Company is accurate and complete. The auctioneer will provide all information required to set up the Auction to the Company Support term at least 48 hours prior to start of the Auction.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Bidder Conduct and Bid Submission: </strong>Bidder will ensure that they act in good faith at all times in dealings with the Company/ Platform. They agree not to collude with other Users or third parties in order to manipulate the Auction nor will they share pricing information with each other. Bidders will indemnify the Company / Site/ Platform against all liability arising out of any breach of the terms. Bidders will not be able to withdraw a Bid (unless expressly allowed by the Auctioneer). Any attempt to do so may constitute a breach of contract. The final selection of Bidders and the winner of the Auction is a matter for the Auctioneer. The time that is displayed from the server clock at the top of Auction Hall Bidding page will be valid for all actions of bid submission, bid in the eauction . The Time followed in this platform is as per Indian Standard Time (IST) which is GMT+5:30. The bidders should adhere to this time during bid submission and should avoid waiting for the last few minutes to place their bids. Bidder should ensure good internet connectivity is maintained from there end. Failure of internet connectivity shall be the sole responsibility of the Bidder. Both the auctioneer and the platform is not responsible.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Privacy: </strong>The data and information provided by Users is not shared or sold with any third party. More details of Our privacy policy are available on Our website. By accepting the Terms, the Users by default also agree and accept Our Privacy Policy.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Third Party Websites: </strong>Our Site may contain links to third party websites/ applications/cookies (“<strong>Linked Websites</strong>”) that are not under Our control. The Company has no control over these third-party website/applications/cookies and accepts no responsibility for them or for any loss or damage that may arise from User’s use of such Linked Websites. User’s use of the Linked Websites will be subject to the terms of use and service of such Linked Website. In the event a User is redirected to a third-party website by clicking on the links, the User shall be subjected to their privacy policy. We are not responsible for their content or privacy policies and are not liable for any issues that arise out of your use/access of those Linked Websites. Except for the Products explicitly provided, the Company does not provide, control or endorse any third-party information, products or services in any manner whatsoever, even if such third-party information, products or services are advertised on the Site.
                            </TCText>
                            <TCText>
                                You understand and acknowledge that the Company shall under no circumstance be responsible or liable, directly or indirectly, for any loss or damage, howsoever caused, by or in connection with use of or reliance on any goods, services, content, products or other materials available on or through any Linked Websites. Further, You understand that the Company makes no express or implied warranties or representations pertaining to such Linked Websites.
                            </TCText>
                        </li>

                        <li>
                            <TCText>
                                <strong>Children’s Privacy: </strong>Our Site is not directed at anyone who We know to be under the age of 18, nor are the Users allowed to collect any personal information from anyone who We know to be under the age of 18. You must be at least 18 years of age to be able to register and access the Site and Platform. We do not intentionally provide any services or collect information from any User under the age of 18. If We become aware that a User under the age of 18 accessing or using the Site and Platform, We reserve the right to prohibit usage by such User by suspension or blocking of the account of such User without any prior notice and We will make all reasonable endeavours to delete any data and information with regard to such User’s registered account.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Fee: </strong>We reserve the right to change the fee structures for the Products, including existing services/ features and charge for current non-chargeable services/features. All taxes shall be charged extra at actuals and the Users shall pay the same. Unless otherwise permitted by the Company or as per the Company’s Refund Policy, any charges payable in advance towards availing the Products shall be non-refundable. The Users shall refer to the Refund Policy for further information on refund of payments made to the Company. Non-payment of the fees, charges or any such amounts as applicable to avail the Products shall result in suspension or termination of these Terms and the User’s ability to use the Platform. Payment of fees to the Company by the Auctioneer will need to be made before the publish of the auction (unless otherwise agreed).
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>User Account: </strong>User must complete the registration process by providing current, complete and accurate information as prompted by the applicable registration form. The account created belongs to the User and cannot at any time be transferred or assigned by the User. The User hereby represents and warrants that such information shared or provided by the User is up to date, correct and complete in all material respects, and does not infringe or violate any third-party rights. The Company shall not be liable for any loss, damage, injury or any liability, whether in tort, contract or other theory of liability, resulting from any unauthorized use of the User’s account. The User shall be solely responsible to keep such information updated from time to time.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>User Password and Security: </strong>User is responsible for maintaining the confidentiality of their username and password that is associated with their account. All transactions/ actions undertaken under the User’s account is the sole responsibility of the User. User shall be held liable for any loss incurred by the Company or any other party on account of someone else using the User’s account or password. User should ensure logging out of their account on completion of their session and ensure that their username and passwords are not shared with other individuals.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Payments: </strong>All payments made on our Site is managed by a third-party service provider and such transactions will be governed by the terms and conditions thereto. We only collect payment details of Users and transmit the same directly to them. All payments shall be made by credit card, debit card or such other method as the Company may specify from time to time. While availing any of the payment method/s available on the Site the Company will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to the Users due to: (i) Lack of authorization for any transaction/s; or (ii) Payments exceeding the present limit mutually agreed by the Users and third-party service providers; or (iii) Any payment issues arising out of the transaction; or (iv) Decline of transaction for any other reason/s. The User understands, accepts and agrees that the payment facility provided by Company is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment through collection and remittance facility for the transactions on the Company’s Site using the existing authorized banking infrastructure and credit card payment gateway networks. The User acknowledges agrees to pay any fees/charges that may be levied for the use of the services of the third-party service provider or a bank.
                            </TCText>
                            <TCText>
                                In the event any payment related details are input incorrectly by the User or on behalf of the User, the User shall be responsible for any and all losses and all charges incidental to the same. In no event shall the Company be liable for any damages whatsoever arising out of the use, inability to use, or the results of use of the third-party service provider’s website/platform, any websites linked to such sites, or the materials or information contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages.
                            </TCText>
                            <TCText>
                                Further, the User agrees that the Company shall not be responsible and/or liable for any issue or claim arising out for dispute whatsoever with the third-party service provider, bank or any other counterparty. Users will keep the Company indemnified and hold the Company harmless in case of any third party claims against the Company (whether by any counterparty, third-party service provider, bank or any governmental authority) for the payments made or attempted to be made by the User (or any other person through the User’s Account).
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Information Disclosure: </strong>We can share your information with Our employees, affiliated parties or agents in order to provide quality services. Your information may also be shared with Our trusted third-party service providers on a need-to-know basis to perform certain services on our behalf, including payment processing, data storage/management, web hosting, web analytics, fulfilment, marketing, mailing, and emailing. Your information may further be disclosed by Us if required to do so by law, government or law enforcement agencies, court order or such circumstances. Please refer to Our Privacy Policy for information on Our usage of your information.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>User Obligations: </strong>The User represents and warrants that:
                                <ul>
                                    <TCLi>
                                        Users shall provide complete and accurate information as required by the Site
                                    </TCLi>
                                    <TCLi>
                                        Users are also required to abide by all laws and regulations that are applicable when using the Product.
                                    </TCLi>
                                    <TCLi>
                                        Users agree not to use any data mining, robots or similar data and/or image gathering and extraction methods in connection with the Product.
                                    </TCLi>
                                    <TCLi>
                                        User shall not use the Site or Product to do anything that is contrary to the law, decency or morality.
                                    </TCLi>
                                    <TCLi>
                                        User shall not use the Site or Product to make unsolicited offers, advertisements, proposals, or send junk mail or spam to others.
                                    </TCLi>
                                    <TCLi>
                                        User shall not take any action that would cause the Company to suffer any types of losses.
                                    </TCLi>
                                    <TCLi>
                                        User agrees not to collect or harvest from the Site or Product any personally identifiable information, including account names, or to use the communication platforms provided by the Product (e.g., comments, email) for any commercial solicitation or other purposes.
                                    </TCLi>
                                    <TCLi>
                                        User shall not copy, reproduce, make available online or electronically transmit, publish, adapt, distribute, transmit, broadcast, display, sell, license, or otherwise exploit the content on the Site.
                                    </TCLi>
                                    <TCLi>
                                        User agrees not to hack, circumvent, disable, corrupt or otherwise interfere with security-related features of the Product or features that prevent or restrict use or copying of any content or enforce limitations on use of the Product or the content therein.
                                    </TCLi>
                                    <TCLi>
                                        User shall also not attempt to affect the performance or functionality of any facilities available on the Site.
                                    </TCLi>
                                    <TCLi>
                                        User shall not transmit or distribute a virus, trojan, worm, logic bomb or any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene hack into any aspect of the Site or Product.
                                    </TCLi>
                                    <TCLi>
                                        The User shall not create any e-auction or upload notices and documentation which violates or infringes any third-party rights, including intellectual property rights.
                                    </TCLi>
                                    <TCLi>
                                        User acknowledges and understands that they are entirely responsible for all contents made available on the Site, and consent that they have the necessary rights and permissions required to use such information in connection with availing the Products on the Site.
                                    </TCLi>
                                </ul>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Unlawful Use: </strong>If it is found that the User is conducting unlawful activity during use of the Site, such will be reported to the respective law enforcement agency/ regulators or officials as the case may be. In such cases disclosure of account related information of such Users to the respective regulatory/ enforcement agencies may be necessary.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Account Deactivation: </strong>The account of the User may be deactivated by the Company on account of breach of these Terms or if required to do so by law. The User can also choose to deactivate their account. On the account getting deactivated, such User will not have access to their account. If the Company reasonably concludes based on information available to the Company that (i) User’s actions and/or performance in connection with the Terms may result in a significant number of customer disputes, chargebacks or other claims in connection with the Site; and/or (ii) the Users have given inaccurate or false information or details while registration or login or has failed to update their information, the Company may then either block, restrict, disable, suspend or terminate User’s access to the Site including the Products, in full or in parts, at any time, as per the Company’s discretion, without prior notice or liability to the User. Notwithstanding the foregoing, the Company may at its sole discretion, and at any given time, either suspend the User’s use of the Site, including the Products or terminate User’s contract under these Terms.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Indemnity: </strong>In the event any person brings a claim, ("<strong>Third-Party Claim</strong>") against the Company related to the User’s actions, or any other use of the e-auction services, Products or Site by the User, the User shall indemnify, and hold the Company, its promoters, directors, employees, licensees, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees harmless from and against all liabilities, damages, losses, and expenses of any kind (including reasonable legal fees and costs) relating to, arising out of, or in any way in connection with any of the following: (a) the User’s access to or use of the e-auction services, Products or Site, including information and content provided in connection therewith; (b) User’s breach of these Terms or applicable law; or (c) any misrepresentation made by the User. The User shall cooperate as fully as required by us in the defense or settlement of any Third-Party Claim. The User’s rights with respect to the Company are not modified by the foregoing indemnification if the laws of the User’s country or territory of residence, applicable as a result of User’s use of the e-auction services, Products or Site, do not permit it. The User understands and acknowledges that it is only acts as a facilitator of the Platform on which the e-auction takes place.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Security Protocols: </strong>The Company undertakes all reasonable and necessary security protocols to ensure the process of e-auction is secure and all associated data is safe. All information is stored in a cloud environment that secures all data from unauthorized access with encryption features and access management tools.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Disclaimer: </strong>The User agrees that the use of our services, site and product at the User’s own risk and subject to these disclaimers. The Company is providing the services on an “AS IS” basis without any express or implied Warranties including, but not limited to, Warranties of Merchantability, Fitness for a particular purpose, Title, Non-infringement, and freedom from computer virus or other harmful code. The Company does not warrant that any information provided by it is accurate, complete or useful, that the services, Site and Product will be operation, error free, secure, or safe, or that the Services, Site and Product will function without disruptions, delays, or imperfections. The Company shall not be responsible for and are not obligated to control the actions or information (including content) of the Users or other Third Parties. The User releases the Company, our subsidiaries, our affiliates, and their Directors, officers, employees, partners and agents (together the “Company and its Representatives”) from any claim, complaint, cause of action, controversy, dispute, or damages (together “Claim”), known and unknown, relating to arising out of, or in any way connected with any such Claim users have against any Third Parties. The Users rights with respect to the Company and its Representatives are not modified by the foregoing disclaimer if the loss of the Users country or territory of residence applicable as a result of the User’s use of the services, Site and Product, do not permit it.
                            </TCText>
                            <TCText>
                                The information contained in this Site has been prepared/compiled for the convenience and general information of Site visitors. The Company reserves the right, at its sole discretion, to change, modify, add or delete portions of these Terms, at any time without any prior written notice to the User. It is the User’s sole responsibility to review these Terms periodically for updates or amendments. The User’s continued use of the Site and Products following the posting of changes shall be deemed to mean that the User accepts and agrees to the revised Terms.
                            </TCText>
                            <TCText>
                                All information or content provided on this Site is obtained by Us from sources believed to be accurate and reliable. The Company shall not be liable in case of any unauthorised access. Access and use are a confirmation by the Users that they have understood and accepted the terms and conditions for using the Products.
                            </TCText>
                            <TCText>
                                While utmost care has been exercised to ensure the accuracy of compilation, correctness and completeness of the information provided on this Site, we do not warrant (whether expressed or implied) as to the quality, efficacy, completeness, performance, or accuracy of the information and disclaims all claims, liabilities, losses and damages arising out of the use of this information.
                            </TCText>
                            <TCText>
                                The information contained herein cannot form the basis for any dispute between Company, Auctioneers, Bidders, and any other Users of the Site. The Company shall not be liable in any manner for any acts of the Auctioneers, including without limitation the notice and process documents published by the Auctioneer, document verification and the outcome of e-auction.
                            </TCText>
                            <TCText>
                                The Company shall endeavour to keep all information updated. The formats, frequency of updating and the retention period of information shall be at the Company’s sole discretion
                            </TCText>
                            <TCText>
                                Unless expressly agreed herein in the Terms, all proprietary rights in the information received shall remain the Company’s property. Reproduction, redistribution and transmission of any information contained on the Site are strictly prohibited.
                            </TCText>
                            <TCText>
                                The Company shall not be responsible for the unavailability of the Site or any delay or failure resulting from any reason whatsoever, including but not limited to infrastructure issues, server uptime, network availability and connectivity. The Users shall acknowledge that during the event there may be discrepancies between the Users local time clock and the Company/ Site/ Platform server time clock which are outside the control of the Company. The Bidders should be aware and place their Bids in a timely manner.
                            </TCText>
                            <TCText>
                                The Company shall not be held responsible for non-availability of the Site during periodic maintenance operations or any unplanned suspension of access to the Site that may occur due to technical reasons or for any reason beyond the Company's control. User understands and agrees that any material and/or data downloaded or otherwise obtained through the Site is done entirely at the User’s own discretion and risk and the User will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data.
                            </TCText>
                            <TCText>
                                The Company will not be liable for any loss or damage caused by distributed denial-of-service attacks, viruses or other technologically harmful material that may infect User’s computer equipment, computer programs, data or other proprietary material due to User’s use of the Site or download of any material from the Site.
                            </TCText>
                            <TCText>
                                The Company shall not be liable for the content developed by the Auctioneers, including without limitation the content for the e-auction. The Company shall also not be liable for any information required to be collected by the Auctioneers in addition to the registration by the Company. The Company shall further not be liable for the outcome of e-auction process.
                            </TCText>
                            <TCText>
                                Despite all possible security measures that the Company takes to keep Site free from hacking and other interference, the Site, like any other website, is not free from such risks. The Company disclaims all liability on account of any loss or damage that any User may suffer or incur on account of any alteration or manipulation of any data or information accessed or downloaded by the User from the Site.
                            </TCText>
                            <TCText>
                                The Company does not and will not, at any time, request your credit card information or national identification numbers in a non-secure or unsolicited e-mail or telephone communication.
                            </TCText>
                            <TCText>
                                At any time if, the Company believes that its services, Products and/or Sites are being utilized by the User in contravention of these Terms, the Company shall have the right to forthwith remove/ block/ close the account of such User and furnish such details about the User upon a request received from the legal/statutory/enforcement authorities or under a court order.
                            </TCText>
                            <TCText>
                                The Company takes all reasonable measures to protect and secure the information of the User. However, the Company shall not be liable for any loss or damage of data, personal and confidential information of the Users occurring due to instances that is beyond the control of the Company including without limitation, glitches in the internet, hacker attacks, virus, malicious software attacks and other events of like nature.
                            </TCText>
                            <TCText>
                                Nothing on the Site constitutes, or is meant to constitute as advice provided or recommendation made by the Company.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Limitation of Liability: </strong>The Company shall not be liable to the User for any transactions executed on Our Platform and the User hereby agrees to fully indemnify and holds the Company harmless against any action, suit, proceeding initiated against it or any loss, cost or damaged incurred by it as a result thereof. The Company shall under no circumstance be liable to the User if the access is not available in the desired manner for reasons including but not limited to natural calamities, legal restraints, faults in the telecommunication network or network failure or any other reason beyond our control. Under no circumstance shall the Company be liable for damages whatsoever whether such damages are direct, indirect, incidental, consequential, punitive, special and/or loss of profit and irrespective whether any claim is based on loss of revenue, interruption of business, or any loss of any character or nature and whether sustained by the user or any other person. Notwithstanding the foregoing, the Company’s maximum liability under these Terms shall in no event exceed INR 500/- (Indian Rupees Five Hundred Only).
                            </TCText>
                            <TCText>
                                The User agrees that any illegal or improper use of our Site shall render the User liable for payment of damages as decided by the Company and may result in suspension of the access to the Site.
                            </TCText>
                            <TCText>
                                User acknowledges and agrees that the warranties, disclaimers and the limitations of liability set forth herein reflect a reasonable and fair allocation of risk between the User and the Company, and that the warranties, disclaimers and the limitations of liability set forth herein form an essential basis of the bargain between User and Company. The Company would not be able to provide the Product to the User on an economically reasonable basis without these limitations.
                            </TCText>
                            <TCText>
                                The Products are controlled and offered by the Company from its facilities in India. If You are a User outside India, please take note the Company is subject only to Indian laws and only to the jurisdiction of Indian courts. The Company makes no representations that the Products or services are appropriate or available for use in other locations. Therefore, if you are a User outside India, you may use the Products or services solely on your own volition and at your own risk. You shall be solely responsible for compliance with local law.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Amendments: </strong>These Terms may be amended at any time by the Company. All such amendments shall be binding on the User effective 24 (twenty-four) hours after the amended Terms are made available on the Site. It shall be the User's sole responsibility to check this page regularly to take notice of any changes the Company may have made to these Terms. If the User does not agree with any amendment, the User should immediately stop accessing and using the Products on the Site with no liability from the Company to User except for such Products already purchased prior to the amendments.
                            </TCText>
                            <TCText>
                                Although the Company may attempt to notify the User when major changes are made to these Terms, it shall be solely the User's responsibility to read and understand the most up-to-date version of the Terms on the Site. Nothing in these Terms shall be deemed to confer any third-party rights or benefits.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Software Modification: </strong>The Company reserves the rights to modify, delete, add or change contents of the Platform/ Products with regards to the software from time to time. It is the responsibility of the User to refer to the terms or the Site to get updated on the same.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Governing Law: </strong>These Terms are governed by and shall be construed in accordance with the laws of the Republic of India without respect to its conflict of law provisions.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Dispute Resolution: </strong>The exclusive jurisdiction and venue for any suit, action, or proceeding arising out of the Agreement shall be with the Courts of Bengaluru, Karnataka, and the Parties agree not to raise, and hereby waive, any objections or defences based upon venue or forum non-conveniens. Any claims, disputes, disagreements or other matters in question arising out of or relating to this Agreement (“Claim”) shall be primarily resolved amicably by mediation within thirty (30) days of the receipt of such notice.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Assignment: </strong>The right to use the Products is personal to the User and is not transferable by assignment, sublicense, or any other method to any other person or entity.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Proprietary Rights: </strong>The User acknowledges that the Platform, including the software underlying e-auction, source code or object code associated with the Platform, and any intellectual property rights associated therewith, is the sole and exclusive property of the Company. The permission given by the Company to access the Platform will not convey any proprietary or ownership rights in the above software. The User shall not attempt to modify, translate, disassemble, decompile or reverse engineer the Platform or any portions of the Site or create any derivative works thereof. The display of trademarks, logos, graphical or other images, layout, design, or any other content on this Site, unless otherwise specifically noted, are the property of the Company alone.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Removal/Modification of Content: </strong>The User acknowledges that the Company may at its sole discretion review the contents submitted by Users on the Site, Bidders, to determine whether it is illegal, or violates the Terms, or is unlawful, or violate any third-party intellectual property. The Company may modify, prevent access to, delete or refuse to display your contents that We believe violates the law or these Terms. In the event a User’s content includes third-party brands, logos, or other sources identifiers, the Company may require such User to submit a statement of non-affiliation before the User uses them on the Site or in the Products. The Company shall have no obligation to monitor or review any content submitted on the Site.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Severability: </strong>If any provision of these Terms is invalid, illegal or unenforceable, it shall be ineffective only to the extent of such invalidity, illegality or unenforceability, and such invalidity, illegality or unenforceability shall not invalidate the balance of such provision to the extent it is not invalid, illegal or enforceable nor the remaining provisions hereof, nor render unenforceable such provision in any other jurisdiction. In the event any provisions of these Terms shall be held to be invalid, illegal or unenforceable, the Parties hereto shall use their best efforts to substitute valid, legal and enforceable provisions which, insofar as practical, implement the purposes hereof.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Notice: </strong>Any notice to be provided to the Company should be sent to
                                <TCOl>
                                    National E-Governance Services Ltd.
                                </TCOl>
                                <TCOl>
                                    5th Floor, The Estate, 121, Dickenson Road,
                                </TCOl>
                                <TCOl>
                                    Yellappa Garden, Yellappa Chetty Layout,
                                </TCOl>
                                <TCOl>
                                    Sivanchetti Gardens, Bengaluru, Karnataka 560042
                                </TCOl>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Waiver: </strong>Any failure by the Company to enforce or exercise any provision of the Terms of Use, or any related right, shall not constitute a waiver by the Company of that provision or right.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Termination: </strong>The Company reserves the rights to:
                                <ul>
                                    <TCLi>
                                        Terminate terms by email at any time without giving reasons
                                    </TCLi>
                                    <TCLi>
                                        Stop any auction/bid or ban users from using the Site
                                    </TCLi>
                                    <TCLi>
                                        To reject any registration or access to Site or temporarily or permanently suspend any registered user
                                    </TCLi>
                                    <TCLi>
                                        Withdraw or modify the Site without any notice
                                    </TCLi>
                                </ul>
                            </TCText>
                            <TCText>
                                The Auctioneer may not terminate this agreement whilst fees/ payment to the Company remains outstanding
                            </TCText>
                            <TCText>
                                On termination of the agreement/ terms the auction/ event / site access will cease and all information belonging to the party will be returned or destroyed
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Support: </strong>In the event Users have any concerns, queries or require clarifications they may contact the Company’s support team at support@enkindletech.com or call +91 8904179220. The support team will be available from 9am to 6pm from Monday to Friday/Saturday.
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Contact Us: </strong>If You have any concerns with your personal information, including that you would like us to update such information, please feel free to contact our Grievance Officer at: 9740988003. Our Grievance Officer shall direct you to the concerned administrator.
                            </TCText>
                        </li>
                        
                    </ul>
                </DialogContentText>
                <Divider />
                <br/>
                <DialogContentText id="scroll-dialog-description" ref={privacyPolicyRef}>
                    <Typography variant="h6" gutterBottom align="center">
                        <strong>Privacy Policy</strong>
                    </Typography>
                    <TCText>
                        Capitalized terms used herein and not defined shall have the meaning given to it in Customer Agreement published on the Platform:
                        <Link href='https://www.trusteauction.com/' target="_blank" underline="always">
                            trusteauction.com
                        </Link>
                    </TCText>
                    <TCText>
                        The aim of this privacy policy statement is to inform the Customer or Authorised Personnel or Authorised User (collectively hereinafter referred to as “<strong>Personal Information Provider</strong>") regarding Company’s practices regarding collection, use, disclosure and transfer of Personal Information that is either provided by Personal Information Provider or collected by the Company through Personal Information Provider’s use of the Platform (“<strong>Privacy Policy</strong>”).
                    </TCText>
                    <TCText>
                        Personal Information shall mean information that identifies Personal Information Provider (identifies an individual) and collected by the Company through Personal Information Provider’s use of the Platform or provided by Personal Information Provider in digital form.
                    </TCText>
                    <ul>
                        <li>
                            <TCText>
                                <strong>Collection, Consent</strong>
                                <ul>
                                    <TCLi>
                                        In order to utilize the Services, Personal Information Provider hereby consents to upload certain Personal Information including identity proofs and mobile number to verify such Personal Information Provider’s identity.
                                    </TCLi>
                                </ul>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Purpose, Opt Out and Withdrawal</strong>
                                <ul>
                                    <TCLi>
                                        If the Personal Information Provider chooses to participate in any activity that is offered through the Platform or takes benefit of any feature that is offered through the Platform, the Personal Information Provider may be required to furnish some Personal Information to the Company.
                                    </TCLi>
                                    <TCLi>
                                        The Personal Information Provider may decline to provide Personal Information that is requested from the Personal Information Provider, but this may affect our ability to provide to the Personal Information Provider the Services requested, or to allow Personal Information Provider’s participation in any activity that is offered on the Platform.
                                    </TCLi>
                                    <TCLi>
                                        The Personal Information Provider may, at any time, by writing to the Company, at the email address provided below, withdraw his/her consent for collection, use, disclosure and transfer of Personal Information by the Company in accordance with this Privacy Policy.
                                    </TCLi>
                                </ul>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Disclosure, Transfer</strong>
                                <ul>
                                    <TCLi>
                                        Personal Information may be hosted with a third party data centre, and the servers may be located in jurisdictions outside India. The Personal Information Provider acknowledges that the data stored in data centres located outside India may be governed by the laws of the jurisdiction in which the data centre is located. The Personal Information Provider specifically consents to the transfer of Personal Information to the data centres or servers operated by the third party service providers located in territories outside India that may not have equivalent laws and regulations regarding protection of personal information.
                                    </TCLi>
                                    <TCLi>
                                        The Company may disclose Personal Information as follows:
                                        <ul>
                                            <TCLi>
                                                To Company’s affiliates that agree to treat it in accordance with this Privacy Policy.
                                            </TCLi>
                                            <TCLi>
                                                To service providers and other partners who support the business of the Company, provided however, all such third parties agree not to use the Personal Information for purposes other than for which it is disclosed.
                                            </TCLi>
                                            <TCLi>
                                                To a purchaser or successor entity in connection with the sale, merger, assignment, or other transfer of the Company or any of its affiliates, or a business division of the Company or any of its affiliates, or sale of substantially all of the assets of the Company or any of its affiliates.
                                            </TCLi>
                                            <TCLi>
                                                If the disclosure is required by law, rule, regulation, or order of a court of competent jurisdiction or governmental authority, or regulatory authority.
                                            </TCLi>
                                            <TCLi>
                                                To enforce this Privacy Policy.
                                            </TCLi>
                                        </ul>
                                    </TCLi>
                                </ul>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Security Practices</strong>
                                <TCOl>
                                    The Company’s security practices with respect to the information collected by the Company is as set out below:
                                    <ul>
                                        <TCLi>
                                            As the Platform is accessed on the internet, Company uses Secured Socket Layers (SSL) for establishing an encrypted link between the web server on the Platform (website) and Personal Information Provider’s browser; however, the Internet is inherently insecure, therefore the Company cannot provide any assurance regarding the security of transmission of information that the Personal Information Provider communicate to the Company and the Personal Information Provider may do so at his/her own risk.
                                        </TCLi>
                                        <TCLi>
                                            Enkindle will secure the storage of Personal Information in compliance with the minimum security measures prescribed under the Information Technology Act, 2000, its regulation and standards, in the following manner:
                                            <ul>
                                                <TCLi>
                                                    register those who have access to the storage
                                                </TCLi>
                                                <TCLi>
                                                    control and limit access based on necessity
                                                </TCLi>
                                                <TCLi>
                                                    maintain proper record of access and transfer of Personal Information
                                                </TCLi>
                                                <TCLi>
                                                    ensure all employees of the Company protect confidentiality of the Personal Information
                                                </TCLi>
                                                <TCLi>
                                                    conduct awareness programmes to educate employees on responsibility to protect Personal Information
                                                </TCLi>
                                                <TCLi>
                                                    establish and enforce physical security procedures
                                                </TCLi>
                                                <TCLi>
                                                    bind third parties involved in processing of Personal Information
                                                </TCLi>
                                            </ul>
                                        </TCLi>
                                    </ul>
                                </TCOl>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>How to Contact the Company / Grievance Officer</strong>
                                <TCOl>
                                    If the Personal Information Provider has questions about this Privacy Policy or needs to contact the Company in connection with the use or disclosure of the Personal Information, please reach out to the Company by sending an email or a letter to:
                                    <TCOl>
                                        ENKINDLE TECHNOLOGIES PRIVATE LIMITED
                                    </TCOl>
                                    <TCOl>
                                        Attention: U.Kamalesh Rao
                                    </TCOl>
                                    <TCOl>
                                        Tel: +91 80 41218544
                                    </TCOl>
                                    <TCOl>
                                        E-mail: kamalesh.rao@enkindletech.com
                                    </TCOl>
                                    <br/>
                                    <TCOl>
                                        Grievance Officer, as required under the Information Technology Act, 2000 is: U.Kamalesh Rao
                                    </TCOl>
                                </TCOl>
                            </TCText>
                        </li>
                        <li>
                            <TCText>
                                <strong>Updates</strong>
                                <TCOl>
                                    The Company reserves the right to modify this Privacy Policy without the Personal Information Provider’s consent. The Company may inform the Personal Information Provider regarding modifications to the Privacy Policy by displaying a banner statement on the Platform or including a statement regarding modifications at the bottom of this Privacy Policy. If the Personal Information Provider continue to use the Platform after information regarding the modifications is published, you shall be deemed to have accepted the revised Privacy Policy.
                                </TCOl>
                                <br/>
                                <TCOl>
                                    This Privacy Policy was last updated on 01 June, 2022.
                                </TCOl>
                            </TCText>
                        </li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button id="disagree" onClick={ () => acceptHandler(false) } color="primary">
                    DISAGREE
                </Button>
                <Button variant='contained' id="agree" onClick={() => acceptHandler(true) } color="primary">
                    AGREE
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TermsAndConditions;