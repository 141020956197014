import { InfoRounded, Refresh, ReportGmailerrorredOutlined, Timelapse } from '@mui/icons-material';
import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiAlarmClock } from 'react-icons/gi';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { AuctionStatus, AutoExtensionMode, MannerOfSale } from '../../common/StaticVariableDeclaration';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import { UserContext } from '../../common/context/UserContext';
import { AxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import AuctionTimer from './AuctionTimer';
import EnglishForwardBid, { AuctionInfoCard } from './EnglishForwardBid';
import { DisplayInfoChip } from './bid-process-components/BidProcessComponents';
import { CurrencyFormatter } from '../../utils/Utils';
import { DisplayInfoWithLabel } from '../../auctioneer-auction-hall/AuctionHall';

const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        borderColor: '#38d !important',
        boxShadow: '0 0 10px rgb(51 136 221 / 50%)'
    },
    price: {
        fontWeight: '600'
    },
    extension: {
        boxShadow: 'rgb(173 210 221 / 9%) 0px 13px 27px -5px, rgb(208 221 226) 0px 8px 16px -8px !important',
        border: '1px solid rgb(171 207 225)',
        padding: '5px 10px !important'
    },
    refreshBtn: {
        position: 'absolute !important',
        top: '10px',
        textTransform: 'capitalize !important',
        fontWeight: 'bold !important',
        color: 'black !important',
        background: 'lightgoldenrodyellow !important',
        '&:hover': {
            background: 'palegoldenrod !important',
        }
    },
    refreshInfo: {
        position: 'absolute !important',
        top: '15px',
        left: '115px',
        fontWeight: 'bold !important',
        color: '#bf7a4c',
        fontSize: '12px !important'
        // color: theme.palette.warning.light
    },
    refreshIcon: {
        animationName: '$spin',
        animationDuration: '5000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
    },
    '@keyframes spin': {
        'from': {
            transform: 'rotate(0deg)'
        },
        'to': {
            transform: 'rotate(360deg)'
        },
    },
    idChip: {
        background: theme.palette.type === 'light' ? '#f4f4f8' : '#262626',
        padding: '0.1rem 0.4rem',
        borderRadius: '4px',
        width: 'fit-content',
        height: 'fit-content',
    },
    "@keyframes flicker": {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0.2
        }
    },
    flicker: {
        animationName: "$flicker",
        animationDuration: "800ms",
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out"
    },
    fastFlicker: {
        animationName: "$flicker",
        animationDuration: "200ms",
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out"
    },
    infoMsg: {
        // background: theme.palette.warning.light, 
        color: theme.palette.warning.main, 
        borderRadius: '5px',
        border: `1px solid ${theme.palette.warning.dark}`,
        width: 'fit-content',
        padding: '2px 5px !important'
        // position: 'absolute'
    },
}))


/* This component will collect all the required data for Bid on Going Concern,
Single Item and Single Lot and Update auction detail every 30 seconds */
const Bid = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const auctionDetailContext = useContext(AuctionDetailContext);
    const [endDateTime, setEndDateTime] = useState(null)
    const [onlyMinutes, setOnlyMinutes] = useState(null);
    const [bidderId, setBidderId] = useState('');
    const [isClosed, setIsClosed] = useState(false)
    const userContext = useContext(UserContext).rootContext;
    const [loading, setLoading] = useState();
    const [bidHistory, setBidHistory] = useState([]);
    const [auctionLotInfo, setAuctionLotInfo] = useState();
    const [previousHighBid, setPreviousHighBid] = useState();
    const [bidTransactionId, setBidTransactionId] = useState(null);
    const [initialRender, setInitialRender] = useState(true);
    const [showExtensionMessage, setShowExtensionMessage] = useState({
        show: false,
        message: '',
        severity: 'info'
    });
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: 'info'
    });
    const [showAuctionStatusMessage, setShowAuctionStatusMessage] = useState({
        show: false,
        message: '',
        severity: 'info',
        weight: 'bold'
    });
    const [bidHeading, setBidHeading] = useState({
        bidLabel: t('Auction'),
        bidDescription: t('Asset_Brief'),
        idLabel: t('Auction'),
        idDescription: ''
    })
    const [intervalId, setIntervalId] = useState(null);
    const [reloadBid, setReloadBid] = useState(null);

    const getBids = (auctionId, bidOnId) => {
        setMessage({ show: false, message: '', severity: '' });
        AxiosAuctionInterceptor.get(`${process.env.REACT_APP_AWS_ENDPOINT}bid/${auctionId}/${bidOnId}`).then(function (response) {
            if (response) {
                auctionDetailContext.setAuctionGetDetail(response.auction);
                setLotInfoBasedOnMannerOfSale(response.auction?.auctionLots,
                    response.auction?.mannerOfSale, response.auction, response)
                setEndDateTime(response.auction?.auctionLots[0].endTime);
                setLoading(false);
            }
        }).catch(function (error) {
            setLoading(false);
            if(error.message == 'Network Error') {
                setMessage({ show: true, message: t('Please_Check_Internet_Connection'), severity: "error" });
            } else {
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        });
    }


    const setLotInfoBasedOnMannerOfSale = (auctionLots, mannerOfSale, auctionInfo, bidInfo) => {
        if (auctionLotInfo !== undefined) {
            let previousEndDate = auctionLotInfo.endTime;
            setPreviousHighBid(auctionLotInfo.currentHighestBid);
            //check if there is anyextension happened Going Concern, SingleLot and SingleItem
            if (new Date(auctionLots[0].endTime) > new Date(previousEndDate)) {
                setShowExtensionMessage({
                    show: true,
                    message: t('Auction_Extended_till') + ' ' + format(new Date(auctionInfo.endTime), 'dd MMM yyyy hh:mm:ss a'),
                    severity: 'info'
                })
                setTimeout(() => {
                    setShowExtensionMessage({
                        show: false,
                        message: '',
                        severity: 'info'
                    })
                }, 6000)
            }
        }
        auctionLots.map((value) => {
            setAuctionLotInfo({
                id: value.id,
                endTime: value.endTime,
                autoExtension: value.autoExtension,
                autoExtensionMode: value.autoExtensionMode,
                extendTimeBy: value.extendTimeBy,
                extendTimeWhenBidReceivedInLastMinute: value.extendTimeWhenBidReceivedInLastMinute,
                startPrice: value.bidStartPrice,
                reservedPrice: value.reservePrice,
                incrementalPrice: value.incrementalAmount,
                noOfExtension: value.noOfExtension,
                extensionTimeIncrementPrice: value.incrementalAmountDuringExtension > 0 ? value.incrementalAmountDuringExtension : value.incrementalAmount,
                incremental: value.incrementalAmount > 0 ? true : false,
                basePrice: value.bidStartPrice > 0 ? value.bidStartPrice : value.reservePrice,
                isExtended: '',
                //Bid values
                yourLastBid: bidInfo.amount !== null ? bidInfo.amount : 0,
                currentHighestBid: bidInfo.latestBidAmount !== null ? bidInfo.latestBidAmount : 0,
                yourRank: bidInfo.rank !== null ? bidInfo.rank : 0,
                currentDateTime: bidInfo.systemDateTime,
                extensionNumber: bidInfo.extensionNumber !== null ? bidInfo.extensionNumber : 0,
            })
        });
        setBidderId(bidInfo.bids.length > 0 ? bidInfo.bids[0].bidderId : '')
        setBidHistory([]);
        bidInfo.bids.map((bid) => {
            setBidHistory(prevState => ([
                ...prevState,
                {
                    startTime: bid.startTime,
                    amount: bid.amount,
                    performedBy: bid.performedBy,
                }
            ]));
        })
        // setBidHistory(bidInfo.bids);
        switch (mannerOfSale) {
            case MannerOfSale.goingConcern:
                let goingConcernManner = userContext.enumList?.MannerOfSale?.filter((item) => MannerOfSale.goingConcern === item.dateBaseCode)
                setBidHeading({
                    bidLabel: t('Asset_Brief'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction_ID'),
                    idDescription: auctionInfo.auctionId,
                    mannerOfSaleUiCode: goingConcernManner.length > 0 ? goingConcernManner[0].uiCode : t('Going_Concern')
                })
                return;
            case MannerOfSale.singleLot:
                let singleLotManner = userContext.enumList?.MannerOfSale?.filter((item) => MannerOfSale.singleLot === item.dateBaseCode)
                setBidHeading({
                    bidLabel: t('Asset_Brief'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction_ID'),
                    idDescription: auctionInfo.auctionId,
                    lotLabel: t('Lot_Number'),
                    lotNumber: auctionLots[0].lotNumber,
                    // lotDescription: auctionLots[0].description,
                    mannerOfSaleUiCode: singleLotManner.length > 0 ? singleLotManner[0].uiCode : t('Single_Lot')
                })
                return;
            case MannerOfSale.singleItem:
                let singleItemManner = userContext.enumList?.MannerOfSale?.filter((item) => MannerOfSale.singleItem === item.dateBaseCode)
                setBidHeading({
                    bidLabel: t('Asset_Brief'),
                    bidDescription: auctionInfo.description,
                    idLabel: t('Auction_ID'),
                    idDescription: auctionInfo.auctionId,
                    mannerOfSaleUiCode: singleItemManner.length > 0 ? singleItemManner[0].uiCode : t('Single_Item')
                })
                return;
        }
    }

    const handleRefresh = () => {
        setLoading(true);
        resetIntervalLoad();
    }

    const loadBidOnClose = () => {
        clearInterval(intervalId);
        setTimeout(() => { 
            setReloadBid(prevState => (!prevState));
        }, 500);
        const listIntervalId = setInterval(() => {
            setReloadBid(prevState => (!prevState));
        }, 3000);
        setIntervalId(listIntervalId);
    }

    const resetIntervalLoad = () => {
        clearInterval(intervalId);
        setReloadBid(prevState => (!prevState));
        const listIntervalId = setInterval(() => {
            setReloadBid(prevState => (!prevState));
        }, 3000);
        setIntervalId(listIntervalId);
    }

    useEffect(() => {
        if(reloadBid != null){
            getBids(auctionDetailContext.auctionGetDetail.id, auctionDetailContext.auctionGetDetail.auctionLots[0].id);
        }
    }, [reloadBid])

    // const Timer = useCallback(() => {
    //     return (
    //         <Fragment>
    //             {endDateTime !== null && <AuctionTimer
    //             date={endDateTime}
    //             title={t('Live_Ends_in')}
    //             isBid={true}
    //             setOnlyMinutes={setOnlyMinutes}
    //             terminated={() => loadBidOnClose}
    //         />}
    //         </Fragment>
    //     )
    // }, [endDateTime])

    useEffect(() => {
        if ( !isClosed && (auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed
            || auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.cancelled)) {
            setIsClosed(true);
            if(!initialRender) {
                setShowAuctionStatusMessage({ show: false, message: '', severity: '', weight: 'bold' });
                if(auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed) {
                    setShowAuctionStatusMessage({ show: true, message: t('Auction_Is_Closed'), severity: "error", weight: 'bold' })
                } else {
                    setShowAuctionStatusMessage({ show: true, message: t('Auction_Is_Cancelled'), severity: "error", weight: 'bold' });
                    clearInterval(intervalId);
                }
            }
        }
        if( auctionDetailContext.auctionGetDetail.status.toLowerCase() != AuctionStatus.closed && auctionDetailContext.auctionGetDetail.status.toLowerCase() != AuctionStatus.cancelled) {
            setIsClosed(false);
            setShowAuctionStatusMessage({ show: false, message: '', severity: '', weight: 'bold' });
        }
    }, [auctionDetailContext.auctionGetDetail.status])

    useEffect(() => {
        return () => {
            if(intervalId){
                clearInterval(intervalId);
            }
        }
    }, [intervalId])

    useEffect(() => {
        setInitialRender(false);
        setLoading(true);
        setReloadBid(true);
        if (auctionDetailContext.auctionGetDetail.status.toLowerCase() !== AuctionStatus.cancelled && auctionDetailContext.auctionGetDetail.status.toLowerCase() !== AuctionStatus.closed) {
            const listIntervalId = setInterval(() => {
                setReloadBid(prevState => (!prevState));
            }, 3000);
            setIntervalId(listIntervalId);
        } else if(auctionDetailContext.auctionGetDetail.status.toLowerCase() === AuctionStatus.closed){
            let diff = new Date(auctionDetailContext.auctionGetDetail.currentTime).getTime() - new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].endTime).getTime();
            if(diff < 43200000) {
                const listIntervalId = setInterval(() => {
                    setReloadBid(prevState => (!prevState));
                }, 5000);
                setIntervalId(listIntervalId);
            }  
        }
    }, []);

    return (
        <Fragment>
            {(auctionLotInfo && !loading) &&
                <div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button id='refresh' className={classes.refreshBtn} color='primary' variant='contained' onClick={handleRefresh}
                                startIcon={<Refresh className={classes.refreshIcon} />} size='small'>
                                {t('Refresh')}
                            </Button>
                            <Typography className={classes.refreshInfo}>{t('Page_Will_Automatically_Refresh_Every_Five_Seconds')}</Typography>
                        </Grid>
                    </Grid>
                    
                    <AuctionInfoCard>
                        <Grid container spacing={1}>
                            <Grid item xs={0.5} mt={2}>
                                <img src={process.env.PUBLIC_URL + '/images/bidnow.svg'} width='50px' height='50px'></img>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8.5} lg={8.5}>
                                <Stack spacing={0.8} pl={2}>
                                    <DisplayInfoWithLabel labelId='itemlabel' label={t('Asset_Brief')} descriptionId='itemdescription'
                                        description={bidHeading.bidDescription} />
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <DisplayInfoWithLabel labelId='auction-id-label' label={bidHeading.idLabel} descriptionId='auction-id-description'
                                                description={bidHeading.idDescription} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <DisplayInfoWithLabel labelId='mannerlabel' label={t('Manner_Of_Sale')} descriptionId='mannerdescription'
                                                description={bidHeading.mannerOfSaleUiCode} />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        { bidderId != '' && <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <DisplayInfoWithLabel labelId='bidder-id-label' label={t('Bidder_ID')} descriptionId='bidder-id-description'
                                                description={bidderId} />
                                        </Grid>}
                                        { MannerOfSale.singleLot === auctionDetailContext.auctionGetDetail.mannerOfSale &&
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <DisplayInfoWithLabel labelId='lotlabel' label={bidHeading.lotLabel} descriptionId='lotdescription'
                                                    description={bidHeading.lotNumber} />
                                            </Grid>
                                        }
                                    </Grid>
                                    {/* TODO Remove Nested Ternary operation */}
                                    <DisplayInfoWithLabel labelId='extensionlabel' label={t('Extension_Details')}
                                        descriptionId='extensiondescription' description={auctionLotInfo.autoExtension ?
                                            `${t('Auto')} / ${AutoExtensionMode.limitedExtension === auctionLotInfo.autoExtensionMode ? `${t('Limited_Extension')} / ${auctionLotInfo.noOfExtension} ${t('Extensions')}` :
                                                t('Unlimited_Extension')}` : t('No_Auto_Extension')} />
                                    {auctionLotInfo.autoExtension && <Stack direction='row' id='extend-msg' spacing={0.25} padding={0.25} className={classes.infoMsg} alignItems='start' 
                                            justifyItems='center'>
                                        <InfoRounded color='warning' sx={{fontSize: '1rem'}}/>
                                        <Typography variant='subtitle2' sx={{fontSize: '12px'}}><strong>{t('Note')} : </strong></Typography>
                                        <Typography variant='subtitle2' sx={{fontSize: '12px'}}>Auction will be extended for <strong>{auctionLotInfo.extendTimeBy}</strong> minutes if a bid is placed in the last <strong>{auctionLotInfo.extendTimeWhenBidReceivedInLastMinute}</strong> minutes</Typography>
                                    </Stack>}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} alignItems='center' justifyItems='end' textAlign='center' margin='auto'>
                                {!isClosed &&
                                    <Stack direction={{ xs: 'column' }} alignItems='center' spacing={0.5} justifyContent='center'>
                                        <Stack direction={{ xs: 'row' }} alignItems='center' spacing={2}>
                                            <GiAlarmClock fontSize='1.7rem' color='#0aa8b4' />
                                            <Typography id='remainingtimelabel' variant='subtitle2' color='textSecondary'>
                                                <Fragment> {t('Remaining_Time')}</Fragment>
                                                {/* {auctionDetailContext.auctionGetDetail.auctionLots[0].actualEndTime < auctionDetailContext.auctionGetDetail.auctionLots[0].endTime ?
                                                    <Fragment> {t('Extension_Time_Remaining')}</Fragment> :
                                                    <Fragment> {t('Remaining_Time')}</Fragment>} */}
                                            </Typography>
                                        </Stack>
                                        {/* <Timer /> */}
                                        {endDateTime !== null && <AuctionTimer
                                            date={endDateTime}
                                            title={t('Live_Ends_in')}
                                            isBid={true}
                                            setOnlyMinutes={setOnlyMinutes}
                                            terminated={loadBidOnClose}
                                        />}
                                        { auctionLotInfo.autoExtension && auctionLotInfo.extensionNumber > 0 &&
                                            <Stack direction={{ xs: 'row' }} alignItems='center' spacing={2} justifyContent='center' sx={{ paddingTop: '10px' }}>
                                                <Timelapse fontSize='1.7rem' color='#0aa8b4' />
                                                {auctionLotInfo.autoExtensionMode === AutoExtensionMode.limitedExtension && auctionLotInfo.noOfExtension === auctionLotInfo.extensionNumber ?
                                                    <Typography id='completeextension' variant='subtitle2' color='textSecondary'>{t('Complete_extensions_in_no')}:</Typography>
                                                    : <Typography id='currentextension' variant='subtitle2' color='textSecondary'>{t('Current_extensions_in_nos')}:</Typography>
                                                }
                                                <Card className={classes.extension}>
                                                    {auctionLotInfo.extensionNumber}
                                                </Card>
                                            </Stack>
                                        }
                                    </Stack>
                                }
                                {isClosed &&
                                    <Stack direction={{ xs: 'column' }} alignItems='center' spacing={0.5} justifyContent='center'>
                                        <Card className={classes.flicker}
                                            sx={{
                                                mb: 2, p: 1, background: AuctionStatus.cancelled === auctionDetailContext.auctionGetDetail.status.toLowerCase() ?
                                                'linear-gradient(195deg, rgb(254, 236, 209), rgb(255, 232, 202)) !important' : '#ff9f9f !important', display: 'inline-flex' }} >
                                            <Stack direction='row' spacing={1} justifyContent='flex-start'>
                                                <ReportGmailerrorredOutlined sx={{
                                                    color: AuctionStatus.cancelled === auctionDetailContext.auctionGetDetail.status.toLowerCase() ?
                                                    'rgb(98,70,2)' : '#730909' }} />
                                                <Typography id='auctionclosed'>{AuctionStatus.cancelled === auctionDetailContext.auctionGetDetail.status.toLowerCase()
                                                    ? t('Auction_Cancelled') : t('Auction_Closed') }</Typography>
                                            </Stack>
                                        </Card>
                                        { auctionLotInfo.autoExtension && auctionLotInfo.extensionNumber > 0 &&
                                            <Stack direction={{ xs: 'row' }} alignItems='center' spacing={2} justifyContent='center' sx={{ paddingTop: '10px' }}>
                                                <Timelapse fontSize='1.7rem' color='#0aa8b4' />
                                                <Typography id='completeextension' variant='subtitle2' color='textSecondary'>{t('Complete_extensions_in_no')}:</Typography>
                                                <Card className={classes.extension}>
                                                    {auctionLotInfo.extensionNumber}
                                                </Card>
                                            </Stack>
                                        }
                                    </Stack>
                                    }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={0.3}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <DisplayInfoChip title={t('Reserve_Price')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.reservedPrice)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <DisplayInfoChip title={t('Bid_Start_Price')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.startPrice)} />
                            </Grid>
                            {auctionLotInfo.incremental &&
                                <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                    <DisplayInfoChip title={t('Incremental_Amount')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.incrementalPrice)} />
                                </Grid>}
                            {(auctionLotInfo.autoExtension && auctionLotInfo.incremental) &&
                                <Grid item xs={12} sm={6} md={4} lg={3.5}>
                                    <DisplayInfoChip title={t('Incremental_Amount_During_Extension')} value={CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionLotInfo.extensionTimeIncrementPrice)} />
                                </Grid>}
                        </Grid>
                    </AuctionInfoCard>
                    <EnglishForwardBid auctionLotInfo={auctionLotInfo}
                        getLatestsBids={resetIntervalLoad} yourLastBid={auctionLotInfo.yourLastBid} yourRank={auctionLotInfo.yourRank}
                        currentHighestBid={auctionLotInfo.currentHighestBid} previousHighBid={previousHighBid !== undefined ? previousHighBid : 0}
                        biddersBidHistory={auctionLotInfo.bidHistory}lotId={auctionLotInfo.id}
                        auctionId={auctionDetailContext.auctionGetDetail.id} isClosed={isClosed}
                        onlyMinutes={onlyMinutes} endDate={auctionDetailContext.auctionGetDetail.endTime}
                        actualIncrementalPrice={auctionDetailContext.auctionGetDetail.auctionLots[0].actualEndTime < auctionDetailContext.auctionGetDetail.auctionLots[0].endTime
                            ? auctionLotInfo.extensionTimeIncrementPrice : auctionLotInfo.incrementalPrice}
                        bidderId={userContext.userSession.session.userId} bidHeading={bidHeading}
                        bidHistory={bidHistory} setBidHistory={setBidHistory}
                        bidTransactionId={bidTransactionId} setBidTransactionId={setBidTransactionId}
                    />
                </div>}
            {showExtensionMessage.show && <AuctionSnackBar show={showExtensionMessage.show} message={showExtensionMessage.message} severity={showExtensionMessage.severity} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            { showAuctionStatusMessage.show &&
                <AuctionSnackBar
                    show={showAuctionStatusMessage.show}
                    message={showAuctionStatusMessage.message}
                    severity={showAuctionStatusMessage.severity}
                    weight={showAuctionStatusMessage.weight}
                />
            }
            {loading && <AuctionLoader show={true} invisible={false} />}
        </Fragment>
    )
}

export default React.memo(Bid)
