import { isValidPhoneNumber } from 'react-phone-number-input';

const requiredValidation = (fieldName, fieldValue) => {
    if (fieldValue instanceof Date) {
        if (fieldValue === null || fieldValue === '') {
            return `${fieldName} is required`;
        }
    } else if (fieldValue === null || fieldValue === '') {
        return `${fieldName} is required`;
    } else if (fieldValue.trim() === '') {
        return `${fieldName} is required`;
    }
    return null;
};

const equalValidation = (fieldName, fieldValue1, feildValue2) => {
    if (fieldValue1.trim() != '' && feildValue2 != '') {
        if (fieldValue1.trim() != feildValue2.trim()) {
            return `Password Mismatch`;
        }
    }
    return null;
}

const emailValidation = (email) => {
    if (email.trim().includes(" ")) {
        return 'Please enter a valid email, should not enter whitespace'
    } else if (
        /(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        )
    ) {
        return null;
    }
    return 'Please enter a valid email';
};

const mobileNumberValidation = (mobileNumber) => {
    if (!isValidPhoneNumber(mobileNumber)) {
        return `Invalid Mobile Number`;
    }
    return null;
};

const percentageValidation = (fieldName, percentage) => {
    if (!(/((\d+)((\.\d{1,2})?))$/.test(percentage))) {
        return `Invalid ${fieldName}`;
    } else if (percentage < 0) {
        return `${fieldName} cannot be less than 0%`;
    } else if (percentage > 100) {
        return `${fieldName} cannot be more than 100%`;
    }
    return null;
};

const maxLengthValidation = (fieldName, value, maxLength) => {
    if (value.length > maxLength) {
        return `Maximum ${maxLength} characters`;
    }
    return null;
};

const gstValidation = (identification) => {
    if (identification != '' && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}$/.test(identification))) {
        return `Invalid GSTIN`;
    }
    return null;
};

const panValidation = (identification) => {
    if (identification != '' && !(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(identification))) {
        return `Invalid PAN`;
    }
    return null;
};

export {
    requiredValidation, emailValidation, mobileNumberValidation, percentageValidation, equalValidation,
    maxLengthValidation, gstValidation, panValidation
};