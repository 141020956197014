
import { useLazyQuery } from "@apollo/client";
import { Email, Phone } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button, Dialog, DialogContent, DialogContentText,
    Fab, Grid, Paper, Stack, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import GraphQLErrors from "../common/GraphQLErrors";
import { GET_ROLE } from "../GraphQL/Queries";
import { UserContext } from "../common/context/UserContext";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const useStyles = makeStyles((theme) => ({
    details: {
        color: theme.roleDetailsColor.color,
        padding: '0.8rem 0rem 0.8rem 0.8rem',
        fontSize: '20px !important'
    },
    closeBtn: {
        marginTop: '0.5rem !important',
        float: 'right',
        color: `${theme.roleDetailsColor.color} !important`
    },
    container: {
        background: theme.roleDetailsColor.containerColor
    },
    emailPaper: {
        padding: '10px',
        boxShadow: 'rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 8px 16px -6px !important',
    },
    phoneFab: {
        marginTop: '-31px !important',
        float: 'right',
        height: '34px !important',
        width: '34px !important',
        color: `${theme.roleDetailsColor.fabPhone} !important`,
        cursor: 'auto !important'
    },
    phonePaper: {
        padding: '10px !important',
        width: '180px !important',
        boxShadow: 'rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 8px 16px -6px !important'
    },
    date: {
        color: theme.roleDetailsColor.date
    },
    tableHead: {
        background: `${theme.rolesHeaderBachground} !important`
    }

}));

function RoleDetail(props) {
    const { t } = useTranslation();
    const classes = useStyles()
    const [open, setOpen] = useState(props.show);
    const [user] = useState(props.user);
    const [role, setRole] = useState([])
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const columns = [
        { id: 'role', label: t('Role'), minWidth: 15 },
        { id: 'granted', label: t('Assigned'), minWidth: 25 },
        { id: 'revoked', label: t('Revoked'), minWidth: 25 },
        { id: 'Remarks', label: t('Remarks'), minWidth: 35 },
    ];

    const [getRole, { loading: roleLoading, error: roleError }] = useLazyQuery(GET_ROLE, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setRole([])
            if (data.getRole != null) {
                data.getRole.roleDetails.map(role => {
                    let roleTypeDescription = ''
                    enumListOptions.Role.find((roleType) => {
                        if (roleType.dateBaseCode == role.role) {
                            roleTypeDescription = roleType.uiCode === 'Viewer' ? "Observer" : roleType.uiCode;
                        }
                    })
                    setRole(prevState => [
                        ...prevState,
                        {
                            id: role.id,
                            role: roleTypeDescription,
                            granter: role.granter,
                            revoker: role.revoker,
                            startTime: role.startTime,
                            endTime: role.endTime,
                            remarks: role.remarks
                        }
                    ])
                })
            }
        }
    })

    useEffect(() => {
        getRole({
            variables: {
                auctionId: props.auctionId,
                userId: user.userId
            }
        })
    }, [])

    const handleClose = () => {
        setOpen(false);
        props.setShowRoleDetails({
            show: false,
            user: ''
        });
    }

    return (
        <div id='role-div'>
            <Dialog onClose={handleClose} open={open} aria-labelledby="role-detail-title" id='dialogRole'
                aria-describedby="role-detail-description" fullWidth
                maxWidth="md">
                <Grid container className={classes.container} >
                    <Grid item xs={6} >
                        <Typography id="role-detail-title" className={classes.details} >
                            {t("Details_of")} {user.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button id='btnClose' className={classes.closeBtn} onClick={handleClose}><CloseIcon /></Button>
                    </Grid>
                </Grid>
                <DialogContent id='content'>
                    <DialogContentText id="role-detail-description" >
                        <Stack direction='row' justifyContent='center' alignItems='center' >
                            <Paper elevation={0} className={classes.emailPaper}>
                                <Stack direction='row'>
                                    <MailOutlineIcon />&nbsp;&nbsp;
                                    {user.email}
                                </Stack>
                            </Paper>&nbsp;&nbsp;
                            <Paper elevation={0} className={classes.phonePaper}>
                                <Stack direction='row'>
                                    <LocalPhoneIcon />&nbsp;&nbsp;
                                    {user.phoneNumber ? user.phoneNumber : '-'}
                                </Stack>
                            </Paper>
                        </Stack><br />
                        <Grid item xs={12}>
                            <TableContainer id='tablecontainer'>
                                <Table id='table' stickyHeader aria-label="sticky-table-header" >
                                    <TableHead id='tableHead' className={classes.tableHead}>
                                        <TableRow id='tableRow'>
                                            {columns.map((column, index) => (
                                                <TableCell id={`cell-column${index}`} key={index} style={{ width: column.minWidth + "%", fontWeight: 'bold', fontSize: '15px' }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='body' >
                                        {role.length > 0 && role.map((assignedrole, index) => {
                                            return (
                                                <TableRow hover key={assignedrole.id} id={`role${index}`} style={index % 2 ? { background: "#fffec8" } : { background: "white" }}>
                                                    <TableCell id={`role-cell${index}`} size='small' >
                                                        <span>{assignedrole.role}</span>
                                                    </TableCell>
                                                    <TableCell id={`granted-by${index}`} size='small' >
                                                        <span>{assignedrole.granter}</span>
                                                        <div className={classes.date}><em>{format(new Date(assignedrole.startTime), 'dd-MM-yyyy hh:mm a')}</em></div>
                                                    </TableCell>
                                                    <TableCell id={`revoke-by${index}`} size='small'>
                                                        <span>{assignedrole.revoker}</span>
                                                        <div className={classes.date}><em>{new Date(assignedrole.endTime).getFullYear() === 10000 ? '--' : format(new Date(assignedrole.endTime), 'dd-MM-yyyy hh:mm a')}</em></div>
                                                    </TableCell>
                                                    <TableCell id={`remarks${index}`} size='small' >
                                                        <span>{assignedrole.remarks}</span>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                {(roleLoading) && <AuctionLoader show={true} invisible={false} />}
            </Dialog>
            {(roleError) && <GraphQLErrors GraphQLErrors error={roleError} show={false} />}
        </div>
    )
}

export default RoleDetail;