import { Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import './AuctionLoader.css';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 1,
        position: 'absolute !important',
        backgroundColor: 'rgba(255, 255, 255, 0.26) !important'
    }
}))

const AuctionLoader = (props) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Backdrop className={classes.backdrop} open={props.show} invisible={props.invisible}>
                <div className="blocks" id="auction-loading">
                    <div className="block orange" id="loading-orange"></div>
                    <div className="block blue" id="loading-blue"></div>
                    {props.message && <div style={{ fontWeight: '700', fontSize: '20px', color: 'forestgreen' }}>{props.message}</div>}
                </div>
            </Backdrop>
        </Fragment>
    )
}

export default AuctionLoader