import React, { useState, useContext, useRef } from 'react';
import { Grid, Paper, Box, Button, Fab, Tooltip, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { AuctionDetailContext } from '../../auction/auction-detail/AuctionDetail';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import EditIcon from '@mui/icons-material/Edit';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import { Roles, AuctionStatus, MannerOfSale, AutoExtensionMode } from '../../common/StaticVariableDeclaration';
import { UserContext } from "../../common/context/UserContext";
import { AuctionDetailMenuItems } from '../../auction/auction-detail/AuctionDetailStaticVar';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { AuctionUpdate } from './AuctionUpdate';
import { CurrencyFormatter } from '../../utils/Utils';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: '0px !important',
        width: '20%',
        padding: '10px 10px !important',
        color: `${theme.table.dataViewColor} !important`,
    },
    subTableCellHeader: {
        width: '20%',
        borderBottom: '0px !important',
        borderLeft: `6px solid ${theme.palette.background.default}`,
        color: `${theme.table.dataViewColor} !important`,
        padding: '10px 10px !important',
    },
    subTableCellValue: {
        borderBottom: '0px !important',
    },
    lightBorder: {
        borderLeft: `1px solid ${theme.palette.background.paper}`
    },
    darkBorder: {
        borderLeft: `1px solid ${theme.table.trAltColor}`
    },
    tableFullRow: {
        borderBottom: '0px !important',
        width: '100%',
        padding: '10px 10px !important',
        fontSize: '0.875rem'
    },
    lableHeader: {
        color: `${theme.table.dataViewColor} !important`
    },
    customChip: {
        border: `2px solid ${theme.step.contentBorderColor}`,
        margin: '2px 5px 2px 0px',
        padding: '0px 8px',
        borderRadius: '14px',
        display: 'inline-block'
    },
    moveToBottom: {
        height: '40px !important',
        width: '40px !important',
        position: 'fixed !important',
        bottom: '20px !important',
        right: '20px !important',
        float: 'right !important',
        boxShadow: 'none !important'
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.table.trAltColor,
        marginTop: '10px !important'
    },
}));

export const AuctionSpecificDetailView = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const auctionDetailContext = useContext(AuctionDetailContext);
    const [showAuctionUpdate, setShowAuctionUpdate] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const isCustomerSupport = (useContext(UserContext).rootContext.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (useContext(UserContext).rootContext.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const bottomRef = useRef(null);
    const dateTimeZoneValue = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

    const showSuccessMsg = (msg) => {
        setShowAuctionUpdate(false);
        props.handleGetAuctionDetail();
        if (msg) {
            setMessage({
                show: true,
                message: msg.message,
                severity: msg.severity
            });
            resetMessage();
        }
    }

    const resetMessage = () => {
        setTimeout(() => setMessage({
            show: false,
            message: '',
            severity: ''
        }), 4000);
    }

    const navigateToAuctionLotOrItem = () => {
        auctionDetailContext.setSelectedList(AuctionDetailMenuItems.ITEMS.value);
    }

    const ItemCategoryListOfDescription = ({ list }) => {
        return list.map((data, index) => <span className={classes.customChip} key={index}>{enumListObject.ItemType[data].uiCode}</span>);
    }

    const moveToBottomOfPage = () => {
        bottomRef.current.scrollIntoView(true);
    }

    return (<Box sx={{ width: '100%', height: 'auto' }}>
        <Grid container>
            {auctionDetailContext.auctionGetDetail.status.toLowerCase() != AuctionStatus.closed &&
                auctionDetailContext.auctionGetDetail.status.toLowerCase() != AuctionStatus.cancelled &&
                (((auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.scheduled || auctionDetailContext.auctionGetDetail.status.toLowerCase() == AuctionStatus.open) &&
                    (auctionDetailContext.auctionGetDetail.roles.includes(Roles.support) && isCustomerSupport) || isAdmin) ||
                    ((auctionDetailContext.auctionGetDetail.status.toLowerCase() != AuctionStatus.open && auctionDetailContext.auctionGetDetail.status.toLowerCase() != AuctionStatus.scheduled)
                        && (auctionDetailContext.auctionGetDetail.roles.includes(Roles.auctioneer) || auctionDetailContext.auctionGetDetail.roles.includes(Roles.support)))
                ) && <React.Fragment>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6} sx={{ padding: '4px 0px' }}>
                        <Button
                            id='auction-update-button'
                            size='small'
                            variant="contained"
                            startIcon={<EditIcon />}
                            sx={{ textTransform: 'none', float: 'right !important', bottom: '6px !important' }}
                            onClick={() => setShowAuctionUpdate(true)}
                        >{t('Edit_Auction')}
                        </Button>
                    </Grid>
                </React.Fragment>
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        {auctionDetailContext.auctionGetDetail && <TableHead>
                            <StyledTableRow>
                                <TableCell id="auction-title-lable" className={classes.tableCell}>{t('Auction_Title')}</TableCell>
                                <TableCell id="auction-title-value" colSpan={3} className={[[classes.subTableCellValue, classes.darkBorder].join(' '), classes.darkBorder].join(' ')}>{auctionDetailContext.auctionGetDetail.title}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-brief-lable" className={classes.tableCell}>{t('Auction_Brief')}</TableCell>
                                <TableCell id="auction-brief-value" colSpan={3} className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>{auctionDetailContext.auctionGetDetail.description}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-id-lable" className={classes.tableCell}>{t('Auction_Id')}</TableCell>
                                <TableCell id="auction-id-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>{auctionDetailContext.auctionGetDetail.auctionId}</TableCell>
                                <TableCell id="auction-reference-lable" className={classes.subTableCellHeader}>{t('Auction_Reference_Number')}</TableCell>
                                <TableCell id="auction-reference-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {(auctionDetailContext.auctionGetDetail.referenceNumber != null && auctionDetailContext.auctionGetDetail.referenceNumber.trim() != '') ?
                                        auctionDetailContext.auctionGetDetail.referenceNumber : ' -'}
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-type-lable" className={classes.tableCell}>{t('Auction_Type')}</TableCell>
                                <TableCell id="auction-type-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {enumListObject.AuctionType[auctionDetailContext.auctionGetDetail.auctionType].uiCode}
                                </TableCell>
                                <TableCell id="auction-method-lable" className={classes.subTableCellHeader}>{t('Auction_Method')}</TableCell>
                                <TableCell id="auction-method-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {enumListObject.AuctionMethod[auctionDetailContext.auctionGetDetail.auctionMethod].uiCode}
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-variant-lable" className={classes.tableCell}>{t('Auction_Variant')}</TableCell>
                                <TableCell id="auction-variant-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {enumListObject.AuctionVariant[auctionDetailContext.auctionGetDetail.auctionVariant].uiCode}
                                </TableCell>
                                <TableCell id="auction-base-currency-lable" className={classes.subTableCellHeader}>{t('Base_Currency')}</TableCell>
                                <TableCell id="auction-base-currency-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {enumListObject.Currency[auctionDetailContext.auctionGetDetail.baseCurrency].uiCode}
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-bidding-access-lable" className={classes.tableCell}>{t('Bidding_Access')}</TableCell>
                                <TableCell id="auction-bidding-access-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {enumListObject.BidderService[auctionDetailContext.auctionGetDetail.biddingAccess].uiCode}
                                </TableCell>
                                <TableCell id="auction-manner-of-sale-lable" className={classes.subTableCellHeader}>{t('Manner_Of_Sale')}</TableCell>
                                <TableCell id="auction-manner-of-sale-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    <span>{auctionDetailContext.auctionGetDetail.mannerOfSale != null ? (enumListObject.MannerOfSale[auctionDetailContext.auctionGetDetail.mannerOfSale].uiCode + '   ') : '-'}</span>
                                    <Button
                                            id='navigate-to-lot-or-item'
                                            variant="standard"
                                            startIcon={<ReadMoreIcon sx={{ fontSize: '12px'}} />}
                                            sx={{ textTransform: 'none', color: 'royalblue', fontSize: '12px', float: 'right', padding: '0px', width: '40%' }}
                                            onClick={navigateToAuctionLotOrItem}
                                        >{t('View_Detail')}
                                    </Button>
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-item-type-lable" className={classes.tableCell}>
                                    {auctionDetailContext.auctionGetDetail.mannerOfSale == MannerOfSale.goingConcern ? t('Going_Concern_Category') : (
                                        auctionDetailContext.auctionGetDetail.mannerOfSale == MannerOfSale.singleLot ? t('Lot_Category') : t('Item_Category'))
                                    }
                                </TableCell>
                                <TableCell id="auction-item-type-value" colSpan={3} className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].itemType?.length > 0 ? <ItemCategoryListOfDescription list={auctionDetailContext.auctionGetDetail.auctionLots[0].itemType} /> : ' -'}
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-reserve-price-lable" className={classes.tableCell}>{t('Reserve_Price')}</TableCell>
                                <TableCell id="auction-reserve-price-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0]?.reservePrice != null ?
                                        CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionDetailContext.auctionGetDetail.auctionLots[0].reservePrice) : ' -'}
                                </TableCell>
                                <TableCell id="auction-increament-amount-lable" className={classes.subTableCellHeader}>{t('Incremental_Amount')}</TableCell>
                                <TableCell id="auction-increament-amount-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0]?.incrementalAmount != null ?
                                        CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionDetailContext.auctionGetDetail.auctionLots[0].incrementalAmount) : ' -'}
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-bid-start-price-lable" className={classes.tableCell}>{t('Bid_Start_Price')}</TableCell>
                                <TableCell id="auction-bid-start-price-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0]?.bidStartPrice != null ?
                                        CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionDetailContext.auctionGetDetail.auctionLots[0].bidStartPrice) : ' -'}
                                </TableCell>
                                <TableCell id="auction-emd-amount-lable" className={classes.subTableCellHeader}>{t('EMD_Amount')}</TableCell>
                                <TableCell id="auction-emd-amount-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0]?.emdAmount != null ?
                                        CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionDetailContext.auctionGetDetail.auctionLots[0].emdAmount) : ' -'}
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-auto-extension-lable" className={classes.tableCell}>{t('Auto_Extension')}</TableCell>
                                <TableCell id="auction-auto-extension-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtension == true ? t('Yes') : t('No')}
                                </TableCell>
                                <TableCell id="auction-incremental-amount-during-extension-lable" className={classes.subTableCellHeader}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtension == true && t('Incremental_Amount_During_Extension')}
                                </TableCell>
                                {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtension == true ?
                                    <TableCell id="auction-incremental-amount-during-extension-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                        {auctionDetailContext.auctionGetDetail.auctionLots[0]?.incrementalAmountDuringExtension != null ?
                                            CurrencyFormatter(auctionDetailContext.auctionGetDetail.baseCurrency, auctionDetailContext.auctionGetDetail.auctionLots[0].incrementalAmountDuringExtension) : ' -'}
                                    </TableCell> : <TableCell id="auction-incremental-amount-during-extension-value" className={classes.subTableCellValue}></TableCell>
                                }
                            </StyledTableRow>
                            {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtension == true && <StyledTableRow>
                                <TableCell id="auction-extend-time-when-bid-received-last-minute-lable" className={classes.tableCell}>{t('Extend_Time_When_Bid_Received_Last_Minute')}</TableCell>
                                <TableCell id="auction-extend-time-when-bid-received-last-minute-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].extendTimeWhenBidReceivedInLastMinute != null ?
                                        auctionDetailContext.auctionGetDetail.auctionLots[0].extendTimeWhenBidReceivedInLastMinute : ' -'}
                                </TableCell>
                                <TableCell id="auction-extend-time-by-lable" className={classes.subTableCellHeader}>{t('Extend_Time_By')}</TableCell>
                                <TableCell id="auction-extend-time-by-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].extendTimeBy != null ?
                                        auctionDetailContext.auctionGetDetail.auctionLots[0].extendTimeBy : ' -'}
                                </TableCell>
                            </StyledTableRow>}
                            {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtension == true && <StyledTableRow>
                                <TableCell id="auction-auto-extension-mode-lable" className={classes.tableCell}>{t('Auto_Extension_Mode')}</TableCell>
                                <TableCell id="auction-auto-extension-mode-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtensionMode != null ?
                                        enumListObject.AutoExtensionMode[auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtensionMode].uiCode : ' -'}
                                </TableCell>
                                <TableCell id="auction-no-of-extension-lable" className={classes.subTableCellHeader}>
                                    {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtensionMode == AutoExtensionMode.limitedExtension && t('No_Of_Extensions')}
                                </TableCell>
                                {auctionDetailContext.auctionGetDetail.auctionLots[0].autoExtensionMode == AutoExtensionMode.limitedExtension ?
                                    <TableCell id="auction-no-of-extension-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                        {auctionDetailContext.auctionGetDetail.auctionLots[0].noOfExtension != null ?
                                            auctionDetailContext.auctionGetDetail.auctionLots[0].noOfExtension : ' -'}
                                    </TableCell> : <TableCell id="auction-no-of-extension-value" className={classes.subTableCellValue}></TableCell>
                                }
                            </StyledTableRow>}
                            <StyledTableRow>
                                <TableCell id="auction-start-date-lable" className={classes.tableCell}>{t('Auction_Start_Date_Time')}</TableCell>
                                <TableCell id="auction-start-date-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    <Stack spacing={2} direction='row'>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                            <span>{format(new Date(auctionDetailContext.auctionGetDetail.startTime), ' dd MMM yyyy')}</span>
                                        </Stack> 
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            <ScheduleRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                            <span>{format(new Date(auctionDetailContext.auctionGetDetail.startTime), ' hh:mm a')}</span>
                                            <span style={{ color: 'slategray'}}>({dateTimeZoneValue})</span>
                                        </Stack>
                                    </Stack> 
                                </TableCell>
                                <TableCell id="auction-end-date-lable" className={classes.subTableCellHeader}>{t('Auction_End_Date_Time')}</TableCell>
                                <TableCell id="auction-end-date-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    <Stack spacing={2} direction='row'>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                            <span>{format(new Date(auctionDetailContext.auctionGetDetail.endTime), ' dd MMM yyyy')}</span>
                                        </Stack>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            <ScheduleRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                            <span>{format(new Date(auctionDetailContext.auctionGetDetail.endTime), ' hh:mm a')}</span>
                                            <span style={{ color: 'slategray'}}>({dateTimeZoneValue})</span>
                                        </Stack>
                                    </Stack>
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-last-date-for-kyc-lable" className={classes.tableCell}>{t('Last_Date_For_KYC_Submission')}</TableCell>
                                <TableCell id="auction-last-date-for-kyc-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    <Stack spacing={2} direction='row'>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            { auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForKyc ?
                                                <React.Fragment>
                                                    <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                                    <span>{format(new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForKyc), ' dd MMM yyyy')}</span>
                                                </React.Fragment>
                                                : ' -'
                                            }
                                        </Stack>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            { auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForKyc &&
                                                <React.Fragment>
                                                    <ScheduleRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                                    <span>{format(new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForKyc), ' hh:mm a')}</span>
                                                    <span style={{ color: 'slategray'}}>({dateTimeZoneValue})</span>
                                                </React.Fragment>
                                            }
                                        </Stack>
                                    </Stack>
                                </TableCell>
                                <TableCell id="auction-emd-date-lable" className={classes.subTableCellHeader}>{t('Last_Date_For_Emd_Amount')}</TableCell>
                                <TableCell id="auction-emd-date-value" className={[classes.subTableCellValue, classes.lightBorder].join(' ')}>
                                    <Stack spacing={2} direction='row'>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            { auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForEmd ?
                                                <React.Fragment>
                                                    <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                                    <span>{format(new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForEmd), ' dd MMM yyyy')}</span>
                                                </React.Fragment>
                                                : ' -'
                                            }
                                        </Stack>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            { auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForEmd &&
                                                <React.Fragment>
                                                    <ScheduleRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                                    <span>{format(new Date(auctionDetailContext.auctionGetDetail.auctionLots[0].lastDateForEmd), ' hh:mm a')}</span>
                                                    <span style={{ color: 'slategray'}}>({dateTimeZoneValue})</span>
                                                </React.Fragment>
                                            }
                                        </Stack>
                                    </Stack>
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell id="auction-last-date-to-approve-bidder-lable" className={classes.tableCell}>{t('Last_Date_To_Approve_Bidder')}</TableCell>
                                <TableCell id="auction-last-date-to-approve-bidder-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    <Stack spacing={2} direction='row'>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            { auctionDetailContext.auctionGetDetail.lastDateToFinalizeBidder ?
                                                <React.Fragment>
                                                    <CalendarMonthRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                                    <span>{format(new Date(auctionDetailContext.auctionGetDetail.lastDateToFinalizeBidder), ' dd MMM yyyy')}</span>
                                                </React.Fragment>
                                                : ' -'
                                            }
                                        </Stack>
                                        <Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
                                            { auctionDetailContext.auctionGetDetail.lastDateToFinalizeBidder &&
                                                <React.Fragment>
                                                    <ScheduleRoundedIcon sx={{ fontSize: '20px', color: 'slategray' }} />
                                                    <span>{format(new Date(auctionDetailContext.auctionGetDetail.lastDateToFinalizeBidder), ' hh:mm a')}</span>
                                                    <span style={{ color: 'slategray'}}>({dateTimeZoneValue})</span>
                                                </React.Fragment>
                                            }
                                        </Stack>
                                    </Stack>
                                </TableCell>
                                <TableCell id="auction-number-of-view-lable" className={classes.subTableCellHeader}>{t('Number_Of_View_To_Auction')}</TableCell>
                                <TableCell id="auction-number-of-view-value" className={[classes.subTableCellValue, classes.darkBorder].join(' ')}>
                                    {(auctionDetailContext.auctionGetDetail.viewCount && auctionDetailContext.auctionGetDetail.viewCount >= 100) ? auctionDetailContext.auctionGetDetail.viewCount : ' -'}
                                </TableCell>
                            </StyledTableRow>
                        </TableHead>}
                    </Table>
                    <Table>
                        {auctionDetailContext.auctionGetDetail.auctioneers.length > 0 && <TableHead>
                            <StyledTableRow>
                                <Grid container className={classes.tableFullRow} ref={bottomRef} >
                                    <Grid item xs={12} sx={{ fontSize: '16px !important', paddingBottom: '10px', textAlign: 'center' }}>
                                        <b>{t('Auctioneer_Details')}</b>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span id="auction-auctioneer-name-lable" className={classes.lableHeader}>{t('Name')}: </span>
                                        <span id="auction-auctioneer-name-value">{auctionDetailContext.auctionGetDetail.auctioneers.length > 0 ? auctionDetailContext.auctionGetDetail.auctioneers[0].name : "-"}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span id="auction-auctioneer-email-lable" className={classes.lableHeader}>{t('Email')}: </span>
                                        <span id="auction-auctioneer-email-value">{auctionDetailContext.auctionGetDetail.auctioneers[0].email.length > 0 ? auctionDetailContext.auctionGetDetail.auctioneers[0].email[0].email : "-"}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span id="auction-auctioneer-phone-lable" className={classes.lableHeader}>{t('Phone_Number')}: </span>
                                        <span id="auction-auctioneer-phone-value">{auctionDetailContext.auctionGetDetail.auctioneers[0].phoneNumber.length > 0 ? auctionDetailContext.auctionGetDetail.auctioneers[0].phoneNumber[0].phoneNumber : "-"}</span>
                                    </Grid>
                                </Grid>
                            </StyledTableRow>
                        </TableHead>}
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        <Tooltip title={t('Scroll_Down')}>
            <Fab id="scroll-down" color='warning'  className={classes.moveToBottom}
                onClick={ () => moveToBottomOfPage()} >
                <KeyboardDoubleArrowDownRoundedIcon />
            </Fab>
        </Tooltip>
        {showAuctionUpdate && <AuctionUpdate show={showAuctionUpdate} onClose={setShowAuctionUpdate} onSuccess={showSuccessMsg} />}
        {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
    </Box>);
}