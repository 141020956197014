import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { IoAlertCircle } from 'react-icons/io5'

const DelayCard = styled(Card)(({theme}) => ({
    width: 'fit-content',
    position: 'absolute',
    zIndex: '1000',
    right: '0px',
    boxShadow: '0px 1.7px 2.2px rgba(0, 0, 0, 0.02), 0px 4px 5.3px rgba(0, 0, 0, 0.028), 0px 7.5px 10px rgba(0, 0, 0, 0.035), 0px 13.4px 17.9px rgba(0, 0, 0, 0.042), 0px 25.1px 33.4px rgba(0, 0, 0, 0.05), 0px 60px 80px rgba(0, 0, 0, 0.07) !important',
    margin: '10px !important',
    borderTop: `5px solid ${theme.palette.warning.main} !important`
}))

const TimerDelayAlert = (props) => {
  return (
    <div>
        <DelayCard>
            <CardContent>
                <Stack justifyContent='center' alignItems='center' spacing={2}>
                      <IoAlertCircle style={{ fontSize: '3rem', color: '#ed7b1d' }}/>
                      <Typography variant='h5'>Warning</Typography>
                      <Typography variant='subtitle1' color='textSecondary'>Your system Time is {props.systemTimerDelay > 0 ? 'future of' : 'delay for'}&nbsp;{props.systemTimerDelay} ms</Typography>
                      <Button color='warning' variant='contained' onClick={() => props.setSystemTimerDelay(0)}>
                          Ok, Got it
                      </Button>
                </Stack>
            </CardContent>
        </DelayCard>
    </div>
  )
}

export default TimerDelayAlert
