export const CountryPincodeRegex = {
    //India
    IN:
    {
        
        pattern: { value: /^[1-9]{1}[0-9]{5}$/, message: 'Invalid Pin Code' }
    },
    //United States
    US: {
        pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: 'Invalid Pin Code' }
    },
    //Canada
    CA: {
        pattern: { value: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/, message: 'Invalid Pin Code' }
    },
    //Bangladesh
    BA: {
        pattern: { value: /\d{4}/, message: 'Invalid Pin Code' }
    },
    //Russia
    RU: {
        pattern: { value: /\d{6}/, message: 'Invalid Pin Code' }
    },
    //Taiwan
    TW: {
        pattern: { value: /\d{3}(\d{2})?/, message: 'Invalid Pin Code' }
    }
}