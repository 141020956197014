import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { UserContext } from '../context/UserContext'

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  let username = name.trim();
  let subs = username.split(" ");
  return {
    sx: {
      bgcolor: stringToColor(username),
    },
    children: subs.length === 1 ? `${username.split(' ')[0][0]}` : `${username.split(' ')[0][0]}${username.split(' ')[1][0]}`,
  };
}

export default function AvatarName(props) {
  const context = React.useContext(UserContext).rootContext;
  return (
    <Stack direction="row" spacing={2} justifyContent='center' alignItems='center'>
      {context?.userSession?.session?.name && context?.userSession?.session?.name.length != 0 &&
        <Avatar className='font-color' style={props ? { width: props.width, height: props.height, fontSize: props.fontSize } : {}}
          {...stringAvatar(context?.userSession?.session?.name)} />}
    </Stack>
  );
}
