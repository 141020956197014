import React from 'react';
import { CurrencyFormat } from '../common/StaticVariableDeclaration';

export const CurrencyFormatter = (currency, value) => {
    let formatter = new Intl.NumberFormat(CurrencyFormat[currency].locale, {style: 'currency', currency: CurrencyFormat[currency].name});
    return formatter.format(value);
}

export const useServerCurrentDateTime = () => {
    let currentDateTime = sessionStorage.getItem('eAuctionServerTimeType') == 'true' ?
        ( new Date().getTime() + Number(sessionStorage.getItem('eAuctionDiffInServerTime')) )
        : ( new Date().getTime() - Number(sessionStorage.getItem('eAuctionDiffInServerTime')) );
    return new Date(currentDateTime);
}