import { useLazyQuery, useMutation } from '@apollo/client';
import { Check, Clear, Edit } from '@mui/icons-material';
import {
    Fab, Grid, TextField, Tooltip
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import {
    AuctionFieldSet,
    AuctionLengend
} from '../../authentication/AuthStyle';
import AuctionLoader from '../../common/auction-loader/AuctionLoader';
import AuctionSnackBar from '../../common/AuctionSnackBar';
import GraphQLErrors from '../../common/GraphQLErrors';
import ListIdentification from '../../common/identification/ListIdentification';
import { PartyType } from '../../common/StaticVariableDeclaration';
import { Validation } from '../../common/Validation';
import { PublicAxiosAuctionInterceptor } from '../../config/AxiosAuctionInterceptor';
import ListEmail from '../../contact/email/ListEmail';
import ListPhoneNumber from '../../contact/phone_number/ListPhoneNumber';
import { UPDATE_SELLER_DETAILS } from '../../GraphQL/Mutation';
import { GET_SELLER_DETAILS } from '../../GraphQL/Queries';
import Address from '../../profile/address/Address';
import { AddressFieldsForCommon } from '../../profile/address/address-common/RequiredFieldsAddress';
import { UserContext } from '../../common/context/UserContext';
import { useServerCurrentDateTime } from '../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: theme.boxShadow
    },
    topFab: {
        width: '34px !important',
        height: '34px !important',
        background: `${theme.palette.background.paper} !important`,
    },
    checkFab: {
        border: `1px solid ${theme.palette.success.main} !important`
    },
    closeFab: {
        border: `1px solid ${theme.palette.error.main} !important`
    },
}))

const AuctionSellerUpdateDetails = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false);
    const [sellerDetails, setSellerDetails] = useState('');
    const userContext = useContext(UserContext).rootContext;
    const [loading, setLoading] = useState(false);
    const serverCurrentTime = useServerCurrentDateTime();

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const { register, getValues, trigger, setValue, control, formState: { errors, isValid }, clearErrors} = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        defaultValues: {
            dobDoi: null,
        }
    })

    const [getSellerDetails, {loading: fetchingSellerDetails, error: errorOnFetchingSellerDetails}] =
            useLazyQuery(GET_SELLER_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getSeller != null) {
                setValue('name', data.getSeller.name, { shouldValidate: true });
                setValue('dobDoi', data.getSeller.dobDoi, { shouldValidate: true });
                setValue('individual', data.getSeller.individual);
                setSellerDetails(data.getSeller);
            }
        }
    })

    const [updateSellerDetails, {loading: updatingSellerDetails, error: errorOnUpdatingSellerDetails}] =
            useMutation(UPDATE_SELLER_DETAILS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if(data.updateSeller != null) {
                setValue('name', data.updateSeller.name);
                setValue('dobDoi', data.updateSeller.dobDoi);
                setIsEdit(false)
                onSuccess({show: true, message: t('Seller_Updated_Successfully'), severity: 'success'});
            }
        },
        refetchQueries: [GET_SELLER_DETAILS]
    })

    const handleDateChange = (newValue) => {
        if(newValue != 'Invalid Date' && newValue != null) {
            setValue('dobDoi', newValue);
        }
    }

    const enableEdit = () => {
        setIsEdit(true);
    }

    const handleCancel = () => {
        setIsEdit(false);
        setValue('name', sellerDetails.name);
        setValue('dobDoi', sellerDetails.dobDoi);
        trigger();
        clearErrors('name')
    }

    const handleUpdate = () => {
        updateSellerDetails({
            variables: {
                auctionId: props.auctionId,
                sellerInput: {
                    name: getValues('name'),
                    dobDoi: getValues('dobDoi'),
                    individual: sellerDetails.individual,
                    personId: props.selectedUserId,
                    phoneNumber: [],
                    email: [],
                    identification: []
                }
            }
        })
    }

    const onSuccess = (successMessage) => {
        setMessage({
            show: successMessage.show,
            message: successMessage.message,
            severity: successMessage.severity
        })
        if (successMessage.severity != 'error') {
            setTimeout(() => {
                resetMessage();
            }, 1500);
        }
    }

    const resetMessage = () => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
    }

    useEffect(() => {
        if (userContext.userSession.isAuthenticated) {
            if(props.selectedUserId) {
                getSellerDetails({
                    variables: {
                        auctionId: props.auctionId,
                        userId: props.selectedUserId
                    }
                })
            }
        } else {
            setLoading(true);
            PublicAxiosAuctionInterceptor.get(`rest/seller/${props.auctionId}/${props.selectedUserId}`).then((response) => {
                if (response != null) {
                    setValue('name', response.name, { shouldValidate: true });
                    setValue('dobDoi', response.dobDoi, { shouldValidate: true });
                    setValue('individual', response.individual);
                    setSellerDetails(response);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                setMessage({show: false, message: '', severity: ''})
                setMessage({show: true, message: error.message, severity: 'error'})
            });
        }
    }, [])

    return (
        <div>
            <Grid>
                <AuctionFieldSet>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{...Validation.name, ...Validation.required}}
                                render={({ field }) => (
                                    <TextField id="name" label={t('Name')}
                                        fullWidth {...field}
                                        required={isEdit}
                                        type="text" variant='standard'
                                        helperText={errors["name"] && errors["name"].message}
                                        InputLabelProps={{ shrink: field.value }}
                                        disabled={!isEdit}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} ms={12} lg={4}>
                            <Controller
                                name="dobDoi"
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            {...field}
                                            label={getValues('individual') === true ? t('Date_of_Birth') : t('Date_of_Incorporation')}
                                            inputFormat="dd/MM/yyyy"
                                            value={getValues('dobDoi')}
                                            onChange={handleDateChange}
                                            maxDate={serverCurrentTime}
                                            disabled={!isEdit}
                                            OpenPickerButtonProps={{id: `dobDoi-picker`}}
                                            renderInput={(params) => <TextField id="dobDoi" variant="standard" fullWidth {...params}
                                            inputProps={{...params.inputProps, readOnly: true}} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </Grid>
                        {!props.isView && <Grid item xs={12} sm={12} ms={12} lg={2}>
                            {!isEdit && (props.sellerLoginUserId ? false : true) &&
                                 <span style={{float: 'right', position: 'relative', bottom: '34px'}}>
                                <Tooltip title={t('Edit')}>
                                    <Fab id="edit" className={`${classes.topFab} ${classes.editFab}`} color='primary'
                                        onClick={enableEdit}>
                                    <Edit color='primary' />
                                    </Fab>
                                </Tooltip>
                            </span>}
                            {isEdit && <span style={{float: 'right', position: 'relative', bottom: '34px'}}>
                                <Tooltip title={t('Save')}>
                                    <Fab id="save" className={`${classes.topFab} ${classes.checkFab}`} style={{position: 'relative', right: '10px'}} color='success'
                                        onClick={handleUpdate} disabled={!isValid || updatingSellerDetails}>
                                        <Check color='success' />
                                    </Fab>
                                </Tooltip>
                                <Tooltip title={t('Cancel')}>
                                <Fab id="cancel" className={`${classes.topFab} ${classes.closeFab}`} color='error' onClick={handleCancel}>
                                    <Clear color='error' />
                                </Fab>
                                </Tooltip>
                            </span>}
                        </Grid>}
                    </Grid>
                </AuctionFieldSet>
                <br/>
                <Grid conatiner spacing={1} style={{display: 'flex'}}>
                    <Grid item xs={4} lg={4} style={{width: '100%'}}>
                        <AuctionFieldSet style={{height: '100%'}}>
                            <AuctionLengend align="left"><strong>{t('Identification_Pan_Cin')}</strong></AuctionLengend>
                            <div style={{marginTop: '-22px'}}>
                            {sellerDetails.personId !== null && <ListIdentification key={sellerDetails.personId} individual={sellerDetails.individual} personId={sellerDetails.personId}
                                isEdit={!props.isView && (props.sellerLoginUserId ? props.sellerLoginUserId == userContext.userSession.session.userId : true)}
                                identifications={sellerDetails.identification} />}
                            </div>
                        </AuctionFieldSet>
                    </Grid>
                    <Grid item xs={4} lg={4} style={{width: '100%'}}>
                        <AuctionFieldSet style={{height: '100%'}}>
                            <AuctionLengend align="left"><strong>{t('Phone_Number')}</strong></AuctionLengend>
                            <div style={{marginTop: '-22px'}}>
                                {sellerDetails.personId !== null && <ListPhoneNumber key={sellerDetails.personId} personId={sellerDetails.personId}
                                isEdit={!props.isView && (props.sellerLoginUserId ? props.sellerLoginUserId == userContext.userSession.session.userId : true)} 
                                phoneNumbers={sellerDetails.phoneNumber} loginId={props.sellerLoginUserId}/>}
                            </div>
                        </AuctionFieldSet>
                    </Grid>
                    <Grid item xs={4} lg={4} style={{width: '100%'}}>
                        <AuctionFieldSet style={{height: '100%'}}>
                            <AuctionLengend align="left"><strong>{t('Email')}</strong></AuctionLengend>
                            <div style={{marginTop: '-22px'}}>
                                {sellerDetails.personId !== null && <ListEmail key={sellerDetails.personId} personId={sellerDetails.personId} emails={sellerDetails.email} 
                                isEdit={!props.isView && (props.sellerLoginUserId ? props.sellerLoginUserId == userContext.userSession.session.userId : true)}
                                loginId={props.sellerLoginUserId}/>}
                            </div>
                        </AuctionFieldSet>
                    </Grid>
                </Grid>
                <br/>
                <Grid container>
                    <Grid istem lg={12}>
                    <AuctionFieldSet>
                    <AuctionLengend align="left"><strong>{t('Contact_Details')}</strong></AuctionLengend>
                            {sellerDetails.personId != null && <Address hasPermission={!props.isView} requiredShowFields={AddressFieldsForCommon} isLoginUser={false}
                        // hasPermission={!props.isView && ((sellerDetails.loginId !== null && sellerDetails.loginId !== undefined) ? false : true)}
                        sourceId={sellerDetails.personId} address={sellerDetails.address} atlestOneMandatory={true}/>}
                </AuctionFieldSet>
                    </Grid>
                </Grid>
            </Grid>
            {(fetchingSellerDetails || updatingSellerDetails || loading) && <AuctionLoader show={(fetchingSellerDetails || updatingSellerDetails || loading)}
            invisible={false} />}
            {(errorOnFetchingSellerDetails || errorOnUpdatingSellerDetails) && <GraphQLErrors error={(errorOnFetchingSellerDetails || errorOnUpdatingSellerDetails)} show={false} />}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} reset={resetMessage}></AuctionSnackBar> }
        </div>
    )
}

export default AuctionSellerUpdateDetails;