import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import Joyride, { STATUS, ACTIONS, LIFECYCLE } from 'react-joyride';

const CustomizedTourComponent = ({tour, setTour }) => {

    const [stepConfig, setStepConfig] = useState([]);

    useEffect(() => {
        setStepConfig([]);
        if(tour.steps.length > 0) {
            tour.steps.forEach(element => {
                setStepConfig(prevState => [
                    ...prevState,
                    {
                        content: (
                            <Grid container>
                                <Grid item xs={12} sx={{ fontWeight: 600, marginBottom: '10px !important' }}>{element.header}</Grid>
                                <Grid item xs={12} sx={{ textAlign: 'left'}}>{element.body}</Grid>
                            </Grid>
                            ),
                        target: element.target,
                        disableBeacon: true
                    }
                    
                ])
            });
        }
    }, [tour.steps])

    // function scrollIntoView(element){
    //     element.scrollIntoView({
    //       behavior: "smooth",
    //       block: "center"
    //     });
    // }

    const handleJoyrideCallback = (data) => {
        console.log('handleJoyrideCallback: ***', data)
        const {action, index, type, status, step, lifecycle} = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.CANCELLED];
        if (action == ACTIONS.CLOSE || finishedStatuses.includes(status)) {
            setTour((prevState) => ({
                run: false,
                steps: []
            }))
        } else if(step && lifecycle === LIFECYCLE.INIT) {
            document.querySelector(step.target).scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
            // waitForElement(step.target).then(scrollIntoView);
        }
    };

    return (
        <React.Fragment>
            <Joyride
                callback={handleJoyrideCallback}
                steps={stepConfig}
                continuous
                showProgress
                scrollToFirstStep
                disableOverlayClose={true}
                disableScrolling={true}
                showSkipButton
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                run={tour.run}
            />
        </React.Fragment>
    )

}

export default CustomizedTourComponent;