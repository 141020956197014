const SearchCriteria = {
    email: { title: 'Email', placeholder: 'Search Email', searchTypeValue: 'email', type: 'email' },
    name: { title: 'Name', placeholder: 'Search Name', searchTypeValue: 'name', type: 'name' },
    phoneNumber: { title: 'Cell_Phone_Number', placeholder: 'Search Phone Number', searchTypeValue: 'phone_number', type: 'phone_number' },
    // identification : {title: 'Identification Number', placeholder: 'Search', searchTypeValue:'identification_Number'},
}

const IndividualIdentificationType = {
    pan: { title: 'PAN', placeholder: 'Search PAN', searchTypeValue: 'identification', type: 'pan' },
    passport: { title: 'Passport', placeholder: 'Search Passport', searchTypeValue: 'identification', type: 'Passport' }
}

const CompanyIdentification = {
    cin: { title: 'CIN', placeholder: 'Search CIN', searchTypeValue: 'identification', type: 'cin' },
    pan: { title: 'PAN', placeholder: 'Search PAN', searchTypeValue: 'identification', type: 'pan' },
}

const PartyType = {
    individual: "individual",
    company: "company",
    other: "other"
}

const AuctionStatus = {
    open: "open",
    scheduled: "scheduled",
    setup: "setup",
    closed: "closed",
    cancelled: "cancelled",
    ready: "ready",
    all: "all",
    favourite: "favourite",
    publish_request:'publish_request'
}

const BidderStatus = {
    all: "all",
    potential: "potential",
    approve: "approve",
    reject: "reject"
}

const Roles = {
    auctioneer: "auctioneer",
    valuer: "valuer",
    requestedBidder: "requested_bidder",
    bidder: "bidder",
    viewer: "viewer",
    coWorker: "co_worker",
    support: "support",
    bidderAuthorizedRepresentative: "bidder_auth_rep",
    systemAdmin: "system_admin",
    rejectedBidder: "rejected_bidder",
    enkSupport: "ROLE_SUPPORT",
    enkAdmin: "ROLE_ADMIN"
}

const InformationDocumentTypes = [
    { DocumentType: "Process Document" },
    { DocumentType: "Sale Notice" }
]

const BidderDocumentTypes = [
    { DocumentType: "Bid Application Form" },
    { DocumentType: "Declaration and Undertaking by Bidder under sec 29A of IBC" },
    { DocumentType: "Affidavit by Bidder" },
    { DocumentType: "Board Resolutions" },
    { DocumentType: "Confidentiality Undertaking" },
    { DocumentType: "Submission of EMD" },
    { DocumentType: "Others" }
]

const FileTypes = [
    { fileTypes: "PDF" },
    { fileTypes: "WORD" },
    { fileTypes: "EXCEL" },
    { fileTypes: "JPEG" },
    { fileTypes: "PNG" },
    { fileTypes: "PPT" },
]

const AutoExtensionMode = {
    limitedExtension: "limited_extension",
    unlimitedExtension: "unlimited_extension"
}

const MannerOfSale = {
    goingConcern: "going_concern",
    lot: "lot",
    item: "item",
    singleLot: "single_lot",
    multiLot: "multi_lot",
    singleItem: "single_item",
    multiItem: "multi_item"
}

const IdentificationTypes = {
    pan: 'pan',
    cin: 'cin',
    aadhar: 'aadhar',
    gstin: 'gstin',
    other: 'other'
}

const BiddingAccess = {
    open: 'public',
    limited: 'limited'
}

const AuctionType = {
    dutch: "dutch",
    english: "english",
    firstBid: "first_bid",
    vickrey: "vickrey"
}

const AuctionMethod = {
    forward: "forward",
    reverse: "reverse",
}

const AuctionVariant = {
    standard: "standard",
}

const BidderService = {
    limited: "limited",
    open: "open"
}

const Currency = {
    inr: "inr",
    usd: "usd"
}

const AccountType = {
    other: "not_specified_or_other"
}

const FilePath = {
    auctionInfoDocumentsPath: "/auction/auction info documents",
    requestDocumentFromBidderPath: "/auction/documents required from bidder",
    uploadingDocumentsFromBidder: "/documents required from bidder"
}

const AddressType = {
    primaryContact: "Primary Contact",
    registeredOfficeAddress: "Registered Office Address",
    billing: "Billing",
    shipping: "Shipping",

}

const ApplicationId = {
    eAuction: 2,
    auditTrial: 'auditTrial'
}

const IdentificationTypeUiCodes = {
    pan: 'PAN',
    cin: 'CIN',
    aadhar: 'AADHAR',
    gstin: 'GSTIN',
    other: 'OTHER'
}

const AuctionReportTypes = {
    auctionHall: 'auction-hall',
    biddingHistory: 'bid-history',
    h1Report: 'h1',
    auctionSummary: 'summary'
}

const BidStatus = {
    bidConfirmed: 'bid_confirmed',
    bidNotConfirmed: 'bid_not_confirmed',
    bidRejected: 'bid_rejected',
}

const ApplicationClientId = {
    eAuction: 'Thirumal'
}

const PartyCodeCd = [
    {dateBaseCode: true, uiCode: 'Individual'},
    {dateBaseCode: false, uiCode: 'Institution'}
]

const CurrencyFormat = {
    inr: {name: "INR", locale: 'en-IN', wordingInteger: 'Rupee', wordingDecimal: 'Paisa'},
    usd: {name: "USD", locale: 'en-US', wordingInteger: 'Doller',  wordingDecimal: ''}
}

const AppHostDomain = {
    enkindle: 'enkindle',
    nbid: 'nbid'
}

const AuctionTermsAndConditions = {
    NESL: "<ol><li><span style=\"color: inherit;\">I declare that the I am eligible to participate in this e-auction as per the terms and conditions laid down by the auctioneer and as per the provisions of the Insolvency and Bankruptcy Code,2016.</span></li><li><span style=\"color: inherit;\">I have paid the EMD and I am fully aware about the payment terms mentioned for the successful bidder.</span></li><li><span style=\"color: inherit;\">NeSL shall not be liable for any loss incurred by the Company or any other party on account of someone else using the User’s account or password.</span></li><li><span style=\"color: inherit;\">NeSL shall not be&nbsp;liable for damages which are direct, indirect or any&nbsp;other loss sustained by the bidder or any other person using the platform.</span></li><li><span style=\"color: inherit;\">I have read the process memorandum associated with this auction and I am fully aware that the Liquidator, IRP or RP's decision shall be final and binding in case of any dispute.</span></li><li>I agree to all the terms&nbsp;and conditions of the Auction Platform.</li></ol><p><br></p>"
}

export {
    SearchCriteria, IndividualIdentificationType, CompanyIdentification, PartyType, AuctionStatus, Roles,
    InformationDocumentTypes, BidderDocumentTypes, BidderStatus, FileTypes, AutoExtensionMode, MannerOfSale,
    IdentificationTypes, BiddingAccess, AuctionType, AuctionMethod, AuctionVariant, BidderService, Currency, 
    FilePath, ApplicationId, AddressType, AccountType, IdentificationTypeUiCodes, AuctionReportTypes, 
    BidStatus, ApplicationClientId, PartyCodeCd, CurrencyFormat, AppHostDomain, AuctionTermsAndConditions
}
