import React, {
    useState,
    useContext
}                                               from 'react';
import { useHistory }                           from 'react-router-dom';
import { useTranslation }                       from 'react-i18next';
import { 
    Card, 
    Grid, 
    Typography,
    TextField,
    Tooltip,
    IconButton,
    InputAdornment,
    Button,
    CircularProgress,
    Link 
}                                               from '@mui/material';
import InputMask                                from 'react-input-mask';
import { Loop }                                 from '@mui/icons-material';
import { ContentBox, IMG, JustifyBox, RootDiv } from '../AuthStyle';
import { PublicAxiosAuctionInterceptor }        from '../../config/AxiosAuctionInterceptor';
import { UserContext }                          from '../../common/context/UserContext';
import AuctionReCaptcha                         from '../../common/AuctionReCaptcha';
import AuctionSnackBar                          from '../../common/AuctionSnackBar';
import AuctionLoader                            from '../../common/auction-loader/AuctionLoader';
import * as Validators                          from '../../common/form-validators.js';
import { Visibility, VisibilityOff }            from '@mui/icons-material';
import PoweredBy                                from '../PoweredBy';
import TermsAndConditions from '../TermsAndCondition';
import { AppHostDomain } from '../../common/StaticVariableDeclaration';
import NbidTermsAndConditions from '../NbidTermsAndConditions';

const MFAVerification = ({username, onCancel, getUser, isMFAEnabled}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const context = useContext(UserContext);
    const [errors, setErrors] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    let history = useHistory();
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [userDetails, setUserDetails] = useState({
        password: '',
        otp: ''
    })
    const [tcData, setTcData] = useState({
        show: false,
        acceptTerms: false
    })

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const validate = {
        password: password => Validators.requiredValidation('Password', password),
        otp: otp => isMFAEnabled ? Validators.requiredValidation(t('Two_Step_Verification_Code'), otp) : null
    }

    const onInputChange = (fieldName, event) => {
        setUserDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                signIn()
            }
        })
    }

    const onAcceptTc = (isAccept) => {
        if(isAccept) {
            history.push({
                pathname: "/auto-account-password-change",
                state: { username: username }
            })
        } else {
            setTcData({
                show: false,
                acceptTerms: false
            })
            setMessage({ showMsg: false, message: null, severity: null });
            setMessage({ showMsg: true, message: t('You_Should_Accept_Terms_Conditions'), severity: 'error' });
        }
    }

    const signIn = () => {
        let code = userDetails.otp.replaceAll(' ', '')
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: null });
        let payload = {
            userName: username,
            password: userDetails.password,
            otp: code
        }
        let userInfo = null;
        if (captcha !== '') {
            PublicAxiosAuctionInterceptor.post(`login?recaptcha=${captcha}`, payload).then(response => {
                setLoading(false);
                userInfo = decodeToken(response.access_token);
                sessionStorage.setItem('token', response.access_token);
                sessionStorage.setItem('refreshToken', response.refresh_token);
                sessionStorage.setItem('isMfaEnabled', userInfo.isMfaEnabled);
                getUser();
                setLoading(true)
                context.setRootContext(prevState => ({
                    ...prevState,
                    userSession: {
                        isAuthenticated: true, session: {
                            accessToken: response.access_token,
                            refreshToken: response.refresh_token,
                            name: userInfo.firstName,
                            middleName: userInfo.middleName !== undefined && userInfo.middleName !== null ? userInfo.middleName : '',
                            lastName: userInfo.lastName !== undefined && userInfo.lastName !== null ? userInfo.lastName : '',
                            dateOfBirth: userInfo.dateOfBirth !== undefined ? userInfo.dateOfBirth : null,
                            individual: userInfo.individual,
                            email: userInfo.email,
                            phoneNumber: userInfo.phoneNumber,
                            userId: userInfo.sub
                        },
                        roles: {
                            agent: true,
                            auctioneer: true
                        }
                    },
                    loginUserRoles: userInfo.roles,
                    selectedMenu: 'auction'
                    })
                )
            }).catch((error) => {
                refreshCaptcha();
                setTimeout(() => {
                    if (error.message) {
                        if (error.message.includes('Need to reset password') || 
                                error.message.includes('User credentials have expired') || error.message.includes('User account is locked')) {
                            // sendOTPToResetPassword();
                            if(error.message.includes('Need to reset password')) {
                                setTcData({
                                    show: true,
                                    acceptTerms: false
                                })
                            } else {
                                history.push({
                                    pathname: "/auto-account-password-change",
                                    state: { username: username }
                                })
                            }
                        } else {
                            setMessage({showMsg: true, message: error.message, severity: 'error'});
                        }
                    } else {
                        setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
                    }
                    setLoading(false);
                }, 2000);
            })
        } else {
            refreshCaptcha();
            setMessage({ showMsg: false, message: null, severity: null });
            setTimeout(() => {
                setLoading(false);
                setMessage({ showMsg: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
            }, 1000);
        }
    }

    const decodeToken = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const refreshCaptcha = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 1000);
    }

    const forgotPasssword = () => {
        history.push("/forgot-password")
    }

    const validateForm = async () => {
        setErrors(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        Object.entries(userDetails).map(([key]) => {
            if (key === 'password' || key === 'otp') {
                const newError = validate[key](userDetails[key])
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    }
                }
            }
        })
        setErrors(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    return (
        <RootDiv>
            <Card className='card'>
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle &&
                                <JustifyBox p={4} height="100%">
                                    <IMG
                                        src={process.env.PUBLIC_URL + '/verifyotp.svg'}
                                        alt=""
                                    />
                                </JustifyBox>
                            }
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid &&
                                <JustifyBox p={4} height="20%" style={{ alignItems: 'baseline' }}>
                                    <img src={process.env.PUBLIC_URL + './images/nesl-pda.png'} height={50} alt='NeslPDALogo'></img>
                                </JustifyBox>
                            }
                            { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid &&
                                <JustifyBox p={4} height="80%" style={{ alignItems: 'baseline' }}>
                                    <IMG
                                        src={process.env.PUBLIC_URL + '/verifyotp.svg'}
                                        alt=""
                                    />
                                </JustifyBox>
                            }
                        </Grid>
                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <ContentBox>
                                <Grid item xs={12} className="text-center">
                                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + '/images/auction.svg'} height={50} alt='E-AuctionLogo'></img> }
                                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + './images/nbid.png'} height={45} alt='NbidLogo'></img> }
                                </Grid>
                                <br />
                                <Typography component="h1" variant="h5" gutterBottom className="text-center">
                                    {t('Sign_In')}
                                </Typography>
                                <br/>
                                <TextField id="username" label={t('Username_Email_Cellphone')} style={{ margin: '8px 8px 8px 0px' }}
                                    fullWidth value={username} variant='standard' disabled
                                />
                                <TextField id="password" label={t('Password')} type={showPassword ? "text" : "password"}
                                    style={{ margin: '8px 8px 8px 0px' }} fullWidth value={userDetails.password}
                                    onChange={(event) => onInputChange('password', event)} variant='standard'
                                    helperText={errors !== '' && errors.errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    id='eyepwdvisibility'
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    autoComplete="off" disabled={loading}
                                />
                                <Grid item xs={12} container justifyContent="flex-end">
                                    <Typography variant="body2">
                                        <Link id="forgotlink" onClick={forgotPasssword} className="pointer">{t('Forgot_Password_?')}</Link>
                                    </Typography>
                                </Grid>
                                <br/>
                                {isMFAEnabled && <Grid item xs={12} className="text-center">
                                    <Typography className="text-center">
                                        <InputMask
                                            mask="9 9 9 9 9 9"
                                            value={userDetails.otp}
                                            onChange={(event) => onInputChange('otp', event)}>
                                            {() => <TextField
                                                label={t('Two_Step_Verification_Code')}
                                                id='otp'
                                                margin="normal"
                                                variant="standard"
                                                placeholder="OTP"
                                                autoComplete="off"
                                                helperText={errors !== '' && errors.errors.otp}
                                                inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                                fullWidth
                                                // InputProps={{
                                                //     endAdornment: (
                                                //         <Tooltip title="Resend">
                                                //             <InputAdornment position="end" className="pointer" style={{ minHeight: '1.8rem' }}>
                                                //                 <IconButton aria-label="resend-otp"
                                                //                     onClick={() => {}}>
                                                //                     <Loop style={{ color: 'blue' }} />
                                                //                 </IconButton>
                                                //             </InputAdornment>
                                                //         </Tooltip>
                                                //     )
                                                // }}
                                            />}
                                        </InputMask>
                                    </Typography>
                                </Grid>}
                                <br/>
                                <Grid item xs={12}>
                                    <Button type="submit" id="signin" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth>
                                        {t('Sign_In')}
                                        {loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                                    </Button>
                                    <Button id="cancel" size="medium" variant="outlined" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                            disabled={loading} onClick={onCancel}>
                                        {t('Cancel')}
                                    </Button>
                                </Grid>
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle &&
                                    <Grid item xs={12} container marginTop="10px" justifyContent="center">
                                        <PoweredBy />
                                    </Grid>
                                }
                            </ContentBox>
                        </Grid>
                    </Grid>
                </form>
            </Card> 
            {!isRefresh && <AuctionReCaptcha key="login" captcha={setCaptcha} refresh={setIsRefresh} />}
            { tcData.show && process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && 
                <TermsAndConditions show={tcData.show} acceptTermsAndConditions={onAcceptTc} />
            }
            { tcData.show && process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && 
                <NbidTermsAndConditions show={tcData.show} acceptTermsAndConditions={onAcceptTc} />
            }        
            {message.showMsg && <AuctionSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}>
            </AuctionSnackBar>}
            {(loading) && <AuctionLoader show={loading} />}
        </RootDiv>
    )
}

export default MFAVerification