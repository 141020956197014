export const AddressFieldsForProfile = {
    contactName: {
        required: false,
        show: true
    },
    contactPhone: {
        required: false,
        show: true
    },
    contactEmail: {
        required: false,
        show: false
    },
    address: {
        required: true,
        show: true
    },
}

export const AddressFieldsForCommon = {
    contactName: {
        required: false,
        show: true
    },
    contactPhone: {
        required: false,
        show: true
    },
    contactEmail: {
        required: false,
        show: false
    },
    address: {
        required: true,
        show: true
    },
}

export const OptionalAddressFieldsForCommon = {
    contactName: {
        required: false,
        show: true
    },
    contactPhone: {
        required: false,
        show: true
    },
    contactEmail: {
        required: false,
        show: false
    },
    address: {
        required: false,
        show: true
    },
}