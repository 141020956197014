import React, {
    useState,
    useContext
}                                   from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Grid,
    Button
}                                   from '@mui/material';
import { makeStyles }               from '@mui/styles';
import { useTranslation }           from 'react-i18next'; 
import LotInput                     from './LotInput';
import { useForm }                  from 'react-hook-form';
import { CREATE_LOT }               from '../../GraphQL/Mutation';
import { useMutation }              from '@apollo/client';
import { LIST_LOTS }                from '../../GraphQL/Queries';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import GraphQLErrors                from '../../common/GraphQLErrors';
import { AuctionDetailContext }     from '../../auction/auction-detail/AuctionDetail';

const useStyles = makeStyles((theme) => ({
    nextBtn: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    }
}))

const AddLot = ({show, onClose, auction_id, mannerOfSale, onSuccess}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(show ? show : false);
    const auctionContext = useContext(AuctionDetailContext);

    const { register, control, getValues, setValue, trigger, reset,
            formState: { errors, isValid }} = useForm({
        mode: 'onChange',
        defaultValues: {
            lotNumber: '',
            description: '',
            autoExtension: false,
            autoExtensionMode: '',
            extendTimeWhenBidReceivedInLastMinute: '',
            extendTimeBy: '',
            reservePrice: 0,
            emdAmount: 0,
            lastDateForEmd: null,
            incrementalAmount: 0,
            bidStartPrice: 0,
            incrementalAmountDuringExtension: 0,
            noOfExtension: 0
        }
    });

    const [createLot, {loading: creatingLot, error: errorOnCreatingLot}] = useMutation(CREATE_LOT, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.createAuctionLot != null) {
                handleClose();
                onSuccess({show: true, message: t('Lot_Added_Successfully'), severity: 'success'})
            }
        },
        refetchQueries: [LIST_LOTS]
    })

    const handleClose = () => {
        setOpen(false);
        onClose(false)
    }

    const create = () => {
        createLot({
            variables: {
                auctionLotInput: {
                    lotNumber: getValues('lotNumber'),
                    description: getValues('description'),
                    autoExtension: getValues('autoExtension'),
                    autoExtensionMode: getValues('autoExtensionMode'),
                    extendTimeWhenBidReceivedInLastMinute: getValues('extendTimeWhenBidReceivedInLastMinute'),
                    extendTimeBy: getValues('extendTimeBy'),
                    reservePrice: getValues('reservePrice'),
                    emdAmount: getValues('emdAmount'),
                    lastDateForEmd: getValues('lastDateForEmd'),
                    bidStartPrice: getValues('bidStartPrice'),
                    incrementalAmount: getValues('incrementalAmount'),
                    incrementalAmountDuringExtension: getValues('incrementalAmountDuringExtension'),
                    noOfExtension: getValues('noOfExtension'),
                    startTime: getValues('startTime'),
                    endTime: getValues('endTime'),
                },
                auctionId: auction_id
            }
        })
    }

    return (
        <Dialog open={open} fullWidth maxWidth='lg'>
            <DialogTitle style={{textAlign: 'center', paddingTop: '10px'}}>
                <Typography id="add-lot-header" variant="h6" gutterBottom color='primary' style={{fontWeight: 'bold'}}>
                    {t('Lot_Details')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LotInput register={register} control={control} errors={errors} setValue={setValue} getValues={getValues}
                    trigger={trigger} mannerOfSale={mannerOfSale}
                    auctionStartDate={auctionContext ? new Date(auctionContext.auctionGetDetail.startTime) : null}
                    auctionEndDate={auctionContext ? new Date(auctionContext.auctionGetDetail.endTime) : null}/>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button type="submit" size="small" id="update-lot" variant="contained"
                                className={classes.nextBtn} onClick={create} disabled={!isValid}>
                            {t('Save')}
                        </Button>
                        <Button size="small" id="cancel-update-lot" variant="outlined"
                                className={classes.nextBtn} onClick={handleClose}>
                            {t('Cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            {creatingLot && <AuctionLoader show={creatingLot} invisible={false} />}
            {errorOnCreatingLot && <GraphQLErrors error={errorOnCreatingLot} show={false} />}
        </Dialog>
    )
}

export default AddLot;