import { useMutation } from "@apollo/client";
import CloseIcon from '@mui/icons-material/Close';
import {
  Button, Dialog, Divider, FormControl, FormControlLabel, Grid,
  IconButton, Paper, Radio, RadioGroup, Slide, Stack, Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import AuctionLoader from "../common/auction-loader/AuctionLoader";
import { UserContext } from "../common/context/UserContext";
import GraphQLErrors from "../common/GraphQLErrors";
import PartySearch from "../common/search/PartySearch";
import { PartyType, Roles, SearchCriteria, PartyCodeCd } from "../common/StaticVariableDeclaration";
import { ASSIGN_ROLE } from "../GraphQL/Mutation";
import { LIST_ROLE } from "../GraphQL/Queries";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      marginLeft: theme.spacing(20),
      marginTop: theme.spacing(10),
      height: theme.spacing(50),
    },
  },

  assign: {
    fontSize: "25px",
    textAlign: "center",
    padding: '0.6rem 0rem 0.6rem 0rem',
  },

  btn: {
    boxShadow: `5px 5px 8px ${theme.rolesColor}`,
    color: theme.rolesMailColor,
    background: 'white',
    border: `1.8px solid ${theme.rolesColor}`,
    padding: '0rem 1rem 0rem 0.2rem',
    margin: '1rem 0rem 1rem 0rem',
    borderRadius: '20px',
    marginLeft: '30px !important'
  },
  back: {
    background: theme.backColor,
    color: theme.rolesGreenColor,
    border: `1px solid ${theme.rolesGreenColor}`
  },
  add: {
    float: 'right',
    margin: '0rem 0.7rem 0.7rem 0rem !important'
  },
  closeBtn: {
    color: `${theme.flashButton.color} !important`
  }
}));

export const AssignRole = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [userId, setUserId] = useState("")
  const context = useContext(UserContext).rootContext;
  const [userRole, setUserRole] = useState({ name: '', email: '', role: Roles.auctioneer })
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [assignRoleForUSers, { loading: roleLoading, error: assignroleError }] = useMutation(ASSIGN_ROLE, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data.assignRole !== null) {
        props.setMessage({ showMsg: false, message: '', severity: '' })
        props.close(false)
        props.setMessage({ showMsg: true, message: t("Role_assigned_successfully"), severity: 'success' })
      }
    },
    refetchQueries: [LIST_ROLE]
  })

  const handleChange = (event) => {
    setUserRole(prevState => ({
      ...prevState,
      role: event.target.value
    }));
  };

  const handleClick = () => {
    assignRoleForUSers({
      variables: {
        userId: userId,
        auctionId: props.auctionId,
        role: userRole.role,
        pagination: {
          limit: props.itemsPerPage,
          page: props.page,
          asc: false
        }
      }
    })
  }

  const handleClose = () => {
    props.close(false)
  }

  const userChange = (e) => {
    setUserId(e.id)
    setName(e.name)
    setEmail(e.email)
  }

  return (
    <div id='main-div'>
      <Dialog open={props.open} fullWidth onClose={props.close} id='dialog'>
        <Slide id='slide' direction="right" in={props.slide} mountOnEnter unmountOnExit>
          <Paper id='paper' style={{ width: "100%" }} elevation={12}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' className={classes.assign}>
              <Typography id='assign' style={{ marginLeft: '40%', fontSize: "20px" }}>{t("Assign_Role")}</Typography>
              <IconButton id='cancelbtn' onClick={handleClose} className={classes.closeBtn}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
            <Grid container>
              <Grid item xs={12} sm={12} style={{ margin: '10px' }}>
                <PartySearch id='party' individual={true} defaultValue={SearchCriteria.email}
                  onSelect={(e) => userChange(e)} cognitoUsers={true} />
              </Grid>
              <Paper id='papers' style={{ margin: "10px", padding: "10px", width: '100%' }} >
                {name &&
                  <div style={{ marginBottom: '10px' }} id='name-div'>
                    <Typography id='name'><span style={{ fontWeight: 'bold' }}>{t("Names")}</span>&nbsp;{name}</Typography>
                    <Typography id='email'><span style={{ fontWeight: 'bold' }}>{t("Emails")}<span></span></span>&nbsp;{email}</Typography>
                  </div>
                }
                {name && <Divider />}
                <FormControl id='formControl' component="fieldset" style={{ width: "100%" }}>
                  <RadioGroup aria-label="role" row id='roles' name="role-user" value={userRole.role} onChange={handleChange}>
                    <FormControlLabel value={Roles.auctioneer} className={`${userRole.role === Roles.auctioneer && classes.back} ${classes.btn}`} id='auctioneer' style={{ paddingTop: '2px' }} control={<Radio color="success" style={{ marginTop: '-2px' }} />} label={<span style={{ fontSize: '18px' }}>{t("Auctioneer")}</span>} />
                    <FormControlLabel value={Roles.support} className={`${userRole.role === Roles.support && classes.back} ${classes.btn}`} id='support' style={{ paddingTop: '2px' }} control={<Radio color="success" style={{ marginTop: '-2px' }} />} label={<span style={{ fontSize: '18px' }}>{t("Support")}</span>} />
                    <FormControlLabel value={Roles.viewer} className={`${userRole.role === Roles.viewer && classes.back} ${classes.btn}`} id="viewer" style={{ paddingTop: '2px' }} control={<Radio color="success" style={{ marginTop: '-2px' }} />} label={<span style={{ fontSize: '18px' }}>{t("Observer")}</span>} />
                  </RadioGroup>
                </FormControl>
              </Paper>
              <Grid item xs={12} sm={12}>
                <Button id='AddBtn' className={classes.add} variant="contained" onClick={handleClick} disabled={name ? false : true}>{t("Add")}</Button>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
        {roleLoading && <AuctionLoader show={true} invisible={false} />}
        {assignroleError && <GraphQLErrors error={assignroleError} show={false} />}
      </Dialog>
    </div>
  )
}

export default AssignRole;