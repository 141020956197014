import React, {
    useState,
    useEffect,
    useContext,
    Fragment
} from 'react';
import {
    Grid,
    TextField,
    Box,
    Button,
    MenuItem,
    Chip
} from '@mui/material';
import { Validation } from '../../../common/Validation';
import { useTranslation } from 'react-i18next';
import {
    Controller,
    useWatch
} from 'react-hook-form';
import { DatePickerControl } from '../../../custom-form-control/DatePickerController';
import { SelectControl } from '../../../custom-form-control/SelectControl';
import BankDetailModal from '../../../profile/bank-accounts/bank-details/BankDetailModal';
import {
    LIST_AUCTION_BANK_DETAIL,
    LIST_BANK_DETAIL_BY_USER_ID
} from '../../../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import { UserContext } from '../../../common/context/UserContext';
import { NumberFormatController } from '../../../custom-form-control/NumberFormatController';
import { useServerCurrentDateTime } from '../../../utils/Utils';

const EMDInput = ({ errors, control, register, setValue, getValues, bidder_id, auction_id, seller_id, user_id, isValid, trigger,
    auctionCreationDate, allowUpdateReceivedEMDField, bidder_login_id }) => {
    const { t } = useTranslation();
    const [addBankDetail, setAddBankDetail] = useState(false);
    const [bidderBanks, setBidderBanks] = useState([]);
    const [auctionBanks, setAuctionBanks] = useState([]);
    const enumListOptions = useContext(UserContext).rootContext.enumList;
    const enumListObject = useContext(UserContext).rootContext.enumListObject;
    const modeOfPayment = useWatch({ control, name: 'modeOfPayment' });
    const userContext = useContext(UserContext);
    const serverCurrentTime = useServerCurrentDateTime();

    const [listBankDetail, { loading: listingBankDetails, error: errorOnListingBankDetails, refetch }] =
        useLazyQuery(LIST_BANK_DETAIL_BY_USER_ID, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setBidderBanks([]);
                if (data.listBankDetailForUser != null) {
                    data.listBankDetailForUser.bankDetail.map(bank => {
                        if (new Date(bank.endTime).getTime() > serverCurrentTime.getTime()) {
                            setBidderBanks(prevState => ([
                                ...prevState,
                                bank
                            ]))
                        }
                    })
                    // setBidderBanks(data.listBankDetailForUser.bankDetail ? data.listBankDetailForUser.bankDetail : []);
                }
            }
        })

    const [listAuctionBankDetail, { loading: listingAuctionBankDetails, error: errorOnListingAuctionBankDetails }] =
        useLazyQuery(LIST_AUCTION_BANK_DETAIL, {
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setAuctionBanks([]);
                if (data.bankDetailListInsideAuction != null) {
                    data.bankDetailListInsideAuction.bankDetail.map(bankAccount => {
                        if (new Date(bankAccount.endTime).getTime() > serverCurrentTime.getTime()) {
                            setAuctionBanks(prevState => ([
                                ...prevState,
                                bankAccount
                            ]))
                        }
                        // setAuctionBanks(data.bankDetailListInsideAuction.bankDetail ? data.bankDetailListInsideAuction.bankDetail : []);
                    })
                }
            }
        })

    const cancelAddBank = (event) => {
        setAddBankDetail(false);
    }

    const onAddBankDetail = (event) => {
        refetch();
    }

    useEffect(() => {
        trigger("fromBankId");
    }, [bidderBanks])

    useEffect(() => {
        listBankDetail({
            variables: {
                userId: user_id,
                pagination: {
                    page: 1,
                    limit: 100,
                    asc: false
                },
            }
        });
        listAuctionBankDetail({
            variables: {
                userId: seller_id,
                auctionId: auction_id,
                pagination: {
                    page: 1,
                    limit: 100,
                    asc: false
                },
            }
        });
    }, [])

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <DatePickerControl
                        errors={errors}
                        control={control}
                        name='transactionDate'
                        id="transactionDate"
                        rules={Validation.dateTime}
                        label={t('Transaction_Date')}
                        mandatory={true}
                        minDate={new Date(auctionCreationDate)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl
                        name='modeOfPayment'
                        control={control}
                        rules={Validation.required}
                        errors={errors}
                        label={t('Mode_of_Payment')}
                        getValues={getValues}
                        id="modeOfPayment"
                        mandatory={true}
                    >
                        {enumListOptions.ModeOfPayment.map(item => (
                            <MenuItem value={item.dateBaseCode} key={item.id}
                                disabled={item.dateBaseCode === enumListObject.ModeOfPayment.cash.dateBaseCode ||
                                    item.dateBaseCode === enumListObject.ModeOfPayment.digital_rupee.dateBaseCode}>{item.uiCode}</MenuItem>
                        ))}
                    </SelectControl>
                </Grid>
                <Grid item xs={12}>
                    <NumberFormatController
                        mandatory={true}
                        errors={errors}
                        control={control}
                        name="amount"
                        rules={Validation.minAmount}
                        label={t('Amount')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="transactionRefNo"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="transactionRefNo"
                                label={t('EMD_Transaction_Ref_Label')}
                                type="text"
                                variant="standard"
                                fullWidth
                                helperText={errors?.transactionRefNo?.message}
                                InputLabelProps={{ shrink: field.value }}
                            />
                        )}
                    />
                </Grid>
                {modeOfPayment !== enumListObject.ModeOfPayment.cash.dateBaseCode && <Fragment>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SelectControl
                                name='fromBankId'
                                id="fromBankId"
                                control={control}
                                rules={{
                                    validate: value => (bidder_login_id ? (bidder_login_id === userContext.rootContext.userSession.session.userId || bidderBanks.length > 0) : true) ||
                                        "EMD cannot be added without Bidder Bank Account. Please request the Bidder to add the Bank Account Details in their profile"
                                }}
                                errors={errors}
                                label={t('Transferred_From')}
                                getValues={getValues}
                                mandatory={true}
                            >
                                {bidderBanks.map(bank => (
                                    <MenuItem value={bank.id} key={bank.id}>{`${bank.bankHolderName} - ${bank.bankAccountNumber} (${bank.bankName})`}</MenuItem>
                                ))}
                            </SelectControl>
                            {(bidder_login_id ? bidder_login_id === userContext.rootContext.userSession.session.userId : true) && <Button id='add-bidder-bank' variant='outlined' size='small' style={{ margin: '6px' }}
                                onClick={() => { setAddBankDetail(true) }}>{t('Add')}</Button>}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SelectControl
                                name='toBankId'
                                id="toBankId"
                                control={control}
                                rules={Validation.required}
                                errors={errors}
                                label={t('Transferred_To')}
                                getValues={getValues}
                                mandatory={true}
                            >
                                {auctionBanks.map(bank => (
                                    <MenuItem value={bank.id} key={bank.id}>{`${bank.bankHolderName} - ${bank.bankAccountNumber} (${bank.bankName})`}</MenuItem>
                                ))}
                            </SelectControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <NumberFormatController
                            mandatory={false}
                            errors={errors}
                            control={control}
                            name="received"
                            rules={Validation.amount}
                            label={t('Received_Amount')}
                            disabled={!allowUpdateReceivedEMDField}
                        />
                    </Grid>
                </Fragment>}
            </Grid>
            {addBankDetail && <BankDetailModal openPopup={addBankDetail} setCancel={cancelAddBank}
                isNew={true} onSuccess={onAddBankDetail} userId={user_id} />}
        </div>
    )
}

export default EMDInput