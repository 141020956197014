import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from "react-hook-form";
import AccessTimeIcon from '@mui/icons-material/AccessTime';


export const DatePickerControl = (props) => {
    const { errors, control, name, rules, label, mandatory, minDate, maxDate, minDateErrorMsg, maxDateErrorMsg } = props;
    const [validationError, setValidationError] = useState(null);
    return (
        <Controller
            errors={errors}
            control={control}
            name={name}
            rules={rules}
            defaultValue={null}
            render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, isDirty, error },
                formState }) => {
                return <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        minDate={minDate}
                        maxDate={maxDate}
                        closeOnSelect={true}
                        ampmInClock
                        // disableIgnoringDatePartForTimeValidation
                        disablePast
                        // showToolbar
                        inputFormat="dd/MM/yyyy"
                        renderInput={(props) =>
                            <TextField
                                id={name}
                                required={mandatory}
                                fullWidth
                                variant='standard'
                                helperText={error && error.message || (validationError == 'minDate' && minDateErrorMsg) || (validationError == 'maxDate' && maxDateErrorMsg)}
                                {...props}
                            />
                        }
                        label={label}
                        value={value}
                        onChange={onChange}
                        onError={setValidationError}
                    />
                </LocalizationProvider>
            }}
        />
    );
}