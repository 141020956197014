import React, { 
    useState, 
    useEffect,
    Fragment,
    useContext
}                                   from 'react';
import {
    Grid,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    Button,
    Tooltip,
    IconButton,
    Typography
}                                   from '@mui/material';
import { makeStyles }               from '@mui/styles';
import AddIcon                      from '@mui/icons-material/AddOutlined';
import { useTranslation }           from 'react-i18next'; 
import { useHistory }               from 'react-router-dom';
import { 
    useLazyQuery,
    useMutation
}                                   from '@apollo/client';
import FieldHelperText              from '../../common/FieldHelperText';
import { LIST_BIDDER_AUTH_REP }     from '../../GraphQL/Queries';
import AddBidderAuthRep             from './AddBidderAuthRep';
import AuctionSnackBar              from '../../common/AuctionSnackBar';
import GraphQLErrors                from '../../common/GraphQLErrors';
import AuctionLoader                from '../../common/auction-loader/AuctionLoader';
import EmailIcon                    from '@mui/icons-material/Email';
import PhoneIcon                    from '@mui/icons-material/Phone';
import DeleteIcon                   from '@mui/icons-material/DeleteOutlined';
import EditIcon                     from '@mui/icons-material/EditOutlined';
import AuctionConfirmDialog         from '../../common/AuctionConfirmDialog';
import { 
    DELETE_BIDDER_AUTH_REP,
    SEND_CREDETIALS_TO_BIDDER_AUTH_REPS
}                                   from '../../GraphQL/Mutation';
import UpdateBidderAuthRep          from '../../bidder/bidder-auth-rep/UpdateBidderAuthRep';
import LocationOnIcon               from '@mui/icons-material/LocationOn';
import SendIcon                     from '@mui/icons-material/Send';
import { UserContext }              from '../../common/context/UserContext';
import { AuctionStatus }            from '../../common/StaticVariableDeclaration';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: "rgb(26, 32, 39)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '10px',
        border: "1px solid rgb(238, 238, 238)",
        backgroundImage: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        // margin: "40px auto",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,
            "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableHead: {
        fontWeight: "bold !important"
    },
    button: {
        margin: '4px !important'
    },
    textIconContainer: {
        display: 'flex'
    },
    textIcon: {
        fontSize: '1.2rem !important',
        paddingRight: '3px'
    },
    statusText: {
        fontWeight: 'bold',
        fontSize: '0.7rem'
    }
}))

const BidderAuthRepList = ({auction_id, bidder_id, canAdd, canEdit, canDelete, canSendCredentials}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    let history = useHistory();
    const userContext = useContext(UserContext).rootContext;
    const [bidderAuthReps, setBidderAuthReps] = useState([]);
    const [showAddAuthRep, setShowAddAuthRep] = useState(false);
    const [showUpdateAuthRep, setShowUpdateAuthRep] = useState({
        show: false,
        authRepDetails: ''
    });
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })
    const columns = [
        {name: 'Name', width: '22%', helperText: ''},
        {name: 'Identification', width: '18%', helperText: ''},
        {name: 'Contact', width: '22%', helperText: ''},
        {name: 'Location', width: '25%', helperText: ''}
    ]

    const [listBidderAuthReps, {loading: fetchingBidderAuthReps, error: errorOnFetchingBidderAuthReps, refetch}] = useLazyQuery(LIST_BIDDER_AUTH_REP, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if(data.listAuthorizedRepresentative != null) {
                setBidderAuthReps(data.listAuthorizedRepresentative);
            }
        },
        onError: (error) => {
            if (error.message.includes('Forbidden')) {
                history.push({
                    pathname: "/auction-list",
                    state: {
                        userRole: [],
                        auctionListType: AuctionStatus.open,
                        pagination: {
                            page: 1,
                            pageSize: 10
                        },
                        isMyBid: false
                    }
                })
            }
        }
    })

    const [deleteBidderAuthRep, {loading: deletingBidderAuthRep, error: errorOnDeletingBidderAuthRep}] = useMutation(DELETE_BIDDER_AUTH_REP, {
        errorPolicy: 'all', 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.deleteAuthorizedRepresentative != null) {
                setMessage({
                    show: true,
                    message: t('Auth_Rep_Deleted_Successfully'),
                    severity: 'success'
                })
            }
        },
        refetchQueries: [LIST_BIDDER_AUTH_REP]
    })

    // const [sendCredetials, {loading: sendingCredentials, error: errorOnSendingCredentials}] = useMutation(SEND_CREDETIALS_TO_BIDDER_AUTH_REPS, {
    //     errorPolicy: 'all', 
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         if (data.createBidderAccount !== null && data.createBidderAccount.createdAccount > 0) {
    //             setMessage({
    //                 show: true,
    //                 message: `${t('Total')} ${data.createBidderAccount.createdAccount} ${t('accounts_created')} ${t('and')} ${t('Sent_Credentials_Successfully')}`,
    //                 severity: 'success'
    //             });
    //         }
    //     },
    //     refetchQueries: [LIST_BIDDER_AUTH_REP]
    // })

    const addAuthRep = () => {
        setShowAddAuthRep(true);
    }

    const showSuccessMsg = (msg) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        });
        if (msg) {
            setMessage(msg);
        }
    }

    useEffect(() => {
        listBidderAuthReps({
            variables: {
                auctionId: auction_id,
                bidderId: bidder_id
            }
        })
    }, [auction_id, bidder_id])

    const deleteConfiration = (authRep) => {
        if (authRep.roleId) {
            setSeekConfitmation({
                show: true,
                title: t('Confirmation'),
                message: t('Are_You_Sure_That_You_Want_To_Delete') + " " + authRep.name + "?",
                onAgree: () => deleteAuthRep(authRep),
                onDisAgree: () => resetSeekConfirmation(),
                isCancel: true
            })
        }
    }

    const deleteAuthRep = (authRep) => {
        setMessage({
            show: false,
            message: '',
            severity: ''
        })
        deleteBidderAuthRep({
            variables: {
                roleId: authRep.roleId,
                bidderId: bidder_id,
                auctionId: auction_id
            }
        })
        resetSeekConfirmation();
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const enableUpdateAuthRep = (authRep) => {
        setShowUpdateAuthRep({
            show: true,
            authRepDetails: authRep
        })
    }

    const resetUpdateAuthRep = () => {
        setShowUpdateAuthRep({
            show: false,
            authRepDetails: ''
        })
        refetch();
    }

    // const sendCredentialsToBidderAuthRep = () => {
    //     setMessage({
    //         show: false,
    //         message: '',
    //         severity: ''
    //     });
    //     setSeekConfitmation({
    //         show: true,
    //         title: t('Confirmation'),
    //         message: t('Are_You_Sure_That_You_Want_To_Send_Credentials_To_Bidder_Auth_Reps'),
    //         onAgree: () => {
    //             sendCredetials({
    //                 variables: {
    //                     auctionId: auction_id,
    //                     bidderId: bidder_id
    //                 }
    //             })
    //             resetSeekConfirmation()
    //         },
    //         onDisAgree: () => resetSeekConfirmation(),
    //         isCancel: true
    //     })
    // }

    return (
        <div>
            <Grid container>
                <Grid item xs={12}><Fragment>
                    {canAdd && <Button variant="contained" size="small" startIcon={<AddIcon />} style={{float: 'right'}}
                            onClick={addAuthRep} className={classes.button} id="add-bidder-btn">
                        {t('Add')}
                    </Button>}
                    {/* {canSendCredentials && bidderAuthReps.length > 0 && <Tooltip title={t('Send_Credentials_To_Bidder')}>
                        <Button variant="contained" size="small" startIcon={<SendIcon />} style={{float: 'right'}}
                                onClick={sendCredentialsToBidderAuthRep} className={classes.button} id="send-credentials-btn">
                            {t('Send_Credentials')}
                        </Button>
                    </Tooltip>} */}
                </Fragment>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper} className={classes.paper}>
                        <Table aria-label="bidder auth. rep. table" stickyHeader id="list-of-bidder-auth-rep">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => {
                                        return (
                                                <TableCell key={column.name} className={`${classes.tableCell} ${classes.tableHead}`} style={{width: column.width}}
                                                        id={column.name}>
                                                    {t(column.name)} 
                                                    {column.helperText && <FieldHelperText helperText={t(column.helperText)}/>}
                                                </TableCell>
                                        )
                                    })}
                                    <TableCell id="action-column" className={`${classes.tableCell} ${classes.tableHead}`} align="center">{t("Actions")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bidderAuthReps.length === 0 && 
                                    <TableRow hover>
                                        <TableCell id="no-bidder-reps-row" colSpan={8} className={`${classes.tableCell} ${classes.tableData}`}>{t('No_Record_Found')}</TableCell>
                                    </TableRow>
                                }
                                {bidderAuthReps.length > 0 && bidderAuthReps.map((authRep, index) => {
                                    return <TableRow id={`auth-rep${index}`}>
                                        <TableCell id={`name${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{padding: '6px 6px 6px 0px'}}>{authRep.name}</div>
                                                {authRep.loginId && <div id="registered-user-icon"><img className={classes.textIcon} src={process.env.PUBLIC_URL + './images/registered-user.png'} height={25} alt='Registered User'/></div>}
                                            </div>
                                        </TableCell>
                                        <TableCell id={`identification${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                            {authRep.identification.length > 0 ?
                                                    <div>
                                                        {/* <Tooltip title={t(authRep.identification[0].identificationType) ? t(authRep.identification[0].identificationType) : null} arrow> */}
                                                            {authRep.identification[0].identification}
                                                        {/* </Tooltip> */}
                                                    </div>
                                                :
                                                    <div>-</div>
                                            }
                                        </TableCell>
                                        <TableCell id={`email${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                            <div className={classes.textIconContainer}><EmailIcon className={classes.textIcon}/>
                                                {authRep.email.length > 0 ? authRep.email[0].email : '-'}
                                            </div>
                                            <div className={classes.textIconContainer}><PhoneIcon className={classes.textIcon}/>
                                                {authRep.phoneNumber.length > 0 ? authRep.phoneNumber[0].phoneNumber : '-'}
                                            </div>
                                        </TableCell>
                                        <TableCell id={`address${index}`} className={`${classes.tableCell} ${classes.tableData}`}>
                                            <div className={classes.textIconContainer}><LocationOnIcon className={classes.textIcon}/>
                                                {authRep.address.length > 0 ? authRep.address[0].cityTown : '-'}
                                            </div>
                                        </TableCell>
                                        <TableCell id={`action${index}`} className={`${classes.tableCell} ${classes.tableData}`} style={{textAlign: 'center'}}>
                                            {canEdit && (authRep.loginId ? authRep.loginId == userContext.userSession.session.userId : true) && <Tooltip title={t('Edit')} arrow>
                                                <IconButton id={`bidder-auth-rep-edit-btn${index}`} size="small" color="primary" 
                                                        onClick={() => enableUpdateAuthRep(authRep)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>}
                                            {!(canEdit && (authRep.loginId ? authRep.loginId == userContext.userSession.session.userId : true)) && <Tooltip title={t('View')} arrow>
                                                <IconButton id={`bidder-auth-rep-view-btn${index}`} size="small" color="primary" 
                                                        onClick={() => enableUpdateAuthRep(authRep)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>}
                                            {canDelete && <Tooltip title={t('Delete')} arrow>
                                                <IconButton id={`bidder-auth-rep-delete-btn${index}`} size="small" color="secondary" 
                                                        onClick={() => deleteConfiration(authRep)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {showAddAuthRep && <AddBidderAuthRep show={showAddAuthRep} auction_id={auction_id} 
                bidder_id={bidder_id} onClose={setShowAddAuthRep} onSuccess={showSuccessMsg}/>}
            {showUpdateAuthRep.show && <UpdateBidderAuthRep show={showUpdateAuthRep.show} auction_id={auction_id} 
                bidder_id={bidder_id} onClose={resetUpdateAuthRep} authRep={showUpdateAuthRep.authRepDetails} 
                canEdit={canEdit}/>}
            {message.show && <AuctionSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {(fetchingBidderAuthReps || deletingBidderAuthRep ) && 
                    <AuctionLoader show={fetchingBidderAuthReps || deletingBidderAuthRep} invisible={false} />}
            {(errorOnFetchingBidderAuthReps || errorOnDeletingBidderAuthRep) && 
                    <GraphQLErrors error={errorOnFetchingBidderAuthReps || errorOnDeletingBidderAuthRep} show={false} />}
            {seekConfirmation.show && <AuctionConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} 
                onDisAgree={seekConfirmation.onDisAgree}
            />}
        </div>
    )
}

export default BidderAuthRepList;